import React, { useEffect, useState, useRef } from "react";
import Api from "../../Api/axios";
import "./show.css";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Error from "../ErrorSuccess/Error";
import { MdDelete } from "react-icons/md";
import DeleteModal from "../DeleteModal/DeleteModal";
import { useReactToPrint } from "react-to-print";
import { Button } from "@mui/material";

const ShowStudents = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [allUsersFiltered, setAllUsersFiltered] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [errMesssage, setErrMesssage] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState();
  const componentRef = useRef();

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    if (
      userDetails?.userType === "CENTRAL" ||
      userDetails?.userType === "DISTRICT"
    ) {
      await Api.get("/user/all-students")
        .then((res) => {
          let allUsersTemp = [];
          allUsersTemp = res.data;
          allUsersTemp.forEach((user) => {
            if (user.classes !== null) {
              user.class_name =
                user.classes[user.classes.length - 1].split(" ")[0];
              user.class_section =
                user.classes[user.classes.length - 1].split(" ")[1];
            }
          });
          setAllUsers(allUsersTemp);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((err) => {
          setErrMesssage(err.response.message);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    } else {
      await Api.get(`/user/by-school?id=${userDetails.schoolId}&type=STUDENT`)
        .then((res) => {
          let allUsersTemp = [];
          allUsersTemp = res.data;
          allUsersTemp.forEach((user) => {
            if (user.classes !== null) {
              user.class_name =
                user.classes[user.classes.length - 1].split(" ")[0];
              user.class_section =
                user.classes[user.classes.length - 1].split(" ")[1];
            }
          });
          setAllUsers(allUsersTemp);

          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((err) => {
          setErrMesssage(err.response.message);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    }
  };

  const openDeleteModal = (userId) => {
    setDeleteModalOpen(true);
    setIdToBeDeleted(userId);
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const deleteConfirmed = () => {
    setDeleteModalOpen(false);
    deleteUser();
  };
  const deleteUser = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.delete("/user", { params: { id: idToBeDeleted } })
      .then((res) => fetchStudents())
      .catch((err) => console.log(err));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const changeFilterData = (e) => {
    const { id, value } = e.target;
    setFilterData({ ...filterData, [id]: value });
  };

  useEffect(() => {
    setFilteredUserDetails();
  }, [filterData, allUsers]);

  const setFilteredUserDetails = () => {
    const keys = Object.keys(filterData);
    let allEmpty = Object.values(filterData).every(
      (x) => x === null || x === "" || x === undefined
    );
    if (allEmpty || filterData === {}) {
      setAllUsersFiltered(allUsers);
    } else {
      let filteredUserDetailsTemp = allUsers.filter((attd) => {
        return keys.every((key) =>
          attd[key]
            ?.toLocaleLowerCase()
            .includes(filterData[key]?.toLocaleLowerCase())
        );
      });
      setAllUsersFiltered(filteredUserDetailsTemp);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="show-table-container">
      <div className="show-table-container-main">
        <Button variant="outlined" style={{margin:"0.5rem"}} onClick={handlePrint}>Print User Details</Button>
        <table className="table" ref={componentRef}>
          <thead>
            <tr>
              <th colSpan="8">Students</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Name
                  <input
                    type="text"
                    id="name"
                    placeholder="eg: Ayan"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Email
                  <input
                    type="text"
                    id="email"
                    placeholder="eg: Ayan"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Username
                  <input
                    type="text"
                    id="username"
                    placeholder="eg: s1s1"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Id
                  <input
                    type="text"
                    id="_id"
                    placeholder="eg: id"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Class
                  <input
                    type="text"
                    id="class_name"
                    placeholder="eg: 5"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Section
                  <input
                    type="text"
                    id="class_section"
                    placeholder="eg: A"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Contact Number
                  <input
                    type="text"
                    id="contactNumber"
                    placeholder="eg: 91XXXXXX01"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>

              <td>Delete</td>
            </tr>
            {allUsersFiltered?.map((user) => (
              <tr key={user}>
                <td>{user?.name}</td>
                <td>{user?.email}</td>
                <td>{user?.username}</td>
                <td>{user?._id}</td>
                <td>{user?.class_name}</td>
                <td>{user?.class_section}</td>
                <td>{user?.contactNumber}</td>
                <td>
                  <MdDelete
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => openDeleteModal(user?._id)}
                  />
                </td>
              </tr>
            ))}
            {errMesssage && <Error message={errMesssage} />}
          </tbody>
        </table>
      </div>
      <DeleteModal
        open={deleteModalOpen}
        closeDeleteModal={closeDeleteModal}
        deleteConfirmed={deleteConfirmed}
        message={
          "Do you really want to delete this user ? This process cannot be undone."
        }
      />
    </div>
  );
};

export default ShowStudents;



// checking all code by single single line

// import React, { useEffect, useState } from "react";
// import { useDataLayerValue } from "../../DataLayer/DataLayer";
// import Api from "../../Api/axios";

// //import { Button } from "@material-ui/core";

// const ShowStudents = () => {
//   const [allUsers, setAllUsers] = useState([]);
//   const [{ userDetails }, dispatch] = useDataLayerValue();
//   //const [allUsersFiltered, setAllUsersFiltered] = useState([]);

//   useEffect(() => {
//     fetchStudents();
//   }, []);

//   const fetchStudents = async () => {
//     dispatch({
//       type: "SET_LOADING",
//       loading: true,
//     });
//     if (
//       userDetails?.userType === "CENTRAL" ||
//       userDetails?.userType === "DISTRICT"
//     ) {
//       await Api.get("/user/all-parents")
//         .then((res) => {
//           setAllUsers(res.data);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         })
//         .catch((err) => {
//           console.log(err);
//           // setErrMesssage(err.response.message);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         });
//     } else {
//       console.log("userDetails", userDetails);
//       await Api.get(`/user/by-school?id=${userDetails.schoolId}&type=STUDENT`)
//         .then((res) => {
//           console.log("before setAllusers", res.data);
//           setAllUsers(res.data);
//           //setAllUsersFiltered(allUsers);
//           //console.log(allUsersFiltered);
//           console.log("alluseres", allUsers);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         })
//         .catch((err) => console.log(err));
//     }
//   };

//   // const deleteUser = async () => {
//   //   dispatch({
//   //     type: "SET_LOADING",
//   //     loading: true,
//   //   });
//   //   await Api.delete("/user", { params: { id: idToBeDeleted } })
//   //     .then((res) => {
//   //       console.log(res.data);
//   //       fetchStudents()})
//   //     .catch((err) => console.log(err));
//   //   dispatch({
//   //     type: "SET_LOADING",
//   //     loading: false,
//   //   });
//   // };

//   return (
//     <div className="show-table-container">
//       <div className="show-table-container-main">
//         <table className="table">
//           <thead>
//             <tr>
//               <th colSpan="8">Students</th>
//             </tr>
//             <tr>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Name
//                   <input type="text" id="name" placeholder="eg: impetus" />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Email
//                   <input type="text" id="name" placeholder="eg: impetus" />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Username
//                   <input type="text" id="name" placeholder="eg: impetus" />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Id
//                   <input type="text" id="name" placeholder="eg: impetus" />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Class
//                   <input type="text" id="name" placeholder="eg: impetus" />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Section
//                   <input type="text" id="name" placeholder="eg: impetus" />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   ContactNo
//                   <input type="text" id="name" placeholder="eg: impetus" />
//                 </div>
//               </td>
//               <td>Delete</td>
//             </tr>
//           </thead>
//           <tbody>
//             {allUsers.map((user) => (
//               <tr>
//                 <td>{user?.name}</td>
//                 <td>{user?.email}</td>
//                 <td>{user?.email}</td>
//                 <td>{user?._id}</td>
//                 <td>{user?.name}</td>
//                 <td>{user?.name}</td>
//                 <td>{user?.contactNumber.userContactNo}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <button onClick={() => fetchStudents()}>Show Students</button>
//     </div>
//   );
// };

// export default ShowStudents;
