import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Table } from "react-bootstrap";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Pie from "./Pie";
import "./Pagination.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const AttendanceReport = () => {
    const [dateRange, setDateRange] = useState([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    const [dateRangeType, setDateRangeType] = useState("TODAY");
    const [dateRangeTypes, setDateRangeTypes] = useState([
        {
            value: "TODAY",
            text: "Today",
        },
        {
            value: "YESTERDAY",
            text: "Yesterday",
        },
        {
            value: "LAST_7_DAYS",
            text: "Last 7 Days",
        },
        {
            value: "LAST_30_DAYS",
            text: "Last 30 Days",
        },
        {
            value: "CUSTOM",
            text: "Custom",
        },
    ]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [schoolList, setSchoolList] = useState([]);
    const [attendanceList, setAttendanceList] = useState({});
    const [schoolWiseInfo, setSchoolWiseInfo] = useState({});
    const [schoolWisePercentage, setSchoolWisePercentage] = useState({});
    const [overallPercent, setOverallPercent] = useState({});
    const [schoolNames, setSchoolNames] = useState([]);
    const [userTypes, setUserTypes] = useState([
        {
            value: "STUDENT",
            text: "Student",
        },
        {
            value: "TEACHER",
            text: "Teacher",
        },
        {
            value: "SCHOOL_ADMIN",
            text: "School Admin",
        }
    ]);
    const [chartTypeList, setChartTypeList] = useState([
        {
            value: "OVERALL",
            text: "Overall",
        },
        {
            value: "USER_TYPE",
            text: "User Type Wise",
        },
        {
            value: "SCHOOL_WISE_STUDENT",
            text: "School Wise Student",
        },
        {
            value: "SCHOOL_WISE_TEACHER",
            text: "School Wise Teacher",
        },
    ]);
    const [chartType, setChartType] = useState("OVERALL");
    const [userType, setUserType] = useState("STUDENT");
    const [userList, setUserList] = useState([]);
    const [usersAttendance, setUsersAttendance] = useState({});
    const [maxWorkingDays, setMaxWorkingDays] = useState(0);
    useEffect(() => {
        Api.get("/school-entity/school").then((res) => {
            res.data.sort((a, b) => a.name.localeCompare(b.name));
            setSchoolList(res.data);
            setSchoolNames(res.data.map((school) => school.name));
        });
    }, [userDetails]);
    useEffect(() => {
        const attendance_list = {};
        const school_wise_info = {};
        Api.post("/attendance/school-attendance", {
            start_date: dateRange[0].getTime(),
            end_date: dateRange[1].getTime(),
            attendance_filters: {
                school_id: schoolList.map((school) => school._id),
            },
        }).then((res) => {
            res.data.map((attendance) => {
                if (attendance.school_id in attendance_list) {
                    if (attendance.class_id in attendance_list[attendance.school_id]) {
                        attendance_list[attendance.school_id][attendance.class_id].push(attendance);
                    } else {
                        attendance_list[attendance.school_id][attendance.class_id] = [attendance];
                    }
                } else {
                    attendance_list[attendance.school_id] = {};
                    attendance_list[attendance.school_id][attendance.class_id] = [attendance];
                }
                if (attendance.school_id in school_wise_info) {
                    school_wise_info[attendance.school_id]["max_days_marked"] = Math.max(school_wise_info[attendance.school_id]["max_days_marked"], attendance_list[attendance.school_id][attendance.class_id].length);
                } else {
                    school_wise_info[attendance.school_id] = {};
                    school_wise_info[attendance.school_id]["max_days_marked"] = attendance_list[attendance.school_id][attendance.class_id].length;
                }
            })
        }).finally(() => {
            const school_wise_percent = {};
            const overall_percent = {};
            Object.keys(attendance_list).map((school_id) => {
                if (!(school_id in school_wise_percent)) {
                    school_wise_percent[school_id] = {};
                }
                Object.keys(attendance_list[school_id]).map((class_id) => {
                    if (!(class_id in school_wise_percent[school_id])) {
                        school_wise_percent[school_id][class_id] = {};
                    }
                    attendance_list[school_id][class_id].map((attendance) => {
                        if (school_wise_percent[school_id][class_id]["Present"] === undefined) {
                            school_wise_percent[school_id][class_id]["Present"] = attendance.total_present;
                        } else {
                            school_wise_percent[school_id][class_id]["Present"] += attendance.total_present;
                        }
                        if (school_wise_percent[school_id][class_id]["Count"] === undefined) {
                            school_wise_percent[school_id][class_id]["Count"] = 1;
                        } else {
                            school_wise_percent[school_id][class_id]["Count"] += 1;
                        }
                        school_wise_percent[school_id][class_id]["Attendance"] = Math.max(attendance.total_attendance, school_wise_percent[school_id][class_id]["Attendance"] || 0);
                    });
                });
            });
            Object.keys(attendance_list).map((school_id) => {
                Object.keys(attendance_list[school_id]).map((class_id) => {
                    school_wise_percent[school_id][class_id]["Average Present"] = (school_wise_percent[school_id][class_id]["Present"] / school_wise_percent[school_id][class_id]["Count"]);
                    school_wise_percent[school_id][class_id]["Average Attendance"] = ((school_wise_percent[school_id][class_id]["Average Present"] / school_wise_percent[school_id][class_id]["Attendance"]) * 100);
                });
            });
            overall_percent["STUDENT"] = {};
            overall_percent["TEACHER"] = {};
            overall_percent["SCHOOL_ADMIN"] = {};
            Object.keys(school_wise_info).map((school_id) => {
                Object.keys(attendance_list[school_id]).map((class_id) => {
                    console.log(school_id, class_id);
                    if (school_id === null || school_id === "null") {
                        overall_percent["SCHOOL_ADMIN"]["Present"] = school_wise_percent[school_id][class_id]["Average Present"];
                        overall_percent["SCHOOL_ADMIN"]["Attendance"] = school_wise_percent[school_id][class_id]["Attendance"];
                        overall_percent["SCHOOL_ADMIN"]["Average Attendance"] = (school_wise_percent[school_id][class_id]["Average Present"] / school_wise_percent[school_id][class_id]["Attendance"]);
                    } else {
                        if (class_id === null || class_id === "null") {
                            if (overall_percent["TEACHER"][school_id] === undefined) {
                                overall_percent["TEACHER"][school_id] = {};
                            }
                            overall_percent["TEACHER"][school_id]["Present"] = school_wise_percent[school_id][class_id]["Average Present"];
                            overall_percent["TEACHER"][school_id]["Attendance"] = school_wise_percent[school_id][class_id]["Attendance"];
                            overall_percent["TEACHER"][school_id]["Average Attendance"] = (school_wise_percent[school_id][class_id]["Average Present"] / school_wise_percent[school_id][class_id]["Attendance"]);
                        } else {
                            if (overall_percent["STUDENT"][school_id] === undefined) {
                                overall_percent["STUDENT"][school_id] = {};
                            }
                            if (overall_percent["STUDENT"][school_id]["Present"] === undefined) {
                                overall_percent["STUDENT"][school_id]["Present"] = school_wise_percent[school_id][class_id]["Average Present"];
                            } else {
                                overall_percent["STUDENT"][school_id]["Present"] += school_wise_percent[school_id][class_id]["Average Present"];
                            }
                            if (overall_percent["STUDENT"][school_id]["Attendance"] === undefined) {
                                overall_percent["STUDENT"][school_id]["Attendance"] = school_wise_percent[school_id][class_id]["Attendance"];
                            } else {
                                overall_percent["STUDENT"][school_id]["Attendance"] += school_wise_percent[school_id][class_id]["Attendance"];
                            }
                        }
                    }
                });
            });
            Object.keys(overall_percent["STUDENT"]).map((school_id) => {
                overall_percent["STUDENT"][school_id]["Average Attendance"] = (overall_percent["STUDENT"][school_id]["Present"] / overall_percent["STUDENT"][school_id]["Attendance"]);
            });
            overall_percent["STUDENT_OVERALL"] = {};
            overall_percent["TEACHER_OVERALL"] = {};
            Object.keys(overall_percent["STUDENT"]).map((school_id) => {
                if (overall_percent["STUDENT_OVERALL"]["Present"] === undefined) {
                    overall_percent["STUDENT_OVERALL"]["Present"] = overall_percent["STUDENT"][school_id]["Present"];
                } else {
                    overall_percent["STUDENT_OVERALL"]["Present"] += overall_percent["STUDENT"][school_id]["Present"];
                }
                if (overall_percent["STUDENT_OVERALL"]["Attendance"] === undefined) {
                    overall_percent["STUDENT_OVERALL"]["Attendance"] = overall_percent["STUDENT"][school_id]["Attendance"];
                } else {
                    overall_percent["STUDENT_OVERALL"]["Attendance"] += overall_percent["STUDENT"][school_id]["Attendance"];
                }
            });
            overall_percent["STUDENT_OVERALL"]["Average Attendance"] = (overall_percent["STUDENT_OVERALL"]["Present"] / overall_percent["STUDENT_OVERALL"]["Attendance"]);
            Object.keys(overall_percent["TEACHER"]).map((school_id) => {
                if (overall_percent["TEACHER_OVERALL"]["Present"] === undefined) {
                    overall_percent["TEACHER_OVERALL"]["Present"] = overall_percent["TEACHER"][school_id]["Present"];
                } else {
                    overall_percent["TEACHER_OVERALL"]["Present"] += overall_percent["TEACHER"][school_id]["Present"];
                }
                if (overall_percent["TEACHER_OVERALL"]["Attendance"] === undefined) {
                    overall_percent["TEACHER_OVERALL"]["Attendance"] = overall_percent["TEACHER"][school_id]["Attendance"];
                } else {
                    overall_percent["TEACHER_OVERALL"]["Attendance"] += overall_percent["TEACHER"][school_id]["Attendance"];
                }
            });
            overall_percent["TEACHER_OVERALL"]["Average Attendance"] = (overall_percent["TEACHER_OVERALL"]["Present"] / overall_percent["TEACHER_OVERALL"]["Attendance"]);
            overall_percent["OVERALL"] = {};
            overall_percent["OVERALL"]["Present"] = overall_percent["SCHOOL_ADMIN"]["Present"] ? overall_percent["SCHOOL_ADMIN"]["Present"] : 0 ;
            overall_percent["OVERALL"]["Attendance"] = overall_percent["SCHOOL_ADMIN"]["Attendance"] ? overall_percent["SCHOOL_ADMIN"]["Attendance"] : 0; 
            overall_percent["OVERALL"]["Present"] += overall_percent["STUDENT_OVERALL"]["Present"] ? overall_percent["STUDENT_OVERALL"]["Present"] : 0; 
            overall_percent["OVERALL"]["Attendance"] += overall_percent["STUDENT_OVERALL"]["Attendance"] ? overall_percent["STUDENT_OVERALL"]["Attendance"] : 0;
            overall_percent["OVERALL"]["Present"] += overall_percent["TEACHER_OVERALL"]["Present"] ? overall_percent["TEACHER_OVERALL"]["Present"] : 0;
            overall_percent["OVERALL"]["Attendance"] += overall_percent["TEACHER_OVERALL"]["Attendance"] ? overall_percent["TEACHER_OVERALL"]["Attendance"] : 0;
            overall_percent["OVERALL"]["Average Attendance"] = ((overall_percent["OVERALL"]["Present"] ? overall_percent["OVERALL"]["Present"] : (overall_percent["OVERALL"]["Attendance"] ? 0 : 0)) / (overall_percent["OVERALL"]["Attendance"] ? overall_percent["OVERALL"]["Attendance"] : 1));
            setOverallPercent(overall_percent);
            setSchoolWisePercentage(school_wise_percent);
            setSchoolWiseInfo(school_wise_info);
            setAttendanceList(attendance_list);
            console.log(overall_percent, school_wise_percent, school_wise_info, attendance_list);
        });

    }, [dateRange, schoolList]);
    useEffect(() => {
        if (userType === "SCHOOL_ADMIN") {
            let count = 0;
            let temp_dict = {};
            Api.get("/user/users-data", {
                params: {
                    userType: "SCHOOL_ADMIN",
                },
            }).then((res) => {
                setUserList(res.data);
            }).finally(() => {
                Api.post("/attendance/personal-of-user", {
                    start_date: dateRange[0].getTime(),
                    end_date: dateRange[1].getTime(),
                    filters: {
                        user_type: "SCHOOL_ADMIN",
                    },
                }).then((res) => {
                    Object.keys(res.data).map((i) => {
                        let list_of_attendance = res.data[i];
                        count = Math.max(count, list_of_attendance.length);
                        let present = 0;
                        let absent = 0;
                        list_of_attendance.map((j) => {
                            if (j["present"] === true) {
                                present++;
                            } else {
                                absent++;
                            }
                        });
                        temp_dict[i] = {
                            present: present,
                            absent: absent,
                            total: present + absent,
                            attendanceList: i,
                        };
                    });
                }).finally(() => {
                    console.log(temp_dict);
                    setUsersAttendance(temp_dict);
                    setMaxWorkingDays(count);
                });
            });
        }
    }, [userType, dateRange]);
    const [schoolWisePageCount, setSchoolWisePageCount] = useState(1);
    return (
        <div>
            <h1 className="pt-3" align="center" style={{ fontWeight: "bold" }}>Attendance View</h1>
            <Form className="row">
                <Form.Group className="col-12" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Date Range</Form.Label>
                    <Form.Control as="select" value={dateRangeType} onChange={(e) => {
                        if (e.target.value === "TODAY") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "YESTERDAY") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)]);
                        } else if (e.target.value === "LAST_7_DAYS") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "LAST_30_DAYS") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (29 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "CUSTOM") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        }
                        setDateRangeType(e.target.value)
                    }}>
                        {dateRangeTypes.map((item) => {
                            return (
                                <option value={item.value}>{item.text}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group className="col-6" style={{
                    paddingLeft: "30px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[0].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                        setDateRangeType("CUSTOM")
                        let temp_time_array = [new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]]
                        if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                            setDateRange([new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]])
                        } else {
                            alert("Start Date cannot be greater than End Date.")
                        }
                    }} />
                </Form.Group>
                <Form.Group className="col-6" style={{
                    paddingLeft: "10px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[1].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                        setDateRangeType("CUSTOM")
                        let temp_time_array = [dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]
                        if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                            setDateRange([dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))])
                        } else {
                            alert("End Date cannot be less than Start Date.")
                        }
                    }} />
                </Form.Group>
                <Form.Group className="col-12" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Type of Report</Form.Label>
                    <Form.Control as="select" value={chartType} onChange={(e) => {
                        setChartType(e.target.value)
                        setSchoolWisePageCount(1)
                    }}>
                        {chartTypeList.map((item) => {
                            return (
                                <option value={item.value}>{item.text}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
            {(chartType === "OVERALL" || chartType === "USER_TYPE") ? <Stack className="row" gap={3} direction="horizontal" style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                {(chartType === "OVERALL") ?
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}>
                        <Pie percentage={(overallPercent["OVERALL"] ? (overallPercent["OVERALL"]["Average Attendance"] ? overallPercent["OVERALL"]["Average Attendance"] : 0) : 0) * 100} colour="red" />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Overall Attendance
                        </p>
                    </div> : chartType === "USER_TYPE" ?
                        <><div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            width: "350px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}>
                            <Pie percentage={(overallPercent["STUDENT_OVERALL"] ? (overallPercent["STUDENT_OVERALL"]["Average Attendance"] ? overallPercent["STUDENT_OVERALL"]["Average Attendance"] : 0) : 0) * 100} colour="red" />
                            <p align="center" style={{
                                "fontWeight": "bold",
                                "fontSize": "x-large",
                            }}>
                                Student Attendance
                            </p>
                        </div>
                            <div style={{
                                padding: "10px",
                                backgroundColor: "white",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <Pie percentage={(overallPercent["TEACHER_OVERALL"] ? (overallPercent["TEACHER_OVERALL"]["Average Attendance"] ? overallPercent["TEACHER_OVERALL"]["Average Attendance"] : 0) : 0) * 100} colour="blue" />
                                <p align="center" style={{
                                    "fontWeight": "bold",
                                    "fontSize": "x-large",
                                }}>
                                    Teacher Attendance
                                </p>
                            </div>
                            <div style={{
                                padding: "10px",
                                backgroundColor: "white",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <Pie percentage={(overallPercent["SCHOOL_ADMIN"] ? (overallPercent["SCHOOL_ADMIN"]["Average Attendance"] ? overallPercent["SCHOOL_ADMIN"]["Average Attendance"] : 0) : 0) * 100} colour="black" />
                                <p align="center" style={{
                                    "fontWeight": "bold",
                                    "fontSize": "x-large",
                                }}>
                                    School Admin Attendance
                                </p>
                            </div></> : null}
            </Stack> : chartType === "SCHOOL_WISE_STUDENT" ?
                <div style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                        width: "100%",
                    }}>
                        {console.log(schoolNames, schoolWisePageCount)}
                        <Bar options={
                            {
                                indexAxis: 'y',
                                elements: {
                                    bar: {
                                        borderWidth: 2,
                                    },
                                },
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    title: {
                                        display: true,
                                        text: 'Student Bar Chart',
                                    },
                                },
                            }
                        } data={{
                            labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                            datasets: [
                                {
                                    label: 'Student Attendance Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        return overallPercent["STUDENT"] ? (overallPercent["STUDENT"][item._id] ? (overallPercent["STUDENT"][item._id]["Average Attendance"] * 100).toFixed(2) : 0) : 0
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                            ],
                        }}
                        />
                        <div class="center">
                            <div class="pagination">
                                <a href="#" onClick={() => {
                                    setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                }}>&laquo;</a>
                                <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(1)
                                }}>1</a>
                                <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(2)
                                }}>2</a>
                                <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(3)
                                }}>3</a>
                                <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(4)
                                }}>4</a>
                                <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(5)
                                }}>5</a>
                                <a href="#" onClick={() => {
                                    setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                }}>&raquo;</a>
                            </div>
                        </div>
                    </div>
                </div> : chartType === "SCHOOL_WISE_TEACHER" ?
                    <div style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                        }}>
                            {console.log(schoolNames)}
                            <Bar options={
                                {
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: 'Teacher Bar Chart',
                                        },
                                    },
                                }
                            } data={{
                                labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                                datasets: [{
                                    label: 'Teacher Attendance Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        return overallPercent["TEACHER"] ? (overallPercent["TEACHER"][item._id] ? (overallPercent["TEACHER"][item._id]["Average Attendance"] * 100).toFixed(2) : 0) : 0
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                ],
                            }}
                            />
                            <div class="center">
                                <div class="pagination">
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                    }}>&laquo;</a>
                                    <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(1)
                                    }}>1</a>
                                    <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(2)
                                    }}>2</a>
                                    <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(3)
                                    }}>3</a>
                                    <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(4)
                                    }}>4</a>
                                    <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(5)
                                    }}>5</a>
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                    }}>&raquo;</a>
                                </div>
                            </div>
                        </div>

                    </div> :
                    null
            }
        </div>
    )
}

export default AttendanceReport;