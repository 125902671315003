import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Table } from "react-bootstrap";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Pie from "./Pie";
import "./Pagination.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const LearningManagementReport = () => {
    const [dateRange, setDateRange] = useState([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    const [dateRangeType, setDateRangeType] = useState("TODAY");
    const [dateRangeTypes, setDateRangeTypes] = useState([
        {
            value: "TODAY",
            text: "Today",
        },
        {
            value: "YESTERDAY",
            text: "Yesterday",
        },
        {
            value: "LAST_7_DAYS",
            text: "Last 7 Days",
        },
        {
            value: "LAST_30_DAYS",
            text: "Last 30 Days",
        },
        {
            value: "CUSTOM",
            text: "Custom",
        },
        {
            value: "ALL_TIME",
            text: "All Time",
        },
    ]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [schoolList, setSchoolList] = useState([]);
    const [progressList, setProgressList] = useState({});
    const [schoolWiseInfo, setSchoolWiseInfo] = useState({});
    const [schoolWisePercentage, setSchoolWisePercentage] = useState({});
    const [overallPercent, setOverallPercent] = useState({});
    const [schoolNames, setSchoolNames] = useState([]);
    const [userTypes, setUserTypes] = useState([
        {
            value: "STUDENT",
            text: "Student",
        },
        {
            value: "TEACHER",
            text: "Teacher",
        },
        {
            value: "SCHOOL_ADMIN",
            text: "School Admin",
        }
    ]);
    const [chartTypeList, setChartTypeList] = useState([
        {
            value: "OVERALL",
            text: "Overall",
        },
        {
            value: "SCHOOL_WISE_PROGRESS",
            text: "School Wise Progress",
        },
        {
            value: "SCHOOL_WISE_USER_VISIT",
            text: "School Wise User Visit",
        },
    ]);
    const [chartType, setChartType] = useState("OVERALL");
    const [userType, setUserType] = useState("STUDENT");
    const [userList, setUserList] = useState([]);
    const [usersProgress, setUsersProgress] = useState({});
    const [maxWorkingDays, setMaxWorkingDays] = useState(0);
    useEffect(() => {
        Api.get("/school-entity/school").then((res) => {
            res.data.sort((a, b) => a.name.localeCompare(b.name));
            setSchoolList(res.data);
            setSchoolNames(res.data.map((school) => school.name));
        });
    }, [userDetails]);
    const [userSetList, setUserSetList] = useState([]);
    const [courseSetList, setCourseSetList] = useState([]);
    const [schoolSetList, setSchoolSetList] = useState([]);
    const [schoolWiseUserDifferential, setSchoolWiseUserDifferential] = useState({});
    const [courseWiseUserDifferential, setCourseWiseUserDifferential] = useState({});
    const [schoolWiseCourseDifferential, setSchoolWiseCourseDifferential] = useState({});
    const [courseWiseSchoolDifferential, setCourseWiseSchoolDifferential] = useState({});
    const [schoolOverallProgress, setSchoolOverallProgress] = useState({});
    const [courseOverallProgress, setCourseOverallProgress] = useState({});
    const [overallProgress, setOverallProgress] = useState({});
    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        const data = {};
        if (dateRangeType !== "ALL_TIME") {
            data.start_time = dateRange[0].getTime();
            data.end_time = dateRange[1].getTime();
        }
        let users_set = new Set();
        let courses_set = new Set();
        let school_set = new Set();
        let school_wise_user_differential = {};
        let course_wise_user_differential = {};
        let school_wise_course_differential = {};
        let course_wise_school_differential = {};
        let school_overall_progress = {};
        let course_overall_progress = {};
        let overall_progress = {};
        Api.get("/course/all-progress", {
            params: data,
        }).then((res) => {
            res.data?.map((item) => {
                item.progress = item.progress * 100;
                users_set.add(item.user_id);
                courses_set.add(item.general_id);
                school_set.add(item.schoolId);
                if (school_wise_user_differential[item.schoolId] === undefined) {
                    school_wise_user_differential[item.schoolId] = {};
                }
                if (school_wise_user_differential[item.schoolId][item.general_id] === undefined) {
                    school_wise_user_differential[item.schoolId][item.general_id] = {};
                }
                if (school_wise_user_differential[item.schoolId][item.general_id][item.user_id] === undefined) {
                    school_wise_user_differential[item.schoolId][item.general_id][item.user_id] = {};
                }
                school_wise_user_differential[item.schoolId][item.general_id][item.user_id] = item;
                if (course_wise_user_differential[item.general_id] === undefined) {
                    course_wise_user_differential[item.general_id] = {};
                }
                if (course_wise_user_differential[item.general_id][item.schoolId] === undefined) {
                    course_wise_user_differential[item.general_id][item.schoolId] = {};
                }
                if (course_wise_user_differential[item.general_id][item.schoolId][item.user_id] === undefined) {
                    course_wise_user_differential[item.general_id][item.schoolId][item.user_id] = {};
                }
                course_wise_user_differential[item.general_id][item.schoolId][item.user_id] = item;
            });
            school_set = Array.from(school_set);
            courses_set = Array.from(courses_set);
            users_set = Array.from(users_set);
            school_set.map((school_id) => {
                if (school_wise_course_differential[school_id] === undefined) {
                    school_wise_course_differential[school_id] = {};
                }
                courses_set.map((course_id) => {
                    if (school_wise_course_differential[school_id][course_id] === undefined) {
                        school_wise_course_differential[school_id][course_id] = {};
                    }
                    school_wise_course_differential[school_id][course_id] = {
                        "Progress": 0,
                        "Count": 0,
                        "Average Progress": 0,
                    };
                    console.log(school_wise_user_differential, school_wise_user_differential?.school_id?.course_id)
                    if (school_wise_user_differential[school_id]) {
                        if (school_wise_user_differential[school_id][course_id]) {
                            Object.keys(school_wise_user_differential[school_id][course_id]).map((user_id) => {
                                school_wise_course_differential[school_id][course_id]["Progress"] += school_wise_user_differential[school_id][course_id][user_id]["progress"];
                                school_wise_course_differential[school_id][course_id]["Count"] += 1;
                            })
                        }
                    }
                    school_wise_course_differential[school_id][course_id]["Average Progress"] = school_wise_course_differential[school_id][course_id]["Progress"] / (school_wise_course_differential[school_id][course_id]["Count"] ? school_wise_course_differential[school_id][course_id]["Count"] : 1);
                });
            });
            courses_set.map((course_id) => {
                if (course_wise_school_differential[course_id] === undefined) {
                    course_wise_school_differential[course_id] = {};
                }
                school_set.map((school_id) => {
                    if (course_wise_school_differential[course_id][school_id] === undefined) {
                        course_wise_school_differential[course_id][school_id] = {};
                    }
                    course_wise_school_differential[course_id][school_id] = {
                        "Progress": 0,
                        "Count": 0,
                        "Average Progress": 0,
                    };
                    if (course_wise_user_differential[course_id]) {
                        if (course_wise_user_differential[course_id][school_id]) {
                            Object.keys(course_wise_user_differential[course_id][school_id]).map((user_id) => {
                                course_wise_school_differential[course_id][school_id]["Progress"] += course_wise_user_differential[course_id][school_id][user_id]["progress"];
                                course_wise_school_differential[course_id][school_id]["Count"] += 1;
                            })
                        }
                    }
                    course_wise_school_differential[course_id][school_id]["Average Progress"] = course_wise_school_differential[course_id][school_id]["Progress"] / (course_wise_school_differential[course_id][school_id]["Count"] ? course_wise_school_differential[course_id][school_id]["Count"] : 1);
                });
            })
            school_set.map((school_id) => {
                school_overall_progress[school_id] = {
                    "Progress": 0,
                    "Count": 0,
                    "Average Progress": 0,
                };
                Object.keys(school_wise_course_differential[school_id])?.map((course_id) => {
                    school_overall_progress[school_id]["Progress"] += school_wise_course_differential[school_id][course_id]["Progress"];
                    school_overall_progress[school_id]["Count"] += school_wise_course_differential[school_id][course_id]["Count"];
                });
                school_overall_progress[school_id]["Average Progress"] = school_overall_progress[school_id]["Progress"] / (school_overall_progress[school_id]["Count"] ? school_overall_progress[school_id]["Count"] : 1);
            });
            courses_set.map((course_id) => {
                course_overall_progress[course_id] = {
                    "Progress": 0,
                    "Count": 0,
                    "Average Progress": 0,
                };
                Object.keys(course_wise_school_differential[course_id])?.map((school_id) => {
                    course_overall_progress[course_id]["Progress"] += course_wise_school_differential[course_id][school_id]["Progress"];
                    course_overall_progress[course_id]["Count"] += course_wise_school_differential[course_id][school_id]["Count"];
                });
                course_overall_progress[course_id]["Average Progress"] = course_overall_progress[course_id]["Progress"] / (course_overall_progress[course_id]["Count"] ? course_overall_progress[course_id]["Count"] : 1);
            });
            overall_progress = {
                "Progress": 0,
                "Count": 0,
                "Average Progress": 0,
            };
            school_set.map((school_id) => {
                overall_progress["Progress"] += school_overall_progress[school_id]["Progress"];
                overall_progress["Count"] += school_overall_progress[school_id]["Count"];
            });
            overall_progress["Average Progress"] = overall_progress["Progress"] / (overall_progress["Count"] ? overall_progress["Count"] : 1);
            console.log(users_set, courses_set, school_set, school_wise_user_differential, course_wise_user_differential, school_wise_course_differential, course_wise_school_differential, school_overall_progress, course_overall_progress, overall_progress);
        }).finally(() => {
            setUserSetList(users_set);
            setCourseSetList(courses_set);
            setSchoolSetList(school_set);
            setSchoolWiseUserDifferential(school_wise_user_differential);
            setCourseWiseUserDifferential(course_wise_user_differential);
            setSchoolWiseCourseDifferential(school_wise_course_differential);
            setCourseWiseSchoolDifferential(course_wise_school_differential);
            setSchoolOverallProgress(school_overall_progress);
            setCourseOverallProgress(course_overall_progress);
            setOverallProgress(overall_progress);
            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        });
    }, [dateRange]);
    useEffect(() => {
        if (userType === "SCHOOL_ADMIN") {
            let count = 0;
            let temp_dict = {};
            Api.get("/user/users-data", {
                params: {
                    userType: "SCHOOL_ADMIN",
                },
            }).then((res) => {
                setUserList(res.data);
            }).finally(() => {
                Api.post("/attendance/personal-of-user", {
                    start_date: dateRange[0].getTime(),
                    end_date: dateRange[1].getTime(),
                    filters: {
                        user_type: "SCHOOL_ADMIN",
                    },
                }).then((res) => {
                    Object.keys(res.data).map((i) => {
                        let list_of_progress = res.data[i];
                        count = Math.max(count, list_of_progress.length);
                        let present = 0;
                        let absent = 0;
                        list_of_progress.map((j) => {
                            if (j["present"] === true) {
                                present++;
                            } else {
                                absent++;
                            }
                        });
                        temp_dict[i] = {
                            present: present,
                            absent: absent,
                            total: present + absent,
                            progressList: i,
                        };
                    });
                }).finally(() => {
                    console.log(temp_dict);
                    setUsersProgress(temp_dict);
                    setMaxWorkingDays(count);
                });
            });
        }
    }, [userType, dateRange]);
    const [schoolWisePageCount, setSchoolWisePageCount] = useState(1);
    return (
        <div>
            <h1 className="pt-3" align="center" style={{ fontWeight: "bold" }}>Learning Management View</h1>
            <Form className="row">
                <Form.Group className="col-12" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Date Range</Form.Label>
                    <Form.Control as="select" value={dateRangeType} onChange={(e) => {
                        if (e.target.value === "TODAY") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "YESTERDAY") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)]);
                        } else if (e.target.value === "LAST_7_DAYS") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "LAST_30_DAYS") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (29 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "CUSTOM") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        }
                        setDateRangeType(e.target.value)
                    }}>
                        {dateRangeTypes.map((item) => {
                            return (
                                <option value={item.value}>{item.text}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
                {dateRangeType !== "ALL_TIME" ? <>
                    <Form.Group className="col-6" style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[0].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                            setDateRangeType("CUSTOM")
                            let temp_time_array = [new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]]
                            if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                                setDateRange([new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]])
                            } else {
                                alert("Start Date cannot be greater than End Date.")
                            }
                        }} />
                    </Form.Group>
                    <Form.Group className="col-6" style={{
                        paddingLeft: "10px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[1].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                            setDateRangeType("CUSTOM")
                            let temp_time_array = [dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]
                            if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                                setDateRange([dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))])
                            } else {
                                alert("End Date cannot be less than Start Date.")
                            }
                        }} />
                    </Form.Group>
                </> : null}
                <Form.Group className="col-12" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Type of Report</Form.Label>
                    <Form.Control as="select" value={chartType} onChange={(e) => {
                        setChartType(e.target.value)
                        setSchoolWisePageCount(1)
                    }}>
                        {chartTypeList.map((item) => {
                            return (
                                <option value={item.value}>{item.text}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
            {(chartType === "OVERALL") ? <Stack className="row" gap={3} direction="horizontal" style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                {(chartType === "OVERALL") ? <>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        height: "300px",
                    }}>
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "xxx-large",
                            color: "red",
                        }}>
                            {userSetList?.length ? userSetList?.length : 0}
                        </p>
                        <br />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Total Users Visited
                        </p>
                    </div>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // flexWrap: "wrap",
                        flexDirection: "column",
                        height: "300px",
                    }}>
                        <Pie percentage={overallProgress["Average Progress"]} colour="red" />
                        {/* <br/> */}
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Overall Progress
                        </p>
                    </div>
                </> : null}
            </Stack> : chartType === "SCHOOL_WISE_PROGRESS" ?
                <div style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                        width: "100%",
                    }}>
                        {console.log(schoolNames, schoolWisePageCount)}
                        <Bar options={
                            {
                                indexAxis: 'y',
                                elements: {
                                    bar: {
                                        borderWidth: 2,
                                    },
                                },
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    title: {
                                        display: true,
                                        text: 'Progress Bar Chart',
                                    },
                                },
                            }
                        } data={{
                            labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                            datasets: [
                                {
                                    label: 'Student Progress Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        return schoolOverallProgress[item._id] ? schoolOverallProgress[item._id]["Average Progress"] ? schoolOverallProgress[item._id]["Average Progress"] : 0 : 0
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                            ],
                        }}
                        />
                        <div class="center">
                            <div class="pagination">
                                <a href="#" onClick={() => {
                                    setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                }}>&laquo;</a>
                                <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(1)
                                }}>1</a>
                                <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(2)
                                }}>2</a>
                                <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(3)
                                }}>3</a>
                                <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(4)
                                }}>4</a>
                                <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(5)
                                }}>5</a>
                                <a href="#" onClick={() => {
                                    setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                }}>&raquo;</a>
                            </div>
                        </div>
                    </div>
                </div> : chartType === "SCHOOL_WISE_USER_VISIT" ?
                    <div style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                        <div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                        }}>
                            {console.log(schoolNames)}
                            <Bar options={
                                {
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: 'User Visit Count Bar Chart',
                                        },
                                    },
                                }
                            } data={{
                                labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                                datasets: [{
                                    label: 'Student User Visit Count',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        return schoolOverallProgress[item._id] ? schoolOverallProgress[item._id]["Count"] ? schoolOverallProgress[item._id]["Count"] : 0 : 0
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                ],
                            }}
                            />
                            <div class="center">
                                <div class="pagination">
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                    }}>&laquo;</a>
                                    <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(1)
                                    }}>1</a>
                                    <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(2)
                                    }}>2</a>
                                    <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(3)
                                    }}>3</a>
                                    <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(4)
                                    }}>4</a>
                                    <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(5)
                                    }}>5</a>
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                    }}>&raquo;</a>
                                </div>
                            </div>
                        </div>

                    </div> :
                    null
            }
        </div>
    )
}

export default LearningManagementReport;