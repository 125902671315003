import React, { useState } from "react";
import "./User_profile.css";
import UserD from "./User_details";
import UserE from "./User_Edit";
import { Route, Routes, NavLink } from "react-router-dom";
import Error from "../../ErrorSuccess/Error";
import Success from "../../ErrorSuccess/Success";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";

const User_profile = () => {
  const [editOn, setEditOn] = useState(false);
  const [succMessage, setSuccMessage] = useState();
  const [errMessage, setErrMessage] = useState();
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [changedValues, setChangedValues] = useState();
  const changeValues = (e) => {
    const { id, value } = e.target;
    setChangedValues({ ...changedValues, [id]: value });
  };
  const enableEdit = () => {
    setEditOn(true);
  };
  const disableEdit = () => {
    setEditOn(false);
  };
  const saveChanges = async () => {
    const allNonEmpty = Object.values(changeValues).every(
      (val) => val !== "" || val !== undefined || val !== null
    );
    if (allNonEmpty) {
      if (
        changedValues?.contactNumber.length < 10 ||
        changedValues?.contactNumber.length > 10
      ) {
        setSuccMessage();
        setErrMessage("Contact number must be of 10 digits");
      } else if (isNaN(changedValues?.contactNumber)) {
        setSuccMessage();
        setErrMessage("Contact number must be numeric");
      } else {
        dispatch({
          type: "SET_LOADING",
          loading: true,
        });
        let dataToPush = changedValues;
        dataToPush._id = userDetails._id;
        await Api.put(`/user?id=${userDetails._id}`, dataToPush)
          .then((res) => {
            getChangedUserDetails();
          })
          .catch((err) => {
            console.log(err);
          });

        setSuccMessage("User details changed successfully");
        setErrMessage();
        setEditOn(false);
        setChangedValues({});
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      }
    }
  };

  const getChangedUserDetails = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.get("/user/get-user")
      .then(async (res) => {
        if (res.status === 200 && res.data.status !== 403) {
          dispatch({
            type: "SET_USER_DETAILS",
            userDetails: res.data,
          });
        }
      })
      .catch((err) => console.log(err));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  return (
    <div className="user-head">
      <div className="user-profile-container">
        <UserD editOn={editOn} changeValues={changeValues} />
        <div className="errMessage-container">
          {succMessage ? (
            <Success message={succMessage} />
          ) : errMessage ? (
            <Error message={errMessage} />
          ) : (
            ""
          )}
        </div>
        {editOn ? (
          <>
            <button onClick={() => disableEdit()}>Cancel</button>
            <button
              onClick={() => saveChanges()}
              style={{ backgroundColor: "limegreen", color: "white" }}
            >
              Save Changes
            </button>
          </>
        ) : (
          <button onClick={() => enableEdit()}>Edit User Profile</button>
        )}
      </div>
    </div>
  );
};

export default User_profile;
