import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from 'react-bootstrap';
import Api from '../../Api/axios';
import { Stack } from '@mui/material';
import {Modal} from 'react-bootstrap';
import { Table } from 'react-bootstrap';

const EditSchool = () => {
    const navigate = useNavigate();
    const handleSchoolEditInfo = (id) => {
        navigate("/edit-school-info/" + id);
    }
    const handleSchoolEditVariables = (id) => {
        navigate("/edit-school-variables/" + id);
    }
    const handleSchoolUsersEdit = (id) => {
        navigate("/edit-school-user/" + id);
    }
    const [schools, setSchools] = useState([]);
    const [filterSchools, setFilterSchools] = useState([]);
    useEffect(() => {
        Api.get('/school-entity/school').then((response) => {
            setSchools(response.data);
            setFilterSchools(response.data);
        });
    }, []);
    const searchSchool = (e) => {
        setFilterSchools(schools)
        if (e.target.value !== "") {
            setFilterSchools(filterSchools.filter((school) => school.name.toLowerCase().includes(e.target.value.toLowerCase())))
        }
    }
    return (

        <div align="center">
            <h1> Edit Variables </h1>
            <div className="shadow col-lg-10 col-11 p-4" style={{ backgroundColor: "#FDFBFB" }} align="center">
                <h3 className='mb-4'>List of Schools</h3>
                <Form.Control
                    placeholder="Search School"
                    className="mb-2"
                    onChange={searchSchool}
                />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>School Name</th>
                            <th>Edit School Info</th>
                            <th>Edit School Variables</th>
                            <th>Edit Users</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterSchools.map((school, index) => {
                            return (
                                <>
                                    <Modal show={school.isOpened} onHide={(e) => { setFilterSchools(filterSchools.map(sc => (school._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>School Information</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }} align="center">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <img src={school.logo} alt="School Logo" width="150" height="150" />
                                                    </div>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Name - </b> {school.name}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Conventional ID - </b> {school.conventional_id}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Address - </b> {school.address}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Phone No. - </b> {school.Contact_number}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Email - </b> {school.email}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Website - </b> {school.website}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Class Range - </b> {school.class_range_start} - {school.class_range_end}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                    <b>Section Range - </b> A - {String.fromCharCode(64 + parseInt(school.max_number_of_sections))}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Max. Number of Students per Section - </b> {school.students_per_section}
                                                    </a>
                                                </div>
                                                <div className="row" align="left">
                                                    <a>
                                                        <b>Subjects - </b> {school.subjects?.map((subject) => subject.name).join(", ")}
                                                    </a>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={(e) => { setFilterSchools(filterSchools.map(sc => (school._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <tr>
                                        <td>{index + 1}</td>
                                        <td><a onClick={(e) => { setFilterSchools(filterSchools.map(sc => (school._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>{school.name}</a></td>
                                        <td><Button variant="secondary" size="sm" onClick={() => handleSchoolEditInfo(school._id)}>Edit School Info</Button></td>
                                        <td><Button variant="secondary" size="sm" onClick={() => handleSchoolEditVariables(school._id)}>Edit School Variables</Button></td>
                                        <td><Button variant="secondary" size="sm" onClick={() => handleSchoolUsersEdit(school._id)}>Edit Users</Button></td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    );
}

export default EditSchool;