import React, { useState, useEffect } from "react";
import { Table, Button, Form } from "react-bootstrap";
import Api from "../../Api/axios";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MultiUserWatching from "../Library/MultiUserWatching";

const MultiUserLogin = () => {
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [usersLoggedIn, setUsersLoggedIn] = useState(JSON.parse(localStorage.getItem("usersLoggedIn")));
    const [loginShow, setLoginShow] = useState(false);
    const [error, setError] = useState("");
    const [userCreds, setUserCreds] = useState({
        email: "",
        password: "",
    });
    const login = async (userCreds) => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        var tempCredentials = {
            email: userCreds.email,
            password: userCreds.password,
        };
        if (tempCredentials.email.indexOf("@") === -1) {
            if (!tempCredentials.email.startsWith("SN")) {
                tempCredentials.email = "SN" + tempCredentials.email;
            }
        }
        await Api.post("/user/login", { user: tempCredentials }).then((res) => {
            if (res.status === 201) {
                if (res.data.status === 401) {
                    setError("Invalid Password");
                } else if (res.data.status === 404) {
                    setError("User not found");
                } else {
                    console.log(res)
                    let userAlreadyLoggedIn = false;
                    usersLoggedIn.forEach((user) => {
                        if (user.conventionalId === res.data.currentUser.conventionalId) {
                            userAlreadyLoggedIn = true;
                        }
                    });
                    if (userAlreadyLoggedIn) {
                        setError("User already logged in");
                        return;
                    }
                    setUsersLoggedIn([...usersLoggedIn, res.data.currentUser]);
                    setLoginShow(false);
                }
            } else {
                setError(res.data.message);
            }
        }).catch((err) => {
            setError(
                err?.response?.data?.message ||
                "Something went wrong! Please try again later"
            );
        });
        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    };
    useEffect(() => {
        console.log(usersLoggedIn);
        localStorage.setItem("usersLoggedIn", JSON.stringify(usersLoggedIn));
        console.log(JSON.parse(localStorage.getItem("usersLoggedIn")));
    }, [usersLoggedIn]);
    useEffect(() => {
        if (userDetails?.userType === "TEACHER") {
            setUsersLoggedIn(JSON.parse(localStorage.getItem("usersLoggedIn")));
        }
    }, [loginShow]);
    const [eyeOpen, setEyeOpen] = useState(false);
    if (loginShow) {
        if (userDetails?.userType === "TEACHER") {
            return (
                <MultiUserWatching
                    submitValueChange={setLoginShow}
                />
            )
        }
        return (
            <>
                {error && (
                    <div
                        className="alert alert-danger"
                        style={{
                            marginBottom: "10px",
                        }}
                    >
                        {error}
                    </div>
                )}
                <Form>
                    <Form.Group controlId="formBasicEmail"
                        style={{
                            marginBottom: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={userCreds.email}
                            onChange={(e) => {
                                setUserCreds({
                                    ...userCreds,
                                    email: e.target.value,
                                });
                            }}
                            style={{ backgroundColor: "#F1F1F1", border: "none", width: "100%" }}
                        />
                    </Form.Group>
                    {/* password with eye button */}
                    <Form.Group controlId="formBasicPassword"
                        style={{
                            marginBottom: "10px",
                            marginTop: "10px",
                        }}
                    >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type={eyeOpen ? "text" : "password"}
                            placeholder="Password"
                            value={userCreds.password}
                            onChange={(e) => {
                                setUserCreds({
                                    ...userCreds,
                                    password: e.target.value,
                                });
                            }}
                            style={{ backgroundColor: "#F1F1F1", border: "none", width: "100%", display: "inline" }}
                        />
                        {eyeOpen ? (
                            <VisibilityOffIcon onClick={() => setEyeOpen(false)} style={{ margin: '-40px', cursor: "pointer", width: "5%" }} />
                        ) : (
                            <VisibilityIcon onClick={() => setEyeOpen(true)} style={{ margin: '-40px', cursor: "pointer", width: "5%" }} />
                        )}
                    </Form.Group>
                    <center>
                        <Button
                            variant="primary"
                            type="submit"
                            onClick={(e) => {
                                e.preventDefault();
                                login(userCreds);
                            }}
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                marginRight: "10px",
                                width: "45%",
                            }}
                        >
                            Login
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setLoginShow(false);
                            }}
                            style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                                marginLeft: "10px",
                                width: "45%",
                            }}
                        >
                            Cancel
                        </Button>
                    </center>
                </Form>
            </>
        )
    } else {
        return (
            <>
                <Button
                    variant="primary"
                    style={{
                        marginBottom: "10px",
                        width: "100%",
                    }}
                    onClick={() => {
                        setLoginShow(true);
                    }}
                >
                    Add User
                </Button>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th
                                style={{
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                    borderRight: "1px solid #dee2e6",
                                    textAlign: "center",
                                }}
                            >
                                Sr. No.
                            </th>
                            <th
                                style={{
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                    borderRight: "1px solid #dee2e6",
                                    textAlign: "center",
                                }}
                            >
                                Name
                            </th>
                            <th
                                style={{
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                    borderRight: "1px solid #dee2e6",
                                    textAlign: "center",
                                }}
                            >
                                Conventonal Id
                            </th>
                            <th
                                style={{
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                    borderRight: "none",
                                    textAlign: "center",
                                }}
                            >
                                Remove
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersLoggedIn.map((user, index) => {
                            return (
                                <tr key={index}>
                                    <td
                                        style={{
                                            borderLeft: "none",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                            borderRight: "1px solid #dee2e6",
                                            textAlign: "center",
                                        }}
                                    >
                                        {index + 1}
                                    </td>
                                    <td
                                        style={{
                                            borderLeft: "none",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                            borderRight: "1px solid #dee2e6",
                                            textAlign: "center",
                                        }}
                                    >
                                        {user.name}
                                    </td>
                                    <td
                                        style={{
                                            borderLeft: "none",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                            borderRight: "1px solid #dee2e6",
                                            textAlign: "center",
                                        }}
                                    >
                                        {user.conventionalId}
                                    </td>
                                    <td
                                        style={{
                                            borderLeft: "none",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                            borderRight: "none",
                                            textAlign: "center",
                                        }}
                                    >
                                        <Button variant="danger" onClick={() => {
                                            const newUsersLoggedIn = usersLoggedIn.filter((userLoggedIn) => {
                                                return userLoggedIn.conventionalId !== user.conventionalId;
                                            });
                                            setUsersLoggedIn(newUsersLoggedIn);
                                        }}>
                                            Remove
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                        }
                    </tbody >
                </Table >
            </>
        )
    }
}

export default MultiUserLogin;