import { SearchOutlined } from '@material-ui/icons'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Api from '../../../../Api/axios'
import AssessmentTable from './components/AssessmentTable'

const AllAssessment = ({search,filterAssessment}) => {
const [AllAssessment,setAllAssessment]=useState([])

  useEffect(()=>{ 
      Api.get("/assessment/get-assessment").then((res)=>{
        let allAssessment=res.data
        setAllAssessment (()=>allAssessment)
      })
  },[])

  return (
    <div>
      {AllAssessment.length!==0&&
      <AssessmentTable showAll={true} datas={AllAssessment} search={search} filterAssessment={filterAssessment}/>
      }
    </div>
  )
}

export default AllAssessment