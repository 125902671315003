import { RiAdminLine } from "react-icons/ri";
import { BiLibrary } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { HiAcademicCap, HiDocumentAdd } from "react-icons/hi";
import { BsChatDots, BsPeople, BsCalendarDate, BsCash } from "react-icons/bs";
import {
	FaRegNewspaper,
	FaMoneyCheckAlt,
	FaCashRegister,
} from "react-icons/fa";
import { FcDocument } from "react-icons/fc";
import { GrDocumentText } from "react-icons/gr";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import EditIcon from '@mui/icons-material/Edit';
import PageviewIcon from '@mui/icons-material/Pageview';
import ArticleIcon from '@mui/icons-material/Article';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PostAddIcon from '@mui/icons-material/PostAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PanToolIcon from '@mui/icons-material/PanTool';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import PersonIcon from '@mui/icons-material/Person';
import AssessmentIcon from '@mui/icons-material/Assessment';

const reports_list = [
	{
		sub: "Sub1",
		overall_aggregate: 50,
		report_link: "#",
	},
	{
		sub: "Sub2",
		overall_aggregate: 50,
		report_link: "#",
	},
	{
		sub: "Sub3",
		overall_aggregate: 50,
		report_link: "#",
	},
	{
		sub: "Sub4",
		overall_aggregate: 60,
		report_link: "#",
	},
	{
		sub: "Sub5",
		overall_aggregate: 40,
		report_link: "#",
	},
];

const overAll_performance = [
	{
		name: "Attendance Aggregate",
		progress: 40,
	},
	{
		name: "Assessment Aggregate",
		progress: 70,
	},
	{
		name: "Quizzes Aggregate",
		progress: 60,
	},
];

// opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],

export const sidebarOptions = [
	// {
	// 	name: "Dashboard",
	// 	opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
	// 	route: "dashboard",
	// },
	{
		name: "My Profile",
		icon: <PersonIcon />,
		opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "PARENT", "STUDENT"],
		route: "dashboard",
	},
	{
		name: "District Dashboard",
		icon: <DashboardIcon />,
		opFor: ["DISTRICT"],
		route: "district-dashboard",
	},
	{
		name: "School Dashboard",
		icon: <DashboardIcon />,
		opFor: ["SCHOOL_ADMIN"],
		route: "school-dashboard",
	},
	{
		name: "Administrative Management",
		opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
		icon: <AdminPanelSettingsIcon />,
		options: [
			// 		{
			// 			name: "School Management",
			// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			// 			options: [
			// 				{
			// 					name: "Add School",
			// 					opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			// 					route: "add-school",
			// 				},
			// 				{
			// 					name: "Edit Schools",
			// 					opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			// 					route: "edit-school",
			// 				}
			// 			],
			// 		},
			// 		{
			// 			name: "User Management",
			// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			// 			options: [
			// 				{
			// 					name: "Add User",
			// 					opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			// 					route: "add-user",
			// 				},
			// 				// {
			// 				// 	name: "Edit/Show District Users",
			// 				// 	opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			// 				// 	route: "edit-school",
			// 				// },
			// 				{
			// 					name: "Edit/Show School Users",
			// 					opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			// 					route: "edit-school",
			// 				},
			// 				{
			// 					name: "Edit/Show Your School Users",
			// 					opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			// 					route: "edit-school-user",
			// 				},
			// 			]
			// 		},
			{
				name: "User Management",
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
				icon: <SupervisedUserCircleIcon />,
				options: [
					{
						name: "Add User",
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
						icon: <PersonAddIcon />,
						route: "add-user",
					},
					{
						name: "Manage Users",
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
						icon: <EditIcon />,
						route: "manage-users",
					},
					{
						name: "Mark RFID",
						opFor: ["INTERNAL"],
						icon: <EditIcon />,
						route: "mark-rfid",
					},
					{
						name: "Generate ID Card",
						opFor: ["INTERNAL"],
						icon: <EditIcon />,
						route: "generate-id-card",
					},
				]
			},
			{
				name: "Life Cycle Management",
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
				icon: <AutorenewIcon />,
				options: [
					{
						name: "Logs",
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
						icon: <ArticleIcon />,
						route: "lifecycle-logs",
					},
					{
						name: "Show Variables",
						opFor: ["CENTRAL", "DISTRICT"],
						icon: <PageviewIcon />,
						route: "show-variables",
					},
					{
						name: "Edit Variables",
						opFor: ["CENTRAL", "DISTRICT"],
						icon: <EditIcon />,
						route: "edit-school",
					},
					{
						name: "Edit Users",
						opFor: ["SCHOOL_ADMIN"],
						icon: <EditIcon />,
						route: "edit-school-user",
					},
				]
			},
			{
				name: "Document Management",
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "PARENT", "STUDENT"],
				icon: <TextSnippetIcon />,
				options: [
					{
						name: "Upload Document",
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
						icon: <FileUploadIcon />,
						route: "add-document",
					},
					{
						name: "Uploaded Documents",
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
						icon: <UploadFileIcon />,
						route: "show-uploaded-documents",
					},
					{
						name: "Show Your Documents",
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "PARENT", "STUDENT"],
						icon: <TextSnippetIcon />,
						route: "show-your-documents",
					},
					{
						name: "Show All Documents",
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
						icon: <TextSnippetIcon />,
						route: "show-documents",
					},
				],
			},
			{
				name: "Attendance Management",
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "PARENT", "STUDENT"],
				icon: <PanToolIcon />,
				options: [
					{
						name: "Mark Attendance",
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
						icon: <HowToRegIcon />,
						route: "mark-attendance",
					},
					{
						name: "View Your Attendance",
						opFor: ["SCHOOL_ADMIN", "TEACHER", "PARENT", "STUDENT"],
						icon: <PageviewIcon />,
						route: "view-user-attendance",
					},
					{
						name: "View Overall Attendance",
						opFor: ["TEACHER"],
						icon: <PageviewIcon />,
						route: "view-class-attendance",
					},
					{
						name: "View Overall Attendance",
						opFor: ["SCHOOL_ADMIN"],
						icon: <PageviewIcon />,
						route: "view-all-school-attendance",
					},
					{
						name: "View Overall Attendance",
						opFor: ["DISTRICT"],
						icon: <PageviewIcon />,
						route: "view-all-attendance",
					},

				]
			},
			{
				name: "Calendar",
				route: "calendar",
				icon: <BsCalendarDate />,
				// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			},
		],
	},
	{
		name: "Communication System",
		icon: <BsChatDots />,
		// opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
		opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
		options: [
			{
				name: "Notices Management System",
				icon: <FaRegNewspaper />,
				route: "notices",
				// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				options: [
					{
						name: "Add Notices",
						icon: <PostAddIcon />,
						route: "add-notice",
						// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
					}, {
						name: "View Notices",
						icon: <PageviewIcon />,
						route: "view-notices",
						// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
					}, {
						name: "View Sent Notices",
						icon: <PageviewIcon />,
						route: "view-uploaded-notices",
						// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
					}, {
						name: "View School Notices",
						icon: <PageviewIcon />,
						route: "view-school-notices",
						// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
						opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
					}
				]
			},
			{
				name: "Chat",
				icon: <BsChatDots />,
				route: "chat",
				// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			},
			{
				name: "Live Classes",
				icon: <VideoChatIcon />,
				route: "classes",
				// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				options: [
					{
						name: "Create Live Class",
						icon: <PostAddIcon />,
						route: "create-live-classes",
						// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
						opFor: ["CENTRAL", "SCHOOL_ADMIN", "TEACHER"],
					}, {
						name: "View Live Classes",
						icon: <PageviewIcon />,
						route: "view-live-classes",
						// opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
						opFor: ["CENTRAL", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
					}
				]
			},
		]
	},
	// {
	// 	name: "Assessment Management",
	// 	route: "assessment",
	// 	icon: <BsPeople />,
	// 	opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
	// 	options: [
	// 		{
	// 			name: "Create Assessment",
	// 			route: "assessment/create-assessment",
	// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
	// 		},
	// 		{
	// 			name: "Assessment Tracker",
	// 			route: "assessment/assessment-tracker",
	// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
	// 		},
	// 		{
	// 			name: "Assessment Library",
	// 			route: "assessment/assessment-library",
	// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
	// 		},
	// 		{
	// 			name: "Assessment Evaluator",
	// 			route: "assessment/assessment-evaluator",
	// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
	// 		},
	// 		{
	// 			name: "AuroScholar",
	// 			route: "assessment/auroscholar",
	// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
	// 		},
	// 	],
	// },
	{
		name: "Learning Management",
		icon: <BiLibrary />,
		opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
		options: [
			{
				name: "Course Management",
				opFor: [],
				options: [
					{
						name: "Add Course",
						route: "course/create-yie-course",
						opFor: [],
					},
					{
						name: "Add Chapter",
						route: "course/create-yie-chapter",
						opFor: [],
					},
					{
						name: "Add Content",
						route: "course/create-yie-content",
						opFor: [],
					},
				],
			},
			{
				name: "Library",
				route: "library",
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			},
			{
				name: "LMS Tracker",
				route: "lms-tracker",
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			}
		],
	},
	{
		name: "Assessment Management",
		route: "assessment",
		icon: <BsPeople />,
		// opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
		opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
		options: [
			{
				name: "Create Assessment",
				route: "assessment/create-assessment",
				// opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			},
			{
				name: "Assessment Tracker",
				route: "assessment/assessment-tracker",
				// opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			},
			{
				name: "Assessment Library",
				route: "assessment/assessment-library",
				// opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			},
			{
				name: "Assessment Evaluator",
				route: "assessment/assessment-evaluator",
				// opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
			},
			{
				name: "AuroScholar",
				route: "assessment/auroscholar",
				// opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
				opFor: ["STUDENT"],
			},
		],
	},
	{
		name: "Reporting and Analytics",
		// route: "attendance",
		icon: <AssessmentIcon />,
		// opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
		opFor: ["CENTRAL", "DISTRICT"],
		options: [
			{
				name: "Attendance",
				route: "attendance-report",
				// opFor: ["CENTRAL", "DISTRICT"],
				opFor: ["CENTRAL", "DISTRICT"],
			},
			{
				name: "Learning Management",
				route: "learning-management-report",
				// opFor: ["CENTRAL", "DISTRICT"],
				opFor: ["CENTRAL", "DISTRICT"],
			},
			{
				name: "Assessment Management",
				route: "assessment-report",
				opFor: ["CENTRAL", "DISTRICT"],
			},
		],
	},
	// {
	// 	name: "Survey Management",
	// 	route: "survey",
	// 	opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
	// 	options: [
	// 		{
	// 			name: "Create Survey",
	// 			route: "survey/create-survey",
	// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
	// 		},
	// 		{
	// 			name: "Assigned Survey",
	// 			route: "survey/assigned-survey",
	// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
	// 		},
	// 		{
	// 			name: "Survey Tracker",
	// 			route: "survey/survey-tracker",
	// 			opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
	// 		},
	// 	],
	// }

];
//         {
//           name: "List all Schools",
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//         {
//           name: "Add School",
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//         {
//           name: "Edit School",
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//           route: "edit-school",
//         },
//         {
//           name: "Manage School",
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//       ],
//     },
//   ]
// },
// {
//   name: "Administrative Tools",
//   icon: <RiAdminLine />,
//   // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER"],
//   opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//   options: [
//     {
//       name: "Life Cycle Management",
//       icon: <AiOutlineUser />,
//       // opFor: ["CENTRAL", "DISTRICT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//       options: [
//         {
//           name: "Show Variables",
//           route: "show-variables",
//           // opFor: ["CENTRAL", "DISTRICT"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//         {
//           name: "Edit Variables",
//           route: "edit-variables",
//           // opFor: ["CENTRAL", "DISTRICT"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//       ]
//     },
//     {
//       name: "School Management",
//       icon: <AiOutlineUser />,
//       // opFor: ["CENTRAL", "DISTRICT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//       options: [
//         {
//           name: "Add School",
//           route: "add-school",
//           // opFor: ["CENTRAL", "DISTRICT"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//         {
//           name: "Show/Edit School",
//           route: "show-edit-school",
//           // opFor: ["CENTRAL", "DISTRICT"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//       ],
//     },
//     {
//       name: "User Management",
//       icon: <AiOutlineUser />,
//       // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//       options: [
//         {
//           name: "Add Users",
//           // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//           options: [
//             {
//               name: "Add Central",
//               route: "add-central",
//               // opFor: ["CENTRAL", "DISTRICT"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Add District",
//               route: "add-district",
//               // opFor: ["CENTRAL", "DISTRICT"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Add School Admin",
//               route: "add-school-admin",
//               // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Add Teacher",
//               route: "add-teacher",
//               // opFor: ["SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Add Student",
//               route: "add-student",
//               // opFor: ["SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Add Parent",
//               route: "add-parent",
//               // opFor: ["SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//           ],
//         },
//         {
//           name: "Show Users",
//           // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//           options: [
//             {
//               name: "Show Centrals",
//               route: "show-central",
//               // opFor: ["CENTRAL"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Show Districts",
//               route: "show-district",
//               // opFor: ["CENTRAL"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Show School Admins",
//               route: "show-school-admin",
//               // opFor: ["CENTRAL", "DISTRICT"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Show Teachers",
//               route: "show-teacher",
//               // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Show Students",
//               route: "show-student",
//               // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Show Parents",
//               route: "show-parent",
//               // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//           ],
//         },
//         {
//           name: "Edit Users",
//           // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//           options: [
//             {
//               name: "Edit Centrals",
//               route: "edit-central",
//               // opFor: ["CENTRAL"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Edit Districts",
//               route: "edit-district",
//               // opFor: ["CENTRAL"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Edit School Admins",
//               route: "edit-school-admin",
//               // opFor: ["CENTRAL", "DISTRICT"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Edit Teachers",
//               route: "edit-teacher",
//               // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Edit Students",
//               route: "edit-student",
//               // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Edit Parents",
//               route: "edit-parent",
//               // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//           ],
//         },
//         {
//           name: "Show/Edit Users",
//           route: "show-edit-user",
//           // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//       ],
//     },
//     {
//       name: "Attendance",
//       route: "attendance",
//       icon: <BsPeople />,
//       // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//       options: [
//         {
//           name: "Update Attendance",
//           route: "attendance/update-attendance",
//           // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//         {
//           name: "Show Attendance",
//           route: "attendance",
//           // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//       ],
//     },
//     {
//       name: "Calendar",
//       route: "calendar",
//       icon: <BsCalendarDate />,
//       // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//     },
//     {
//       name: "Document Management",
//       route: "documents",
//       icon: <FaRegNewspaper />,
//       // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//       options: [
//         {
//           name: "Add Document",
//           route: "documents/add-document",
//           // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//         {
//           name: "Show Document",
//           // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//           options: [
//             {
//               name: "Show School Document",
//               route: "documents/show-school-documents",
//               // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Show Your Document",
//               route: "documents/show-your-document",
//               // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//           ],
//         },
//       ],
//     },
//     {
//       name: "Fee Management",
//       route: "fee-collection",
//       icon: <BsCash />,
//       // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//       options: [
//         {
//           name: "show fees",
//           icon: <FaMoneyCheckAlt />,
//           // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//           options: [
//             {
//               name: "Academic",
//               route: "fee-collection/Complete-Details",
//               // opFor: ["SCHOOL_ADMIN", "TEACHER"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Student",
//               route: "fee-collection/Complete-Details",
//               // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//           ],
//         },
//         {
//           name: "Add fees",
//           // route: "fee-collection/Details",
//           icon: <FaCashRegister />,
//           // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//           options: [
//             {
//               name: "Academic",
//               route: "fee-collection/Details",
//               // opFor: ["SCHOOL_ADMIN", "TEACHER"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//             {
//               name: "Student",
//               route: "fee-collection/Details",
//               // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//               opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//             },
//           ],
//         },
//       ],
//     },
//     {
//       name: "Class Management",
//       route: "fee-collection",
//       icon: <BsCash />,
//       // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//       options: [
//         {
//           name: "Create Class",
//           route: "create-class",
//           // opFor: ["CENTRAL", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//         {
//           name: "Create Subject",
//           route: "create-subject",
//           // opFor: ["CENTRAL", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//         {
//           name: "Show Classes",
//           route: "show-class",
//           // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN"],
//           opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//         },
//       ],
//     },
//   ],
// },

// {
//   name: "Communication System",
//   icon: <BsChatDots />,
//   // opFor: ["CENTRAL", "DISTRICT", "SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//   opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//   options: [
//     {
//       name: "Notices Management System",
//       icon: <FaRegNewspaper />,
//       route: "notices",
//       // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//     },
//     {
//       name: "Chat",
//       icon: <BsChatDots />,
//       route: "chat",
//       // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//     },
//     {
//       name: "Live Classes",
//       route: "live-classes",
//       // opFor: ["SCHOOL_ADMIN", "TEACHER", "STUDENT"],
//       opFor : ["CENTRAL","DISTRICT","SCHOOL_ADMIN","TEACHER","STUDENT"],
//     },
//   ],
// },
// ];

export const initialState = {
	name: "UIF Eschool",
	reports_list: reports_list,
	overAll_performance: overAll_performance,
	sidebarOptions: sidebarOptions,
	loading: false,
	lib_type: "SCHOOL_LIBRARY",
	userDetails: localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : {},
	token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
	loggedIn: localStorage.getItem("token") ? true : false
};

const reducer = (state, action) => {
	switch (action.type) {
		case "SET_TOKEN":
			return {
				...state,
				token: action.token,
			};
		case "SET_LOGIN_STATUS":
			return {
				...state,
				loggedIn: action.loggedIn,
			};
		case "SET_CLASSROOMS":
			return {
				...state,
				classrooms: action.classrooms,
			};
		case "SET_USER_DETAILS":
			return {
				...state,
				userDetails: action.userDetails,
			};
		case "SET_SIDEBAR_OPTION":
			return {
				...state,
				sidebarOptions: action.sidebarOptions,
			};
		case "SET_LOADING":
			return {
				...state,
				loading: action.loading,
			};
		case "SET_ERROR_MESSAGE":
			return {
				...state,
				errMess: action.errMess,
			};
		case "SET_CLASS_TEACHER_CLASS_DETAILS":
			return {
				...state,
				class_teacher_class_details: action.class_teacher_class_details,
			};
		case "SET_LIB_TYPE":
			return {
				...state,
				lib_type: action.lib_type,
			};

		default:
			return state;
	}
};

export default reducer;
