import { useEffect } from "react";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import Api from "../../../Api/axios";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Table from "react-bootstrap/Table";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const AssessmentLibrary = () => {
  const [createdAssessmentList, SetCreatedAssessmentList] = useState([]);
  const [querySearch, setQuerySearch] = useState("");
  const navigate = useNavigate();
  const [{ userDetails }] = useDataLayerValue();
  const [page, setPage] = useState("1");

  const questionBankHandler = () => {
    setPage("2");
  };

  const uploadedAssessmentHandler = () => {
    setPage("3");
  };

  const backbutton = () => {
    setPage("1");
  };

  const [schoolList, setSchoolList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [filterClasses, setFilterClasses] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [filterUserClasses, setFilterUserClasses] = useState([]);
  const [filterUserSubjects, setFilterUserSubjects] = useState([]);
  const [filterSchoolId, setFilterSchoolId] = useState("");
  const [questionBankData, setQuestionBankData] = useState([]);

  useEffect(() => {
    if (filterSchoolId) {
      Api.get("school-entity/all-classes?school_id=" + filterSchoolId).then(
        (res) => {
          setClassList(res.data);
        }
      );
    } else if (userDetails?.schoolId) {
      Api.get(
        "school-entity/all-classes?school_id=" + userDetails?.schoolId
      ).then((res) => {
        setClassList(res.data);
      });
    } else {
      setClassList([]);
    }
  }, [userDetails]);

  useEffect(() => {
    // if (!userDetails?.schoolId) {
    //   Api.get("school-entity/school").then((res) => {
    //     res.data.sort((a, b) => a.name.localeCompare(b.name));
    //     res.data = [{ _id: "", name: "All" }, ...res.data];
    //     setSchoolList(res.data);
    //   });
    // }
    Api.get("preconfig-entites/subjects").then((res) => {
      res.data.sort((a, b) => a.name.localeCompare(b.name));
      setSubjectList(res.data);
    });
  }, [userDetails]);

  useEffect(() => {}, [selectedClasses]);

  useEffect(() => {
    const classID = selectedClasses.map(({ _id }) => _id);
    setFilterClasses(classID);
    if (selectedClasses.length >= 1) {
      Api.post("/assessment/get-all-questions", {
        class: filterClasses,
      }).then((res) => console.log(res));
    }
  }, [selectedClasses]);

  console.log(
    classList.filter((x) => x._id === "37d65615-14f6-4b9d-82ed-7e39530c8964")
  );
  const [arr, setArr] = useState({
    items: [],
  });
  const SortableItem = SortableElement(({ value }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "10px",
        cursor: "pointer",
      }}
    >
      <img
        src={value}
        alt=""
        style={{ width: "300px", height: "90px", borderRadius: "10px" }}
      />
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul>
        {items.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} value={value} />
        ))}
      </ul>
    );
  });

  useEffect(() => {
    // if (!userDetails?.schoolId) {
    //   Api.get("school-entity/school").then((res) => {
    //     res.data.sort((a, b) => a.name.localeCompare(b.name));
    //     res.data = [{ _id: "", name: "All" }, ...res.data];
    //     setSchoolList(res.data);
    //   });
    // }
    Api.get("preconfig-entites/subjects").then((res) => {
      res.data.sort((a, b) => a.name.localeCompare(b.name));
      setSubjectList(res.data);
    });
  }, [userDetails]);

  useEffect(() => {
    if (filterSchoolId) {
      Api.get("school-entity/all-classes?school_id=" + filterSchoolId).then(
        (res) => {
          setClassList(res.data);
        }
      );
    } else if (userDetails?.schoolId) {
      Api.get(
        "school-entity/all-classes?school_id=" + userDetails?.schoolId
      ).then((res) => {
        setClassList(res.data);
      });
    } else {
      setClassList([]);
    }
  }, [userDetails]);

  useEffect(() => {
    Api.post(
      "/assessment/get-all-questions"
    ).then((res) => {
      setClassList(res.data);
    });
  });

  useEffect(() => {
    Api.get("/assessment/created-assessment").then((res) => {
      let AList = [];
      let fetchedData = res.data;
      console.log("fetch", fetchedData);
      fetchedData?.forEach((x) => {
        let obj = {};
        obj.assessmentId = x._id;
        obj.assessmenttitle = x.title;
        obj.assessmentDate = x.createdAt;
        obj.assessmentType = x.type;
        obj.assessmentTotalQuestions = x.total_questions;
        obj.assessmentUserType = x.user_type;
        obj.assessmentUserId = x.user_ids;
        AList.push(obj);
      });
      SetCreatedAssessmentList(AList);
    });
  }, [userDetails, navigate, querySearch]);

  return (
    <div
      className="ps-2 pe-2"
      style={{
        minHeight: "94vh",
        backgroundColor: "#FFF",
        paddingBottom: "40px",
      }}
      align="center"
    >
      {/*---------------------------------------------Assessment Library Home------------------------------------*/}
      <h1 className="p-4" style={{ fontWeight: "bold" }}>
        Assessment Library
      </h1>
      {page === "1" && (
        <div>
          <Button
            className="m-4"
            variant="secondary"
            size="lg"
            onClick={questionBankHandler}
          >
            Question Bank
          </Button>
          <Button
            className="m-4"
            variant="secondary"
            size="lg"
            onClick={uploadedAssessmentHandler}
          >
            View Uploaded Assessment
          </Button>
        </div>
      )}
      {/*---------------------------------------------Question Bank------------------------------------*/}
      {page === "2" && (
        <div>
          Question Bank
          <div align="left">
            <Button
              className="m-4"
              variant="secondary"
              size="sm"
              onClick={backbutton}
              ali
            >
              Go Back
            </Button>
          </div>
          {classList.length !== 0 ? (
            <Form.Group
              className="pt-2 pb-2 col-12 col-sm-6 col-lg-4"
              controlId="class"
            >
              <Form.Label>Class</Form.Label>
              {console.log(classList, classList.length)}
              <Multiselect
                showArrow
                options={classList}
                displayValue="class_name_section"
                groupBy="class_name"
                showCheckbox={true}
                selectedValues={selectedClasses}
                onSelect={(selectedList, selectedItem) => {
                  setSelectedClasses(selectedList);
                }}
                onRemove={(selectedList, selectedItem) => {
                  setSelectedClasses(selectedList);
                }}
                style={{
                  marginTop: "0",
                }}
              />
            </Form.Group>
          ) : null}
          {subjectList.length !== 0 ? (
            <Form.Group
              align="left"
              className="pt-2 pb-2 col-12 col-sm-6 col-lg-4"
              controlId="class"
            >
              <Form.Label>Subjects</Form.Label>
              <Multiselect
                showArrow
                options={subjectList}
                displayValue="name"
                showCheckbox={true}
                selectedValues={filterUserSubjects}
                onSelect={(selectedList, selectedItem) => {
                  setFilterUserSubjects(selectedList);
                }}
                onRemove={(selectedList, selectedItem) => {
                  setFilterUserSubjects(selectedList);
                }}
                style={{
                  marginTop: "0",
                }}
              />
            </Form.Group>
          ) : null}
          <div className="m-4">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th scope="col" style={{ maxWidth: "5px" }}>
                    Sl. No
                  </th>
                  <th scope="col" style={{ minWidth: "80px" }}>
                    Questions
                  </th>
                </tr>
              </thead>
              <tbody>
                {questionBankData.length < 1 && (
                  <tr>
                    <td></td>
                    <td>No questions are available in question bank</td>
                  </tr>
                )}
                {questionBankData !== 0 &&
                  questionBankData.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.description}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      )}
      {/*---------------------------------------------Uploaded Assessment------------------------------------*/}
      {page === "3" && (
        <div>
          Uploaded assessment
          <div align="left">
            <Button
              className="m-4"
              variant="secondary"
              size="sm"
              onClick={backbutton}
              ali
            >
              Go Back
            </Button>
          </div>
          <div className="m-4">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "80px" }}>
                  Assessment Type
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Assessment user type
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Assessment Name
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  No. of Questions
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {createdAssessmentList
                ?.filter((data) => {
                  if(data === ""){
                    return data;
                  } else if(data.assessmenttitle.toLowerCase().includes(querySearch.toLowerCase())){
                    return data;
                  }
                })
                .map((data) => {
                  return (
                    <tr>
                      <td>{data.assessmentType}</td>
                      <td>{data.assessmentUserType}</td>
                      <td>{data.assessmenttitle}</td>
                      <td>{data.assessmentTotalQuestions}</td>
                      <td
                        onClick={() => navigate(`${data.assessmentId}`)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        View
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentLibrary;
