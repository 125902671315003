import { Button, Grid, Stack, Table, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import EvaluatorQuestions from "./EvaluatorQuestions"
import { useEffect } from 'react'
import Api from '../../../Api/axios'
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

const SubmissionList = ({ assessmentName, ass_id, assDetails, SetSubmisionList }) => {//ass_id,assDetails,SetSubmisionList
	//   const{id,title}=assDetails
	// const [faketest,SetSubmisionList]=useState({})
	console.log("Ass submitted", assDetails, ass_id)
	const [evaluvatedDetails, setEvaluvatedDetails] = useState({})

	useEffect(() => {
		let usersEvaluations = {}
		assDetails.map((x, k) => {
			async function fetchUserAnswer() {
				await Api.get(`/assessment/submission/get-user-submission?assessment_id=${ass_id}&user_id=${x._id}`).then((res) => {
					// console.log(res)
					if (res.data.is_evaluated) {
						// let obj={userId:x._id,evaluated:res.is_evaluated}
						// usersEvaluations.push(obj)
						usersEvaluations[x._id] = true
					} else {
						// let obj={userId:x._id,evaluated:false}
						// usersEvaluations.push(obj)
						usersEvaluations[x._id] = false
					}
					if (k === assDetails.length - 1) {
						setEvaluvatedDetails(() => usersEvaluations)
						console.log("usersEvaluations ==> ", usersEvaluations)
					}

				})
			}
			fetchUserAnswer()
		})

	}, [assDetails])
	const [{ userDetails }, dispatch] = useDataLayerValue();
	const [usersInfo, setUsersInfo] = useState([])
	const [QuestionsWithUser, setQuestionsWithUser] = useState({
		status: false,
		data: {},
		userid: ""
	})
	useEffect(() => {
		let usersDetails = []
		assDetails?.map((x, k) => {
			async function getUserdetails() {
				await Api.get(`/user/get-user-by-id/?id=${x._id}`).then(async (res) => {
					console.log(k + 1, "user data", res.data)
					let userId = res.data._id
					let schoolName = res.data.schoolName
					if (res.data.userType !== "SCHOOL_ADMIN") {
						let userClasslist = [...res.data.classes]
						console.log("if if if iof ");
						await Api.get(`/school-entity/all-classes?school_id=${res.data.schoolId}`).then((res) => {
							let schoolList = [...res.data]
							console.log(k + 1, "user school data", res.data)
							for (let i = 0; i < schoolList.length; i++) {
								for (let j = 0; j < userClasslist.length; j++) {
									if (schoolList[i]._id === userClasslist[j]) {
										if (usersDetails[k]) {
											usersDetails[k] = { ...usersDetails[k], class_name_section: [...usersDetails[k].class_name_section, schoolList[i].class_name_section] }
										} else {
											let obj = {}
											obj.userId = userId
											obj.class_name_section = [schoolList[i].class_name_section]
											obj.schoolName = schoolName
											usersDetails[k] = obj
										}
									}
								}
								if (usersDetails[k] && userClasslist.length === usersDetails[k].class_name_section.length) {
									break
								}
							}
							if (assDetails.length === usersDetails.length) {
								console.log("updated usersDetails =====>", k + 1, usersDetails)
								setUsersInfo(() => usersDetails)
							} else {
								console.log("not updated usersDetails =====>", k + 1, usersDetails)
							}

						})
					} else {
						console.log("else else else ");
						let obj = {}
						obj.userId = userId
						obj.class_name_section = null
						obj.schoolName = schoolName
						usersDetails[k] = obj
						if (assDetails.length === usersDetails.length) {
							console.log("updated usersDetails =====>", k + 1, usersDetails)
							setUsersInfo(() => usersDetails)
						}
					}

				})

			}
			getUserdetails()
			// return 
		})
	}, [assDetails])
	const [quesInfo, setQuesInfo] = useState()
	function evaluateUserAnswer(e) {
		let userId = e.target.value
		// console.log("ass_id",ass_id,"userId",userId)// axoios not fetching
		Api.get(`/assessment/created-assessment`).then((res) => {
			console.log("assessment created", res.data)
			let fetchedAssessment = [...res.data]
			let questionDetails
			fetchedAssessment.forEach((x) => {
				if (x._id === ass_id) {
					questionDetails = x.questions
					return
				}
			})
			setQuesInfo(() => questionDetails)
		})

		Api.get(`/assessment/submission/get-user-submission?assessment_id=${ass_id}&user_id=${userId}`).then((res) => {
			console.log("user submissiom data", res.data)
			setQuestionsWithUser(() => {
				return {
					status: !QuestionsWithUser.status,
					data: res.data,
					userid: userId
				}
			})
		}).catch((err) => {
			console.log("sub err", err);
		})
	}
	function classSplitUp(classArr) {
		let reduced = classArr?.reduce((final, x) => final + x + ", ", "").trim()
		reduced = reduced?.slice(0, reduced.length - 1)
		if (reduced?.length >= 13) {
			reduced = reduced.slice(0, 10) + "..."
		}
		return reduced
		// usersInfo[i]?.class_name_section.reduce((final,x)=>final+x+",","").trim().slice(0,st.length-1)}
	}

	return (
		<div >
			{!QuestionsWithUser.status &&
				<Grid container spacing={1} className="p-5">
					<Grid xs={12} md={12} sm={12} lg={12} item>
						<Typography variant='h4' align='center' className='mt-3'>Assessment name</Typography>
						<Typography variant='h6' align='center' className='mt-3'>{assessmentName}</Typography>
					</Grid>
					<Grid item xs={12} md={12} sm={12} lg={12}>{/*className='mt-3 d-flex align-items-center justify-content-center' */}
						{/* <div className="shadow-lg p-4 mb-4 w-70" style={{ borderRadius: 10, backgroundColor:"yellow" }} align="center"> */}
						<Grid container>
							<Grid item xs={12} md={12} sm={12} lg={12}>
								<Stack direction="row" >
									<div className="col-12 col-sm-6" style={{ display: 'flex' }}>
										<Button variant="light" style={{ backgroundColor: "#0d6efd", color: "white", fontWeight: "bold" }} onClick={(e) => {
											// setPageNo(1)
											// setSectionIds(new Set())
											SetSubmisionList(() => {
												return {
													status: false,
													data: {}
												}
											})
										}} >
											← Previous
										</Button>
									</div>
									{/* <div className="col-12 col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }}   >
													Next
												</Button>
											</div> */}
								</Stack>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12} md={12} sm={12} lg={12}  >
								<h2 align={"center"} className="mt-4">List of Submissions</h2>
								<Table className="mt-4 mb-4 w-100" >
									<thead>
										<tr>
											<td><b>Sr. No.</b></td>
											<td><b>Name</b></td>
											<td><b>Submission Date</b></td>
											{userDetails?.userType === "DISTRICT" &&
												<td><b>School name</b></td>
											}
											<td><b>Section</b></td>
											<td><b>Action</b></td>
										</tr>
									</thead>
									<tbody >
										{assDetails.map((user, i) => {
											return (
												<tr key={i}>
													<td>{i + 1}</td>
													<td>{user.name}</td>
													<td>{user.submissionDate || "20-12-2022"}</td>
													{userDetails?.userType === "DISTRICT" &&
														<td>{usersInfo[i]?.schoolName}</td>
													}
													<td>{usersInfo[i] &&
														((usersInfo[i]?.class_name_section?.length === 0) || (usersInfo[i]?.class_name_section === null)) ? "No Section Found" :
														`${classSplitUp(usersInfo[i]?.class_name_section)}`
													}
														{/* // usersInfo[i]?.class_name_section.reduce((final,x)=>final+x+",","").trim().slice(0,st.length-1)} */}
													</td>
													<td>
														<Button
															onClick={(e) => evaluateUserAnswer(e)}
															// 	setQuestionsWithUser((pre)=>{
															// 	let updated={...pre}
															// 	updated.status=!pre.status
															// 	if(updated.status){
															// 		updated.data=user
															// 	}else{
															// 		updated.data=[]
															// 	}
															// 	return updated
															// })
															// disabled={evaluvatedDetails[user._id]}
															value={user._id}
															variant='contained'>{evaluvatedDetails[user._id] ? "Evaluated" : "Evaluate"}</Button>

													</td>
												</tr>
											)
										})}
									</tbody>

								</Table>
								{assDetails?.length === 0 &&
									<div align={"center"} className='mt-3 w-100'>No submission </div>
								}
							</Grid>
						</Grid>


						{/* </div> */}

					</Grid>

				</Grid>
			}

			{QuestionsWithUser.status && <EvaluatorQuestions setEvaluvatedDetails={setEvaluvatedDetails} quesInfo={quesInfo} setQuestionsWithUser={setQuestionsWithUser} userId={QuestionsWithUser.userid} UserQuestionsWithAnswer={QuestionsWithUser.data} />
			}



		</div>
	)
}

export default SubmissionList