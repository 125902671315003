import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { Box, MenuItem, Multiselect } from "@mui/material";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useLocation } from 'react-router-dom';

const OverallClassAttendance = () => {
    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();
    let location = useLocation();
    // timestamp 200 days ago
    // const [startDate, setStartDate] =
    // start date as 200 days before today as timestamp
    // const [startDate, setStartDate] = useState(+new Date();
    // const [endDate, setEndDate] = useState(new Date());
    // const [attendanceData, setAttendanceData] = useState([]);
    // const [actualData, setActualData] = useState([]);
    // const [classFilter, setClassFilter] = useState('ALL');
    // const [schoolName, setSchoolName] = useState('');
    // const [classes, setClasses] = useState({});
    // setSchoolName(location.school_name);
    // const school_id = location.school_id;
    const [schoolName, setSchoolName] = useState(location.state.school_name);
    const [school_filter, setSchoolFilter] = useState(location.state.school_id);
    const [allClasses, setAllClasses] = useState({});
    const [classAttendance, setClassAttendance] = useState([]);
    const [actualAttendance, setActualAttendance] = useState([]);
    const [fromDate, setFromDate] = useState(location.state.from_date);
    const [toDate, setToDate] = useState(location.state.to_date);
    const [classFilter, setClassFilter] = useState('ALL');
    // useEffect(async () => {
    // }, [userDetails]);



    useEffect(async () => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });

        let temp = {}
        await Api.get("live-class/get-school-classes", { params: { school_id: location.state.school_id } }).then((res) => {
            console.log(res.data, 'res');
            res.data.map((item) => {
                temp[item._id] = item.class_name_section
            })
            // order temp by value
            setAllClasses(temp);
        })


        let data = {
            start_date: fromDate,
            end_date: toDate,
            attendance_filters: {
                school_id: location.state.school_id,
                class_ids: Object.keys(temp),
            }
        }
        await Api.post("attendance/class-attendance", data).then((res) => {
            setClassAttendance(res.data);
            setActualAttendance(res.data);
        }).finally(() => {

            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        });


    }, [userDetails]);

    console.log(classAttendance, 'classAttendance');
    console.log(allClasses, 'allClasses');


    const handleClassFilter = (e) => {
        if (e.target.value == 'ALL') {
            setClassAttendance(actualAttendance);

        }
        else {
            setClassAttendance(actualAttendance.filter((item) => item.class_id == e.target.value));
        }
        setClassFilter(e.target.value);
    }


    return (
        <div className='ps-2 pe-2' style={{
            // width: '100%',
            minHeight: '94vh',
            backgroundColor: '#FFF',
            paddingBottom: '40px'
        }}
            align="center"
        >
            <h1 className="p-4" style={{ fontWeight: "bold" }}>{location.state.school_name} Attendance</h1>


            {userDetails.userType === 'DISTRICT' ?
                <InputGroup className="p-2">
                    <InputGroup.Text>Select Class</InputGroup.Text>
                    <Form.Control
                        as="select"
                        label="Select Class"
                        variant="filled"
                        style={{ minWidth: 230 }}
                        value={classFilter}
                        onChange={handleClassFilter}
                    >
                        <option key="ALL" value="ALL" style={{ width: "100%" }}>
                            ALL
                        </option>

                        {
                            Object.keys(allClasses).map((cl, i) => (
                                <option key={cl} value={cl} style={{ width: "100%" }}>
                                    {allClasses[`${cl}`]}
                                </option>
                            ))
                        }

                    </Form.Control>
                </InputGroup>
                :
                null}
            {/* <div>
				<TextField
					select
					label="Notice By"
					variant="filled"
					style={{ minWidth: 230 }}
					className="m-2"
					value={ownerUserType}
					onChange={handleOwnerUserTypeChange}
				>
					<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
						ALL
					</MenuItem>
					{
						userTypes.map((userType, i) => {
							return (
								<MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
									{userType[1]}
								</MenuItem>
							)
						})
					}
				</TextField>
				{(userDetails?.userType === "TEACHER") &&
					<>
						<TextField
							select
							label="For Class"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={className}
							onChange={handleClassChange}
						>
							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
								ALL
							</MenuItem>
							{
								classDict.map((cl, i) => {
									return (
										<MenuItem key={cl._id} value={cl._class_name_section} style={{ width: "100%" }}>
											{cl.class_name_section}
										</MenuItem>
									)
								})
							}
						</TextField> */}
            {/* <TextField
							select
							label="Section"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={sectionName}
							onChange={(e) => setSectionName(e.target.value)}
						>
							{Object.keys(sectionDict).map((sectionVar, i) => {
								return (
									<MenuItem key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
										{sectionVar}
									</MenuItem>
								)
							})
							}
						</TextField>
						<TextField
							select
							label="Subject"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={subjectName}
							onChange={handleSubjectChange}
						>
							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
								ALL
							</MenuItem>
							{subjectDict.map((subjectDictVar, i) => {
								// console.log(classDict[className][sectionName])
								if (className == "ALL" || (classDict[className][sectionName] && classDict[className][sectionName][0] === subjectDictVar.classroom_id)) {
									return (
										<MenuItem key={subjectDictVar._id} value={subjectDictVar._id} style={{ width: "100%" }}>
											{subjectDictVar.name}
										</MenuItem>
									)
								}
							})
							}
						</TextField> */}
            {/* </>
				}
			</div> */}
            {/* <Button className="m-3" variant="secondary" onClick={filterChange} style={{ minWidth: "100px" }}>
				Filter
			</Button> */}
            <div className="m-4">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: "80px" }}>Sr. No.</th>
                            <th scope="col" style={{ minWidth: "80px" }}>From Date</th>
                            <th scope="col" style={{ minWidth: "80px" }}>To date</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Date</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Total Duration In Days</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Class Name Section</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Type</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Total Present</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Total Attendance Taken</th>
                        </tr>
                    </thead>
                    <tbody>

                        {/* {console.log(classes, 'cl')} */}
                        {/* {setFileC(0)} */}
                        {classAttendance.map((attendanceDataVar, i) => {
                            return (
                                // onClick={Navigate('/overall-class-attendance', { schoolid: attendanceDataVar.school_id, school_name: schools[attendanceDataVar.school_id] })}
                                <tr key={i + 1} >
                                    <td>{i + 1}</td>
                                    <td>{new Date(attendanceDataVar.start_date).toDateString()}</td>
                                    <td>{new Date(attendanceDataVar.end_date).toDateString()}</td>
                                    <td>{new Date(attendanceDataVar.date).toDateString()}</td>
                                    <td>{attendanceDataVar.total_duration_in_days}</td>
                                    <td>{allClasses[attendanceDataVar.class_id]}</td>
                                    <td>{attendanceDataVar.user_type}</td>
                                    <td>{attendanceDataVar.total_present}</td>
                                    <td>{attendanceDataVar.total_attendance}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </Table>
            </div>
        </div >
    )
}

export default OverallClassAttendance