import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Table } from "react-bootstrap";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Pie from "./Pie";
import { useParams } from "react-router";
import NotFound from "../NotFound/NotFound";

const SchoolLevelAttendanceView = (data) => {
    const urlParams = useParams();
    const [dateRange, setDateRange] = useState([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    const [dateRangeType, setDateRangeType] = useState("TODAY");
    const [dateRangeTypes, setDateRangeTypes] = useState([
        {
            value: "TODAY",
            text: "Today",
        },
        {
            value: "YESTERDAY",
            text: "Yesterday",
        },
        {
            value: "LAST_7_DAYS",
            text: "Last 7 Days",
        },
        {
            value: "LAST_30_DAYS",
            text: "Last 30 Days",
        },
        {
            value: "CUSTOM",
            text: "Custom",
        },
    ]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [schoolList, setSchoolList] = useState([]);
    const [attendanceList, setAttendanceList] = useState({});
    const [schoolWiseInfo, setSchoolWiseInfo] = useState({});
    const [schoolWisePercentage, setSchoolWisePercentage] = useState({});
    const [overallPercent, setOverallPercent] = useState({});
    const [userTypes, setUserTypes] = useState([
        {
            value: "STUDENT",
            text: "Student",
        },
        {
            value: "TEACHER",
            text: "Teacher",
        },
    ]);
    const [userType, setUserType] = useState("STUDENT");
    const [userList, setUserList] = useState([]);
    const [usersAttendance, setUsersAttendance] = useState({});
    const [maxWorkingDays, setMaxWorkingDays] = useState(0);
    const [schoolId, setSchoolId] = useState(urlParams.id ? urlParams.id : (data.school_id ? data.school_id : (userDetails.schoolId ? userDetails.schoolId : "")));
    const [classList, setClassList] = useState([]);
    useEffect(() => {
        if (userDetails.schoolId) {
            Api.get("/school-entity/school").then((res) => {
                setSchoolList([res.data]);
                setSchoolId(res.data._id);
            });
        } else {
            Api.get("/school-entity/school").then((res) => {
                if (schoolId) {
                    setSchoolList(res.data.filter((school) => school._id === schoolId));
                    if (res.data.filter((school) => school._id === schoolId).length === 0) {
                        setSchoolId(undefined);
                    }
                } else {
                    setSchoolList([]);
                    setSchoolId(undefined);
                }
            });
        }
    }, [userDetails]);
    useEffect(() => {
        Api.get("/school-entity/all-classes", {
            params: {
                school_id: schoolId,
            },
        }).then((res) => {
            setClassList(res.data);
        });
    }, [schoolId]);
    useEffect(() => {
        console.log("schools", schoolList);
        const attendance_list = {};
        const school_wise_info = {};
        Api.post("/attendance/school-attendance", {
            start_date: dateRange[0].getTime(),
            end_date: dateRange[1].getTime(),
            attendance_filters: {
                school_id: schoolList.map((school) => school._id),
            },
        }).then((res) => {
            res.data.map((attendance) => {
                if (attendance.school_id in attendance_list) {
                    if (attendance.class_id in attendance_list[attendance.school_id]) {
                        attendance_list[attendance.school_id][attendance.class_id].push(attendance);
                    } else {
                        attendance_list[attendance.school_id][attendance.class_id] = [attendance];
                    }
                } else {
                    attendance_list[attendance.school_id] = {};
                    attendance_list[attendance.school_id][attendance.class_id] = [attendance];
                }
                if (attendance.school_id in school_wise_info) {
                    school_wise_info[attendance.school_id]["max_days_marked"] = Math.max(school_wise_info[attendance.school_id]["max_days_marked"], attendance_list[attendance.school_id][attendance.class_id].length);
                } else {
                    school_wise_info[attendance.school_id] = {};
                    school_wise_info[attendance.school_id]["max_days_marked"] = attendance_list[attendance.school_id][attendance.class_id].length;
                }
            })
        }).finally(() => {
            const school_wise_percent = {};
            const overall_percent = {};
            Object.keys(school_wise_info).map((school_id) => {
                if (!(school_id in school_wise_percent)) {
                    school_wise_percent[school_id] = {};
                }
                Object.keys(attendance_list[school_id]).map((class_id) => {
                    if (!(class_id in school_wise_percent[school_id])) {
                        school_wise_percent[school_id][class_id] = {};
                    }
                    attendance_list[school_id][class_id].map((attendance) => {
                        if (school_wise_percent[school_id][class_id]["Present"] === undefined) {
                            school_wise_percent[school_id][class_id]["Present"] = attendance.total_present;
                        } else {
                            school_wise_percent[school_id][class_id]["Present"] += attendance.total_present;
                        }
                        if (school_wise_percent[school_id][class_id]["Count"] === undefined) {
                            school_wise_percent[school_id][class_id]["Count"] = 1;
                        } else {
                            school_wise_percent[school_id][class_id]["Count"] += 1;
                        }
                        school_wise_percent[school_id][class_id]["Attendance"] = attendance.total_attendance;
                    });
                });
            });
            Object.keys(school_wise_info).map((school_id) => {
                Object.keys(attendance_list[school_id]).map((class_id) => {
                    school_wise_percent[school_id][class_id]["Average Present"] = (school_wise_percent[school_id][class_id]["Present"] / school_wise_percent[school_id][class_id]["Count"]);
                    school_wise_percent[school_id][class_id]["Average Attendance"] = ((school_wise_percent[school_id][class_id]["Average Present"] / school_wise_percent[school_id][class_id]["Attendance"]) * 100);
                });
            });
            overall_percent["STUDENT"] = {};
            overall_percent["TEACHER"] = {};
            overall_percent["SCHOOL_ADMIN"] = {};
            Object.keys(school_wise_info).map((school_id) => {
                Object.keys(attendance_list[school_id]).map((class_id) => {
                    if (school_id === null || school_id === "null") {
                        overall_percent["SCHOOL_ADMIN"]["Present"] = school_wise_percent[school_id][class_id]["Average Present"];
                        overall_percent["SCHOOL_ADMIN"]["Attendance"] = school_wise_percent[school_id][class_id]["Attendance"];
                        overall_percent["SCHOOL_ADMIN"]["Average Attendance"] = (school_wise_percent[school_id][class_id]["Average Present"] / school_wise_percent[school_id][class_id]["Attendance"]);
                    } else {
                        if (class_id === null || class_id === "null") {
                            if (overall_percent["TEACHER"][school_id] === undefined) {
                                overall_percent["TEACHER"][school_id] = {};
                            }
                            overall_percent["TEACHER"][school_id]["Present"] = school_wise_percent[school_id][class_id]["Average Present"];
                            overall_percent["TEACHER"][school_id]["Attendance"] = school_wise_percent[school_id][class_id]["Attendance"];
                            overall_percent["TEACHER"][school_id]["Average Attendance"] = (school_wise_percent[school_id][class_id]["Average Present"] / school_wise_percent[school_id][class_id]["Attendance"]);
                        } else {
                            if (overall_percent["STUDENT"][school_id] === undefined) {
                                overall_percent["STUDENT"][school_id] = {};
                            }
                            if (overall_percent["STUDENT"][school_id]["Present"] === undefined) {
                                overall_percent["STUDENT"][school_id]["Present"] = school_wise_percent[school_id][class_id]["Average Present"];
                            } else {
                                overall_percent["STUDENT"][school_id]["Present"] += school_wise_percent[school_id][class_id]["Average Present"];
                            }
                            if (overall_percent["STUDENT"][school_id]["Attendance"] === undefined) {
                                overall_percent["STUDENT"][school_id]["Attendance"] = school_wise_percent[school_id][class_id]["Attendance"];
                            } else {
                                overall_percent["STUDENT"][school_id]["Attendance"] += school_wise_percent[school_id][class_id]["Attendance"];
                            }
                        }
                    }
                });
            });
            Object.keys(overall_percent["STUDENT"]).map((school_id) => {
                overall_percent["STUDENT"][school_id]["Average Attendance"] = (overall_percent["STUDENT"][school_id]["Present"] / overall_percent["STUDENT"][school_id]["Attendance"]);
            });
            overall_percent["STUDENT_OVERALL"] = {};
            overall_percent["TEACHER_OVERALL"] = {};
            Object.keys(overall_percent["STUDENT"]).map((school_id) => {
                if (overall_percent["STUDENT_OVERALL"]["Present"] === undefined) {
                    overall_percent["STUDENT_OVERALL"]["Present"] = overall_percent["STUDENT"][school_id]["Present"];
                } else {
                    overall_percent["STUDENT_OVERALL"]["Present"] += overall_percent["STUDENT"][school_id]["Present"];
                }
                if (overall_percent["STUDENT_OVERALL"]["Attendance"] === undefined) {
                    overall_percent["STUDENT_OVERALL"]["Attendance"] = overall_percent["STUDENT"][school_id]["Attendance"];
                } else {
                    overall_percent["STUDENT_OVERALL"]["Attendance"] += overall_percent["STUDENT"][school_id]["Attendance"];
                }
            });
            overall_percent["STUDENT_OVERALL"]["Average Attendance"] = (overall_percent["STUDENT_OVERALL"]["Present"] / overall_percent["STUDENT_OVERALL"]["Attendance"]);
            Object.keys(overall_percent["TEACHER"]).map((school_id) => {
                if (overall_percent["TEACHER_OVERALL"]["Present"] === undefined) {
                    overall_percent["TEACHER_OVERALL"]["Present"] = overall_percent["TEACHER"][school_id]["Present"];
                } else {
                    overall_percent["TEACHER_OVERALL"]["Present"] += overall_percent["TEACHER"][school_id]["Present"];
                }
                if (overall_percent["TEACHER_OVERALL"]["Attendance"] === undefined) {
                    overall_percent["TEACHER_OVERALL"]["Attendance"] = overall_percent["TEACHER"][school_id]["Attendance"];
                } else {
                    overall_percent["TEACHER_OVERALL"]["Attendance"] += overall_percent["TEACHER"][school_id]["Attendance"];
                }
            });
            overall_percent["TEACHER_OVERALL"]["Average Attendance"] = (overall_percent["TEACHER_OVERALL"]["Present"] / overall_percent["TEACHER_OVERALL"]["Attendance"]);
            setOverallPercent(overall_percent);
            setSchoolWisePercentage(school_wise_percent);
            setSchoolWiseInfo(school_wise_info);
            setAttendanceList(attendance_list);
            console.log(overall_percent, school_wise_percent, school_wise_info, attendance_list);
        });
    }, [dateRange, schoolList]);
    useEffect(() => {
        if (userType === "TEACHER") {
            let count = 0;
            let temp_dict = {};
            Api.get("/user/users-data", {
                params: {
                    userType: "TEACHER",
                    schoolId: schoolId,
                },
            }).then((res) => {
                setUserList(res.data);
            }).finally(() => {
                Api.post("/attendance/personal-of-user", {
                    start_date: dateRange[0].getTime(),
                    end_date: dateRange[1].getTime(),
                    filters: {
                        user_type: "TEACHER",
                        school_id: schoolId,
                    },
                }).then((res) => {
                    console.log(res.data);
                    Object.keys(res.data).map((i) => {
                        let list_of_attendance = res.data[i];
                        count = Math.max(count, list_of_attendance.length);
                        let present = 0;
                        let absent = 0;
                        list_of_attendance.map((j) => {
                            if (j["present"] === true) {
                                present++;
                            } else {
                                absent++;
                            }
                        });
                        temp_dict[i] = {
                            present: present,
                            absent: absent,
                            total: present + absent,
                            attendanceList: i,
                        };
                    });
                }).finally(() => {
                    console.log(temp_dict);
                    setUsersAttendance(temp_dict);
                    setMaxWorkingDays(count);
                });
            });
        }
    }, [userType, dateRange]);
    const [forceRender, setForceRender] = useState(0);
    useEffect(() => {
        setForceRender(forceRender + 1);
    }, [schoolWisePercentage]);
    return (
        <>
            {schoolId ? <div>
                <h1 className="pt-3" align="center" style={{ fontWeight: "bold" }}>Attendance View</h1>
                <Form className="row">
                    <Form.Group className="col-12" style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>School Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={schoolList[0]?.name + " - " + schoolList[0]?.conventional_id}
                            disabled={true}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="col-12" style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>Date Range</Form.Label>
                        <Form.Control as="select" value={dateRangeType} onChange={(e) => {
                            if (e.target.value === "TODAY") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "YESTERDAY") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)]);
                            } else if (e.target.value === "LAST_7_DAYS") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "LAST_30_DAYS") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (29 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "CUSTOM") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            }
                            setDateRangeType(e.target.value)
                        }}>
                            {dateRangeTypes.map((item) => {
                                return (
                                    <option value={item.value}>{item.text}</option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="col-6" style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[0].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                            setDateRangeType("CUSTOM")
                            let temp_time_array = [new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]]
                            if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                                setDateRange([new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]])
                            } else {
                                alert("Start Date cannot be greater than End Date.")
                            }
                        }} />
                    </Form.Group>
                    <Form.Group className="col-6" style={{
                        paddingLeft: "10px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[1].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                            setDateRangeType("CUSTOM")
                            let temp_time_array = [dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]
                            if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                                setDateRange([dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))])
                            } else {
                                alert("End Date cannot be less than Start Date.")
                            }
                        }} />
                    </Form.Group>
                </Form>
                <Stack className="row" gap={3} direction="horizontal" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}>
                        <Pie percentage={overallPercent["STUDENT"] ? (overallPercent["STUDENT"][schoolId] ? (overallPercent["STUDENT"][schoolId]["Average Attendance"] * 100).toFixed(2) : 0) : 0} colour="red" />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Student Attendance
                        </p>
                    </div>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}>
                        <Pie percentage={overallPercent["TEACHER"] ? (overallPercent["TEACHER"][schoolId] ? (overallPercent["TEACHER"][schoolId]["Average Attendance"] * 100).toFixed(2) : 0) : 0} colour="blue" />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Teacher Attendance
                        </p>
                    </div>
                </Stack>
                <Form className="row">
                    <Form.Group className="col-12" style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>User Type</Form.Label>
                        <Form.Control as="select" value={userType} onChange={(e) => {
                            setUserType(e.target.value)
                        }}>
                            {userTypes.map((item) => {
                                return (
                                    <option value={item.value}>{item.text}</option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                </Form>
                <div style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    {(userType === "STUDENT") ?
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Sr. No.</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Class Name</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Total Days</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Total Working Days</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{ dateRangeType==="TODAY" ? "Total Present" : "Average Present" }</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Total Users</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Attendance Percentage</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Detail View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {classList.map((item, index) => {
                                    // console.log(schoolWisePercentage,[schoolId]  ,schoolWisePercentage[schoolId][item._id], item._id, item)
                                    return (
                                        <tr>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{item.class_name_section}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{Math.floor((1 + dateRange[1].getTime() - dateRange[0].getTime()) / (1000 * 3600 * 24))}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{schoolWisePercentage[schoolId] ? (schoolWisePercentage[schoolId][item._id] ? schoolWisePercentage[schoolId][item._id]["Count"].toFixed(0) : 0) : 0}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{schoolWisePercentage[schoolId] ? (schoolWisePercentage[schoolId][item._id] ? schoolWisePercentage[schoolId][item._id]["Average Present"].toFixed(0) : 0) : 0}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{schoolWisePercentage[schoolId] ? (schoolWisePercentage[schoolId][item._id] ? schoolWisePercentage[schoolId][item._id]["Attendance"] : 0) : 0}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{schoolWisePercentage[schoolId] ? (schoolWisePercentage[schoolId][item._id] ? (schoolWisePercentage[schoolId][item._id]["Average Attendance"]).toFixed(0) : "0") : "0"} %</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}><a style={{
                                                textDecoration: "none",
                                            }} href={`/view-class-attendance/${schoolId}/${item._id}`}>View</a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        :
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>S.No</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>User Name</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Conventional ID</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Total Days</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Days Marked</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Days Present</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Attendance Percent</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Detail View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userList.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{item.name}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{item.conventionalId}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{Math.floor((1 + dateRange[1].getTime() - dateRange[0].getTime()) / (1000 * 3600 * 24))}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{maxWorkingDays}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{usersAttendance[item._id] ? usersAttendance[item._id]["present"] : 0}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{Math.floor(((usersAttendance[item._id] ? usersAttendance[item._id]["present"] : (maxWorkingDays ? 0 : 0)) / Math.max(maxWorkingDays, 1)) * 100)} %</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                    }}
                                                    href={`/view-user-attendance/${item._id}`}
                                                >
                                                    View
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                </div>
            </div>
                :
                <NotFound />
            }
        </>
    )
}

export default SchoolLevelAttendanceView;
