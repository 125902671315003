import React, { useEffect, useState, useRef } from "react";
import Api from "../../Api/axios";
import "./show.css";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Error from "../ErrorSuccess/Error";
import { MdDelete } from "react-icons/md";
import DeleteModal from "../DeleteModal/DeleteModal";
import { useReactToPrint } from "react-to-print";

const ShowDistricts = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [errMesssage, setErrMesssage] = useState();
  const [{ userDetails }, dispatch] = useDataLayerValue();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [idToBeDeleted, setIdToBeDeleted] = useState();
  const [allUsersFiltered, setAllUsersFiltered] = useState([]);
  const [filterData, setFilterData] = useState({});
  const componentRef = useRef();

  useEffect(() => {
    fetchDistricts();
  }, []);

  const fetchDistricts = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.get("/user/all-districts")
      .then((res) => {
        setAllUsers(res.data);
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      })
      .catch((err) => {
        setErrMesssage(err.response.message);
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      });
  };
  const openDeleteModal = (userId) => {
    setDeleteModalOpen(true);
    setIdToBeDeleted(userId);
  };
  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const deleteConfirmed = () => {
    setDeleteModalOpen(false);
    deleteUser();
  };
  const deleteUser = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.delete("/user", { params: { id: idToBeDeleted } })
      .then((res) => fetchDistricts())
      .catch((err) => console.log(err));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const changeFilterData = (e) => {
    const { id, value } = e.target;
    setFilterData({ ...filterData, [id]: value });
  };

  useEffect(() => {
    setFilteredUserDetails();
  }, [filterData, allUsers]);

  const setFilteredUserDetails = () => {
    const keys = Object.keys(filterData);
    let allEmpty = Object.values(filterData).every(
      (x) => x === null || x === "" || x === undefined
    );
    if (allEmpty || filterData === {}) {
      setAllUsersFiltered(allUsers);
    } else {
      let filteredUserDetailsTemp = allUsers.filter((attd) => {
        return keys.every((key) =>
          attd[key]
            ?.toLocaleLowerCase()
            .includes(filterData[key]?.toLocaleLowerCase())
        );
      });
      setAllUsersFiltered(filteredUserDetailsTemp);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="show-table-container">
      <div className="show-table-container-main">
        <button onClick={handlePrint}>Print User Details</button>
        <table className="table" ref={componentRef}>
          <thead>
            <tr>
              <th colSpan="5">Districts</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Name
                  <input
                    type="text"
                    id="name"
                    placeholder="eg: Ayan"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Email
                  <input
                    type="text"
                    id="email"
                    placeholder="eg: Ayan"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Username
                  <input
                    type="text"
                    id="username"
                    placeholder="eg: username"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>

              <td>
                <div className="ad-st-attnd-table-filter">
                  Contact Number
                  <input
                    type="text"
                    id="contactNumber"
                    placeholder="eg: 91XXXXXX01"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>

              <td>Delete</td>
            </tr>
            {allUsers?.map((user) => (
              <tr>
                <td>{user?.name}</td>
                <td>{user?.email}</td>
                <td>{user?.username}</td>
                <td>{user?.contactNumber}</td>
                <td>
                  <MdDelete
                    color="red"
                    style={{ cursor: "pointer" }}
                    onClick={() => openDeleteModal(user?._id)}
                  />
                </td>
              </tr>
            ))}
            {errMesssage && <Error message={errMesssage} />}
          </tbody>
        </table>
      </div>
      <DeleteModal
        open={deleteModalOpen}
        closeDeleteModal={closeDeleteModal}
        deleteConfirmed={deleteConfirmed}
        message={
          "Do you really want to delete this user ? This process cannot be undone."
        }
      />
    </div>
  );
};

export default ShowDistricts;
