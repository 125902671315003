import React, { useState, useEffect } from 'react';
import { Box, option } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Api from "../../../Api/axios";
import { MenuItem } from "@mui/material";
import Table from 'react-bootstrap/Table';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ListContainer, ListItem } from "./styles";
import { DragHandle } from "./partials/DragHandle";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";




const Course = () => {

    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();
    const [courseList, setCourseList] = useState([]);

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let data = {
            class_id: "37d65615-14f6-4b9d-82ed-7e39530c8964"
        }
        Api.post("/course/get-courses", data).then((response) => {
            setCourseList(response.data);
        }).finally(() => {

            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        })
    }, [userDetails]);

    const reorder = (list, startIndex, endIndex) => {
        console.log(startIndex, endIndex);
        const result = Array.from(list);

        const [removed] = result.splice(startIndex, 1);

        result.splice(endIndex, 0, removed);
        result.map((item, index) => {
            if (index === endIndex + 1) {
                return
            }
            item.seq_no = index + 1
        })
        console.log(result, 'res');
        return result;
    };


    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: grid * 2,
        margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "lightgreen" : "grey",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: grid,
        width: 250
    });

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            courseList,
            result.source.index,
            result.destination.index
        );


        setCourseList(items);
    }




    return (
        <>
            <center>
                <h1 className="m-4">Courses for 7 A</h1>

                <div>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}
                                >
                                    {courseList.map((item, index) => (
                                        <Draggable key={item._id} draggableId={item._id} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    {item.subject_name}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </center>
        </>

    );
};


export default Course;