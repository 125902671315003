import React, { useEffect, useState, useRef } from "react";
import Api from "../../Api/axios";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Error from "../ErrorSuccess/Error";
import { FaUserEdit } from "react-icons/fa";
import EditModal from "../EditModal/EditModal";
import { useReactToPrint } from "react-to-print";
import TextField from '@material-ui/core/TextField';
import { Box, MenuItem } from "@mui/material";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { Multiselect } from "multiselect-react-dropdown";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';



const ShowEditUser = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [errMesssage, setErrMesssage] = useState();
  const [allUsersFiltered, setAllUsersFiltered] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [idToBeEdited, setIdToBeEdited] = useState();
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userContactNo, setUserContactNo] = useState();

  const componentRef = useRef();

  useEffect(() => {
    fetchAllUsers();
  }, []);

  const fetchAllUsers = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.get(`/user/all-users-in-skl?id=${userDetails.schoolId}&type=PARENT`)
      .then((res) => {
        setAllUsers(res.data);
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      })
      .catch((err) => {
        setErrMesssage(err.response.message);
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      });
  };

  // update Contact No from EditModal
  const changeValue = (e) => {
    const { id, value } = e.target;
    setUserContactNo({ [id]: value });
  };

  const openEditModal = (userId, userName, userEmail, userContactNo) => {
    console.log(allUsers);
    setEditModalOpen(true);
    setUserName(userName);
    setUserEmail(userEmail);
    setUserContactNo(userContactNo);
    setIdToBeEdited(userId);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const editConfirmed = () => {
    setEditModalOpen(false);
    console.log(idToBeEdited);
    console.log(userContactNo);
    editUser();
  };
  const editUser = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    //console.log(userContactNo);
    await Api.put(
      `user?id=${idToBeEdited}`,
      { contactNumber: `${userContactNo.userContactNo}` },
      true
    )
      .then(() => fetchAllUsers())
      .catch((err) => console.log(err));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const changeFilterData = (e) => {
    const { id, value } = e.target;
    setFilterData({ ...filterData, [id]: value });
  };

  useEffect(() => {
    setFilteredUserDetails();
  }, [filterData, allUsers]);

  const setFilteredUserDetails = () => {
    const keys = Object.keys(filterData);
    let allEmpty = Object.values(filterData).every(
      (x) => x === null || x === "" || x === undefined || x === "ALL"
    );
    if (allEmpty || filterData === {}) {
      setAllUsersFiltered(allUsers);
    } else {
      let filteredUserDetailsTemp = allUsers.filter((attd) => {
        return keys.every((key) =>
          attd[key]
            ?.toLocaleLowerCase()
            .includes(filterData[key]?.toLocaleLowerCase())
        );
      });
      setAllUsersFiltered(filteredUserDetailsTemp);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const userTypes = [
    ['CENTRAL', 'Central'],
    ['DISTRICT', 'District'],
    ['SCHOOL_ADMIN', 'School Admin'],
    ['TEACHER', 'Teacher'],
    ['PARENT', 'Parent'],
    ['STUDENT', 'Student'],
  ]
  const currentUserTypeIndex = userTypes.findIndex((userType) => userType[0] === userDetails?.userType);
  const [filterUserType, setFilterUserType] = useState("ALL");
  return (
    <>
      <center>
        <h1 className="m-4">Show/Edit Users</h1>
      </center>
      <div className="m-4 flex">
        <Stack direction="horizontal">
          <div style={{ display: 'flex', width: '50%' }}>
            <TextField
              select
              label="Owner User Type"
              variant="filled"
              style={{ minWidth: 230 }}
              value={filterUserType}
              onChange={(e) => { setFilterUserType(e.target.value); setFilterData({ ...filterData, userType: e.target.value }); }}
            >
              <MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
                ALL
              </MenuItem>
              {
                userTypes.map((userType, i) => {
                  if (i > currentUserTypeIndex) {
                    return (
                      <MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
                        {userType[1]}
                      </MenuItem>
                    )
                  }
                })
              }
            </TextField>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
            <Button variant="secondary" onClick={handlePrint}>Print User Details</Button>
          </div>
        </Stack>
      </div>
      <div className="m-4">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>User Name</th>
              <th>Name</th>
              <th>User Type</th>
              <th>Email</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {allUsersFiltered.map((user, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{user.username}</td>
                <td>{user.name}</td>
                <td>{user.userType}</td>
                <td>{user.email}</td>
                <td>
                  <Button variant="secondary">
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    </>
  );
};

export default ShowEditUser;
