// import React, { useState } from "react";
// import TextField from '@material-ui/core/TextField';
// import { Box, MenuItem, Multiselect } from "@mui/material";
// import { useDataLayerValue } from "../../../DataLayer/DataLayer";
// import Api from "../../../Api/axios";
// import Button from 'react-bootstrap/Button';
// import Table from 'react-bootstrap/Table';
// import { useEffect } from "react";

// const ShowYourDocument = () => {
// 	const userTypes = [
// 		['DISTRICT', 'District'],
// 		['SCHOOL_ADMIN', 'School Admin'],
// 		['TEACHER', 'Teacher'],
// 		['PARENT', 'Parent'],
// 		['STUDENT', 'Student'],
// 	]
// 	const documentTypes = [
// 		["Academic", "Academic"],
// 		["Personal", "Personal"],
// 		["Administrative", "Administrative"],
// 		["Others", "Others"],
// 	]
// 	const [classDict, setClassDict] = useState({ "ALL": { "ALL": [] } });
// 	const [sectionDict, setSectionDict] = useState({ "ALL": [] });
// 	const [subjectDict, setSubjectDict] = useState([]);
// 	const [chapterDict, setChapterDict] = useState([]);
// 	const [topicDict, setTopicDict] = useState([]);
// 	const [subTopicDict, setSubTopicDict] = useState([]);
// 	const [userDict, setUserDict] = useState([]);


// 	const [documentType, setDocumentType] = useState("ALL");
// 	const [ownerUserType, setOwnerUserType] = useState("ALL");
// 	const [ownerUser, setOwnerUser] = useState("ALL");
// 	const [recipientUser, setRecipientUser] = useState("ALL");
// 	const [className, setClassName] = useState("ALL");
// 	const [subjectName, setSubjectName] = useState("ALL");
// 	const [chapterName, setChapterName] = useState("ALL");
// 	const [topicName, setTopicName] = useState("ALL");
// 	const [subTopicName, setSubTopicName] = useState("ALL");
// 	const [
// 		{ user, userDetails, initialState, loggedIn, class_teacher_class_details },
// 	] = useDataLayerValue();
// 	const currentUserTypeIndex = userTypes.findIndex((userType) => userType[0] === userDetails?.userType);
// 	const [files, setFiles] = useState([]);
// 	const [{ type }, dispatch] = useDataLayerValue();
// 	const [fileC, setFileC] = useState([]);
// 	const [sectionName, setSectionName] = useState("ALL");
// 	React.useEffect(async () => {
// 		dispatch({
// 			type: "SET_LOADING",
// 			loading: true,
// 		});
// 		setFiles([]);
// 		await Api.get("documents", { params: { document_for_user: [userDetails?._id], } }).then((res) => {
// 			setFiles(res.data);
// 		})
// 		dispatch({
// 			type: "SET_LOADING",
// 			loading: false,
// 		});
// 	}, []);
// 	React.useEffect(async () => {
// 		let temp_dict = { "ALL": { "ALL": [] } };
// 		await Api.get("live-class").then((res) => {
// 			res.data.forEach((classObj) => {
// 				// console.log(classObj);
// 				// console.log(classObj.class_name);
// 				let f = 0;
// 				for (var class_n in temp_dict) {
// 					if (class_n === classObj.class_name) {
// 						temp_dict[class_n]["ALL"] = [...temp_dict[class_n]["ALL"], classObj._id];
// 						temp_dict[class_n][classObj.class_section] = [classObj._id];
// 						f = 1;
// 						break;
// 					}
// 				}
// 				if (f === 0) {
// 					temp_dict[classObj.class_name] = {};
// 					temp_dict[classObj.class_name]["ALL"] = [classObj._id];
// 					temp_dict[classObj.class_name][classObj.class_section] = [classObj._id];
// 				}
// 				// console.log(classObj.class_section);
// 			})
// 			// console.log(temp_dict);
// 		}).finally(() => {
// 			setClassDict(temp_dict);
// 		})
// 	}, []);
// 	// React.useEffect(() => {
// 	React.useEffect(() => {
// 		Api.get("live-class/subject").then((res) => {
// 			setSubjectDict(res.data);
// 		})
// 	}, []);
// 	const handleDocumentTypeChange = (event) => {
// 		setDocumentType(event.target.value);
// 	};
// 	const handleOwnerUserTypeChange = (event) => {
// 		setOwnerUserType(event.target.value);
// 	};
// 	const handleClassChange = async (e) => {
// 		setClassName(e.target.value);
// 		setSubjectName("ALL");
// 		setSectionName("ALL");
// 		setSectionDict(classDict[e.target.value]);
// 	}
// 	const handleSubjectChange = async (e) => {
// 		setSubjectName(e.target.value);
// 	}
// 	const [change, setChange] = useState(true)
// 	useEffect(() => {
// 		console.log("change")
// 		dispatch({
// 			type: "SET_LOADING",
// 			loading: true,
// 		});
// 		const data = {
// 			document_for: classDict? classDict[className][sectionName] : "",
// 			type: documentType === "ALL" ? "" : documentType,
// 			subject_id: subjectName === "ALL" ? "" : subjectName,
// 			topic_id: topicName === "ALL" ? "" : topicName,
// 			subtopic_id: subTopicName === "ALL" ? "" : subTopicName,
// 			ownerType: ownerUserType === "ALL" ? "" : ownerUserType,
// 			chapter_id: chapterName === "ALL" ? "" : chapterName,
// 			document_for_user: [userDetails?._id],
// 		}
// 		Api.get("documents", { params: data }).then((res) => {
// 			setFiles(res.data);
// 			// console.log(res.data);
// 		}).finally((() => {
// 			dispatch({
// 				type: "SET_LOADING",
// 				loading: false,
// 			})
// 		}))
// 		}, [documentType,subjectName,topicName,subTopicName,chapterName,ownerUserType,className,sectionName])
// 	return (
// 		<div className='ps-2 pe-2' style={{
// 			// width: '100%',
// 			minHeight: '94vh',
// 			backgroundColor: '#FFF',
// 			paddingBottom: '40px'
// 		}}
// 			align="center"
// 		>
// 			<h1 className="p-4" style={{ fontWeight: "bold" }}>List of Your Documents</h1>
// 			<div>
// 				<TextField
// 					select
// 					label="Document Type"
// 					variant="filled"
// 					style={{ minWidth: 230 }}
// 					value={documentType}
// 					onChange={handleDocumentTypeChange}
// 					className="m-2"
// 				>
// 					<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
// 						ALL
// 					</MenuItem>
// 					{documentTypes.map((documentType) => (
// 						<MenuItem key={documentType[0]} value={documentType[0]} style={{ width: "100%" }}>
// 							{documentType[1]}
// 						</MenuItem>
// 					))}
// 				</TextField>
// 				<TextField
// 					select
// 					label="Document Owner Type"
// 					variant="filled"
// 					style={{ minWidth: 230 }}
// 					className="m-2"
// 					value={ownerUserType}
// 					onChange={handleOwnerUserTypeChange}
// 				>
// 					<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
// 						ALL
// 					</MenuItem>
// 					{
// 						userTypes.map((userType, i) => {
// 							return (
// 								<MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
// 									{userType[1]}
// 								</MenuItem>
// 							)
// 						})
// 					}
// 				</TextField>
// 				{(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") &&
// 					<>
// 						<TextField
// 							select
// 							label="Class"
// 							variant="filled"
// 							style={{ minWidth: 230 }}
// 							className="m-2"
// 							value={className}
// 							onChange={handleClassChange}
// 						>
// 							{Object.keys(classDict).map((sectionVar, i) => {
// 								return (
// 									<MenuItem key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
// 										{sectionVar}
// 									</MenuItem>
// 								)
// 							})}
// 						</TextField>
// 						<TextField
// 							select
// 							label="Section"
// 							variant="filled"
// 							style={{ minWidth: 230 }}
// 							className="m-2"
// 							value={sectionName}
// 							onChange={(e) => setSectionName(e.target.value)}
// 						>
// 							{Object.keys(sectionDict).map((sectionVar, i) => {
// 								return (
// 									<MenuItem key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
// 										{sectionVar}
// 									</MenuItem>
// 								)
// 							})
// 							}
// 						</TextField>
// 						<TextField
// 							select
// 							label="Subject"
// 							variant="filled"
// 							style={{ minWidth: 230 }}
// 							className="m-2"
// 							value={subjectName}
// 							onChange={handleSubjectChange}
// 						>
// 							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
// 								ALL
// 							</MenuItem>
// 							{subjectDict.map((subjectDictVar, i) => {
// 								// console.log(classDict[className][sectionName])
// 								if (className == "ALL" || (classDict[className][sectionName] && classDict[className][sectionName][0] === subjectDictVar.classroom_id)) {
// 									return (
// 										<MenuItem key={subjectDictVar._id} value={subjectDictVar._id} style={{ width: "100%" }}>
// 											{subjectDictVar.name}
// 										</MenuItem>
// 									)
// 								}
// 							})
// 							}
// 						</TextField>
// 					</>
// 				}
// 			</div>
// 			{/* <Button className="m-3" variant="secondary" onClick={filterChange} style={{ minWidth: "100px" }}>
// 				Filter
// 			</Button> */}
// 			<div className="m-4">
// 				<Table striped bordered hover responsive>
// 					<thead>
// 						<tr>
// 							<th scope="col" style={{ minWidth: "80px" }}>Sr. No.</th>
// 							<th scope="col" style={{ minWidth: "200px" }}>Document Name</th>
// 							<th scope="col" style={{ minWidth: "280px" }}>Document Description</th>
// 							<th scope="col" style={{ minWidth: "200px" }}>Document Type</th>
// 							<th scope="col" style={{ minWidth: "200px" }}>Owner User Name</th>
// 							<th scope="col" style={{ minWidth: "200px" }}>Owner User ID</th>
// 							{/* {console.log(userDetails?.userType , 	(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT"))} */}
// 							{(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") && <th scope="col" style={{ minWidth: "200px" }}>Class</th>}
// 							{(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") && <th scope="col" style={{ minWidth: "200px" }}>Section</th>}
// 							{(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") && <th scope="col" style={{ minWidth: "200px" }}>Subject</th>}
// 							<th scope="col" style={{ minWidth: "140px" }}>View</th>
// 						</tr>
// 					</thead>
// 					<tbody>
// 						{console.log(files)}
// 						{/* {setFileC(0)} */}
// 						{files.map((item, index) => {
// 							return (
// 								<tr key={index}>
// 									<td >{index + 1}</td>
// 									<td>{item.name}</td>
// 									<td>{item.description}</td>
// 									<td>{item.document_type}</td>
// 									<td>{item.created_by.name}</td>
// 									<td>{item.created_by.conventionalId}</td>
// 									{(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") && <td>{item.class_data_names ? item.class_data_names.map(name => { return name.split(' ')[0]; }).join(' ,') : "ALL"}</td>}
// 									{(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") && <td>{item.class_data_names ? item.class_data_names.map(name => { return name.split(' ')[1]; }).join(' ,') : "ALL"}</td>}
// 									{(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") && <td>{item.subject ? item.subject.name : ""}</td>}
// 									<td>
// 										<Button variant="light" onClick={() => window.open(item.link_arr[0], "_blank")}>
// 											View
// 										</Button>
// 									</td>
// 								</tr>
// 							)

// 						})}
// 					</tbody>
// 				</Table>
// 			</div>
// 		</div>

// 	)
// }

// export default ShowYourDocument

import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from '../../../Api/axios';
import { Multiselect } from "multiselect-react-dropdown";

const ShowYourDocument = () => {
	const [{ userDetails }, dispatch] = useDataLayerValue();
	const [documentTypes, setDocumentTypes] = useState([
		{
			id: "Academic",
			value: "Academic"
		},
		{
			id: "Personal",
			value: "Personal"
		},
		{
			id: "Administrative",
			value: "Administrative"
		},
		{
			id: "Others",
			value: "Others"
		},
	]);
	const [userTypes, setUserTypes] = useState([
		{
			id: "DISTRICT",
			value: "District"
		},
		{
			id: "SCHOOL_ADMIN",
			value: "School Admin"
		},
		{
			id: "TEACHER",
			value: "Teacher"
		},
		{
			id: "STUDENT",
			value: "Student"
		},
	]);
	const [schoolList, setSchoolList] = useState([]);
	const [classList, setClassList] = useState([]);
	const [subjectList, setSubjectList] = useState([]);
	const [filterDocumentType, setFilterDocumentType] = useState("");
	const [filterRecipientUserType, setFilterRecipientUserType] = useState("");
	const [filterOwnerUserType, setFilterOwnerUserType] = useState("");
	const [filterSchool, setFilterSchool] = useState("");
	const [filterClass, setFilterClass] = useState([]);
	const [filterSubject, setFilterSubject] = useState("");
	const [documentsList, setDocumentsList] = useState([]);
	useEffect(() => {
		Api.get('/school-entity/school').then((response) => {
			if (userDetails?.userType == "DISTRICT") {
				response.data.sort((a, b) => a.name.localeCompare(b.name));
				setSchoolList(response.data);
			} else {
				setSchoolList([response.data]);
				setFilterSchool(response.data._id);
			}
		});
		setFilterRecipientUserType(userDetails?.userType);
	}, [userDetails]);
	useEffect(() => {
		if (filterSchool !== "") {
			Api.get('school-entity/all-classes?school_id=' + filterSchool).then((response) => {
				setClassList(response.data);
				if (userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") {
					setFilterClass(response.data.filter((classItem) => {
						if (userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") {
							return userDetails?.classes?.map((classId) => {
								if (classId === classItem._id) {
									return true
								}
							}).filter((classId) => {
								if (classId) {
									return true
								}
							}).length > 0
						}
						return true
					}))
				}
			});
		} else {
			setClassList([]);
		}
	}, [filterSchool]);
	useEffect(() => {
		Api.get('preconfig-entites/subjects').then((response) => {
			response.data.sort((a, b) => a.name.localeCompare(b.name));
			setSubjectList(response.data);
		});
	}, []);
	useEffect(() => {
		dispatch({
			type: "SET_LOADING",
			loading: true,
		});
		let tempClass = filterClass;
		if (userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") {
			if (filterClass.length == 0) {
				tempClass = classList.filter((classItem) => {
					if (userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") {
						return userDetails?.classes?.map((classId) => {
							if (classId === classItem._id) {
								return true
							}
						}).filter((classId) => {
							if (classId) {
								return true
							}
						}).length > 0
					}
					return true
				})
			}
		}
		Api.get("documents", {
			params: {
				school_ids: filterSchool ? [filterSchool] : null,
				class_data_names: tempClass.length > 0 ? tempClass.map((classData) => classData.class_name_section) : null,
				subject_id: filterSubject ? filterSubject : null,
				ownerType: filterOwnerUserType ? filterOwnerUserType : null,
				type: filterDocumentType ? filterDocumentType : null,
				userType: filterRecipientUserType ? filterRecipientUserType : null,
				document_for_user: [userDetails?._id],
			}
		}).then((response) => {
			setDocumentsList(response.data);
			console.log(response.data);
		}).finally(() => {
			dispatch({
				type: "SET_LOADING",
				loading: false,
			});
		});
	}, [filterDocumentType, filterRecipientUserType, filterOwnerUserType, filterSchool, filterClass, filterSubject]);
	return (
		<div
			style={{
				backgroundColor: "white",
				width: "100%",
				height: "100%",
				padding: "10px",
			}}
		>
			<h1
				style={{
					textAlign: "center",
					marginTop: "10px",
					marginBottom: "20px",
					fontWeight: "bold",
				}}
			> Show Your Documents </h1>
			<Form
				style={{
					display: "flex",
					flexDirection: "row",
					flexWrap: "wrap",
					justifyContent: "center",
				}}
			>
				<Form.Group
					controlId="documentType"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>Document Type</Form.Label>
					<Form.Control
						as="select"
						value={filterDocumentType}
						onChange={(e) => setFilterDocumentType(e.target.value)}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
							minHeight: "55px",
						}}
					>
						<option value="">ALL</option>
						{documentTypes.map((documentType) => {
							return (
								<option value={documentType.id}> {documentType.value} </option>
							)
						})}
					</Form.Control>
				</Form.Group>
				<Form.Group
					controlId="ownerUserType"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>Owner User Type</Form.Label>
					<Form.Control
						as="select"
						value={filterOwnerUserType}
						onChange={(e) => setFilterOwnerUserType(e.target.value)}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
							minHeight: "55px",
						}}
					>
						<option value="">ALL</option>
						{userTypes.map((userType) => {
							return (
								<option value={userType.id}> {userType.value} </option>
							)
						})}
					</Form.Control>
				</Form.Group>
				<Form.Group
					controlId="recipientUserType"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>Recipient User Type</Form.Label>
					{/* <Multiselect
                        showArrow
                        labelledBy={"Select User Types"}
                        options={userTypes}
                        displayValue="value"
                        showCheckbox={true}
                        selectedValues={filterRecipientUserType}
                        onSelect={(selectedList, selectedItem) => { setFilterRecipientUserType(selectedList) }}
                        onRemove={(selectedList, selectedItem) => { setFilterRecipientUserType(selectedList) }}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                        }}
                    /> */}
					<Form.Control
						as="select"
						value={filterRecipientUserType}
						onChange={(e) => setFilterRecipientUserType(e.target.value)}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
							minHeight: "55px",
						}}
						disabled={true}
					>
						<option value="">ALL</option>
						{userTypes.map((userType) => {
							return (
								<option value={userType.id}> {userType.value} </option>
							)
						})}
					</Form.Control>
				</Form.Group>
				<Form.Group
					controlId="school"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>School</Form.Label>
					<Form.Control
						as="select"
						value={filterSchool}
						onChange={(e) => setFilterSchool(e.target.value)}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
							minHeight: "55px",
						}}
						disabled={userDetails?.userType === "DISTRICT" ? false : true}
					>
						<option value="">ALL</option>
						{schoolList.map((school) => {
							return (
								<option value={school._id}> {school.name} </option>
							)
						})}
					</Form.Control>
				</Form.Group>
				{classList.length > 0 && <Form.Group
					controlId="class"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>Class</Form.Label>
					<Multiselect
						labelledBy={"Select"}
						showArrow
						options={classList.filter((classItem) => {
							if (userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT") {
								return userDetails?.classes?.map((classId) => {
									if (classId === classItem._id) {
										return true
									}
								}).filter((classId) => {
									if (classId) {
										return true
									}
								}).length > 0
							}
							return true
						})}
						displayValue="class_name_section"
						groupBy="class_name"
						showCheckbox={true}
						selectedValues={filterClass}
						onSelect={(selectedList, selectedItem) => { setFilterClass(selectedList) }}
						onRemove={(selectedList, selectedItem) => { setFilterClass(selectedList) }}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
						}}
					/>
				</Form.Group>
				}
				<Form.Group
					controlId="subject"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>Subject</Form.Label>
					<Form.Control
						as="select"
						value={filterSubject}
						onChange={(e) => setFilterSubject(e.target.value)}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
							minHeight: "55px",
						}}
					>
						<option value="">ALL</option>
						{subjectList.map((subject) => {
							return (
								<option value={subject._id}> {subject.name} </option>
							)
						})}
					</Form.Control>
				</Form.Group>
			</Form>
			<div className="m-4">
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th scope="col"
								style={{
									minWidth: "80px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Sr. No.</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Document Name</th>
							<th scope="col"
								style={{
									minWidth: "280px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Document Description</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Document Type</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Recipient User ID's</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Recipient User Type</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Owner Conventional Id</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Owner User Type</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>School's</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Class</th>
							<th scope="col"
								style={{
									minWidth: "200px",
									textAlign: "center",
									borderRight: "1px solid #dee2e6",
									borderLeft: "none",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>Subject</th>
							<th scope="col"
								style={{
									minWidth: "140px",
									textAlign: "center",
									borderRight: "none",
									borderLeft: "1px solid #dee2e6",
									borderTop: "1px solid #dee2e6",
									borderBottom: "1px solid #dee2e6",
								}}
							>View</th>
						</tr>
					</thead>
					<tbody>
						{documentsList.map((item, index) => {
							return (
								<>
									<Modal
										show={item.userShowToggle}
										onHide={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}
									>
										<Modal.Header closeButton>
											<Modal.Title>Users List</Modal.Title>
										</Modal.Header>
										<Modal.Body
											style={{
												maxHeight: "calc(100vh - 210px)",
												overflowY: "scroll"
											}}
										>
											<Table striped bordered hover responsive className="mt-4 mb-4">
												<thead>
													<tr>
														<td><b>Sr. No.</b></td>
														<td><b>Name</b></td>
														<td><b>Conventional ID</b></td>
													</tr>
												</thead>
												<tbody>
													{item.document_for_user_data ? item.document_for_user_data.map((user, i) => {
														return (
															<tr key={i}>
																<td>{i + 1}</td>
																<td>{user ? user[1] : user}</td>
																<td>{user ? user[2] : user}</td>
																{/* <td><Form.Check type="checkbox" style={{ padding: 10 }} checked = {user.checked} onChange={e => setUserList(userList.map(us => (us.username==user.username ? {...us, checked: !user.checked} : us)))} /></td> */}
															</tr>
														)
													}) : <tr><td colSpan="4">No Users</td></tr>}
												</tbody>
											</Table>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" onClick={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
									<Modal
										show={item.schoolShowToggle}
										onHide={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, schoolShowToggle: !item.schoolShowToggle } : f))) }}
									>
										<Modal.Header closeButton>
											<Modal.Title>Users List</Modal.Title>
										</Modal.Header>
										<Modal.Body
											style={{
												maxHeight: "calc(100vh - 210px)",
												overflowY: "scroll"
											}}
										>
											<Table striped bordered hover responsive className="mt-4 mb-4">
												<thead>
													<tr>
														<td><b>Sr. No.</b></td>
														<td><b>Name</b></td>
														<td><b>Conventional ID</b></td>
													</tr>
												</thead>
												<tbody>
													{item.school_id ? item.school_id.map((user, i) => {
														console.log(user, schoolList.filter(school => school._id === user))
														return schoolList.filter(school => school._id === user).map((school) => {
															return (
																<tr key={i}>
																	<td><b>{i + 1}</b></td>
																	<td><b>{school.name}</b></td>
																	<td><b>{school.conventional_id}</b></td>
																</tr>
															)
														})
													}) : <tr><td colSpan="4">No Users</td></tr>}
												</tbody>
											</Table>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" onClick={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, schoolShowToggle: !item.schoolShowToggle } : f))) }}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
									<tr key={index}>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "none",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{index + 1}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{item.name}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{item.description}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{item.document_type}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}><a onClick={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}>{item.document_for_user ? item.document_for_user.length + " Users" : "0 Users"}</a></td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{item.userType_to_show ? item.userType_to_show : ""}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{item.created_by ? item.created_by.conventionalId : ""}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{item.user_type_of_creator ? item.user_type_of_creator : ""}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}><a onClick={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, schoolShowToggle: !item.schoolShowToggle } : f))) }}>{item.school_id ? item.school_id.length + " School's" : "0 School"}</a></td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{item.class_data_names ? item.class_data_names.map(name => { return name }).join(' ,') : "ALL/None"}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>{item.subject ? item.subject.name : "ALL/None"}</td>
										<td style={{
											textAlign: "center",
											borderRight: "none",
											borderLeft: "1px solid #dee2e6",
											borderTop: "1px solid #dee2e6",
											borderBottom: "1px solid #dee2e6",
										}}>
											<Button variant="secondary" onClick={() => window.open(item.link_arr[0], "_blank")}>
												View
											</Button>
										</td>
									</tr>
								</>
							)

						})}
					</tbody>
				</Table>
			</div>
		</div>
	)
}

export default ShowYourDocument;