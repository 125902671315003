import { Box, Grid, IconButton, Tab, Tabs, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabPanel from "../AssessmentTracker/StudentAssessmentTracker/components/TabPanel";
import { a11yProps } from "../AssessmentTracker/StudentAssessmentTracker/components/Constants";
import { SearchOutlined } from "@material-ui/icons";
import Api from "../../../Api/axios";
import Table from "react-bootstrap/Table";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const SchoolAdminAssessmentTracker = () => {
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");
  const [createdAssessmentList, SetCreatedAssessmentList] = useState([]);
  const [allAssessment, setAllAssessment] = useState([]);
  const [notSubmittedAssessment, setNotSubmittedAssessment] = useState([]);
  const [SubmittedAssessment, setSubmittedAssessment] = useState([]);
  const [isAssessmentEmpty, setIsAssessmentEmpty] = useState(false);
  const [querySearch, setQuerySearch] = useState();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    Api.get("/assessment/created-assessment").then((res) => {
      let AList = [];
      let fetchedData = res.data;
      console.log("fetch", fetchedData);
      fetchedData?.forEach((x) => {
        let obj = {};
        obj.assessmentId = x._id;
        obj.assessmenttitle = x.title;
        obj.assessmentDate = x.createdAt;
        obj.assessmentType = x.type;
        obj.assessmentTotalQuestions = x.total_questions;
        obj.assessmentUserType = x.user_type;
        obj.assessmentUserId = x.user_ids;
        AList.push(obj);
      });
      SetCreatedAssessmentList(AList);
    });
  }, [userDetails]);

  useEffect(() => {
    Api.get("/assessment/get-assessment").then((res) => {
      let AList = [];
      let fetchedData = res.data;
      console.log("fetch", fetchedData);
      fetchedData?.forEach((x) => {
        let obj = {};
        obj.assessmentId = x._id;
        obj.assessmenttitle = x.title;
        obj.assessmentDate = x.createdAt;
        obj.assessmentType = x.type;
        obj.assessmentTotalQuestions = x.total_questions;
        obj.assessmentUserType = x.user_type;
        obj.assessmentUserId = x.user_ids;
        AList.push(obj);
      });
      setAllAssessment(AList);
    });
  }, [userDetails]);

  useEffect(() => {
    Api.get("/assessment/submission/not-submitted").then((res) => {
      let AList = [];
      let fetchedData = res.data;
      console.log("fetch", fetchedData);
      fetchedData?.forEach((x) => {
        let obj = {};
        obj.assessmentId = x._id;
        obj.assessmenttitle = x.title;
        obj.assessmentDate = x.createdAt;
        obj.assessmentType = x.type;
        obj.assessmentTotalQuestions = x.total_questions;
        obj.assessmentUserType = x.user_type;
        obj.assessmentUserId = x.user_ids;
        AList.push(obj);
      });
      setNotSubmittedAssessment(AList);
    });
  }, [userDetails]);

  useEffect(() => {
    Api.get("/assessment/submission/submitted").then((res) => {
      let AList = [];
      let fetchedData = res.data;
      console.log("fetch", fetchedData);
      fetchedData?.forEach((x) => {
        let obj = {};
        obj.assessmentId = x._id;
        obj.assessmenttitle = x.title;
        obj.assessmentDate = x.createdAt;
        obj.assessmentType = x.type;
        obj.assessmentTotalQuestions = x.total_questions;
        obj.assessmentUserType = x.user_type;
        obj.assessmentUserId = x.user_ids;
        AList.push(obj);
      });
      setSubmittedAssessment(AList);
    });
  }, [userDetails]);

  console.log("===>", createdAssessmentList);
  console.log("===>All Assessent", allAssessment);
  console.log("===>Not submitted", notSubmittedAssessment);
  console.log("/submitted ==>", SubmittedAssessment);

  const handleAssessmentDelete = () => {
    alert(
      "Are you sure you want to delete this assessment it will be deleted in users assessment list"
    );
  };

  return (
    <Box sx={{ width: "100%" }}>
      <h3 className="p-4">School_Admin Assessment Tracker</h3>
      <Grid item xs={12} md={12} sm={12} lg={12} className="mt-2 mb-3 p-4">
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <TextField
              variant="outlined"
              className="w-100"
              label="Search by"
              onChange={(e) => setSearch(() => e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{ margin: "5px" }}
        >
          <Tab
            style={{ margin: "5px" }}
            label="Created Assessment"
            {...a11yProps(0)}
          />
          <Tab
            style={{ margin: "5px" }}
            label={"Pending Assessment" + `( ${notSubmittedAssessment.length} )`}
            {...a11yProps(1)}
          />
          <Tab
            style={{ margin: "5px" }}
            label="Submitted Assessment"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="m-4">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "80px" }}>
                  Assessment Type
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Assessment user type
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Assessment Name
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  No. of Questions
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  status
                </th>
                <th scope="col" style={{ minWidth: "80px" }}>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {createdAssessmentList
                .filter((data) => data.assessmenttitletitle === querySearch)
                .map((data) => {
                  return (
                    <tr>
                      <td>{data.assessmentType}</td>
                      <td>{data.assessmentUserType}</td>
                      <td>{data.assessmenttitle}</td>
                      <td>{data.assessmentTotalQuestions}</td>
                      <td
                        onClick={() => navigate(`${data.assessmentId}`)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        View
                      </td>
                      <td
                        onClick={handleAssessmentDelete}
                        style={{ color: "danger", cursor: "pointer" }}
                      >
                        <DeleteForeverIcon />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="m-4">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "80px" }}>
                  Assessment Type
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Assessment user type
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Assessment Name
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  No. of Questions
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  status
                </th>
              </tr>
            </thead>
            <tbody>
              {notSubmittedAssessment
                .filter((data) => data.assessmenttitletitle === querySearch)
                .map((data) => {
                  return (
                    <tr>
                      <td>{data.assessmentType}</td>
                      <td>{data.assessmentUserType}</td>
                      <td>{data.assessmenttitle}</td>
                      <td>{data.assessmentTotalQuestions}</td>
                      <td
                        onClick={() => navigate(`${data.assessmentId}/submit`)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        Submit Assessment
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div className="m-4">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th scope="col" style={{ minWidth: "80px" }}>
                  Assessment Type
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Assessment user type
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  Assessment Name
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  No. of Questions
                </th>
                <th scope="col" style={{ minWidth: "200px" }}>
                  status
                </th>
              </tr>
            </thead>
            <tbody>
              {SubmittedAssessment.filter(
                (data) => data.assessmenttitletitle === querySearch
              ).map((data) => {
                return (
                  <tr>
                    <td>{data.assessmentType}</td>
                    <td>{data.assessmentUserType}</td>
                    <td>{data.assessmenttitle}</td>
                    <td>{data.assessmentTotalQuestions}</td>
                    <td>submitted</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </TabPanel>
    </Box>
  );
};

export default SchoolAdminAssessmentTracker;
