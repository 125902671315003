import React, { useState, useEffect, useRef } from "react";
import { Stack, Form, InputGroup, Button } from "react-bootstrap";
import GetUsers from "../UserManagement/GetUsers";

const AddGroup = () => {
    const [users, setUsers] = useState([]);
    const getUsers = (users) => {
        setUsers(users);
        console.log(users);
    }
    return (
        <div
            className="p-4"
            style={{
                backgroundColor: "#fff",
                height: "100%"
            }}
            align="cenetr"
        >
            <h1
                className="pt-4 pb-2 ps-1 pe-1"
                style={{
                    fontWeight: "bold",
                    color: "#000",
                    alignSelf: "center",
                    justifyContent: "center",
                    textAlign: "center"
                }}
            >
                Create New Group
            </h1>
            <Form>
                <Form.Group className="pt-2 pb-2">
                    <Form.Label>Group Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Group name" />
                </Form.Group>
                <Form.Group className="pt-2 pb-2">
                    <Form.Label>Group Description</Form.Label>
                    <Form.Control type="text" placeholder="Enter Group description" />
                </Form.Group>
                <Form.Group className="pt-2 pb-2">
                    <Form.Label>Group Icon</Form.Label>
                    <Form.Control type="file" />
                </Form.Group>
                <GetUsers
                    selectUsers={true}
                    selectedUsersSet={getUsers}
                />
                <Button
                    variant="primary"
                    type="submit"
                    align="center"
                    className="mt-2 mb-2"
                    size="lg"
                    style={{
                        alignSelf: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        width: "100%"
                    }}
                >
                    Create Group
                </Button>
            </Form>
        </div>
    );
}

export default AddGroup;