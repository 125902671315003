import React, { useState, useEffect } from 'react';
import { Box, option } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Api from "../../../Api/axios";
import { MenuItem } from "@mui/material";
import Table from 'react-bootstrap/Table';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';




const CreateSubjectTest = () => {




    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();
    const [classId, setClassId] = ('')
    const [name, setName] = ('')
    const [classMap, setClassMap] = useState([]);
    const [pageNo, setPageNo] = useState(1);

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });



        Api.get("live-class").then((res) => {
            // order res.data by class_name then section
            setClassMap(res.data);
        }).finally(() => {

            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        })

    }, [userDetails]);


    const createLiveClass = async () => {


        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let temp = {
            classroom_id: classId,
            name: name,
        }
        console.log(temp, 'temp')
        await Api.post("live-class/add-subject", { data: temp }).then((res) => {
            console.log(res.data);

        }).catch((err) => {
            setPageNo(-1)
        }).finally(() => {


            setPageNo(2);


            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        })
    }

    const doNothing = (event) => {
        return true;
    }

    return (
        <>
            <h1 className="m-4">Create Live Class</h1>
            <center>

                {pageNo == 1
                    ?

                    <div className="shadow-lg p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                        <Form.Group controlId="formFile" className="m-3">
                            <Stack type="vartical" gap={4}>
                                <InputGroup>
                                    <InputGroup.Text>Select Class</InputGroup.Text>
                                    <Form.Select aria-label="Select Class" value={classId}
                                        onChange={(e) => setClassId(e.target.value)} >

                                        <option key="ALL" value="" style={{ width: "100%" }}>
                                            CHOOSE
                                        </option>
                                        {classMap.map((cl, i) => (
                                            <option key={i} value={cl._id} style={{ width: "100%" }}>
                                                {cl.class_name_section}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>
                                        Live Class Name
                                    </InputGroup.Text>
                                    <Form.Control
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                    // placeholder="Enter Title"
                                    />
                                </InputGroup>







                                <center style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outline-secondary" size="lg" onClick={createLiveClass} style={{ minWidth: '150px' }} >
                                        Create Live Class
                                    </Button>
                                </center>
                            </Stack>
                        </Form.Group>
                    </div>
                    : pageNo === -1 ?
                        <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                            <img src={`${process.env.PUBLIC_URL}/Images/failure.png`} style={{ width: '100px' }} alt="" />
                            <h3 className="m-4">There Was Error Creating Class</h3>
                            <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                                <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()}
                                >
                                    Create another live class
                                </Button>
                                <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
                                    Go to Dashboard
                                </Button>
                            </Stack>
                        </div>
                        :
                        <center>
                            <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                                <img src={`${process.env.PUBLIC_URL}/Images/sucess.png`} style={{ width: '100px' }} alt="" />
                                <h3 className="m-4">Live class Sucessfully Created</h3>

                                <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                                    <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => {

                                        setPageNo(1);
                                    }} >
                                        Create another live class
                                    </Button>
                                    <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
                                        Go to Dashboard
                                    </Button>
                                </Stack>
                            </div>


                        </center>

                }
            </center >
        </>
    );
};


export default CreateSubjectTest;