import { Button, Grid, TextField, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'

const QuestionLayout = ({quesInfo,i,id,marksState,questionWithAnswer,setmarksState}) => {
    const {marks_awarded,answer,s3_url}=questionWithAnswer
    // console.log("quesInfo[i]",quesInfo[i]["options"],"id===", id,"quesInfo indside",quesInfo,"quesInfo[i] ==> ",quesInfo[i]," quesInfo[i]?.options",quesInfo[i].options)
    // console.log("answer ==> ",answer);
    // console.log("quesInfo[i]?.options",quesInfo[i]?.correct_answer);
    const [isReadyToRender,setIsReadyToRender]=useState(false)
    console.log("quesInfo answer ==> ",quesInfo,id,answer,questionWithAnswer)
    if(Array.isArray(quesInfo)&&quesInfo[i]?.type==="msq"){
      console.log(quesInfo[i]?.correct_answer.includes(0))
      console.log(quesInfo[i]?.correct_answer.includes(1))
    }
    useEffect(()=>{
      if(Array.isArray(quesInfo)){
        setIsReadyToRender(true)
      }
    },[quesInfo])

    // const {evaluatioData,setEvaluatioData}=useState({
    //     marks:"0",
    // })
    function updateState(e){
        // setIsReadyToRender(false)
        setmarksState((pre)=>{
            let updated={...pre}
            updated[id]=e.target.value
            return updated
        })
    }
    function changeButtonBackgroundHover(e){
      e.target.style.background = 'white';
    }
    function changeButtonBackgroundLeave(e){
      e.target.style.background = '#1976d2';
    }

     
  return (
    <>
{ isReadyToRender&&
    <Grid key={id} container className='mb-4' >
    <Grid    item xs={12} md={12} lg={9}>{/*  user details and question details */}
                  <Typography variant='h5' className='mt-3'>Question {i+1}</Typography>
                  <Typography component='p'  align='justify' >{quesInfo[i]?.description}</Typography>
    </Grid>
    {(quesInfo[i]?.type=="mcq" ) &&
    <Grid    className="mt-2" item xs={12} md={12} lg={9}>
    <Typography variant='h5' className='mt-3'>Options</Typography>
    {quesInfo[i]?.options.map((x,i)=>{
    return (<InputGroup key={i} style={{marginBottom:"2%"}}>                                                
      <InputGroup.Radio 
          checked={i===Number(answer)}
          readOnly
      />
      <Form.Control
          value={x.option}
          readOnly
          // style={{backgroundColor:(i===answer)?(quesInfo[i]?.correct_answer===i?"green":"red"):''}}
      />
      </InputGroup>)
    })
    }
    </Grid>
    }
    { quesInfo[i]?.type=="msq" &&
    <Grid    className="mt-2" item xs={12} md={12} lg={9}>
    <Typography variant='h5' className='mt-3'>Options</Typography>
    {quesInfo[i]?.options.map((x,j)=>{
      // console.log("inside ==> in",quesInfo[i].correct_answer?.includes(i),"quesInfo[i].correct_answer",Array.isArray(quesInfo[i].correct_answer),"i",i)
      // return <Typography key={x._id} style={{backgroundColor:(answer.includes(i))?"blue":""}}   component='p' align='justify'>{x.option}</Typography>
      return (
      <InputGroup key={j}  style={{marginBottom:"2%"}}>                                                
        <InputGroup.Radio 
            checked={answer?.includes(j)}
            readOnly
        />
        <Form.Control
            value={x.option}
            readOnly
            // style={{backgroundColor:(answer?.includes(j))?((quesInfo[i].correct_answer.includes(j))?"green":"red"):''}}
    />
        </InputGroup>)
    })
    }
    </Grid>
    }

    {quesInfo[i]?.type==="subjective"&& //as of now,, but need this type  type!=="mcq" && type!=="msq"
    <Grid    className="mt-2" item xs={12} md={12} lg={9} style={{wordBreak:"break-all"}}>
    <Typography variant='h5' className='mt-3'>Answer</Typography>
    <Typography  component='p' align='justify' >   {answer}</Typography>
    </Grid>
    }
    {(quesInfo[i]?.type=="doc" ) &&
    <Grid  className="mt-2" item xs={12} md={12} lg={9}>
    <Button variant='contained' component="a" href={s3_url} onMouseLeave={(e)=>changeButtonBackgroundLeave(e)} onMouseOver={(e)=>changeButtonBackgroundHover(e)} target="_blank">View Document</Button>
    {/* <Typography variant='h5' className='mt-3'>Options rgfgfgfgf</Typography> */}
    {/* {quesInfo[i]?.options.map((x,i)=>{
    return (<InputGroup key={i} style={{marginBottom:"2%"}}>                                                
      <InputGroup.Radio 
          checked={i===Number(answer)}
          readOnly
      />
      <Form.Control
          value={x.option}
          readOnly
          // style={{backgroundColor:(i===answer)?(quesInfo[i]?.correct_answer===i?"green":"red"):''}}
      />
      </InputGroup>)
    })
    } */}
    </Grid>
    }
    <Grid className='mt-3'   item xs={12} md={12} lg={9}>    
          <Grid container spacing={3}>
                            <Grid item  xs={12} md={12} sm={12} lg={6}>
                            <TextField
                                variant='outlined'
                                className='w-100'
                                defaultValue={quesInfo[i]?.correct_marking}//static
                              inputProps={{
                                readOnly:true
                              }}
                                label="Marks Allocated"
                                />
                            </Grid>
                            <Grid item  xs={12} md={12} sm={12} lg={6}>
                                <TextField
                                variant='outlined'
                                className='w-100'
                                defaultValue={quesInfo[i]?.incorrect_marking}//static
                              inputProps={{
                                readOnly:true
                              }}
                                label="Negative Marks"
                                />
                            </Grid>
                            <Grid  item xs={12} md={12} sm={12} lg={6}>
                            {/* {marks_awarded!==null&&
                                <TextField
                                variant='outlined'
                                label="Marks Given"
                                className='w-100'
                                inputProps={{
                                    readOnly:true//type==="mcq"||type==="msq"
                                }}
                                value={marks_awarded}
                                //  onChange={(e)=>updateState(e)}
                                />
                            } */}
                            {/* {(marksState[id]==="")&&
                                    <TextField
                                    variant='outlined'
                                    label="Marks Given"
                                    className='w-100'
                                    value={marksState[id]}
                                    onChange={(e)=>updateState(e)}
                                  />
                            } */}
                              {/* {!(marksState[id]==="")&&  */}
                                    <TextField
                                    variant='outlined'
                                    label="Marks Given"
                                    className='w-100'
                                    value={marksState[id]}
                                    onChange={(e)=>updateState(e)}
                                  />
                            {/* } */}
      
                            </Grid>
                            {/* <Grid  className="d-flex align-items-center justify-content-end pe-4"  item xs={12} md={12} sm={12} lg={6}>
                            <Button className="h-80"  onClick={()=>console.log("jii")} variant='contained'>{(type==="mcq"||type==="msq")?"Approve":"Save"}</Button>
                            </Grid> */}
              </Grid>
    </Grid>
    </Grid>
}
</>
  )
}

export default QuestionLayout
