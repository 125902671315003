import React, { useState, useEffect } from 'react';
import { Box, option } from "@mui/material";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Api from "../../Api/axios";
import { MenuItem } from "@mui/material";
import Table from 'react-bootstrap/Table';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CreateLiveClassDetails from './CreateLiveClassDetails';
import MultiSelectComp from '../UserManagement/component/MultiSelect';
import ListOfUsers from './ListOfUsers';
import LiveClassDateTiming from './LiveClassDateTiming';

const CreateLiveClass = () => {




    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();

    const [userTypeList, setUserTypeList] = useState([
        ['DISTRICT', 'District'],
        ['SCHOOL_ADMIN', 'School Admin'],
        ['TEACHER', 'Teacher'],
        ['STUDENT', 'Student'],
    ]);
    const [currentUserTypeIndex, setCurrentUserTypeIndex] = useState(
        userTypeList.findIndex((item) => item[0] === userDetails?.userType)
    );
    let DefaultEmptyEventList = {
        id: uuidv4(),
        userCreateType: userDetails?.userType === "DISTRICT" ? "SCHOOL_ADMIN" : userDetails?.userType === "SCHOOL_ADMIN" ? "TEACHER" : userDetails?.userType === "TEACHER" ? "STUDENT" : "",
        userTypeList: userTypeList,
        currentUserTypeIndex: currentUserTypeIndex,
        SelectSchool: userDetails?.userType !== "DISTRICT" ? userDetails?.schoolId : "",
        // schoolList:schoolList,
        classOptions: [],
        classId: [],
    }
    const [eventList, setEventList] = useState([DefaultEmptyEventList])

    const [classMap, setClassMap] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [classId, setClassId] = useState([]);
    const [liveClassName, setLiveClassName] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [everyday, setEveryday] = useState(false);
    const [meetLink, setMeetLink] = useState('');


    useEffect(() => {
        // dispatch({
        //     type: "SET_LOADING",
        //     loading: true,
        // });
        // if (userDetails.userType === 'SCHOOL_ADMIN') { //gives all class
        //     Api.get("live-class").then((res) => {
        //         // order res.data by class_name then section
        //         setClassMap(res.data);
        //     }).finally(() => {
        //         dispatch({
        //             type: "SET_LOADING",
        //             loading: false,
        //         });
        //     })
        // }
        // else {//a api internal server error
        //     Api.post("live-class/get-all-classes", { data: userDetails.classes }).then((res) => {
        //         // order res.data by class_name then section
        //         res.data.sort((a, b) => {
        //             if (a.class_name === b.class_name) {
        //                 return a.class_section > b.class_section ? 1 : -1;
        //             }
        //             return a.class_name > b.class_name ? 1 : -1;
        //         });
        //         console.log(" res.data ==> ", res.data)
        //         setClassMap(res.data);
        //     }).catch((err)=>{

        //     })

        //     .finally(() => {

        //         dispatch({
        //             type: "SET_LOADING",
        //             loading: false,
        //         });
        //     })
        // }

    }, []);




    const generateMeetLink = async () => {//need to be checked

        if (liveClassName === '') {
            alert("Live Class Name cannot be empty");
            return;
        }

        if (date === '' && !everyday) {
            alert("Date cannot be empty");
            return;
        }
        if (time === '') {
            alert("Time cannot be empty");
            return;
        }
        // dispatch({
        //     type: "SET_LOADING",
        //     loading: true,
        // });
        // let temp = {
        //     class_id: classId,
        //     live_class_name: liveClassName,
        //     date: everyday ? "everyday" : date,
        //     time: time,
        // }
        // if (classId === '') {
        //     alert("Class cannot be empty");
        //     return;
        // }
        console.log("eventList", eventList)
        console.log("liveClassName", liveClassName, "date", date, "time", time, "userList", userList)

        let dataObj = {
            school_ids: [],
            class_ids: [],
            user_ids: [],
            user_types: [],
            liveClassName: "",
            time: ""
        }
        eventList.map((eventObj) => {
            dataObj.school_ids.push(eventObj.SelectSchool)
            dataObj.user_types.push(eventObj.userCreateType)
            // let classIds=eventObj.classId.map((classObj)=>{ .// not proper for all select
            //     return  classObj.classid
            // })
            // dataObj.class_ids.push(...classIds)
        })
        // dataObj.usertypes=eventList[0].userCreateType
        dataObj.liveClassName = liveClassName
        dataObj.time = time
        if (!everyday) {
            dataObj.date = date
        }
        let userids = userList.filter((x) => x.checked).map((userObj) => {
            return userObj.userId
        })
        let filterClassIds = userList.filter((x) => x.checked && x.userType !== "SCHOOL_ADMIN").map((userObj, i) => {
            return userObj.classes
        })
        console.log("filterClassIds", filterClassIds)
        let newSetClassIds = new Set()
        filterClassIds.map(userClass => {
            userClass.map(classid => {
                newSetClassIds.add(classid)
            })
        })
        console.log("newSetClassIds ", newSetClassIds)
        dataObj.class_ids.push(...Array.from(newSetClassIds))
        dataObj.user_ids = userids
        console.log("dataObj", dataObj) // api end need to be testted
        await Api.post("live-class/create-live-class", { data: dataObj }).then((res) => {
            console.log(res.data);
            setMeetLink(res.data);
            setPageNo(() => 4)
        }).catch((err) => {
            // setPageNo(-1)
        }).finally(() => {
            // dispatch({
            //             type: "SET_LOADING",
            //             loading: false,
            //         });
        })
        //     if (pageNo !== -1) {
        //         setClassId('');
        //         setLiveClassName('');
        //         setDate('');
        //         setTime('');
        //         setEveryday(false);
        //         setPageNo(2);

        //     }
        //     dispatch({
        //         type: "SET_LOADING",
        //         loading: false,
        //     });
        // })
        // dev testingn
        // setPageNo(()=>4)
    }

    const doNothing = (event) => {
        return true;
    }
    const [userList, setUserList] = useState([]);
    function evaluateDetails() {
        let isValid = true
        eventList.map((eventObj) => {
            if (eventObj.userCreateType !== "SCHOOL_ADMIN") {
                if ((eventObj.SelectSchool == "" || eventObj.classId.length == 0)) {
                    alert("Kindly fill School details and class details")
                    isValid = false
                }
            } else if (eventObj.userCreateType === "SCHOOL_ADMIN") {
                if ((eventObj.SelectSchool == "")) {
                    alert("Kindly fill School details for school Admin")
                    isValid = false
                }
            }
        })
        console.log("isValid", isValid);
        return isValid
    }
    async function AssignUsers() {
        if (!evaluateDetails()) {
            return
        }

        setPageNo(() => 2)
        let overllUsers = []
        let count = 0
        for await (const eventObj of eventList) {
            count++
            if (eventObj.userCreateType !== "SCHOOL_ADMIN") {
                let param = { userType: eventObj.userCreateType, schoolId: eventObj.SelectSchool }
                let ids = []
                eventObj.classId.forEach((x, i) => {
                    ids.push(x.classid)
                })
                param.classes = ids

                await Api.get(`/user/users-data`, { params: param }).then((res) => {
                    // console.log("r==============>",res.data);
                    // console.log("1 ==>with class use  required===>",res.data)
                    let fetchedUserData = [...res.data]
                    let requiredUserDetails = []
                    fetchedUserData.forEach((x) => {
                        let obj = {}
                        obj.name = x.name
                        obj.userType = x.userType
                        obj.username = x.username
                        obj.checked = false
                        obj.userId = x._id
                        obj.classes = ids?.length === 0 ? x.classes : ids
                        requiredUserDetails.push(obj)
                    })
                    overllUsers.push(...requiredUserDetails)
                    console.log(" with fetchedUserData use  fetchedUserData===>", fetchedUserData)

                }).catch((err) => {
                    console.log("err", err)
                })

            } else if (eventObj.userCreateType === "SCHOOL_ADMIN") {
                // console.log(" admin user SCHOOL_ADMIN required===>",eventObj.SelectSchool)

                await Api.get(`/user/users-data/?schoolId=${eventObj.SelectSchool}&userType=${eventObj.userCreateType}`).then((res) => {
                    console.log(" admin user  required===>", res.data)
                    let fetchedUserData = [...res.data]
                    let requiredUserDetails = []
                    fetchedUserData.forEach((x) => {
                        let obj = {}
                        obj.name = x.name
                        obj.username = x.username
                        obj.userType = x.userType
                        obj.checked = false
                        obj.userId = x._id
                        requiredUserDetails.push(obj)
                    })
                    // console.log(" admin user required===>", requiredUserDetails)
                    overllUsers.push(...requiredUserDetails)
                    // if(count===eventList.length){
                    //     console.log("overllUsers",overllUsers)
                    //     setUserList(()=>overllUsers)     
                    //    }
                }).catch((err) => {
                    console.log("err", err)
                })
            }

        }
        // if(count===eventList.length){
        // console.log("overllUsers",overllUsers)
        // console.log("event ==>  ",eventList)
        setUserList(() => overllUsers)
        // }
    }

    function setDefault() {
        setMeetLink('');
        setPageNo(1);
        setEventList(() => [DefaultEmptyEventList])
        setLiveClassName("")
        setDate("")
        setTime("")
        setEveryday(false)
        setUserList([])
    }
    return (
        <>
            <center>
                <h1 className="m-4">Create Live Class</h1>
                {pageNo == 1
                    ?
                    <CreateLiveClassDetails
                        AssignUsers={AssignUsers}
                        userTypeList={userTypeList}
                        currentUserTypeIndex={currentUserTypeIndex}
                        DefaultEmptyEventList={DefaultEmptyEventList}
                        eventList={eventList}
                        setEventList={setEventList}
                        setClassMap={setClassMap}
                        setTime={setTime}
                        setEveryday={setEveryday}
                        everyday={everyday}
                        setDate={setDate}
                        createLiveClass={generateMeetLink}
                        liveClassName={liveClassName}
                        setLiveClassName={setLiveClassName}
                        classMap={classMap}
                        setClassId={setClassId}
                        classId={classId} userDetails={userDetails} />
                    : pageNo == 2 ?
                        <ListOfUsers
                            userList={userList}
                            setUserList={setUserList}
                            eventList={eventList} setPageNo={setPageNo} />
                        : pageNo == 3 ?
                            <LiveClassDateTiming
                                createLiveClass={generateMeetLink}
                                everyday={everyday}
                                setLiveClassName={setLiveClassName}
                                liveClassName={liveClassName}
                                setTime={setTime}
                                setPageNo={setPageNo}
                                setEveryday={setEveryday}
                                setDate={setDate}
                            />
                            : pageNo == 4 ?
                                <center>
                                    <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                                        <img src={`${process.env.PUBLIC_URL}/Images/sucess.png`} style={{ width: '100px' }} alt="" />
                                        <h3 className="m-4">Live class Sucessfully Created</h3>
                                        <a href={meetLink}>Click here to join the meet</a>
                                        <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                                            <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => {
                                                setDefault()
                                            }}>
                                                Create another live class
                                            </Button>
                                            <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => {
                                                window.location.href = window.location.origin;
                                                setDefault()
                                            }} >
                                                Go to Dashboard
                                            </Button>
                                        </Stack>
                                    </div>
                                </center>
                                : <></>
                }
            </center >
        </>
    );
};


export default CreateLiveClass;