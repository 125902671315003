import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import { Box, MenuItem, Multiselect } from "@mui/material";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useEffect } from "react";

const ViewLiveClasses = () => {


    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();
    const [classMap, setClassMap] = useState([]);
    const [classId, setClassId] = useState("ALL");
    const [meets, setMeets] = useState([]);
    const [filterMeets, setFilterMeets] = useState([]);

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });

        Api.get("live-class").then((res) => {
            let temp = {};
            res.data.map((classroom) => {
                temp[classroom._id] = classroom.class_name_section;
            })
            setClassMap(temp);
        })
        Api.get("live-class/get-live-classes").then((res) => {
            setMeets(res.data);
            setFilterMeets(res.data);
        }).finally(() => {
            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        })
    }, []);


    const handleClassChange = (e) => {
        if (e.target.value === "ALL") {

            setFilterMeets(meets);
            console.log(filterMeets, 'yes')
            setClassId("ALL");
        }
        else {
            setClassId(e.target.value);
            setFilterMeets(meets.filter((meet) => meet.class_id === e.target.value));
        }
    }


    return (
        <div className='ps-2 pe-2' style={{
            // width: '100%',
            minHeight: '94vh',
            backgroundColor: '#FFF',
            paddingBottom: '40px'
        }}
            align="center"
        >

            <h1 className="p-4" style={{ fontWeight: "bold" }}>List of Live Classes</h1>
            <div>
                {

                    userDetails?.userType === "SCHOOL_ADMIN" ?

                        <TextField
                            select
                            label="Class"
                            variant="filled"
                            style={{ minWidth: 230 }}
                            className="m-2"
                            value={classId}
                            onChange={handleClassChange}
                        >
                            <MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
                                ALL
                            </MenuItem>
                            {
                                Object.keys(classMap).map((cl, i) => {
                                    return (
                                        <MenuItem key={cl} value={cl} style={{ width: "100%" }}>
                                            {classMap[`${cl}`]}
                                        </MenuItem>
                                    )
                                })
                            }
                        </TextField>
                        : userDetails?.userType === "TEACHER" ?
                            <TextField
                                select
                                label="Class"
                                variant="filled"
                                style={{ minWidth: 230 }}
                                className="m-2"
                                value={classId}
                                onChange={handleClassChange}
                            >
                                <MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
                                    ALL
                                </MenuItem>
                                {
                                    userDetails.classes.map((cl, i) => {
                                        return (
                                            <MenuItem key={cl} value={cl} style={{ width: "100%" }}>
                                                {classMap[`${cl}`]}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                            :
                            null
                }
            </div>


            <div className="m-4">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: "80px" }}>Sr. No.</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Class Name Section</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Live Class Name</th>
                            <th scope="col" style={{ minWidth: "280px" }}>Date</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Time</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Link</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Created By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterMeets.map((meet, index) => {
                            return (
                                <tr  key={index}>
                                    <td >{index + 1}</td>
                                    <td>{classMap[meet.class_id]}</td>
                                    <td>{meet.live_class_name}</td>
                                    <td>{meet.date}</td>
                                    <td>{meet.time}</td>
                                    <td>
                                        <a href={`${meet.meet_link}#userInfo.displayName="${userDetails?.name}"`} target="_blank">
                                            {meet.meet_link}
                                        </a>
                                    </td>
                                    <td>{meet.created_by_name}</td>
                                </tr>
                            )

                        })}


                    </tbody>
                </Table>
                {filterMeets.length===0&&<>
                    <div>
                        No Live Classes
                    </div>
                </>}
            </div>
        </div>

    )
}

export default ViewLiveClasses