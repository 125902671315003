export function DefaultStartDateTiming(){
    return(
        new Date(new Date(new Date
            (new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000))
    )
    }
    export function DefaultEndDateTiming(){
    return (
        new Date(new Date(new Date
            (new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))
    )
    }
    
    export function OldDatenumToNewDateNumConversion(dateNumStr){
        let dateNum=parseInt(dateNumStr)
        return new Date(new Date(new Date(new Date(dateNum).getFullYear()+"-"+new Date(dateNum).getMonth()+1+"-"+new Date(dateNum).getDate()).getTime()).setHours(0)).setMinutes(0)
        // return new Date(new Date(dateNum).getFullYear()+"-"+new Date(dateNum).getMonth()+1+"-"+new Date(dateNum).getDate()).getTime()
    //    return new Date(new Date(dateNum).getFullYear()+"-"+new Date(dateNum).getDate()+"-"+new Date(dateNum).getMonth()+1).getTime()
    }
    
    export function DateTonumConversion(DateStr){
    return new Date(new Date(new Date(new Date(DateStr).getTime()).setHours(0)).setMinutes(0)).getTime()
    }
    
    export function StartDateTonumConversion(DateStr){
        //DateStr format "2023-01-13"
       return new Date(new Date(new Date(new Date(new Date(new Date(DateStr).getTime()).setHours(0)).setMinutes(0))).setMinutes(0)).getTime()
    }
    
        
    export function EndDateTonumConversion(DateStr){
       return new Date(new Date(new Date(new Date(new Date(new Date(DateStr).getTime()).setHours(23)).setMinutes(59))).setSeconds(59)).getTime()
    }
    
    
    export function ZeroPrefixFormat(date){
    if(parseInt(date)<10){
    return "0"+date
    }else{
        return date
    }
    }
    export function yesterdayStartDate(){
        return (
            new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (24 * 60 * 60 * 1000))
        )
        new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)
    
    }
    export function yesterdayEndDate(){
        return (
            new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)
            )
    }
    
    export function Last_7_days_Start_Date(){
        return (
           new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000))
            )
    }
    export function Last_7_days_End_Date(){
        return (
            new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))
            )
    }
    export function Last_30_days_Start_Date(){
        return (
           new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (29 * 24 * 60 * 60 * 1000))
            )
    }
    export function Last_30_days_End_Date(){
        return (
            new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))
            )
    }
    
    export function DateFormater(startFunc,endFunc){
        let start=`${startFunc().getFullYear()}-${ZeroPrefixFormat(startFunc().getMonth()+1)}-${ZeroPrefixFormat(startFunc().getDate())}`
        let end=`${endFunc().getFullYear()}-${ZeroPrefixFormat(endFunc().getMonth()+1)}-${ZeroPrefixFormat(endFunc().getDate())}`
    return [start,end]
    }
    
    
    export const FilterDateRanges=[
        {
            value: "OVERALL",
            text: "Overall",
        },
        {
            value: "TODAY",
            text: "Today",
        },
        {
            value: "YESTERDAY",
            text: "Yesterday",
        },
        {
            value: "LAST_7_DAYS",
            text: "Last 7 Days",
        },
        {
            value: "LAST_30_DAYS",
            text: "Last 30 Days",
        },
        {
            value: "CUSTOM",
            text: "Custom",
        }
    ]
    export const FilterUserTypes=[
        {
            value: "STUDENT",
            text: "Student",
        },
        {
            value: "TEACHER",
            text: "Teacher",
        },
        {
            value: "SCHOOL_ADMIN",
            text: "School Admin",
        }
    ]
    export const FilterChartTypeList=[
        {
            value: "OVERALL",
            text: "Overall",
        },
        {
            value: "USER_TYPE",
            text: "User Type Wise",
        },
        {
            value: "SCHOOL_WISE_STUDENT_SUB",
            text: "School Wise Student Submission",
        },
        {
            value: "SCHOOL_WISE_TEACHER_SUB",
            text: "School Wise Teacher Submission",
        },
        {
            value: "SCHOOL_WISE_ADMIN_SUB",
            text: "School Wise Admin Submission",
        },
        {
            value: "SCHOOL_WISE_STUDENT_NOT_SUB",
            text: "School Wise Student not Submission",
        },
        {
            value: "SCHOOL_WISE_TEACHER_NOT_SUB",
            text: "School Wise Teacher not Submission",
        },
        {
            value: "SCHOOL_WISE_ADMIN_NOT_SUB",
            text: "School Wise Admin not Submission",
        }
    
    ]
    
    export function overAllRatio(total,submision){
        // //console.log("in constant",submision,total)
        // //console.log("in constant",(submision/total))
        let divid=submision/total
        if(isNaN(divid)){
            return 0
        }else{
            return (divid*100)
        }
        // //console.log("in constant",(submision/total)*100)
    
        // let ratio=(submision/total)*100
        // return ratio
    }
    export const userTypeBody=[
        {
            "user_type":"SCHOOL_ADMIN"
        },
        {
            "user_type":"TEACHER"
        },
        {
            "user_type": "STUDENT",
        }
    
    ]
    export const initialUserTypeRatio={
        "STUDENT":0,
        "TEACHER":0,
        "SCHOOL_ADMIN":0
    }
    
    function ArrayFromObj(obj){
        return Object.keys(obj)
     }
    
    export function makeUserTypeCumOverallRatio(overallSubmission,overAllUserTyperatio){
        //console.log("in constanr",overallSubmission)
        let Overall=ArrayFromObj(overallSubmission)
        // let OverallAssSubBySchool=ArrayFromObj(overallAssessmentSubBySchool)
        // let OverallAssNotSubBySchool=ArrayFromObj(overallNotSubmissionBySchool)
        let totalOverall=0
        let totalOverallSubmission=0
        let updated={...overAllUserTyperatio}
        Overall.map((x)=>{
            totalOverall+=overallSubmission[x].total
            totalOverallSubmission+=overallSubmission[x].submission
            //console.log("working overallSubmission ",overallSubmission)
            // if(x==="TEACHER"){
            //     //console.log("before",overallSubmission);
            //     //console.log("before",updated)
            // }
            updated[x]=overAllRatio(overallSubmission[x].total,overallSubmission[x].submission)
            // if(x==="TEACHER"){
            //     //console.log("after",updated)
            // }
            // setOverAllUserTyperatio(()=>updated)
        })
        //console.log("in constanr updated",updated)
        let ratio=overAllRatio(totalOverall,totalOverallSubmission)
    
        return [updated,ratio]
    
    }