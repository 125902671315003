import React, { useState, useEffect } from 'react';
import Api from '../../Api/axios';
import { Stack } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table, Button, Form, Col, Row } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import GetUsers from '../UserManagement/GetUsers';
import { useDataLayerValue } from "../../DataLayer/DataLayer";

const SchoolIdDashboard = () => {
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const navigate = useNavigate();
    const urlParams = useParams();
    const [schoolId, setSchoolId] = useState(urlParams.id);
    const [school, setSchool] = useState([]);
    const [schoolAvailable, setSchoolAvailable] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [userDict, setUserDict] = useState([]);
    const [filterUserType, setFilterUserType] = useState("ALL");
    const [searchString, setSearchString] = useState("");
    const userTypes = [
        ['SCHOOL_ADMIN', 'School Admin'],
        ['TEACHER', 'Teacher'],
        ['PARENT', 'Parent'],
        ['STUDENT', 'Student'],
    ]
    useEffect(() => {
        let sc = {};
        Api.get('/school-entity/school').then((response) => {
            response.data.map((school) => {
                if (school._id === schoolId) {
                    sc = school;
                    setSchool(school);
                    setSchoolAvailable(true);
                }
            })
            Api.get('/user/by-school?id=' + sc._id).then((res) => {
                setUserDict(res.data);
            })
            Api.get('/session-entity?district_id=' + sc.district_id).then((resp) => {
                setSessionList(resp.data);
            });
        }).finally(() => {
            console.log("School: ", !sc._id);
            if (!sc._id) {
                setSchoolAvailable(false);
            }
            console.log("School:", sc);
        })
    }, [refresh, schoolId]);
    return schoolAvailable ?
        (<>
            < div style={{ minHeight: "100%", width: "auto", minWidth: "800px", background: "#FFF" }
            }>
                <div style={{ height: "150px", width: "auto", color: "#fff", background: `url(${process.env.PUBLIC_URL}/Images/banner.jpg)`, backgroundSize: "cover" }} align="left">
                </div>
                <div align="center">
                    <Stack direction='vertical' gap={3}
                        className="pb-4"
                        style={{ alignItems: "center", width: "100%" }}
                    >
                        <img
                            src={school?.logo ? school.logo : `${process.env.PUBLIC_URL}/Images/default_avatar.jpg`}
                            alt="profile pic"
                            className='ms-2'
                            style={{ marginTop: "-90px", width: "200px", height: "200px", border: "4px solid white", borderRadius: "50%" }}
                        />
                        <h1 style={{ marginBottom: "0", paddingBottom: "0", fontWeight: "bold" }}>{school?.name}</h1>
                        <h4 className='text-muted' style={{ marginTop: "0", paddingTop: "0", fontWeight: "light" }}>{school?.address}</h4>
                    </Stack>
                    <Tabs
                        defaultActiveKey="basicInfo"
                        className="mb-3"
                        style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <Tab className='p-4' tabClassName='ms-2 me-2' eventKey="basicInfo" title="Basic Info" align="left">
                            <Table responsive hover>
                                <tbody>

                                    {/* <br/><p style={{fontWeight:"bold", margin:"0", padding:"0", display:"inline"}}>ok</p> */}
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Cenventional ID</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {school.conventional_id}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Address</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.address}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Block</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.Block_name} ({school.Block_code})</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>District</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: SUNDERGARH</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Pincode</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.pincode}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Phone No.</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.Contact_number}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Email</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Website</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.website}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>School Catagory</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.School_Category?.join(", ")}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>School Management Type</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.School_Management_Type}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>School Type</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.School_Type}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>School Location Type</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.School_Location_Type}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>School Inscharge Name</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.HOS_name}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>School Inscharge Designation</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {school.Incharge_Type}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>School Inscharge Phone No.</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: +91 {school.HOS_contact_number}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "none", textAlign: "left" }}><b>Map Location</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "none", textAlign: "left" }}>: </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }} colSpan={2}>
                                            <iframe src={"https://maps.google.com/maps?q=" + school.lat_long + "&z=15&output=embed"} width="100%" height="450"></iframe>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="variables" tabClassName='ms-2 me-2' title="LifeCycle Variables" className='p-4' align="left">
                            <Table responsive hover>
                                <tbody>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Class Range</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {school.class_range_start} - {school.class_range_end}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Section Range</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: A-{String.fromCharCode(64 + (Number(school.max_number_of_sections) ? Number(school.max_number_of_sections) : 26))}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Maximum Allowed No of Students/Section </b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {school.students_per_section}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Current Session</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {sessionList.filter((session) => session._id === school.current_session)[0]?.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Subjects</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {school.subjects?.map((item, key) => item?.name).join(",")}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                        <Tab eventKey="users" tabClassName='ms-2 me-2' title="Users" className='p-4' align="center">
                            {/* <Stack className='mt-2 mb-2' direction="horizontal" gap={3} style={{ justifyContent: "center" }}>
                                <Form.Control
                                    placeholder="Search User"
                                    // className="mb-2"
                                    value={searchString}
                                    onChange={(e) => { setSearchString(e.target.value) }}
                                    style={{ height: "100%", padding: "10px" }}
                                />
                                <TextField
                                    select
                                    label="Owner User Type"
                                    variant="filled"
                                    style={{ minWidth: 230 }}
                                    value={filterUserType}
                                    onChange={(e) => { setFilterUserType(e.target.value); }}
                                >
                                    <MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
                                        ALL
                                    </MenuItem>
                                    {
                                        userTypes.map((userType, i) => {
                                            return (
                                                <MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
                                                    {userType[1]}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Stack>
                            <Table striped bordered hover >
                                <thead>
                                    <tr align="center">
                                        <th>Sr. No.</th>
                                        <th>Name</th>
                                        <th>ID</th>
                                        <th>User Type</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userDict.map((user, index) => {
                                        if ((filterUserType === "ALL" || user.userType === filterUserType) && (searchString === "" || user.name.toLowerCase().includes(searchString.toLowerCase()) || user.conventionalId.toLowerCase().includes(searchString.toLowerCase()))) {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{user.name}</td>
                                                    <td>{user.conventionalId}</td>
                                                    <td>{user.userType}</td>
                                                    <td><a onClick={() => window.open(window.location.origin+"/dashboard/" + user._id, '_blank')}>View</a></td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </Table> */}
                            <GetUsers districtId={userDetails?.districtId} schoolId={schoolId} />
                        </Tab>

                    </Tabs>
                </div>
            </div >
        </>) : (
            <center>
                <div className="shadow-lg p-4 col-11 col-lg-10 mt-4" style={{ borderRadius: 10, background: "#FFF" }} align="center">
                    <img src={`${process.env.PUBLIC_URL}/Images/failure.png`} style={{ width: '100px' }} alt="" />
                    <h3 className="m-4">Sorry, we cannot find the requested school.</h3>
                    <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                        <Button variant="outline-secondary" size="lg" onClick={(e) => { window.location.href = window.location.origin; }} >
                            Go to Dashboard
                        </Button>
                    </Stack>
                </div>
            </center>
        )
}

export default SchoolIdDashboard;