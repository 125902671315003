import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";
// import { Document, Page, pdfjs } from "react-pdf";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import PdfViewer from "./PdfViewer";
import VideoPlayer from "./VideoPlayer";
import NotFound from "../../NotFound/NotFound";

const ContentPlayer = () => {
	const urlParams = useParams();
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const updateDimensions = () => {
		setWidth(window.innerWidth);
		console.log("width", width);
		setHeight(window.innerHeight);
	}
	const [{ userDetails }, dispatch] = useDataLayerValue();
	const [progress, setProgress] = useState(0);
	const [items, setItems] = useState([]);
	const [refreshCount, setRefreshCount] = useState(0);
	const [countItems, setCountItems] = useState(0);
	const [courseId, setCourseId] = useState(urlParams?.courseId);
	const [course, setCourse] = useState();
	const [usersIdList, setUsersIdList] = useState(
		JSON.parse(localStorage.getItem("usersLoggedIn"))?.map((i) => i._id)
	);
	console.log("courseId", courseId, urlParams?.courseId, urlParams);
	const itemWiseProgressFunc = (item, chapterIdd, percentage) => {
		console.log("item", item);
		if (item?.percentage < percentage) {
			var temp = progress + ((percentage - item?.percentage) / 100) / countItems;
			setProgress(temp);
			item.percentage = percentage;
			Api.post("course/create-progress", {
				general_id: item?._id,
				user_id: userDetails?._id,
				progress: percentage,
				type: "content",
				users: usersIdList,
			}).then((res) => {
				console.log("res", res);
			})
			Api.post("course/create-progress", {
				general_id: urlParams?.courseId,
				user_id: userDetails?._id,
				progress: temp,
				type: "course",
				users: usersIdList,
			}).then((res) => {
				console.log("res", res);
			})
		}
		setRefreshCount(refreshCount + 1);
		console.log(items);
	}
	useEffect(() => {
		window.addEventListener("resize", updateDimensions);
		return () => window.removeEventListener("resize", updateDimensions);
	}, []);
	useEffect(() => {
		console.log(urlParams?.courseId);
		console.log("i am running");
		Api.post('/course/get-courses', { _id: urlParams?.courseId }).then((res) => {
			console.log("res", res);
			setCourse(res.data);
		})
		var setted = false;
		var percent_overall = 0;
		var count = 0;
		Api.get('/course/get-course-content?course_id=' + urlParams?.courseId).then((res) => {
			console.log("res", res);
			var dataContent = res.data;
			if (res.data.length === 0) {
				dataContent = [{
					chapter_description: "",
					chapter_name: "No Content Found",
					content_ids: [],
					contents: [],
					course_id: urlParams?.courseId,
					seq_no: 0,
					_id: "0"
				}]
			}
			dataContent.sort((a, b) => a.seq_no - b.seq_no);
			dataContent.map((i) => {
				i.contents?.sort((a, b) => a.seq_no - b.seq_no);
				i.contents?.map((j) => {
					j.percentage = j.percentage ? j.percentage : 0;
					percent_overall += j.percentage / 100;
					count += 1;
					if (!setted && (j.to_display || userDetails?.userType !== "STUDENT")) {
						setSelectedItem(j);
						setted = true;
					}
					return j;
				});
				return i;
			});
			if (count === 0) {
				count = 1;
			}
			percent_overall = percent_overall / count;
			console.log("res", res);
			// Api.post("course/get-progress",{
			// 	general_id: courseId,
			// 	user_id: userDetails?._id,
			// }).then((res2) => {
			// 	if (res2.progress>=percent_overall) {
			// 		setProgress(res2.progress);
			// 	} else {
			setProgress(percent_overall);
			Api.post("course/create-progress", {
				general_id: urlParams?.courseId,
				user_id: userDetails?._id,
				progress: percent_overall,
				type: "course",
				users: usersIdList,
			}).then((res3) => {
				console.log("res3", res3);
			})
			// 	}
			// })
			setCountItems(count);
			setItems(dataContent);
		})
	}, [courseId]);
	const [chapterId, setChapterId] = useState(1);
	const [selectedItem, setSelectedItem] = useState({});
	useEffect(() => {
		setRefreshCount(refreshCount + 1)
		console.log("selectedItem", selectedItem);
	}, [selectedItem]);
	return (
		<>
			{course?.length ?
				<div>
					<div className="row" style={{
						width: '100%',
						minHeight: '50px',
						backgroundColor: '#343a40',
						margin: '0px',
					}}>
						<div className="col" style={{
							color: "white",
							fontSize: "x-large",
							alignSelf: "center",
						}}>
							{course.length ? course[0].subject_name : ""}
						</div>
						<div style={{
							display: "flex",
							alignContent: "center",
							justifyContent: "flex-end",
							width: "fit-content",
							alignItems: "center",
							color: "white",
							fontSize: "larger",
						}}>
							Progress: {Math.round(progress * 100)}%
						</div>
					</div>
					<div className="row gx-0">
						<div className="col" style={{ maxWidth: '100%', minWidth: "50%", background: 'rgba(0,0,0,0)', padding: '10px', marginTop: '4px' }}>
							{selectedItem?.content_type?.toLowerCase() === 'video' && <VideoPlayer
								item={selectedItem}
								itemWiseProgress={itemWiseProgressFunc}
								url={selectedItem?.link}
								subtitle={selectedItem?.transcript_link}
								chapterId={selectedItem?.chapter_id}
							/>}
							{selectedItem?.content_type?.toLowerCase() === 'pdf' && <PdfViewer
								item={selectedItem}
								itemWiseProgress={itemWiseProgressFunc}
								url={selectedItem?.link}
								chapterId={selectedItem?.chapter_id}
							/>}
						</div>
						<div className="col" style={{ width: '400px', maxWidth: width > 1000 ? '400px' : "100%", minWidth: Math.min(width - 55, 400), padding: '10px', overflowX: "hidden", overflowY: "scroll" }}>
							{items?.map((item, index) => {
								return (
									<>
										<Button className="btn btn-primary font-monospace text-start" style={{ width: '100%', minWidth: '100%', maxWidth: '100%', background: 'var(--bs-border-color)', borderStyle: 'none', borderRadius: '0px', marginTop: '4px' }}><strong><span style={{ color: 'rgb(0, 0, 0)', backgroundColor: 'rgba(222, 226, 230, 0)' }}>{item?.chapter_name}</span></strong></Button>
										<div style={{ width: '100%', minWidth: '100%', maxWidth: '100%', background: "#343a40", color: "#FFF" }}>
											{item?.contents?.map((con, index) => {
												if (con.to_display !== true && userDetails?.userType === "STUDENT") return null;
												return (
													<div style={{ background: `url(${process.env.PUBLIC_URL}/Images/progress_background.jpg)`, backgroundSize: `${con.percentage}% 100%`, backgroundRepeat: "no-repeat", padding: "5px" }}>
														<a className="dropdown-item font-monospace" href="#" onClick={
															() => {
																setSelectedItem(con);
																setChapterId(item?._id);
															}
														}>{con.title}</a>
													</div>
												)
											})}
										</div>
									</>
								)
							})}
						</div>
					</div>
				</div >
				:
				<NotFound />
			}
		</>
	);
};

export default ContentPlayer;