import React from "react";
import "./DeleteModal.css";
import { MdCancel } from "react-icons/md";

function DeleteModal({ closeDeleteModal, open, deleteConfirmed, message }) {
  if (open) {
    return (
      <div className="deleteModal">
        <div class="delete-modal-content">
          <div className="delete-modal-top"></div>
          <div className="delete-modal-bottom">
            <div className="delete-modal-icon">
              <MdCancel size={70} color={"red"} />
            </div>
            <div className="delete-modal-main-content">
              <h1>Are you sure ?</h1>
              <p>{message}</p>
              <div className="delete-modal-buttons">
                <button onClick={() => closeDeleteModal()}>Cancel</button>
                <button onClick={() => deleteConfirmed()}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
}

export default DeleteModal;
