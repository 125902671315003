import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import { Box, MenuItem } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import { Multiselect } from "multiselect-react-dropdown";
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useEffect } from "react";

const ShowSchoolDocument = () => {
	const userTypes = [
		['CENTRAL', 'Central'],
		['DISTRICT', 'District'],
		['SCHOOL_ADMIN', 'School Admin'],
		['TEACHER', 'Teacher'],
		['PARENT', 'Parent'],
		['STUDENT', 'Student'],
	]
	const documentTypes = [
		["Academic", "Academic"],
		["Personal", "Personal"],
		["Administrative", "Administrative"],
		["Others", "Others"],
	]
	const [classDict, setClassDict] = useState({});
	const [sectionDict, setSectionDict] = useState({ "ALL": [] });
	const [subjectDict, setSubjectDict] = useState([]);
	const [documentType, setDocumentType] = useState("ALL");
	const [ownerUserType, setOwnerUserType] = useState("ALL");
	const [recipientUserType, setRecipientUserType] = useState("ALL");
	const [ownerUser, setOwnerUser] = useState("ALL");
	const [recipientUsers, setRecipientUsers] = useState("ALL");
	const [className, setClassName] = useState("ALL");
	const [sectionName, setSectionName] = useState("ALL");
	const [subjectName, setSubjectName] = useState("ALL");
	const [
		{ user, userDetails, initialState, loggedIn, class_teacher_class_details },
	] = useDataLayerValue();
	const currentUserTypeIndex = userTypes.findIndex((userType) => userType[0] === userDetails?.userType);
	const [files, setFiles] = useState([]);
	const [{ type }, dispatch] = useDataLayerValue();
	// React.useEffect(async () => {
	// 	dispatch({
	// 		type: "SET_LOADING",
	// 		loading: true,
	// 	});
	// 	setFiles([]);
	// 	if (ownerUserType === "ALL") {
	// 		for (let i = currentUserTypeIndex; i < userTypes.length; i++) {
	// 			await Api.get("documents", { params: { ownerType: userTypes[i][0] } }).then((res) => {
	// 				setFiles(files => [...files, ...res.data]);
	// 			})
	// 		}
	// 	} else {
	// 		await Api.get("documents").then((res) => {
	// 			setFiles(res.data);
	// 		})
	// 	}
	// 	dispatch({
	// 		type: "SET_LOADING",
	// 		loading: false,
	// 	});
	// }, []);
	const [sectionIds, setSectionIds] = useState(new Set());
	const [sec_names, setSectionNames] = useState({});
	React.useEffect(async () => {
		let temp_dict = [{ class: "ALL", section: "ALL", ids: [], name: "ALL" }];
		let sec_n = {};
		await Api.get("live-class").then((res) => {
			for (const cl of res.data) {
				let classObj = { class: cl.class_name, section: cl.class_section, ids: [cl._id], name: cl.class_name + " " + cl.class_section };
				temp_dict.push(classObj);
				sec_n[cl._id] = cl.class_name + " " + cl.class_section;
				let f = 1;
				for (const sec of temp_dict) {
					if (sec.class === cl.class_name && sec.section === "ALL") {
						sec.ids.push(cl._id);
						f = 0;
					}
				}
				if (f) {
					temp_dict.push({ class: cl.class_name, section: "ALL", ids: [cl._id], name: cl.class_name + " ALL" });
				}
			}
		}).finally(() => {
			setClassDict(temp_dict);
			setSectionNames(sec_n);
		})
		console.log(classDict);
	}, []);
	React.useEffect(() => {
		Api.get("live-class/subject").then((res) => {
			setSubjectDict(res.data);
		})
	}, []);
	const handleDocumentTypeChange = (event) => {
		setDocumentType(event.target.value);
	};
	const handleOwnerUserTypeChange = (event) => {
		setOwnerUserType(event.target.value);
	};
	const handleRecipientUserTypeChange = (event) => {
		setRecipientUserType(event.target.value);
		if (event.target.value !== "STUDENT") {
			setClassName("ALL");
			setSubjectName("ALL");
		}
	}
	const handleClassChange = async (e) => {
		setClassName(e.target.value);
		setSubjectName("ALL");
		setSectionName("ALL");
		setSectionDict(classDict[e.target.value]);
	}
	const handleSubjectChange = async (e) => {
		setSubjectName(e.target.value);
	}
	useEffect(async () => {
		dispatch({
			type: "SET_LOADING",
			loading: true,
		});
		var fil = [];
		if (ownerUserType === "ALL") {
			for (let i = currentUserTypeIndex; i < userTypes.length; i++) {
				const data = {
					document_for: [...sectionIds],
					type: documentType === "ALL" ? "" : documentType,
					userType: recipientUserType === "ALL" ? "" : recipientUserType,
					subject_id: subjectName === "ALL" ? "" : subjectName,
					ownerType: userTypes[i][0],
				}

				await Api.get("documents", { params: data }).then((res) => {
					fil.push(...res.data);
				})
			}
		} else {
			const data = {
				document_for: [...sectionIds],
				type: documentType === "ALL" ? "" : documentType,
				userType: recipientUserType === "ALL" ? "" : recipientUserType,
				subject_id: subjectName === "ALL" ? "" : subjectName,
				ownerType: ownerUserType === "ALL" ? "" : ownerUserType,
			}
			await Api.get("documents", { params: data }).then((res) => {
				// console.log(res.data);
				fil.push(...res.data);
			})
		}
		dispatch({
			type: "SET_LOADING",
			loading: false,
		});
		setFiles(fil);
	}, [sectionIds, documentType, recipientUserType, subjectName, ownerUserType, currentUserTypeIndex]);
	return (
		<div className='ps-2 pe-2' style={{
			// width: '100%',
			minHeight: '94vh',
			backgroundColor: '#FFF',
			paddingBottom: '40px'
		}}
			align="center"
		>
			<h1 className="p-4" style={{fontWeight:"bold"}}>List of School Documents</h1>
			<div className="m-4 row" style={{justifyContent: "center"}}>
				<Form.Group className="p-2 col-12 col-sm-6 col-lg-3" align="left">
					<Form.Label align="left">Document Type</Form.Label>
					<Form.Control
						as="select"
						label="Document Type"
						// variant="filled"
						value={documentType}
						onChange={handleDocumentTypeChange}
					>
						<option key="ALL" value="ALL" style={{ width: "100%" }}>
							ALL
						</option>
						{documentTypes.map((documentType) => (
							<option key={documentType[0]} value={documentType[0]} style={{ width: "100%" }}>
								{documentType[1]}
							</option>
						))}
					</Form.Control>
				</Form.Group>
				<Form.Group className="p-2 col-12 col-sm-6 col-lg-3" align="left">
					<Form.Label align="left">Owner User Type</Form.Label>
					<Form.Control
						as="select"
						label="Owner User Type"
						// variant="filled"
						value={ownerUserType}
						onChange={handleOwnerUserTypeChange}
					>
						<option key="ALL" value="ALL" style={{ width: "100%" }}>
							ALL
						</option>
						{userTypes.map((userType) => (
							<option key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
								{userType[1]}
							</option>
						))}
					</Form.Control>
				</Form.Group>
				<Form.Group className="p-2 col-12 col-sm-6 col-lg-3" align="left">
					<Form.Label align="left">Recipient User Type</Form.Label>
					<Form.Control
						as="select"
						label="Recipient User Type"
						// variant="filled"
						value={recipientUserType}
						onChange={handleRecipientUserTypeChange}
					>
						<option key="ALL" value="ALL" style={{ width: "100%" }}>
							ALL
						</option>
						{userTypes.map((userType, i) => {
							if (i >= currentUserTypeIndex) {
								return (
									<option key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
										{userType[1]}
									</option>
								);
							}
						}
						)}
					</Form.Control>
				</Form.Group>
				{(recipientUserType === "TEACHER" || recipientUserType === "STUDENT") &&
					<>
						<Form.Group className="p-2 col-12 col-sm-6 col-lg-3" align="left">
							<Form.Label align="left">Subject</Form.Label>
							<Form.Control
								as="select"
								label="Subject"
								// variant="filled"
								value={subjectName}
								onChange={handleSubjectChange}
							>
								<option key="ALL" value="ALL" style={{ width: "100%" }}>
									ALL
								</option>
								{subjectDict.map((subjectDictVar, i) => {
									if (sectionIds.size == 0 || (sectionIds.size > 0 && sectionIds.has(subjectDictVar.classroom_id))) {
										return (
											<option key={subjectDictVar._id} value={subjectDictVar.name} style={{ width: "100%" }}>
												{subjectDictVar.name}
											</option>
										)
									}
								})}
							</Form.Control>
						</Form.Group>
						<Form.Group className="p-2 col-12" align="left">
							<Form.Label align="left">Class</Form.Label>
						<Multiselect
							showArrow
							options={classDict}
							className="col-12"
							displayValue="name"
							groupBy="class"
							showCheckbox={true}
							placeholder="Select Class"
							onSelect={(selectedList, selectedItem) => {
								setSectionName(selectedList)
								let temp_sec = new Set()
								selectedList.forEach(element => {
									element.ids.forEach(sec => {
										temp_sec.add(sec)
									})
								})
								setSectionIds(temp_sec)
							}}
							onRemove={(selectedList, selectedItem) => {
								setSectionName(selectedList)
								let temp_sec = new Set()
								selectedList.forEach(element => {
									element.ids.forEach(sec => {
										temp_sec.add(sec)
									})
								})
								setSectionIds(temp_sec)
							}}
						/>
						</Form.Group>
						
					</>
				}
			</div>
			<div className="m-4">
				<Table striped bordered hover responsive>
					<thead>
						<tr>
							<th scope="col" style={{ minWidth: "80px" }}>Sr. No.</th>
							<th scope="col" style={{ minWidth: "200px" }}>Recipient User ID's</th>
							<th scope="col" style={{ minWidth: "200px" }}>Recipient User Type</th>
							<th scope="col" style={{ minWidth: "200px" }}>Document Name</th>
							<th scope="col" style={{ minWidth: "280px" }}>Document Description</th>
							<th scope="col" style={{ minWidth: "200px" }}>Document Type</th>
							<th scope="col" style={{ minWidth: "200px" }}>Owner Username</th>
							<th scope="col" style={{ minWidth: "200px" }}>Owner User Type</th>
							{/* <th scope="col" style={{ minWidth: "200px" }}>School ID</th> */}
							<th scope="col" style={{ minWidth: "200px" }}>Class</th>
							<th scope="col" style={{ minWidth: "200px" }}>Section</th>
							<th scope="col" style={{ minWidth: "200px" }}>Subject</th>
							<th scope="col" style={{ minWidth: "140px" }}>View</th>
							{/* {userDetails?.userType !== "STUDENT" && <th scope="col" style={{ minWidth: "140px" }}>Delete</th>} */}
						</tr>
					</thead>
					<tbody>
						{console.log(files)}
						{/* {setFileC(0)} */}
						{files.map((item, index) => {
							return (
								<>
									<Modal 
										show={item.userShowToggle}
										onHide={(e) => { setFiles(files.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}
									>
										<Modal.Header closeButton>
											<Modal.Title>Users List</Modal.Title>
										</Modal.Header>
										<Modal.Body
											style={{
												maxHeight: "calc(100vh - 210px)",
												overflowY: "scroll"
											}}
										>
											<Table striped bordered hover responsive className="mt-4 mb-4">
												<thead>
													<tr>
														<td><b>Sr. No.</b></td>
														<td><b>Name</b></td>
														<td><b>Conventional ID</b></td>
													</tr>
												</thead>
												<tbody>
													{item.document_for_user_data ? item.document_for_user_data.map((user, i) => {
														return (
															<tr key={i}>
																<td>{i + 1}</td>
																<td>{user?user[1]:user}</td>
																<td>{user?user[2]:user}</td>
																{/* <td><Form.Check type="checkbox" style={{ padding: 10 }} checked = {user.checked} onChange={e => setUserList(userList.map(us => (us.username==user.username ? {...us, checked: !user.checked} : us)))} /></td> */}
															</tr>
														)
													}) : <tr><td colSpan="4">No Users</td></tr>}
												</tbody>
											</Table>
										</Modal.Body>
										<Modal.Footer>
											<Button variant="secondary" onClick={(e) => { setFiles(files.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}>
												Close
											</Button>
										</Modal.Footer>
									</Modal>
									<tr key={index}>
										<td >{index + 1}</td>
										<td><a onClick={(e) => { setFiles(files.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}>{item.document_for_user ? item.document_for_user.length + " Users" : "0 Users"}</a></td>
										<td>{item.userType_to_show ? item.userType_to_show : ""}</td>
										<td>{item.name}</td>
										<td>{item.description}</td>
										<td>{item.document_type}</td>
										<td>{item.created_by ? item.created_by.username : ""}</td>
										<td>{item.user_type_of_creator ? item.user_type_of_creator : ""}</td>
										{/* <td>{item.school_id ? item.school_id.join(",") : ""}</td> */}
										<td>{item.class_data_names ? item.class_data_names.map(name => { return name.split(' ')[0]; }).join(' ,') : "ALL/None"}</td>
										<td>{item.class_data_names ? item.class_data_names.map(name => { return name.split(' ')[1]; }).join(' ,') : "ALL/None"}</td>
										<td>{item.subject ? item.subject.name : "ALL/None"}</td>
										<td>
											<Button variant="secondary" onClick={() => window.open(item.link_arr[0], "_blank")}>
												View
											</Button>
										</td>
										{/* <td>
											<Button variant="secondary" onClick={() => window.open(item.link_arr[0], "_blank")}>
												Delete
											</Button>
										</td> */}
									</tr>
								</>
							)

						})}
					</tbody>
				</Table>
			</div>
		</div>

	)
}

export default ShowSchoolDocument