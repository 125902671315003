import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Table } from "react-bootstrap";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";
import Pie from "../../ReportsAndAnalytics/Pie";
import { Await, useParams } from "react-router";
import NotFound from "../../NotFound/NotFound";

const SchoolLevelLMS = (data) => {
    const urlParams = useParams();
    const [dateRange, setDateRange] = useState([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    const [dateRangeType, setDateRangeType] = useState("TODAY");
    const[fetchedData,setFetchedData]=useState([])
    const [dateRangeTypes, setDateRangeTypes] = useState([
        {
            value: "TODAY",
            text: "Today",
        },
        {
            value: "YESTERDAY",
            text: "Yesterday",
        },
        {
            value: "LAST_7_DAYS",
            text: "Last 7 Days",
        },
        {
            value: "LAST_30_DAYS",
            text: "Last 30 Days",
        },
        {
            value: "CUSTOM",
            text: "Custom",
        },
        {
            value: "ALL_TIME",
            text: "All Time",
        }
    ]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [schoolList, setSchoolList] = useState([]);
function userTypeSetting(){
let setUsertype={}
if(userDetails.userType==="DISTRICT"){
    setUsertype=[
        {
            value: "STUDENT",
            text: "Student",
        },
        {
            value: "TEACHER",
            text: "Teacher",
        },
        {
            value: "SCHOOL_ADMIN",
            text: "SCHOOL ADMIN",
        },
    ]
}else if(userDetails.userType==="SCHOOL_ADMIN"){
    setUsertype=[
        {
            value: "STUDENT",
            text: "Student",
        },
        {
            value: "TEACHER",
            text: "Teacher",
        }
    ]
}else if(userDetails.userType==="TEACHER"){
    setUsertype=[
        {
            value: "STUDENT",
            text: "Student",
        }
    ]
}
return setUsertype
}
    const [userTypes, setUserTypes] = useState(userTypeSetting());
    const [userType, setUserType] = useState("STUDENT");
    const [schoolId, setSchoolId] = useState(urlParams.id ? urlParams.id : (data.school_id ? data.school_id : (userDetails.schoolId ? userDetails.schoolId : "")));
    const [classList, setClassList] = useState([]);
    const[TeacherAdminProgress,setTeacherAdminProgress]=useState({}) //for teacher nd admins only 
    // const[studentList,setStudentList]=useState([])//for student and groupoing clss
    const[overallProgress,setoverallProgress]=useState({})
    useEffect(() => {
        if (userDetails.schoolId) {
            Api.get("/school-entity/school").then((res) => {
                setSchoolList([res.data]);
                setSchoolId(res.data._id);
            });
        } else {
            Api.get("/school-entity/school").then((res) => {
                if (schoolId) {
                    setSchoolList(res.data.filter((school) => school._id === schoolId));
                    if (res.data.filter((school) => school._id === schoolId).length === 0) {
                        setSchoolId(undefined);
                    }
                } else {
                    setSchoolList([]);
                    setSchoolId(undefined);
                }
            });
        }
    }, [userDetails]);
    const[classWisePercentage,setClassWisePercentage]=useState({})
    useEffect(() => {
        // if(Object.keys(classWisePercentage).length!==0){
            Api.get("/school-entity/all-classes", {
                params: {
                    school_id: schoolId,
                },
            }).then((res) => {
                console.log("class list -==> ",res.data)
                setClassList(res.data);
            });
        // }
       
    }, [schoolId]);
   useEffect(()=>{
    if(classList.length!==0&&Object.keys(classWisePercentage).length!==0){
        let updated=[...classList]
        updated=updated.map((classObj)=>{
            let newObj={...classObj}
            if(classWisePercentage[classObj._id]){
                newObj.average=classWisePercentage[classObj._id].average
            }else{
                newObj.average=0
            }
            return newObj
        })
        setClassList(()=>updated)
        }else if(classList.length!==0&&Object.keys(classWisePercentage).length===0){
            let updated=[...classList]
            updated=updated.map((classObj)=>{
                let newObj={...classObj}
                newObj.average=0
                return newObj
            })
            setClassList(()=>updated)
            }
   },[classWisePercentage])
   useEffect(()=>{
    // console.log("thses cjeck",TeacherAdminProgress)
    // let test=Object.keys(TeacherAdminProgress)?.filter((id)=>TeacherAdminProgress[id].userType==="TEACHER")
    // console.log("test ---> ",test)
   },[TeacherAdminProgress])
    useEffect(() => {
        const data = {};
        if (dateRangeType !== "ALL_TIME") {
            data.start_time = dateRange[0].getTime();
            data.end_time = dateRange[1].getTime();
        }
        Api.get("/course/all-progress", {
            params: data,
        }).then((res) => {
            let fetchedData=res.data
            // setCoursesList(()=>fetchedData)
            setFetchedData(()=>fetchedData)
                console.log("data",data);
                console.log("fetchedData",fetchedData);
                console.log("dateRangeType",dateRangeType);
                let studentCourse=fetchedData.filter((obj)=>obj.userType==="STUDENT"&&obj.schoolId===schoolId)//filter with school in api.as of now no api funtion ..
                let AdminTeacherUsersCombined=fetchedData.filter((obj)=>obj.userType!=="STUDENT"&&obj.userType!=="DISTRICT"&&obj.schoolId===schoolId)
                let userWiseProgress={}
                console.log("AdminTeacherUsersCombined",AdminTeacherUsersCombined);
                AdminTeacherUsersCombined.map((obj)=>{
                    if(!userWiseProgress[obj.user_id]){
                        userWiseProgress[obj.user_id]={
                            total:1,
                            progress:parseInt(obj.progress*100),
                            user_id:obj.user_id,
                            user_name:obj.user_name,
                            userType:obj.userType
                        }
                        userWiseProgress[obj.user_id].average=parseInt(userWiseProgress[obj.user_id].progress/userWiseProgress[obj.user_id].total)
                        // console.log("test test userWiseProgress ==> ",userWiseProgress[obj.user_id].progress)
                        // console.log("test test userWiseProgress ==> ",userWiseProgress[obj.user_id].progress)

                    }else{
                        userWiseProgress[obj.user_id]={
                            ...userWiseProgress[obj.user_id],
                            total: userWiseProgress[obj.user_id].total+1,
                            progress:userWiseProgress[obj.user_id].progress+parseInt(obj.progress*100)
                        }
                        userWiseProgress[obj.user_id].average=parseInt(userWiseProgress[obj.user_id].progress/userWiseProgress[obj.user_id].total)
                    }

                    if(!userWiseProgress[obj.userType]){
                        userWiseProgress[obj.userType]={
                        count:1,
                        progress:parseInt(obj.progress*100),
                        }
                        userWiseProgress[obj.userType].average=parseInt(userWiseProgress[obj.userType].progress/userWiseProgress[obj.userType].count)
                    }else{
                      userWiseProgress[obj.userType].count+=1
                      userWiseProgress[obj.userType].progress+=parseInt(obj.progress*100)
                      userWiseProgress[obj.userType].average=parseInt(userWiseProgress[obj.userType].progress/userWiseProgress[obj.userType].count)
                    }
                })
                console.log(" checko AdminTeacherUsersCombined",userWiseProgress)

                setTeacherAdminProgress(()=>userWiseProgress)


                async function fetchStudentClass(){
                    let usersData=[]
                    let classWiseStudentPercentage={}
                    let count=0
                    console.log("studentCourse",studentCourse);
                    if(studentCourse.length===0){
                        setClassWisePercentage(()=>{
                            return {}
                        })
                    }
                    for await(const obj of studentCourse){
                        Api.get("/user/users-data", {
                            params: {
                                _id: obj.user_id,
                                userType:"STUDENT"
                            }}).then((res)=>{
                                count++
                                usersData.push(res.data[0])
                                if(!classWiseStudentPercentage[res.data[0].classes[0]]){
                                    classWiseStudentPercentage[res.data[0].classes[0]]={
                                        count:1,
                                        total:parseInt(obj.progress*100)
                                    }
                                    classWiseStudentPercentage[res.data[0].classes[0]].average=parseInt(classWiseStudentPercentage[res.data[0].classes[0]].total/classWiseStudentPercentage[res.data[0].classes[0]].count)
                                    // console.log("after 1",classWiseStudentPercentage,Object.keys(classWiseStudentPercentage))
                                }else{
                                    classWiseStudentPercentage[res.data[0].classes[0]]={
                                        ...classWiseStudentPercentage[res.data[0].classes[0]],
                                        count:classWiseStudentPercentage[res.data[0].classes[0]].count+1,
                                        total:classWiseStudentPercentage[res.data[0].classes[0]].total+parseInt(obj.progress*100)
                                    }
                                    classWiseStudentPercentage[res.data[0].classes[0]].average=parseInt(classWiseStudentPercentage[res.data[0].classes[0]].total/classWiseStudentPercentage[res.data[0].classes[0]].count)
                                    // console.log("after 2",classWiseStudentPercentage)
                                }
                                console.log("checking ",classWiseStudentPercentage[res.data[0].classes[0]].count)
                                console.log("checking 2",classWiseStudentPercentage[res.data[0].classes[0]].total)
                                if(!classWiseStudentPercentage.count){
                                    classWiseStudentPercentage.count=1
                                    classWiseStudentPercentage.progress=parseInt(obj.progress*100)
                                    classWiseStudentPercentage.average=parseInt(classWiseStudentPercentage.progress/classWiseStudentPercentage.count)
                                }else{
                                    classWiseStudentPercentage.count+=1
                                    classWiseStudentPercentage.progress+=parseInt(obj.progress*100)
                                    classWiseStudentPercentage.average=parseInt(classWiseStudentPercentage.progress/classWiseStudentPercentage.count)
                                }
                                if(count===studentCourse.length){
                                    console.log("classWiseStudentPercentage",classWiseStudentPercentage);
                                    setClassWisePercentage(()=>classWiseStudentPercentage)
                                }
                            })
                    }
                    
                }
                fetchStudentClass()
                // console.log("filtered fore", data);
            //    Api.get("/course/all-progress",data).then((res) => {
            //             // let filtered=res?.data?.filter((x)=>x.userType!=="DISTRICT"
            //             // // &&x.userType!=="STUDENT"
            //             // )
            //             console.log("filtered",res.data)
            //             // let studentsFiltered=res?.data?.filter((x)=>x.userType==="STUDENT")
            //             // setStudentList(()=>studentsFiltered)
            //             // setSchoolUserProgress(()=>filtered)
            //     })
            // });
        // }
        })
   
    }, [userType, dateRange,dateRangeType]); 

// useEffect(()=>{
// if(SchoolUserProgress.length!==0){
// let updatedSchoolUserProgress=[...SchoolUserProgress]
// console.log("updatedSchoolUserProgress before ==> ",updatedSchoolUserProgress);
// SchoolUserProgress.map((user,i)=>{
//     if(user.userType==="STUDENT"){
//         Api.get("/user/users-data", {
//             params: {
//                 userType:userType,
//                 schoolId:schoolId,
//                 _id:user.user_id
//             }}).then((res)=>{
//                 console.log("res ===> " ,res)
//                 let userClassId=res?.data[0]?.classes[0]
//                  user.classId=userClassId
//                  updatedSchoolUserProgress[i]=user
//                 //  return user
//             })
//     }else{
//         user.classId=null
//         updatedSchoolUserProgress[i]=user
//         // return user
//     }

// })
// console.log("updatedSchoolUserProgress after testing n ==> ",updatedSchoolUserProgress);
// }
// },[SchoolUserProgress])

    return (
        <>
            {schoolId ? <div>
                <h1 className="pt-3" align="center" style={{ fontWeight: "bold" }}>Attendance View</h1>
                <Form className="row">
                    <Form.Group className="col-12" style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>School Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={schoolList[0]?.name + " - " + schoolList[0]?.conventional_id}
                            disabled={true}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="col-12" style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>Date Range</Form.Label>
                        <Form.Control as="select" value={dateRangeType} onChange={(e) => {
                            if (e.target.value === "TODAY") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "YESTERDAY") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)]);
                            } else if (e.target.value === "LAST_7_DAYS") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "LAST_30_DAYS") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (29 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "CUSTOM") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            }
                            setDateRangeType(e.target.value)
                        }}>
                            {dateRangeTypes.map((item) => {
                                return (
                                    <option value={item.value}>{item.text}</option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="col-6" style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[0].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                            setDateRangeType("CUSTOM")
                            let temp_time_array = [new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]]
                            if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                                setDateRange([new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]])
                            } else {
                                alert("Start Date cannot be greater than End Date.")
                            }
                        }} />
                    </Form.Group>
                    <Form.Group className="col-6" style={{
                        paddingLeft: "10px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[1].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                            setDateRangeType("CUSTOM")
                            let temp_time_array = [dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]
                            if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                                setDateRange([dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))])
                            } else {
                                alert("End Date cannot be less than Start Date.")
                            }
                        }} />
                    </Form.Group>
                </Form>
                <Stack className="row" gap={3} direction="horizontal" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}>
                        <Pie percentage={ classWisePercentage["average"] ?classWisePercentage["average"].toFixed(2):0} colour="red" />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Student LMS Progress
                        </p>
                    </div>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}>
                        <Pie percentage={ 
                            TeacherAdminProgress["TEACHER"]?TeacherAdminProgress["TEACHER"]["average"]?TeacherAdminProgress["TEACHER"]["average"].toFixed(2):0:0}
                         colour="orange" />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Teacher LMS Progress
                        </p>
                    </div>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}>
                        <Pie percentage={TeacherAdminProgress["SCHOOL_ADMIN"]?TeacherAdminProgress["SCHOOL_ADMIN"]["average"]?TeacherAdminProgress["SCHOOL_ADMIN"]["average"].toFixed(2):0:0} colour="blue" />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            School Admin LMS Progress
                        </p>
                    </div>
                </Stack>
                <Form className="row">
                    <Form.Group className="col-12" style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>User Type</Form.Label>
                        <Form.Control as="select" value={userType} onChange={(e) => {
                            setUserType(e.target.value)
                        }}>
                            {userTypes.map((item) => {
                                return (
                                    <option value={item.value}>{item.text}</option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                </Form>
                <div style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    {(userType === "STUDENT") ?
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Sr. No.</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Class Name</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Progress Percentage</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Detail View</th>
                                </tr>
                            </thead>
                            <tbody>
                                {classList.map((item, index) => {
                                    return (
                                        <tr>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{item.class_name_section}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{item.average||0}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}><a style={{textDecoration: "none"}} href={`/view-class-lms-progress/${schoolId}/${item._id}`}>View</a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        :
                        <>
                        <Table striped bordered hover responsive style={{marginBottom:Object.keys(TeacherAdminProgress)?.filter((id)=>TeacherAdminProgress[id].userType===userType).length===0&&"0px"}}>
                            <thead>
                                <tr>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>S.No</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>User Name</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>userType</th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Progress Percentage </th>
                                    <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Detail View </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(TeacherAdminProgress)?.filter((id)=>TeacherAdminProgress[id].userType===userType).map((id, index) => {
                                    const{user_name,average,userType}=TeacherAdminProgress[id]
                                    return (
                                        <tr>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{user_name}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{userType}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{average}</td>
                                            <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            <a
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                                href={`/view-user-lms-progress/${id}`}
                                            >
                                                View
                                            </a>
                                        </td>
                                        </tr>
                                    )
                                })}
                               
                            </tbody>
                         
                        </Table>
                        {Object.keys(TeacherAdminProgress)?.filter((id)=>TeacherAdminProgress[id].userType===userType).length===0&&
                            <div className="p-2" style={{width:"100%", background: "white", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>No data</div>
                        }
                        </>
                        
                    }
                </div>
            </div>
                :
                <NotFound />
            }
        </>
    )
}

export default SchoolLevelLMS;
