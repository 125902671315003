import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ItemsCarousel from 'react-items-carousel';
import Api from "../../Api/axios";
import "./CourseLibrary.css";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { Form, Button, Stack } from 'react-bootstrap';
import { Multiselect } from 'multiselect-react-dropdown';
import MultiUserLogin from '../UserManagement/MultiUserLogin';
import { Modal } from 'react-bootstrap';
import MultiUserWatching from './MultiUserWatching';

const CourseLibrary = () => {
    const [{ user, userDetails, initialState, loggedIn }, dispatch] = useDataLayerValue();
    const [courseListByMasterClass, setCourseListByMasterClass] = useState([]);
    const [courseListByYouthIndia, setCourseListByYouthIndia] = useState([]);
    const [courseListBySchool, setCourseListBySchool] = useState([]);
    const [courseListByYou, setCourseListByYou] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [filterUserClasses, setFilterUserClasses] = useState([]);
    const [filterUserSubjects, setFilterUserSubjects] = useState([]);
    const [filterSchoolId, setFilterSchoolId] = useState("");
    const [filterCourseName, setFilterCourseName] = useState("");
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const navigate = useNavigate();
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    useEffect(() => {
        if (!userDetails?.schoolId) {
            Api.get("school-entity/school").then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                res.data = [{ _id: "", name: "All" }, ...res.data];
                setSchoolList(res.data);
            }
            )
        }
        Api.get("preconfig-entites/subjects").then((res) => {
            console.log("entites/subjects", res.data);
            res.data.sort((a, b) => a.name.localeCompare(b.name));
            setSubjectList(res.data);
        })
    }, [userDetails]);
    useEffect(() => {
        if (filterSchoolId) {
            Api.get("school-entity/all-classes?school_id=" + filterSchoolId).then((res) => {
                setClassList(res.data);
                setFilterUserClasses([]);
            })
        } else if (userDetails?.schoolId) {

            Api.get("school-entity/all-classes?school_id=" + userDetails?.schoolId).then((res) => {
                setClassList(res.data);
                setFilterUserClasses([]);
            })
        } else {
            setClassList([]);
            setFilterUserClasses([]);
        }
    }, [userDetails, filterSchoolId]);
    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        Api.get("/course/get-yie-courses").then((res) => {
            setCourseListByYouthIndia(res.data);
        }).finally(() => {
            Api.get("/course/get-inhouse-courses").then((res) => {
                setCourseListBySchool(res.data);
            }).finally(() => {
                Api.get("/course/master-class").then((res) => {
                    setCourseListByMasterClass(res.data);
                }).finally(() => {
                    dispatch({
                        type: "SET_LOADING",
                        loading: false,
                    });
                })
            })
        })
        var madeCourse = [];
        if (userDetails?.schoolId) {
            madeCourse = courseListBySchool?.filter((course) => course.created_by_id === userDetails?._id);
        }
        setCourseListByYou(madeCourse);
    }, [userDetails]);
    const [filteredCourseListByMasterClass, setFilteredCourseListByMasterClass] = useState([]);
    const [filteredCourseListByYouthIndia, setFilteredCourseListByYouthIndia] = useState([]);
    const [filteredCourseListBySchool, setFilteredCourseListBySchool] = useState([]);
    const [filteredCourseListByYou, setFilteredCourseListByYou] = useState([]);
    useEffect(() => {
        var filteredCourseListByMasterClass = courseListByMasterClass ? courseListByMasterClass : [];
        var filteredCourseListByYouthIndia = courseListByYouthIndia ? courseListByYouthIndia : [];
        var filteredCourseListBySchool = courseListBySchool ? courseListBySchool : [];
        var filteredCourseListByYou = courseListByYou ? courseListByYou : [];
        if (filterSchoolId) {
            filteredCourseListBySchool = filteredCourseListBySchool?.filter((course) => course.school_id === filterSchoolId);
            filteredCourseListByYou = filteredCourseListByYou?.filter((course) => course.school_id === filterSchoolId);
        }
        if (filterUserClasses?.length !== 0) {
            filteredCourseListBySchool = filteredCourseListBySchool?.filter((course) => filterUserClasses?.some((classObj) => classObj?._id === course?.class_id));
            filteredCourseListByYou = filteredCourseListByYou?.filter((course) => filterUserClasses?.some((classObj) => classObj?._id === course?.class_id));
            filteredCourseListByYouthIndia = filteredCourseListByYouthIndia?.filter((course) => (filterUserClasses?.some((classObj) => classObj?.class_name === course?.class_name) || (course?.class_name === "GLOBAL")));
            filteredCourseListByMasterClass = filteredCourseListByMasterClass?.filter((course) => (filterUserClasses?.some((classObj) => classObj?.class_name === course?.class_name) || (course?.class_name === "GLOBAL")));
        }
        if (filterUserSubjects?.length !== 0) {
            filteredCourseListBySchool = filteredCourseListBySchool?.filter((course) => filterUserSubjects?.some((subject) => subject?._id === course?.subject_id));
            filteredCourseListByYou = filteredCourseListByYou?.filter((course) => filterUserSubjects?.some((subject) => subject?._id === course?.subject_id));
            filteredCourseListByYouthIndia = filteredCourseListByYouthIndia?.filter((course) => filterUserSubjects?.some((subject) => subject?._id === course?.subject_id));
            filteredCourseListByMasterClass = filteredCourseListByMasterClass?.filter((course) => filterUserSubjects?.some((subject) => subject?._id === course?.subject_id));
        }
        if (filterCourseName) {
            filteredCourseListBySchool = filteredCourseListBySchool?.filter((course) => course?.subject_name?.toLowerCase().includes(filterCourseName?.toLowerCase()));
            filteredCourseListByYou = filteredCourseListByYou?.filter((course) => course?.subject_name?.toLowerCase().includes(filterCourseName?.toLowerCase()));
            filteredCourseListByYouthIndia = filteredCourseListByYouthIndia?.filter((course) => course?.subject_name?.toLowerCase().includes(filterCourseName?.toLowerCase()));
            filteredCourseListByMasterClass = filteredCourseListByMasterClass?.filter((course) => course?.subject_name?.toLowerCase().includes(filterCourseName?.toLowerCase()));
        }
        setFilteredCourseListByMasterClass(filteredCourseListByMasterClass);
        setFilteredCourseListByYouthIndia(filteredCourseListByYouthIndia);
        setFilteredCourseListBySchool(filteredCourseListBySchool);
        setFilteredCourseListByYou(filteredCourseListByYou);
    }, [filterSchoolId, filterUserClasses, filterUserSubjects, filterCourseName, courseListByMasterClass, courseListByYouthIndia, courseListBySchool, courseListByYou]);
    const [showMultiUserModal, setShowMultiUserModal] = useState(false);
    return (
        <div
            style={{
                backgroundColor: "#FFF",
                paddingTop: "20px",
                minHeight: windowSize.innerHeight - 50,
            }}
        >
            <Modal
                show={showMultiUserModal}
                onHide={() => setShowMultiUserModal(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Multiple User Login
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        maxHeight: "calc(100vh - 200px)",
                        overflowY: "auto",
                    }}
                >
                    <MultiUserLogin />
                    {/* {userDetails?.userType === "TEACHER" ? <MultiUserWatching /> : } */}
                </Modal.Body>
            </Modal>
            <h1
                style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                    fontWeight: "bold",
                }}
            >
                Course Library
            </h1>
            <div className='mt-2 mb-2 ps-2 pe-2 row' style={{ justifyContent: "center" }}>
                {schoolList.length !== 0 &&
                    <Form.Group align="left" className="pt-2 pb-2 col-12 col-sm-6 col-lg-4" controlId="school">
                        <Form.Label align="left">School</Form.Label>
                        <Form.Select
                            value={filterSchoolId}
                            onChange={(e) => { setFilterSchoolId(e.target.value) }}
                        >
                            {schoolList?.map((school) => {
                                return (
                                    <option value={school._id}>{school.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                }
                {(classList.length !== 0) ?
                    <Form.Group align="left" className="pt-2 pb-2 col-12 col-sm-6 col-lg-4" controlId="class">
                        <Form.Label>Class</Form.Label>
                        {console.log(classList, classList.length)}
                        <Multiselect
                            showArrow
                            options={classList}
                            displayValue="class_name_section"
                            groupBy="class_name"
                            showCheckbox={true}
                            selectedValues={filterUserClasses}
                            onSelect={(selectedList, selectedItem) => {
                                console.log("selectedList", selectedList, filterUserClasses)
                                setFilterUserClasses(selectedList)
                            }}
                            onRemove={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                            style={{
                                marginTop: "0",
                            }}
                        />
                    </Form.Group>
                    : null
                }
                {(subjectList.length !== 0) ?
                    <Form.Group align="left" className="pt-2 pb-2 col-12 col-sm-6 col-lg-4" controlId="class">
                        <Form.Label>Subjects</Form.Label>
                        <Multiselect
                            showArrow
                            options={subjectList}
                            displayValue="name"
                            showCheckbox={true}
                            selectedValues={filterUserSubjects}
                            onSelect={(selectedList, selectedItem) => { setFilterUserSubjects(selectedList) }}
                            onRemove={(selectedList, selectedItem) => { setFilterUserSubjects(selectedList) }}
                            style={{
                                marginTop: "0",
                            }}
                        />
                    </Form.Group>
                    : null
                }

            </div>
            <div className='mt-2 mb-2 ps-2 pe-2' style={{ justifyContent: "center" }}>
                <Form.Control
                    type="text"
                    placeholder="Search by Course Name"
                    value={filterCourseName}
                    onChange={(e) => { setFilterCourseName(e.target.value) }}
                />
            </div>
            <div
                style={{
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant="primary"
                    size='lg'
                    style={{
                        width: '100%',
                    }}
                    onClick={() => setShowMultiUserModal(true)}
                >
                    Multiple Users Watching
                </Button>
            </div>
            <div
                style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    maxWidth: "100%",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                }}
            >
                {filteredCourseListByMasterClass.length ? <><h1
                    style={{
                        textAlign: "center",
                        marginTop: "10px",
                        marginBottom: "20px",
                        fontWeight: "bold",
                    }}
                >
                    Master Class
                </h1>
                    <div className='row'
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        {filteredCourseListByMasterClass?.map((item) => {
                            console.log(Math.min((windowSize.innerWidth - 85) / 1.15, 300));
                            return (
                                <div className='col'
                                    style={{
                                        minWidth: Math.min((windowSize.innerWidth - 65), 290),
                                        maxWidth: Math.min((windowSize.innerWidth - 65), 290),
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                    }}
                                ><div
                                    className="course-card shadow"
                                    onClick={() => {
                                        navigate("/course-player/" + item._id);
                                    }}
                                >
                                        <img
                                            src={item?.thumbnail_link ? item.thumbnail_link : ""}
                                            alt="course"
                                            style={{
                                                borderRadius: "10px 10px 0px 0px",
                                                minHeight: "180px",
                                                maxHeight: "180px",
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontSize: "21px",
                                                padding: "10px 10px 0px 10px",
                                                wordBreak: "keep-all",
                                                fontWeight: "bold",
                                                margin: "0",
                                                textAlign: "center",
                                            }}
                                        >{item?.subject_name}</p>
                                        <p
                                            style={{
                                                padding: "5px 20px 20px 20px",
                                                wordBreak: "keep-all",
                                                textAlign: "justify",
                                                margin: "0",
                                            }}
                                        >
                                            <p
                                                className="paragraph"
                                                style={{
                                                    maxHeight: "150px",
                                                    minHeight: "150px",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                }}
                                            >
                                                {item?.description}
                                            </p>
                                            {/* <br /> */}
                                            <b>Class:</b> {item?.class_name}
                                            {/* <br />
                                <b>Subject:</b> {item?.subject_name} */}
                                            <br />
                                            <b>Created By:</b> {item?.created_by_name}
                                        </p>
                                    </div></div>
                            )
                        })}
                    </div></> : null}
                {filteredCourseListBySchool.length ? <><h1
                    style={{
                        textAlign: "center",
                        marginTop: "10px",
                        marginBottom: "20px",
                        fontWeight: "bold",
                    }}
                >
                    Courses Made in School
                </h1>
                    <div className='row'
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        {filteredCourseListBySchool?.map((item) => {
                            console.log(Math.min((windowSize.innerWidth - 85) / 1.15, 300));
                            return (
                                <div className='col'
                                    style={{
                                        minWidth: Math.min((windowSize.innerWidth - 65), 290),
                                        maxWidth: Math.min((windowSize.innerWidth - 65), 290),
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                    }}
                                ><div
                                    className="course-card shadow"
                                    onClick={() => {
                                        navigate("/course-player/" + item._id);
                                    }}
                                >
                                        <img
                                            src={item?.thumbnail_link ? item.thumbnail_link : ""}
                                            alt="course"
                                            style={{
                                                borderRadius: "10px 10px 0px 0px",
                                                minHeight: "180px",
                                                maxHeight: "180px",
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontSize: "21px",
                                                padding: "10px 10px 0px 10px",
                                                wordBreak: "keep-all",
                                                fontWeight: "bold",
                                                margin: "0",
                                                textAlign: "center",
                                            }}
                                        >{item?.subject_name}</p>
                                        <p
                                            style={{
                                                padding: "5px 20px 20px 20px",
                                                wordBreak: "keep-all",
                                                textAlign: "justify",
                                                margin: "0",
                                            }}
                                        >
                                            <p
                                                className="paragraph"
                                                style={{
                                                    maxHeight: "150px",
                                                    minHeight: "150px",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                }}
                                            >
                                                {item?.description}
                                            </p>
                                            {/* <br /> */}
                                            <b>Class:</b> {item?.class_name}
                                            {/* <br />
                                <b>Subject:</b> {item?.subject_name} */}
                                            <br />
                                            <b>Created By:</b> {item?.created_by_name}
                                        </p>
                                    </div></div>
                            )
                        })}
                    </div></> : null}
                {filteredCourseListByYouthIndia.length ? <><h1
                    style={{
                        textAlign: "center",
                        marginTop: "30px",
                        marginBottom: "20px",
                        fontWeight: "bold",
                    }}
                >
                    Courses By Youth India E-School
                </h1>
                    <div className='row'
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        {filteredCourseListByYouthIndia?.map((item) => {
                            console.log(Math.min((windowSize.innerWidth - 85) / 1.15, 300));
                            return (
                                <div className='col'
                                    style={{
                                        minWidth: Math.min((windowSize.innerWidth - 65), 290),
                                        maxWidth: Math.min((windowSize.innerWidth - 65), 290),
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                    }}
                                ><div
                                    className="course-card shadow"
                                    onClick={() => {
                                        navigate("/course-player/" + item._id);
                                    }}
                                >
                                        <img
                                            src={item?.thumbnail_link ? item.thumbnail_link : ""}
                                            alt="course"
                                            style={{
                                                borderRadius: "10px 10px 0px 0px",
                                                minHeight: "180px",
                                                maxHeight: "180px",
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontSize: "21px",
                                                padding: "10px 10px 0px 10px",
                                                wordBreak: "keep-all",
                                                fontWeight: "bold",
                                                margin: "0",
                                                textAlign: "center",
                                            }}
                                        >{item?.subject_name}</p>
                                        <p
                                            style={{
                                                padding: "5px 20px 20px 20px",
                                                wordBreak: "keep-all",
                                                textAlign: "justify",
                                                margin: "0",
                                            }}
                                        >
                                            <p
                                                className="paragraph"
                                                style={{
                                                    maxHeight: "150px",
                                                    minHeight: "150px",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                }}
                                            >
                                                {item?.description}
                                            </p>
                                            {/* <br /> */}
                                            <b>Class:</b> {item?.class_name}
                                            {/* <br />
                                <b>Subject:</b> {item?.subject_name} */}
                                            <br />
                                            <b>Created By:</b> {item?.created_by_name}
                                        </p>
                                    </div></div>
                            )
                        })}
                    </div></> : null}
                {filteredCourseListByYou.length ? <><h1
                    style={{
                        textAlign: "center",
                        marginTop: "30px",
                        marginBottom: "20px",
                        fontWeight: "bold",
                    }}
                >
                    Courses By You
                </h1>
                    <div className='row'
                        style={{
                            justifyContent: "center",
                        }}
                    >
                        {filteredCourseListByYou?.map((item) => {
                            console.log(Math.min((windowSize.innerWidth - 85) / 1.15, 300));
                            return (
                                <div className='col'
                                    style={{
                                        minWidth: Math.min((windowSize.innerWidth - 65), 290),
                                        maxWidth: Math.min((windowSize.innerWidth - 65), 290),
                                        paddingTop: "20px",
                                        paddingBottom: "20px",
                                    }}
                                ><div
                                    className="course-card shadow"
                                    onClick={() => {
                                        navigate("/course-player/" + item._id);
                                    }}
                                >
                                        <img
                                            src={item?.thumbnail_link ? item.thumbnail_link : ""}
                                            alt="course"
                                            style={{
                                                borderRadius: "10px 10px 0px 0px",
                                                minHeight: "180px",
                                                maxHeight: "180px",
                                                minWidth: "100%",
                                                maxWidth: "100%",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontSize: "21px",
                                                padding: "10px 10px 0px 10px",
                                                wordBreak: "keep-all",
                                                fontWeight: "bold",
                                                margin: "0",
                                                textAlign: "center",
                                            }}
                                        >{item?.subject_name}</p>
                                        <p
                                            style={{
                                                padding: "5px 20px 20px 20px",
                                                wordBreak: "keep-all",
                                                textAlign: "justify",
                                                margin: "0",
                                            }}
                                        >
                                            <p
                                                className="paragraph"
                                                style={{
                                                    maxHeight: "150px",
                                                    minHeight: "150px",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                }}
                                            >
                                                {item?.description}
                                            </p>
                                            {/* <br /> */}
                                            <b>Class:</b> {item?.class_name}
                                            {/* <br />
                                <b>Subject:</b> {item?.subject_name} */}
                                            <br />
                                            <b>Created By:</b> {item?.created_by_name}
                                        </p>
                                    </div></div>
                            )
                        })}
                    </div></> : null}
            </div>
        </div>
    )
}

export default CourseLibrary;
