import React, { useState, useEffect } from "react";
import Notice_Board from "../Notice_Board";
import "./notices_admin.css";
import Notices_Admin_Create from "./Notices_Admin_Create";
import { Link } from "react-router-dom";
import Api from "../../../Api/axios";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

function Notices_Admin() {
  const [notices, setNotices] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const fetchNotices = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.get("/notices")
      .then((res) => {
        let tempNotices = [];
        res.data.forEach((notice) => {
          if (notice.notice_for === userDetails.userType) {
            tempNotices.push(notice);
          }
        });
        setNotices(tempNotices);
      })
      .catch((err) => console.log(err.response.data.message));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  return (
    <div className="Notices_Admin">
      <div className="notices-admin-container">
        <Notice_Board notices={notices} />
        <Link to="create-notice">
          <button className="notices-admin-btn">Create Notices</button>
        </Link>
      </div>
    </div>
  );
}

export default Notices_Admin;
