import React, { useState, useEffect } from 'react';
import { Box, option } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Api from "../../../Api/axios";
import { MenuItem } from "@mui/material";
import Table from 'react-bootstrap/Table';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useNavigate } from "react-router-dom";



const AddYieChapter = () => {

    const navigate = useNavigate();
    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();

    const [course, setCourse] = useState("");
    const [chapterId, setChapterId] = useState("");
    const [courses, setCourses] = useState([]);
    const [chapters, setChapters] = useState([{ id: uuidv4(), chapter_name: "", chapter_description: "" }]);
    const [classNames, setClassNames] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [className, setClassName] = useState('ALL');
    const [createdChapters, setCreatedChapters] = useState();

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let temp = [{ id: "ALL", name: "ALL" }]
        for (let i = 1; i <= 12; i++) {
            temp.push({ id: i, name: i })

        }
        setClassNames(temp)

        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    }, [])

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let temp = {}
        if (className !== "ALL") {
            temp = {
                class_name: className
            }
        }
        Api.post("/course/get-yie-courses-filter", temp).then((res) => {
            setCourses(res.data);
            setCourse(res.data[0]?._id ? res.data[0]._id : "")
        }).catch((err) => {
            console.log(err);
        })
        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    }, [className])

    const createBulkChapters = () => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        const data = {
            chapters: chapters,
            course_id: course,
        }
        Api.post("/course/create-bulk-chapter", data).then((res) => {
            setCreatedChapters(res.data);
            setPageNo(3)
        }).catch((err) => {
            console.log(err);
            setPageNo(-1)
        }).finally(() => {
            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        })
    }



    const doNothing = (event) => {
        return true;
    }

    return (
        <>
            <center>
                <h1 className="m-4">Create YIE Chapter</h1>

                {pageNo == 1
                    ?

                    <div className="shadow-lg p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                        <Form.Group controlId="formFile" className="m-3">
                            <Stack type="vartical" gap={4}>
                                <InputGroup>
                                    <InputGroup.Text>Select Class</InputGroup.Text>
                                    <Form.Select aria-label="Select Class" value={className}
                                        onChange={(e) => setClassName(e.target.value)} >

                                        {classNames.map((cl, i) => (
                                            <option key={i} value={cl.id} style={{ width: "100%" }}>
                                                {cl.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>Select Course</InputGroup.Text>
                                    <Form.Select aria-label="Select Class" value={course.subject_name}
                                        onChange={(e) => setCourse(e.target.value)} >

                                        {courses.map((cl, i) => (
                                            <option key={i} value={cl._id} style={{ width: "100%" }}>
                                                {cl.subject_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <center style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outline-secondary" size="lg" onClick={() => setPageNo(2)} style={{ minWidth: '150px' }} >
                                        Next
                                    </Button>
                                </center>
                            </Stack>
                        </Form.Group>
                    </div>
                    : pageNo == 2 ?
                        <>
                            {chapters.map((chapter, i) => {
                                return (
                                    <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                                        <Form.Group controlId="formFile" className="m-3">
                                            <Stack type="vartical" gap={4}>
                                                <InputGroup>
                                                    <InputGroup.Text>
                                                        Chapter Name
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        aria-label="Default"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        value={chapter.chapter_name}
                                                        onChange={(e) => setChapters(chapters.map(chap => (chap.id === chapter.id ? { ...chap, chapter_name: e.target.value } : chap)))}
                                                    // placeholder="Enter Title"
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <InputGroup.Text>Description</InputGroup.Text>
                                                    <Form.Control as="textarea" aria-label="With textarea" aria-describedby="inputGroup-sizing-default"
                                                        value={chapter.chapter_description}
                                                        onChange={(e) => setChapters(chapters.map(chap => (chap.id === chapter.id ? { ...chap, chapter_description: e.target.value } : chap)))}
                                                    />
                                                </InputGroup>
                                            </Stack>

                                        </Form.Group>
                                        <Button variant="outline-danger" size="lg"
                                            disabled={chapters.length <= 1}
                                            onClick={(e) => setChapters(chapters.filter(ch => ch.id !== chapter.id))}>
                                            <DeleteForeverIcon /> Delete
                                        </Button>
                                    </div>)
                            })}
                            <div className="mt-4 p-4 col-12 col-sm-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                                <Button variant="outline-secondary" onClick={(e) => { setChapters([...chapters, { id: uuidv4(), chapter_name: "", chapter_description: "" }]) }}>
                                    <AddIcon />
                                    Add More
                                </Button>
                            </div>
                            <div className=" p-4 col-12 col-sm-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                                <Stack direction="horizontal">
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                                        <Button variant="outline-secondary" size="lg" align="right" onClick={createBulkChapters} >
                                            Create Chapters
                                        </Button>
                                    </div>
                                </Stack>
                            </div>
                        </>
                        : pageNo === -1 ?
                            <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">

                            </div>
                            :
                            <center>
                                <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                                    <img src={`${process.env.PUBLIC_URL}/Images/sucess.png`} style={{ width: '100px' }} alt="" />
                                    <h3 className="m-4">Course Successfully </h3>
                                    <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                                        <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => {
                                            navigate(`/course/create-yie-content`)
                                        }} >
                                            Add Content To These Chapters
                                        </Button>
                                        <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
                                            Go to Dashboard
                                        </Button>
                                    </Stack>
                                </div>


                            </center>

                }
            </center >
        </>
    )

};


export default AddYieChapter;