import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Box, MenuItem, Multiselect } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const ShowSchoolNotices = () => {
  const userTypes = [
    ["DISTRICT", "District"],
    ["SCHOOL_ADMIN", "School Admin"],
    ["TEACHER", "Teacher"],
    ["PARENT", "Parent"],
    ["STUDENT", "Student"],
  ];
  const documentTypes = [
    ["Academic", "Academic"],
    ["Personal", "Personal"],
    ["Administrative", "Administrative"],
    ["Others", "Others"],
  ];
  const [classDict, setClassDict] = useState([]);
  const [sectionDict, setSectionDict] = useState({ ALL: [] });
  const [subjectDict, setSubjectDict] = useState([]);
  const [chapterDict, setChapterDict] = useState([]);
  const [topicDict, setTopicDict] = useState([]);
  const [subTopicDict, setSubTopicDict] = useState([]);
  const [userDict, setUserDict] = useState([]);

  const [documentType, setDocumentType] = useState("ALL");
  const [ownerUserType, setOwnerUserType] = useState("ALL");
  const [ownerUser, setOwnerUser] = useState("ALL");
  const [recipientUser, setRecipientUser] = useState("ALL");
  const [className, setClassName] = useState("ALL");
  const [subjectName, setSubjectName] = useState("ALL");
  const [chapterName, setChapterName] = useState("ALL");
  const [topicName, setTopicName] = useState("ALL");
  const [subTopicName, setSubTopicName] = useState("ALL");
  const [noticeFor, setNoticeFor] = useState("ALL");
  const [
    { user, userDetails, initialState, loggedIn, class_teacher_class_details },
  ] = useDataLayerValue();
  const currentUserTypeIndex = userTypes.findIndex(
    (userType) => userType[0] === userDetails?.userType
  );
  const [change, setChange] = useState(true);
  const [files, setFiles] = useState([]);
  const [{ type }, dispatch] = useDataLayerValue();
  const [fileC, setFileC] = useState([]);
  const [schools, setSchools] = useState([]);
  const [sectionName, setSectionName] = useState("ALL");
  React.useEffect(async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    setFiles([]);
    // if (userDetails?.userType !== "DISTRICT" && userDetails?.userType !== "CENTRAL") {
    //     await Api.get("school-entity/school").then((res) => {
    //         setSchools(res.data);
    //         let temp = []
    //         res.data.map((school) => {
    //             temp.push(school._id)
    //         })

    //         Api.get("notices", { params: { school_ids: temp } }).then((res) => {
    //             setFiles(res.data);
    //             setFileC(res.data);
    //         })
    //     });
    //     // let schoolids = schools.map((school) => school.id);
    // }

    await Api.get("notices").then((res) => {
      setFiles(res.data);
      setFileC(res.data);
    });

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  }, []);
  // React.useEffect(async () => {
  // 	dispatch({
  // 		type: "SET_LOADING",
  // 		loading: true,
  // 	});
  // 	setFiles([]);
  // 	await Api.get("notices", { params: { notice_for_user: [userDetails?._id], } }).then((res) => {
  // 		setFiles(res.data);
  // 	})
  // 	dispatch({
  // 		type: "SET_LOADING",
  // 		loading: false,
  // 	});
  // }, [change]);
  useEffect(async () => {
    let temp_dict = { ALL: { ALL: [] } };
    await Api.get("live-class").then((res) => {
      setClassDict(res.data);
    });
    // console.log(temp_dict);
  }, []);
  // React.useEffect(() => {
  // React.useEffect(() => {
  // 	Api.get("live-class/subject").then((res) => {
  // 		setSubjectDict(res.data);
  // 	})
  useEffect(() => {
    console.log("change");
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    const data = {
      notice_for: noticeFor === "ALL" ? "" : [noticeFor],
      userType: ownerUserType === "ALL" ? "" : ownerUserType,
      class_data_names: className === "ALL" ? "" : [className],
    };
    Api.get("notices", { params: data })
      .then((res) => {
        setFiles(res.data);
        // console.log(res.data);
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      });
  }, [className, ownerUserType, noticeFor]);
  // }, []);
  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
    if (event.target.value === "ALL") {
      files = fileC;
    } else {
      // filter files by usertype = ownertype
      files = files.filter((file) => file.userType === event.target.value);
    }
  };
  const handleOwnerUserTypeChange = (event) => {
    setOwnerUserType(event.target.value);
  };
  const handleClassChange = async (e) => {
    setClassName(e.target.value);
    // filter files by class_name_data array including class_name
    files.filter((file) => file.class_name_data.includes(e.target.value));
  };
  const handleSubjectChange = async (e) => {
    setSubjectName(e.target.value);
  };

  const handleDeleteSchoolNotices = (id) => {
    console.log(id)
    // Api.delete("/" + id).then((res) => {
    //   alert("sucessfully deleted")
    // })
  };

  return (
    <div
      className="ps-2 pe-2"
      style={{
        // width: '100%',
        minHeight: "94vh",
        backgroundColor: "#FFF",
        paddingBottom: "40px",
      }}
      align="center"
    >
      <h1 className="p-4" style={{ fontWeight: "bold" }}>
        List of School Notices
      </h1>
      <div className="m-4 row" style={{ justifyContent: "center" }}>
        <Form.Group className="p-2 col-12 col-sm-6 col-lg-3" align="left">
          <Form.Label align="left">Notice By</Form.Label>
          <Form.Control
            as="select"
            label="Document Type"
            // variant="filled"
            value={ownerUserType}
            onChange={handleOwnerUserTypeChange}
          >
            <option key="ALL" value="ALL" style={{ width: "100%" }}>
              ALL
            </option>
            {userTypes.map((userType) => (
              <option
                key={userType[0]}
                value={userType[0]}
                style={{ width: "100%" }}
              >
                {userType[1]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {/* <div>
        <TextField
          select
          label="Notice By"
          variant="filled"
          style={{ minWidth: 230 }}
          className="m-2"
          value={ownerUserType}
          onChange={handleOwnerUserTypeChange}
        >
          <MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
            ALL
          </MenuItem>
          {userTypes.map((userType, i) => {
            return (
              <MenuItem
                key={userType[0]}
                value={userType[0]}
                style={{ width: "100%" }}
              >
                {userType[1]}
              </MenuItem>
            );
          })}
        </TextField> */}
        <Form.Group className="p-2 col-12 col-sm-6 col-lg-3" align="left">
          <Form.Label align="left">Notice For</Form.Label>
          <Form.Control
            as="select"
            label="Document Type"
            // variant="filled"
            value={noticeFor}
            onChange={(e) => setNoticeFor(e.target.value)}
          >
            <option key="ALL" value="ALL" style={{ width: "100%" }}>
              ALL
            </option>
            {userTypes.map((userType) => (
              <option
                key={userType[0]}
                value={userType[0]}
                style={{ width: "100%" }}
              >
                {userType[1]}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {/* <TextField
          select
          label="Notice For"
          variant="filled"
          style={{ minWidth: 230 }}
          className="m-2"
          value={noticeFor}
          onChange={(e) => setNoticeFor(e.target.value)}
        >
          <MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
            ALL
          </MenuItem>
          {userTypes.map((userType, i) => {
            return (
              <MenuItem
                key={userType[0]}
                value={userType[0]}
                style={{ width: "100%" }}
              >
                {userType[1]}
              </MenuItem>
            );
          })}
        </TextField> */}
        <Form.Group className="p-2 col-12 col-sm-6 col-lg-3" align="left">
          <Form.Label align="left">Class</Form.Label>
          <Form.Control
            as="select"
            label="Document Type"
            // variant="filled"
            value={className}
            onChange={handleClassChange}
          >
            <option key="ALL" value="ALL" style={{ width: "100%" }}>
              ALL
            </option>
            {classDict.map((cl, i) => (
              <option
                key={cl._id}
                value={cl.class_name_section}
                style={{ width: "100%" }}
              >
                {cl.class_name_section}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* <TextField
          select
          label="Class"
          variant="filled"
          style={{ minWidth: 230 }}
          className="m-2"
          value={className}
          onChange={handleClassChange}
        >
          <MenuItem key="ALL" value="ALL" style={{ width: "10%" }}>
            ALL
          </MenuItem>
          {classDict.map((cl, i) => {
            return (
              <MenuItem
                key={cl._id}
                value={cl.class_name_section}
                style={{ width: "10%" }}
              >
                {cl.class_name_section}
              </MenuItem>
            );
          })}
        </TextField> */}
      </div>

      {/* <TextField
					select
					label="Document Type"
					variant="filled"
					style={{ minWidth: 230 }}
					value={documentType}
					onChange={handleDocumentTypeChange}
					className="m-2"
				>
				<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
						ALL
					</MenuItem>
					{documentTypes.map((documentType) => (
						<MenuItem key={documentType[0]} value={documentType[0]} style={{ width: "100%" }}>
						{documentType[1]}
						</MenuItem>
						))}
					</TextField> */}
      {/* <div>
				<TextField
					select
					label="Notice By"
					variant="filled"
					style={{ minWidth: 230 }}
					className="m-2"
					value={ownerUserType}
					onChange={handleOwnerUserTypeChange}
				>
					<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
						ALL
					</MenuItem>
					{
						userTypes.map((userType, i) => {
							return (
								<MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
									{userType[1]}
								</MenuItem>
							)
						})
					}
				</TextField>
				{(userDetails?.userType === "TEACHER") &&
					<>
						<TextField
							select
							label="For Class"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={className}
							onChange={handleClassChange}
						>
							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
								ALL
							</MenuItem>
							{
								classDict.map((cl, i) => {
									return (
										<MenuItem key={cl._id} value={cl._class_name_section} style={{ width: "100%" }}>
											{cl.class_name_section}
										</MenuItem>
									)
								})
							}
						</TextField> */}
      {/* <TextField
							select
							label="Section"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={sectionName}
							onChange={(e) => setSectionName(e.target.value)}
						>
							{Object.keys(sectionDict).map((sectionVar, i) => {
								return (
									<MenuItem key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
										{sectionVar}
									</MenuItem>
								)
							})
							}
						</TextField>
						<TextField
							select
							label="Subject"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={subjectName}
							onChange={handleSubjectChange}
						>
							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
								ALL
							</MenuItem>
							{subjectDict.map((subjectDictVar, i) => {
								// console.log(classDict[className][sectionName])
								if (className == "ALL" || (classDict[className][sectionName] && classDict[className][sectionName][0] === subjectDictVar.classroom_id)) {
									return (
										<MenuItem key={subjectDictVar._id} value={subjectDictVar._id} style={{ width: "100%" }}>
											{subjectDictVar.name}
										</MenuItem>
									)
								}
							})
							}
						</TextField> */}
      {/* </>
				}
			</div> */}
      {/* <Button className="m-3" variant="secondary" onClick={filterChange} style={{ minWidth: "100px" }}>
				Filter
			</Button> */}
      <div className="m-4">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col" style={{ minWidth: "80px" }}>
                Sr. No.
              </th>
              <th scope="col" style={{ minWidth: "200px" }}>
                Notice Name
              </th>
              <th scope="col" style={{ minWidth: "280px" }}>
                Notice Description
              </th>
              <th scope="col" style={{ minWidth: "200px" }}>
                Notice Date
              </th>
              {/* {console.log(userDetails?.userType , 	(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT"))} */}
              {(userDetails?.userType === "TEACHER" ||
                userDetails?.userType === "STUDENT") && (
                <th scope="col" style={{ minWidth: "200px" }}>
                  Class
                </th>
              )}
              <th scope="col" style={{ minWidth: "140px" }}>
                View
              </th>
              <th scope="col" style={{ minWidth: "140px" }}>
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {console.log(files, "files")}
            {/* {setFileC(0)} */}
            {files.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{item.date}</td>
                  {(userDetails?.userType === "TEACHER" ||
                    userDetails?.userType === "STUDENT") && (
                    <td>
                      {item.class_data_names
                        ? item.class_data_names
                            .map((name) => {
                              return name;
                            })
                            .join(" ,")
                        : "ALL"}
                    </td>
                  )}
                  <td>
                    <Button
                      variant="light"
                      onClick={() => window.open(item.link, "_blank")}
                    >
                      View
                    </Button>
                  </td>
                  <td>
                    <Button variant="light" onClick={() => handleDeleteSchoolNotices(item._id)}>
                      <DeleteForeverIcon />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ShowSchoolNotices;
