import { React, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ItemsCarousel from 'react-items-carousel';
import ShowVariablesClass from './ShowVariables/class';
import ShowVariablesSection from './ShowVariables/section';
import ShowVariablesSubjects from './ShowVariables/subjects';
import ShowVariablesSession from './ShowVariables/session';
import Api from '../../../Api/axios';
import Stack from 'react-bootstrap/Stack';


const ShowVariables = () => {
    const navigate = useNavigate();
    const handleClassClick = () => {
        navigate("/show-variables/class");
    }
    const handleSectionClick = () => {
        navigate("/show-variables/section");
    }
    const handleSubjectClick = () => {
        navigate("/show-variables/subjects");
    }
    const handleSessionClick = () => {
        navigate("/show-variables/session");
    }
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [class_start_range, setClassStartRange] = useState(1);
    const [class_end_range, setClassEndRange] = useState(12);
    const [max_number_of_sections, setMaxNumberOfSections] = useState(4);
    const [section_start_range, setSectionStartRange] = useState("A");
    const [section_end_range, setSectionEndRange] = useState("Z");
    const [session, setSession] = useState("");
    const [subjectList, setSubjectList] = useState([]);
    const [sessions, setSessions] = useState([]);
    useEffect(() => {

        Api.get("/preconfig-entites/variables").then((response) => {
            setClassStartRange(response.data[0].class_start_range);
            setClassEndRange(response.data[0].class_end_range);
            setMaxNumberOfSections(response.data[0].max_number_of_sections);
            setSectionStartRange(response.data[0].sections_identifier_range[0]);
            setSectionEndRange(response.data[0].sections_identifier_range[response.data[0].max_number_of_sections - 1]);
            setSession(response.data[0].current_session_id);
            console.log(response.data[0].current_session_id);
            Api.get('/session-entity?district_id=' + response.data[0].district_id).then((res) => {
                setSessions(res.data);
            });
        }).finally(() => {
            console.log(class_start_range, class_end_range, max_number_of_sections, section_start_range, section_end_range);
        })
        Api.get('/preconfig-entites/subjects').then((response) => {
            console.log(response.data.filter((subject) => subject.name != null));
            setSubjectList(response.data.filter((subject) => subject.name != null));
        });
    }, [])

    return (
        <div align="center">

            {console.log(activeItemIndex)}
            <h1 className='p-4' style={{
                fontWeight: 'bold',
            }}>Show Variables</h1>
            <div className="col-12 col-sm-11 col-md-10" style={{paddingLeft: "22px", paddingRight: "22px" }} align="center">
                <div className='shadow' style={{ backgroundColor: "#FFFFFF", width: "100%" }}>
                    <div className="p-4 flex" style={{ backgroundColor: (activeItemIndex % 4) == 0 ? "#597ef7" : "#FFFFFF", color: (activeItemIndex % 4) == 0 ? "#FFFFFF" : "#000000", borderRadius: "20px 20px 20px 20px", marginLeft: (activeItemIndex % 4) == 0 ? "-20px" : "0px", marginRight: (activeItemIndex % 4) == 0 ? "-20px" : "0px" }} onMouseEnter={(e) => setActiveItemIndex(0)} onClick={handleClassClick}>
                        <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                            <h4 style={{ display: 'flex', width: '50%' }} className='ms-4'><strong style={{ color: (activeItemIndex % 4) == 0 ? "#FFFFFF" : "#000000" }}>Class Range</strong></h4>
                            <h5 className='me-2' style={{ color: (activeItemIndex % 4) == 0 ? "#FFFFFF" : "#000000", display: 'flex', justifyContent: 'flex-end', width: '50%' }}>(Class {class_start_range} - Class {class_end_range})</h5>
                        </Stack>
                    </div>
                    <div className="p-4 flex" style={{ backgroundColor: (activeItemIndex % 4) == 1 ? "#597ef7" : "#FFFFFF", color: (activeItemIndex % 4) == 1 ? "#FFFFFF" : "#000000", borderRadius: "20px 20px 20px 20px", marginLeft: (activeItemIndex % 4) == 1 ? "-20px" : "0px", marginRight: (activeItemIndex % 4) == 1 ? "-20px" : "0px" }} onMouseEnter={(e) => setActiveItemIndex(1)} onClick={handleSectionClick} >
                        <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                            <h4 style={{ display: 'flex', width: '50%' }} className='ms-4'><strong style={{ color: (activeItemIndex % 4) == 1 ? "#FFFFFF" : "#000000" }}>Section Range</strong></h4>
                            <h5 className='me-2' style={{ color: (activeItemIndex % 4) == 1 ? "#FFFFFF" : "#000000", display: 'flex', justifyContent: 'flex-end', width: '50%' }}>(Section {section_start_range} - Section {section_end_range})</h5>
                        </Stack>
                    </div>
                    <div className="p-4 flex" style={{ backgroundColor: (activeItemIndex % 4) == 2 ? "#597ef7" : "#FFFFFF", color: (activeItemIndex % 4) == 2 ? "#FFFFFF" : "#000000", borderRadius: "20px 20px 20px 20px", marginLeft: (activeItemIndex % 4) == 2 ? "-20px" : "0px", marginRight: (activeItemIndex % 4) == 2 ? "-20px" : "0px" }} onMouseEnter={(e) => setActiveItemIndex(2)} onClick={handleSubjectClick} >
                        <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                            <h4 style={{ display: 'flex', width: '50%' }} className='ms-4'><strong style={{ color: (activeItemIndex % 4) == 2 ? "#FFFFFF" : "#000000" }}>Subjects</strong></h4>
                            <h5 className='me-2' style={{ color: (activeItemIndex % 4) == 2 ? "#FFFFFF" : "#000000", display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                                ({subjectList.slice(0,4).map((item)=> item.name).join(", ")} {subjectList.length > 4 ? ", etc." : ""})
                            </h5>
                        </Stack>
                    </div>
                    <div className="p-4 flex" style={{ backgroundColor: (activeItemIndex % 4) == 3 ? "#597ef7" : "#FFFFFF", color: (activeItemIndex % 4) == 3 ? "#FFFFFF" : "#000000", borderRadius: "20px 20px 20px 20px", marginLeft: (activeItemIndex % 4) == 3 ? "-20px" : "0px", marginRight: (activeItemIndex % 4) == 3 ? "-20px" : "0px" }} onMouseEnter={(e) => setActiveItemIndex(3)} onClick={handleSessionClick}>
                        <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                            <h4 style={{ display: 'flex', width: '50%' }} className='ms-4'><strong style={{ color: (activeItemIndex % 4) == 3 ? "#FFFFFF" : "#000000" }}>Session</strong></h4>
                            <h5 className='me-2' style={{ color: (activeItemIndex % 4) == 3 ? "#FFFFFF" : "#000000", display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
                                ({sessions.map((s) => {
                                    console.log(s._id, session, sessions )
                                    if (s._id == session) {
                                        return s.name
                                    }
                                })})
                            </h5>
                        </Stack>
                    </div>
                </div>
                {/* <div className="col-10 m-4 p-4" onClick={() => handleClassClick()} onMouseEnter={changeBackgroundHover} onMouseLeave={changeBackgroundNonHover}>
                    <h4 className='mt-2 mb-2'>Class Range</h4>
                    <p className='mb-2'>(Class {class_start_range} - Class {class_end_range})</p>
                </div>
                <div className="col-10 m-4  p-4" onClick={() => handleSectionClick()}>
                    <h4 className='mt-2 mb-2'>Section Range</h4>
                    <p className='mb-2'>(Section {section_start_range} - Section {section_end_range})</p>
                </div>

                <div className="col-10 m-4  p-4" onClick={() => handleSubjectClick()}>
                    <h4 className='mt-2 mb-2'>Subjects</h4>
                    <p className='mb-2'>(Maths, Science, English, Hindi, etc.)</p>
                </div>

                <div className="col-10 m-4  p-4" onClick={() => handleSessionClick()}>
                    <h4 className='mt-2 mb-2'>Session</h4>
                    <p className='mb-2'>(2022 - 23)</p>
                </div> */}
                {/* <div className='m-4'>
                    <ItemsCarousel
                        infiniteLoop={true}
                        gutter={15}
                        activePosition={'center'}
                        chevronWidth={10}
                        disableSwipe={false}
                        alwaysShowChevrons={false}
                        numberOfCards={3}
                        slidesToScroll={1}
                        outsideChevron={true}
                        showSlither={true}
                        firstAndLastGutter={true}
                        activeItemIndex={activeItemIndex}
                        requestToChangeActive={value => setActiveItemIndex(value)}
                        rightChevron={'>'}
                        leftChevron={'<'}
                    >
                        <div className="p-4" style={{ backgroundColor: (activeItemIndex % 4) == 3 ? "#FFF" : "#F8F5F4" }} onClick= {(e) => setActiveItemIndex(3)}>
                            <h4 className='mt-4 mb-2'>Session</h4>
                            <p className='mb-4'>(2022 - 23)</p>
                        </div>
                        <div className="p-4" style={{ backgroundColor: (activeItemIndex % 4) == 0 ? "#FFF" : "#F8F5F4" }} onClick= {(e) => setActiveItemIndex(0)}>
                            <h4 className='mt-4 mb-2'>Class Range</h4>
                            <p className='mb-4'>(Class {class_start_range} - Class {class_end_range})</p>
                        </div>
                        <div className="p-4" style={{ backgroundColor: (activeItemIndex % 4) == 1 ? "#FFF" : "#F8F5F4" }} onClick= {(e) => setActiveItemIndex(1)}>
                            <h4 className='mt-4 mb-2'>Section Range</h4>
                            <p className='mb-4'>(Section {section_start_range} - Section {section_end_range})</p>
                        </div>
                        <div className="p-4" style={{ backgroundColor: (activeItemIndex % 4) == 2 ? "#FFF" : "#F8F5F4" }} onClick= {(e) => setActiveItemIndex(2)}>
                            <h4 className='mt-4 mb-2'>Subjects</h4>
                            <p className='mb-4'>Click to View</p>
                        </div>

                    </ItemsCarousel>
                    {(activeItemIndex%4) == 3 ? <ShowVariablesSession /> : (activeItemIndex%4) == 0 ? <ShowVariablesClass /> : (activeItemIndex%4) == 1 ? <ShowVariablesSection /> : <ShowVariablesSubjects />}
                </div> */}
            </div>

        </div>
    );
};

export default ShowVariables;