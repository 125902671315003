import React, { useState, useEffect } from "react";
import { Box, option } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Api from "../../../Api/axios";
import { MenuItem } from "@mui/material";
import Table from 'react-bootstrap/Table';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const AddDocument = () => {
	const userTypes = [
		['CENTRAL', 'Central'],
		['DISTRICT', 'District'],
		['SCHOOL_ADMIN', 'School Admin'],
		['TEACHER', 'Teacher'],
		['PARENT', 'Parent'],
		['STUDENT', 'Student'],
	]
	const documentTypes = [
		["Academic", "Academic"],
		["Personal", "Personal"],
		["Administrative", "Administrative"],
		["Others", "Others"],
	]
	const [classDict, setClassDict] = useState({});
	const [subjectDict, setSubjectDict] = useState([]);
	const [chapterDict, setChapterDict] = useState([]);
	const [topicDict, setTopicDict] = useState([]);
	const [subTopicDict, setSubTopicDict] = useState([]);
	const [userDict, setUserDict] = useState([]);
	const [documentType, setDocumentType] = useState("Academic");
	const [ownerUserType, setOwnerUserType] = useState("ALL");

	const [ownerUser, setOwnerUser] = useState("ALL");
	const [recipientUser, setRecipientUser] = useState("ALL");
	const [className, setClassName] = useState("ALL");
	const [subjectName, setSubjectName] = useState("ALL");
	const [chapterName, setChapterName] = useState("ALL");
	const [topicName, setTopicName] = useState("ALL");
	const [subTopicName, setSubTopicName] = useState("ALL");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [uploadFile, setUploadFile] = useState("");
	const [sectionDict, setSectionDict] = useState({ "ALL": [] });
	const [sectionName, setSectionName] = useState([]);
	const [
		{ user, userDetails, initialState, loggedIn, class_teacher_class_details, type }, dispatch
	] = useDataLayerValue();
	const [documents, setDocuments] = useState([{ id: uuidv4(), title: "", description: "", file: "" }]);
	console.log("userDetails", user, userDetails?._id);
	const currentUserTypeIndex = userTypes.findIndex((userType) => userType[0] === userDetails?.userType);
	const [userList, setUserList] = useState([]);
	const [forUsers, setForUsres] = useState([]);
	const [sectionIds, setSectionIds] = useState(new Set());
	const [sec_names, setSectionNames] = useState({});
	const [recipientUserType, setRecipientUserType] = useState((userDetails?.userType === "STUDENT" || userDetails?.userType === "PARENT") ? 'None' : 'PARENT');
	const [classList, setClassList] = useState([]);
	const [selectedClass, setSelectedClass] = useState([]);
	const [schoolList, setSchoolList] = useState([]);
	const [schoolId, setSchoolId] = useState("");
	useEffect(() => {
		if (schoolId) {
			Api.get(`school-entity/all-classes/?school_id=${schoolId}`).then((res) => {
				setClassList(res.data);
			})
		} else {
			setClassList([]);
		}
		setSelectedClass([]);
	}, [schoolId, userDetails, recipientUserType]);
	// React.useEffect(() => {
	React.useEffect(() => {
		Api.get("preconfig-entites/subjects").then((res) => {
			setSubjectDict(res.data);
		})
		if (userDetails?.userType !== 'DISTRICT') {
			Api.get("school-entity/school").then((res) => {
				setSchoolList([res.data]);
				setSchoolId(res.data._id);
			})
		} else {
			Api.get("school-entity/school").then((res) => {
				setSchoolList(res.data);
			})
		}
	}, [userDetails]);
	const handleDocumentTypeChange = (event) => {
		setDocumentType(event.target.value);
	};
	const handleOwnerUserTypeChange = (event) => {
		setOwnerUserType(event.target.value);
	};
	const handleRecipientUserTypeChange = (event) => {
		setRecipientUserType(event.target.value);
		setSubjectName("ALL");
		setSectionIds(new Set());
	}
	const handleClassChange = async (e) => {
		setClassName(e.target.value);
		setSubjectName("ALL");
		setSectionDict(classDict[e.target.value]);
		setSectionName("ALL");
	}
	const handleSubjectChange = async (e) => {
		setSubjectName(e.target.value);
	}

	const [canUpload, setCanUpload] = useState(userDetails?.userType !== "STUDENT");
	const [pageNo, setPageNo] = useState(1);
	const movetouser = () => {
		dispatch({
			type: "SET_LOADING",
			loading: true,
		});
		Api.get(`/user/users-data?` + (recipientUserType ? `userType=${recipientUserType}` : "") + (schoolId ? `&schoolId=${schoolId}` : "") + (selectedClass ?
			selectedClass.map((classes, index) => {
				return `&classes[${index}]=${classes._id}`
			}).join('')
			: "")).then((res) => {
				setUserList(res.data);
			}).finally(() => {
				dispatch({
					type: "SET_LOADING",
					loading: false,
				});
			})
		setPageNo(2);
	}
	const doNothing = (event) => {
		return true;
	}
	const handleTitleChange = (event) => {
		setTitle(event.target.value);
	}
	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	}
	const handleFileChange = (event) => {
		setUploadFile(event.target.files[0]);
	}
	const [finalData, setFinalData] = useState([]);
	const postDocument = async (event) => {
		console.log(documents)
		let file_sum = 0;
		for (const doc of documents) {
			if (doc.title === "") {
				alert("Please enter title for all documents");
				return;
			}
			if (doc.description === "") {
				alert("Please enter description for all documents");
				return;
			}
			if (doc.file === "") {
				alert("Please select a file for all documents");
				return;
			}
			if (doc.file.size > 10485760) {
				alert("File size should be less than 10MB");
				return;
			}
			file_sum += doc.file.size;
			if (file_sum > 26214400) {
				alert("Total file size should be less than 25MB");
				return;
			}
		}
		for (const doc of documents) {
			console.log(doc);
			const formData = new FormData();
			formData.append("files", doc.file);
			formData.append("name", doc.title);
			formData.append("description", doc.description);
			formData.append("document_type", documentType == "ALL" ? "ALL" : documentType);
			formData.append("userType", recipientUserType);
			selectedClass.forEach((item) => {
				formData.append("document_for[]", item._id);
			});
			formData.append("subject_id", subjectName == "ALL" ? "" : subjectName);
			userList.forEach((item) => {
				if (item.checked)
					formData.append("document_for_user[]", item._id);
			})
			await Api.post("documents", formData).then((res) => {
				console.log(res);
				setFinalData([...finalData, res.data]);
			}).catch((err) => {
				setPageNo(-1);
			})
		}
		setUserList([]);
		setDocuments([{ id: uuidv4(), title: "", description: "", file: "" }]);
		setSectionIds(new Set());
		setSubjectName("ALL");
		if (pageNo != -1)
			setPageNo(4);
		setRecipientUser("PARENT");
	}



	return (
		<div>
			<h1 className="m-4" align="center" style={{ fontWeight: "bold" }}>Document Upload</h1>
			<center
				style={{
					paddingBottom: "30px",
				}}
			>

				{canUpload ?
					<>
						{pageNo == 1
							?
							<div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
								<Form.Group controlId="formFile" className="m-3">
									<Stack type="vartical" gap={4}>
										<InputGroup>
											<InputGroup.Text>Type</InputGroup.Text>
											<Form.Select aria-label="Select Document Type"
												value={documentType}
												onChange={handleDocumentTypeChange}
											>
												{documentTypes.map((docType) => (
													<option key={docType[0]} value={docType[0]} style={{ width: "100%" }}>
														{docType[1]}
													</option>
												))}
											</Form.Select>
										</InputGroup>
										<InputGroup>
											<InputGroup.Text>User Type</InputGroup.Text>
											<Form.Select aria-label="Select Document Type"
												value={recipientUserType}
												onChange={handleRecipientUserTypeChange}
												as="select"
											>
												<option value="" style={{ width: "100%" }}>
													ALL
												</option>
												{
													userTypes.map((userType, i) => {
														if (i > currentUserTypeIndex) {
															return (
																<option key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
																	{userType[1]}
																</option>
															)
														}
													})
												}
											</Form.Select>
										</InputGroup>
										<InputGroup>
											<InputGroup.Text>Select School</InputGroup.Text>
											<Form.Select aria-label="Select Document Type"
												value={schoolId}
												onChange={(e) => setSchoolId(e.target.value)}
											>
												<option value="" style={{ width: "100%" }}>
													ALL
												</option>
												{schoolList.map((sectionVar, i) => {
													return (
														<option key={sectionVar._id} value={sectionVar._id} style={{ width: "100%" }}>
															{sectionVar.name}
														</option>
													)
												})}
											</Form.Select>
										</InputGroup>
										{(recipientUserType === "STUDENT" || recipientUserType === "TEACHER") && <>

											{schoolId ? <InputGroup>
												<InputGroup.Text>Class</InputGroup.Text>
												{/* <Form.Select aria-label="Select Document Type"
													value={sectionName}
													onChange={(e) => setSectionName(e.target.value)}
												>
													{Object.keys(sectionDict).map((sectionVar, i) => {
														return (
															<option key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
																{sectionVar}
															</option>
														)
													})
													}
												</Form.Select> */}
												{console.log(sectionDict)}
												{
													console.log(classDict)
												}
												<Multiselect
													showArrow
													options={classList}
													displayValue="class_name_section"
													groupBy="class_name"
													showCheckbox={true}
													selectedValues={selectedClass}
													onSelect={(selectedList, selectedItem) => { setSelectedClass(selectedList) }}
													onRemove={(selectedList, selectedItem) => { setSelectedClass(selectedList) }}
												/>
												{console.log(sectionIds)}
											</InputGroup> : null}
											<InputGroup>
												<InputGroup.Text>Subject</InputGroup.Text>
												<Form.Select aria-label="Select Document Type" value={subjectName}
													onChange={handleSubjectChange}
												>
													<option key="ALL" value="ALL" style={{ width: "100%" }}>
														ALL
													</option>
													{subjectDict.map((subjectDictVar, i) => {
														// console.log(classDict[className][sectionName])
														console.log(sectionIds)
														console.log(!sectionIds, sectionIds.length)
														if (sectionIds.size == 0 || (sectionIds.size > 0 && sectionIds.has(subjectDictVar.classroom_id))) {
															return (
																<option key={subjectDictVar._id} value={subjectDictVar._id} style={{ width: "100%" }}>
																	{subjectDictVar.name}
																</option>
															)
														}
													})
													}
												</Form.Select>
											</InputGroup>


										</>
										}
										<center style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<Button variant="outline-secondary" size="lg" onClick={movetouser} style={{ minWidth: '150px' }} >
												Next
											</Button>
										</center>

									</Stack>
								</Form.Group>
							</div>
							: pageNo == 2 ?
								<>
									<div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9" style={{ borderRadius: 10, backgroundColor: "#FFF", marginBottom: "20px" }} align="center">
										<Stack className="row" direction="horizontal">
											<div className="col-12 col-sm-6" style={{ display: 'flex' }}>
												<Button variant="light" style={{ backgroundColor: "#FFF", color: "#6c757d" }} onhover={(e) => doNothing()} onClick={(e) => {
													setPageNo(1)
													setSectionIds(new Set())
												}} >
													← Previous
												</Button>
											</div>
											<div className="col-12 col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }} onClick={(e) => setPageNo(3)}  >
													Next
												</Button>
											</div>
										</Stack>
										<h2 className="mt-4">List of Users</h2>
										<div>
											<Button className="m-4" variant="outline-secondary" onClick={e => setUserList(userList.map(us => ({ ...us, checked: false })))} style={{ minWidth: '150px' }} >
												Un-Check All
											</Button>
											<Button className="m-4" variant="outline-secondary" onClick={e => setUserList(userList.map(us => ({ ...us, checked: true })))} style={{ minWidth: '150px' }} >
												Check All
											</Button>
										</div>

										<Table striped bordered hover responsive className="mt-4 mb-4">
											<thead>
												<tr>
													<td><b>Sr. No.</b></td>
													<td><b>Name</b></td>
													<td><b>Username</b></td>
													<td><b>Check/Uncheck</b></td>
												</tr>
											</thead>
											<tbody>
												{userList.map((user, i) => {
													console.log(user);
													return (
														<tr key={i}>
															<td>{i + 1}</td>
															<td>{user.name}</td>
															<td>{user.conventionalId}</td>
															<td><Form.Check
																type="checkbox"
																style={{ padding: 10 }}
																checked={user.checked}
																onChange={(e) => {
																	let temp = [...userList];
																	temp[i].checked = !temp[i].checked;
																	setUserList(temp);
																}}
															/></td>
														</tr>
													)
												})}
											</tbody>
										</Table>

									</div>
								</>
								: pageNo == 3 ?
									<>
										{documents.map((document, i) => {
											return (
												<div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
													<Form.Group controlId="formFile" className="m-3">
														<Stack type="vartical" gap={4}>
															<InputGroup>
																<InputGroup.Text>
																	Title
																</InputGroup.Text>
																<Form.Control
																	aria-label="Default"
																	aria-describedby="inputGroup-sizing-default"
																	value={document.title}
																	onChange={(e) => setDocuments(documents.map(doc => (doc.id === document.id ? { ...doc, title: e.target.value } : doc)))}
																// placeholder="Enter Title"
																/>
															</InputGroup>
															<InputGroup>
																<InputGroup.Text>Description</InputGroup.Text>
																<Form.Control as="textarea" aria-label="With textarea" aria-describedby="inputGroup-sizing-default"
																	value={document.description}
																	onChange={(e) => setDocuments(documents.map(doc => (doc.id === document.id ? { ...doc, description: e.target.value } : doc)))}
																/>
															</InputGroup>

															<Form.Control
																type="file"
																// value={uploadFile}
																onChange={(e) => setDocuments(documents.map(doc => (doc.id === document.id ? { ...doc, file: e.target.files[0] } : doc)))}
															/>
														</Stack>

													</Form.Group>
													<Button variant="outline-danger" size="lg"
														disabled={documents.length <= 1}
														onClick={(e) => setDocuments(documents.filter(doc => doc.id !== document.id))}>
														<DeleteForeverIcon /> Delete
													</Button>
												</div>)
										})}
										<div className="mt-4 p-4 col-12 col-sm-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
											<Button variant="outline-secondary" onClick={(e) => { documents.length <= 4 ? setDocuments([...documents, { id: uuidv4(), title: "", description: "", file: "" }]) : alert("You can add maximum of 5 documents at once.") }}>
												<AddIcon />
												Add Document
											</Button>
										</div>
										<div className=" p-4 col-12 col-sm-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
											<Stack direction="horizontal">
												<div style={{ display: 'flex', width: '50%' }}>
													<Button variant="light" style={{ backgroundColor: "#FFF", color: "#6c757d" }} size="lg" onhover={(e) => doNothing()} onClick={(e) => setPageNo(2)} >
														← Previous
													</Button>


												</div>
												<div style={{ display: 'flex', justifyContent: 'flex-end', width: '50%' }}>
													<Button variant="outline-secondary" size="lg" align="right" onClick={(e) => postDocument()}  >
														Upload Document
													</Button>
												</div>
											</Stack>
										</div>
									</>

									:
									<center>
										<div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
											<img src={`${process.env.PUBLIC_URL}/Images/sucess.png`} style={{ width: '100px' }} alt="" />
											<h3 className="m-4">File Sucessfully Uploaded</h3>
											<Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
												<Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => setPageNo(1)} >
													Upload Another Document
												</Button>
												<Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
													Go to Dashboard
												</Button>
											</Stack>
										</div>


									</center>
						}
					</>
					: pageNo == -1 ?
						<div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
							<img src={`${process.env.PUBLIC_URL}/Images/failure.png`} style={{ width: '100px' }} alt="" />
							<h3 className="m-4">There Was Error Uploading Document</h3>
							<Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
								<Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => setPageNo(1)} >
									Upload Another Document
								</Button>
								<Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
									Go to Dashboard
								</Button>
							</Stack>
						</div>
						:
						<div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
							<img src={`${process.env.PUBLIC_URL}/Images/failure.png`} style={{ width: '100px' }} alt="" />
							<h3 className="m-4">Sorry, you don't have permission to upload documents.</h3>
							<Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
								<Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
									Go to Dashboard
								</Button>
							</Stack>
						</div>
				}
			</center>

		</div>
	);
};

export default AddDocument;
