// import React, { useEffect, useState, useRef } from "react";
// import Api from "../../Api/axios";
// import "./edit.css";
// import { useDataLayerValue } from "../../DataLayer/DataLayer";
// import Error from "../ErrorSuccess/Error";
// import { FaUserEdit } from "react-icons/fa";
// import EditModalStudent from "../EditModal/EditModalStudent";
// import { useReactToPrint } from "react-to-print";

// const ShowStudents = () => {
//   const [allUsers, setAllUsers] = useState([]);
//   const [{ userDetails }, dispatch] = useDataLayerValue();
//   const [errMesssage, setErrMesssage] = useState();
//   const [allUsersFiltered, setAllUsersFiltered] = useState([]);
//   const [filterData, setFilterData] = useState({});
//   const [editModalOpen, setEditModalOpen] = useState(false);
//   const [idToBeEditd, setIdToBeEditd] = useState();
//   const [userName, setUserName] = useState();
//   const [userEmail, setUserEmail] = useState();
//   const [userContactNo, setUserContactNo] = useState();
//   const [userClass, setUserClass] = useState();
//   const [userSection, setUserSection] = useState();
//   const componentRef = useRef();

//   useEffect(() => {
//     fetchStudents();
//   }, []);

//   const fetchStudents = async () => {
//     dispatch({
//       type: "SET_LOADING",
//       loading: true,
//     });
//     if (
//       userDetails?.userType === "CENTRAL" ||
//       userDetails?.userType === "DISTRICT"
//     ) {
//       await Api.get("/user/all-students")
//         .then((res) => {
//           let allUsersTemp = [];
//           allUsersTemp = res.data;
//           allUsersTemp.forEach((user) => {
//             if (user.classes !== null) {
//               user.class_name =
//                 user.classes[user.classes.length - 1].split(" ")[0];
//               user.class_section =
//                 user.classes[user.classes.length - 1].split(" ")[1];
//             }
//           });
//           setAllUsers(allUsersTemp);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         })
//         .catch((err) => {
//           setErrMesssage(err.response.message);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         });
//     } else {
//       await Api.get(`/user/by-school?id=${userDetails.schoolId}&type=STUDENT`)
//         .then((res) => {
//           let allUsersTemp = [];
//           allUsersTemp = res.data;
//           allUsersTemp.forEach((user) => {
//             if (user.classes !== null) {
//               user.class_name =
//                 user.classes[user.classes.length - 1].split(" ")[0];
//               user.class_section =
//                 user.classes[user.classes.length - 1].split(" ")[1];
//             }
//           });
//           setAllUsers(allUsersTemp);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         })
//         .catch((err) => {
//           setErrMesssage(err?.response?.message);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         });
//     }
//   };

//   // update Contact No from EditModal
//   const changeValue = (e) => {
//     const { id, value } = e.target;
//     setUserContactNo({ [id]: value });
//   };

//   const openEditModal = (
//     userId,
//     userName,
//     userEmail,
//     userContactNo,
//     userClass,
//     userSection
//   ) => {
//     console.log(allUsers);
//     setEditModalOpen(true);
//     setUserName(userName);
//     setUserEmail(userEmail);
//     setUserContactNo(userContactNo);
//     setIdToBeEditd(userId);
//     setUserClass(userClass);
//     setUserSection(userSection);
//   };
//   const closeEditModal = () => {
//     setEditModalOpen(false);
//   };

//   const editConfirmed = () => {
//     setEditModalOpen(false);
//     console.log(idToBeEditd);
//     console.log(userContactNo);
//     editUser();
//   };

//   const editUser = async () => {
//     dispatch({
//       type: "SET_LOADING",
//       loading: true,
//     });
//     //console.log("length", userContactNo.length);
//     await Api.put(
//       `user?id=${idToBeEditd}`,
//       { contactNumber: `${userContactNo.userContactNo}` },
//       true
//     )
//       .then(() => fetchStudents())
//       .catch((err) => console.log(err));
//     dispatch({
//       type: "SET_LOADING",
//       loading: false,
//     });
//   };

//   const changeFilterData = (e) => {
//     const { id, value } = e.target;
//     setFilterData({ ...filterData, [id]: value });
//   };

//   useEffect(() => {
//     setFilteredUserDetails();
//   }, [filterData, allUsers]);

//   const setFilteredUserDetails = () => {
//     const keys = Object.keys(filterData);
//     let allEmpty = Object.values(filterData).every(
//       (x) => x === null || x === "" || x === undefined
//     );
//     if (allEmpty || filterData === {}) {
//       setAllUsersFiltered(allUsers);
//     } else {
//       let filteredUserDetailsTemp = allUsers.filter((attd) => {
//         return keys.every((key) =>
//           attd[key]
//             ?.toLocaleLowerCase()
//             .includes(filterData[key]?.toLocaleLowerCase())
//         );
//       });
//       setAllUsersFiltered(filteredUserDetailsTemp);
//     }
//   };

//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current,
//   });

//   return (
//     <div className="show-table-container">
//       <div className="show-table-container-main">
//         <button onClick={handlePrint}>Print User Details</button>
//         <table className="table" ref={componentRef}>
//           <thead>
//             <tr>
//               <th colSpan="8">Students</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Name
//                   <input
//                     type="text"
//                     id="name"
//                     placeholder="eg: Ayan"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Email
//                   <input
//                     type="text"
//                     id="email"
//                     placeholder="eg: Ayan"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Username
//                   <input
//                     type="text"
//                     id="username"
//                     placeholder="eg: s1s1"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Id
//                   <input
//                     type="text"
//                     id="_id"
//                     placeholder="eg: id"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Class
//                   <input
//                     type="text"
//                     id="class_name"
//                     placeholder="eg: 5"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Section
//                   <input
//                     type="text"
//                     id="class_section"
//                     placeholder="eg: A"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Contact Number
//                   <input
//                     type="text"
//                     maxlength="10"
//                     minLength="10"
//                     id="contactNumber"
//                     placeholder="eg: 91XXXXXX01"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>

//               <td>Edit</td>
//             </tr>
//             {allUsersFiltered?.map((user) => (
//               <tr key={user}>
//                 <td>{user?.name}</td>
//                 <td>{user?.email}</td>
//                 <td>{user?.username}</td>
//                 <td>{user?._id}</td>
//                 <td>{user?.class_name}</td>
//                 <td>{user?.class_section}</td>
//                 <td>{user?.contactNumber}</td>
//                 <td>
//                   <FaUserEdit
//                     color="Black"
//                     style={{ cursor: "pointer" }}
//                     onClick={() =>
//                       openEditModal(
//                         user?._id,
//                         user?.name,
//                         user?.email,
//                         user?.contactNumber,
//                         user?.class_name,
//                         user?.class_section
//                       )
//                     }
//                   />
//                 </td>
//               </tr>
//             ))}
//             {errMesssage && <Error message={errMesssage} />}
//           </tbody>
//         </table>
//       </div>
//       <EditModalStudent
//         changevalue={changeValue}
//         open={editModalOpen}
//         closeEditModal={closeEditModal}
//         editConfirmed={editConfirmed}
//         userId={idToBeEditd}
//         userName={userName}
//         userContactNo={userContactNo}
//         userEmail={userEmail}
//         userClass={userClass}
//         userSection={userSection}
//         message={
//           "Do you really want to edit this user ? This process cannot be undone."
//         }
//       />
//     </div>
//   );
// };

// export default ShowStudents;

import React, { useEffect, useState, useRef } from "react";
import Api from "../../Api/axios";
import "./edit.css";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Error from "../ErrorSuccess/Error";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { useReactToPrint } from "react-to-print";

const EditStudent = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [allUsersFiltered, setAllUsersFiltered] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [errMessage, setErrMessage] = useState();
  const [userToBeEdited, setUserToBeEdited] = useState({});
  const [openEditMenu, setOpenEditMenu] = useState(false);
  const componentRef = useRef();

  useEffect(() => {
    fetchStudents();
  }, []);

  const fetchStudents = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    if (
      userDetails?.userType === "CENTRAL" ||
      userDetails?.userType === "DISTRICT"
    ) {
      await Api.get("/user/all-students")
        .then((res) => {
          let allUsersTemp = [];
          allUsersTemp = res.data;
          allUsersTemp.forEach((user) => {
            if (user.classes !== null) {
              user.class_name =
                user.classes[user.classes.length - 1].split(" ")[0];
              user.class_section =
                user.classes[user.classes.length - 1].split(" ")[1];
            }
          });
          setAllUsers(allUsersTemp);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((err) => {
          setErrMessage(err.response.message);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    } else {
      await Api.get(`/user/by-school?id=${userDetails.schoolId}&type=STUDENT`)
        .then((res) => {
          let allUsersTemp = [];
          console.log("data", res.data);
          allUsersTemp = res.data;
          allUsersTemp.forEach((user) => {
            if (user.classes !== null) {
              user.class_name =
                user.classes[user.classes.length - 1].split(" ")[0];
              user.class_section =
                user.classes[user.classes.length - 1].split(" ")[1];
            }
          });
          setAllUsers(allUsersTemp);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((err) => {
          setErrMessage(err.response.message);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    }
  };

  const editUser = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.put(`/user?id=${userToBeEdited._id}`, {
      _id: userToBeEdited._id,
      contactNumber: userToBeEdited.contactNumber,
      classes: [userToBeEdited.classes],
    })
      .then((res) => fetchStudents())
      .catch((err) => console.log(err));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
    setOpenEditMenu(false);
    setUserToBeEdited(null);
  };

  const changeFilterData = (e) => {
    const { id, value } = e.target;
    setFilterData({ ...filterData, [id]: value });
  };

  useEffect(() => {
    setFilteredUserDetails();
  }, [filterData, allUsers]);

  const setFilteredUserDetails = () => {
    const keys = Object.keys(filterData);
    let allEmpty = Object.values(filterData).every(
      (x) => x === null || x === "" || x === undefined
    );
    if (allEmpty || filterData === {}) {
      setAllUsersFiltered(allUsers);
    } else {
      let filteredUserDetailsTemp = allUsers.filter((attd) => {
        return keys.every((key) =>
          attd[key]
            ?.toLocaleLowerCase()
            .includes(filterData[key]?.toLocaleLowerCase())
        );
      });
      setAllUsersFiltered(filteredUserDetailsTemp);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="edit-table-container">
      <div className="edit-table-container-main">
        {openEditMenu ? (
          <>
            <div className="user-details">
              <div className="user-details-container">
                <div className="user-profile-img-container">
                  <img
                    src={`${process.env.PUBLIC_URL}/Images/default_avatar.jpg`}
                    alt=""
                  />
                </div>
                <div className="user-details-info">
                  <div className="user-info-item">
                    <label htmlFor="user_name">Name : </label>
                    <input
                      type="text"
                      name="user_name"
                      id="user_name"
                      value={userToBeEdited?.name}
                      disabled
                    />
                  </div>
                  {!(
                    userDetails?.userType === "CENTRAL" ||
                    userDetails?.userType === "DISTRICT"
                  ) && (
                      <div>
                        <div className="user-info-item">
                          <label htmlFor="user_school">School : </label>
                          <input
                            type="text"
                            name="user_school"
                            id="user_school"
                            value={userToBeEdited?.schoolName}
                            disabled
                          />
                        </div>
                        <div className="user-info-item">
                          <label htmlFor="user_school_id">School ID : </label>
                          <input
                            type="text"
                            name="user_school_id"
                            id="user_school_id"
                            value={userToBeEdited?.schoolId}
                            disabled
                          />
                        </div>
                      </div>
                    )}

                  {userToBeEdited?.userType === "STUDENT" && (
                    <div className="user-info-item">
                      <label htmlFor="user_class">Class : </label>
                      <input
                        type="text"
                        name="classes"
                        id="classes"
                        defaultValue={userToBeEdited?.classes}
                        // value={
                        //   userToBeEdited?.classes !== null
                        //     ? `${
                        //         userToBeEdited?.classes[
                        //           userToBeEdited?.classes.length - 1
                        //         ].split(" ")[0]
                        //       }${
                        //         userToBeEdited?.classes[
                        //           userToBeEdited?.classes.length - 1
                        //         ].split(" ")[1]
                        //       }`
                        //     : "--"
                        // }
                        onChange={(e) => {
                          setUserToBeEdited({
                            ...userToBeEdited,
                            classes: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}

                  <div className="user-info-item">
                    <label htmlFor="user_id">Conventional Id : </label>
                    <input
                      type="text"
                      name="user_id"
                      id="user_id"
                      value={userToBeEdited?.conventionalId}
                      disabled
                    />
                  </div>
                  <div className="user-info-item">
                    <label htmlFor="contactNumber">Contact no. : </label>
                    <input
                      type="text"
                      name="contactNumber"
                      id="contactNumber"
                      defaultValue={userToBeEdited?.contactNumber}
                      onChange={(e) => {
                        setUserToBeEdited({
                          ...userToBeEdited,
                          contactNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="user-info-item">
                    <label htmlFor="user_email">Email id : </label>
                    <input
                      type="text"
                      name="user_email"
                      id="user_email"
                      value={userToBeEdited?.email}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-controls">
              <button onClick={() => setOpenEditMenu(false)}>Cancel</button>
              <button
                onClick={() => editUser()}
                style={{ backgroundColor: "limegreen", color: "white" }}
              >
                Save Changes
              </button>
            </div>
          </>
        ) : (
          <div>
            <table className="table" ref={componentRef}>
              <thead>
                <tr>
                  <th colSpan="8">Students</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="ad-st-attnd-table-filter">
                      Name
                      <input
                        type="text"
                        id="name"
                        placeholder="eg: Ayan"
                        onChange={(e) => changeFilterData(e)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="ad-st-attnd-table-filter">
                      Email
                      <input
                        type="text"
                        id="email"
                        placeholder="eg: Ayan"
                        onChange={(e) => changeFilterData(e)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="ad-st-attnd-table-filter">
                      Username
                      <input
                        type="text"
                        id="username"
                        placeholder="eg: s1s1"
                        onChange={(e) => changeFilterData(e)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="ad-st-attnd-table-filter">
                      Id
                      <input
                        type="text"
                        id="_id"
                        placeholder="eg: id"
                        onChange={(e) => changeFilterData(e)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="ad-st-attnd-table-filter">
                      Class
                      <input
                        type="text"
                        id="class_name"
                        placeholder="eg: 5"
                        onChange={(e) => changeFilterData(e)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="ad-st-attnd-table-filter">
                      Section
                      <input
                        type="text"
                        id="class_section"
                        placeholder="eg: A"
                        onChange={(e) => changeFilterData(e)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="ad-st-attnd-table-filter">
                      Contact Number
                      <input
                        type="text"
                        id="contactNumber"
                        placeholder="eg: 91XXXXXX01"
                        onChange={(e) => changeFilterData(e)}
                      />
                    </div>
                  </td>

                  <td>Edit</td>
                </tr>
                {allUsersFiltered?.map((user) => (
                  <tr>
                    <td>{user?.name}</td>
                    <td>{user?.email}</td>
                    <td>{user?.username}</td>
                    <td>{user?._id}</td>
                    <td>{user?.class_name}</td>
                    <td>{user?.class_section}</td>
                    <td>{user?.contactNumber}</td>
                    <td>
                      <MdOutlineModeEditOutline
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setUserToBeEdited(user);
                          setOpenEditMenu(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
                {errMessage && <Error message={errMessage} />}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditStudent;
