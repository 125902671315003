import { SearchOutlined } from '@material-ui/icons'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Api from '../../../../Api/axios'
import AssessmentDetailsCard from "./components/AssessmentDetailsCard"
import AssessmentTable from './components/AssessmentTable'
const filtersBy=[
  "All","Urgency"
]


const NotSubmittedAssessment = ({search,filterAssessment,updateAssId,selectedAssid}) => {
const [notSubmittedAssessment,setNotSubmittedAssessment]=useState([])
const[isAssessmentEmpty,setIsAssessmentEmpty]=useState(false)

  useEffect(()=>{ 
    Api.get("/assessment/submission/submitted").then((res)=>{
      let submission=res.data
      let submissionIdObj={}
      submission.map(({_id})=>{
        submissionIdObj[_id]=_id
      })
      Api.get("/assessment/get-assessment").then((res)=>{
        let allAssessment=res.data
        let notSubmittedAss=[]
        console.log("/submitted-submitted ==>",submission)
        console.log("/assessment/get-assessment==>",allAssessment)

        notSubmittedAss=allAssessment.filter(({_id})=>{
          return _id!==submissionIdObj[_id]
        })
        console.log("notSubmittedAss ==> ",notSubmittedAss)
        if(notSubmittedAss.length===0){
          setIsAssessmentEmpty(true)
          // setNotSubmittedAssessment (()=>allAssessment)//dev testing for ques css  //should not be 
        }else{
          setNotSubmittedAssessment (()=>notSubmittedAss)
        }
      })

    })
  },[])

  return (
    <div>
      {notSubmittedAssessment.length!==0&&
      <AssessmentTable BtnClick={updateAssId} selectedAssid={selectedAssid} datas={notSubmittedAssessment} search={search} filterAssessment={filterAssessment}/>
      }
      {isAssessmentEmpty&&
      <div className='mt-5'>No Assessment is pending</div>
      }
    </div>
  )
}

export default NotSubmittedAssessment