import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";
// import { Document, Page, pdfjs } from "react-pdf";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { pdfjs } from 'react-pdf';
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

const PdfViewer = ({ url, item, itemWiseProgress, chapterId }) => {
	// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
	const [progress, setProgress] = useState(item?.percentage);
	useEffect(() => {
		setProgress(item?.percentage);
	}, [item]);
	const [duration, setDuration] = useState(0);
	const defaultLayoutPluginInstance = defaultLayoutPlugin();
	return (
		<div>
			<div
				id="thereadMessage"
				style={{
					maxHeight: '90vh',
					overflow: 'auto',
				}}
				onScroll={(e) => {
					var total = document.getElementById("thereadMessage")?.scrollHeight;
					var current = document.getElementById("thereadMessage")?.scrollTop + document.getElementById("thereadMessage")?.clientHeight;
					var percentage = (current / total) * 100;
					if ((percentage - progress >= 10) || Math.round(percentage) === 100) {
						// decimal to integer conversion
						setProgress(Math.round(percentage));
						itemWiseProgress(item, chapterId, Math.round(percentage));
					}
				}}
			>
				<Worker workerUrl={`${process.env.PUBLIC_URL}/pdf.worker.min.js`}>
					<Viewer fileUrl={url} />
				</Worker>
			</div>
			{/* <Viewer fileUrl="https://docubucket.s3.ap-south-1.amazonaws.com/lms/ans_tnp_java.pdf" /> */}
		</div>
	);
};

export default PdfViewer;