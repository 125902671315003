import React from 'react'
import Form from 'react-bootstrap/Form';

const SingleSelect = ({className,label,userCreateType,
    setUserCreateType,userTypeList,currentUserTypeIndex,
    labelClassName,id,
    eventList,
    setEventList
}) => {
  return (
<Form.Group className={className} controlId="userType">
{label!==""&&<Form.Label className={labelClassName}>{label}</Form.Label>}
<Form.Select
  aria-label="User Type"
  value={userCreateType}
  onChange={(e) => {
    // setUserCreateType(e.target.value);

    let updated=[...eventList]
    updated=updated.map((x)=>{
    if(x.id===id){
      let obj={...x}
      obj.userCreateType=e.target.value
        // console.log(x.id,"<===>",id,"obj ==>",obj)
        return obj
    }else{
        return x
    }
    })
    // console.log("e.tarhet",e.target.value)
    setEventList(()=>updated)
  }}
>
  {userTypeList.map((userType, idx) => {
    if (idx > currentUserTypeIndex) {
      return <option value={userType[0]}>{userType[1]}</option>;
    }
  })}
</Form.Select>
</Form.Group>
    )
}

export default SingleSelect