import React from "react";
import { NavLink, Outlet } from 'react-router-dom'

const StudentDocuments = () => {
  const tagLinkStyles = ({ isActive }) => {
    return {
      paddingLeft: "20px",
      textDecoration: isActive ? 'none' : 'underline',
      fontWeight: isActive ? 'bold' : 'normal'
    }
  }
  return (
    <div className="text-center">
      <NavLink to="AcademicDocument" style={tagLinkStyles}>Academic</NavLink>
      <NavLink to="SubmissionDocument" style={tagLinkStyles}>Submission</NavLink>
      <NavLink to="AddDocument" style={tagLinkStyles}>Add Document</NavLink>
      <div className="mt-40">
        <Outlet />
      </div>
    </div>
  );
};

export default StudentDocuments;
