import React, { useState, useEffect } from 'react';
import Api from '../../Api/axios';
import { Stack } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table, Button, Form, Col, Row } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import GetUsers from '../UserManagement/GetUsers';

const DistrictDashboard = () => {
    const navigate = useNavigate();
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [refresh, setRefresh] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [schoolDict, setSchoolDict] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [district, setDistrict] = useState({});
    const checkStr = (str, search) => {
        var pattern = search.split("").join(".");
        // con
        // var regex = new RegExp(pattern);
        console.log(pattern, str, str.search(pattern))
        return str.search(pattern) != -1 || str.toLowerCase().includes(search.toLowerCase());
        // return str.toLowerCase().includes(search.toLowerCase());
    }
    const [userDict, setUserDict] = useState([]);
    const [filterUserType, setFilterUserType] = useState("ALL");
    const [finalUserDict, setFinalUserDict] = useState([]);
    const [count, setCount] = useState(50);
    const [loadCount, setLoadCount] = useState(count);
    const [searchSchoolString, setSearchSchoolString] = useState("");
    const userTypes = [
        ['DISTRICT', 'District'],
        ['SCHOOL_ADMIN', 'School Admin'],
        ['TEACHER', 'Teacher'],
        ['PARENT', 'Parent'],
        ['STUDENT', 'Student'],
    ]
    useEffect(() => {
        // dispatch({
        //     type: "SET_LOADING",
        //     loading: true
        // })
        Api.get("/district-entity/?id=" + userDetails?.districtId).then((res) => {
            setDistrict(res.data);
            Api.get('/school-entity/school').then((response) => {
                setSchoolDict(response.data);
                Api.get('/session-entity?district_id=' + userDetails?.districtId).then((resp) => {
                    setSessionList(resp.data);
                });
            });
        }).finally(() => {
            // Api.get('/user/all-district').then((res) => {
            //     setUserDict(res.data);
            // }).finally(() => {
            // dispatch({
            //     type: "SET_LOADING",
            //     loading: false
            // })
            // })
        })
    }, [refresh]);
    useEffect(() => {
        let c = 0;
        let temp = [];
        for (let i = 0; i < userDict.length; i++) {
            let user = userDict[i];
            if (c < count && (filterUserType === "ALL" || user.userType === filterUserType) && (searchString === "" || user.name.toLowerCase().includes(searchString.toLowerCase()) || user.conventionalId.toLowerCase().includes(searchString.toLowerCase()))) {
                temp.push(user);
                c += 1;
            }
            if (c >= count) {
                break;
            }
        }
        setFinalUserDict(temp);
        setLoadCount(count);
    }, [userDict, filterUserType, searchString]);
    const [key, setKey] = useState('basicInfo');

    const loadMore = () => {
        let c = 0;
        for (let i = loadCount; i < userDict.length; i++) {
            let user = userDict[i];
            if (c < count && (filterUserType === "ALL" || user.userType === filterUserType) && (searchString === "" || user.name.toLowerCase().includes(searchString.toLowerCase()) || user.conventionalId.toLowerCase().includes(searchString.toLowerCase()))) {
                finalUserDict.push(user);
                c += 1;
            }
            if (c >= count) {
                break;
            }
        }
        setLoadCount(count + loadCount);
    }

    return (
        <>
            <div style={{ minHeight: "100%", width: "auto", minWidth: "800px", background: "#FFF" }}>
                <div style={{ height: "150px", width: "auto", color: "#fff", background: `url(${process.env.PUBLIC_URL}/Images/banner.jpg)`, backgroundSize: "cover" }} align="left">
                </div>
                <div align="center" >
                    <Stack direction='vertical' gap={3}
                        className="pb-4"
                        style={{ alignItems: "center", width: "100%" }}
                    >
                        <img
                            src={district?.logo ? district.logo : true ? `${process.env.PUBLIC_URL}/Images/sundergarh.jpg` : `${process.env.PUBLIC_URL}/Images/default_avatar.jpg`}
                            alt="profile pic"
                            className='ms-2'
                            style={{ marginTop: "-90px", width: "200px", height: "200px", border: "4px solid white", borderRadius: "50%" }}
                        />
                        <h1 style={{ marginBottom: "0", paddingBottom: "0", fontWeight: "bold" }}>{district?.name}</h1>
                        <h4 className='text-muted' style={{ marginTop: "0", paddingTop: "0", fontWeight: "light" }}>{district?.state}</h4>
                    </Stack>
                    <Tabs
                        defaultActiveKey="basicInfo"
                        className="mb-3"
                        style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                        }}
                        onSelect={(k) => {
                            // this.setState({ key: k });
                            setKey(k);
                        }}
                    >
                        <Tab className='p-4' tabClassName='ms-2 me-2' eventKey="basicInfo" title="Basic Info" align="left">
                            <Table responsive hover>
                                <tbody>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Cenventional ID</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {district.conventional_id}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>State</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {district.state}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Code</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {district.code}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "none", textAlign: "left" }}><b>Map Location</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "none", textAlign: "left" }}>: </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }} colSpan={2}>
                                            <iframe src={"https://www.google.com/maps/d/embed?mid=1dMNEhv0WNWiENa-_GrKXW1PKy8JCwTE&ehbc=2E312"} width="100%" height="450"></iframe>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                        {/* <Tab eventKey="variables" tabClassName='ms-2 me-2' title="District Variables" className='p-4' align="left">
                            <Table responsive hover>
                                <tbody>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Class Range</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {school.class_range_start} - {school.class_range_end}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Section Range</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: A-{String.fromCharCode(64 + (school.max_number_of_sections ? school.max_number_of_sections : 1))}{school.max_number_of_sections}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Maximum Allowed No of Students/Section </b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {school.students_per_section}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Current Session</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {sessionList.filter((session) => session._id === school.current_session)[0]?.name}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}><b>Subjects</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {school.subjects}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>  */}
                        <Tab eventKey="schools" tabClassName='ms-2 me-2' title="Schools" className='ps-4 pe-4 pt-4 pb-2' align="center">
                            <Stack className='mb-2' direction="horizontal" gap={3} style={{ justifyContent: "center" }}>
                                <Form.Control
                                    placeholder="Search School"
                                    // className="mb-2"
                                    value={searchSchoolString}
                                    onChange={(e) => { setSearchSchoolString(e.target.value) }}
                                    style={{ height: "100%", padding: "10px" }}
                                />
                            </Stack>
                            <Table striped bordered hover >
                                <thead>
                                    <tr align="center">
                                        <th style={{ whiteSpace: "nowrap" }}>Sr. No.</th>
                                        <th style={{ whiteSpace: "nowrap" }}>School Name</th>
                                        <th style={{ whiteSpace: "nowrap" }}>School ID</th>
                                        <th style={{ whiteSpace: "nowrap" }}>School Address</th>
                                        <th style={{ whiteSpace: "nowrap" }}>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {schoolDict.map((school, index) => {
                                        if (searchSchoolString === "" || checkStr(school.name.toLowerCase(), searchSchoolString.toLowerCase()) || checkStr(school.conventional_id.toLowerCase(), searchSchoolString.toLowerCase()) || checkStr(school.address.toLowerCase(), searchSchoolString.toLowerCase())) {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{school.name}</td>
                                                    <td>{school.conventional_id}</td>
                                                    <td>{school.address}</td>
                                                    <td><a style={{ textDecoration: "none" }} href={window.location.origin + "/school-dashboard/" + school._id} target='_blank'>View</a></td>
                                                </tr>
                                            )
                                        }
                                    })}
                                </tbody>
                            </Table>

                        </Tab>
                        <Tab eventKey="users" tabClassName='ms-2 me-2' title="Users" className='p-4' align="center">
                            {/* <Stack className='mt-2 mb-2' direction="horizontal" gap={3} style={{ justifyContent: "center" }}>
                                <Form.Control
                                    placeholder="Search User"
                                    // className="mb-2"
                                    value={searchString}
                                    onChange={(e) => { setSearchString(e.target.value) }}
                                    style={{ height: "100%", padding: "10px" }}
                                />
                                <TextField
                                    select
                                    label="Owner User Type"
                                    variant="filled"
                                    style={{ minWidth: 230 }}
                                    value={filterUserType}
                                    onChange={(e) => { setFilterUserType(e.target.value); }}
                                >
                                    <MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
                                        ALL
                                    </MenuItem>
                                    {
                                        userTypes.map((userType, i) => {
                                            return (
                                                <MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
                                                    {userType[1]}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </TextField>
                            </Stack>
                            <Table striped bordered hover >
                                <thead>
                                    <tr align="center">
                                        <th>Sr. No.</th>
                                        <th>Name</th>
                                        <th>ID</th>
                                        <th>User Type</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {finalUserDict.map((user, index) => {
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{user.name}</td>
                                                <td>{user.conventionalId}</td>
                                                <td>{user.userType}</td>
                                                <td><a onClick={() => window.open(window.location.origin+"/dashboard/" + user._id, '_blank')}>View</a></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            <Button variant="contained" color="primary" onClick={loadMore}>Load More</Button> */}
                            {key === "users" && <GetUsers districtId={userDetails?.districtId} />}
                        </Tab>

                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default DistrictDashboard;