import React, { useState } from "react";
import TextField from '@material-ui/core/TextField';
import { Box, MenuItem, Multiselect } from "@mui/material";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useEffect } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from "react-router";

const ShowYourAttendance = () => {
    const [classDict, setClassDict] = useState([]);
    const [sectionDict, setSectionDict] = useState({ "ALL": [] });
    const [subjectDict, setSubjectDict] = useState([]);
    const [chapterDict, setChapterDict] = useState([]);
    const [topicDict, setTopicDict] = useState([]);
    const [subTopicDict, setSubTopicDict] = useState([]);
    const [userDict, setUserDict] = useState([]);
    const navigate = useNavigate();
    // date 200 days before
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 200)));
    const [endDate, setEndDate] = useState(new Date());

    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details },
    ] = useDataLayerValue();
    // const currentUserTypeIndex = userTypes.findIndex((userType) => userType[0] === userDetails?.userType);
    const [change, setChange] = useState(true)
    const [files, setFiles] = useState([]);
    const [{ type }, dispatch] = useDataLayerValue();
    const [fileC, setFileC] = useState([]);
    const [sectionName, setSectionName] = useState("ALL");
    const [personalAttendance, setPersonalAttendance] = useState([]);
    const [attendanceLogs, setAttendanceLogs] = useState([]);
    const [classAll, setClassAll] = useState([]);
    const [classIds, setClassIds] = useState([]);
    const [classMap, setClassMap] = useState({});
    React.useEffect(async () => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        // setStartDate('')
        // setEndDate('')
        await Api.get("attendance").then((res) => {
            setPersonalAttendance(res.data);
            setAttendanceLogs(res.data.attendance_records);
        })
        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    }, []);
    React.useEffect(async () => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        // date to timestamp
        let start = new Date(startDate).getTime();
        let end = new Date(endDate).getTime();
        // let data = {
        //     startDate: start,
        //     endDate: end
        // }
        await Api.get("attendance", { params: { start_date: start, end_date: end } }).then((res) => {
            let temp = [];
            setPersonalAttendance(res.data);
            setAttendanceLogs(res.data.attendance_records);
            res.data.attendance_records.map((log) => {
                temp.push(log.classroom_id);
            })
            setClassIds(temp);

            Api.post("live-class/get-all-classes", { data: temp }).then((res) => {
                setClassAll(res.data);
                let temp = {};
                res.data.map((classroom) => {
                    temp[classroom._id] = classroom.class_name_section;
                })
                setClassMap(temp);
            })
        }).finally(() => {

            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        })
        // axios post with body array
        // await Api.post("live-class/get-all-calsses", { body: { data: classIds } }).then((res) => {
        //     setClassAll(res.data);
        // })
    }, [startDate, endDate]);

    // React.useEffect(async () => {
    // 	dispatch({
    // 		type: "SET_LOADING",
    // 		loading: true,
    // 	});
    // 	setFiles([]);
    // 	await Api.get("notices", { params: { notice_for_user: [userDetails?._id], } }).then((res) => {
    // 		setFiles(res.data);
    // 	})
    // 	dispatch({
    // 		type: "SET_LOADING",
    // 		loading: false,
    // 	});
    // }, [change]);
    // React.useEffect(async () => {
    //     let temp_dict = { "ALL": { "ALL": [] } };
    //     await Api.get("live-class").then((res) => {
    //         setClassDict(res.data);
    //     })
    // }, []);
    // React.useEffect(() => {
    // React.useEffect(() => {
    // 	Api.get("live-class/subject").then((res) => {
    // 		setSubjectDict(res.data);
    // 	})
    // }, []);
    // const handleDocumentTypeChange = (event) => {
    //     setDocumentType(event.target.value);
    //     if (event.target.value === "ALL") {
    //         files = fileC;
    //     }
    //     else {
    //         // filter files by usertype = ownertype
    //         files = files.filter((file) => file.userType === event.target.value);
    //     }
    // };
    // const handleOwnerUserTypeChange = (event) => {
    //     setOwnerUserType(event.target.value);
    // };
    // const handleClassChange = async (e) => {
    //     setClassName(e.target.value);
    //     // filter files by class_name_data array including class_name
    //     files.filter((file) => file.class_name_data.includes(e.target.value));
    // }
    // const handleSubjectChange = async (e) => {
    //     setSubjectName(e.target.value);
    // }
    // // useEffect(() => {
    // 	console.log("change")
    // 	dispatch({
    // 		type: "SET_LOADING",
    // 		loading: true,
    // 	});
    // 	const data = {
    // 		notice_for_user: [userDetails?._id],
    // 		// userType: ownerUserType === "ALL" ? "" : ownerUserType,
    // 		// class_data_names: className === "ALL" ? "" : className,
    // 	}
    // 	Api.get("documents", { params: data }).then((res) => {
    // 		setFiles(res.data);
    // 		// console.log(res.data);
    // 	}).finally((() => {
    // 		dispatch({
    // 			type: "SET_LOADING",
    // 			loading: false,
    // 		})
    // 	}))
    // }, [className, ownerUserType])
    return (
        <div className='ps-2 pe-2' style={{
            // width: '100%',
            minHeight: '94vh',
            backgroundColor: '#FFF',
            paddingBottom: '40px'
        }}
            align="center"
        >
            <h1 className="p-4" style={{ fontWeight: "bold" }}>Attendance</h1>
            <InputGroup>
                <InputGroup.Text>Date</InputGroup.Text>
                <Form.Control
                    type="date"
                    aria-label="From Date"
                    onChange={(e) => { setStartDate(e.target.value) }}
                />
            </InputGroup>
            <br />
            <InputGroup>
                <InputGroup.Text>Date</InputGroup.Text>
                <Form.Control
                    type="date"
                    aria-label="To Date"
                    onChange={(e) => { setEndDate(e.target.value) }}
                />
            </InputGroup>
            {/* <TextField
					select
					label="Document Type"
					variant="filled"
					style={{ minWidth: 230 }}
					value={documentType}
					onChange={handleDocumentTypeChange}
					className="m-2"
				>
				<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
						ALL
					</MenuItem>
					{documentTypes.map((documentType) => (
						<MenuItem key={documentType[0]} value={documentType[0]} style={{ width: "100%" }}>
						{documentType[1]}
						</MenuItem>
						))}
					</TextField> */}
            {/* <div>
				<TextField
					select
					label="Notice By"
					variant="filled"
					style={{ minWidth: 230 }}
					className="m-2"
					value={ownerUserType}
					onChange={handleOwnerUserTypeChange}
				>
					<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
						ALL
					</MenuItem>
					{
						userTypes.map((userType, i) => {
							return (
								<MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
									{userType[1]}
								</MenuItem>
							)
						})
					}
				</TextField>
				{(userDetails?.userType === "TEACHER") &&
					<>
						<TextField
							select
							label="For Class"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={className}
							onChange={handleClassChange}
						>
							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
								ALL
							</MenuItem>
							{
								classDict.map((cl, i) => {
									return (
										<MenuItem key={cl._id} value={cl._class_name_section} style={{ width: "100%" }}>
											{cl.class_name_section}
										</MenuItem>
									)
								})
							}
						</TextField> */}
            {/* <TextField
							select
							label="Section"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={sectionName}
							onChange={(e) => setSectionName(e.target.value)}
						>
							{Object.keys(sectionDict).map((sectionVar, i) => {
								return (
									<MenuItem key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
										{sectionVar}
									</MenuItem>
								)
							})
							}
						</TextField>
						<TextField
							select
							label="Subject"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={subjectName}
							onChange={handleSubjectChange}
						>
							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
								ALL
							</MenuItem>
							{subjectDict.map((subjectDictVar, i) => {
								// console.log(classDict[className][sectionName])
								if (className == "ALL" || (classDict[className][sectionName] && classDict[className][sectionName][0] === subjectDictVar.classroom_id)) {
									return (
										<MenuItem key={subjectDictVar._id} value={subjectDictVar._id} style={{ width: "100%" }}>
											{subjectDictVar.name}
										</MenuItem>
									)
								}
							})
							}
						</TextField> */}
            {/* </>
				}
			</div> */}
            {/* <Button className="m-3" variant="secondary" onClick={filterChange} style={{ minWidth: "100px" }}>
				Filter
			</Button> */}
            <div className="m-4">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: "80px" }}>Total days in academic year</th>
                            <th scope="col" style={{ minWidth: "200px" }}>From Date</th>
                            <th scope="col" style={{ minWidth: "280px" }}>To Date</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Total Classes</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Total Attended</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {setFileC(0)} */}
                        <tr>
                            <td >{personalAttendance.total_days_in_academic_year}</td>
                            <td>{
                                // date number to date
                                new Date(personalAttendance.start_date).toDateString()
                            }</td>
                            <td>{
                                // date number to date
                                new Date(personalAttendance.end_date).toDateString()
                            }</td>
                            <td>{personalAttendance.total_class_count}</td>
                            <td>{personalAttendance.attendance_count}</td>
                        </tr>

                    </tbody>
                </Table>
            </div>
            <h1 className="p-4" style={{ fontWeight: "bold" }}>Attendance Logs</h1>
            <div className="m-4">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: "200px" }}>Date</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Time</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Attendance Status</th>
                            <th scope="col" style={{ minWidth: "200px" }}>Class</th>
                        </tr>
                    </thead>
                    <tbody>
                        {console.log(classAll, 'all')}
                        {/* {setFileC(0)} */}
                        {attendanceLogs.map((attendanceLog, i) => {
                            return (
                                <tr>
                                    <td >{new Date(attendanceLog.updatedAt)?.toDateString()}</td>
                                    <td >{attendanceLog.updatedAt ? (new Date(attendanceLog.updatedAt).toLocaleTimeString()) : ""}</td>
                                    <td>{
                                        // date number to date
                                        attendanceLog.present ? "Present" : "Absent"
                                    }</td>
                                    <td>{
                                        classMap[attendanceLog.classroom_id] ? classMap[attendanceLog.classroom_id].class_name_section : ""
                                    }</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </Table>
            </div>
        </div >

    )
}

export default ShowYourAttendance