import React, { useState, useEffect } from 'react';
import { Stack, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Home = () => {
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const navigate = useNavigate();
    return (
        <div style={{
            // backgroundColor: 'white',
            background: "linear-gradient(to bottom right,#6E45E1, #89D4CF)",
            minHeight: '100%',
            display: 'flex',
            alignItems: 'center',
        }}>
            <div align="center" style={{
                overflow: "-moz-scrollbars-none",
                alignItems: 'center',
            }}>
                <h1 style={{
                    color: "white",
                    textAlign: "center",
                    paddingTop: "40px",
                    fontWeight: "bold",
                    fontSize: "xxx-large",
                }}>Welcome to Youth India E-School</h1>
                <p className='ps-4 pe-4' style={{
                    color: "white",
                    textAlign: "center",
                    paddingTop: "10px",
                    paddingBottom: "20px",
                    fontSize: "small",
                }}>Our flagship product ‘Youth India E-School’ is a School and Education Management platform that ensures the delivery of high quality education to the students at a very affordable price. We have built a highly competent digital infrastructure to meet the demands of the marginalised students and thus bridge the gap between the privileged and the underprivileged. The vision of our endeavour is to transform the face of the education sector. We aim to provide a level playing field for the present and future generations, with access to high quality education.</p>
                {/* <div className="mt-4 mb-4 col-10" style={{
                    borderRadius: 10,
                    background: "#FFF",
                }}
                    align="center"
                >
                    <img className="shadow" src="/Images/hero.png" alt="hero" style={{
                        width: "100%",
                        borderRadius: 10,
                        marginBottom: "-15%",
                    }} />
                </div> */}
            </div>
            {/* <div align="center" style={{
                marginTop: "15%",
                background: "rgb(125 150 215)",
                justifyContent: "center",
            }}>
                <p className='ps-4 pe-4' style={{
                    color: "#FFF",
                    // textAlign: "left",
                    paddingTop: "30px",
                    fontWeight: "bold",
                }}>POWERED AND SPONSORED BY</p>
                <Stack direction="horizontal" align="center" gap={4} align="center" className="ps-4 pe-4" style={{
                    paddingBottom: "40px",
                    justifyContent: "center",
                }}>
                    <img src="https://i.ibb.co/GPy2xhs/yif1-1.png" alt="3" height="90px" />
                    <div class="vr" style={{
                        color: "#FFF",
                    }}></div>
                    <img src="https://i.ibb.co/bJMbmHd/Vedanta-Logo-2-ai.png" alt="1" height="90px" />
                    <div class="vr" style={{
                        color: "#FFF",
                    }}></div>
                    <img src="https://i.ibb.co/qpWrKDv/Seal-of-Odisha-1.png" alt="2" height="90px" />
                </Stack>
            </div> */}
            {/* <div style={{
                background: "#FFF",
            }}>
                <h1 style={{
                    color: "#000",
                    textAlign: "center",
                    paddingTop: "40px",
                    fontWeight: "bold",
                }}>Our Vision</h1>
                <p className='ps-4 pe-4' style={{
                    color: "#000",
                    textAlign: "center",
                    paddingTop: "10px",
                    paddingBottom: "40px",
                }}>To digitise the schooling ecosystem for the purpose of administrative ease, high quality education delivery, seamless communication and creation of a virtual school environment for all stakeholders i.e School Administration, Teachers, Students and Parents.                </p>
            </div> */}
            {/* footer */}
            {/* <div
                className="p-2"
                style={{
                    position: "relative",
                    bottom: 0,
                    width: "100%",
                    marginTop: "15%",
                    justifyContent: 'space-between',
                    background: "rgb(125 150 215)",
                }}>
                <p className=" text-center text-white text-sm py-1">
                    Copyright ©️ 2022 YISE E-SCHOOL PRIVATE LIMITED
                </p>
            </div> */}
        </div>
    )
}

export default Home;