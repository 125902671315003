import React, { useState, useEffect } from "react";
import { Box, option } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import Api from "../../../Api/axios";
import { MenuItem } from "@mui/material";
import Table from "react-bootstrap/Table";
import AddIcon from "@material-ui/icons/Add";
import { v4 as uuidv4 } from "uuid";
import { Multiselect } from "multiselect-react-dropdown";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { SignalCellularNull } from "@mui/icons-material";

const AddNotice = () => {
	// const userTypes = [
	//     ['CENTRAL', 'Central'],
	//     ['DISTRICT', 'District'],
	//     ['SCHOOL_ADMIN', 'School Admin'],
	//     ['TEACHER', 'Teacher'],
	//     ['PARENT', 'Parent'],
	//     ['STUDENT', 'Student'],
	// ]

	const userTypes = [
		{ name: "Central", id: "CENTRAL" },
		{ name: "District", id: "DISTRICT" },
		{ name: "School Admin", id: "SCHOOL_ADMIN" },
		{ name: "Teacher", id: "TEACHER" },
		{ name: "Parent", id: "PARENT" },
		{ name: "Student", id: "STUDENT" },
	];

	const [
		{ user, userDetails, initialState, loggedIn, class_teacher_class_details },
		dispatch,
	] = useDataLayerValue();

	const [title, setTitle] = useState("");
	const [classList, setClassList] = useState([]);
	const [description, setDescription] = useState("");
	const [date, setDate] = useState("");

	const [pageNo, setPageNo] = useState(1);

	const [canUpload, setCanUpload] = useState(
		userDetails?.userTypes !== "STUDENT"
	);
	const [recipientUserTypeList, setRecipientUserTypeList] = useState([]);
	const [userList, setUserList] = useState([]);
	const currentUserTypeIndex = userTypes.findIndex(
		(userType) => userType.id === userDetails?.userType
	);

	const [availableRecipients, setAvailableRecipients] = useState(
		userTypes.filter((userType, i) => i > currentUserTypeIndex)
	);
	const [notices, setNotices] = useState([
		{ id: uuidv4(), title: "", description: "", date: "", file: "" },
	]);
	const [forClasses, setForClasses] = useState([]);
	const [finalData, setFinalData] = useState([]);
	const [schoolList, setSchoolList] = useState({});
	const [schoolFilter, setSchoolFilter] = useState("");

	useEffect(() => {
		dispatch({
			type: "SET_LOADING",
			loading: true,
		});
		if (userDetails.userType === "DISTRICT") {
			let temp = {};
			Api.get("school-entity/school")
				.then((res) => {
					res.data.map((sc) => {
						temp[sc._id] = sc.name;
					});
					console.log("school", temp);
					setSchoolList(temp);
				})
				.finally(() => {
					dispatch({
						type: "SET_LOADING",
						loading: false,
					});
				});
		}
	}, [userDetails]);

	// const movetouser = async () => {

	//     recipientUserTypeList.map(async (userType) => {

	//         param = { userType: userType }
	//         forClasses.map((cl, i) => {
	//             param[`classes[${i}]`] = cl;
	//         }
	//         await Api.get('user/user-data', { params: param }).then((res) => {
	//         })
	//     })
	// }

	const movetouser = () => {
		if (userDetails.userType === "DISTRICT" && schoolFilter === "") {
			alert("Please choose school");
			return;
		}
		let temp_userList = [];
		recipientUserTypeList.map((userType) => {
			let param = { userType: userType };
			forClasses.map((cl, i) => {
				param[`classes[${i}]`] = cl._id;
			});
			if (userDetails.userType === "DISTRICT") {
				param["schoolId"] = schoolFilter;
			}
			dispatch({
				type: "SET_LOADING",
				loading: true,
			});
			Api.get("user/users-data", { params: param })
				.then((res) => {
					temp_userList.push(...res.data);
				})
				.finally(() => {
					dispatch({
						type: "SET_LOADING",
						loading: false,
					});
				});
		});
		setUserList(temp_userList);
		setPageNo(2);
	};

	const postNotice = async (event) => {
		let file_sum = 0;
		for (const doc of notices) {
			if (doc.title === "") {
				alert("Please enter title for all documents");
				return;
			}
			if (doc.description === "") {
				alert("Please enter description for all documents");
				return;
			}
			if (doc.file === "") {
				alert("Please select a file for all documents");
				return;
			}
			if (doc.file.size > 10485760) {
				alert("File size should be less than 10MB");
				return;
			}
			file_sum += doc.file.size;
			if (file_sum > 26214400) {
				alert("Total file size should be less than 25MB");
				return;
			}
		}
		for (const doc of notices) {
			console.log(doc, "okok");
			let formData = new FormData();
			formData.append("file", doc.file);
			formData.append("title", doc.title);
			formData.append("description", doc.description);
			formData.append("school_id", schoolFilter);
			formData.append("date", doc.date);
			recipientUserTypeList.map((each) => {
				formData.append("notice_for[]", each);
			});
			forClasses.forEach((item) => {
				formData.append("class_data_names[]", item.class_name_section);
			});
			userList.forEach((item) => {
				if (item.checked) formData.append("notice_for_user[]", item._id);
			});
			console.log(doc, "doc");
			await Api.post("notices", formData)
				.then((res) => {
					console.log(res, "okok");
					setFinalData([...finalData, res.data]);
				})
				.catch((err) => {
					setPageNo(-1);
				});
		}
		setUserList([]);
		setNotices([{ id: uuidv4(), title: "", description: "", file: "" }]);
		if (pageNo != -1) setPageNo(4);
		setForClasses([]);
		setRecipientUserTypeList([]);
		setSchoolFilter("");
	};

	const doNothing = (event) => {
		return true;
	};
	const handleTitleChange = (e) => {
		setTitle(e.target.value);
	};

	const handleDescriptionChange = (e) => {
		setDescription(e.target.value);
	};

	const handleDateChange = (e) => {
		setDate(e.target.value);
	};

	useEffect(() => {
		Api.get("/school-entity/all-classes?school_id=" + schoolFilter).then(
			(res) => {
				let ClassList = [];
				let fetchedData = res.data;
				fetchedData.forEach((x) => {
					let obj = {};
					obj.name = x.class_name_section;
					obj.id = x._id;
					ClassList.push(obj);
				});
				setClassList(ClassList);
			}
		);
	}, [recipientUserTypeList]);

	console.log(classList);

	const [selectedOptions, setSelectedOptions] = useState([]);
	const [removedOptions, setRemovedOptions] = useState([]);
	const onSelectOptions = (selectedList, selectedItem) => {
		setSelectedOptions([...selectedOptions, selectedItem]);
	};
	const onRemoveOptions = (selectedList, removedItem) => {
		setRemovedOptions([...removedOptions, removedItem]);
	};
	return (
		<>
			<h1 className="p-4" style={{ fontWeight: "bold" }} align="center">
				Create Notice
			</h1>
			<center>
				{canUpload ? (
					<>
						{pageNo == 1 ? (
							<div
								className="shadow-lg p-4 col-10 col-lg-9"
								style={{ borderRadius: 10, backgroundColor: "#fff" }}
								align="center"
							>
								<Form.Group controlId="formFile" className="m-3">
									<Stack type="vartical" gap={4}>
										{userDetails.userType === "DISTRICT" ? (
											<InputGroup className="p-2">
												<InputGroup.Text>Select School</InputGroup.Text>
												<Form.Control
													as="select"
													label="Select School"
													variant="filled"
													style={{ minWidth: 230 }}
													value={schoolFilter}
													onChange={(e) => setSchoolFilter(e.target.value)}
												>
													<option key="ALL" value="" style={{ width: "100%" }}>
														ALL
													</option>

													{Object.keys(schoolList).map((sc, i) => (
														<option
															key={sc}
															value={sc}
															style={{ width: "100%" }}
														>
															{schoolList[`${sc}`]}
														</option>
													))}
												</Form.Control>
											</InputGroup>
										) : null}

										<InputGroup>
											<InputGroup.Text>Notice For</InputGroup.Text>
											{/* {
                                                console.log(availableRecipients)
                                            } */}
											<Multiselect
												showArrow
												options={availableRecipients}
												displayValue="name"
												showCheckbox={true}
												onSelect={(selectedList, selectedItem) => {
													setRecipientUserTypeList([
														...recipientUserTypeList,
														selectedItem.id,
													]);
												}}
												onRemove={(selectedList, selectedItem) => {
													setRecipientUserTypeList(
														recipientUserTypeList.filter(
															(item) => item != selectedItem.id
														)
													);
												}}
											/>
										</InputGroup>

										{(recipientUserTypeList.includes("TEACHER") ||
											recipientUserTypeList.includes("STUDENT")) && (
												<>
													{/* <InputGroup>
												<InputGroup.Text>Select Class</InputGroup.Text>
												<Form.Select aria-label="Select Document Type"
													value={className}
													onChange={handleClassChange}
												>
													{console.log(classDict)}
													{Object.keys(classDict).map((sectionVar, i) => {
														return (
															<option key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
																{sectionVar}
															</option>
														)
													})}
												</Form.Select>
											</InputGroup> */}

													<InputGroup>
														<InputGroup.Text>
															Select Class & Section
														</InputGroup.Text>
														<Multiselect
															options={classList}
															name="particulars"
															onSelect={onSelectOptions}
															onRemove={onRemoveOptions}
															displayValue="name"
															closeIcon="cancel"
															placeholder="Select Options"
															selectedValues={selectedOptions}
															className="multiSelectContainer"
														/>

														{/* <Form.Select aria-label="Select Document Type"
													value={sectionName}
													onChange={(e) => setSectionName(e.target.value)}
												>
													{Object.keys(sectionDict).map((sectionVar, i) => {
														return (
															<option key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
																{sectionVar}
															</option>
														)
													})
													}
                                                    
												</Form.Select> */}
														{/* {console.log(sectionDict)}
                                                {
                                                    console.log(classDict)
                                                } */}

														{/* <Multiselect
                            showArrow
                            options={classList}
                            displayValue="class_name_section"
                            groupBy="class_name"
                            showCheckbox={true}
                            selectedValues={forClasses}
                            onSelect={(selectedList, selectedItem) => {
                              setForClasses(selectedList);
                            }}
                            onRemove={(selectedList, selectedItem) => {
                              setForClasses(selectedList);
                            }}
                          /> */}
														{/* {console.log(sectionIds)} */}
													</InputGroup>
													{/* <InputGroup>
                                                <InputGroup.Text>Select Subject</InputGroup.Text>
                                                <Form.Select aria-label="Select Document Type" value={subjectName}
                                                    onChange={handleSubjectChange}
                                                >
                                                    <option key="ALL" value="ALL" style={{ width: "100%" }}>
                                                        ALL
                                                    </option>
                                                    {subjectDict.map((subjectDictVar, i) => {
                                                        // console.log(classDict[className][sectionName])
                                                        console.log(sectionIds)
                                                        console.log(!sectionIds, sectionIds.length)
                                                        if (sectionIds.size == 0 || (sectionIds.size > 0 && sectionIds.has(subjectDictVar.classroom_id))) {
                                                            return (
                                                                <option key={subjectDictVar._id} value={subjectDictVar._id} style={{ width: "100%" }}>
                                                                    {subjectDictVar.name}
                                                                </option>
                                                            )
                                                        }
                                                    })
                                                    }
                                                </Form.Select>
                                            </InputGroup> */}

													{/* <InputGroup>
                                            <InputGroup.Text>Notice Title</InputGroup.Text>
                                            <Form.Control
                                                placeholder="Enter Notice Title"
                                                aria-label="Write notice title"
                                                aria-describedby="basic-addon1"
                                                onChange={handleTitleChange}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup.Text>Notice Description</InputGroup.Text>
                                            <Form.Control
                                                as="textarea"
                                                aria-label="Description"
                                                onChange={handleDescriptionChange}
                                            />
                                        </InputGroup>
                                        <InputGroup>
                                            <InputGroup.Text>Date</InputGroup.Text>
                                            <Form.Control
                                                type="date"
                                                aria-label="Date"
                                                onChange={handleDateChange}
                                            />
                                        </InputGroup> */}
												</>
											)}

										<center
											style={{ display: "flex", justifyContent: "flex-end" }}
										>
											<Button
												variant="outline-secondary"
												size="lg"
												onClick={movetouser}
												style={{ minWidth: "150px" }}
											>
												Next
											</Button>
										</center>
									</Stack>
								</Form.Group>
							</div>
						) : pageNo == 2 ? (
							<>
								<div
									className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mb-4"
									style={{ borderRadius: 10, backgroundColor: "#FFF" }}
									align="center"
								>
									<Stack className="row" direction="horizontal">
										<div
											className="col-12 col-sm-6"
											style={{ display: "flex" }}
										>
											<Button
												variant="light"
												style={{ backgroundColor: "#FFF", color: "#6c757d" }}
												onClick={(e) => {
													setUserList([]);
													setForClasses([]);
													setRecipientUserTypeList([]);
													setSchoolFilter("");
													setPageNo(1);
												}}
											>
												← Previous
											</Button>
										</div>
										<div
											className="col-12 col-sm-6"
											style={{ display: "flex", justifyContent: "flex-end" }}
										>
											<Button
												variant="outline-secondary"
												align="right"
												style={{ minWidth: "150px" }}
												onClick={(e) => setPageNo(3)}
											>
												Next
											</Button>
										</div>
									</Stack>
									<h2 className="mt-4">List of Users</h2>
									<div>
										<Button
											className="m-4"
											variant="outline-secondary"
											onClick={(e) =>
												setUserList(
													userList.map((us) => ({ ...us, checked: false }))
												)
											}
											style={{ minWidth: "150px" }}
										>
											Un-Check All
										</Button>
										<Button
											className="m-4"
											variant="outline-secondary"
											onClick={(e) =>
												setUserList(
													userList.map((us) => ({ ...us, checked: true }))
												)
											}
											style={{ minWidth: "150px" }}
										>
											Check All
										</Button>
									</div>
									{console.log(userList, "uselisy")}

									<Table
										striped
										bordered
										hover
										responsive
										className="mt-4 mb-4"
									>
										<thead>
											<tr>
												<td>
													<b>Sr. No.</b>
												</td>
												<td>
													<b>Name</b>
												</td>
												<td>
													<b>Username</b>
												</td>
												<td>
													<b>User Type</b>
												</td>
												<td>
													<b>Check/Uncheck</b>
												</td>
											</tr>
										</thead>
										<tbody>
											{userList.map((user, i) => {
												console.log(user);
												return (
													<tr key={i}>
														<td>{i + 1}</td>
														<td>{user.name}</td>
														<td>{user.conventionalId}</td>
														<td>{user.userType}</td>
														<td>
															<Form.Check
																type="checkbox"
																style={{ padding: 10 }}
																checked={user.checked}
																onChange={(e) => {
																	let temp = [...userList];
																	temp[i].checked = !temp[i].checked;
																	setUserList(temp);
																}}
															/>
														</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</div>
							</>
						) : pageNo == 3 ? (
							<>
								{notices.map((notice, i) => {
									return (
										<div
											className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4"
											style={{ borderRadius: 10, backgroundColor: "#FFF" }}
											align="center"
										>
											<Form.Group controlId="formFile" className="m-3">
												<Stack type="vartical" gap={4}>
													<InputGroup>
														<InputGroup.Text>Title</InputGroup.Text>
														<Form.Control
															aria-label="Default"
															aria-describedby="inputGroup-sizing-default"
															value={notice.title}
															onChange={(e) =>
																setNotices(
																	notices.map((note) =>
																		note.id === notice.id
																			? { ...note, title: e.target.value }
																			: note
																	)
																)
															}
														// placeholder="Enter Title"
														/>
													</InputGroup>
													<InputGroup>
														<InputGroup.Text>Description</InputGroup.Text>
														<Form.Control
															as="textarea"
															aria-label="With textarea"
															aria-describedby="inputGroup-sizing-default"
															value={notice.description}
															onChange={(e) =>
																setNotices(
																	notices.map((note) =>
																		note.id === notice.id
																			? { ...note, description: e.target.value }
																			: note
																	)
																)
															}
														/>
													</InputGroup>
													<InputGroup>
														<InputGroup.Text>Date</InputGroup.Text>
														<Form.Control
															type="date"
															aria-label="Date"
															onChange={(e) =>
																setNotices(
																	notices.map((note) =>
																		note.id === notice.id
																			? { ...note, date: e.target.value }
																			: note
																	)
																)
															}
														/>
													</InputGroup>

													<Form.Control
														type="file"
														// value={uploadFile}
														onChange={(e) =>
															setNotices(
																notices.map((note) =>
																	note.id === notice.id
																		? { ...note, file: e.target.files[0] }
																		: note
																)
															)
														}
													/>
												</Stack>
											</Form.Group>
											<Button
												variant="outline-danger"
												size="lg"
												disabled={notices.length <= 1}
												onClick={(e) =>
													setNotices(
														notices.filter((note) => note.id !== note.id)
													)
												}
											>
												<DeleteForeverIcon /> Delete
											</Button>
										</div>
									);
								})}
								<div
									className="mt-4 p-4 col-12 col-sm-10 col-lg-9"
									style={{ borderRadius: 10 }}
									align="center"
								>
									<Button
										variant="outline-secondary"
										onClick={(e) => {
											notices.length <= 4
												? setNotices([
													...notices,
													{
														id: uuidv4(),
														title: "",
														description: "",
														file: "",
													},
												])
												: alert("You can add maximum of 5 documents at once.");
										}}
									>
										<AddIcon />
										Add Notice
									</Button>
								</div>
								<div
									className=" p-4 col-12 col-sm-10 col-lg-9"
									style={{ borderRadius: 10 }}
									align="center"
								>
									<Stack direction="horizontal">
										<div style={{ display: "flex", width: "50%" }}>
											<Button
												variant="light"
												style={{ backgroundColor: "#FFF", color: "#6c757d" }}
												size="lg"
												onClick={(e) => setPageNo(2)}
											>
												← Previous
											</Button>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-end",
												width: "50%",
											}}
										>
											<Button
												variant="outline-secondary"
												size="lg"
												align="right"
												onClick={postNotice}
											>
												Upload Notice
												{console.log(notices)}
											</Button>
										</div>
									</Stack>
								</div>
							</>
						) : (
							<center>
								<div
									className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4"
									style={{ borderRadius: 10, backgroundColor: "#FFF" }}
									align="center"
								>
									<img
										src={`${process.env.PUBLIC_URL}/Images/sucess.png`}
										style={{ width: "100px" }}
										alt=""
									/>
									<h3 className="m-4">File Sucessfully Uploaded</h3>
									<Stack
										className="m-4"
										direction="horizontal"
										gap={3}
										style={{ justifyContent: "center" }}
									>
										<Button
											variant="outline-secondary"
											size="lg"
											onhover={(e) => doNothing()}
											onClick={(e) => setPageNo(1)}
										>
											Upload Another Notice
										</Button>
										<Button
											variant="outline-secondary"
											size="lg"
											onhover={(e) => doNothing()}
											onClick={(e) => {
												window.location.href = window.location.origin;
											}}
										>
											Go to Dashboard
										</Button>
									</Stack>
								</div>
							</center>
						)}
					</>
				) : pageNo == -1 ? (
					<div
						className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4"
						style={{ borderRadius: 10, backgroundColor: "#FFF" }}
						align="center"
					>
						<img
							src={`${process.env.PUBLIC_URL}/Images/failure.png`}
							style={{ width: "100px" }}
							alt=""
						/>
						<h3 className="m-4">There Was Error Uploading Notice</h3>
						<Stack
							className="m-4"
							direction="horizontal"
							gap={3}
							style={{ justifyContent: "center" }}
						>
							<Button
								variant="outline-secondary"
								size="lg"
								onhover={(e) => doNothing()}
								onClick={(e) => setPageNo(1)}
							>
								Upload Another Notice
							</Button>
							<Button
								variant="outline-secondary"
								size="lg"
								onhover={(e) => doNothing()}
								onClick={(e) => {
									window.location.href = window.location.origin;
								}}
							>
								Go to Dashboard
							</Button>
						</Stack>
					</div>
				) : (
					<div
						className="shadow-lg p-4 col-10 col-lg-9 mt-4"
						style={{ borderRadius: 10 }}
						align="center"
					>
						<img
							src={`${process.env.PUBLIC_URL}/Images/failure.png`}
							style={{ width: "100px" }}
							alt=""
						/>
						<h3 className="m-4">
							Sorry, you don't have permission to upload documents.
						</h3>
						<Stack
							className="m-4"
							direction="horizontal"
							gap={3}
							style={{ justifyContent: "center" }}
						>
							<Button
								variant="outline-secondary"
								size="lg"
								onClick={(e) => {
									window.location.href = window.location.origin;
								}}
							>
								Go to Dashboard
							</Button>
						</Stack>
					</div>
				)}
			</center>
		</>
	);
};

export default AddNotice;
