import {
  Box,
  createTheme,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../StudentAssessmentTracker/components/TabPanel";
import { a11yProps } from "../StudentAssessmentTracker/components/Constants";
import SubmittedAssessment from "./SubmittedAssessment";
import NotSubmittedAssessment from "./NotSubmittedAssessment";
import AllAssessment from "./AllAssessment";
import { SearchOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import QuestionsUI from "./QuestionsUI";
// import { makeStyles } from '@mui/styles';
import "./StudentTracker.css";

const filtersBy = ["All", "Urgency"];

const StudentAssessmentTracker = ({ updateAssId,selectedAssid ,backToTracker}) => {
  const [value, setValue] = useState(0);
  const [search, setSearch] = useState("");

  function changeFilterForAssessment(e) {
    setFilterAssessment(e?.target?.value);
  }

  const [filterAssessment, setFilterAssessment] = useState(filtersBy[0]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
    {!selectedAssid.status&&
      <Box sx={{ width: "100%" }}>
      <h3 className="p-4">Assessment Tracker</h3>
      <Grid item xs={12} md={12} sm={12} lg={12} className="mt-2 mb-3 p-4">
        <Grid container spacing={4}>
          <Grid item xs={12} md={12} sm={12} lg={6}>
            <TextField
              variant="outlined"
              className="w-100"
              label="Search by"
              onChange={(e) => setSearch(() => e.target.value)}
              InputProps={{
                startAdornment: (
                  <IconButton>
                    <SearchOutlined />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} sm={12} lg={6}>
            <FormControl fullWidth>
              <InputLabel>Filter by</InputLabel>
              <Select
                label="Filter by"
                labelId="Filter"
                value={filterAssessment}
                onChange={(e) => changeFilterForAssessment(e)}
              >
                {filtersBy.map((x, i) => (
                  <MenuItem key={x} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="All" {...a11yProps(0)} />
          <Tab label="Pending" {...a11yProps(1)} />
          <Tab label="Submitted" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AllAssessment search={search} filterAssessment={filterAssessment} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <NotSubmittedAssessment
          updateAssId={updateAssId}
          search={search}
          selectedAssid={selectedAssid}
          filterAssessment={filterAssessment}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <SubmittedAssessment
          search={search}
          filterAssessment={filterAssessment}
        />
      </TabPanel>
    </Box>
    }
    </>
    
  );
};

export default StudentAssessmentTracker;
