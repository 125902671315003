import { React, useState, useEffect } from 'react';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Box, Button as ButtonMui, Table } from '@material-ui/core';
import Api from "../../Api/axios";
import { Multiselect } from "multiselect-react-dropdown";
import { ExcelRenderer } from "react-excel-renderer";
import { AppendDataWithPostObjFormat } from "./Constants"
import MultiSelectComp from './component/MultiSelect';
import Modal from 'react-bootstrap/Modal';

const AddUser = () => {
	const [{ userDetails }, dispatch] = useDataLayerValue();
	const [userTypeList, setUserTypeList] = useState([
		['DISTRICT', 'District'],
		['SCHOOL_ADMIN', 'School Admin'],
		['TEACHER', 'Teacher'],
		['STUDENT', 'Student'],
	]);
	const [currentUserTypeIndex, setCurrentUserTypeIndex] = useState(
		userTypeList.findIndex((item) => item[0] === userDetails?.userType)
	);
	const [userCreateType, setUserCreateType] = useState(userTypeList[currentUserTypeIndex + 1][0]);
	const [userCreateName, setUserCreateName] = useState("");
	const [userCreateDesignation, setUserCreateDesignation] = useState(
		userTypeList[currentUserTypeIndex + 1][0]
	);
	const [userCreateEmail, setUserCreateEmail] = useState("");
	const [userCreatePassword, setUserCreatePassword] = useState("");
	const [userCreateConfirmPassword, setUserCreateConfirmPassword] = useState("");
	const [userCreatePhone, setUserCreatePhone] = useState("");
	const [userCreateAddress, setUserCreateAddress] = useState("");
	const [userCreateDob, setUserCreateDob] = useState("");
	const [userCreateGender, setUserCreateGender] = useState("");
	const [userCreateReligion, setUserCreateReligion] = useState("");
	const [userCreateCaste, setUserCreateCaste] = useState("");
	const [userCreateMotherTongue, setUserCreateMotherTongue] = useState("");
	const [userCreateDisability, setUserCreateDisability] = useState("");
	const [userCreateDistrict, setUserCreateDistrict] = useState("");
	const [userCreateSchool, setUserCreateSchool] = useState("");
	const [userCreateClass, setUserCreateClass] = useState("");
	const [userCreateAdmissionNumber, setUserCreateAdmissionNumber] = useState("");
	const [userCreateFatherName, setUserCreateFatherName] = useState("");
	const [userCreateMotherName, setUserCreateMotherName] = useState("");
	const [userCreateParentsAddress, setUserCreateParentsAddress] = useState("");
	const [userCreateLocality, setUserCreateLocality] = useState("");
	const [userCreateBPL, setUserCreateBPL] = useState("No");
	const [userCreateStudentType, setUserCreateStudentType] = useState("");
	const [userCreateMediumOfInstruction, setUserCreateMediumOfInstruction] = useState("");
	const [userCreateAadharNumber, setUserCreateAadharNumber] = useState("");
	const [userCreateEyeScreening, setUserCreateEyeScreening] = useState("");
	const [userCreateTeacherCatagory, setUserCreateTeacherCatagory] = useState("");
	const [userCreateClassTaughtType, setUserCreateClassTaughtType] = useState("");
	const [userCreateClassTaught, setUserCreateClassTaught] = useState([]);
	const [userCreateSubjectTaught, setUserCreateSubjectTaught] = useState("");
	const [userCreateAppointmentDate, setUserCreateAppointmentDate] = useState("");
	const [userCreatePlusTwoStream, setUserCreatePlusTwoStream] = useState("");
	const [userCreateGraduationStream, setUserCreateGraduationStream] = useState("");
	const [userCreatePostGraduationStream, setUserCreatePostGraduationStream] = useState("");
	const [userCreatePhdSubject, setUserCreatePhdSubject] = useState("");
	const [userCreateProfessionalQualification, setUserCreateProfessionalQualification] = useState("");
	const [userCreateMPhilStream, setUserCreateMPhilStream] = useState("");
	const [districtList, setDistrictList] = useState([]);
	const [schoolList, setSchoolList] = useState([]);
	const [classList, setClassList] = useState([]);
	const [subjectList, setSubjectList] = useState([]);
	const [studentTypeList, setStudentTypeList] = useState([]);
	const [classTaughtTypeList, setClassTaughtTypeList] = useState([]);
	const [sucess, setSucess] = useState(false);
	const [check, setCheck] = useState(false);
	useEffect(() => {
		Api.get("user/get_class_taught_type").then((res) => {
			setClassTaughtTypeList(["None", ...Object.keys(res.data)]);
		});
		Api.get("preconfig-entites/subjects").then((res) => {
			res.data.sort((a, b) => a.name.localeCompare(b.name));
			setSubjectList(res.data);
		})
	}, []);
	useEffect(() => {
		setCurrentUserTypeIndex(
			userTypeList.findIndex((item) => item[0] === userDetails?.userType)
		);
		setUserCreateDistrict(userDetails?.districtId);
		if (userDetails?.schoolId) {
			setUserCreateSchool(userDetails?.schoolId);
		} else if (userDetails?.districtId) {
			setUserCreateDistrict(userDetails?.districtId);
		}
	}, [userDetails]);
	useEffect(() => {
		if (userCreateSchool) {
			dispatch({
				type: "SET_LOADING",
				loading: true,
			});
			Api.get("school-entity/all-classes?school_id=" + userCreateSchool).then((res) => {
				setClassList(res.data);
				if (res.data.length > 0 && userCreateType !== "TEACHER") {
					setUserCreateClass(res.data[0]._id);
				}
			}).finally(() => {
				dispatch({
					type: "SET_LOADING",
					loading: false,
				});
			});
		}
	}, [userCreateSchool, userCreateType]);
	useEffect(() => {
		dispatch({
			type: "SET_LOADING",
			loading: true,
		});
		Api.get("school-entity/school").then((res) => {
			if (userDetails?.userType === "DISTRICT") {
				res.data.sort((a, b) => a.name.localeCompare(b.name));
				setSchoolList(res.data);
				if (res.data.length > 0) {
					setUserCreateSchool(res.data[0]._id);
				}
			} else {
				setSchoolList(res.data._id)
			}
		}
		).finally(() => {
			dispatch({
				type: "SET_LOADING",
				loading: false,
			});
		});
	}, [userCreateDistrict]);
	useEffect(() => {
		setUserCreateType(userTypeList[currentUserTypeIndex + 1][0]);
		setUserCreateDesignation(userTypeList[currentUserTypeIndex + 1][0]);
	}, [currentUserTypeIndex]);
	const [error, setError] = useState("");
	const handleUserCreate = () => {
		if (userCreatePassword !== userCreateConfirmPassword) {
			setError("Password and Confirm Password do not match");
			setSucess(false);
			return;
		}
		if (userCreateType === "STUDENT" && !userCreateClass) {
			setError("Please select a class");
			setSucess(false);
			return;
		}
		if (userCreateType === "TEACHER" && !userCreateClassTaught.length) {
			setError("Please select a class taught");
			setSucess(false);
			return;
		}
		if (userCreateType === "TEACHER" && !userCreateSubjectTaught) {
			setError("Please select a subject taught");
			setSucess(false);
			return;
		}
		if (userCreateName === "") {
			setError("Please enter a name");
			setSucess(false);
			return;
		}
		if (userCreatePhone === "" || userCreatePhone.length !== 10 || isNaN(userCreatePhone)) {
			setError("Please enter a valid phone number");
			setSucess(false);
			return;
		}
		var data = {
			email: userCreateEmail,
			name: userCreateName,
			password: userCreatePassword,
			contactNumber: userCreatePhone,
			schoolId: userCreateSchool,
			userType_specific_data: {
				districtId: userCreateDistrict,
				userType: userCreateType,
				gender: userCreateGender,
				displayUserType: userCreateDesignation,
				Teacher_category: userCreateTeacherCatagory,
				class_taught_type: userCreateClassTaughtType,
				Caste: userCreateCaste,
				disability_type: userCreateDisability,
				DOB: userCreateDob,
				permanent_address: userCreateAddress,
				plus_two_stream: userCreatePlusTwoStream,
				graduation_stream: userCreateGraduationStream,
				postgraduation_stream: userCreatePostGraduationStream,
				Phd: userCreatePhdSubject,
				m_phil: userCreateMPhilStream,
				Professional_qualification: userCreateProfessionalQualification,
				first_appointment_date: userCreateAppointmentDate,
				admission_number: userCreateAdmissionNumber,
				aadhar_number: userCreateAadharNumber,
				father_name: userCreateFatherName,
				mother_name: userCreateMotherName,
				student_parents_address: userCreateParentsAddress,
				name_of_habitation_or_locality: userCreateLocality,
				mother_tongue: userCreateMotherTongue,
				Religion: userCreateReligion,
				social_category: userCreateCaste,
				is_student_bpl: userCreateBPL,
				student_opted: userCreateStudentType,
				medium_of_instruction: userCreateMediumOfInstruction,
			}
		}
		if (userCreateType === "STUDENT") {
			console.log(userCreateClass);
			data["userType_specific_data"][`classes`] = [userCreateClass];
		}
		if (userCreateType === "TEACHER") {
			console.log(userCreateClassTaught.map((item) => item._id))
			data["userType_specific_data"][`classes`] = userCreateClassTaught.map((item) => item._id);
		}
		if (userCreateSubjectTaught.length > 0) {
			data["userType_specific_data"][`subject_taught`] = userCreateSubjectTaught;
		}
		dispatch({
			type: "SET_LOADING",
			loading: true,
		});
		if (userCreateType === "SCHOOL_ADMIN") {
			Api.post("user/school-admin", data).then((res) => {
				setSucess(true);
				setError("Created User with Id : " + res.data.currentUser.conventionalId);
			}).catch((err) => {
				setError(err.message);
				setSucess(false);
			}).finally(() => {
				dispatch({
					type: "SET_LOADING",
					loading: false,
				});
			}
			);
		} else if (userCreateType === "TEACHER") {
			Api.post("user/teacher", data).then((res) => {
				setSucess(true);
				setError("Created User with Id : " + res.data.currentUser.conventionalId);
			}).catch((err) => {
				setError(err.message);
				setSucess(false);
			}).finally(() => {
				dispatch({
					type: "SET_LOADING",
					loading: false,
				});
			}
			);
		} else if (userCreateType === "STUDENT") {
			Api.post("user/student", data).then((res) => {
				setSucess(true);
				setError("Created User with Id : " + res.data.currentUser.conventionalId);
			}).catch((err) => {
				setError(err.message);
				setSucess(false);
			}).finally(() => {
				dispatch({
					type: "SET_LOADING",
					loading: false,
				});
			}
			);
		}
	}
	useEffect(() => {
		if (error !== "" && error !== undefined && error !== null && error.length !== 0)
			setCheck(true);
	}, [error])
	const [file, setFile] = useState("");
	const [bulkFiles, setBulkFiles] = useState([])
	const [Header, setHeader] = useState([])
	const [isUploading, setIsUploading] = useState({
		pending: false,
		finished: false
	})

	useEffect(() => {
		if (file) {
			ExcelRenderer(file, (err, resp) => {
				if (err) {
					console.log(err);
				} else {
					//   console.log("resp ==> ",resp)
					let HeaderDatas = resp.rows.slice(0, 1)
					let Usersdatas = resp.rows.slice(1)
					//   console.log("bedore ",Usersdatas)
					Usersdatas = Usersdatas.map((userdata) => {
						userdata = Array.from(userdata, item => {
							return item || ""
						})
						// console.log("cheanhing", userdata)
						return userdata
					})
					//   console.log( "Usersdatas test",Usersdatas[2][0]);
					//    console.log("bdrefe",HeaderDatas)

					// HeaderDatas=HeaderDatas[0].map((z)=>{
					//     return `&lt;th&gt${z}</th>`
					// })
					// console.log("after",Usersdatas)
					// console.log("after HeaderDatas",HeaderDatas)
					// console.log("after checking",[HeaderDatas])
					setHeader(() => HeaderDatas[0])
					setBulkFiles(() => Usersdatas)
				}
			});
		}
	}, [file]);

	function postDataObj(Bulkarray) {
		let uploadedFiles = []
		Bulkarray?.filter((x) => {
			return x.length !== 0
		}).map((userData) => {
			let dataObj = AppendDataWithPostObjFormat(userData, userCreateSchool)
			if (userCreateType == "STUDENT") {
				dataObj["userType_specific_data"][`classes`] = [userCreateClass];
			}//teacher class will be adde after finalize ,wheter it was uploaded from excell or user may want to select from web for particular taecher or one select for all teachers for batch uploading
			uploadedFiles.push(dataObj)
		})
		console.log("uploadedFiles ==> ", uploadedFiles)


		async function uploadDatas() {
			// console.log("chckig behind ")

			setIsUploading((pre) => {
				return {
					...pre,
					pending: true,
					finished: false
				}
			})
			let count = 0
			let uploadedUserType = uploadedFiles[0].userType_specific_data.userType//it is from excel
			if (uploadedUserType === "STUDENT") {
				uploadedUserType = "student"
			} else if (uploadedUserType === "TEACHER") {
				uploadedUserType = "teacher"
			}
			else if (uploadedUserType === "SCHOOL_ADMIN") {
				uploadedUserType = "school-admin"
			}
			// console.log("uploadedFiles ==> ",uploadedFiles)
			//  uploadedFiles=["1"]//dev testing
			for await (const userData of uploadedFiles) {
				// console.log(`user/${uploadedUserType}`,"userData",userData)
				await Api.post(`user/${uploadedUserType}`, userData).then((response) => {
					count++
					if (count === uploadedFiles.length) {
						setIsUploading((pre) => {
							return {
								...pre,
								pending: false,
								finished: true
							}
						})
						setHeader(() => [])
						setBulkFiles(() => [])

					}
					console.log("response", response)
				}).catch((error) => {
					console.log("err", error)
				})
				setIsUploading((pre) => { //dev tetsing
					return {
						...pre,
						pending: false,
						finished: true
					}
				})
				setHeader(() => [])
				setBulkFiles(() => [])
			}

		}
		uploadDatas()

	}



	return (
		<div style={{ backgroundColor: "#fff" }} align="center">
			{bulkFiles.length !== 0 && Header.length !== 0 && (
				<div className="p-2" align="center">
					<Box className="pe-4 py-3 d-flex justify-content-end align-items-center">
						<Form.Label>User Type : </Form.Label>
						<Form.Group className="ms-2" controlId="userType">
							<Form.Select
								aria-label="User Type"
								value={userCreateType}
							// onChange={(e) => {
							//   setUserCreateType(e.target.value);
							//   setUserCreateDesignation(e.target.value);
							// }}
							>
								{/* {userTypeList.map((userType, idx) => {
                        if (idx > currentUserTypeIndex) { */}
								{/* return  */}
								<option value={userTypeList[3][0]}>{userTypeList[3][1]}</option>
								{/* } */}
								{/* })} */}
							</Form.Select>
						</Form.Group>
						{/* { userCreateType==="STUDENT"&& */}
						<Form.Group
							className="ms-2"
							controlId="class"
						>
							<Form.Select
								// style={{width:"2em"}}
								placeholder='select Class'
								value={userCreateClass}
								onChange={(e) => setUserCreateClass(e.target.value)}
							>
								{classList?.map((classs) => {
									return (
										<option value={classs._id}>
											{classs.class_name_section}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>
						{/* } */}

						{/* { userCreateType==="TEACHER"&& //
              <MultiSelectComp
              classes="ms-2"
              options={classList}
              // style={{width:"2em"}}
              groupBy={"class_name"}
              selectedVal={userCreateClassTaught}
              setSelected={setUserCreateClassTaught}
              // style={{ marginTop: "0"}}
              />
                } */}
						<ButtonMui onClick={() => postDataObj(bulkFiles)}
							style={{ backgroundColor: "green" }}
							className="ms-2"
						>
							{isUploading.pending ? "Uploading" : isUploading.finished ? "Uploaded" : "Confirm Upload"}
						</ButtonMui>
					</Box>
					<Table className='mx-4' striped bordered hover responsive>
						<thead className='mx-5'>
							<tr>
								{Header.map((y) => {
									return (
										<th
											style={{
												minWidth: "150px",
												borderTop: "1px solid rgb(0 0 0 / 26%)",
												borderLeft: "none",
												borderRight: "1px solid rgb(0 0 0 / 26%)",
												borderBottom: "1px solid rgb(0 0 0 / 26%)",
												textAlign: "center",
												whiteSpace: "nowrap",
											}}
										>{y.toUpperCase()}</th>
									)
								})
								}
							</tr>

						</thead>
						<tbody>
							{bulkFiles
								.filter((x) => x.length !== 0)
								.map((userdata, index) => {
									if (userdata) {
										return (
											<tr>
												{userdata.map((y) => {
													// console.log("workimg correvtly ",y)
													if (y === undefined) {
														return (
															<td
																style={{
																	minWidth: "150px",
																	borderTop: "1px solid rgb(0 0 0 / 26%)",
																	borderLeft: "none",
																	borderRight: "1px solid rgb(0 0 0 / 26%)",
																	borderBottom: "1px solid rgb(0 0 0 / 26%)",
																	textAlign: "center",
																	whiteSpace: "nowrap",
																}}
															>
																{""}
															</td>
														);
													}
													return (
														<td
															style={{
																minWidth: "150px",
																borderTop: "1px solid rgb(0 0 0 / 26%)",
																borderLeft: "none",
																borderRight: "1px solid rgb(0 0 0 / 26%)",
																borderBottom: "1px solid rgb(0 0 0 / 26%)",
																textAlign: "center",
																whiteSpace: "nowrap",
															}}
														>
															{y}
														</td>
													);
												})}
											</tr>
										);
									} else {
										return null;
									}
								})}
						</tbody>
					</Table>
				</div>
			)}
			{bulkFiles.length === 0 && Header.length === 0 && (
				<div className="p-4" align="center">
					<h1
						style={{
							fontWeight: "bold",
						}}
					>
						Add User
					</h1>
					<p className="p-2" style={{ color: sucess ? "green" : "red" }}>
						{error}
					</p>
					{/* <Box className="pe-4 d-flex justify-content-end align-items-center">
						<ButtonMui variant="contained" component="label">
							Upload Bulk Files
							<input
								type={"file"}
								hidden
								onChange={(e) => {
									setFile(e.target.files[0]);
									setUserCreateType("STUDENT")
									setIsUploading((pre) => {
										return {
											...pre,
											pending: false,
											finished: false
										}
									})
								}}
							/>
						</ButtonMui>
					</Box> */}

					<Form className="row" align="left">
						<Form.Group className="mt-2 mb-3 col-12" controlId="userType">
							<Form.Label>User Type</Form.Label>
							<Form.Select
								aria-label="User Type"
								value={userCreateType}
								onChange={(e) => {
									setUserCreateType(e.target.value);
									setUserCreateDesignation(e.target.value);
								}}
							>
								{userTypeList.map((userType, idx) => {
									if (idx > currentUserTypeIndex) {
										return <option value={userType[0]}>{userType[1]}</option>;
									}
								})}
							</Form.Select>
						</Form.Group>
						<h5 className="mt-2" style={{ fontWeight: "bold" }} align="left">
							Basic Details
						</h5>
						<hr className="solid" style={{ marginTop: "1px" }} />
						{/* Name */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="name"
						>
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								value={userCreateName}
								onChange={(e) => {
									setUserCreateName(e.target.value);
								}}
							/>
						</Form.Group>
						{/* Designation */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="designation"
						>
							<Form.Label>Designation</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter designation"
								value={userCreateDesignation}
								onChange={(e) => {
									setUserCreateDesignation(e.target.value);
								}}
							/>
						</Form.Group>
						{/* Email */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="email"
						>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter email"
								value={userCreateEmail}
								onChange={(e) => {
									setUserCreateEmail(e.target.value);
								}}
								autoComplete='off'
							/>
						</Form.Group>
						{/* Phone */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="phone"
						>
							<Form.Label>Phone</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter phone"
								value={userCreatePhone}
								onChange={(e) => {
									setUserCreatePhone(e.target.value);
								}}
								autoComplete='off'
							/>
						</Form.Group>
						{/* Password */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="password"
						>
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Enter password"
								value={userCreatePassword}
								onChange={(e) => {
									setUserCreatePassword(e.target.value);
								}}
								autoComplete='new-password'
							/>
						</Form.Group>
						{/* Confirm Password */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="confirmPassword"
						>
							<Form.Label>Confirm Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Confirm password"
								value={userCreateConfirmPassword}
								onChange={(e) => {
									setUserCreateConfirmPassword(e.target.value);
								}}
							/>
						</Form.Group>
						{/* Address */}
						<Form.Group className="mb-3 col-12" controlId="address">
							<Form.Label>Address</Form.Label>
							<Form.Control
								as="textarea"
								placeholder="Enter address"
								value={userCreateAddress}
								onChange={(e) => {
									setUserCreateAddress(e.target.value);
								}}
							/>
						</Form.Group>
						{/* DOB */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="dob"
						>
							<Form.Label>Date of Birth</Form.Label>
							<Form.Control
								type="date"
								placeholder="Enter date of birth"
								value={userCreateDob}
								onChange={(e) => {
									setUserCreateDob(e.target.value);
								}}
							/>
						</Form.Group>
						{/* Gender */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="gender"
						>
							<Form.Label>Gender</Form.Label>
							<Form.Select
								value={userCreateGender}
								onChange={(e) => setUserCreateGender(e.target.value)}
							>
								<option value="">Select Gender</option>
								<option value="Male">Male</option>
								<option value="Female">Female</option>
								<option value="Other">Other</option>
							</Form.Select>
						</Form.Group>
						{/* Religion */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="religion"
						>
							<Form.Label>Religion</Form.Label>
							<Form.Select
								value={userCreateReligion}
								onChange={(e) => setUserCreateReligion(e.target.value)}
							>
								<option value="">Select Religion</option>
								<option value="Hindu">Hindu</option>
								<option value="Muslim">Muslim</option>
								<option value="Christian">Christian</option>
								<option value="Sikh">Sikh</option>
								<option value="Buddhist">Buddhist</option>
								<option value="Jain">Jain</option>
								<option value="Parsi">Parsi</option>
								<option value="Jewish">Jewish</option>
								<option value="Bahai">Bahai</option>
								<option value="Other">Other</option>
							</Form.Select>
						</Form.Group>
						{/* Caste */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="caste"
						>
							<Form.Label>Caste</Form.Label>
							<Form.Select
								value={userCreateCaste}
								onChange={(e) => setUserCreateCaste(e.target.value)}
							>
								<option value="">Select Caste</option>
								<option value="General">General</option>
								<option value="OBC">OBC</option>
								<option value="SC">SC</option>
								<option value="ST">ST</option>
								<option value="Other">Other</option>
							</Form.Select>
						</Form.Group>
						{/* Mother TOngue */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="motherTongue"
						>
							<Form.Label>Mother Tongue</Form.Label>
							<Form.Select
								value={userCreateMotherTongue}
								onChange={(e) => setUserCreateMotherTongue(e.target.value)}
							>
								<option value="">Select Mother Tongue</option>
								<option value="Hindi">Hindi</option>
								<option value="English">English</option>
								<option value="Odia">Odia</option>
								<option value="Other">Other</option>
							</Form.Select>
						</Form.Group>
						{/* Disability */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="disability"
						>
							<Form.Label>Disability</Form.Label>
							<Form.Select
								value={userCreateDisability}
								onChange={(e) => setUserCreateDisability(e.target.value)}
							>
								<option value="">Select Disability</option>
								<option value="Yes">Yes</option>
								<option value="No">No</option>
							</Form.Select>
						</Form.Group>
						<h5 className="mt-2" style={{ fontWeight: "bold" }} align="left">
							Type Specific Details
						</h5>
						<hr className="solid" style={{ marginTop: "1px" }} />
						{/* School, Class, AdmissionNumber, FatherName, MotherName, ParentsAddress, Locality, BPL, StudentType, MediumOfInstruction, AadharNumber, EyeScreening, TeacherCatagory, ClassTaughtType, ClassTaught, SubjectTaught, AppointmentDate, PlusTwoStream, GraduationStream, PostGraduationStream, PhdSubject, ProfessionalQualification, MPhilStream */}
						{/* Admission Number */}
						{userCreateType === "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="admissionNumber"
							>
								<Form.Label>Admission Number</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Admission Number"
									value={userCreateAdmissionNumber}
									onChange={(e) =>
										setUserCreateAdmissionNumber(e.target.value)
									}
								/>
							</Form.Group>
						)}
						{/* Father Name */}
						{userCreateType === "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="fatherName"
							>
								<Form.Label>Father Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Father Name"
									value={userCreateFatherName}
									onChange={(e) => setUserCreateFatherName(e.target.value)}
								/>
							</Form.Group>
						)}
						{/* Mother Name */}
						{userCreateType === "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="motherName"
							>
								<Form.Label>Mother Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Mother Name"
									value={userCreateMotherName}
									onChange={(e) => setUserCreateMotherName(e.target.value)}
								/>
							</Form.Group>
						)}
						{/* Parents Address */}
						{userCreateType === "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="parentsAddress"
							>
								<Form.Label>Parents Address</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Parents Address"
									value={userCreateParentsAddress}
									onChange={(e) =>
										setUserCreateParentsAddress(e.target.value)
									}
								/>
							</Form.Group>
						)}
						{/* Locality */}
						{userCreateType === "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="locality"
							>
								<Form.Label>Locality</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Locality"
									value={userCreateLocality}
									onChange={(e) => setUserCreateLocality(e.target.value)}
								/>
							</Form.Group>
						)}
						{/* BPL */}
						{userCreateType === "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="bpl"
							>
								<Form.Label>Below Poverty Line</Form.Label>
								<Form.Select
									value={userCreateBPL}
									onChange={(e) => setUserCreateBPL(e.target.value)}
								>
									<option value="Yes">Yes</option>
									<option value="No">No</option>
								</Form.Select>
							</Form.Group>
						)}
						{/* Student Type */}
						{userCreateType === "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="studentType"
							>
								<Form.Label>Student Type</Form.Label>
								<Form.Select
									value={userCreateStudentType}
									onChange={(e) => setUserCreateStudentType(e.target.value)}
								>
									<option value="">Select Type</option>
									<option value="Day Boarder">Day Boarder</option>
									<option value="Hosteller">Hosteller</option>
								</Form.Select>
							</Form.Group>
						)}
						{/* Medium of Instruction */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="mediumOfInstruction"
						>
							<Form.Label>Medium of Instruction</Form.Label>
							<Form.Select
								value={userCreateMediumOfInstruction}
								onChange={(e) =>
									setUserCreateMediumOfInstruction(e.target.value)
								}
							>
								<option value="">Select Medium</option>
								<option value="English">English</option>
								<option value="Hindi">Hindi</option>
								<option value="Bengali">Odia</option>
								<option value="Other">Other</option>
							</Form.Select>
						</Form.Group>
						{/* Aadhar Number */}
						<Form.Group
							className="mb-3 col-12 col-sm-6 col-lg-4"
							controlId="aadharNumber"
						>
							<Form.Label>Aadhar Number</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter Aadhar Number"
								value={userCreateAadharNumber}
								onChange={(e) => setUserCreateAadharNumber(e.target.value)}
							/>
						</Form.Group>
						{/* PlusTwo Stream */}
						{userCreateType !== "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="plusTwoStream"
							>
								<Form.Label>PlusTwo Stream</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter PlusTwo Stream"
									value={userCreatePlusTwoStream}
									onChange={(e) => setUserCreatePlusTwoStream(e.target.value)}
								/>
							</Form.Group>
						)}
						{/* Graduation Stream */}
						{userCreateType !== "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="graduationStream"
							>
								<Form.Label>Graduation Stream</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Graduation Stream"
									value={userCreateGraduationStream}
									onChange={(e) =>
										setUserCreateGraduationStream(e.target.value)
									}
								/>
							</Form.Group>
						)}
						{/* Post Graduation Stream */}
						{userCreateType !== "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="postGraduationStream"
							>
								<Form.Label>Post Graduation Stream</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Post Graduation Stream"
									value={userCreatePostGraduationStream}
									onChange={(e) =>
										setUserCreatePostGraduationStream(e.target.value)
									}
								/>
							</Form.Group>
						)}
						{/* PHd Subject */}
						{userCreateType !== "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="phdSubject"
							>
								<Form.Label>PHd Subject</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter PHd Subject"
									value={userCreatePhdSubject}
									onChange={(e) => setUserCreatePhdSubject(e.target.value)}
								/>
							</Form.Group>
						)}
						{/* MPhill */}
						{userCreateType !== "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="mPhill"
							>
								<Form.Label>MPhill Stream</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter MPhill Stream"
									value={userCreateMPhilStream}
									onChange={(e) => setUserCreateMPhilStream(e.target.value)}
								/>
							</Form.Group>
						)}
						{/* Professional Qualification */}
						{userCreateType !== "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="professionalQualification"
							>
								<Form.Label>Professional Qualification</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter Professional Qualification"
									value={userCreateProfessionalQualification}
									onChange={(e) =>
										setUserCreateProfessionalQualification(e.target.value)
									}
								/>
							</Form.Group>
						)}
						{/* Appointment Date */}
						{userCreateType !== "STUDENT" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="appointmentDate"
							>
								<Form.Label>Appointment Date</Form.Label>
								<Form.Control
									type="date"
									placeholder="Enter Appointment Date"
									value={userCreateAppointmentDate}
									onChange={(e) =>
										setUserCreateAppointmentDate(e.target.value)
									}
								/>
							</Form.Group>
						)}
						<h5 className="mt-2" style={{ fontWeight: "bold" }} align="left">
							Lifecycle Details
						</h5>
						<hr className="solid" style={{ marginTop: "1px" }} />
						{/* School */}
						{!userDetails?.schoolId && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="school"
							>
								<Form.Label>School</Form.Label>
								<Form.Select
									value={userCreateSchool}
									onChange={(e) => setUserCreateSchool(e.target.value)}
								>
									{schoolList?.map((school) => {
										return <option value={school._id}>{school.name}</option>;
									})}
								</Form.Select>
							</Form.Group>
						)}
						{/* Class Taught Type */}
						{userCreateType === "TEACHER" && (
							<Form.Group
								className="mb-3 col-12 col-sm-6 col-lg-4"
								controlId="classTaughtType"
							>
								<Form.Label>Class Taught Type</Form.Label>
								<Form.Select
									value={userCreateClassTaughtType}
									onChange={(e) =>
										setUserCreateClassTaughtType(e.target.value)
									}
								>
									{classTaughtTypeList?.map((item, index) => {
										return (
											<option key={item} value={item}>
												{item}
											</option>
										);
									})}
								</Form.Select>
							</Form.Group>
						)}
						{/* Class */}
						{userCreateType === "TEACHER" ? (
							<MultiSelectComp
								classes="mb-3 col-12 col-sm-6 col-lg-4"
								options={classList}
								groupBy={"class_name"}
								label={"Class"}
								selectedVal={userCreateClassTaught}
								setSelected={setUserCreateClassTaught}
								style={{ marginTop: "0" }}
							/>
						) : (
							userCreateType === "STUDENT" && (
								<Form.Group
									className="mb-3 col-12 col-sm-6 col-lg-4"
									controlId="class"
								>
									<Form.Label>Class</Form.Label>
									<Form.Select
										value={userCreateClass}
										onChange={(e) => setUserCreateClass(e.target.value)}
									>
										{classList?.map((classs) => {
											return (
												<option value={classs._id}>
													{classs.class_name_section}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
							)
						)}
						{/* Subject Taught */}
						{(userCreateType === "STUDENT" ||
							userCreateType === "TEACHER") && (
								<Form.Group
									className="mb-3 col-12 col-sm-6 col-lg-4"
									controlId="class"
								>
									<Form.Label>Subjects</Form.Label>
									<Multiselect
										showArrow
										options={subjectList}
										displayValue="name"
										showCheckbox={true}
										selectedValues={userCreateSubjectTaught}
										onSelect={(selectedList, selectedItem) => {
											setUserCreateSubjectTaught(selectedList);
										}}
										onRemove={(selectedList, selectedItem) => {
											setUserCreateSubjectTaught(selectedList);
										}}
										style={{
											marginTop: "0",
										}}
									/>
								</Form.Group>
							)}
					</Form>
					<Button
						size="lg"
						className="btn btn-default"
						align="center"
						type="submit"
						style={{ width: "100%" }}
						onClick={handleUserCreate}
					>
						Add User
					</Button>
				</div>
			)}
			{/* <Modal show={check} onHide={() => setCheck(false)}>
				<Modal.Body>
					<p className="p-2">
						{error}
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setCheck(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal> */}
			<Modal show={check} onHide={() => setCheck(false)} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>{error}</Modal.Title>
				</Modal.Header>
			</Modal>
		</div>
	);
}

export default AddUser;