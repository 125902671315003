import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from 'react-bootstrap';
import Api from '../../Api/axios';
import { Stack } from '@mui/material';
import { Modal } from 'react-bootstrap';

const EditSchoolVariables = () => {
    const urlParams = useParams();
    const [selectedSchool, setSelectedSchool] = useState({});
    const [schoolAvailable, setSchoolAvailable] = useState(false);
    const [schoolName, setSchoolName] = useState('');
    const [schoolAddress, setSchoolAddress] = useState('');
    const [schoolLogo, setSchoolLogo] = useState('');
    const [schoolPhoneNo, setSchoolPhoneNo] = useState('');
    const [schoolEmail, setSchoolEmail] = useState('');
    const [schoolWebsite, setSchoolWebsite] = useState('');
    const [schoolSession, setSchoolSession] = useState('');
    const [schoolStartingClass, setSchoolStartingClass] = useState('');
    const [schoolEndingClass, setSchoolEndingClass] = useState('');
    const [schoolMaxSection, setSchoolMaxSection] = useState('');
    const [schoolMaxStudents, setSchoolMaxStudents] = useState('');
    const [schoolSubjects, setSchoolSubjects] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    useEffect(() => {
        Api.get('/school-entity/school/').then((response) => {
            response.data.map((school) => {
                console.log(school);
                if (school._id === urlParams.schoolId) {
                    setSelectedSchool(school);
                    setSchoolName(school.name);
                    setSchoolAddress(school.address);
                    setSchoolLogo(school.logo);
                    setSchoolPhoneNo(school.Contact_number);
                    setSchoolEmail(school.email);
                    setSchoolWebsite(school.website);
                    setSchoolSession(school.current_session);
                    setSchoolStartingClass(school.class_range_start);
                    setSchoolEndingClass(school.class_range_end);
                    setSchoolMaxSection(school.max_number_of_sections);
                    setSchoolMaxStudents(school.students_per_section);
                    setSchoolSubjects(school.subjects);
                    setSchoolAvailable(true);
                    Api.get('/session-entity?district_id=' + school.district_id).then((response) => {
                        setSessionList(response.data);
                    });
                    Api.get('/preconfig-entites/subjects').then((response) => {
                        console.log(response.data.filter((subject) => subject.name != null));
                        setSubjectList(response.data.filter((subject) => subject.name != null));
                    });
                }
            });
        });
    }, []);
    const [sessionError, setSessionError] = useState(false);
    const [classRangeError, setClassRangeError] = useState(false);
    const [sectionRangeError, setSectionRangeError] = useState(false);
    const [maxStudentsError, setMaxStudentsError] = useState(false);
    const [subjectsError, setSubjectsError] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const validateStartingClass = (e) => {
        setSchoolStartingClass(e.target.value);
        if (e.target.value < 1 || e.target.value > 12 || isNaN(e.target.value) || !schoolEndingClass || e.target.value > schoolEndingClass) {
            setClassRangeError(true);
        } else {
            setClassRangeError(false);
        }
    }
    const validateEndingClass = (e) => {
        setSchoolEndingClass(e.target.value);
        console.log(schoolStartingClass, e.target.value, schoolStartingClass > e.target.value);
        if (e.target.value < 1 || e.target.value > 12 || isNaN(e.target.value) || !schoolStartingClass || schoolStartingClass > e.target.value) {
            setClassRangeError(true);
        } else {
            setClassRangeError(false);
        }
    }
    const validateSection = (e) => {
        if (e.target.value < 1) {
            setSectionRangeError(true);
        } else {
            setSectionRangeError(false);
        }
        setSchoolMaxSection(e.target.value);
    }
    const validateMaxStudents = (e) => {
        if (e.target.value < 1) {
            setMaxStudentsError(true);
        } else {
            setMaxStudentsError(false);
        }
        setSchoolMaxStudents(e.target.value);
    }
    const validate = () => {
        let valid = true;
        if (schoolSession === '') {
            setSessionError(true);
            valid = false;
        }
        if (schoolStartingClass === '' || schoolEndingClass === '' || schoolStartingClass > schoolEndingClass) {
            setClassRangeError(true);
            valid = false;
        }
        if (schoolMaxSection === '' || schoolMaxSection < 1) {
            setSectionRangeError(true);
            valid = false;
        }
        if (schoolMaxStudents === '' || schoolMaxStudents < 1) {
            setMaxStudentsError(true);
            valid = false;
        }
        console.log(schoolSubjects);
        if (schoolSubjects.length === 0) {
            setSubjectsError(true);
            valid = false;
            alert('Please select atleast one subject');
        }
        if (!valid || sessionError || classRangeError || sectionRangeError || maxStudentsError || subjectsError) {
            console.log('error', sessionError, classRangeError, sectionRangeError, maxStudentsError, subjectsError);
            return false;
        } else {
            const data = {
                'school_id': urlParams.schoolId,
                'class_range_start': schoolStartingClass,
                'class_range_end': schoolEndingClass,
                'max_number_of_sections': schoolMaxSection,
                'students_per_section': schoolMaxStudents,
                'session_id': schoolSession,
                "subject_ids": [
                    ...schoolSubjects.map((subject) => subject._id)
                ]
            }
            Api.put("/school-entity/update", data).then((response) => {
                setUpdateSuccess(true);
            });
        }
    }

    return (
        <div className='mb-4' align="center" style={{ overflowY: "scroll" }}>
            {schoolAvailable ? <div className="shadow-lg p-4 col-11 col-lg-10 mt-4 mb-4" style={{ borderRadius: 10, backgroundColor: "#fff" }}>
                <h1 className='mb-4'> Edit School </h1>
                {
                    updateSuccess ?
                        <Modal show={updateSuccess} onHide={() => setUpdateSuccess(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Success</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Successfully updated school details</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setUpdateSuccess(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        : null
                }
                <div className="row">
                    <div className="col-md-auto col-11 ms-4 me-2 mt-2 mb-2">
                        <img src={schoolLogo} alt="School Logo" width="130" height="130" />
                    </div>
                    <div className="col-auto ms-2 mt-2 mb-2" align="left">
                        <h3>{schoolName}</h3>
                        <p>Phone No: <a target="_blank" href={"tel:+91" + schoolPhoneNo} style={{ textDecoration: "none" }}>+91 {schoolPhoneNo}</a><br></br>E-Mail: <a target="_blank" href={"mailto:" + schoolEmail} style={{ textDecoration: "none" }}>{schoolEmail}</a> <br></br>Website: <a target="_blank" href={schoolWebsite ? schoolWebsite.indexOf("://") == -1 ? "https://" + schoolWebsite : schoolWebsite : ""} style={{ textDecoration: "none" }}>{schoolWebsite} </a> <br></br>Address: {schoolAddress}</p>
                    </div>
                </div>
                <Form.Group controlId="currentSession" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Current Session</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            as="select"
                            isInvalid={sessionError}
                            value={schoolSession}
                            onChange={(e) => setSchoolSession(e.target.value)}

                        >
                            {sessionList.map((session) => {
                                return <option value={session._id}>{session.name}</option>
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please select a session.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="classRange" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Class Range</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="number"
                            placeholder="Starting Class"
                            isInvalid={classRangeError}
                            value={schoolStartingClass}
                            onChange={(e) => validateStartingClass(e)}

                        />
                        <Form.Control
                            type="number"
                            placeholder="Ending Class"
                            isInvalid={classRangeError}
                            value={schoolEndingClass}
                            onChange={(e) => validateEndingClass(e)}

                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid class range.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="sectionRange" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Maximum No. of Sections</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="number"
                            placeholder="Maximum No. of Sections"
                            isInvalid={sectionRangeError}
                            value={schoolMaxSection}
                            onChange={(e) => validateSection(e)}

                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid section numbers.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="maxStudents" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Maximum No. of Students per Section</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="number"
                            placeholder="Enter Maximum No. of Students per Section"
                            isInvalid={maxStudentsError}
                            value={schoolMaxStudents}
                            onChange={(e) => validateMaxStudents(e)}

                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid number.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <Form.Group controlId="subjects" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Subjects</Form.Label>
                    <Multiselect
                        options={subjectList}
                        displayValue="name"
                        placeholder="Select Subjects"
                        style={{ minWidth: "12rem" }}
                        selectedValues={schoolSubjects}
                        onSelect={(selectedList, selectedItem) => { setSchoolSubjects(selectedList); }}
                        onRemove={(selectedList, removedItem) => { setSchoolSubjects(selectedList); }}
                    />
                </Form.Group>
                <Button variant="secondary" className='mb-4' onClick={validate}>
                    Save Changes
                </Button>
            </div> :
                <div className="shadow-lg p-4 col-11 col-lg-10 mt-4" style={{ borderRadius: 10 }} align="center">
                    <img src={`${process.env.PUBLIC_URL}/Images/failure.png`} style={{ width: '100px' }} alt="" />
                    <h3 className="m-4">Sorry, we cannot find the requested school.</h3>
                    <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                        <Button variant="outline-secondary" size="lg" onClick={(e) => { window.location.href = window.location.origin; }} >
                            Go to Dashboard
                        </Button>
                    </Stack>
                </div>}
        </div>


    );
}

export default EditSchoolVariables;