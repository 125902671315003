import React from 'react'
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import DistrictLevelLMS from './DistrictLevelLMS';
import SchoolLevelLMS from './SchoolLevelLMS';
import ClassLevelLMS from './ClassLevelLMS';
import UserLevelLMS from './UserLevelLMS';

const LMS_Tracker = () => {
  const [{ userDetails }, dispatch] = useDataLayerValue();

  return (
    <div>
      {userDetails.userType==="DISTRICT"&&
          <DistrictLevelLMS/>
      }
      {userDetails.userType==="SCHOOL_ADMIN"&&
          <SchoolLevelLMS/>
      }
      {userDetails.userType==="TEACHER"&&
         <SchoolLevelLMS/>
      }
      {userDetails.userType==="STUDENT"&& //as of now api is authroized for student level
         <UserLevelLMS/>
      }
    </div>
  )
}

export default LMS_Tracker