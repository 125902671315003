import React, { useEffect, useState } from 'react'
import Api from '../../../../Api/axios'
import AssessmentTable from './components/AssessmentTable'

const SubmittedAssessment = ({search,filterAssessment}) => {
  const [SubmittedAssessment,setSubmittedAssessment]=useState([])
  useEffect(()=>{ 
    Api.get("/assessment/submission/submitted").then((res)=>{
      console.log("/submitted-submitted ==>",res.data)
      setSubmittedAssessment(()=>res.data)
    })
  },[])

  return (
    <div>
    {SubmittedAssessment.length!==0&&
          <AssessmentTable  showAll={true}  isSubmitted={true} datas={SubmittedAssessment} search={search} filterAssessment={filterAssessment}/>
    }
  </div>

    )
}

export default SubmittedAssessment