import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Col, Row, InputGroup, FormControl, Stack } from "react-bootstrap";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { Multiselect } from 'multiselect-react-dropdown';
import Api from "../../Api/axios";

const CreateEvent = ({ scheduler, updateCount, setUpdateCount }) => {

    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [userTypes, setUserTypes] = useState([
        {
            value: "DISTRICT",
            text: "District",
        },
        {
            value: "SCHOOL_ADMIN",
            text: "School Admin",
        },
        {
            value: "TEACHER",
            text: "Teacher",
        },
        {
            value: "PATENT",
            text: "Parent",
        },
        {
            value: "STUDENT",
            text: "Student",
        }
    ]);
    const [eventTitle, setEventTitle] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventStartTime, setEventStartTime] = useState(
        new Date(new Date(scheduler?.state?.start?.value).getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000).toISOString().split("Z")[0]
    );
    const [eventEndTime, setEventEndTime] = useState(
        new Date(new Date(scheduler?.state?.end?.value).getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000).toISOString().split("Z")[0]
    );
    const [eventType, setEventType] = useState("GENRAL_EVENT");
    const [eventRepeat, setEventRepeat] = useState('ONETIME');
    const [eventRepeatEndDate, setEventRepeatEndDate] = useState(
        new Date(new Date(scheduler?.state?.end?.value).getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000).toISOString().split("T")[0]
    );
    const [recipientUserTypeList, setRecipientUserTypeList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [classList, setClassList] = useState({});
    const [classListOfAllSchools, setClassListOfAllSchools] = useState([]);
    const [classListToShow, setClassListToShow] = useState([]);
    const [recipientSchoolList, setRecipientSchoolList] = useState([]);
    const [recipientClassList, setRecipientClassList] = useState([]);
    const [courseListByYouthIndia, setCourseListByYouthIndia] = useState([]);
    const [finalCourseList, setFinalCourseList] = useState([]);
    const [recipientCourseList, setRecipientCourseList] = useState([]);
    const [textMessageToSend, setTextMessageToSend] = useState("");
    const [location, setLocation] = useState("");
    const [meetingLink, setMeetingLink] = useState("");
    const [generateMeetingLinkAutomatically, setGenerateMeetingLinkAutomatically] = useState(false);
    const [courseWiseContentList, setCourseWiseContentList] = useState({});

    useEffect(() => {
        setUserTypes(userTypes?.slice(userTypes?.findIndex(userType => userType?.value === userDetails?.userType) + 1));
    }, [userDetails]);
    useEffect(() => {
        Api.get('/school-entity/school').then((response) => {
            if (userDetails?.userType === "DISTRICT") {
                response.data.sort((a, b) => a.name.localeCompare(b.name));
                setSchoolList(response.data);
            } else {
                setSchoolList([response.data]);
                setRecipientSchoolList([response.data]);
            }
        });
    }, [userDetails]);
    useEffect(async () => {
        let tempClassList = {};
        var allClassList = [];
        console.log(recipientSchoolList);
        if (recipientSchoolList?.length > 0) {
            await Promise.all(
                recipientSchoolList.map((school) => {
                    return Api.get(`school-entity/all-classes?school_id=${school?._id}`).then((response) => {
                        tempClassList[school?._id] = response.data
                        allClassList = [...allClassList, ...response.data];
                    });
                })
            );
        } else {
            await Promise.all(
                schoolList.map((school) => {
                    return Api.get(`school-entity/all-classes?school_id=${school?._id}`).then((response) => {
                        tempClassList[school?._id] = response.data
                        allClassList = [...allClassList, ...response.data];
                    });
                })
            );
        }
        setClassList(tempClassList);
        setClassListOfAllSchools(allClassList);
    }, [recipientSchoolList, schoolList]);
    useEffect(async () => {
        var tempClassListToShow = [];
        if (Object.keys(classList)?.length === 0) {
            tempClassListToShow = [];
        } else {
            let keys = Object.keys(classList);
            tempClassListToShow = classList[keys[0]]?.map((classObj) => classObj.class_name_section);
            for await (const key of keys) {
                tempClassListToShow = await Promise.all(tempClassListToShow?.filter((classObj) => classList[key]?.map((classObj) => classObj.class_name_section).includes(classObj)));
            }
        }
        tempClassListToShow = tempClassListToShow?.map((classObj) => {
            return {
                value: classObj,
                text: classObj,
            }
        });
        tempClassListToShow?.sort((a, b) => a.text.localeCompare(b.text));
        setClassListToShow(tempClassListToShow);
    }, [classList]);
    useEffect(async () => {
        if (eventType === "5T-PERIOD") {
            scheduler.loading(true);
            var tempCourseList = [];
            await Promise.all([Api.get("/course/get-yie-courses").then(async (res) => {
                return await Promise.all([res.data?.forEach(async (course) => {
                    return Api.get('/course/get-course-content?course_id=' + course?._id).then((response) => {
                        course.content = response.data?.map((content) => content?.contents?.map((contents) => {
                            return {
                                "chapter_id": content?._id,
                                "chapter_name": content?.chapter_name,
                                ...contents
                            }
                        })).flat();
                        tempCourseList.push(course);
                    });
                })]);
            })]);
            setCourseListByYouthIndia(tempCourseList);
            scheduler.loading(false);
        }
    }, [eventType]);
    useEffect(() => {
        if (courseListByYouthIndia?.length > 0) {
            let tempCourseList = [];
            let tempClassList = [];
            if (recipientClassList?.length > 0) {
                tempClassList = recipientClassList?.map((className) => className.text.split(" ")[0])?.filter((value, index, self) => self.indexOf(value) === index);
            } else {
                tempClassList = classListToShow?.map((classObj) => classObj.text.split(" ")[0])?.filter((value, index, self) => self.indexOf(value) === index);
            }
            console.log(tempClassList);
            tempCourseList = courseListByYouthIndia?.filter((course) => {
                var courseClassList = course?.class_name;
                if (tempClassList?.length > 0) {
                    if (!Array.isArray(course?.class_name)) {
                        courseClassList = [course?.class_name];
                    }
                    return tempClassList?.every((className) => courseClassList?.includes(className));
                } else {
                    return false;
                }
            });
            setFinalCourseList(tempCourseList);
        }
    }, [recipientClassList, courseListByYouthIndia, eventType]);
    useEffect(() => {
        console.log(scheduler);
    }, [scheduler]);

    const createEvent = async () => {
        if (!eventStartTime) {
            alert("Please select event start time");
        }
        else if (!eventEndTime) {
            alert("Please select event end time");
        } else if (!eventTitle) {
            alert("Please enter event title");
        } else if (!eventRepeatEndDate) {
            alert("Please select event repeat end date");
        }
        scheduler.loading(true);
        const validMonths = [
            'JANUARY',
            'FEBRUARY',
            'MARCH',
            'APRIL',
            'MAY',
            'JUNE',
            'JULY',
            'AUGUST',
            'SEPTEMBER',
            'OCTOBER',
            'NOVEMBER',
            'DECEMBER'
        ]
        let classValues = recipientClassList?.map((classObj) => classObj?.value);
        if (recipientClassList.length === 0) {
            classValues = classListToShow?.map((classObj) => classObj?.value);
        }
        console.log(classValues);
        let eventRepeatEnd = new Date(eventRepeatEndDate);
        eventRepeatEnd.setHours(23, 59, 59, 999);
        var tempCourseList = recipientCourseList?.length > 0 ? recipientCourseList : finalCourseList;
        let content_ids_array = [];
        if (eventType === "5T-PERIOD") {
            tempCourseList?.forEach((course) => {
                if (course?.selectedContent?.length > 0) {
                    content_ids_array.push(course?.selectedContent?.map((content) => content?._id));
                } else {
                    content_ids_array.push(course?.content?.map((content) => content?._id));
                }
            });
        }
        content_ids_array = content_ids_array.flat();
        var postData = {
            "month": validMonths[new Date(eventStartTime)?.getMonth()],
            "day": new Date(eventStartTime)?.getDate(),
            "year": new Date(eventStartTime)?.getFullYear(),
            "event_name": eventTitle,
            "description": eventDescription,
            "event_start": new Date(eventStartTime)?.getTime(),
            "event_end": new Date(eventEndTime)?.getTime(),
            "for_class": recipientSchoolList?.length > 0 ? classListOfAllSchools?.filter((classObj) => classValues.includes(classObj?.class_name_section))?.map((classObj) => classObj?._id) : classListOfAllSchools?.map((classObj) => classObj?._id),
            "meet_link": meetingLink,
            "auto_generates_meet_link": generateMeetingLinkAutomatically,
            "recepient_user_type": recipientUserTypeList?.length > 0 ? recipientUserTypeList?.map((userType) => userType?.value) : userTypes?.map((userType) => userType?.value),
            "school": recipientSchoolList?.length > 0 ? recipientSchoolList?.map((school) => school?.name) : schoolList?.map((school) => school?._id),
            "frequency": eventRepeat,
            "freq_end_date": eventRepeatEnd?.getTime(),
            "event_type": eventType,
            "course_ids": eventType === "5T-PERIOD" ? tempCourseList?.map((course) => course?._id) : [],
            "content_ids": eventType === "5T-PERIOD" ? content_ids_array : [],
            "holiday_message": textMessageToSend,
            "offline_class_location": location,
            "frontend_data": "ok"
        }
        if (postData?.event_type === "5T-PERIOD" && postData?.course_ids?.length === 0) {
            alert("Please select at least one course, if course list not available select atleast one class.");
            scheduler.loading(false);
            return;
        }
        if (postData?.event_type === "5T-PERIOD" && postData?.content_ids?.length === 0) {
            alert("Please select at least one course, if course list not available select atleast one class.");
            scheduler.loading(false);
            return;
        }
        if (postData?.event_type === "HOLIDAY" && postData?.holiday_message?.length === 0) {
            alert("Please enter holiday message.");
            scheduler.loading(false);
            return;
        }
        Api.post("calendar", postData).then((res) => {
            scheduler.close();
        }).catch((err) => {
            alert("Error while creating event. Try Again Later.");
        }).finally(() => {
            setUpdateCount(updateCount + 1);
            scheduler.loading(false);
        });
    }

    return (
        <div
            style={{
                backgroundColor: "white",
                borderRadius: "5px",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
                minHeight: "90vh",
                maxHeight: "90vh",
                minWidth: "90vw",
                maxWidth: "90vw",
                overflow: "hidden",
            }}
        >
            <div
                style={{
                    maxHeight: "90px",
                    minHeight: "90px",
                }}
            >
                <h1
                    style={{
                        paddingTop: "30px",
                        textAlign: "center",
                        fontWeight: "bold",
                    }}
                >
                    Create Event
                </h1>
            </div>
            <div
                style={{
                    padding: "10px",
                    height: "calc(90vh - 180px)",
                    maxHeight: "calc(90vh - 150px)",
                    minHeight: "calc(90vh - 150px)",
                    overflowY: "scroll",
                    overflowX: "hidden",
                }}
            >
                <Form>
                    <Form.Group
                        controlId="eventTitle"
                        style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    >
                        <Form.Label>
                            Event Title
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={eventTitle}
                            onChange={(e) => setEventTitle(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Form.Group
                        controlId="eventDescription"
                        style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    >
                        <Form.Label>
                            Event Description
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            value={eventDescription}
                            onChange={(e) => setEventDescription(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Stack
                        direction="horizontal"
                        gap={2}
                    >
                        <Form.Group
                            controlId="eventStartTIme"
                            style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                width: "50%",
                            }}
                        >
                            <Form.Label>
                                Event Start Time
                            </Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="start"
                                value={eventStartTime}
                                onChange={(e) => setEventStartTime(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group
                            controlId="eventEndTime"
                            style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                width: "50%",
                            }}
                        >
                            <Form.Label>
                                Event End Time
                            </Form.Label>
                            <Form.Control
                                type="datetime-local"
                                name="end"
                                value={eventEndTime}
                                onChange={(e) => setEventEndTime(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Stack>
                    <Form.Group
                        controlId="eventType"
                        style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    >
                        <Form.Label>
                            Event Type
                        </Form.Label>
                        <Form.Control
                            as="select"
                            name="type"
                            value={eventType}
                            onChange={(e) => { setEventType(e.target.value) }}
                        >
                            <option value="GENRAL_EVENT">General Event</option>
                            <option value="5T-PERIOD">5T Period</option>
                            <option value="HOLIDAY">Holiday</option>
                            <option value="OFFLINE_CLASS">Offline Class</option>
                            <option value="MEETING">Online Class/Meeting</option>
                            {/* <option value="assesment">Assesment</option>
                            <option value="meeting">Meeting</option>
                            <option value="reminder">Reminder</option>
                            <option value="task">Task</option> */}
                        </Form.Control>
                    </Form.Group>
                    <Stack
                        direction="horizontal"
                        gap={2}
                    >
                        <Form.Group
                            controlId="eventRepeat"
                            style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                width: "50%",
                            }}
                        >
                            <Form.Label>
                                Repeat
                            </Form.Label>
                            <Form.Control
                                as="select"
                                name="repeat"
                                value={eventRepeat}
                                onChange={(e) => { setEventRepeat(e.target.value) }}
                            >
                                <option value='ONETIME'>None</option>
                                <option value='WEEKLY'>Weekly</option>
                                <option value='MONTHLY'>Monthly</option>
                                <option value='YEARLY'>Yearly</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group
                            controlId="eventRepeatEndDate"
                            style={{
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                width: "50%",
                            }}
                        >
                            <Form.Label>
                                Repeat End Date
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="repeatEndDate"
                                value={eventRepeatEndDate}
                                onChange={(e) => setEventRepeatEndDate(e.target.value)}
                            >
                            </Form.Control>
                        </Form.Group>
                    </Stack>
                    <Form.Group
                        controlId="eventUserType"
                        style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    >
                        <Form.Label>
                            Recipient User Types
                        </Form.Label>
                        <Multiselect
                            name="recipientUserType"
                            label="Recipient User Type"
                            placeholder="Recipient User Types"
                            options={userTypes}
                            selectedValues={recipientUserTypeList}
                            onSelect={(selectedList, selectedItem) => { setRecipientUserTypeList(selectedList) }}
                            onRemove={(selectedList, selectedItem) => { setRecipientUserTypeList(selectedList) }}
                            displayValue="text"
                            style={{
                                width: "100%",
                                height: "30px",
                            }}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="eventSchools"
                        style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    >
                        <Form.Label>
                            Recipient Schools
                        </Form.Label>
                        <Multiselect
                            name="recipientSchools"
                            label="Recipient Schools"
                            placeholder="Recipient Schools"
                            options={schoolList}
                            selectedValues={recipientSchoolList}
                            onSelect={(selectedList, selectedItem) => { setRecipientSchoolList(selectedList) }}
                            onRemove={(selectedList, selectedItem) => { setRecipientSchoolList(selectedList) }}
                            disable={schoolList.length === 1 || schoolList.length === 0}
                            displayValue="name"
                            style={{
                                width: "100%",
                                height: "30px",
                            }}
                        />
                    </Form.Group>
                    <Form.Group
                        controlId="eventClasses"
                        style={{
                            paddingTop: "10px",
                            paddingBottom: "10px",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                        }}
                    >
                        <Form.Label>
                            Recipient Classes
                        </Form.Label>
                        <Multiselect
                            name="recipientClasses"
                            label="Recipient Classes"
                            placeholder="Recipient Classes"
                            options={classListToShow}
                            displayValue="text"
                            selectedValues={recipientClassList}
                            onSelect={(selectedList, selectedItem) => { setRecipientClassList(selectedList) }}
                            onRemove={(selectedList, selectedItem) => { setRecipientClassList(selectedList) }}
                            style={{
                                width: "100%",
                                height: "30px",
                            }}
                        />
                    </Form.Group>
                    {
                        eventType === "5T-PERIOD" && <>
                            <Form.Group
                                controlId="eventCourseList"
                                style={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                }}
                            >
                                <Form.Label>
                                    Recipient Courses
                                </Form.Label>
                                <Multiselect
                                    name="recipientCourses"
                                    label="Recipient Courses"
                                    placeholder="Recipient Courses"
                                    options={finalCourseList}
                                    displayValue="subject_name"
                                    selectedValues={recipientCourseList}
                                    onSelect={(selectedList, selectedItem) => {
                                        if (recipientCourseList.length > 0) {
                                            setRecipientCourseList([...recipientCourseList, selectedItem])
                                        } else {
                                            setRecipientCourseList([selectedItem])
                                        }
                                    }}
                                    onRemove={(selectedList, selectedItem) => {
                                        setRecipientCourseList(recipientCourseList.filter(course => course._id !== selectedItem._id))
                                    }}
                                    style={{
                                        width: "100%",
                                        height: "30px",
                                    }}
                                />
                            </Form.Group>
                            {
                                recipientCourseList.length > 0 && recipientCourseList.map((course, index) => {
                                    return (
                                        <Form.Group
                                            controlId={`eventContentList-${course._id}`}
                                            style={{
                                                paddingTop: "10px",
                                                paddingBottom: "10px",
                                                paddingLeft: "5px",
                                                paddingRight: "5px",
                                            }}
                                        >
                                            <Form.Label>
                                                Recipient Contents for {course.subject_name}
                                            </Form.Label>
                                            <Multiselect
                                                name={`recipientContent-${course._id}`}
                                                label="Recipient Content"
                                                placeholder={`Recipient Content`}
                                                options={course.content}
                                                selectedValues={course["selectedContent"]}
                                                onSelect={(selectedList, selectedItem) => {
                                                    course["selectedContent"] = selectedList;
                                                }}
                                                onRemove={(selectedList, selectedItem) => {
                                                    course["selectedContent"] = selectedList;
                                                }}
                                                displayValue="title"
                                                style={{
                                                    width: "100%",
                                                    height: "30px",
                                                }}
                                                groupBy="chapter_name"
                                            />
                                        </Form.Group>
                                    )
                                })
                            }
                        </>
                    }
                    {
                        eventType === "HOLIDAY" && <>
                            <Form.Group
                                controlId="textMessageToSend"
                                style={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                }}
                            >
                                <Form.Label>
                                    Text Message to Send
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="textMessageToSend"
                                    placeholder="Text Message to Send"
                                    value={textMessageToSend}
                                    onChange={(e) => { setTextMessageToSend(e.target.value) }}
                                />
                            </Form.Group>
                        </>
                    }
                    {
                        eventType === "OFFLINE_CLASS" && <>
                            <Form.Group
                                controlId="eventLocation"
                                style={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                }}
                            >
                                <Form.Label>
                                    Location
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="location"
                                    placeholder="Location"
                                    value={location}
                                    onChange={(e) => { setLocation(e.target.value) }}
                                />
                            </Form.Group>
                        </>
                    }
                    {
                        eventType === "MEETING" && <>
                            <Form.Group
                                controlId="eventMeetingLink"
                                style={{
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                }}
                            >
                                <Form.Label>
                                    Meeting Link
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="meetingLink"
                                    placeholder="Meeting Link"
                                    value={meetingLink}
                                    onChange={(e) => { setMeetingLink(e.target.value) }}
                                />
                            </Form.Group>
                            <Form.Group
                                controlId="eventMeetingLink"
                                style={{
                                    paddingBottom: "10px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                }}
                            >
                                <Form.Check
                                    type="checkbox"
                                    label="Generate Meeting Link Automatically"
                                    checked={generateMeetingLinkAutomatically}
                                    onChange={(e) => { setGenerateMeetingLinkAutomatically(e.target.checked) }}
                                />
                            </Form.Group>
                        </>
                    }
                </Form>
            </div>
            <div
                style={{
                    height: "60px",
                    maxHeight: "60px",
                    minHeight: "60px",
                }}
            >
                <Stack
                    direction="horizontal"
                    gap={2}
                    style={{
                        paddingTop: "12px",
                        paddingBottom: "8px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                    }}
                >
                    <Button
                        variant="secondary"
                        type="submit"
                        style={{
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: "50%",
                        }}
                        onClick={() => {
                            scheduler.close();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        style={{
                            height: "40px",
                            maxHeight: "40px",
                            minHeight: "40px",
                            width: "50%",
                        }}
                        onClick={() => {
                            createEvent();
                        }}
                    >
                        {scheduler.edited ? "Updat Event" : "Create Event"}
                    </Button>
                </Stack>
            </div>
        </div >
    );
}

export default CreateEvent;