import React, { useState, useEffect } from "react";
import TextField from '@material-ui/core/TextField';
import { Box, MenuItem, Multiselect } from "@mui/material";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from "react-router";

const OverallSchoolAttendance = () => {
    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();
    let navigate = useNavigate();
    // timestamp 200 days ago
    // const [startDate, setStartDate] =
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 200)));
    // start date as 200 days before today as timestamp
    // const [startDate, setStartDate] = useState(+new Date();
    const [endDate, setEndDate] = useState(new Date());
    const [attendanceData, setAttendanceData] = useState([]);
    const [actualData, setActualData] = useState([]);
    const [schools, setSchools] = useState({});
    const [schoolFilter, setSchoolFilter] = useState('ALL');

    // useEffect(async () => {
    // }, [userDetails]);


    useEffect(async () => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let temp = {}
        if (userDetails.userType === 'DISTRICT') {
            await Api.get("school-entity/school").then((res) => {
                console.log(res.data, 'res');
                res.data.map((item) => {
                    temp[item._id] = item.name
                })
                setSchools(temp);
            })
        }

        let data = {
            start_date: new Date(new Date(new Date().toISOString().split("T")[0]).getTime() - (2 * 24 * 60 * 60 * 1000) - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)),
            end_date: new Date(new Date(new Date().toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1 )),
            attendance_filters: {
                school_ids: Object.keys(temp)
            }
        }
        await Api.post("attendance/school-attendance", data).then((res) => {
            console.log(res.data, 'res att');
            res.data = res.data.map((item) => {
                return {
                ...item,
                "total_duration_in_days" : item.total_duration_in_days + 1,
            }})
            setActualData(res.data);
            setAttendanceData(res.data);
        }).finally(() => {

            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        });


    }, [startDate, endDate, userDetails]);



    const handleSchoolFilter = (e) => {
        if (e.target.value === 'ALL') {
            setAttendanceData(actualData);
        } else {
            let temp = actualData.filter((item) => {
                return item.school_id === e.target.value
            })
            setAttendanceData(temp);
        }
    }



    return (
        <div className='ps-2 pe-2' style={{
            // width: '100%',
            minHeight: '94vh',
            backgroundColor: '#FFF',
            paddingBottom: '40px'
        }}
            align="center"
        >
            <h1 className="p-4" style={{ fontWeight: "bold" }}>Attendance</h1>
            <InputGroup className="p-2">
                <InputGroup.Text>From Date</InputGroup.Text>
                <Form.Control
                    type="date"
                    aria-label="From Date"
                    onChange={(e) => { setStartDate(e.target.value) }}
                />
            </InputGroup>
            <InputGroup className="p-2">
                <InputGroup.Text>To Date</InputGroup.Text>
                <Form.Control
                    type="date"
                    aria-label="To Date"
                    onChange={(e) => { setEndDate(e.target.value) }}
                />
            </InputGroup>

            {userDetails.userType === 'DISTRICT' ?
                <InputGroup className="p-2">
                    <InputGroup.Text>Select School</InputGroup.Text>
                    <Form.Control
                        as="select"
                        label="Select School"
                        variant="filled"
                        style={{ minWidth: 230 }}
                        value={schoolFilter}
                        onChange={handleSchoolFilter}
                    >
                        <option key="ALL" value="ALL" style={{ width: "100%" }}>
                            ALL
                        </option>

                        {
                            Object.keys(schools).map((sc, i) => (
                                <option key={sc} value={sc} style={{ width: "100%" }}>
                                    {schools[`${sc}`]}
                                </option>
                            ))
                        }

                    </Form.Control>
                </InputGroup>
                :
                null}
            {/* <div>
				<TextField
					select
					label="Notice By"
					variant="filled"
					style={{ minWidth: 230 }}
					className="m-2"
					value={ownerUserType}
					onChange={handleOwnerUserTypeChange}
				>
					<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
						ALL
					</MenuItem>
					{
						userTypes.map((userType, i) => {
							return (
								<MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
									{userType[1]}
								</MenuItem>
							)
						})
					}
				</TextField>
				{(userDetails?.userType === "TEACHER") &&
					<>
						<TextField
							select
							label="For Class"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={className}
							onChange={handleClassChange}
						>
							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
								ALL
							</MenuItem>
							{
								classDict.map((cl, i) => {
									return (
										<MenuItem key={cl._id} value={cl._class_name_section} style={{ width: "100%" }}>
											{cl.class_name_section}
										</MenuItem>
									)
								})
							}
						</TextField> */}
            {/* <TextField
							select
							label="Section"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={sectionName}
							onChange={(e) => setSectionName(e.target.value)}
						>
							{Object.keys(sectionDict).map((sectionVar, i) => {
								return (
									<MenuItem key={sectionVar} value={sectionVar} style={{ width: "100%" }}>
										{sectionVar}
									</MenuItem>
								)
							})
							}
						</TextField>
						<TextField
							select
							label="Subject"
							variant="filled"
							style={{ minWidth: 230 }}
							className="m-2"
							value={subjectName}
							onChange={handleSubjectChange}
						>
							<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
								ALL
							</MenuItem>
							{subjectDict.map((subjectDictVar, i) => {
								// console.log(classDict[className][sectionName])
								if (className == "ALL" || (classDict[className][sectionName] && classDict[className][sectionName][0] === subjectDictVar.classroom_id)) {
									return (
										<MenuItem key={subjectDictVar._id} value={subjectDictVar._id} style={{ width: "100%" }}>
											{subjectDictVar.name}
										</MenuItem>
									)
								}
							})
							}
						</TextField> */}
            {/* </>
				}
			</div> */}
            {/* <Button className="m-3" variant="secondary" onClick={filterChange} style={{ minWidth: "100px" }}>
				Filter
			</Button> */}
            <div className="m-4">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: "80px" }}>Sr. No.</th>
                            <th scope="col" style={{ minWidth: "80px" }}>From Date</th>
                            <th scope="col" style={{ minWidth: "80px" }}>To date</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Date</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Total Duration In Days</th>
                            <th scope="col" style={{ minWidth: "80px" }}>School Name</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Type</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Total Present</th>
                            <th scope="col" style={{ minWidth: "80px" }}>Total Attendance Taken</th>
                        </tr>
                    </thead>
                    <tbody>

                        {/* {console.log(classes, 'cl')} */}
                        {/* {setFileC(0)} */}
                        {attendanceData.map((attendanceDataVar, i) => {
                            return (
                                // onClick={Navigate('/overall-class-attendance', { schoolid: attendanceDataVar.school_id, school_name: schools[attendanceDataVar.school_id] })}
                                <tr key={i + 1} onClick={() => { navigate('/overall-class-attendance', { state: { school_id: attendanceDataVar.school_id, school_name: schools[attendanceDataVar.school_id], from_date: attendanceDataVar.start_date, to_date: attendanceDataVar.end_date } }) }}>
                                    <td>{i + 1}</td>
                                    <td>{new Date(attendanceDataVar.start_date).toDateString()}</td>
                                    <td>{new Date(attendanceDataVar.end_date).toDateString()}</td>
                                    <td>{new Date(attendanceDataVar.date).toDateString()}</td>
                                    <td>{attendanceDataVar.total_duration_in_days}</td>
                                    <td>{schools[attendanceDataVar.school_id]}</td>
                                    <td>{attendanceDataVar.user_type}</td>
                                    <td>{attendanceDataVar.total_present}</td>
                                    <td>{attendanceDataVar.total_attendance}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </Table>
            </div>
        </div >

    )
}

export default OverallSchoolAttendance