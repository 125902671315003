import React, { useState, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import { Button, Modal, Form, Col, Row, InputGroup, FormControl } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import CreateEvent from "./CreateEvent";
import "./UserCalendar.css";

const UserCalendar = () => {
	const [tempUserTypes, setTempUserTypes] = useState([
		{
			value: "DISTRICT",
			text: "District",
		},
		{
			value: "SCHOOL_ADMIN",
			text: "School Admin",
		},
		{
			value: "TEACHER",
			text: "Teacher",
		},
		{
			value: "PATENT",
			text: "Parent",
		},
		{
			value: "STUDENT",
			text: "Student",
		}
	]);
	const [userTypes, setUserTypes] = useState([]);
	const [eventTypeList, setEventTypeList] = useState([
		{ value: "GENRAL_EVENT", text: "General Event" },
		{ value: "OFFLINE_CLASS", text: "Offline Class" },
		{ value: "EXAM", text: "Exam" },
		{ value: "HOLIDAY", text: "Holiday" },
		{ value: "MEETING", text: "Meeting" },
		{ value: "5T-PERIOD", text: "5T Period" },
	]);
	const [schoolList, setSchoolList] = useState([]);
	const [classList, setClassList] = useState([]);
	const [{ userDetails }, dispatch] = useDataLayerValue();
	const [events, setEvents] = useState([]);
	const [schoolListForFilter, setSchoolListForFilter] = useState([]);
	const [classListForFilter, setClassListForFilter] = useState([]);
	const [filterRecipientUserType, setFilterRecipientUserType] = useState("");
	const [filterSchool, setFilterSchool] = useState("");
	const [filterClass, setFilterClass] = useState([]);
	const [eventType, setEventType] = useState("");
	useEffect(() => {
		console.log(tempUserTypes.map(
			(user, i) => {
				console.log(i, user)
				if (i >= tempUserTypes.findIndex((use) => use.value === userDetails.userType)) {
					return user
				}
			}
		).filter((us) => us !== undefined))
		setUserTypes(tempUserTypes.map(
			(user, i) => {
				console.log(i, user)
				if (i >= tempUserTypes.findIndex((use) => use.value === userDetails.userType)) {
					return user
				}
			}
		).filter((us) => us !== undefined))
		// setUserTypes(
		// 	tempUserTypes.map(
		// 		(user, i) => {
		// 			console.log(i, user)
		// 			if (i >= userTypes.findIndex((use) => use.value === userDetails.userType)) {
		// 				return user
		// 			}
		// 		}
		// 	).filter((us) => us !== undefined)
		// )
	}, [userDetails]);
	useEffect(() => {
		Api.get('/school-entity/school').then((response) => {
			if (userDetails?.userType == "DISTRICT") {
				response.data.sort((a, b) => a.name.localeCompare(b.name));
				setSchoolListForFilter(response.data);
			} else {
				setSchoolListForFilter([response.data]);
				setFilterSchool(response.data._id);
			}
		});
	}, [userDetails]);
	useEffect(() => {
		if (filterSchool !== "") {
			Api.get('school-entity/all-classes?school_id=' + filterSchool).then((response) => {
				setClassListForFilter(response.data);
			});
		} else {
			setClassListForFilter([]);
		}
	}, [filterSchool]);
	useEffect(() => {
		Api.get("/school-entity/school").then((res) => {
			var data = res.data;
			if (userDetails?.userType !== "DISTRICT") {
				data = [res.data]
			}
			data = data?.map((item) => {
				return {
					value: item._id,
					text: item.name,
				};
			});
			data.sort((a, b) => { return a.text.localeCompare(b.text) });
			data = [{ value: "ALL", text: "ALL" }, ...data];
			setSchoolList(data);
		});
		if (userDetails?.schoolId) {
			Api.get(`school-entity/all-classes?school_id=${userDetails.schoolId}`).then((res) => {
				var data = res.data;
				console.log("data", data);
				data = data.map((item) => {
					return {
						value: item._id,
						text: item.class_name_section,
					};
				});
				data.sort((a, b) => { return a.text.localeCompare(b.text) });
				data = [{ value: "ALL", text: "ALL" }, ...data];
				setClassList(data);
			});
		}
		console.log("events", events);
		// setUserTypes([userTypes[0], ...((userTypes.findIndex(userTypes => userTypes.value === userDetails.userType) > 0) ? userTypes.slice(userTypes.findIndex(userTypes => userTypes.value === userDetails.userType)) : [])]);
	}, [userDetails]);
	const [updateCount, setUpdateCount] = useState(0);
	const [allEvents, setAllEvents] = useState([]);
	useEffect(() => {
		dispatch({
			type: "SET_LOADING",
			loading: true,
		});
		Api.get('/calendar').then((res) => {
			console.log(res.data, "res");
			var eve = [];
			res.data?.map((item) => {
				if (item.event_dates && item.event_dates.length > 0) {
					item.event_dates.map((date) => {
						eve.push({
							...item,
							"id": item._id,
							"title": item.event_name,
							"description": item.description,
							"meetLink": item.meet_link,
							"start": new Date(new Date(date).setHours(0, 0, 0, 0) + new Date(item.event_start).getHours() * 60 * 60 * 1000 + new Date(item.event_start).getMinutes() * 60 * 1000 + new Date(item.event_start).getSeconds() * 1000),
							"end": new Date(new Date(date).setHours(0, 0, 0, 0) + new Date(item.event_start).getHours() * 60 * 60 * 1000 + new Date(item.event_start).getMinutes() * 60 * 1000 + new Date(item.event_start).getSeconds() * 1000 + (new Date(item.event_end).getTime() - new Date(item.event_start).getTime())),
						})
					})
				} else {
					eve.push({
						...item,
						"id": item._id,
						"title": item.event_name,
						"description": item.description,
						"meetLink": item.meet_link,
						"start": new Date(item.event_start),
						"end": new Date(item.event_end),
					})
				}
			})
			setEvents(eve);
			console.log(eve, "eve");
			setAllEvents(eve);
		}).finally(() => {
			dispatch({
				type: "SET_LOADING",
				loading: false,
			});
		});
	}, [updateCount, userDetails]);
	useEffect(() => {
		var event = allEvents;
		console.log(event, eventType, filterRecipientUserType, filterSchool, filterClass)

		if (eventType !== "") {
			event = event?.filter((eve) => eventType === eve.event_type);
		}
		console.log(event, eventType, filterRecipientUserType, filterSchool, filterClass)

		if (filterRecipientUserType !== "") {
			event = event?.filter((eve) => Array.isArray(eve.recepient_user_type) ? eve.recepient_user_type?.includes(filterRecipientUserType) : eve.recepient_user_type === filterRecipientUserType)
		}
		console.log(event, eventType, filterRecipientUserType, filterSchool, filterClass)

		if (filterSchool !== "") {
			event = event?.filter((eve) => Array.isArray(eve.school) ? eve.school?.includes(filterSchool) : eve.school === filterSchool)
		}
		console.log(event, eventType, filterRecipientUserType, filterSchool, filterClass)

		if (filterClass && filterClass.length > 0) {
			event = event?.filter((eve) => Array.isArray(eve.for_class) ? eve.for_class?.filter((c) => filterClass.filter((fc) => fc._id === c)?.length > 0)?.length > 0 : filterClass.filter((fc) => fc._id === eve.for_class)?.length > 0)
		}
		console.log(event, eventType, filterRecipientUserType, filterSchool, filterClass)
		setEvents(event);
	}, [allEvents, eventType, filterRecipientUserType, filterSchool, filterClass]);
	return (
		<div
			style={{
				backgroundColor: '#fff',
				padding: '20px',
			}}
		>
			<Form
				style={{
					display: "flex",
					flexDirection: "row",
					flexWrap: "wrap",
					justifyContent: "center",
				}}
			>
				<Form.Group
					controlId="ownerUserType"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>Event Type</Form.Label>
					<Form.Control
						as="select"
						value={eventType}
						onChange={(e) => setEventType(e.target.value)}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
							minHeight: "55px",
						}}
					>
						<option value="">ALL</option>
						{eventTypeList.map((event) => {
							return (
								<option value={event.value}> {event.text} </option>
							)
						})}
					</Form.Control>
				</Form.Group>
				<Form.Group
					controlId="recipientUserType"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>Recipient User Type</Form.Label>
					{/* <Multiselect
                        showArrow
                        labelledBy={"Select User Types"}
                        options={userTypes}
                        displayValue="value"
                        showCheckbox={true}
                        selectedValues={filterRecipientUserType}
                        onSelect={(selectedList, selectedItem) => { setFilterRecipientUserType(selectedList) }}
                        onRemove={(selectedList, selectedItem) => { setFilterRecipientUserType(selectedList) }}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                        }}
                    /> */}
					<Form.Control
						as="select"
						value={filterRecipientUserType}
						onChange={(e) => setFilterRecipientUserType(e.target.value)}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
							minHeight: "55px",
						}}
					>
						<option value="">ALL</option>
						{userTypes && userTypes.length > 0 && userTypes.map((userType) => {
							return (
								<option value={userType?.value}> {userType?.text} </option>
							)
						})}
					</Form.Control>
				</Form.Group>
				<Form.Group
					controlId="school"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>School</Form.Label>
					<Form.Control
						as="select"
						value={filterSchool}
						onChange={(e) => setFilterSchool(e.target.value)}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
							minHeight: "55px",
						}}
						disabled={userDetails?.userType === "DISTRICT" ? false : true}
					>
						<option value="">ALL</option>
						{schoolListForFilter.map((school) => {
							return (
								<option value={school._id}> {school.name} </option>
							)
						})}
					</Form.Control>
				</Form.Group>
				{classListForFilter.length > 0 && <Form.Group
					controlId="class"
					style={{
						padding: "10px",
					}}
				>
					<Form.Label>Class</Form.Label>
					<Multiselect
						labelledBy={"Select"}
						showArrow
						options={classListForFilter}
						displayValue="class_name_section"
						groupBy="class_name"
						showCheckbox={true}
						selectedValues={filterClass}
						onSelect={(selectedList, selectedItem) => { setFilterClass(selectedList) }}
						onRemove={(selectedList, selectedItem) => { setFilterClass(selectedList) }}
						style={{
							minWidth: "250px",
							maxWidth: "300px",
						}}
					/>
				</Form.Group>
				}
			</Form>
			<Scheduler
				view={'month'}
				deletable={false}
				// editable={false}
				events={events}
				fields={[
					{
						name: "month",
						title: "Month",
						type: "input",
					},
					{
						name: "day",
						title: "Day",
						type: "input",
					},
					{
						name: "year",
						title: "Year",
						type: "input",
					},
					{
						name: "is_full_day",
						title: "Is Full Day",
						type: "input",
					},
					{
						name: "for_class",
						title: "For Class",
						type: "input",
					},
					{
						name: "recepient_user_type",
						title: "Recepient User Type",
						type: "input",
					},
					{
						name: "school",
						title: "School",
						type: "input",
					},
					{
						name: "frequency",
						title: "Frequency",
						type: "input",
					},
					{
						name: "freq_end_date",
						title: "Frequency End Date",
						type: "input",
					},
					{
						name: "event_type",
						title: "Event Type",
						type: "input",
					},
					{
						name: "recepient_content_ids",
						title: "Recepient Content Ids",
						type: "input",
					},
					{
						name: "recepient_course_ids",
						title: "Recepient Course Ids",
						type: "input",
					},
					{
						name: "holiday_message",
						title: "Holiday Message",
						type: "input",
					},
					{
						name: "offline_class_location",
						title: "Offline Class Location",
						type: "input",
					},
					{
						name: "frontend_data",
						title: "Frontend Data",
						type: "input",
					},
				]}
				onConfirm={(e, action) => {
					console.log((new Date(e["repeat-end-date"]).getMonth() + 1) + "/" + new Date(e["repeat-end-date"]).getDate() + "/" + new Date(e["repeat-end-date"]).getFullYear())
					// console.log("e", e,e["repeat-end-date"], new Date(e["repeat-end-date"]).getDate() , (new Date(e["repeat-end-date"]).getMonth() + 1) , new Date(e["repeat-end-date"]).getFullYear())
					const validMonths = [
						'JANUARY',
						'FEBRUARY',
						'MARCH',
						'APRIL',
						'MAY',
						'JUNE',
						'JULY',
						'AUGUST',
						'SEPTEMBER',
						'OCTOBER',
						'NOVEMBER',
						'DECEMBER'
					]
					var date = new Date(e.start);
					if (e.schoolId === "ALL" || e.schoolId === undefined || e.schoolId === null || e.schoolId === "") {
						e.schoolId = undefined;
					} else {
						e.schoolId = [e.schoolId];
					}
					if (e.classId === "ALL" || e.classId === undefined || e.classId === null || e.classId === "") {
						e.classId = undefined;
					} else {
						e.classId = [e.classId];
					}
					console.log(e, "e");
					Api.post("calendar", {
						"day": date.getDate(),
						"month": validMonths[date.getMonth()],
						"year": date.getFullYear(),
						"event_name": e.title,
						"description": e.description,
						"meet_link": e.meetLink,
						"recepient_user_type": e.userType,
						"school": e.schoolId,
						"for_class": e.classId,
						"event_start": new Date(e.start).getTime(),
						"event_end": new Date(e.end).getTime(),
						"freq_end_date": new Date((new Date(e["repeat-end-date"]).getMonth() + 1) + "/" + new Date(e["repeat-end-date"]).getDate() + "/" + new Date(e["repeat-end-date"]).getFullYear()).getTime() + 24 * 60 * 60 * 1000 - 1,
						"event_type": e["event-type"],
						"frequency": e["repeat-type"],
					}).then((res) => {
						// e.id = res.data._id
						setUpdateCount(updateCount + 1);
						return e;
					});
					return e;
				}}
				customEditor={(scheduler) => <CreateEvent
					scheduler={scheduler}
					updateCount={updateCount}
					setUpdateCount={setUpdateCount}
				/>}
				viewerExtraComponent={(fields, event) => {
					return (
						<div style={{
							padding: '10px',
						}}>
							<b>Description:</b> {event.description} <br />
							<b>Event Type:</b> {event.event_type} <br />
							<b>Event For:</b> {event.recepient_user_type} <br />
							<b>Meet Link:</b> <a href={event.meetLink} target="_blank">{event.meetLink}</a>
						</div>
					);
				}}
			/>
		</div>
	);
};

export default UserCalendar;