import React, { useState, useEffect } from 'react';
import Api from '../../Api/axios';
import { Stack } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table, Button, Form, Col, Row } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { Multiselect } from 'multiselect-react-dropdown';
import { Modal } from 'react-bootstrap';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const GetUsers = (data) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const navigate = useNavigate();
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [districtId, setDistrictId] = useState(data.districtId ? data.districtId : (params.districtId ? params.districtId : (userDetails ? userDetails?.districtId : null)));
    const [schoolId, setSchoolId] = useState(data.schoolId ? data.schoolId : (params.schoolId ? params.schoolId : (userDetails ? userDetails?.schoolId : null)));
    const [refresh, setRefresh] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [userDict, setUserDict] = useState([]);
    const [filterUserType, setFilterUserType] = useState("ALL");
    const [finalUserDict, setFinalUserDict] = useState([]);
    const [count, setCount] = useState(data.count ? data.count : 50);
    const [loadCount, setLoadCount] = useState(count);
    const [filterUserClasses, setFilterUserClasses] = useState([]);
    const [filterUserSubjects, setFilterUserSubjects] = useState([]);
    const [filterSchoolId, setFilterSchoolId] = useState("");
    const [schoolList, setSchoolList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(false);
    const [promoteStudentClass, setPromoteStudentClass] = useState("");
    const [promoteOpen, setPromoteOpen] = useState(false);
    const [promoteErrorText, setPromoteErrorText] = useState("");
    const [transferSchool, setTransferSchool] = useState("");
    const [transferSchoolList, setTransferSchoolList] = useState([]);
    const [transferClass, setTransferClass] = useState("");
    const [transferClassList, setTransferClassList] = useState([]);
    const [transferOpen, setTransferOpen] = useState(false);
    const [transferClassTeacher, setTransferClassTeacher] = useState([]);
    const [markRFID, setMarkRFID] = useState(data.markRFID ? data.markRFID : false);
    const [markRFIDCount, setMarkRFIDCount] = useState(0);
    const [scanText, setScanText] = useState("");
    const [enterUserIdsModelOpen, setEnterUserIdsModelOpen] = useState(false);
    const [enterUserIds, setEnterUserIds] = useState("");
    const [text, setText] = useState("");
    const selectUsers = async (usersId) => {
        await Promise.all([userDict.map(async (user, idx) => {
            if (usersId.includes(user._id)) {
                user.isSelected = true;
            }
        })])
        if (data.selectUsers) {
            data.selectedUsersSet(userDict)
        }
    }
    const transferUsers = () => {
        if (transferSchool === "") {
            setPromoteErrorText("Please select a school");
            return;
        }
        setPromoteErrorText('')
        userDict.map((user) => {
            userDict[user["index"]].status = "running";
        })
        setForceRefresh(!forceRefresh);
        userDict.map(async (user, idx) => {
            let prerror = false;
            if (user.isSelected) {
                // console.log(user)
                if ((user.userType === 'STUDENT') && user.classes.length > 0) {
                    // console.log(user.classes[0]._id)
                    await Api.post('/user-lifecycle/transfer/student', {
                        userId: user._id,
                        from_school_id: user.schoolId,
                        to_school_id: transferSchool,
                        from_class_id: user.classes[0]._id,
                        to_class_id: [transferClass]
                    }).then((response) => {
                        userDict[user["index"]].status = "success";
                    }).catch((error) => {
                        // console.log(error);
                        prerror = true;
                        userDict[user["index"]].status = "failed";
                    }).finally(() => {
                        // console.log(userDict[user["index"]].status);
                        setPromoteErrorText('')
                        setForceRefresh(!forceRefresh);
                    })
                } else if (user.userType === 'TEACHER') {
                    await Api.post('/user-lifecycle/transfer/teacher', {
                        userId: user._id,
                        from_school_id: user.schoolId,
                        to_school_id: transferSchool,
                        to_class_id: transferClassTeacher?.map((classTeacher) => classTeacher._id)
                    }).then((response) => {
                        userDict[user["index"]].status = "success";
                    }).catch((error) => {
                        // console.log(error);
                        prerror = true;
                        userDict[user["index"]].status = "failed";
                    }).finally(() => {
                        // console.log(userDict[user["index"]].status);
                        setPromoteErrorText('')
                        setForceRefresh(!forceRefresh);
                    })
                } else {
                    userDict[user["index"]].status = "failed";
                    setPromoteErrorText('')
                    setForceRefresh(!forceRefresh);
                }
            }
            // console.log(user["index"]);
            if (userDict[user["index"]].status === "success" || userDict[user["index"]].status === "failed") {
                setForceRefresh(!forceRefresh, setPromoteErrorText('Processing'));
            }
            setPromoteErrorText('')
        })
        setForceRefresh(!forceRefresh);
    }

    const promoteUsers = async () => {
        if (promoteStudentClass === '') {
            setPromoteErrorText('Please select a class');
        } else {
            setPromoteErrorText('')
            await userDict.map((user) => {
                userDict[user["index"]].status = "running";
            })
            setForceRefresh(!forceRefresh);
            await userDict.map(async (user, idx) => {
                let prerror = false;
                if (user.isSelected) {
                    if (user.userType === 'STUDENT' && user.classes.length > 0) {
                        await Api.post('/user-lifecycle/promote/student', {
                            userId: user._id,
                            to_class_id: promoteStudentClass,
                            from_class_id: user.classes[0]
                        }).then((response) => {
                            userDict[user["index"]].status = "success";
                        }).catch((error) => {
                            // console.log(error);
                            prerror = true;
                            userDict[user["index"]].status = "failed";
                        }).finally(() => {
                            // console.log(userDict[user["index"]].status);
                            setPromoteErrorText('')
                            setForceRefresh(!forceRefresh);
                        })
                    } else {
                        userDict[user["index"]].status = "failed";
                        setPromoteErrorText('')
                        setForceRefresh(!forceRefresh);
                    }
                }
                // console.log(user["index"]);
                if (userDict[user["index"]].status === "success" || userDict[user["index"]].status === "failed") {
                    setForceRefresh(!forceRefresh, setPromoteErrorText('Processing'));
                }
                setPromoteErrorText('')
            })
            setForceRefresh(!forceRefresh);
        }
    }
    const userTypes = [
        ['DISTRICT', 'District'],
        ['SCHOOL_ADMIN', 'School Admin'],
        ['TEACHER', 'Teacher'],
        ['PARENT', 'Parent'],
        ['STUDENT', 'Student'],
    ]
    function intersect(a, b) {
        var setB = new Set(b);
        return [...new Set(a)].filter(x => setB.has(x));
    }
    useEffect(() => {
        setDistrictId(data.districtId ? data.districtId : (params.districtId ? params.districtId : (userDetails ? userDetails?.districtId : null)));
        setSchoolId(data.schoolId ? data.schoolId : (params.schoolId ? params.schoolId : (userDetails ? userDetails?.schoolId : null)));
        setTransferSchool(data.schoolId ? data.schoolId : (params.schoolId ? params.schoolId : (userDetails ? userDetails?.schoolId : null)));
        setRefresh(true);
    }, []);
    useEffect(async () => {
        if (refresh) {
            dispatch({
                type: "SET_LOADING",
                loading: true
            })
            var url = "/user/users-data?"
            // console.log(data, params);
            if (districtId) {
                url += "&districtId=" + districtId
            }
            if (schoolId) {
                url += "&schoolId=" + schoolId
            }
            if (filterUserClasses !== []) {
                filterUserClasses.map((item, index) => {
                    url += `&classes${index}=` + item.class_name_section
                })
            }
            if (filterUserSubjects !== []) {
                filterUserSubjects.map((item, index) => {
                    url += `&subject_taught${index}=` + item.name
                })
            }
            Api.get(url).then((res) => {
                setUserDict(res.data.map((item, index) => {
                    item["index"] = index;
                    return item;
                }));
            }).finally(() => {
                dispatch({
                    type: "SET_LOADING",
                    loading: false
                })
            })
        }
    }, [refresh, userDetails, districtId, schoolId]);
    useEffect(() => {
        if (transferSchool) {
            Api.get("school-entity/all-classes?school_id=" + transferSchool).then((res) => {
                setTransferClassList(res.data);
            })
        } else {
            setTransferClassList([]);
        }
    }, [transferSchool]);
    useEffect(() => {
        if (filterSchoolId) {
            Api.get("school-entity/all-classes?school_id=" + filterSchoolId).then((res) => {
                setClassList(res.data);
                setFilterUserClasses([]);
            })
        } else if (schoolId) {
            Api.get("school-entity/all-classes?school_id=" + schoolId).then((res) => {
                setClassList(res.data);
                setFilterUserClasses([]);
            })
        } else {
            setClassList([]);
            setFilterUserClasses([]);
        }
    }, [userDetails, schoolId, filterSchoolId]);
    useEffect(() => {
        if (!schoolId) {
            // console.log(userDetails);
            Api.get("school-entity/school").then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                res.data = [{ _id: "", name: "All" }, ...res.data];
                setSchoolList(res.data);
                setTransferSchoolList(res.data);
            }
            )
        } else if (!userDetails?.schoolId) {
            Api.get("school-entity/school").then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                res.data = [{ _id: "", name: "All" }, ...res.data];
                setTransferSchoolList(res.data);
            }
            )
        }

        Api.get("preconfig-entites/subjects").then((res) => {
            res.data.sort((a, b) => a.name.localeCompare(b.name));
            setSubjectList(res.data);
        })
    }, [userDetails, districtId]);
    useEffect(() => {
        let c = 0;
        let temp = [];
        for (let i = 0; i < userDict.length; i++) {
            let user = userDict[i];
            let userClasses = filterUserClasses.map((item) => item._id);
            let userSubjects = filterUserSubjects.map((item) => item._id);
            let userSubjectsTaught = user.subject_taught?.map((item) => item._id);
            if (c < count && (filterSchoolId === "" || user.schoolId === filterSchoolId) && (filterUserType === "ALL" || user.userType === filterUserType) && (searchString === "" || user.name.toLowerCase().includes(searchString.toLowerCase()) || user.conventionalId.toLowerCase().includes(searchString.toLowerCase())) && (userClasses.length === 0 || intersect(user.classes, userClasses).length > 0) && (userSubjects.length === 0 || intersect(userSubjectsTaught, userSubjects).length > 0) && (markRFID === false || (markRFID && !user.rfid))) {
                temp.push(user);
                c += 1;
            }
            if (c >= count) {
                break;
            }
        }
        setFinalUserDict(temp);
        setLoadCount(count);
    }, [userDict, filterUserType, searchString, filterSchoolId, filterUserClasses, filterUserSubjects]);
    useEffect(() => {
        let c = 0;
        let temp = [];
        for (let i = 0; i < userDict.length; i++) {
            let user = userDict[i];
            let userClasses = filterUserClasses.map((item) => item._id);
            let userSubjects = filterUserSubjects.map((item) => item._id);
            let userSubjectsTaught = user.subject_taught?.map((item) => item._id);
            if (c < loadCount && (!user.is_internal_super_user) && (!user.is_internal_user) && (filterSchoolId === "" || user.schoolId === filterSchoolId) && (filterUserType === "ALL" || user.userType === filterUserType) && (searchString === "" || user.name.toLowerCase().includes(searchString.toLowerCase()) || user.conventionalId.toLowerCase().includes(searchString.toLowerCase())) && (userClasses.length === 0 || intersect(user.classes, userClasses).length > 0) && (userSubjects.length === 0 || intersect(userSubjectsTaught, userSubjects).length > 0) && (markRFID === false || (markRFID && !user.rfid))) {
                temp.push(user);
                c += 1;
            }
            if (c >= loadCount) {
                break;
            }
        }
        setFinalUserDict(temp);
        setLoadCount(c);
    }, [markRFIDCount]);
    const loadMore = () => {
        // console.log(markRFID);
        let c = 0;
        for (let i = 0; i < userDict.length; i++) {
            let user = userDict[i];
            let userClasses = filterUserClasses.map((item) => item._id);
            let userSubjects = filterUserSubjects.map((item) => item._id);
            let userSubjectsTaught = user.subject_taught?.map((item) => item._id);
            if (c < (count + loadCount) && (!user.is_internal_super_user) && (!user.is_internal_user) && (filterSchoolId === "" || user.schoolId === filterSchoolId) && (filterUserType === "ALL" || user.userType === filterUserType) && (searchString === "" || user.name.toLowerCase().includes(searchString.toLowerCase()) || user.conventionalId.toLowerCase().includes(searchString.toLowerCase())) && (userClasses.length === 0 || intersect(user.classes, userClasses).length > 0) && (userSubjects.length === 0 || intersect(userSubjectsTaught, userSubjects).length > 0) && (markRFID === false || (markRFID && !user.rfid))) {
                if (c >= loadCount) {
                    finalUserDict.push(user);
                }
                // finalUserDict.push(user);
                c += 1;
            }
            if (c >= (count + loadCount)) {
                break;
            }
        }
        setLoadCount(count + loadCount);
    }
    return (
        <div align="center" style={{
            paddingBottom: "10px",
        }}>
            <div className='mt-2 mb-2 row' style={{ justifyContent: "center" }}>
                <Form.Group align="left" className="pt-2 pb-2 col-12 col-sm-6 col-lg-4">
                    <Form.Label>User Type</Form.Label>
                    <Form.Select
                        label="User Type"
                        variant="filled"
                        value={filterUserType}
                        onChange={(e) => { setFilterUserType(e.target.value); }}
                    >
                        <option key="ALL" value="ALL" style={{ width: "100%" }}>
                            ALL
                        </option>
                        {
                            userTypes.map((userType, i) => {
                                return (
                                    <option key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
                                        {userType[1]}
                                    </option>
                                )
                            })
                        }
                    </Form.Select>
                </Form.Group>
                {schoolList.length !== 0 &&
                    <Form.Group align="left" className="pt-2 pb-2 col-12 col-sm-6 col-lg-4" controlId="school">
                        <Form.Label align="left">School</Form.Label>
                        <Form.Select
                            value={filterSchoolId}
                            onChange={(e) => { setFilterSchoolId(e.target.value) }}
                        >
                            {schoolList?.map((school) => {
                                return (
                                    <option value={school._id}>{school.name}</option>
                                )
                            })}
                        </Form.Select>
                    </Form.Group>
                }
                {((filterUserType === "TEACHER" || filterUserType === "STUDENT") && (classList.length !== 0)) ?
                    <Form.Group align="left" className="pt-2 pb-2 col-12 col-sm-6 col-lg-4" controlId="class">
                        <Form.Label>Class</Form.Label>
                        {/* {console.log(classList, classList.length)} */}
                        <Multiselect
                            showArrow
                            options={classList}
                            displayValue="class_name_section"
                            groupBy="class_name"
                            showCheckbox={true}
                            selectedValues={filterUserClasses}
                            onSelect={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                            onRemove={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                            style={{
                                marginTop: "0",
                            }}
                        />
                    </Form.Group>
                    : null
                }
                {((filterUserType === "TEACHER") && (subjectList.length !== 0)) ?
                    <Form.Group align="left" className="pt-2 pb-2 col-12 col-sm-6 col-lg-4" controlId="class">
                        <Form.Label>Subjects</Form.Label>
                        <Multiselect
                            showArrow
                            options={subjectList}
                            displayValue="name"
                            showCheckbox={true}
                            selectedValues={filterUserSubjects}
                            onSelect={(selectedList, selectedItem) => { setFilterUserSubjects(selectedList) }}
                            onRemove={(selectedList, selectedItem) => { setFilterUserSubjects(selectedList) }}
                            style={{
                                marginTop: "0",
                            }}
                        />
                    </Form.Group>
                    : null
                }
                <Form.Group>
                    <Form.Control
                        placeholder="Search User"
                        className="mt-2 mb-2 col-12"
                        value={searchString}
                        onChange={(e) => { setSearchString(e.target.value) }}
                        style={{ padding: "10px" }}
                    />
                </Form.Group>
            </div>
            <Modal show={transferOpen} onHide={(e) => setTransferOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer Users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-2" style={{ maxHeight: "500px", overflowY: "auto", overflowX: "auto" }} align="center">
                        <Form align="left">
                            {userDetails.userType === "DISTRICT" ? <Form.Group className="mb-3" controlId="destinationClass">
                                <Form.Label>Destination School</Form.Label>
                                <Form.Select
                                    value={transferSchool}
                                    onChange={(e) => {
                                        setTransferSchool(e.target.value);
                                    }}
                                >
                                    {
                                        transferSchoolList.map((school) => {
                                            return (
                                                <option value={school._id} {...(school._id === "" ? { disabled: true } : {})}>{school.name}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group> : null}
                            <Form.Group className="mb-3" controlId="destinationClass">
                                <Form.Label>Destination Class (Applicable only for Teacher)</Form.Label>
                                <Multiselect
                                    showArrow
                                    options={transferClassList}
                                    displayValue="class_name_section"
                                    groupBy="class_name"
                                    showCheckbox={true}
                                    selectedValues={transferClassTeacher}
                                    onSelect={(selectedList, selectedItem) => { setTransferClassTeacher(selectedList) }}
                                    onRemove={(selectedList, selectedItem) => { setTransferClassTeacher(selectedList) }}
                                    style={{
                                        marginTop: "0",
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="destinationClass">
                                <Form.Label>Destination Class (Applicable only for Students)</Form.Label>
                                <Form.Select
                                    value={transferClass}
                                    {...(classList.length == 0 ? { disabled: true } : {})}
                                    onChange={(e) => {
                                        setTransferClass(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected>Select Class</option>
                                    {
                                        transferClassList.map((classs) => {
                                            return (
                                                <option value={classs._id}>{classs.class_name_section}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Form>
                        <p style={{ color: "red" }} >{promoteErrorText}</p>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Userame</th>
                                    <th>User Type</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userDict.map((user, index) => {
                                        if (user) {
                                            if (user.isSelected) {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{user.name}</td>
                                                        <td>{user.conventionalId}</td>
                                                        <td>{user.userType}</td>
                                                        <td>{
                                                            user.status === "running" ?
                                                                <img src={`${process.env.PUBLIC_URL}/Images/loading.gif`} height="30" width="30" /> :
                                                                user.status === "success" ?
                                                                    <CheckCircleOutlineOutlinedIcon /> :
                                                                    user.status === "failed" ?
                                                                        <ErrorOutlineOutlinedIcon /> :
                                                                        ""
                                                        }</td>
                                                    </tr>
                                                )
                                            }
                                            else {
                                                return null;
                                            }
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </tbody>
                        </Table>
                        <Button
                            variant="secondary"
                            onClick={async (e) => {
                                await transferUsers()
                                setForceRefresh(!forceRefresh);
                            }}
                        >
                            Transfer Users
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => setTransferOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={promoteOpen} onHide={(e) => setPromoteOpen(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Promote Users</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-2" style={{ maxHeight: "500px", overflowY: "auto", overflowX: "auto" }} align="center">
                        <Form align="left">
                            <Form.Group className="mb-3" controlId="destinationClass">
                                <Form.Label>Destination Class (Applicable only for Students)</Form.Label>
                                <Form.Select
                                    value={promoteStudentClass}
                                    {...(classList.length == 0 ? { disabled: true } : {})}
                                    onChange={(e) => {
                                        setPromoteStudentClass(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected>Select Class</option>
                                    {
                                        classList.map((classs) => {
                                            return (
                                                <option value={classs._id}>{classs.class_name_section}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Form>
                        <p style={{ color: "red" }} >{promoteErrorText}</p>
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Userame</th>
                                    <th>User Type</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    userDict.map((user, index) => {
                                        if (user) {
                                            if (user.isSelected) {
                                                return (
                                                    <tr>
                                                        <td>{index + 1}</td>
                                                        <td>{user.name}</td>
                                                        <td>{user.conventionalId}</td>
                                                        <td>{user.userType}</td>
                                                        <td>{
                                                            user.status === "running" ?
                                                                <img src={`${process.env.PUBLIC_URL}/Images/loading.gif`} height="30" width="30" /> :
                                                                user.status === "success" ?
                                                                    <CheckCircleOutlineOutlinedIcon /> :
                                                                    user.status === "failed" ?
                                                                        <ErrorOutlineOutlinedIcon /> :
                                                                        ""
                                                        }</td>
                                                    </tr>
                                                )
                                            }
                                            else {
                                                return null;
                                            }
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </tbody>
                        </Table>
                        <Button
                            variant="secondary"
                            onClick={async (e) => {
                                await promoteUsers()
                                setForceRefresh(!forceRefresh);
                            }}
                        >
                            Promote Users
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => setPromoteOpen(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                data.allowUserIdSelect &&
                <div className='mt-2 mb-2' style={{ justifyContent: "center" }}>
                    <Button
                        variant="secondary"
                        style={{
                            width: '100%',
                        }}
                        onClick={(e) => {
                            setEnterUserIdsModelOpen(true);
                        }}
                    >
                        Select Users Based on User Id's
                    </Button>
                </div>
            }
            <Modal
                show={enterUserIdsModelOpen}
                onHide={(e) => setEnterUserIdsModelOpen(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Enter User Ids</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            padding: '10px',
                        }}
                    >
                        <center>
                            <b>
                                {text != "" ? text : ""}
                            </b>
                        </center>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Enter User Ids</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={enterUserIds}
                            onChange={(e) => {
                                console.log(e.target.value);
                                setEnterUserIds(e.target.value);
                            }}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => {
                        setEnterUserIdsModelOpen(false)
                        setText("")
                    }}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={async (e) => {
                        dispatch({ type: "SET_LOADING", payload: true })
                        let userIds = JSON.parse(enterUserIds);
                        await selectUsers(userIds);
                        setText("Done Selecting Users")
                        dispatch({ type: "SET_LOADING", payload: false })
                        setForceRefresh(!forceRefresh);
                    }}>
                        Select
                    </Button>
                </Modal.Footer>
            </Modal>
            {
                data.lifeCycleEdit && (userDetails.userType === "DISTRICT" || (userDetails.userType === "SCHOOL_ADMIN" || userDetails.schoolId === schoolId)) &&
                <div className='mt-2 mb-2 row' style={{ justifyContent: "center" }}>
                    <Stack className='row' direction="horizontal" gap={2} style={{ width: "100%" }}>
                        <Button className='col' variant="secondary" onClick={(e) => {
                            userDict.map((user, index) => {
                                userDict[index].status = "not-running";
                            })
                            setTransferOpen(true)
                            setPromoteErrorText("")
                            setForceRefresh(!forceRefresh);
                        }}>Transfar</Button>
                        <Button className='col' variant="secondary" onClick={(e) => {
                            userDict.map((user, index) => {
                                userDict[index].status = "not-running";
                            })
                            setPromoteOpen(true)
                            setPromoteErrorText("")
                            setForceRefresh(!forceRefresh);
                        }}>Promote</Button>
                        <Button className='col' variant="secondary">Remove</Button>
                    </Stack>
                </div>
            }
            <Table striped bordered hover responsive>
                <thead>
                    <tr align="center">
                        {
                            (data.lifeCycleEdit || data.selectUsers) &&
                            <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                <Form.Check
                                    type="checkbox"
                                    onChange={(e) => {
                                        dispatch({ type: "SET_LOADING", payload: true })
                                        finalUserDict.forEach((user, index) => {
                                            // console.log(e.target.checked);
                                            // console.log(userDict[user["index"]].isSelected, finalUserDict[index].isSelected)
                                            userDict[user["index"]].isSelected = e.target.checked;
                                            // console.log(userDict[user["index"]].isSelected, finalUserDict[index].isSelected)
                                        })
                                        if (data.selectUsers) {
                                            data.selectedUsersSet(userDict)
                                        }
                                        dispatch({ type: "SET_LOADING", payload: false })
                                        setForceRefresh(!forceRefresh);
                                    }}
                                    checked={finalUserDict.length === finalUserDict.filter((user) => user.isSelected).length}
                                />
                            </th>
                        }
                        <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Sr. No.</th>
                        <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Name</th>
                        <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Conventional ID</th>
                        {
                            // (userDetails?.userType === "DISTRICT") ?
                            (userDetails?.userType === "DISTRICT" && markRFID) ?
                                <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>RFID</th>
                                :
                                null
                        }
                        <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>User Type</th>
                        <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: (userDetails?.userType === "DISTRICT" || userDetails?.userType === "SCHOOL_ADMIN") ? "1px solid rgb(0 0 0 / 26%)" : "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Dashboard</th>
                        {
                            (userDetails?.userType === "DISTRICT" || userDetails?.userType === "SCHOOL_ADMIN") ?
                                <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: (userDetails?.userType === "DISTRICT" && markRFID) ? "1px solid rgb(0 0 0 / 26%)" : "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Edit</th>
                                :
                                null
                        }
                        {
                            (userDetails?.userType === "DISTRICT" && markRFID) ?
                                <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Mark RFID</th>
                                :
                                null
                        }
                    </tr>
                </thead>
                <tbody>
                    {finalUserDict.map((user, index) => {
                        return (
                            <>
                                <Modal
                                    show={user?.isModalOpen}
                                    onHide={() => {
                                        var tempUserList = [...finalUserDict]
                                        tempUserList[index].isModalOpen = false
                                        setFinalUserDict(tempUserList)
                                    }}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>User Information</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                        style={{
                                            maxHeight: "500px",
                                            overflowY: "auto",
                                            overflowX: "auto"
                                        }}
                                    >
                                        <Table responsive hover>
                                            <tbody>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap", minWidth: "200px" }}><b>Name</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {user.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap", minWidth: "200px" }}><b>Designation</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {user.displayUserType}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap", minWidth: "200px" }}><b>Conventioanl ID</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {user.conventionalId}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap", minWidth: "200px" }}><b>School Name</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {user.schoolName}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>District</b></td>
                                                    {/*  remove this hardcode  */}
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: Sundergarh</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Date of Birth</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {
                                                        new Date(user.DOB)
                                                            ? (String((new Date(user.DOB)).getDate()).padStart(2, '0') + '-' + String((new Date(user.DOB)).getMonth() + 1).padStart(2, '0') + '-' + (new Date(user.DOB)).getFullYear()) : ""
                                                    }</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Gender</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.gender}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Mother Tongue</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.mother_tongue}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Email</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.email}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Phone No.</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: +91 {user.contactNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Address</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.permanent_address}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Religion</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Religion}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Caste</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Caste ? user.Caste : user.social_category}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Disability</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.disability_type}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => {
                                            var tempUserList = [...finalUserDict]
                                            tempUserList[index].isModalOpen = false
                                            setFinalUserDict(tempUserList)
                                        }}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal
                                    show={user?.markRFIDOpen}
                                    onHide={() => {
                                        // var tempUserList = [...finalUserDict]
                                        finalUserDict[index].markRFIDOpen = false
                                        // setFinalUserDict(tempUserList)
                                    }}
                                    onShow={() => {
                                        var input = document.getElementById('myIdInput');
                                        input.focus();
                                        input.select();
                                    }}
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title>User Information</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body
                                        style={{
                                            maxHeight: "500px",
                                            overflowY: "auto",
                                            overflowX: "auto"
                                        }}
                                    >
                                        <Table responsive hover>
                                            <tbody>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap", minWidth: "200px" }}><b>Name</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {user.name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap", minWidth: "200px" }}><b>Conventional ID</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {user.conventionalId}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <Form>
                                            <Form.Group>
                                                <Form.Label>Enter ID</Form.Label>
                                                <Form.Control
                                                    id="myIdInput"
                                                    type="text"
                                                    placeholder="Enter ID"
                                                    value={scanText}
                                                    onChange={(e) => setScanText(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Button
                                                className="mt-4 mb-4"
                                                variant="primary"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    dispatch(
                                                        {
                                                            type: "SET_LOADING",
                                                            payload: true
                                                        }
                                                    )

                                                    var text = scanText;
                                                    setScanText("")
                                                    Api.post("addRFID", {
                                                        user_id: user?._id,
                                                        rfid: text
                                                    }).then((res) => {
                                                        // console.log(res, res.data.status);
                                                        if (res.data.status === 403) {
                                                            alert("RFID already exists")
                                                        } else {
                                                            // var tempUserList = [...finalUserDict]
                                                            finalUserDict[index]["rfid"] = text
                                                            finalUserDict[index].markRFIDOpen = false
                                                            // setFinalUserDict(tempUserList)
                                                            setMarkRFIDCount(markRFIDCount + 1)
                                                        }
                                                    }).catch((err) => {
                                                        // console.log("fhsifs", err)
                                                    }).finally(() => {
                                                        dispatch(
                                                            {
                                                                type: "SET_LOADING",
                                                                payload: false
                                                            }
                                                        )
                                                    })
                                                    return false
                                                }}
                                                type="submit"
                                                style={{
                                                    width: "100%"
                                                }}
                                            >
                                                Mark ID
                                            </Button>
                                        </Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => {
                                            // var tempUserList = [...finalUserDict]
                                            finalUserDict[index].markRFIDOpen = false
                                            // setFinalUserDict(tempUserList)
                                        }}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <tr>
                                    {
                                        (data.lifeCycleEdit || data.selectUsers) &&
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            <Form.Check
                                                type="checkbox"
                                                checked={user.isSelected}
                                                onChange={() => {
                                                    userDict[user["index"]].isSelected = !userDict[user["index"]].isSelected
                                                    if (data.selectUsers) {
                                                        data.selectedUsersSet(userDict)
                                                    }
                                                    setForceRefresh(!forceRefresh);
                                                }}
                                            />
                                        </td>
                                    }
                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                        <a onClick={() => {
                                            var tempUserList = [...finalUserDict]
                                            tempUserList[index].isModalOpen = true
                                            setFinalUserDict(tempUserList)
                                        }}>
                                            {user.name}
                                        </a>
                                    </td>
                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{user.conventionalId}</td>
                                    {
                                        // (userDetails?.userType === "DISTRICT") ?
                                        (userDetails?.userType === "DISTRICT" && markRFID) ?
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{user.rfid ? user.rfid : ""}</td>
                                            :
                                            null
                                    }
                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{user.userType}</td>
                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: (userDetails?.userType === "DISTRICT" || userDetails?.userType === "SCHOOL_ADMIN") ? "1px solid rgb(0 0 0 / 26%)" : "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}><a href="#" style={{ textDecoration: "none" }} onClick={() => window.open(window.location.origin + "/dashboard/" + user._id, '_blank')}>Visit</a></td>
                                    {
                                        (userDetails?.userType === "DISTRICT" || userDetails?.userType === "SCHOOL_ADMIN") ?
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: (userDetails?.userType === "DISTRICT" && markRFID) ? "1px solid rgb(0 0 0 / 26%)" : "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}><a href="#" style={{ textDecoration: "none" }} onClick={() => window.open(window.location.origin + "/edit-user/" + user._id + "/?edit=true", '_blank')}>Edit</a></td>
                                            :
                                            null
                                    }
                                    {
                                        (userDetails?.userType === "DISTRICT" && markRFID) ?
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}><a href="#" style={{ textDecoration: "none" }} onClick={() => {
                                                // var tempUserList = [...finalUserDict]
                                                finalUserDict[index].markRFIDOpen = true
                                                // setFinalUserDict(tempUserList)
                                            }}>Mark RFID</a></td>
                                            :
                                            null
                                    }
                                </tr>
                            </>
                        )
                    })}
                </tbody>
            </Table>
            {
                ((finalUserDict.length % count === 0) && (finalUserDict.length !== 0)) ? <Button variant="contained" color="primary" onClick={loadMore}>Load More</Button> : null
            }

        </div>
    )
}

export default GetUsers;