import React, { useState, useEffect } from "react";
import "./Slibrary.css";
import { Link } from "react-router-dom";
import Slibrary_Topicwise from "./Slibrary_Topicwise";
import Library_Toggler from "../Library_Toggler";
import Api from "../../../Api/axios";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

const Slibrary = () => {
  const [classId, setClassId] = useState();
  const [subjects, setSubjects] = useState([]);
  const [errMessage, setErrMessage] = useState();
  const [succMessage, setSuccMessage] = useState();
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  const [{ userDetails, lib_type }, dispatch] = useDataLayerValue();

  useEffect(() => {
    getSubjects();
    if (lib_type === "YIE_LIBRARY") {
      getClasses();
    }
  }, [lib_type]);

  const getSubjects = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let classIdTemp;
    let subjectsTemp = [];
    if (lib_type === "SCHOOL_LIBRARY") {
      try {
        const res = await Api.get("/live-class/library");
        classIdTemp = res.data[0].classroom_id;
        setClassId(classIdTemp);
      } catch (err) {
        console.log(err?.response?.data?.message);
      }

      await Api.get("/live-class/subject", {
        params: { classroom_id: classIdTemp },
      })
        .then(async (res) => {
          subjectsTemp = res.data;
        })
        .catch((err) => console.log(err?.response?.data?.message));

      await Promise.all(
        subjectsTemp.map(async (sub) => {
          try {
            let res = await Api.get("/live-class/get-progress", {
              params: { class: "Subject", id: sub._id },
            });
            let done = res.data.subtopics_done;
            let total = res.data.subtopics_total;
            let percentage = (done / total) * 100;

            if (isNaN(percentage)) {
              sub.completion = 0;
            } else {
              sub.completion = percentage.toFixed(0);
            }
          } catch (err) {
            console.log(err?.response?.data?.message);
          }
        })
      );
    }

    setSubjects(subjectsTemp);

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const getClasses = async () => {
    await Api.get("/live-class/inhouse-classes")
      .then((res) => {
        setClasses(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getInhouseClassSubjects = async (e) => {
    e.preventDefault();
    let subjectsTemp = [];
    await Api.get("/live-class/subject", {
      params: { classroom_id: selectedClass },
    })
      .then(async (res) => {
        subjectsTemp = res.data;
      })
      .catch((err) => console.log(err?.response?.data?.message));
    setSubjects(subjectsTemp);
  };

  return (
    <>
      <Library_Toggler />
      <div className="SLibrary">
        {lib_type === "YIE_LIBRARY" && (
          <>
            <form
              className="Tlib-selector"
              onSubmit={(e) => getInhouseClassSubjects(e)}
            >
              <select
                name="classroom_id"
                id="classroom_id"
                onChange={(e) => setSelectedClass(e.target.value)}
                required
              >
                <option value="">Select Class</option>
                {classes?.map((cl, i) => (
                  <option value={cl._id} key={i}>
                    {cl.class_name}
                  </option>
                ))}
              </select>
              <button type="submit">Get Class Progress</button>
            </form>
          </>
        )}
        <table id="library" className="center w-70">
          <thead>
            <tr>
              <th>Subject</th>
              {lib_type === "SCHOOL_LIBRARY" && <th>Completion</th>}
            </tr>
          </thead>
          <tbody>
            {subjects?.map((sub, i) => (
              <tr key={i}>
                <td>
                  <Link
                    to={`/library/topicwise/${classId || selectedClass}/${
                      sub?._id
                    }`}
                  >
                    {sub?.name}
                  </Link>
                </td>
                {lib_type === "SCHOOL_LIBRARY" && (
                  <td>
                    {sub.completion !== undefined
                      ? `${sub?.completion}%`
                      : "--"}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Slibrary;
