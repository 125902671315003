import React from 'react'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

const LiveClassDateTiming = ({everyday,setLiveClassName,liveClassName,
    setTime,setEveryday,setDate,setPageNo,createLiveClass
}) => {
    function backToPrev(){
        setPageNo(()=>2)
    }
    function validateUserSelection(){

    }

  return (
    <>
    <div className="shadow-lg p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
    <Stack className="row justify-content-start" direction="horizontal" >
                                <div className="col-12 col-sm-6" style={{ display: 'flex'}}>
                                    <Button variant="light" style={{ backgroundColor: "#FFF", color: "#6c757d" }} 
                                    // onhover={(e) => doNothing()} 
                                    onClick={()=>backToPrev()}
                                  >
                                        ← Previous
                                    </Button>
                                </div>
                                {/* <div className="col-12 col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }}
                                     onClick={ () =>validateUserSelection() }
                                         >
                                        Next
                                    </Button>
                                </div> */}
                            </Stack>
    
    
    <Form.Group  controlId="formFile" className="m-3">
        <Stack type="vartical" gap={4}>
        <InputGroup>
                <InputGroup.Text>
                    Live Class Name
                </InputGroup.Text>
                <Form.Control
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                    value={liveClassName}
                    onChange={(e) => { setLiveClassName(e.target.value) }}
                // placeholder="Enter Title"
                />
            </InputGroup>
            <InputGroup>
                <InputGroup.Text>Time</InputGroup.Text>
                <Form.Control
                    type="time"
                    aria-label="Time"
                    onChange={(e) =>{
                        // console.log("time ==> schickijng",e.target.value)
                        setTime(e.target.value)
                    }}
                />
            </InputGroup>

            <Form.Group className="mb-3" controlId="formBasicCheckbox" align="left">
                <Form.Check type="checkbox" label="Is this class for everyday?" onChange={(e) => { setEveryday(!everyday) }} />
            </Form.Group>

            {!everyday ?
                <InputGroup>
                    <InputGroup.Text>Date</InputGroup.Text>
                    <Form.Control
                        type="date"
                        aria-label="Date"
                        onChange={(e) => {
                            console.log("Date  ==> schickijng",e.target.value)
                            setDate(e.target.value)}
                        }
                    />
                </InputGroup>
                :
                <div>   </div>
            }
    <div className="col-12 col-sm-6" style={{ display: 'flex',alignSelf:"flex-end" , justifyContent: 'flex-end' }}>
            <Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }}
                onClick={ () =>createLiveClass()}
                    >
                Create Meet         
            </Button>
        </div>

        </Stack>
    </Form.Group>


    </div>
    
    </>


  )
}

export default LiveClassDateTiming