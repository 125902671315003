import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Api from '../../../Api/axios'
import {questionTypeFilter} from "./Data"
import QuestionLayout from "./QuestionLayout"

const EvaluatorQuestions = ({quesInfo,UserQuestionsWithAnswer,setQuestionsWithUser,userId,setEvaluvatedDetails}) => {
  // const{studentName,submissionDate,questions}=UserQuestionsWithAnswer
  console.log("UserQuestionsWithAnswer eval ques===>",UserQuestionsWithAnswer);
  console.log("quesInfo",quesInfo,"setQuestionsWithUser");
  // const[questionType,setQuestionType]=useState(questionTypeFilter[0]) setEvaluvatedDetails
  const[marksState,setmarksState]=useState({})
useEffect(()=>{
if(UserQuestionsWithAnswer){
let data=[...UserQuestionsWithAnswer.answers]
console.log("answers",data)
let obj={}
    data.forEach((x,i)=>{
      if(x.marks_awarded===null){
        obj[x._id]=""
        // obj.answer_id=x._id
      }else if(x.marks_awarded!==null){
      obj[x._id]=x.marks_awarded
      // obj.answer_id=x._id
    }
    })
console.log("obj ==>", obj)
setmarksState(()=>obj)
}

},[UserQuestionsWithAnswer])

function submitMarks(){
  // console.log("waiting",marksState)
  let numCheckingRegex=new RegExp(/^[0-9]+(.[0-9]+)?$/)
  let isError=false
  for(let [key,value] of Object.entries(marksState)){
    let test=numCheckingRegex.test(value)
    console.log(value, test)
    if(!test){
      isError=true
      alert("Number only allowed")
      break;
    }
}
if(!isError){
    console.log("waiting",marksState)
  console.log("succes posted",UserQuestionsWithAnswer._id)
  let answersArray=[]
for(let [key, value] of Object.entries(marksState)){
      let obj ={
        "answer_id":key,
        "marks_awarded":value
    }
    answersArray.push(obj)
}
let bodyObj={
  "response_id":UserQuestionsWithAnswer._id,
    "answers":answersArray
}
// console.log("succes posted",answersArray)

  Api.post("/evaluation",bodyObj).then((res)=>{
    console.log("res eval", res)
    if(res){
      setQuestionsWithUser(()=>{
        return {status:false,
        data:{}
        }
      })
      setEvaluvatedDetails((pre)=>{
        let updated={...pre}
        updated[userId]=true
        return updated
      })
    }
  })
}
}

  return (
    <div className="ps-5 pe-5 mt-4 d-flex align-items-center justify-content-center"
    >
       <Grid className="ps-4 pe-4 d-flex align-items-center justify-content-center"  container>
        <Grid className="ps-4 pe-4"  style={{margin:"auto"}}  item xs={12} md={12} lg={12}>
        <Grid container>
										<Grid item   xs={12} md={12} sm={12} lg={12}>
											<Stack  direction="row" >
											<div className="col-12 col-sm-6" style={{ display: 'flex'}}>
												<Button variant="light" style={{ backgroundColor: "#FFF", color: "#6c757d" }} onClick={(e) => {
													setQuestionsWithUser(()=>{
														return {
                              status:false,
                                data:{}
                              }
													})
												}} >
													← Previous
												</Button>
											</div>
											{/* <div className="col-12 col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }}   >
													Next
												</Button>
											</div> */}
											</Stack>
										</Grid>
									</Grid>

          {/* <Grid   container>
                      <Grid  item  xs={12} md={12} sm={12} lg={8}>
                         <Typography variant='h6' >Student Name</Typography>
                         </Grid>
                         <Grid  item xs={12} md={12} sm={12} lg={4}>
                         <Typography variant='h6' > {"studentName"}</Typography>
                         </Grid>

                         <Grid  item  xs={12} md={12} sm={12} lg={8}>
                         <Typography variant='h6' >Questions Assigned</Typography>
                         </Grid>
                         <Grid  item xs={12} md={12} sm={12} lg={4}>
                         <Typography variant='h6' > {"20"}</Typography>
                         </Grid>
                         <Grid  item  xs={12} md={12} sm={12} lg={8}>
                         <Typography variant='h6' >Questions Attended</Typography>
                         </Grid>
                         <Grid  item xs={12} md={12} sm={12} lg={4}>
                         <Typography variant='h6' > {"10"}</Typography>
                         </Grid>
          </Grid> */}
        </Grid>
        {/* <Grid className="mt-4"   item xs={12} md={12} lg={12}>
          <Grid container>
                         <Grid style={{margin:"auto"}} item xs={12} md={12} sm={12} lg={4}>
                         <FormControl fullWidth>
                                <InputLabel>Filter by</InputLabel>
                                <Select
                                label="Filter by"
                                labelId='Filter'
                                value={questionType}
                                onChange={(e)=>setQuestionType(e.target.value)}
                                >
                                {questionTypeFilter.map((x,i)=><MenuItem key={x} value={x}>{x}</MenuItem>)}
                                </Select>
                            </FormControl>
                         </Grid>
            </Grid>
        </Grid> */}
        <Grid className="ps-4 pe-4"  style={{margin:"auto"}}  item xs={12} md={12} lg={12}>   
        {Object.keys(marksState).length && UserQuestionsWithAnswer?.answers?.map((x,i)=><QuestionLayout i={i} quesInfo={quesInfo} setmarksState={setmarksState} key={x._id} id={x._id} marksState={marksState} questionWithAnswer={UserQuestionsWithAnswer?.answers[i]}/>)}
        </Grid>
        <Grid  className="d-flex align-items-center justify-content-center mt-5 mb-5"  item xs={12} md={12} sm={12} lg={12}>
            <Button className="h-100"  onClick={()=>submitMarks()} variant='contained'>{"Submit"}</Button>
        </Grid>
      </Grid>
    </div>
     
  )
}

export default EvaluatorQuestions
