import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Table } from "react-bootstrap";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Pie from "./Pie";
import { useParams } from "react-router";
import NotFound from "../NotFound/NotFound";

const UserLevelAttendanceView = (data) => {
    const urlParams = useParams();
    const [dateRange, setDateRange] = useState([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    const [dateRangeType, setDateRangeType] = useState("TODAY");
    const [dateRangeTypes, setDateRangeTypes] = useState([
        {
            value: "TODAY",
            text: "Today",
        },
        {
            value: "YESTERDAY",
            text: "Yesterday",
        },
        {
            value: "LAST_7_DAYS",
            text: "Last 7 Days",
        },
        {
            value: "LAST_30_DAYS",
            text: "Last 30 Days",
        },
        {
            value: "CUSTOM",
            text: "Custom",
        },
    ]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [overallPercent, setOverallPercent] = useState({});
    const [usersAttendance, setUsersAttendance] = useState([]);
    const [userId, setUserId] = useState(urlParams.id ? urlParams.id : (data.id ? data.id : (userDetails ? userDetails._id : undefined)));
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        let count = 0;
        let temp_dict = {};
        Api.get("/user/users-data", {
            params: {
                _id: userId,
            },
        }).then((res) => {
            console.log(res.data);
            setUserList(res.data);
            if (res.data.length === 0) {
                setUserId(undefined);
            }
        }).finally(() => {
            Api.post("/attendance/personal-of-user", {
                start_date: dateRange[0].getTime(),
                end_date: dateRange[1].getTime(),
                filters: {
                    user_id: userId,
                },
            }).then((res) => {
                // console.log(res.data);
                setUsersAttendance(res.data[userId] ? res.data[userId] : []);
                res.data[userId].forEach((item) => {
                    if (item.present) {
                        if (temp_dict["PRESENT"]) {
                            temp_dict["PRESENT"] += 1;
                        } else {
                            temp_dict["PRESENT"] = 1;
                        }
                    }
                    if (temp_dict["TOTAL"]) {
                        temp_dict["TOTAL"] += 1;
                    } else {
                        temp_dict["TOTAL"] = 1;
                    }
                });
            }).finally(() => {
                setOverallPercent(temp_dict);
            })
        });
    }, [dateRange, userDetails, userId]);
    return (
        <>
            {(userId) ? <div>
                <h1 className="pt-3" align="center" style={{ fontWeight: "bold" }}>Attendance View</h1>
                <Form className="row">
                    <Form.Group className="col-12" style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>User Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={userList[0]?.conventionalId + " - " + userList[0]?.name}
                            disabled={true}
                        >
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="col-12" style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>Date Range</Form.Label>
                        <Form.Control as="select" value={dateRangeType} onChange={(e) => {
                            if (e.target.value === "TODAY") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "YESTERDAY") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)]);
                            } else if (e.target.value === "LAST_7_DAYS") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "LAST_30_DAYS") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (29 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            } else if (e.target.value === "CUSTOM") {
                                setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                            }
                            setDateRangeType(e.target.value)
                        }}>
                            {dateRangeTypes.map((item) => {
                                return (
                                    <option value={item.value}>{item.text}</option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className="col-6" style={{
                        paddingLeft: "30px",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[0].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                            setDateRangeType("CUSTOM")
                            let temp_time_array = [new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]]
                            if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                                setDateRange([new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]])
                            } else {
                                alert("Start Date cannot be greater than End Date.")
                            }
                        }} />
                    </Form.Group>
                    <Form.Group className="col-6" style={{
                        paddingLeft: "10px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                    }}>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[1].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                            setDateRangeType("CUSTOM")
                            let temp_time_array = [dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]
                            if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                                setDateRange([dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))])
                            } else {
                                alert("End Date cannot be less than Start Date.")
                            }
                        }} />
                    </Form.Group>
                </Form>
                <Stack className="row" gap={3} direction="horizontal" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}>
                        <Pie percentage={
                            ((overallPercent["PRESENT"] ? overallPercent["PRESENT"] : (overallPercent["TOTAL"] ? 0 : 0)) / (overallPercent["TOTAL"] ? overallPercent["TOTAL"] : 1)) * 100
                        } colour="red" />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Your Attendance
                        </p>
                    </div>
                </Stack>
                <div style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>S.No</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>User Name</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Conventional ID</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Date</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersAttendance?.map((item, index) => {
                                console.log(item)
                                return (
                                    <tr>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{userList ? userList[0] ? userList[0].name : "" : ""}</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{userList ? userList[0] ? userList[0].conventionalId : "" : ""}</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{new Date(new Date(new Date(item.date).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toLocaleDateString()}</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{item.present ? "PRESENT" : "ABSENT"}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
                :
                <NotFound />
            }
        </>
    )
}

export default UserLevelAttendanceView;