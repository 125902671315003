import { React, useState, useEffect } from 'react';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Api from "../../Api/axios";
import { Multiselect } from "multiselect-react-dropdown";
import { useParams } from 'react-router-dom';

const EditUser = () => {
    const urlParams = useParams();
    const [userId, setUserId] = useState(urlParams?.id)
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [userTypeList, setUserTypeList] = useState([
        ['DISTRICT', 'District'],
        ['SCHOOL_ADMIN', 'School Admin'],
        ['TEACHER', 'Teacher'],
        ['STUDENT', 'Student'],
    ]);
    const [currentUserTypeIndex, setCurrentUserTypeIndex] = useState(
        userTypeList.findIndex((item) => item[0] === userDetails?.userType)
    );
    const [userCreateType, setUserCreateType] = useState();
    const [userCreateName, setUserCreateName] = useState("");
    const [userCreateDesignation, setUserCreateDesignation] = useState();
    const [userCreateEmail, setUserCreateEmail] = useState("");
    const [userCreatePassword, setUserCreatePassword] = useState("");
    const [userCreateConfirmPassword, setUserCreateConfirmPassword] = useState("");
    const [userCreatePhone, setUserCreatePhone] = useState("");
    const [userCreateAddress, setUserCreateAddress] = useState("");
    const [userCreateDob, setUserCreateDob] = useState("");
    const [userCreateGender, setUserCreateGender] = useState("");
    const [userCreateReligion, setUserCreateReligion] = useState("");
    const [userCreateCaste, setUserCreateCaste] = useState("");
    const [userCreateMotherTongue, setUserCreateMotherTongue] = useState("");
    const [userCreateDisability, setUserCreateDisability] = useState("");
    const [userCreateDistrict, setUserCreateDistrict] = useState("");
    const [userCreateSchool, setUserCreateSchool] = useState("");
    const [userCreateClass, setUserCreateClass] = useState("");
    const [userCreateAdmissionNumber, setUserCreateAdmissionNumber] = useState("");
    const [userCreateFatherName, setUserCreateFatherName] = useState("");
    const [userCreateMotherName, setUserCreateMotherName] = useState("");
    const [userCreateParentsAddress, setUserCreateParentsAddress] = useState("");
    const [userCreateLocality, setUserCreateLocality] = useState("");
    const [userCreateBPL, setUserCreateBPL] = useState("No");
    const [userCreateStudentType, setUserCreateStudentType] = useState("");
    const [userCreateMediumOfInstruction, setUserCreateMediumOfInstruction] = useState("");
    const [userCreateAadharNumber, setUserCreateAadharNumber] = useState("");
    const [userCreateEyeScreening, setUserCreateEyeScreening] = useState("");
    const [userCreateTeacherCatagory, setUserCreateTeacherCatagory] = useState("");
    const [userCreateClassTaughtType, setUserCreateClassTaughtType] = useState("");
    const [userCreateClassTaught, setUserCreateClassTaught] = useState([]);
    const [userCreateSubjectTaught, setUserCreateSubjectTaught] = useState([]);
    const [userCreateAppointmentDate, setUserCreateAppointmentDate] = useState("");
    const [userCreatePlusTwoStream, setUserCreatePlusTwoStream] = useState("");
    const [userCreateGraduationStream, setUserCreateGraduationStream] = useState("");
    const [userCreatePostGraduationStream, setUserCreatePostGraduationStream] = useState("");
    const [userCreatePhdSubject, setUserCreatePhdSubject] = useState("");
    const [userCreateProfessionalQualification, setUserCreateProfessionalQualification] = useState("");
    const [userCreateMPhilStream, setUserCreateMPhilStream] = useState("");
    const [districtList, setDistrictList] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [studentTypeList, setStudentTypeList] = useState([]);
    const [classTaughtTypeList, setClassTaughtTypeList] = useState([]);
    const [sucess, setSucess] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [conventioanlID, setUserConventioanlID] = useState("");
    useEffect(() => {
        Api.get("user/get_class_taught_type").then((res) => {
            setClassTaughtTypeList(["None", ...Object.keys(res.data)]);
        });
        Api.get("preconfig-entites/subjects").then((res) => {
            res.data.sort((a, b) => a.name.localeCompare(b.name));
            setSubjectList(res.data);
        })
        Api.get("user/get-user-by-id/?id=" + userId).then((res) => {
            console.log(userTypeList.findIndex((item) => item[0] === res.data.userType), userTypeList.findIndex((item) => item[0] === userDetails?.userType))
            if ((userDetails?.userType === "DISTRICT" || userDetails?.userType === "SCHOOL_ADMIN") &&
                userTypeList.findIndex((item) => item[0] === res.data.userType) > userTypeList.findIndex((item) => item[0] === userDetails?.userType)
            ) {
                console.log("can edit");
                setCanEdit(true);
                setUserConventioanlID(res.data?.conventionalId);

                Api.post("live-class/get-all-classes", {
                    data: res.data?.classes
                }).then((resp) => {
                    console.log(resp.data);
                    setUserCreateClass(resp.data);
                    setUserCreateClassTaught(resp.data);
                })
                setUserCreateSubjectTaught(res.data?.subject_taught);
                setUserCreateEmail(res.data?.email)
                setUserCreateName(res.data?.name)
                setUserCreatePassword(res.data?.password)
                setUserCreatePhone(res.data?.contactNumber)
                setUserCreateSchool(res.data?.schoolId)
                setUserCreateDistrict(res.data?.districtId)
                setUserCreateType(res.data?.userType)
                setUserCreateGender(res.data?.gender)
                setUserCreateDesignation(res.data?.displayUserType)
                setUserCreateTeacherCatagory(res.data?.Teacher_category)
                setUserCreateClassTaughtType(res.data?.class_taught_type)
                setUserCreateCaste(res.data?.Caste)
                setUserCreateDisability(res.data?.disability_type)
                setUserCreateDob(res.data?.DOB)
                setUserCreateAddress(res.data?.permanent_address)
                setUserCreatePlusTwoStream(res.data?.plus_two_stream)
                setUserCreateGraduationStream(res.data?.graduation_stream)
                setUserCreatePostGraduationStream(res.data?.postgraduation_stream)
                setUserCreatePhdSubject(res.data?.Phd)
                setUserCreateMPhilStream(res.data?.m_phil)
                setUserCreateProfessionalQualification(res.data?.Professional_qualification)
                setUserCreateAppointmentDate(res.data?.first_appointment_date)
                setUserCreateAdmissionNumber(res.data?.admission_number)
                setUserCreateAadharNumber(res.data?.aadhar_number)
                setUserCreateFatherName(res.data?.father_name)
                setUserCreateMotherName(res.data?.mother_name)
                setUserCreateParentsAddress(res.data?.student_parents_address)
                setUserCreateLocality(res.data?.name_of_habitation_or_locality)
                setUserCreateMotherTongue(res.data?.mother_tongue)
                setUserCreateReligion(res.data?.Religion)
                setUserCreateCaste(res.data?.social_category)
                setUserCreateBPL(res.data?.is_student_bpl)
                setUserCreateStudentType(res.data?.student_opted)
                setUserCreateMediumOfInstruction(res.data?.medium_of_instruction)
            } else {
                console.log("can not edit");
                setCanEdit(false);
            }
        });
    }, [userDetails, userId, currentUserTypeIndex, userTypeList]);
    useEffect(() => {
        setCurrentUserTypeIndex(
            userTypeList.findIndex((item) => item[0] === userDetails?.userType)
        );
        setUserCreateDistrict(userDetails?.districtId);
        if (userDetails?.schoolId) {
            // setUserCreateSchool(userDetails?.schoolId);
        } else if (userDetails?.districtId) {
            setUserCreateDistrict(userDetails?.districtId);
        }
    }, [userDetails]);
    useEffect(() => {
        if (userCreateSchool) {
            dispatch({
                type: "SET_LOADING",
                loading: true,
            });
            Api.get("school-entity/all-classes?school_id=" + userCreateSchool).then((res) => {
                setClassList(res.data);
                if (res.data.length > 0 && userCreateType !== "TEACHER") {
                    setUserCreateClass(res.data[0]._id);
                }
            }).finally(() => {
                dispatch({
                    type: "SET_LOADING",
                    loading: false,
                });
            });
        }
    }, [userCreateSchool, userCreateType]);
    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        Api.get("school-entity/school").then((res) => {
            if (userDetails?.userType === "DISTRICT") {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setSchoolList(res.data);
                if (res.data.length > 0) {
                    // setUserCreateSchool(res.data[0]._id);
                }
            } else {
                setSchoolList(res.data._id)
            }
        }
        ).finally(() => {
            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        });
    }, [userCreateDistrict]);
    useEffect(() => {
        setUserCreateType(userTypeList[currentUserTypeIndex + 1][0]);
        setUserCreateDesignation(userTypeList[currentUserTypeIndex + 1][0]);
    }, [currentUserTypeIndex]);
    const [error, setError] = useState("");
    const handleUserCreate = () => {
        console.log("mai to chala");
        if (userCreateName === "") {
            setError("Please enter a name");
            setSucess(false);
            return;
        }
        if (userCreateType === "STUDENT" && !userCreateClass) {
            setError("Please select a class");
            setSucess(false);
            return;
        }
        if (userCreateType === "TEACHER" && !userCreateClassTaught.length) {
            setError("Please select a class taught");
            setSucess(false);
            return;
        }
        if (userCreateType === "TEACHER" && !userCreateSubjectTaught) {
            setError("Please select a subject taught");
            setSucess(false);
            return;
        }
        var data = {
            // email: userCreateEmail,
            name: userCreateName,
            // contactNumber: userCreatePhone,
            schoolId: userCreateSchool,
            districtId: userCreateDistrict,
            // userType: userCreateType,
            gender: userCreateGender,
            displayUserType: userCreateDesignation,
            Teacher_category: userCreateTeacherCatagory,
            class_taught_type: userCreateClassTaughtType,
            Caste: userCreateCaste,
            disability_type: userCreateDisability,
            DOB: userCreateDob,
            permanent_address: userCreateAddress,
            plus_two_stream: userCreatePlusTwoStream,
            graduation_stream: userCreateGraduationStream,
            postgraduation_stream: userCreatePostGraduationStream,
            Phd: userCreatePhdSubject,
            m_phil: userCreateMPhilStream,
            Professional_qualification: userCreateProfessionalQualification,
            first_appointment_date: userCreateAppointmentDate,
            admission_number: userCreateAdmissionNumber,
            aadhar_number: userCreateAadharNumber,
            father_name: userCreateFatherName,
            mother_name: userCreateMotherName,
            student_parents_address: userCreateParentsAddress,
            name_of_habitation_or_locality: userCreateLocality,
            mother_tongue: userCreateMotherTongue,
            Religion: userCreateReligion,
            social_category: userCreateCaste,
            is_student_bpl: userCreateBPL,
            student_opted: userCreateStudentType,
            medium_of_instruction: userCreateMediumOfInstruction,
        }
        if (userCreateType === "STUDENT") {
            console.log(userCreateClass);
            if (typeof userCreateClass === 'string' || userCreateClass instanceof String) {
                data[`classes`] = [userCreateClass];
            } else {
                data[`classes`] = [userCreateClassTaught.map((item) => item._id)[0]];
            }
        }
        if (userCreateType === "TEACHER") {
            data[`classes`] = userCreateClassTaught.map((item) => item._id);
        }
        if (userCreateType === "TEACHER" && userCreateSubjectTaught.length > 0) {
            data[`subject_taught`] = userCreateSubjectTaught;
        }
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        Api.put("user/?id=" + userId, data).then((res) => {
            setSucess(true);
            setError(`Updated User Sucessfully.`);
        }).catch((err) => {
            setError(err.message);
            setSucess(false);
        }).finally(() => {
            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        }
        );

    }
    return (
        <div style={{ backgroundColor: "#fff" }} align="center">
            {canEdit ?
                <div className="p-4" align="center">
                    <h1 style={{
                        fontWeight: "bold",
                    }}>Edit User</h1>
                    <p className="p-2" style={{ color: sucess ? 'green' : 'red' }}>{error}</p>
                    <Form className="row" align="left">
                        <Form.Group className="mb-3 col-12" controlId="conventioanlId">
                            <Form.Label>Conventional Id</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Conventional Id"
                                value={conventioanlID}
                                disabled
                            />
                        </Form.Group>
                        <h5 className="mt-2" style={{ fontWeight: "bold" }} align="left">Basic Details</h5>
                        <hr className="solid" style={{ marginTop: "1px" }} />
                        {/* Name */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name"
                                value={userCreateName}
                                onChange={(e) => { setUserCreateName(e.target.value) }}
                            />
                        </Form.Group>
                        {/* Designation */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="designation">
                            <Form.Label>Designation</Form.Label>
                            <Form.Control type="text" placeholder="Enter designation"
                                value={userCreateDesignation}
                                onChange={(e) => { setUserCreateDesignation(e.target.value) }}
                            />
                        </Form.Group>
                        {/* Email */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email"
                                value={userCreateEmail}
                                onChange={(e) => { setUserCreateEmail(e.target.value) }}
                                disabled
                            />
                        </Form.Group>
                        {/* Phone */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="phone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="text" placeholder="Enter phone"
                                value={userCreatePhone}
                                onChange={(e) => { setUserCreatePhone(e.target.value) }}
                            />
                        </Form.Group>
                        {/* Address */}
                        <Form.Group className="mb-3 col-12" controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control as="textarea" placeholder="Enter address"
                                value={userCreateAddress}
                                onChange={(e) => { setUserCreateAddress(e.target.value) }}
                            />
                        </Form.Group>
                        {/* DOB */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="dob">
                            <Form.Label>Date of Birth</Form.Label>
                            <Form.Control type="date" placeholder="Enter date of birth"
                                value={userCreateDob}
                                onChange={(e) => { setUserCreateDob(e.target.value) }}
                            />
                        </Form.Group>
                        {/* Gender */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="gender">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select
                                value={userCreateGender}
                                onChange={(e) => setUserCreateGender(e.target.value)}
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        {/* Religion */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="religion">
                            <Form.Label>Religion</Form.Label>
                            <Form.Select
                                value={userCreateReligion}
                                onChange={(e) => setUserCreateReligion(e.target.value)}
                            >
                                <option value="">Select Religion</option>
                                <option value="Hindu">Hindu</option>
                                <option value="Muslim">Muslim</option>
                                <option value="Christian">Christian</option>
                                <option value="Sikh">Sikh</option>
                                <option value="Buddhist">Buddhist</option>
                                <option value="Jain">Jain</option>
                                <option value="Parsi">Parsi</option>
                                <option value="Jewish">Jewish</option>
                                <option value="Bahai">Bahai</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        {/* Caste */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="caste">
                            <Form.Label>Caste</Form.Label>
                            <Form.Select
                                value={userCreateCaste}
                                onChange={(e) => setUserCreateCaste(e.target.value)}
                            >
                                <option value="">Select Caste</option>
                                <option value="General">General</option>
                                <option value="OBC">OBC</option>
                                <option value="SC">SC</option>
                                <option value="ST">ST</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        {/* Mother TOngue */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="motherTongue">
                            <Form.Label>Mother Tongue</Form.Label>
                            <Form.Select
                                value={userCreateMotherTongue}
                                onChange={(e) => setUserCreateMotherTongue(e.target.value)}
                            >
                                <option value="">Select Mother Tongue</option>
                                <option value="Hindi">Hindi</option>
                                <option value="English">English</option>
                                <option value="Odia">Odia</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        {/* Disability */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="disability">
                            <Form.Label>Disability</Form.Label>
                            <Form.Select
                                value={userCreateDisability}
                                onChange={(e) => setUserCreateDisability(e.target.value)}
                            >
                                <option value="">Select Disability</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                        <h5 className="mt-2" style={{ fontWeight: "bold" }} align="left">Type Specific Details</h5>
                        <hr className="solid" style={{ marginTop: "1px" }} />
                        {/* School, Class, AdmissionNumber, FatherName, MotherName, ParentsAddress, Locality, BPL, StudentType, MediumOfInstruction, AadharNumber, EyeScreening, TeacherCatagory, ClassTaughtType, ClassTaught, SubjectTaught, AppointmentDate, PlusTwoStream, GraduationStream, PostGraduationStream, PhdSubject, ProfessionalQualification, MPhilStream */}
                        {/* Admission Number */}
                        {userCreateType === "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="admissionNumber">
                                <Form.Label>Admission Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Admission Number"
                                    value={userCreateAdmissionNumber}
                                    onChange={(e) => setUserCreateAdmissionNumber(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* Father Name */}
                        {userCreateType === "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="fatherName">
                                <Form.Label>Father Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Father's Name"
                                    value={userCreateFatherName}
                                    onChange={(e) => setUserCreateFatherName(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* Mother Name */}
                        {userCreateType === "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="motherName">
                                <Form.Label>Mother Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Mother's Name"
                                    value={userCreateMotherName}
                                    onChange={(e) => setUserCreateMotherName(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* Parents Address */}
                        {userCreateType === "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="parentsAddress">
                                <Form.Label>Parents Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Parents Address"
                                    value={userCreateParentsAddress}
                                    onChange={(e) => setUserCreateParentsAddress(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* Locality */}
                        {userCreateType === "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="locality">
                                <Form.Label>Locality</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Locality"
                                    value={userCreateLocality}
                                    onChange={(e) => setUserCreateLocality(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* BPL */}
                        {userCreateType === "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="bpl">
                                <Form.Label>Below Poverty Line</Form.Label>
                                <Form.Select
                                    value={userCreateBPL}
                                    onChange={(e) => setUserCreateBPL(e.target.value)}
                                >
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Form.Select>
                            </Form.Group>
                        }
                        {/* Student Type */}
                        {userCreateType === "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="studentType">
                                <Form.Label>Student Type</Form.Label>
                                <Form.Select
                                    value={userCreateStudentType}
                                    onChange={(e) => setUserCreateStudentType(e.target.value)}
                                >
                                    <option value="">Select Type</option>
                                    <option value="Day Boarder">Day Boarder</option>
                                    <option value="Hosteller">Hosteller</option>
                                </Form.Select>
                            </Form.Group>
                        }
                        {/* Medium of Instruction */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="mediumOfInstruction">
                            <Form.Label>Medium of Instruction</Form.Label>
                            <Form.Select
                                value={userCreateMediumOfInstruction}
                                onChange={(e) => setUserCreateMediumOfInstruction(e.target.value)}
                            >
                                <option value="">Select Medium</option>
                                <option value="English">English</option>
                                <option value="Hindi">Hindi</option>
                                <option value="Bengali">Odia</option>
                                <option value="Other">Other</option>
                            </Form.Select>
                        </Form.Group>
                        {/* Aadhar Number */}
                        <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="aadharNumber">
                            <Form.Label>Aadhar Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Aadhar Number"
                                value={userCreateAadharNumber}
                                onChange={(e) => setUserCreateAadharNumber(e.target.value)}
                            />
                        </Form.Group>
                        {/* PlusTwo Stream */}
                        {userCreateType !== "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="plusTwoStream">
                                <Form.Label>PlusTwo Stream</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter PlusTwo Stream"
                                    value={userCreatePlusTwoStream}
                                    onChange={(e) => setUserCreatePlusTwoStream(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* Graduation Stream */}
                        {userCreateType !== "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="graduationStream">
                                <Form.Label>Graduation Stream</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Graduation Stream"
                                    value={userCreateGraduationStream}
                                    onChange={(e) => setUserCreateGraduationStream(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* Post Graduation Stream */}
                        {userCreateType !== "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="postGraduationStream">
                                <Form.Label>Post Graduation Stream</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Post Graduation Stream"
                                    value={userCreatePostGraduationStream}
                                    onChange={(e) => setUserCreatePostGraduationStream(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* PHd Subject */}
                        {userCreateType !== "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="phdSubject">
                                <Form.Label>PHd Subject</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter PHd Subject"
                                    value={userCreatePhdSubject}
                                    onChange={(e) => setUserCreatePhdSubject(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* MPhill */}
                        {userCreateType !== "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="mPhill">
                                <Form.Label>MPhill Stream</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter MPhill Stream"
                                    value={userCreateMPhilStream}
                                    onChange={(e) => setUserCreateMPhilStream(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* Professional Qualification */}
                        {userCreateType !== "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="professionalQualification">
                                <Form.Label>Professional Qualification</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Professional Qualification"
                                    value={userCreateProfessionalQualification}
                                    onChange={(e) => setUserCreateProfessionalQualification(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {/* Appointment Date */}
                        {userCreateType !== "STUDENT" &&
                            <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="appointmentDate">
                                <Form.Label>Appointment Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Enter Appointment Date"
                                    value={userCreateAppointmentDate}
                                    onChange={(e) => setUserCreateAppointmentDate(e.target.value)}
                                />
                            </Form.Group>
                        }
                        {(userDetails?.userType === "DISTRICT" || userDetails?.userType === "SCHOOL_ADMIN") && (userCreateType === "TEACHER" || userCreateType === "STUDENT") && <>
                            <h5 className="mt-2" style={{ fontWeight: "bold" }} align="left">Lifecycle Details</h5>
                            <hr className="solid" style={{ marginTop: "1px" }} />

                            {/* School
                            {!(userDetails?.schoolId) &&
                                <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="school">
                                    <Form.Label>School</Form.Label>
                                    <Form.Select
                                        value={userCreateSchool}
                                        onChange={(e) => setUserCreateSchool(e.target.value)}
                                    >
                                        {schoolList?.map((school) => {
                                            return (
                                                <option value={school._id}>{school.name}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            } */}
                            {/* Class Taught Type */}
                            {
                                userCreateType === "TEACHER" &&
                                <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="classTaughtType">
                                    <Form.Label>Class Taught Type</Form.Label>
                                    <Form.Select
                                        value={userCreateClassTaughtType}
                                        onChange={(e) => setUserCreateClassTaughtType(e.target.value)}
                                    >
                                        {
                                            classTaughtTypeList?.map((item, index) => {
                                                return <option key={item} value={item}>{item}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            }
                            {/* Class */}
                            {userCreateType === "TEACHER" ?
                                <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="class">
                                    <Form.Label>Class</Form.Label>
                                    <Multiselect
                                        showArrow
                                        options={classList}
                                        displayValue="class_name_section"
                                        groupBy="class_name"
                                        showCheckbox={true}
                                        selectedValues={userCreateClassTaught}
                                        onSelect={(selectedList, selectedItem) => { setUserCreateClassTaught(selectedList) }}
                                        onRemove={(selectedList, selectedItem) => { setUserCreateClassTaught(selectedList) }}
                                        style={{
                                            marginTop: "0",
                                        }}
                                    />
                                </Form.Group>
                                : userCreateType === "STUDENT" &&
                                <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="class">
                                    <Form.Label>Class</Form.Label>
                                    <Form.Select
                                        value={userCreateClass}
                                        onChange={(e) => setUserCreateClass(e.target.value)}
                                    >
                                        {classList?.map((classs) => {
                                            return (
                                                <option value={classs._id}>{classs.class_name_section}</option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            }
                            {/* Subject Taught */}
                            {(userCreateType === "STUDENT" || userCreateType === "TEACHER") &&
                                <Form.Group className="mb-3 col-12 col-sm-6 col-lg-4" controlId="class">
                                    <Form.Label>Subjects</Form.Label>
                                    <Multiselect
                                        showArrow
                                        options={subjectList}
                                        displayValue="name"
                                        showCheckbox={true}
                                        selectedValues={userCreateSubjectTaught}
                                        onSelect={(selectedList, selectedItem) => { setUserCreateSubjectTaught(selectedList) }}
                                        onRemove={(selectedList, selectedItem) => { setUserCreateSubjectTaught(selectedList) }}
                                        style={{
                                            marginTop: "0",
                                        }}
                                    />
                                </Form.Group>
                            }
                        </>
                        }
                    </Form>
                    <Button size="lg" className="btn btn-default" align="center" type="submit" style={{ width: '100%' }} onClick={handleUserCreate} >
                        Edit User
                    </Button>
                </div>
                :
                <div className="p-4 col-12" style={{ backgroundColor: "#FFF", minHeight: "94vh" }} align="center">
                    <img src={`${process.env.PUBLIC_URL}/Images/failure.png`} style={{ width: '100px' }} alt="" />
                    <h3 className="m-4">Sorry, you don't have permission to edit this user.</h3>
                    <Button variant="outline-secondary" size="lg" onClick={(e) => { window.location.href = window.location.origin; }} >
                        Go to Home
                    </Button>
                </div>
            }
        </div>
    )
}

export default EditUser;