import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Table } from "react-bootstrap";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import Pie from "./Pie";
import "./Pagination.css";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DefaultStartDateTiming,DefaultEndDateTiming ,FilterDateRanges,FilterChartTypeList,
    FilterUserTypes,userTypeBody,overAllRatio,ZeroPrefixFormat,DateFormater,
    yesterdayStartDate,yesterdayEndDate,Last_7_days_Start_Date,Last_7_days_End_Date,Last_30_days_Start_Date,
    Last_30_days_End_Date,OldDatenumToNewDateNumConversion,DateTonumConversion,
    makeUserTypeCumOverallRatio,initialUserTypeRatio,StartDateTonumConversion,
    EndDateTonumConversion
} from "./Constants";//userTypeBody

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
let count =0

const AssessmentReport = () => {
    const [dateRange, setDateRange] = useState(["",""]);
    const [dateRangeType, setDateRangeType] = useState(FilterDateRanges[0].value);
    const [dateRangeTypes, setDateRangeTypes] = useState(FilterDateRanges);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [schoolList, setSchoolList] = useState([]);
    const [attendanceList, setAttendanceList] = useState({});
    const [schoolWiseInfo, setSchoolWiseInfo] = useState({});
    const [schoolWisePercentage, setSchoolWisePercentage] = useState({});
    const [overallPercent, setOverallPercent] = useState({});
    const [schoolNames, setSchoolNames] = useState([]);
    const [userTypes, setUserTypes] = useState([FilterUserTypes]);
    const [chartTypeList, setChartTypeList] = useState(FilterChartTypeList);
    const [chartType, setChartType] = useState("OVERALL");
    const [userType, setUserType] = useState("STUDENT");
    const [userList, setUserList] = useState([]);
    const [usersAttendance, setUsersAttendance] = useState({});
    const [maxWorkingDays, setMaxWorkingDays] = useState(0);
    const[isAllFetched,setIsAllFetched]=useState(false)
    useEffect(() => {
        Api.get("/school-entity/school").then((res) => {
            res.data.sort((a, b) => a.name.localeCompare(b.name));
            setSchoolList(res.data);
            setSchoolNames(res.data.map((school) => school.name));
        });
    }, [userDetails])

    const [schoolWisePageCount, setSchoolWisePageCount] = useState(1);
    const [overallSubmission,setOverallSubmission]=useState({})
    const [overallAssessmentSubBySchool,setOverallAssessmentSubBySchool]=useState({})
    const [overallNotSubmissionBySchool,setOverallNotSubmissionBySchool]=useState({})
    const[filterOverallAssessmentSubBySchool,setFilterOverallAssessmentSubBySchool]=useState({})
    const[filterOverallNotSubmissionBySchool,setFilterOverallNotSubmissionBySchool]=useState({})
    const[isDateChanging,setIsDateChanging]=useState(false)
    const[overAllratio,setOverAllratio]=useState(0)
    const[overAllUserTyperatio,setOverAllUserTyperatio]=useState(initialUserTypeRatio)
    function IsArrayWithKeys(Arr){
       return Array.isArray(Arr)&&Arr.length!==0
    }
    function ArrayFromObj(obj){
        return Object.keys(obj)
     }

    useEffect(()=>{
        if(dateRangeType==="OVERALL"){
            const[userTypeRatioArr,overallRatio]=makeUserTypeCumOverallRatio(overallSubmission,initialUserTypeRatio)
                    setOverAllUserTyperatio(()=>userTypeRatioArr)
                    setOverAllratio(()=>overallRatio)
                    setFilterOverallAssessmentSubBySchool(()=>overallAssessmentSubBySchool)
                    setFilterOverallNotSubmissionBySchool(()=>overallNotSubmissionBySchool)

                }


    },[dateRangeType,overallSubmission]) 
useEffect(()=>{
   console.log( overAllUserTyperatio)
},[overAllUserTyperatio])
useEffect(()=>{
    // async functio
    //console.log("overallNotSubmissionBySchool ==> ",overallNotSubmissionBySchool);
    //console.log("overallAssessmentSubBySchool ==> ",overallAssessmentSubBySchool);
    //console.log("overallSubmission ==> ",overallSubmission);
    // //console.log("testing dateRange",dateRange,dateRangeType);
   
     if(dateRangeType!=="OVERALL"){
        let Overall=ArrayFromObj(overallSubmission)
        let OverallAssSubBySchool=ArrayFromObj(overallAssessmentSubBySchool)
        let OverallAssNotSubBySchool=ArrayFromObj(overallNotSubmissionBySchool)
        let totalOverall=0
        let totalOverallSubmission=0
        let updated={...overAllUserTyperatio}
        let updatedUserWise={}
        Overall.map((x)=>{
            console.log("overallSubmission[x]",overallSubmission[x]);
            let filtered=ArrayFromObj(overallSubmission[x].submissionByDates).filter((y)=>{
                // let dateNumUpdated=OldDatenumToNewDateNumConversion(y)
                let dateNumUpdated=parseInt(y)
                let start=StartDateTonumConversion(dateRange[0])
                let end=EndDateTonumConversion(dateRange[1])
                console.log("start",start,"dateNumUpdated",dateNumUpdated,"y",y,"end",end);
                if(dateNumUpdated>=start&&dateNumUpdated<=end){
                    console.log("filtering ",x)
                    return true
                }else{
                    return false
                }
                // overallSubmission[x].submissionByDates[]
            })
            console.log("filtered for ==> x",x,filtered)
            filtered.map((z)=>{
                if(updatedUserWise[x]){
                    // //////console.log("if")
                    updatedUserWise[x]={
                        ...updatedUserWise[x],
                        submission:updatedUserWise[x].submission+overallSubmission[x].submissionByDates[z].submission,
                        total:updatedUserWise[x].total+overallSubmission[x].submissionByDates[z].total
                    }
                }else{
                    // //////console.log("else ")
                    updatedUserWise[x]={
                        submission:overallSubmission[x].submissionByDates[z].submission,
                        total:overallSubmission[x].submissionByDates[z].total
                    }
                }
                
            })

            // //////console.log("test updatedUserWise ==> ",updatedUserWise);
            // totalOverallSubmission+=overallSubmission[x].submission
          
            // updated[x]=overAllRatio(overallSubmission[x].total,overallSubmission[x].submission)
        })


        // setOverAllUserTyperatio(()=>updated)
        //console.log("out side test updatedUserWise ==> ",updatedUserWise);//testb
        // //console.log("updatedUserWise ==>",updatedUserWise,"checking ",overallAssessmentSubBySchool)
        //schoolWise
        let updatedSchoolSub={}
        let updatedSchoolNotSub={}
        // //console.log("dateRange 0",dateRange[0]);
        // //console.log("dateRange 1",dateRange[1]);
        // //console.log("test b",overallAssessmentSubBySchool)
        ArrayFromObj(overallAssessmentSubBySchool).map((x)=>{
            updatedSchoolSub[x]={}
            ArrayFromObj(overallAssessmentSubBySchool[x]).map((schID)=>{
                let currTotal=0
                let currSub=0
                // //console.log("test 2222",overallAssessmentSubBySchool[x][schID].SchoolSubmissionByDates);
                ArrayFromObj(overallAssessmentSubBySchool[x][schID].SchoolSubmissionByDates).map((createdDate)=>{
                    // let dateNumUpdated=OldDatenumToNewDateNumConversion(createdDate)
                    let dateNumUpdated=parseInt(createdDate)
                    let start=StartDateTonumConversion(dateRange[0])
                    let end=EndDateTonumConversion(dateRange[1])
                    //console.log("creted ",createdDate ,"start",start,"end",end );
                    //console.log("dateNumUpdated ",dateNumUpdated);
                    if(dateNumUpdated>=start&&dateNumUpdated<=end){
                        // //console.log("overallAssessmentSubBySchool",overallAssessmentSubBySchool);
                        // //console.log("overallAssessmentSubBySchool[x][schID][createdDate]",overallAssessmentSubBySchool[x][schID].SchoolSubmissionByDates[createdDate]);
                        currTotal+=overallAssessmentSubBySchool[x][schID].SchoolSubmissionByDates[createdDate].total
                        currSub+=overallAssessmentSubBySchool[x][schID].SchoolSubmissionByDates[createdDate].submission
                        // return true
                    }else{
                        // //console.log("not working ",dateNumUpdated,start,end);
                        // return false
                    }
                })
                updatedSchoolSub[x][schID]={
                    submission:currSub,
                    total:currTotal
                }
            })
            // let filtered=ArrayFromObj(overallAssessmentSubBySchool[x].submissionByDates).filter((y)=>{

            // })
        })
        ArrayFromObj(overallNotSubmissionBySchool).map((x)=>{
            updatedSchoolNotSub[x]={}
            ArrayFromObj(overallNotSubmissionBySchool[x]).map((schID)=>{
                let currTotal=0
                let currNotSub=0
                ArrayFromObj(overallNotSubmissionBySchool[x][schID].notSubmissionByDates).map((createdDate)=>{
                    // let dateNumUpdated=OldDatenumToNewDateNumConversion(createdDate)
                    let dateNumUpdated=parseInt(createdDate)
                    let start=StartDateTonumConversion(dateRange[0])
                    let end=EndDateTonumConversion(dateRange[1])
                 
                    if(dateNumUpdated>=start&&dateNumUpdated<=end){
                        if(x=="SCHOOL_ADMIN"){
                            // //console.log(" working dateNumUpdated",dateNumUpdated,"start",start,"end",end);
                        }
                        currTotal+=overallNotSubmissionBySchool[x][schID].notSubmissionByDates[createdDate].total
                        currNotSub+=overallNotSubmissionBySchool[x][schID].notSubmissionByDates[createdDate].notSubmission
                    }else{
                        if(x=="SCHOOL_ADMIN"){
                            // //console.log("not  working dateNumUpdated",dateNumUpdated,"start",start,"end",end);
                        }
                        // //console.log("not working not required ",dateNumUpdated,start,end);
                    }
                })

                updatedSchoolNotSub[x][schID]={
                    notSubmission:currNotSub,
                    total:currTotal
                }
            })
            // let filtered=ArrayFromObj(overallAssessmentSubBySchool[x].submissionByDates).filter((y)=>{

            // })
        })  

        console.log("updatedSchoolSub ======> ",updatedSchoolSub ,"updatedSchoolNotSub ==> ",updatedSchoolNotSub)
        console.log("updatedUserWise======>",updatedUserWise);
        const[updatedUserTypeRatioArr,updatedOverallRatio]=makeUserTypeCumOverallRatio(updatedUserWise,initialUserTypeRatio)
        setOverAllUserTyperatio(()=>updatedUserTypeRatioArr)
        setOverAllratio(()=>updatedOverallRatio) 
        setFilterOverallAssessmentSubBySchool(()=>updatedSchoolSub)
        setFilterOverallNotSubmissionBySchool(()=>updatedSchoolNotSub)
        //////console.log("updatedUserTypeRatioArr",updatedUserTypeRatioArr)
        // let ratio=overAllRatio(totalOverall,totalOverallSubmission)
        // setOverAllratio(()=>ratio)
    }
    // else if(dateRangeType==="YESTERDAY"){
    //     setOverAllratio(()=>50)
    // }else if(dateRangeType==="LAST_7_DAYS"){
    //     setOverAllratio(()=>44)
    // }else if(dateRangeType==="LAST_30_DAYS"){
    //     setOverAllratio(()=>45)
    // }else if(dateRangeType==="CUSTOM"){
    //     setOverAllratio(()=>97)
    // }
    // //////console.log("out dateRangeType 2==>",dateRangeType)
 
    // //////console.log(count,"use ",Overall,OverallAssSubBySchool,OverallAssNotSubBySchool)
    // //////console.log("overallSubmission ",overallSubmission,"overallAssessmentSubBySchool",overallAssessmentSubBySchool,"overallNotSubmissionBySchool",overallNotSubmissionBySchool)
    // if(IsArrayWithKeys(Overall)&&IsArrayWithKeys(OverallAssSubBySchool)&&IsArrayWithKeys(OverallAssNotSubBySchool)){
        //////console.log("ok ",count ,"overallSubmission ==> ",overallSubmission)
        // //////console.log("ok ",count ,"overallAssessmentSubBySchool ==> ",overallAssessmentSubBySchool)
        // //////console.log("ok ",count ,"overallNotSubmissionBySchool ==> ",overallNotSubmissionBySchool)
    // }
    count++;

},[overallSubmission,overallAssessmentSubBySchool,overallNotSubmissionBySchool,dateRangeType,dateRange])
useEffect(()=>{
let AssessmentOverAllSubmission={};
let AssessmentDetailsSubBySchool={};
let AssessmentDetailsNotSubBySchool={};
// let testing=  {
//     "user_type":"STUDENT"
// }
// const userTypeBody=[testing] //dev testing
// (
    async function fetchUsersData(){
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
    userTypeBody?.map((x,i)=>{
        (async function fetchDatas(){
            // //////console.log("userTypeBody[i] ",userTypeBody[i])
        await Api.post("/assessment/assessment-filter",userTypeBody[i]).then((x)=>{//    
                let fetchedAssData=x.data
        fetchedAssData.map((assDet,k)=>{
                (async function fetchAssNotSubmisssion(){
                let totalUsers=assDet?.user_ids?.length
                let assId=assDet._id
                // //////console.log("totalUsers in not submi",totalUsers,"i ",i,"user_type",userTypeBody[i].user_type,totalUsers,"assDet?.user_ids",assDet?.user_ids)
                await Api.get(`/assessment/submission/users-not-submitted?assessment_id=${assDet._id}`).then((notSubRes)=>{
                    let userID=notSubRes.data[0]?._id
                    let notSubmissionCount=notSubRes.data.length 
                    let creationDates=new Date(assDet.createdAt).getTime()
                    console.log(userTypeBody[i].user_type ,"==>","not submitted list",notSubRes.data)
                    if(userTypeBody[i].user_type==="STUDENT"){
                        // //////console.log("not sub",assId)
                    }
                    // //////console.log("AssessmentDetailsNotSubBySchool not sub",AssessmentDetailsNotSubBySchool)
                        if(userID){
                            (async function fetchSchoolDetails(){
                                await Api.get(`/user/get-user-by-id?id=${userID}`).then((detailsById)=>{
                                    let schoolId=detailsById.data.schoolId
                                    // //////console.log("in not submission",assId,totalUsers,"notSubmissionCount  ==>",notSubmissionCount,"schoolId",schoolId)
                                   if(AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type]){
                                    // //////console.log("not wporking  test==>")
                                    if( AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]){
                                        // //////console.log("if before ==> ",AssessmentDetailsNotSubBySchool)
                                        AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]={
                                            ...AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId],
                                            total:(AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.total||0)+totalUsers,
                                            notSubmission:(AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.notSubmission||0)+notSubmissionCount
                                        }
                                        if(AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.notSubmissionByDates){
                                            if( AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.notSubmissionByDates[creationDates]){
                                                AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId].notSubmissionByDates[creationDates]={
                                                        total:AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.notSubmissionByDates.creationDates.total+totalUsers,
                                                        notSubmission:AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.notSubmissionByDates.creationDates.notSubmission+notSubmissionCount
                                                    }
                                            }else{
                                                AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                    ...AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId],
                                                    notSubmissionByDates:{...AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId].notSubmissionByDates}
                                                }
                                                AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId].notSubmissionByDates[creationDates]={
                                                    total:totalUsers,
                                                    notSubmission:notSubmissionCount
                                                }
                                            }

                                        }else{
                                            ////console.log("not required as of nmow 2 ");

                                        }
                                        
                                        // //////console.log("if after ==> ",AssessmentDetailsNotSubBySchool)
                                    }else{
                                        AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type]={...AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type]}
                                        if(schoolId){
                                            if( AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]){//not requiired
                                                ////console.log("not required , but it is workimng ??")
                                                AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                    ...AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId],
                                                    total:(AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId].total||0)+totalUsers,
                                                    notSubmission:(AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.notSubmission||0)+notSubmissionCount
                                                }
                                            }else{
                                                AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                    total:totalUsers,
                                                    notSubmission:notSubmissionCount,
                                                    notSubmissionByDates:{}
                                                }
                                                AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId].notSubmissionByDates[creationDates]=  {
                                                    total:totalUsers,
                                                    notSubmission:notSubmissionCount
                                                }
                                                // {
                                                  
                                                // }
                                            }
                                        }
                                    }
                                   }else{
                                    AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type]={}
                                    if(schoolId){
                                        // //////console.log("test 2 , top of if ")
                                        if( AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]){
                                            // //////console.log("else before insdie if ",AssessmentDetailsNotSubBySchool,"notSubmissionCount",notSubmissionCount)
                                            AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                ...AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId],
                                                total:(AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.total||0)+totalUsers,
                                                notSubmission:(AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]?.notSubmission||0)+notSubmissionCount
                                            }
                                            // //////console.log("else after insdie if ",AssessmentDetailsNotSubBySchool,"notSubmissionCount",notSubmissionCount)
                                        }else{
                                            // //////console.log("else before insdie else ",AssessmentDetailsNotSubBySchool,"notSubmissionCount",notSubmissionCount)
                                            AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                total:totalUsers,
                                                notSubmission:notSubmissionCount,
                                                notSubmissionByDates:{}
                                            }
                                            AssessmentDetailsNotSubBySchool[userTypeBody[i].user_type][schoolId].notSubmissionByDates[creationDates]= {
                                                total:totalUsers,
                                                notSubmission:notSubmissionCount
                                            }
                                            // {
                                               
                                            // }
                                            ////console.log("updatred 2 ==> ",AssessmentDetailsNotSubBySchool);
                                            // //////console.log("else after insdie else ",AssessmentDetailsNotSubBySchool,"notSubmissionCount",notSubmissionCount)
                                        }
                                    }
                                   }
                            //        if(userTypeBody[i].user_type==="STUDENT"){
                            //         //////console.log("not AssessmentDetailsSubBySchool ==> ", "assId",assId ,"totalUsers ==> ",totalUsers,
                            //         "schoolId",
                            //         schoolId,
                            //         "userid",userID,
                            //         AssessmentDetailsNotSubBySchool);
                            //  }
                                //    "assId",assId, totalUsers,notSubmissionCount);
                                   if(k===fetchedAssData.length-1){
                                    // //////console.log("nto inside AssessmentDetailsNotSubBySchool ==> ",AssessmentDetailsNotSubBySchool)
                                    // //////console.log("is 2 updating ",AssessmentDetailsSubBySchool)
                                    // //////console.log("is 2 updating ",AssessmentDetailsNotSubBySchool)
                                    setOverallAssessmentSubBySchool(()=>AssessmentDetailsSubBySchool)
                                    setFilterOverallAssessmentSubBySchool(()=>AssessmentDetailsSubBySchool)
                                    setOverallSubmission(()=>AssessmentOverAllSubmission)
                                    setOverallNotSubmissionBySchool(()=>AssessmentDetailsNotSubBySchool)
                                    setFilterOverallNotSubmissionBySchool(()=>AssessmentDetailsNotSubBySchool)
                                    // const[userTypeRatioArr,overallRatio]=makeUserTypeCumOverallRatio(AssessmentOverAllSubmission,initialUserTypeRatio)
                                    // //console.log("1111 ==> test test",userTypeRatioArr,overallRatio);
                                    // setOverAllUserTyperatio(()=>userTypeRatioArr)
                                    // setOverAllratio(()=>overallRatio)
                                     
                                }
                                })
                            })()
                        }
                       
                                          
                })
                    })()
        })
            })
        })()
    })
    let userCount=0
    userTypeBody?.map((xy,i)=>{
        userCount++
        (async function fetchDatas(){
            // //////console.log("userTypeBody[i] ",userTypeBody[i])-
        await Api.post("/assessment/assessment-filter",userTypeBody[i]).then((x)=>{//    
                let fetchedAssData=x.data
                if(fetchedAssData.length==0&&xy.user_type==="STUDENT"){//edge case
                    console.log("its is wokrking or mnot ")
                    setOverallSubmission(()=>AssessmentOverAllSubmission)
                    setOverallAssessmentSubBySchool(()=>AssessmentDetailsSubBySchool)
                    const[userTypeRatioArr,overallRatio]=makeUserTypeCumOverallRatio(AssessmentOverAllSubmission,initialUserTypeRatio)
                    setOverAllUserTyperatio(()=>userTypeRatioArr)
                    setOverAllratio(()=>overallRatio)
                    setFilterOverallAssessmentSubBySchool(()=>AssessmentDetailsSubBySchool)
                    setOverallNotSubmissionBySchool(()=>AssessmentDetailsNotSubBySchool)
                    setFilterOverallNotSubmissionBySchool(()=>AssessmentDetailsNotSubBySchool)
                    // dispatch({
                    //     type: "SET_LOADING",
                    //     loading:false,
                    // });   
                    setIsAllFetched(()=>true)

                    //console.log("testing this false above  ", )
                }else{
                    //console.log("testing this false below ", )
        let setUpdatedDetailsCount=0
        fetchedAssData.map((assDet,j)=>{
                            setUpdatedDetailsCount++
                            (async function fetchAssSubmisssion(){
                                let totalUsers=assDet.user_ids.length
                                let assId=assDet._id
                                // //////console.log("sub ",assId)
                        await Api.get(`assessment/submission/users-submitted?assessment_id=${assDet._id}`).then((subRes)=>{
                            // //////console.log(userTypeBody[i].user_type," ==> ","assDet ==> ",assDet)
                            let userID=subRes.data[0]?._id 
                            let submissionCount=subRes.data.length 
                            let creationDates=new Date(assDet.createdAt).getTime()
                            // //////console.log("creationDates ==> ",creationDates)
                            if(AssessmentOverAllSubmission[userTypeBody[i].user_type]){
                                if( AssessmentOverAllSubmission[userTypeBody[i].user_type].submissionByDates[creationDates]){
                                AssessmentOverAllSubmission[userTypeBody[i].user_type]={
                                    ...AssessmentOverAllSubmission[userTypeBody[i].user_type],
                                    total:AssessmentOverAllSubmission[userTypeBody[i].user_type].total+totalUsers,
                                    submission:AssessmentOverAllSubmission[userTypeBody[i].user_type].submission+submissionCount 
                                 }
                                 AssessmentOverAllSubmission[userTypeBody[i].user_type].submissionByDates[creationDates]={
                                    ...AssessmentOverAllSubmission[userTypeBody[i].user_type].submissionByDates[creationDates],
                                    total:AssessmentOverAllSubmission[userTypeBody[i].user_type].submissionByDates[creationDates].total+totalUsers,
                                    submission:AssessmentOverAllSubmission[userTypeBody[i].user_type].submissionByDates[creationDates].submission+submissionCount
                                }

                                }else{
                                    AssessmentOverAllSubmission[userTypeBody[i].user_type]={
                                        ...AssessmentOverAllSubmission[userTypeBody[i].user_type],
                                        total:AssessmentOverAllSubmission[userTypeBody[i].user_type].total+totalUsers,
                                        submission:AssessmentOverAllSubmission[userTypeBody[i].user_type].submission+submissionCount 
                                     }
                                     AssessmentOverAllSubmission[userTypeBody[i].user_type].submissionByDates[creationDates]={
                                        total:totalUsers,
                                        submission:submissionCount
                                    }
                                }

                            }else{
                                AssessmentOverAllSubmission[userTypeBody[i].user_type]={
                                    total:totalUsers,
                                    submission:submissionCount,
                                    submissionByDates:{}
                                }
                                AssessmentOverAllSubmission[userTypeBody[i].user_type].submissionByDates[creationDates]={
                                    total:totalUsers,
                                    submission:submissionCount
                                }
                                if(userTypeBody[i].user_type==="STUDENT"){
                                    // //////console.log("else totalUsers AssessmentOverAllSubmission","assId ==>",assId,totalUsers)

                                }
                                // //////console.log("else AssessmentOverAllSubmission",AssessmentOverAllSubmission)
                            }
                            if(userID){
                                (async function fetchSchoolDetails(){
                                    await Api.get(`/user/get-user-by-id?id=${userID}`).then((detailsById)=>{
                                        // //////console.log("user_type",userTypeBody[i].user_type,
                                        // "detailsById ==> "
                                        // ,detailsById.data)
                                        // //////console.log("fetchedAssData ",assDet,"subRes ",subRes.data);
                                        let schoolId=detailsById.data.schoolId
                                        // if(userTypeBody[i].user_type==="STUDENT"){
                                            // //////console.log("sub",assId,"fetchedAssData ==> ",fetchedAssData)
                                        // }
                                        // //////console.log("in submission",assId,totalUsers,"submissionCount ==>",submissionCount,"scId ==> ",schoolId)
                                        // //////console.log("AssessmentDetailsSubBySchool sub",AssessmentDetailsSubBySchool)
                                       if(AssessmentDetailsSubBySchool[userTypeBody[i].user_type]){
                                        // //////console.log("if user type true test 3")
                                        if(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]){
                                            AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                ...AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId],
                                                total:(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]?.total||0)+totalUsers,
                                                submission:(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]?.submission||0)+submissionCount
                                            }
                                            if( AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].SchoolSubmissionByDates){
                                                // //////console.log("befoere if ",AssessmentDetailsSubBySchool)
                                                // //////console.log("if schol id true test 3")
                                                // if(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].notSubmission){
                                                    if(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].SchoolSubmissionByDates[creationDates]){
                                                        AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].SchoolSubmissionByDates[creationDates]={
                                                            total:  AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].SchoolSubmissionByDates[creationDates].total+totalUsers,
                                                            submission:  AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].SchoolSubmissionByDates[creationDates].submission+submissionCount
                                                        }
    
                                                    }else{
                                                        AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].SchoolSubmissionByDates[creationDates]={
                                                            total:totalUsers,
                                                            submission:submissionCount
                                                        }
    
                                                    }
                                                    
                                                // }else{
                                                //     AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                //         total:totalUsers,
                                                //         submission:submissionCount
                                                //     } 
                                                // }
                                                // //////console.log("schol id true test 3after if  ==> ",AssessmentDetailsSubBySchool)
                                            }
                                            else{
                                                ////console.log("sub not requred test");
                                                // AssessmentDetailsSubBySchool[userTypeBody[i].user_type]={...AssessmentDetailsSubBySchool[userTypeBody[i].user_type]}
                                                // // //////console.log("spreacd after ==> ",AssessmentDetailsSubBySchool)
                                                //             if(schoolId){
                                                //                 if( AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]){
                                                //                     if(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].notSubmission){
                                                //                         AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                //                             ...AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId],
                                                //                             total:(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]?.total||0)+totalUsers,
                                                //                             submission:(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]?.submission||0)+submissionCount
                                                //                         }
                                                //                     }else{
                                                //                         AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                //                             total:totalUsers,
                                                //                             submission:submissionCount
                                                //                         } 
                                                //                     }
                                                //                 }else{
                                                //                     AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                //                         total:totalUsers,
                                                //                         submission:submissionCount
                                                //                     }
                                                //                 }
                                                //             }
                                                        }
                                        }else{
                                            AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                total:totalUsers,
                                                submission:submissionCount,
                                                SchoolSubmissionByDates:{}
                                            }
                                            AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].SchoolSubmissionByDates[creationDates]={
                                                total:totalUsers,
                                                submission:submissionCount
                                            }
                                            ////console.log("testing  else 1 ",assId,"total ==> ",totalUsers)  


                                        }
                                     
                                      

                                                }
                                        else{
                                            // //////console.log("AssessmentDetailsSubBySchool ==> ",AssessmentDetailsSubBySchool);
                                        AssessmentDetailsSubBySchool[userTypeBody[i].user_type]={}
                                        if(schoolId){
                                            if( AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]){
                                                // //////console.log("befoere else ",AssessmentDetailsSubBySchool)
                                                AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                    ...AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId],
                                                    total:(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]?.total||0)+totalUsers,
                                                    submission:(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]?.submission||0)+submissionCount
                                                }
                                                // //////console.log("after else ",AssessmentDetailsSubBySchool)
                                            }else{
                                                // //////console.log("befoere else 2 ",AssessmentDetailsSubBySchool)
                                                 AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]={
                                                    // ...AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId],
                                                    total:totalUsers,//(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]?.total||0)+
                                                    submission:submissionCount,//(AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId]?.submission||0)+
                                                    SchoolSubmissionByDates:{}
                                                }
                                                AssessmentDetailsSubBySchool[userTypeBody[i].user_type][schoolId].SchoolSubmissionByDates[creationDates]={
                                                    total:totalUsers,
                                                    submission:submissionCount
                                                }
                                                ////console.log("testing  else 2 ",assId,"total ==> ",totalUsers)  
                                            }
                                        }
                                       }

                                //        if(userTypeBody[i].user_type==="STUDENT"){
                                //        //////console.log("AssessmentDetailsSubBySchool ==> ", "assId",assId ,"totalUsers ==> ",totalUsers,"schoolId",
                                //        schoolId,
                                //        "userid",userID,
                                //        AssessmentDetailsSubBySchool);
                                // }
                                if(xy.user_type==="STUDENT"){//temp sol , may be use find index to fix 
                                    // //////console.log("j ==>",j,"fetchedAssData ==> ",fetchedAssData.length,userTypeBody[i].user_type)
                                    // //////console.log("is 1 updating ",AssessmentOverAllSubmission)
                                    // //////console.log("is 1 updating ",AssessmentDetailsSubBySchool)
                                    const[userTypeRatioArr,overallRatio]=makeUserTypeCumOverallRatio(AssessmentOverAllSubmission,initialUserTypeRatio)
                                    setOverallSubmission(()=>AssessmentOverAllSubmission)
                                    setOverallAssessmentSubBySchool(()=>AssessmentDetailsSubBySchool)
                                    // // //////console.log("AssessmentDetailsSubBySchool ==> ",AssessmentDetailsSubBySchool);
                                    console.log("test",userTypeRatioArr,overallRatio)
                                    setOverAllUserTyperatio(()=>userTypeRatioArr)
                                    setOverAllratio(()=>overallRatio)
                                    //console.log("2222 ==> test test",userTypeRatioArr,overallRatio);
                                    setFilterOverallAssessmentSubBySchool(()=>AssessmentDetailsSubBySchool)
                                    setOverallNotSubmissionBySchool(()=>AssessmentDetailsNotSubBySchool)
                                    setFilterOverallNotSubmissionBySchool(()=>AssessmentDetailsNotSubBySchool)
                                   console.log("check ", AssessmentDetailsNotSubBySchool);
                                    setIsAllFetched(()=>true)
                                    // dispatch({
                                    //     type: "SET_LOADING",
                                    //     loading:false,
                                    // });   
                              
                                }else{
                                    // //console.log("else   ====> j===fetchedAssData.length-1",j,fetchedAssData.length-1);
                                }  
                                })
                                })()
                            }
                          
                            // //////console.log(userTypeBody[i].user_type ,"fetched x ==> ",fetchedAssData)
                            // //////console.log("res sub",userTypeBody[i].user_type," ==> ",assDet._id,subRes.data)                                    
                            // //////console.log("i ==> ",i," user_type ==> ",userTypeBody[i].user_type,AssessmentOverAllSubmission)
                        })
                 })()
                })

            }

            })
        })()
    }

    )

  
}
// )()
fetchUsersData()
},[])
useEffect(()=>{
    if(isAllFetched){
        dispatch({
            type: "SET_LOADING",
            loading:false,
        });  
    }
    // let value=overAllRatio
    // setOverAllratio((pre)=>{
    //     if(pre!==value){
    //         console.log("workingn")
    //     }
    // })
    },[isAllFetched,overAllratio])

function setDates(e){
    if (e.target.value === "TODAY") {
        // let start=`${DefaultStartDateTiming().getFullYear()}-${ZeroPrefixFormat(DefaultStartDateTiming().getDate())}-${ZeroPrefixFormat(DefaultStartDateTiming().getMonth()+1)}`
        // let end=`${DefaultEndDateTiming().getFullYear()}-${ZeroPrefixFormat(DefaultEndDateTiming().getDate())}-${ZeroPrefixFormat(DefaultEndDateTiming().getMonth()+1)}`
        let dateArray=DateFormater(DefaultStartDateTiming,DefaultEndDateTiming)
        setDateRange(dateArray);
        //////console.log("dateArray ==> ",dateArray)
        // //////console.log("DefaultStartDateTiming ==> ",DefaultStartDateTiming(),"DefaultEndDateTiming",DefaultEndDateTiming())
    } else if (e.target.value === "YESTERDAY") {
        let dateArray=DateFormater(yesterdayStartDate,yesterdayEndDate)
        setDateRange(dateArray);
        // //////console.log("dateArray ==> ",dateArray)
        // setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)]);
    } else if (e.target.value === "LAST_7_DAYS") {
        let dateArray=DateFormater(Last_7_days_Start_Date,Last_7_days_End_Date)
        setDateRange(dateArray);
        // setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    } else if (e.target.value === "LAST_30_DAYS") {
        let dateArray=DateFormater(Last_30_days_Start_Date,Last_30_days_End_Date)
        // //console.log("dateArray 30",dateArray)
        setDateRange(dateArray);
        // setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (29 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    } else if (e.target.value === "CUSTOM") {//default to tiodayt
        let dateArray=DateFormater(DefaultStartDateTiming,DefaultEndDateTiming)
        // //console.log("dateArray custom",dateArray)
        setDateRange(dateArray);
        // setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    }else if (e.target.value === "OVERALL") {
        setDateRange(()=>["",""]);
        // setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    }
    setDateRangeType(e.target.value)

}
useEffect(()=>{
    console.log("filterOverallAssessmentSubBySchool",filterOverallAssessmentSubBySchool);
    console.log("filterOverallNotSubmissionBySchool",filterOverallNotSubmissionBySchool);
    console.log("schoolList",schoolList);
},[filterOverallAssessmentSubBySchool,filterOverallNotSubmissionBySchool])
    return (
        <div>
            <h1 className="pt-3" align="center" style={{ fontWeight: "bold" }}>Assessment Report</h1>
            <Form className="row">
                <Form.Group className="col-12" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Date Range</Form.Label>
                    <Form.Control as="select" value={dateRangeType} onChange={(e) => {
                        setDates(e)
                        }}>
                        {dateRangeTypes.map((item) => {
                            return (
                                <option value={item.value}>{item.text}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group className="col-6" style={{
                    paddingLeft: "30px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" 
                    value={(dateRange[0]!==""?dateRange[0]:"")}//"yy-mm-dd"
                    // value="" 
                    onChange={(e) => {
                        // //////console.log("e.target.value ==> ",e.target.value)
                        let updated=[...dateRange]
                        updated[0]=e.target.value
                        // //////console.log(updated)
                        if(new Date(updated[0]).getTime()>new Date(updated[1]).getTime()){
                            // alert("Start Date cannot be greater than End Date.")
                       }else{
                        // setDateRange(()=>updated)
                        }
                        setDateRange(()=>updated)
                        setIsDateChanging(true)

                        // setDateRangeType("CUSTOM")
                        // let temp_time_array = [new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]]
                        // if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                        //     setDateRange([new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]])
                        // } else {
                        //     alert("Start Date cannot be greater than End Date.")
                        // }
                    }} />
                </Form.Group>
                <Form.Group className="col-6" style={{
                    paddingLeft: "10px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" 
                      value={(dateRange[1]!==""?dateRange[1]:"")}//"yy-mm-dd"
                // value={new Date(dateRange[1]!==""&&(dateRange[1].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0])}
                    // value={new Date(dateRange[1].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} 
                    onChange={(e) => {
                        // //////console.log("e.target.value ==> ",e.target.value)
                        let updated=[...dateRange]
                        updated[1]=e.target.value
                        if(new Date(updated[0]).getTime()>new Date(updated[1]).getTime()){
                                                    // //////console.log("End",updated)
                            alert("End Date cannot be less than Start Date.",updated)
                        }
                    //     else if(new Date(updated[0]).getTime()>new Date(updated[1]).getTime()){
                    //         //////console.log("Start",updated)
                    //         alert("Start Date cannot be greater than End Date.",updated)
                    //    }
                       else{
                        setDateRange(()=>updated)
                        setIsDateChanging(true)
                        }
                        // setDateRangeType("CUSTOM")
                        // let temp_time_array = [dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]
                        // if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                        //     setDateRange([dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))])
                        // } else {
                        //     alert("End Date cannot be less than Start Date.")
                        // }
                    }} />
                </Form.Group>
                <Form.Group className="col-12" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Type of Report</Form.Label>
                    <Form.Control as="select" value={chartType} onChange={(e) => {
                        setChartType(e.target.value)
                        setSchoolWisePageCount(1)
                    }}>
                        {chartTypeList.map((item) => {
                            return (
                                <option key={item.value} value={item.value}>{item.text}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
            {(chartType === "OVERALL" || chartType === "USER_TYPE") ? <Stack className="row" gap={3} direction="horizontal" style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                {(chartType === "OVERALL") ?
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        width: "350px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}>
                        <Pie percentage={overAllratio}colour="red" />
                        <p align="center" style={{
                            "fontWeight": "bold",
                            "fontSize": "x-large",
                        }}>
                            Overall Assessment Submission
                        </p>
                    </div> : chartType === "USER_TYPE" ?
                        <><div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            width: "350px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}>
                            <Pie percentage={parseInt(overAllUserTyperatio.STUDENT)===0?0:overAllUserTyperatio.STUDENT} colour="red" />
                            <p align="center" style={{
                                "fontWeight": "bold",
                                "fontSize": "x-large",
                            }}>
                                Student Assessment Ratio
                            </p>
                        </div>
                            <div style={{
                                padding: "10px",
                                backgroundColor: "white",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}> 
                                <Pie percentage={parseInt(overAllUserTyperatio.TEACHER)===0?0:overAllUserTyperatio.TEACHER} colour="blue" />
                                <p align="center" style={{
                                    "fontWeight": "bold",
                                    "fontSize": "x-large",
                                }}>
                                    Teacher Assessment Ratio
                                </p>
                            </div>
                            <div style={{
                                padding: "10px",
                                backgroundColor: "white",
                                borderRadius: "10px",
                                width: "350px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                <Pie percentage={parseInt(overAllUserTyperatio.SCHOOL_ADMIN)===0?0:overAllUserTyperatio.SCHOOL_ADMIN} colour="orange" />
                                <p align="center" style={{
                                    "fontWeight": "bold",
                                    "fontSize": "x-large",
                                }}>
                                    School Admin Ass. Ratio
                                </p>
                            </div></> : null}
            </Stack> : chartType === "SCHOOL_WISE_STUDENT_SUB" ?
                <div style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <div style={{
                        padding: "10px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                        width: "100%",
                    }}>
                        {/* {//////console.log(schoolNames, schoolWisePageCount)} */}
                        <Bar options={
                            {
                                indexAxis: 'y',
                                elements: {
                                    bar: {
                                        borderWidth: 2,
                                    },
                                },
                                responsive: true,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    title: {
                                        display: true,
                                        text: 'Student Assessment Chart',
                                    },
                                },
                            }
                        } data={{
                            labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                            datasets: [
                                {
                                    label: 'Student Assessment Submission Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                    // {//////console.log("testing ==> ",overallAssessmentSubBySchool["STUDENT"]?(overallAssessmentSubBySchool["STUDENT"][item?._id]):"no")}
                                    return filterOverallAssessmentSubBySchool["STUDENT"]?(filterOverallAssessmentSubBySchool["STUDENT"][item._id] ? (overAllRatio(filterOverallAssessmentSubBySchool["STUDENT"][item._id].total,filterOverallAssessmentSubBySchool["STUDENT"][item._id].submission)).toFixed(2) : 0) : 0
                            }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                            ],
                        }}
                        />
                        <div class="center">
                            <div class="pagination">
                                <a href="#" onClick={() => {
                                    setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                }}>&laquo;</a>
                                <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(1)
                                }}>1</a>
                                <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(2)
                                }}>2</a>
                                <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(3)
                                }}>3</a>
                                <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(4)
                                }}>4</a>
                                <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                    setSchoolWisePageCount(5)
                                }}>5</a>
                                <a href="#" onClick={() => {
                                    setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                }}>&raquo;</a>
                            </div>
                        </div>
                    </div>
                </div> : chartType === "SCHOOL_WISE_TEACHER_SUB" ?
                    <div style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                        }}>
                            {/* {//////console.log(schoolNames)} */}
                            <Bar options={
                                {
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: 'Teacher Assessment Chart',
                                        },
                                    },
                                }
                            } data={{
                                labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                                datasets: [{
                                    label: 'Teacher Assessment Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        return filterOverallAssessmentSubBySchool["TEACHER"]?(filterOverallAssessmentSubBySchool["TEACHER"][item._id] ? (overAllRatio(filterOverallAssessmentSubBySchool["TEACHER"][item._id].total,filterOverallAssessmentSubBySchool["TEACHER"][item._id].submission)).toFixed(2) : 0) : 0  
                                        // return overallPercent["TEACHER"] ? (overallPercent["TEACHER"][item._id] ? (overallPercent["TEACHER"][item._id]["Average Attendance"] * 100).toFixed(2) : 0) : 0
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                ],
                            }}
                            />
                            <div class="center">
                                <div class="pagination">
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                    }}>&laquo;</a>
                                    <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(1)
                                    }}>1</a>
                                    <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(2)
                                    }}>2</a>
                                    <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(3)
                                    }}>3</a>
                                    <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(4)
                                    }}>4</a>
                                    <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(5)
                                    }}>5</a>
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                    }}>&raquo;</a>
                                </div>
                            </div>
                        </div>

                    </div> :chartType === "SCHOOL_WISE_STUDENT_NOT_SUB" ?
                    <div style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                        }}>
                            <Bar options={
                                {
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: 'Student Assessment Not submssion Chart',
                                        },
                                    },
                                }
                            } data={{
                                labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                                datasets: [{
                                    label: 'Student Not Submission Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        // if(overallNotSubmissionBySchool["STUDENT"]&&(overallNotSubmissionBySchool["STUDENT"][item._id])){
                                        //     //////console.log("school id, test", item._id)
                                        //     //////console.log("test2",overallNotSubmissionBySchool["STUDENT"][item._id].notSubmission)
                                        //     //////console.log("overallNotSubmissionBySchool",overallNotSubmissionBySchool)
                                        // }
                                        return filterOverallNotSubmissionBySchool["STUDENT"]?(filterOverallNotSubmissionBySchool["STUDENT"][item._id] ? (overAllRatio(filterOverallNotSubmissionBySchool["STUDENT"][item._id].total,filterOverallNotSubmissionBySchool["STUDENT"][item._id].notSubmission)).toFixed(2) : 0) : 0  
                                        // return overallPercent["TEACHER"] ? (overallPercent["TEACHER"][item._id] ? (overallPercent["TEACHER"][item._id]["Average Attendance"] * 100).toFixed(2) : 0) : 0
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                ],
                            }}
                            />
                            <div class="center">
                                <div class="pagination">
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                    }}>&laquo;</a>
                                    <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(1)
                                    }}>1</a>
                                    <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(2)
                                    }}>2</a>
                                    <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(3)
                                    }}>3</a>
                                    <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(4)
                                    }}>4</a>
                                    <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(5)
                                    }}>5</a>
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                    }}>&raquo;</a>
                                </div>
                            </div>
                        </div>

                    </div> :chartType === "SCHOOL_WISE_TEACHER_NOT_SUB" ?
                    <div style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                        }}>
                            <Bar options={
                                {
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: 'Teacher Not Submission Assessment Chart',
                                        },
                                    },
                                }
                            } data={{
                                labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                                datasets: [{
                                    label: 'Teacher Not Submission Assessment Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        return filterOverallNotSubmissionBySchool["TEACHER"]?(filterOverallNotSubmissionBySchool["TEACHER"][item._id] ? (overAllRatio(filterOverallNotSubmissionBySchool["TEACHER"][item._id].total,filterOverallNotSubmissionBySchool["TEACHER"][item._id].notSubmission)).toFixed(2) : 0) : 0   
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                ],
                            }}
                            />
                            <div class="center">
                                <div class="pagination">
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                    }}>&laquo;</a>
                                    <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(1)
                                    }}>1</a>
                                    <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(2)
                                    }}>2</a>
                                    <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(3)
                                    }}>3</a>
                                    <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(4)
                                    }}>4</a>
                                    <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(5)
                                    }}>5</a>
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                    }}>&raquo;</a>
                                </div>
                            </div>
                        </div>

                    </div> :chartType === "SCHOOL_WISE_ADMIN_SUB" ?
                    <div style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                        }}>
                            <Bar options={
                                {
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: 'Admin Assessment Chart',
                                        },
                                    },
                                }
                            } data={{
                                labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                                datasets: [{
                                    label: 'Admin Assessment Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        return filterOverallAssessmentSubBySchool["SCHOOL_ADMIN"]?(filterOverallAssessmentSubBySchool["SCHOOL_ADMIN"][item._id] ? (overAllRatio(filterOverallAssessmentSubBySchool["SCHOOL_ADMIN"][item._id].total,filterOverallAssessmentSubBySchool["SCHOOL_ADMIN"][item._id].submission)).toFixed(2) : 0) : 0  
                                        // return overallPercent["TEACHER"] ? (overallPercent["TEACHER"][item._id] ? (overallPercent["TEACHER"][item._id]["Average Attendance"] * 100).toFixed(2) : 0) : 0
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                ],
                            }}
                            />
                            <div class="center">
                                <div class="pagination">
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                    }}>&laquo;</a>
                                    <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(1)
                                    }}>1</a>
                                    <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(2)
                                    }}>2</a>
                                    <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(3)
                                    }}>3</a>
                                    <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(4)
                                    }}>4</a>
                                    <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(5)
                                    }}>5</a>
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                    }}>&raquo;</a>
                                </div>
                            </div>
                        </div>

                    </div> 
                    :chartType === "SCHOOL_WISE_ADMIN_NOT_SUB" ?
                    <div style={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        paddingTop: "10px",
                        paddingBottom: "30px",
                        display: "flex",
                        justifyContent: "center",
                            alignItems: "center",
                        }}>
                        <div style={{
                            padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            width: "100%",
                        }}>
                            <Bar options={
                                {
                                    indexAxis: 'y',
                                    elements: {
                                        bar: {
                                            borderWidth: 2,
                                        },
                                    },
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        title: {
                                            display: true,
                                            text: 'Admin Not Assessment Chart',
                                        },
                                    },
                                }
                            } data={{
                                labels: schoolNames.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10),
                                datasets: [{
                                    label: 'Admin Not Assessment Percentage',
                                    data: schoolList.slice((schoolWisePageCount - 1) * 10, schoolWisePageCount * 10).map((item) => {
                                        return filterOverallNotSubmissionBySchool["SCHOOL_ADMIN"]?(filterOverallNotSubmissionBySchool["SCHOOL_ADMIN"][item._id] ? (overAllRatio(filterOverallNotSubmissionBySchool["SCHOOL_ADMIN"][item._id].total,filterOverallNotSubmissionBySchool["SCHOOL_ADMIN"][item._id].notSubmission)).toFixed(2) : 0) : 0  
                                        // return overallPercent["TEACHER"] ? (overallPercent["TEACHER"][item._id] ? (overallPercent["TEACHER"][item._id]["Average Attendance"] * 100).toFixed(2) : 0) : 0
                                    }),
                                    borderColor: 'rgb(255, 99, 132)',
                                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                                },
                                ],
                            }}
                            />
                            <div class="center">
                                <div class="pagination">
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.max(1, schoolWisePageCount - 1))
                                    }}>&laquo;</a>
                                    <a class={schoolWisePageCount == 1 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(1)
                                    }}>1</a>
                                    <a class={schoolWisePageCount == 2 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(2)
                                    }}>2</a>
                                    <a class={schoolWisePageCount == 3 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(3)
                                    }}>3</a>
                                    <a class={schoolWisePageCount == 4 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(4)
                                    }}>4</a>
                                    <a class={schoolWisePageCount == 5 ? "active" : ""} href="#" onClick={() => {
                                        setSchoolWisePageCount(5)
                                    }}>5</a>
                                    <a href="#" onClick={() => {
                                        setSchoolWisePageCount(Math.min(5, schoolWisePageCount + 1))
                                    }}>&raquo;</a>
                                </div>
                            </div>
                        </div>

                    </div> 
                    :
                    null
            }
        </div>
    )
}

export default AssessmentReport;