import React from "react";

function NotFound() {
	return (
		<div style={{
			alignContent: 'center',
			height: '100%',
			width: '100%',
			backgroundColor: '#00abf7'
		}}>
			<div style={{
				display: 'flex',
				alignContent: 'center',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
				flexDirection: 'column'
			}}>
				{/* <img
					src={`${process.env.PUBLIC_URL}/Images/404.png`}
					style={{
						width: '800px',
						maxWidth: '100%',
						maxHeight: '100%',
					}}
				/> */}
				<h1 style={{
					textAlign: 'center',
					whiteSpace: 'pre-wrap',
					color: 'white',
					fontWeight: 'bold'
				}}>
					The Page You are trying to Visit is Currently Loading.
				</h1>
				<p style={{
					textAlign: 'center',
					whiteSpace: 'pre-wrap',
					color: 'white'
				}}>
					{/* It's just a 404 Error!
					<br />
					What you’re looking for may have been misplaced in Long Term Memory.
					<br /> */}
					If the page dosen't load in 5 minutes, Either you have opened a wrong link or the page is currently under maintenance. For further assistance, please contact the admin.
				</p>
			</div>
		</div>
	);
}

export default NotFound;
