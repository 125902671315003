import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate} from "react-router-dom";
import Api from "../../../Api/axios";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import { Box, Grid, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "react-bootstrap/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Radio from "@mui/material/Radio";
import { Fab, Button as ButtonMUI } from "@material-ui/core";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Textarea from '@mui/joy/Textarea';
import "./SubmitUI.css"
const SubmitQuestions = () => {
  const id = useParams();
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [assessmentData, setAssessmentData] = useState([]);
  const [filterAssessment, setFilterAssessment] = useState([]);
  const [filterQuestions, setFilterQuestions] = useState([]);
  const[userAnswer,setUserAnswer]=useState([])
  useEffect(() => {
    Api.get(`/assessment/get-assessment`).then((res) => {
      // //console.log("assessment created", res.data);
      let fetchedAssessment = res.data;
      fetchedAssessment.forEach((x) => {
        if (x._id === id.assessment_id) {
          setAssessmentData(x);
          //console.log("setFilterQuestions -> ",x.questions)
          let questionsDataWithUserAnswer=x.questions
          console.log("questionsDataWithUserAnswer =====> " ,questionsDataWithUserAnswer)
          questionsDataWithUserAnswer=questionsDataWithUserAnswer.map(ele=>{
            let userSelected
            let updated={...ele}
              if(ele.type==="mcq"){
                  userSelected={
                  quesId:ele._id,
                  userSelection:"",
                  quesType:"mcq"
                  }
              }else if(ele.type==="msq"){
                  userSelected={
                      quesId:ele._id,
                      userSelection:[],
                      optionLength:ele.options.length,
                      quesType:"msq"
                      }
              }else if(ele.type==="subjective"){
                  userSelected={
                      quesId:ele._id,
                      userSelection:"",
                      quesType:"subjective"
                      }
              }else if(ele.type==="doc"){
                userSelected={
                    quesId:ele._id,
                    userSelection:"",
                    quesType:"doc"
                    }
            }
              updated["userAnswer"]=userSelected
              return updated
          });
          //console.log("userSelected wih question==> ",questionsDataWithUserAnswer) ["userAnswer"]["userSelection"]
          setFilterQuestions(questionsDataWithUserAnswer);
          // setUserAnswer(()=>userSelected)
          return;
        }
      });
    });
  }, [userDetails, id.assessment_id]);

  // useEffect(() => {
  //   filterAssessment.filter((x) => {
  //     return setFilterQuestions(x.questions);
  //   });
  /*
  let bodyobj={
    answers: [{},{}] //zero based index
    mcq ==>{question_id: '8ddbd7d7-05ea-466a-890e-cc065e48077b', answer: 1}
    msq ==>{question_id: '8ddbd7d7-05ea-466a-890e-cc065e48077b', answer: [1,2]}
    sub ==>{question_id: '8ddbd7d7-05ea-466a-890e-cc065e48077b', answer:""}
    assessment_id: "eb24f3fe-d483-4ee0-af94-81356614bb6a"
  }
  
  */
  // }, [filterAssessment]);

  //console.log("submit", assessmentData);
  //console.log("qyestions", filterQuestions);
function selectOption(e,optId,quesId,type){
  e.preventDefault();//onCLick when usign array includes in msq type,it  triggers 2 events, opt not selected , so e.preventDe()
  console.log("clicked",optId,quesId,type)
let updated=[...filterQuestions]
//console.log("test updated ", updated)
updated=updated.map((obj)=>{
  // obj
  let updatedObj={...obj}
  if(obj._id===quesId){
    if(type==="mcq"){
      updatedObj["userAnswer"]["userSelection"]=optId
    }else if(type==="msq"){
      if(updatedObj["userAnswer"]["userSelection"].includes(optId)){
        updatedObj["userAnswer"]["userSelection"]=updatedObj["userAnswer"]["userSelection"].filter((selectedOptId)=>selectedOptId!==optId)
      }else{
        if(updatedObj["userAnswer"]["userSelection"].length===updatedObj["userAnswer"].optionLength-1){
          // alert("can not select all options")
        }else{
          //console.log(updatedObj["userAnswer"].optionLength, updatedObj["userAnswer"]["userSelection"].length)
          // updatedObj["userAnswer"]["userSelection"].push(optId)
        }
        updatedObj["userAnswer"]["userSelection"].push(optId)

      }

    }
    return updatedObj
  }else{
    return updatedObj
  }
})
setFilterQuestions(updated);

}
function setUserSubjectiveAnswer(e,quesID,quesType){
  let updated=[...filterQuestions]
  let textValue=e.target.value
  //console.log("test updated ", updated)
  updated=updated.map((obj)=>{
    // obj
    let updatedObj={...obj}
    if(obj._id===quesID){
      updatedObj["userAnswer"]["userSelection"]=textValue
      return updatedObj
      }
      else{
      return updatedObj
    }
  })
  setFilterQuestions(updated);


}
function validationUserAssessment(filterQuestionsWithAnswer){
let isError=false
filterQuestionsWithAnswer.forEach((obj)=>{
  if(isError){
    return
  }
if(obj.type==="mcq"){
if(obj["userAnswer"]["userSelection"]===""){
  alert("complete mcq questions")
  isError=true
  return
}
}else if(obj.type==="msq"){
  if(obj["userAnswer"]["userSelection"].length===0){
    alert("complete msq questions")
    isError=true
    return
  }
}else if(obj.type==="subjective"){
  if(obj["userAnswer"]["userSelection"]===""){
    alert("complete subjective questions")
    isError=true
    return
  }
}else if(obj.type==="doc"){
  if(obj["userAnswer"]["userSelection"]===""){
    alert("upload Document questions")
    isError=true
    return
  }
}

})

return isError

}
function ChangeAnswerWithIndex(filterQuestions){
let IndexAnswers=[]
console.log("filterQuestions ===> ",filterQuestions)
filterQuestions.map((obj)=>{
  let updatedAns={}
  if(obj.type==="mcq"){
    let userSelected=obj["userAnswer"]["userSelection"]
    let index=obj.options.findIndex((x)=>x.id===userSelected)
    updatedAns["question_id"]=obj["userAnswer"]["quesId"]
    updatedAns["answer"]=index
    updatedAns["type"]=obj.type
    IndexAnswers.push(updatedAns)
  }else if(obj.type==="msq"){
    let updatedAns={}
    let indexArr=[]
    let userSelected=obj["userAnswer"]["userSelection"]
    userSelected.map((id)=>{
      let index=obj.options.findIndex((x)=>x.id===id)
      indexArr.push(index)
    })
    updatedAns["question_id"]=obj["userAnswer"]["quesId"]
    updatedAns["answer"]=indexArr
    updatedAns["type"]=obj.type
    IndexAnswers.push(updatedAns)
/*
    mcq ==>{question_id: '8ddbd7d7-05ea-466a-890e-cc065e48077b', answer: 1}
    msq ==>{question_id: '8ddbd7d7-05ea-466a-890e-cc065e48077b', answer: [1,2]}
    sub ==>{question_id: '8ddbd7d7-05ea-466a-890e-cc065e48077b', answer:""}

*/
  }else if(obj.type==="subjective"){
    let updatedAns={}
    updatedAns["question_id"]=obj["userAnswer"]["quesId"]
    updatedAns["answer"]=obj["userAnswer"]["userSelection"]
    updatedAns["type"]=obj.type
    IndexAnswers.push(updatedAns)
  }else if(obj.type==="doc"){
    let updatedAns={}
    updatedAns["question_id"]=obj["userAnswer"]["quesId"]
    updatedAns["answer"]=obj["userAnswer"]["userSelection"]
    updatedAns["type"]=obj.type
    IndexAnswers.push(updatedAns)
  }
})
return IndexAnswers
}
const browserHistory=useNavigate();
function submitAnswer(){
  let result=validationUserAssessment(filterQuestions)
  if(!result){
      console.log("submitted",filterQuestions,assessmentData)
      let indexUpdatedAns=ChangeAnswerWithIndex(filterQuestions)
      console.log("upda",indexUpdatedAns,assessmentData._id);
      // console.log("indexUpdatedAns ==>",indexUpdatedAns)
      // let bodyObj=   {
      //     "assessment_id":assessmentData._id,
      //     "answers":indexUpdatedAns}
          // this.context.router.history.goBack()
      let data = new FormData();
    //   let dataTobeSend={
    //   "answers":[],
    //   "assessment_id":assessmentData._id
    // }
      data.append(`assessment_id`,assessmentData._id)
      let answers=[]
      let files=[]
      indexUpdatedAns.map((userAnswer,i)=>{
        if(userAnswer.type!="doc"){
          // answers.push({"question_id":userAnswer.question_id,"answer":userAnswer.answer})
          // data.append(`answers`,JSON.stringify({question_id:userAnswer.question_id,answer:userAnswer.answer}))
      data.append(`answers[${i}][question_id]`,userAnswer.question_id)
      data.append(`answers[${i}][answer]`,userAnswer.answer)
          // dataTobeSend.answers.push({
          //   "question_id":userAnswer.question_id,//question_id
          //   "answer":userAnswer.answer
          // })
          // data.append(`answers${i}[answer]`,)
        }else if(userAnswer.type==="doc"){
          data.append(`answers[${i}][question_id]`,userAnswer.question_id)
          // answers.push({"question_id":userAnswer.question_id})
          // files.push(userAnswer.answer)
          // data.append(`answers`,JSON.stringify({"question_id":userAnswer.question_id}))
          // dataTobeSend.answers.push({
          //   "question_id":userAnswer.question_id,
          // })
          // console.log("userAnswer.answer files",userAnswer.answer)
          // console.log("userAnswer.answer files",JSON.stringify(userAnswer.answer))
          // console.log("userAnswer.answer files",userAnswer.answer.name)
          // console.log("userAnswer.answer files type",typeof userAnswer.answer)
          data.append("files",userAnswer.answer)
        }
      })
      // data.append(`files`,JSON.stringify(files))
      // data.append(`answers`,(answers))
      // console.log("verfify",data.getAll("files[]"));
      // console.log("answers answers",Object.fromEntries(data));
      //  dataTobeSend.files=data
      Api.defaults.headers["Content-Type"]="multipart/form-data";
      Api.post("/assessment/submission/create", data).then((res) => {
          console.log("form data and res =====>", res)
          let resStatus=res.data.status
          let  numRegex=new RegExp(/^2[\d+]{2}$/)
          if(res){
            browserHistory(-1)
          }else{
          alert("Problem occurs, Try Submit Again")
          }

      }).catch((err) => {
          console.log("err while sending",err);
      })

      }
}
function selectFile(e, quesID, type){
  console.log("chan",e.target.files[0])
  console.log("quesID",quesID);
  console.log("type",type);

  let updated=[...filterQuestions]
  updated=updated.map((obj)=>{
    let updatedObj={...obj}
    if(obj._id===quesID){
      updatedObj["userAnswer"]["userSelection"]=e.target.files[0]
      return updatedObj
      }
      else{
      return updatedObj
    }
  })
  console.log("up",updated);
  setFilterQuestions(()=>updated);
}
  return (
    <div
      className="ps-2 pe-2"
      style={{
        // minHeight: "94vh",
        paddingBottom: "40px",
      }}
      align="center"
    >
      <h1 className="p-4" style={{ fontWeight: "bold" }}>
        {assessmentData.title}
      </h1>
      <Grid sx={{ width: "90%",paddingLeft:"5%",paddingRight:"5%"}} container>
        <Paper variant="outlined" style={{width: "100%"}}>
          {filterQuestions.map((q,quesIndex) => {
        // {//console.log("i",quesIndex,"o",q.options,"status",q.type==="mcq", q.type==="msq")}
            return (
              <Card sx={{ margin: "30px" }}>
                <CardContent>
                  <Typography align="left" style={{ paddingLeft:"4%",marginBottom:"15px"}} variant="h5" component="div">
                    {q.description}
                  </Typography>
                  {(q.type==="mcq" || q.type==="msq") &&
                      <Stack style={{ paddingLeft:"5%",paddingRight:"5%"}} direction={"column"}>
                      {q.options.map((o,i) => {
                        // //console.log("checking ","opyt idf ",o.id,"user seeer id ",q["userAnswer"]?.userSelection);
                        // //console.log("option ","mcq",q.type==="mcq",o.id===q["userAnswer"]?.userSelection,"msq",q.type==="msq",q["userAnswer"]?.userSelection.includes(o.id))
                        return (
                          <FormControl style={{marginBottom:"10px"}}>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              name="radio-buttons-group"
                            >
                              <FormControlLabel
                                value={o.id}
                                control={<Radio />}
                                label={o.option}
                                onClick={(e)=>selectOption(e,o.id,q._id,q.type)}
                                checked={q.type==="mcq"?o.id===q["userAnswer"]?.userSelection:q.type==="msq"?q["userAnswer"]?.userSelection.includes(o.id):false}
                              />
                            </RadioGroup>
                          </FormControl>
                        );
                      })}
                    </Stack>
                  }
                   {q.type==="subjective" &&
                   <Textarea maxRows={10}
                    style={{border:"none",paddingLeft:"4%",outline:"none","--Textarea-focusedHighlight":"none"}}
                     onChange={(e)=>setUserSubjectiveAnswer(e,q._id,q.type)} value={ q["userAnswer"]["userSelection"]}
                       placeholder="Write your answers here" />
                  }
                   {q.type==="doc" &&
          <>
             <label htmlFor="upload-photo">
        <input
          style={{ display: "none" ,backgroundColor:"red"}}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={(e)=>selectFile(e,q._id,q.type)}
          // onClick={(e)=>selectFile(e,q._id,q.type)}
          // onKeyDown={(e)=>selectFile(e,q._id,q.type)}
        />
        <ButtonMUI color="primary" variant="contained" component="span">
          Upload Document
        </ButtonMUI>
      </label>
      {console.log("q?.userSelected?.userSelection",q?.userAnswer?.userSelection)}
      {q?.userAnswer?.userSelection&&q?.userAnswer?.userSelection!==""&&
      <p>file uploaded  {q?.userAnswer?.userSelection?.name}
      </p>
      }
     </>
}
                
                </CardContent>
              </Card>
            );
          })}
          <Button onClick={()=>submitAnswer()} variant="success">Submit</Button>
        </Paper>
      </Grid>
    </div>
  );
};

export default SubmitQuestions;
