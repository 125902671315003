import React, { useState, useEffect } from 'react';
import Api from '../../Api/axios';
import { Stack } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table, Button, Form, Col, Row } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import GetUsers from './GetUsers';
import { Modal } from 'react-bootstrap';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const GenerateIDCard = (data) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [districtId, setDistrictId] = useState(data.districtId ? data.districtId : (params.districtId ? params.districtId : (userDetails ? userDetails?.districtId : null)));
    const [schoolId, setSchoolId] = useState(data.schoolId ? data.schoolId : (params.schoolId ? params.schoolId : (userDetails ? userDetails?.schoolId : null)));
    const [selectedUsers, setSelectedUsers] = useState([]);
    useEffect(() => {
        setDistrictId(data.districtId ? data.districtId : (params.districtId ? params.districtId : (userDetails ? userDetails?.districtId : null)));
        setSchoolId(data.schoolId ? data.schoolId : (params.schoolId ? params.schoolId : (userDetails ? userDetails?.schoolId : null)));
    }, [data, params, userDetails]);
    const [GenerateIDCardOpen, setGenerateIDCardOpen] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [downloadText, setDownloadText] = useState('');
    const [allowClose, setAllowClose] = useState(true);
    const generateCards = async () => {
        await Promise.all([setAllowClose(false), setErrorText('Starting Process')])
        let count = 0;
        let temp_dict = selectedUsers.map((user, idx) => {
            if (user.isSelected) {
                count += 1;
                return user;
            }
        })
        temp_dict = temp_dict.filter((user) => user !== undefined);
        let id_cards = [];
        let count2 = 0;
        for (const user of temp_dict) {
            if (id_cards.length % 5 == 0) {
                await Promise.all(id_cards)
                setErrorText('Processing ' + (count2 + 1) + ' of ' + count + ' users');
            }
            if (user.isSelected && user.status !== "success") {
                id_cards.push(Api.post('/Idcard?user_id=' + user._id).then((response) => {
                    user.status = "success";
                }).catch((error) => {
                    user.status = "failed";
                }));
            }
            count2 += 1;
        }
        await Promise.all(id_cards);
        await Promise.all([setErrorText('Generating Zip')]);
        await Promise.all([Api.post('/generateIDcardZIP', {
            user_ids: temp_dict.map((user) => user._id),
        }).then((response) => {
            setDownloadText(response.data.signed_url);
            window.open(response.data.signed_url, '_blank');
        }).catch((error) => {
            setErrorText('Error in generating zip');
        })])
        setErrorText('');
        await Promise.all([setAllowClose(true)])
    }
    return (
        <div style={{ backgroundColor: 'white', padding: '10px' }}>
            <Modal
                show={GenerateIDCardOpen}
                onHide={(e) => {
                    allowClose ? setGenerateIDCardOpen(false) : alert("Please wait for the process to complete");
                }}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Generate ID Card </Modal.Title>
                </Modal.Header>
                <Modal.Body
                    style={{
                        maxHeight: "calc(100vh - 200px)",
                        maxWidth: "calc(100vw - 100px)",
                        overflowY: "auto",
                        overflowX: "auto",
                    }}
                >
                    <div className="p-2" align="center">
                        {downloadText !== '' ? <b><>Download from here : <a target="_blank" href={downloadText}>Click Here</a></></b> : errorText !== '' ? <b>{errorText}</b> : null}
                    </div>
                    <Button
                        variant="secondary"
                        style={{
                            width: "100%",
                            marginBottom: "10px",
                        }}
                        onClick={async (e) => {
                            allowClose ? await generateCards() : alert("Please wait for the process to complete");
                        }}
                    >
                        Generate
                    </Button>
                    <div className="p-2" align="center">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th style={{
                                        minWidth: "50px",
                                        borderTop: "1px solid rgb(0 0 0 / 26%)",
                                        borderLeft: "none",
                                        borderRight: "1px solid rgb(0 0 0 / 26%)",
                                        borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                        textAlign: "center",
                                        whiteSpace: "nowrap"
                                    }}>Sr. No.</th>
                                    <th style={{
                                        minWidth: "150px",
                                        borderTop: "1px solid rgb(0 0 0 / 26%)",
                                        borderLeft: "none",
                                        borderRight: "1px solid rgb(0 0 0 / 26%)",
                                        borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                        textAlign: "center",
                                        whiteSpace: "nowrap"
                                    }}>Userame</th>
                                    <th style={{
                                        minWidth: "150px",
                                        borderTop: "1px solid rgb(0 0 0 / 26%)",
                                        borderLeft: "none",
                                        borderRight: "1px solid rgb(0 0 0 / 26%)",
                                        borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                        textAlign: "center",
                                        whiteSpace: "nowrap"
                                    }}>Conventional ID</th>
                                    <th style={{
                                        minWidth: "150px",
                                        borderTop: "1px solid rgb(0 0 0 / 26%)",
                                        borderLeft: "none",
                                        borderRight: "1px solid rgb(0 0 0 / 26%)",
                                        borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                        textAlign: "center",
                                        whiteSpace: "nowrap"
                                    }}>User Type</th>
                                    <th style={{
                                        minWidth: "100px",
                                        borderTop: "1px solid rgb(0 0 0 / 26%)",
                                        borderLeft: "none",
                                        borderRight: "none",
                                        borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                        textAlign: "center",
                                        whiteSpace: "nowrap"
                                    }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    selectedUsers.filter((user) => user.isSelected)?.map((user, index) => {
                                        if (user) {
                                            if (user.isSelected) {
                                                return (
                                                    <tr>
                                                        <td style={{
                                                            minWidth: "50px",
                                                            borderTop: "1px solid rgb(0 0 0 / 26%)",
                                                            borderLeft: "none",
                                                            borderRight: "1px solid rgb(0 0 0 / 26%)",
                                                            borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                                            textAlign: "center",
                                                            whiteSpace: "nowrap"
                                                        }}>{index + 1}</td>
                                                        <td style={{
                                                            minWidth: "150px",
                                                            borderTop: "1px solid rgb(0 0 0 / 26%)",
                                                            borderLeft: "none",
                                                            borderRight: "1px solid rgb(0 0 0 / 26%)",
                                                            borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                                            textAlign: "center",
                                                            whiteSpace: "nowrap"
                                                        }}>{user.name}</td>
                                                        <td style={{
                                                            minWidth: "150px",
                                                            borderTop: "1px solid rgb(0 0 0 / 26%)",
                                                            borderLeft: "none",
                                                            borderRight: "1px solid rgb(0 0 0 / 26%)",
                                                            borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                                            textAlign: "center",
                                                            whiteSpace: "nowrap"
                                                        }}>{user.conventionalId}</td>
                                                        <td style={{
                                                            minWidth: "150px",
                                                            borderTop: "1px solid rgb(0 0 0 / 26%)",
                                                            borderLeft: "none",
                                                            borderRight: "1px solid rgb(0 0 0 / 26%)",
                                                            borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                                            textAlign: "center",
                                                            whiteSpace: "nowrap"
                                                        }}>{user.userType}</td>
                                                        <td style={{
                                                            minWidth: "100px",
                                                            borderTop: "1px solid rgb(0 0 0 / 26%)",
                                                            borderLeft: "none",
                                                            borderRight: "none",
                                                            borderBottom: "1px solid rgb(0 0 0 / 26%)",
                                                            textAlign: "center",
                                                            whiteSpace: "nowrap"
                                                        }}>{
                                                                user.status === "running" ?
                                                                    <img src={`${process.env.PUBLIC_URL}/Images/loading.gif`} height="30" width="30" /> :
                                                                    user.status === "success" ?
                                                                        <CheckCircleOutlineOutlinedIcon /> :
                                                                        user.status === "failed" ?
                                                                            <ErrorOutlineOutlinedIcon /> :
                                                                            ""
                                                            }</td>
                                                    </tr>
                                                )
                                            }
                                            else {
                                                return null;
                                            }
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={(e) => {
                            allowClose ? setGenerateIDCardOpen(false) : alert("Please wait for the process to complete");
                        }}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <h1 className='p-4' style={{ textAlign: 'center', fontWeight: 'bold' }}>Generate ID Card</h1>
            <Button
                style={{
                    width: '100%',
                }} variant="primary"
                onClick={() => {
                    setDownloadText("");
                    setGenerateIDCardOpen(true);
                }}
            >
                Generate
            </Button>
            <GetUsers
                districtId={districtId}
                schoolId={schoolId}
                selectUsers={true}
                selectedUsersSet={setSelectedUsers}
                count={100}
                allowUserIdSelect={true}
            />
        </div>
    )
}

export default GenerateIDCard;