import React from "react";
import "./editmodal.css";
import { FaUserEdit } from "react-icons/fa";

function EditModal({
  closeEditModal,
  open,
  editConfirmed,
  message,
  userId,
  userName,
  userEmail,
  userContactNo,
  changevalue,
}) {
  if (open) {
    return (
      <div className="editModal">
        <div class="edit-modal-content">
          <div className="edit-modal-top"></div>
          <div className="edit-modal-bottom">
            <div className="edit-modal-icon">
              <FaUserEdit size={70} color={"black"} />
            </div>
            <div className="edit-modal-main-content">
              <p>{message}</p>
              <form>
                <div>
                  <label>Name</label>
                  <input value={userName} disabled />
                </div>
                <div>
                  <label>Email</label>
                  <input value={userEmail} disabled />
                </div>
                <div>
                  <label>ContactNo</label>
                  <input
                    type="number"
                    id="userContactNo"
                    max="10"
                    defaultValue={userContactNo}
                    onChange={(e) => changevalue(e)}
                  />
                </div>
              </form>
              <div className="edit-modal-buttons">
                <button onClick={() => closeEditModal()}>Cancel</button>
                <button onClick={() => editConfirmed()}>Confirm</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
}

export default EditModal;
