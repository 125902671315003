import React from 'react'
import { Box } from "@mui/material";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
const ListOfUsers = ({setPageNo,eventList,userList,setUserList}) => {
function backToPrev(){
    setPageNo(()=>1)
}

function validateUserSelection(){
   let checkingUserList=[...userList]
   let result=checkingUserList.every((x)=>x.checked===false) 
   if(result){
    alert("Minimum one Should be Selected")
   }else{
    setPageNo(()=>3)
   }
}

  return (
    <>
    <center  id="custom-wrapper-page2" 
         > 
        <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mb-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                            <Stack className="row" direction="horizontal">
                                <div className="col-12 col-sm-6" style={{ display: 'flex'}}>
                                    <Button variant="light" style={{ backgroundColor: "#FFF", color: "#6c757d" }} 
                                    // onhover={(e) => doNothing()} 
                                    onClick={()=>backToPrev()}
                                  >
                                        ← Previous
                                    </Button>
                                </div>
                                <div className="col-12 col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }}
                                     onClick={ () =>validateUserSelection() }
                                         >
                                        Next
                                    </Button>
                                </div>
                            </Stack>
                            <h2 className="mt-4">List of Users</h2>
                            <div>
                                <Button className="m-4" variant="outline-secondary"
                                 onClick={e => setUserList(userList?.map(us => ({ ...us, checked: false })))} style={{ minWidth: '150px' }} >
                                    Un-Check All
                                </Button>
                                <Button className="m-4" variant="outline-secondary"
                                 onClick={e => setUserList(userList?.map(us => ({ ...us, checked: true })))} style={{ minWidth: '150px' }} >
                                    Check All
                                </Button>
                            </div>
                            {/* {userEmptyCheckError?.isError && // as of now only alert ...err need to be added
                                <Box
                                sx={{color:"error.main"}}
                                noValidate
                                className="mb-1 mt-2 w-50"
                            >
                                {userEmptyCheckError.statement}
                            </Box>
                                } */}
                            <Table striped bordered hover responsive className="mt-4 mb-4">
                                <thead>
                                    <tr>
                                        <td><b>Sr. No.</b></td>
                                        <td><b>Name</b></td>
                                        <td><b>UserType</b></td>
                                        <td><b>Check/Uncheck</b></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userList?.map((user, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{user.name}</td>
                                                <td>{user.username||user.userType}</td>
                                                <td><Form.Check
                                                    type="checkbox"
                                                    style={{ padding: 10 }}
                                                    checked={user.checked}
                                                    onChange={(e) => {
                                                        let temp = [...userList];
                                                        temp[i].checked = !temp[i].checked;
                                                        setUserList(temp);
                                                    }}
                                                /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>

        
        
        </div>



        </center>
    </>
  )
}

export default ListOfUsers