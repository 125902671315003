import axios from "axios";

// console.log(process.env);
const Api = axios.create({
	baseURL: process.env.REACT_APP_AXIOS_URL
});
const token = localStorage.getItem("token");
if (token) {
	Api.defaults.headers["Authorization"] = `Bearer ${token}`;
	// console.log(`Bearer ${token}`);
}
export const setToken = (token_) => {
	if (token_) {
		console.log("Header set");
		Api.defaults.headers["Authorization"] = `Bearer ${token_}`;
		// console.log(`Bearer ${token_}`);
	} else {
		const token = localStorage.getItem("token");
		if (token) {
			Api.defaults.headers["Authorization"] = `Bearer ${token}`;
			// console.log(` localStorage Bearer ${token}`);
		}
	}
};
export const removeToken = () => {
	const token = localStorage.getItem("token");
	delete Api.defaults.headers.common["Authorization"];
};

export default Api;
