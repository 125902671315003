import React, { useState, useEffect } from "react";
import LoginForm from "./loginForm";
import "./login.css";
import { useLocation, useNavigate } from "react-router";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api, { setToken, removeToken } from "../../Api/axios";
import { Navigate, NavLink } from "react-router-dom";
import loginImage from "./Mobile-login-Cristina.jpg";
import loginImage1 from "./illustration_login.png"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { Stack } from 'react-bootstrap';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

function getWindowSize() {
	const { innerWidth, innerHeight } = window;
	return { innerWidth, innerHeight };
}
function Login() {
	const [error, setError] = useState();
	const [passwordType, setpasswordType] = useState("password");
	const [{ loggedIn, userDetails }, dispatch] = useDataLayerValue();
	const navigate = useNavigate();
	const location = useLocation();
	const [windowSize, setWindowSize] = useState(getWindowSize());
	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);
	const login = async (userCreds) => {
		let loginAproved = false;
		dispatch({
			type: "SET_LOADING",
			loading: true,
		});
		removeToken();
		var tempCredentials = {
			email: userCreds.email,
			password: userCreds.password,
		};
		// check if not a email
		if (tempCredentials.email.indexOf("@") === -1) {
			// if (process.env.NODE_ENV !== "production") {
			// 	if (!tempCredentials.email.startsWith("SDG")) {
			// 		tempCredentials.email = "SDG" + tempCredentials.email;
			// 	}
			// } else {
			if (!tempCredentials.email.startsWith("SN")) {
				tempCredentials.email = "SN" + tempCredentials.email;
			}
			// }
		}
		console.log("tempCredentials", tempCredentials);
		await Api.post("/user/login", { user: tempCredentials })
			.then((res) => {
				if (res.status === 201) {
					if (res.data.status === 401) {
						setError("Invalid Password");
					} else if (res.data.status === 404) {
						setError("User not found");
					} else {
						localStorage.setItem("token", res.data?.emailToken);
						localStorage.setItem("userDetails", JSON.stringify(res.data.currentUser));
						localStorage.setItem("usersLoggedIn", JSON.stringify([res.data.currentUser]));
						setToken();
						dispatch({
							type: "SET_LOGIN_STATUS",
							loggedIn: true,
						});
						dispatch({
							type: "SET_USER_DETAILS",
							userDetails: res.data.currentUser,
						});
						loginAproved = true;
					}

					if (loginAproved) {
						// if (location.state?.from) {
						// 	navigate(location.state.from);
						// } else {
						navigate("/");
						// }
					}
				} else {
					setError(res.data.message);
				}
			})
			.catch((err) => {
				setError(
					err?.response?.data?.message ||
					"Something went wrong! Please try again later"
				);
			});
		dispatch({
			type: "SET_LOADING",
			loading: false,
		});
	};
	const [userCred, setUserCred] = useState({ email: "", password: "" });
	return (
		<div align="center" style={{
			position: "absolute",
			top: "0px",
			right: "0px",
			bottom: "0px",
			left: "0px",
			background: "linear-gradient(to bottom right,#6E45E1, #89D4CF)", overflow: "-moz-scrollbars-none",
			alignItems: "center",
			justifyContent: "center",
			display: "flex",
		}}>
			<div className="mt-4 mb-4 shadow-lg col-11 col-lg-10 col-xl-9" style={{ borderRadius: 10, background: "#FFF", minHeight: "75%", height: "auto", display: "grid" }} align="center">
				<div className="row" style={{ height: "100%", width: "100%", margin: "0" }}>
					<div className="d-none d-md-block col-md-6 p-4"
						style={{
							background: "#F8F5F4",
							margin: 0,
							borderRadius: 10,
							alignItems: "center",
							justifyContent: "center",
							height: "100%",
							width: "50%",
						}}
					>
						<Stack className="pb-2" direction="vertical" gap={2} style={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
							<img src={`${process.env.PUBLIC_URL}/Images/login-vector.svg`} style={{ height: "90%", maxWidth: "100%" }} />
						</Stack>
					</div>
					<div className="col-12 col-md-6 d-none d-md-flex"
						style={{
							margin: 0,
							padding: 30,
							borderRadius: 10,
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							fontSize: "1vw"
						}}>
						<Stack className="pb-2" direction="vertical" gap={2} style={{ alignItems: "center", justifyContent: "center", display: "contents" }}>
							<Stack direction="horizontal" gap={2} style={{ alignItems: "center", justifyContent: "center", maxWidth: "100%" }}>
								<img src={`${process.env.PUBLIC_URL}/Images/logo.jpg`} alt="" style={{ width: "18%", marginBottom: 0 }} />
								<div class="vr"></div>
								<img src={`${process.env.PUBLIC_URL}/Images/vedanta-Logo.png`} alt="" style={{ width: "45%", marginBottom: 0 }} />
							</Stack>
							<h1 className="d-none d-md-block pt-1" style={{ fontSize: "3vw", fontWeight: "bold" }}>Youth India E-School</h1>
						</Stack>
						<p>Enter your details below to sign in to your account.</p>
						<Form className="pt-2">
							{error &&
								<p style={{ fontSize: "18px", color: "red", textDecoration: "None", paddingTop: "1vw" }}>{error}</p>}
							<Form.Group
								controlId="formBasicEmail"
								align="left"
								className="ps-2 pe-2"
							>
								<Form.Label>Email Address / Username</Form.Label>
								<Form.Control
									type="email"
									placeholder="Email"
									value={userCred.email}
									onChange={(e) =>
										setUserCred({ ...userCred, email: e.target.value })
									}
									style={{ fontSize: "1vw", backgroundColor: "#F1F1F1", borderRadius: "0.5vw", border: "none", width: "100%", marginBottom: 10 }}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicPassword" align="left"
								className="ps-2 pe-2"
							>
								<Form.Label>Password</Form.Label>
								<Form.Control
									type={passwordType}
									id="password_field"
									placeholder="Password"
									value={userCred.password}
									onChange={(e) =>
										setUserCred({ ...userCred, password: e.target.value })
									}
									style={{ fontSize: "1vw", backgroundColor: "#F1F1F1", borderRadius: "0.5vw", border: "none", width: "100%", marginBottom: 10, display: "inline" }}

								/>
								{passwordType === "password" ? (
									<VisibilityOffIcon onClick={() => setpasswordType('text')} style={{ margin: '-7%', cursor: "pointer", width: "5%" }} />
								) : (
									<VisibilityIcon onClick={() => setpasswordType('password')} style={{ margin: '-7%', cursor: "pointer", width: "5%" }} />
								)}

							</Form.Group>
							<Form.Group controlId="login" align="left"
								className="pt-2 ps-2 pe-2"
							>
								<Button variant="primary" type="submit" style={{ fontSize: "1.2vw", backgroundColor: " #6E45E1", borderRadius: "0.5vw", border: "none", minWidth: "100%", marginBottom: 5 }}
									onClick={(e) => {
										e.preventDefault();
										login(userCred);
									}}
								>
									Login
								</Button>
							</Form.Group>
							<p>Forgot Password? <Link to="/login/forgetPassword" style={{ color: "#6E45E1", textDecoration: "None" }}>Reset Now</Link></p>
						</Form>
					</div>
					<div className="col-12 col-md-6 d-flex d-md-none "
						style={{
							margin: 0,
							padding: 30,
							borderRadius: 10,
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							fontSize: "3vw"
						}}>
						<Stack className="pb-2" direction="vertical" gap={2} style={{ alignItems: "center", justifyContent: "center", display: "contents" }}>
							<Stack direction="horizontal" gap={2} style={{ alignItems: "center", justifyContent: "center", maxWidth: "100%" }}>
								<img src={`${process.env.PUBLIC_URL}/Images/logo.jpg`} alt="" style={{ width: "18%", marginBottom: 0 }} />
								<div class="vr"></div>
								<img src={`${process.env.PUBLIC_URL}/Images/vedanta-Logo.png`} alt="" style={{ width: "45%", marginBottom: 0 }} />
							</Stack>
							<h1 className="d-block d-md-none pt-1" style={{ fontSize: "7vw", fontWeight: "bold" }}>Youth India E-School</h1>
						</Stack>
						<p>Enter your details below to sign in to your account.</p>
						<Form className="pt-2">
							{error &&
								<p style={{ fontSize: "18px", color: "red", textDecoration: "None", paddingTop: "1vw" }}>{error}</p>}
							<Form.Group
								controlId="formBasicEmail"
								align="left"
								className="ps-2 pe-2"
							>
								<Form.Label>Email Address / Username</Form.Label>
								<Form.Control
									type="email"
									placeholder="Email"
									value={userCred.email}
									onChange={(e) =>
										setUserCred({ ...userCred, email: e.target.value })
									}
									style={{ fontSize: "3vw", backgroundColor: "#F1F1F1", borderRadius: "0.5vw", border: "none", width: "100%", marginBottom: 10 }}
								/>
							</Form.Group>
							<Form.Group controlId="formBasicPassword" align="left"
								className="ps-2 pe-2"
							>
								<Form.Label>Password</Form.Label>
								<Form.Control
									type={passwordType}
									placeholder="Password"
									value={userCred.password}
									onChange={(e) =>
										setUserCred({ ...userCred, password: e.target.value })
									}
									style={{ fontSize: "3vw", backgroundColor: "#F1F1F1", borderRadius: "0.5vw", border: "none", width: "100%", marginBottom: 10, display: "inline" }}
								/>
								{passwordType === "password" ? (
									<VisibilityOffIcon onClick={() => setpasswordType('text')} style={{ margin: '-7%', cursor: "pointer", width: "5%" }} />
								) : (
									<VisibilityIcon onClick={() => setpasswordType('password')} style={{ margin: '-7%', cursor: "pointer", width: "5%" }} />
								)}
							</Form.Group><Form.Group controlId="login" align="left"
								className="pt-2 ps-2 pe-2"
							>
								<Button variant="primary" type="submit" style={{ fontSize: "3vw", backgroundColor: " #6E45E1", borderRadius: "0.5vw", border: "none", minWidth: "100%", marginBottom: 5 }}
									onClick={(e) => {
										e.preventDefault();
										login(userCred);
									}}
								>
									Login
								</Button>
							</Form.Group>
							<p>Forgot Password? <Link to="/login/forgetPassword" style={{ color: "#6E45E1", textDecoration: "None" }}>Reset Now</Link></p>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
