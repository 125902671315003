import { SearchOutlined, Translate } from '@material-ui/icons'
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography,Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { Stack } from 'react-bootstrap'
//fakedata
import {filtersBy} from "./Data"
//mui
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import SubmissionList from './SubmissionList'
import { useEffect } from 'react'
import Api from '../../../Api/axios'
import AssessmentCard from './AssessmentCard'
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

const AssessmentEvaluator = () => {
function changeFilterForAssessment(e){
    setFilterAssessment(e?.target?.value)
    // return true
}

const [filterAssessment,setFilterAssessment]=useState(filtersBy[0])
// const [AssessmentDatas,setAssessmentDatas]=useState(AssessmentData)
const[details,SetDetails]=useState({
    status:false,
    data:{}
})
const [{userDetails }, dispatch] = useDataLayerValue();
const[search,setSearch]=useState("")
// const [filterType,setFilterType]=useState("All")
const[filteredList,SetFilteredList]=useState({
    status:false,
    data:{}
})

const[SubmisionList,SetSubmisionList]=useState({
    status:false,//css test
    data:{}
})
const [assessmentData,setAssessmentData]=useState([]);
useEffect(()=>{
    Api.get(`/assessment/created-assessment`).then((res)=>{
        console.log("assessment created",res)
        setAssessmentData(()=>res.data)
    })
},[])
const[ratio,setRatio]=useState([])
// const[isActive,setIsActive]=useState([])
const[assessmentId,setAssessmentId]=useState("")
const [assessmentName,setAssessmentName]=useState("")
function fetchSubmissionList(e){
    dispatch({
        type: "SET_LOADING",
        loading: true,
    })
    let currentId=(e?.target?.value)
    let currentAssTitle=e?.target?.dataset?.title
    // console.log("title finding",e.target,e.target.dataset.title)
    Api.get(`/assessment/submission/users-submitted?assessment_id=${currentId}`).then((res)=>{
        //as of now no submission
        console.log("submitted id s",res)
        setAssessmentName(()=>currentAssTitle)
        setAssessmentId(()=>currentId)
        SetSubmisionList((pre)=>{
            return{
                ...pre,
                status:!SubmisionList.status,
                data:res?.data
            }
        })
        dispatch({
            type: "SET_LOADING",
            loading: false,
        })
    })
}



useEffect( ()=>{
    let updatedRatio=[]
    if(assessmentData.length!==0){
        assessmentData.map( (x,i)=>{
       async function fetchUserSubmission(){
                await Api.get(`/assessment/submission/users-submitted?assessment_id=${x._id}`).then((res)=>{
                    // console.log(i,'submissi=====>',res.data)
                    if(updatedRatio[i]){
                        let setRatioupdated=[...updatedRatio]
                        setRatioupdated[i]={
                            ...setRatioupdated[i],
                            submitted:res.data.length
                        }
                        updatedRatio[i]=setRatioupdated[i]
                        // console.log(i+1," submist 1", updatedRatio[i])
                        if(i===assessmentData.length-1){
                            // console.log("submist 1", updatedRatio)
                            setRatio(()=>percentageUpdate(updatedRatio))
                        }
                    }else{
                        let setRatioupdated=[...updatedRatio]
                        setRatioupdated[i]={
                            submitted:res.data.length
                        }
                        updatedRatio[i]=setRatioupdated[i]
                        // console.log(i+1," submist 2", updatedRatio[i])
                        if(i===assessmentData.length-1){
                            // console.log("submist 2", updatedRatio)
                            setRatio(()=>percentageUpdate(updatedRatio))
                        }
                    }         
                })
           
        }
        async function fetchUsernotSubmission(){
              await Api.get(`/assessment/submission/users-not-submitted?assessment_id=${x._id}`).then((res)=>{
                console.log("ass ._id",x._id,'not submissi=====>',res.data)
                if(updatedRatio[i]){
                    let setRatioupdated=[...updatedRatio]
                    // console.log("before",updatedRatio,setRatioupdated)
                    setRatioupdated[i]={
                        ...setRatioupdated[i],
                        not_submitted:res.data.length
                    }
                    // console.log("after",updatedRatio,setRatioupdated)
                    updatedRatio[i]=setRatioupdated[i]
                    // console.log(i+1," not submist 1", updatedRatio[i])
    
                if(i===assessmentData.length-1){
                    // console.log("not submist 1", updatedRatio)
                    setRatio(()=>percentageUpdate(updatedRatio))
                }
                }else{
                    let setRatioupdated=[...updatedRatio]
                    setRatioupdated[i]={
                        not_submitted:res.data.length
                    }
                    updatedRatio[i]=setRatioupdated[i]
                    // console.log(i+1," not submist 2", updatedRatio[i])
                    if(i===assessmentData.length-1){
                        // console.log("not submist 2", updatedRatio)
                        setRatio(()=>percentageUpdate(updatedRatio))
                    }
                }
            })
        }

         fetchUserSubmission();
         fetchUsernotSubmission();
      
        }) 
    }
           

},[assessmentData])

// useEffect(()=>{
function  percentageUpdate(currentArray){
        let updatedRatio=[...currentArray]
        updatedRatio.map((x)=>{
            // let currentobj={...x}
            if(x){
                let total=x?.submitted+x?.not_submitted;
                let percent=parseInt(x?.submitted/total)*100;
                x.percentage=percent
            }
            // console.log("x",x)
            return x
        })
        return updatedRatio
}
   
// },[ratio])
// const [additionalDetails,setadditionalDetails]=useState({})
// useEffect(()=>{
// //setIsActive change data to expire or acive or no time limit
// // console.log("ratio",ratio)
// if(details.status){
// let additional={}
// const {created_by_name,createdAt,ended_at,late_submission,total_questions}=details.data
// // additional.creatorName=
    

// }
// },[details])

  return (
    <div 
    >
    
        {!SubmisionList?.status&& !filteredList?.status&&
    
     <Grid container style={{opacity:details.status?"0.5":""}} >
                <Grid xs={12} md={12} sm={12} lg={12} item>
                    <Typography variant='h4' align='center' className='mt-3'>Assessment Evaluator</Typography>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={12} className='mt-5 mb-3 p-4'>
                    <Grid container spacing={4}>
                         <Grid item xs={12} md={12} sm={12} lg={6}>
                            <TextField
                            variant='outlined'
                            className='w-100'
                            label="Search by"
                            onChange={(e)=>setSearch(()=>e.target.value)}
                            InputProps={{
                                startAdornment:(
                                    <IconButton>
                                        <SearchOutlined/>
                                    </IconButton>
                                )
                            }}
                    
                            />
                         </Grid>
                        <Grid item xs={12} md={12} sm={12} lg={6}>
                            <FormControl fullWidth>
                                <InputLabel>Filter by</InputLabel>
                                <Select
                                label="Filter by"
                                labelId='Filter'
                                value={filterAssessment}
                                onChange={(e)=>changeFilterForAssessment(e)}
                                >
                                {filtersBy.map((x,i)=><MenuItem key={x} value={x}>{x}</MenuItem>)}
                                </Select>
                            </FormControl>

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12} sm={12} lg={12} className='mt-2'>
                <Typography variant='h4' align='center' >Assessment Details</Typography>
                
               
                <Grid container className='mt-2 w-100 ps-5 pe-5' spacing={4}>
                            
                    {filterAssessment==="All"&& filterAssessment!=="Recent"&&filterAssessment!=="Urgency"&&
                       assessmentData?.filter((x)=>{
                        if(x.title.includes(search)){
                            return 1
                        }else{
                            return 0
                        }
                       })
                       .map((AssObj,i)=><AssessmentCard ratio={ratio} i={i} AssObj={AssObj} SetDetails={SetDetails} fetchSubmissionList={fetchSubmissionList}/>
                       )
                    }
                    {filterAssessment!=="All"&& filterAssessment==="Urgency"&&
                          assessmentData?.filter((x)=>{
                            if(x.title.includes(search)){
                                return 1
                            }else{
                                return 0
                            }
                           })
                            .sort((a,b)=>{ //descending
                                if(new Date(a.ended_at)>new Date(b.ended_at)){
                                    return 1
                                }else{
                                    return -1
                                }
                               })
                               .map((AssObj,i)=><AssessmentCard ratio={ratio} i={i} AssObj={AssObj} SetDetails={SetDetails} fetchSubmissionList={fetchSubmissionList}/>)
                    }
                     {filterAssessment!=="All"&& filterAssessment==="Recent"&&
                          assessmentData?.filter((x)=>{
                            if(x.title.includes(search)){
                                return 1
                            }else{
                                return 0
                            }
                           })
                           .sort((a,b)=>{ //Ascediing
                            if(new Date(a.ended_at)>new Date(b.ended_at)){
                                return -1
                            }else{
                                return 1
                            }
                           }) 
                               .map((AssObj,i)=><AssessmentCard ratio={ratio} i={i} AssObj={AssObj} SetDetails={SetDetails} fetchSubmissionList={fetchSubmissionList}/>)
                    }
            
                </Grid>
                </Grid>
        </Grid>
          }
        {details.status &&
            <Box sx={{border:"2px solid black",width:"30%",borderRadius:"20px" ,position:'absolute',top:"50%", left:"50%",zIndex:10,transform:"translate(-50%,-50%)"}} >
                    <Box  style={{backgroundColor:"#FFFFFF",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", borderRadius:"20px"}}>
                            <Box className="ps-4 pe-4 px-4 py-4"  style={{backgroundColor:"#FFFFFF", width:"80%", height:"80%"}}>
                            <Stack direction="horizontal" className='justify-content-start'>
                                <Box><TurnedInIcon/></Box>
                                <Box>{details.data.title}</Box>
                            </Stack>
                            <Stack direction="vertical" className='justify-content-around' >
                            {/* <Box>{details.data.percentage===0||details.data.percentage===NaN||!details.data.percentage?"No submission":details?.data?.percentage +"% submission Rate"} </Box>percentage submission */}
                            <Box>Total Questions : { details.data.total_questions||" No questions"}</Box>
                            <Box>Created at : {`${new Date(details.data.createdAt).getDate()}/${new Date(details.data.createdAt).getMonth()+1}/${new Date(details.data.createdAt).getFullYear()}`}</Box>
                            {!details.data.late_submission&&
                            <Box>Ended at :{`${new Date(details.data.ended_at).getDate()}/${new Date(details.data.ended_at).getMonth()+1}/${new Date(details.data.ended_at).getFullYear()}`}</Box>   
                            }
                            <Box>{details.data.late_submission===true?"No Time Limit":new Date(details.data.ended_at).getTime()>Date.now()?"Active":"Time Expired"}</Box> {/*Due date finished or not */}
                            <Box>Created by :{details.data.created_by_name}</Box>
                            </Stack>
                            <Stack  direction="horizontal" className='justify-content-around align-items-center mt-3'>
                                <Button variant='contained'  onClick={()=>SetDetails((pre)=>{
                                    let updated={...pre}
                                    updated.status=false
                                    updated.data={}
                                    return updated
                                })}
                                
                                >Close</Button>
                            </Stack>
                            </Box>
                        </Box>
                    </Box>

                    }
                    {SubmisionList?.status&&
                            <SubmissionList assessmentName={assessmentName} ass_id={assessmentId} SetSubmisionList={SetSubmisionList} assDetails={SubmisionList.data}/>
                    }
                    {

                    }
    </div>
  )
}

export default AssessmentEvaluator