import { React, useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from 'react-bootstrap';
import Api from '../../Api/axios';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { SettingsInputCompositeSharp } from '@material-ui/icons';
import Success from '../ErrorSuccess/Success';
import { Modal } from 'react-bootstrap';
import MapPicker from 'react-google-map-picker'

const AddSchool = () => {
    const [{ userDetails, initialState, loggedIn }, dispatch] = useDataLayerValue();
    const [schoolName, setSchoolName] = useState('');
    const [schoolConventionalID, setSchoolConventionalID] = useState('');
    const [schoolAddress, setSchoolAddress] = useState('');
    const [schoolLogo, setSchoolLogo] = useState([]);
    const [schoolPhoneNo, setSchoolPhoneNo] = useState('');
    const [schoolEmail, setSchoolEmail] = useState('');
    const [schoolWebsite, setSchoolWebsite] = useState('');
    const [schoolSession, setSchoolSession] = useState('');
    const [schoolStartingClass, setSchoolStartingClass] = useState('');
    const [schoolEndingClass, setSchoolEndingClass] = useState('');
    const [schoolMaxSection, setSchoolMaxSection] = useState('');
    const [schoolMaxStudents, setSchoolMaxStudents] = useState('');
    const [schoolSubjects, setSchoolSubjects] = useState([]);
    const [nameError, setNameError] = useState(false);
    const [conventionalIDError, setConventionalIDError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [logoError, setLogoError] = useState(false);
    const [phoneNoError, setPhoneNoError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [sessionError, setSessionError] = useState(false);
    const [classRangeError, setClassRangeError] = useState(false);
    const [sectionRangeError, setSectionRangeError] = useState(false);
    const [maxStudentsError, setMaxStudentsError] = useState(false);
    const [subjectsError, setSubjectsError] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [location, setLocation] = useState({ lat: 22.122766, lng: 84.042715 });
    useEffect(() => {
        Api.get('/session-entity?district_id=' + userDetails?.districtId).then((response) => {
            setSessions(response.data);
            if (response.data.length > 0) {
                setSchoolSession(response.data[response.data.length - 1]._id);
            } else {
                setSessionError(true);
            }
        });
    }, []);
    useEffect(() => {
        Api.get('/preconfig-entites/subjects').then((response) => {
            setSubjectList(response.data.filter((subject) => subject.name != null));
        });
    }, [userDetails?.districtId]);
    const validateSchoolName = (e) => {
        if (e.target.value.length < 1) {
            setNameError(true);
        } else {
            setNameError(false);
        }
        setSchoolName(e.target.value);
    };
    const validateSchoolConventionalID = (e) => {
        if (e.target.value.length < 1) {
            setConventionalIDError(true);
        } else {
            setConventionalIDError(false);
        }
        setSchoolConventionalID(e.target.value);
    };
    const validateSchoolAddress = (e) => {
        if (e.target.value.length < 1) {
            setAddressError(true);
        } else {
            setAddressError(false);
        }
        setSchoolAddress(e.target.value);
    };
    const validateSchoolLogo = (e) => {
        if (e.target.files.length < 1) {
            setLogoError(true);
        } else {
            if (e.target.files[0].size > 1048576) {
                setLogoError(true);
            } else if (e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png') {
                setLogoError(true);
            } else {
                setLogoError(false);
                setSchoolLogo(e.target.files[0]);
            }
        }
    };
    const validateSchoolPhoneNo = (e) => {
        if (e.target.value.length < 1) {
            setPhoneNoError(true);
        } else {
            if (e.target.value.match(/^[0-9]{10}$/)) {
                setPhoneNoError(false);
            } else {
                setPhoneNoError(true);
            }
        }
        setSchoolPhoneNo(e.target.value);
    };
    const validateSchoolEmail = (e) => {
        if (e.target.value.length < 1) {
            setEmailError(false);
        } else {
            if (e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setEmailError(false);
            } else {
                setEmailError(true);
            }
        }
        setSchoolEmail(e.target.value);
    };
    const validateSchoolWebsite = (e) => {
        if (e.target.value.length < 1) {
            setWebsiteError(true);
        } else {
            if (e.target.value.match(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm)) {
                setWebsiteError(false);
            } else {
                setWebsiteError(true);
            }
        }
        setSchoolWebsite(e.target.value);
    };
    const validateStartingClass = (e) => {
        let val = parseInt(e.target.value)
        setSchoolStartingClass(val);
        if (val < 1 || isNaN(val) || !schoolEndingClass || val > schoolEndingClass) {
            setClassRangeError(true);
        } else {
            setClassRangeError(false);
        }
    }
    const validateEndingClass = (e) => {
        let val = parseInt(e.target.value)
        setSchoolEndingClass(val);
        console.log(schoolStartingClass, val, schoolStartingClass > val);
        if (val < 1 || isNaN(val) || !schoolStartingClass || schoolStartingClass > val) {
            setClassRangeError(true);
        } else {
            setClassRangeError(false);
        }
    }
    const validateSection = (e) => {
        if (e.target.value < 1) {
            setSectionRangeError(true);
        } else {
            setSectionRangeError(false);
        }
        setSchoolMaxSection(e.target.value);
    }
    const validateMaxStudents = (e) => {
        if (e.target.value < 1) {
            setMaxStudentsError(true);
        } else {
            setMaxStudentsError(false);
        }
        setSchoolMaxStudents(e.target.value);
    }
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [error, setError] = useState("");

    const validate = () => {
        let valid = true;
        if (schoolName.length < 1) {
            setNameError(true);
            valid = false;
        }
        if (schoolConventionalID.length < 1) {
            setConventionalIDError(true);
            valid = false;
        }
        if (schoolAddress.length < 1) {
            setAddressError(true);
            valid = false;
        }
        if (schoolPhoneNo.length < 1) {
            setPhoneNoError(true);
            valid = false;
        }
        if (schoolEmail.length < 1) {
            setEmailError(false);
            valid = false;
        }
        if (!schoolStartingClass || !schoolEndingClass || schoolStartingClass > schoolEndingClass) {
            setClassRangeError(true);
            valid = false;
        }
        if (!schoolMaxSection) {
            setSectionRangeError(true);
            valid = false;
        }
        if (!schoolMaxStudents) {
            setMaxStudentsError(true);
            valid = false;
        }
        if (schoolSubjects.length < 1) {
            valid = false;
            alert('Please add atleast one subject.');
        }
        if (!schoolLogo) {
            setLogoError(true);
            valid = false;
        }
        if (!valid || nameError || conventionalIDError || addressError || logoError || phoneNoError || emailError || websiteError || classRangeError || sectionRangeError || maxStudentsError || logoError) {
            return false;
        } else {
            const data = new FormData();
            data.append('name', schoolName);
            data.append('short_name', schoolName);
            data.append('conventional_id', schoolConventionalID);
            data.append('address', schoolAddress);
            data.append('logo', schoolLogo);
            data.append('Contact_number', schoolPhoneNo);
            data.append('email', schoolEmail);
            data.append('website', schoolWebsite);
            data.append('class_range_start', schoolStartingClass);
            data.append('class_range_end', schoolEndingClass);
            data.append('max_number_of_sections', schoolMaxSection);
            data.append('students_per_section', schoolMaxStudents);
            data.append('session_id', schoolSession);
            schoolSubjects.map((subject, index) => {
                data.append('subject_ids[]', subject._id);
            });
            console.log(schoolLogo)
            dispatch({
                type: "SET_LOADING",
                loading: true,
            });
            Api.post("/school-entity/create", data, schoolLogo).then((response) => {
                console.log(response);
                dispatch({
                    type: "SET_LOADING",
                    loading: false,
                });
                setSuccess(true);
            }).catch((err) => {
                setFailure(true);
                setError(err.message);
                dispatch({
                    type: "SET_LOADING",
                    loading: false,
                });
            })
        }
    }

    return (
        <div className='' align="center">
            {success ?
                <Modal show={success} onHide={() => setSuccess(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Successfully Created School</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setSuccess(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                : failure ?
                    <Modal show={failure} onHide={() => setFailure(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Failure</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Error: {error} <br /> May be because of duplicate conventioanl Id.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setFailure(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            <div className="col-12 p-4" style={{ backgroundColor: "#fff" }} align="center">
                <h1>Create School</h1>
                <h5 className="mt-3" style={{ fontWeight: "bold" }} align="left">Basic Details</h5>
                <hr className="solid" style={{ marginTop: "1px" }} />
                <Form>
                    <Form.Group controlId="schoolName" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Name</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                placeholder="Enter School Name"
                                isInvalid={nameError}
                                value={schoolName}
                                onChange={(e) => validateSchoolName(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a school name.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="schoolAddress" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Address</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                as="textarea"
                                placeholder="Enter School Address"
                                isInvalid={addressError}
                                value={schoolAddress}
                                onChange={(e) => validateSchoolAddress(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter Address.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="schoolAddress" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Map Location</Form.Label>
                        <MapPicker
                            defaultLocation={location}
                            zoom={15}
                            mapTypeId={'satellite'}
                            style={{
                                height: '400px',
                                borderRadius: '10px',
                            }}
                            onChangeLocation={(lat, lng) => {
                                setLocation({ lat: lat, lng: lng });
                            }}
                            apiKey='AIzaSyAqZdlAvnIAF3I9PN8qeM0uOu53QyVtNsU'
                        />
                    </Form.Group>



                    
                    <Form.Group controlId="schoolAddress" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Block Name</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                as="textarea"
                                placeholder="Enter School Address"
                                isInvalid={addressError}
                                value={schoolAddress}
                                onChange={(e) => validateSchoolAddress(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter Address.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="schoolAddress" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Block Code</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                as="textarea"
                                placeholder="Enter School Address"
                                isInvalid={addressError}
                                value={schoolAddress}
                                onChange={(e) => validateSchoolAddress(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter Address.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>





                    <Form.Group controlId="schoolLogo" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label align="left">Logo</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="file"
                                placeholder="Upload Logo"
                                isInvalid={logoError}
                                onChange={(e) => validateSchoolLogo(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please select a valid Logo File (PNG/JPG , Size less than 1MB).
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="schoolPhoneNo" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Phone Number</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                placeholder="Enter Phone Number"
                                isInvalid={phoneNoError}
                                value={schoolPhoneNo}
                                onChange={(e) => validateSchoolPhoneNo(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Phone Number.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="schoolEmail" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Email</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="email"
                                placeholder="Enter Email"
                                isInvalid={emailError}
                                value={schoolEmail}
                                onChange={(e) => validateSchoolEmail(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Email.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="schoolWebsite" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Website</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="text"
                                placeholder="Enter Website"
                                isInvalid={websiteError}
                                value={schoolWebsite}
                                onChange={(e) => validateSchoolWebsite(e)}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Website.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <h5 className="mt-4" style={{ fontWeight: "bold" }} align="left">LifeCycle Details</h5>
                    <hr className="solid" style={{ marginTop: "1px" }} />
                    {/* Current Session */}
                    <Form.Group controlId="currentSession" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Current Session</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                as="select"
                                isInvalid={sessionError}
                                value={schoolSession}
                                onChange={(e) => setSchoolSession(e.target.value)}

                            >
                                {
                                    sessions.map((session) => {
                                        return (
                                            <option key={session._id} value={session._id}>{session.name}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please Add Session For District First.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="classRange" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Class Range</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="number"
                                placeholder="Starting Class"
                                isInvalid={classRangeError}
                                value={schoolStartingClass}
                                onChange={(e) => validateStartingClass(e)}

                            />
                            <Form.Control
                                type="number"
                                placeholder="Ending Class"
                                isInvalid={classRangeError}
                                value={schoolEndingClass}
                                onChange={(e) => validateEndingClass(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid class range.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="sectionRange" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Maximum No. of Sections</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="number"
                                placeholder="Maximum No. of Sections"
                                isInvalid={sectionRangeError}
                                value={schoolMaxSection}
                                onChange={(e) => validateSection(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid section numbers.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="maxStudents" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Maximum No. of Students per Section</Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                type="number"
                                placeholder="Enter Maximum No. of Students per Section"
                                isInvalid={maxStudentsError}
                                value={schoolMaxStudents}
                                onChange={(e) => validateMaxStudents(e)}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid number.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="subjects" className="m-3" style={{ textAlign: "left" }}>
                        <Form.Label>Subjects</Form.Label>
                        <Multiselect
                            options={subjectList}
                            displayValue="name"
                            placeholder="Select Subjects"
                            style={{ minWidth: "12rem" }}
                            selectedValues={schoolSubjects}
                            onSelect={(selectedList, selectedItem) => { setSchoolSubjects(selectedList); }}
                            onRemove={(selectedList, removedItem) => { setSchoolSubjects(selectedList); }}
                        />
                    </Form.Group>
                    <h5 className="mt-4" style={{ fontWeight: "bold" }} align="left">School Inscharge Details</h5>
                    <hr className="solid" style={{ marginTop: "1px" }} />
                    <Button variant="secondary" onClick={(e) => validate()} className="m-3">
                        Create School
                    </Button>
                </Form>
            </div>
            {console.log(
                schoolName,
                schoolConventionalID,
                schoolAddress,
                schoolPhoneNo,
                schoolEmail,
                schoolWebsite,
                schoolSession,
                schoolStartingClass,
                schoolEndingClass,
                schoolMaxSection,
                schoolMaxStudents
            )}
        </div>
    );
}

export default AddSchool;