import {React, useState, useEffect} from 'react';
import {Table} from 'react-bootstrap';

const Show_Edit_Schools = () => {
    return (
        <div align="center" className='m-4'>
            <h1 className='mb-4'> Show/Edit Schools </h1>
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>Sr. No.</th>
                    <th>Name</th>
                    <th>Conventional ID</th>
                    <th>Address</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Website</th>
                    <th>Edit</th>
                    </tr>
                </thead>

                    
            </Table>
        </div>
    );
};

export default Show_Edit_Schools;