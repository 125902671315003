import React, { useEffect, useState } from "react";
import "./body.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Calendar from "../Calendar/Calendar";
import Assesment from "../Assesment/Assesment";
import Auroscholar from "../Assesment/Auroscholar";
import User_profile from "../User_Profile/Student-User-Profile/User_profile";
import User_Edit from "../User_Profile/Student-User-Profile/User_Edit";
import Home from "../Dashboard/Home";
import EditUser from "../UserManagement/EditUser";

// Imports for notices
import NoticesStudent from "../Notices/Notices_Student/Notices_Student";
import Notices_Teacher from "../Notices/Notices_Teacher/Notices_Teacher";
import Notices_Admin from "../Notices/Notices_Admin/Notices_Admin";
import Notices_Admin_Create from "../Notices/Notices_Admin/Notices_Admin_Create";
import Notices_Teacher_Create from "../Notices/Notices_Teacher/Notices_Teacher_Create";
import ShowYourNotices from "../Notices/ShowNotices/ShowYourNotices";
import ShowUploadedNotices from "../Notices/ShowNotices/ShowUploadedNotices";
import ShowSchoolNotices from "../Notices/ShowNotices/ShowSchoolNotices";

// imports for live classes
import LiveClasses_Student from "../LiveClasses/LiveClasses_Student/LiveClasses_Student";
import LiveClasses_Admin from "../LiveClasses/LiveClasses_Admin/LiveClasses_Admin";
import LiveClasses_Teacher from "../LiveClasses/LiveClasses_Teacher/LiveClasses_Teacher";
import CreateLiveClass from "../LiveClasses/CreateLiveClass";
import ViewLiveClasses from "../LiveClasses/ViewLiveClasses";

// imports for chat
import Chat from "../Chat/Chat";
import AddGroup from "../Chat/AddGroup";

// imports for dashboards
import Admin_Dashboard from "../Dashboard/Admin_Dashboard/Admin_Dashboard";
import Teacher_Dashboard from "../Dashboard/Teacher_Dashboard/Teacher_Dashboard";
import Student_Dashboard from "../Dashboard/Student_Dashboard/Student_Dashboard";
import SchoolDashboard from "../Dashboard/SchoolDashboard";
import DistrictDashboard from "../Dashboard/DistrictDashboard";
import SchoolIdDashboard from "../Dashboard/SchoolIdDashboard";
import UserDashboard from "../Dashboard/UserDashboard";

// Imports for reports
import Student_Reports from "../Reports/Student_Reports/Student_Reports";
import ReportsInner from "../Reports/Student_Reports/ReportsInner/ReportsInner";
import Treports from "../Reports/Teacher_Reaports/Treports";
import Myreport from "../Reports/Teacher_Reaports/My_report";
import Evalution from "../Reports/Teacher_Reaports/Evalution";
import Gevalution from "../Reports/Teacher_Reaports/Go_To_Evalution/Go_to_evalution";
import ADash from "../Reports/Teacher_Reaports/Go_To_Evalution/Assess/Assessment_dash";
import Student from "../Reports/Teacher_Reaports/Go_To_Evalution/Assess/Student/Student";
import Over from "../Reports/Teacher_Reaports/Go_To_Evalution/Over_All/Over_All";
import Areports from "../Reports/Admin-Reports/Admin_reports";
import CEvollution from "../Reports/Admin-Reports/Class/CEvollution";
import SEvolution from "../Reports/Admin-Reports/School/SEvolution";
import TEvolution from "../Reports/Admin-Reports/Teacher/TEvolution";
import GAEvolution from "../Reports/Admin-Reports/Class/Go-to-AEvlution/Go_to_AEvolution";
import Admin_Assess from "../Reports/Admin-Reports/Class/Go-to-AEvlution/Admin-Assess/Admin_Assess";
import Admin_overAll from "../Reports/Admin-Reports/Class/Go-to-AEvlution/Admin-overall/Admin_overAll";
import AStudent from "../Reports/Admin-Reports/Class/Go-to-AEvlution/Admin-Assess/Admin-Student/AStudent";

// Imports for Library
import CourseLibrary from "../Library/CourseLibrary";
import Tlibrary from "../Library/Teacher_Library/Tlibrary";
import Ucontent from "../Library/Teacher_Library/Uploaded_content/Uploaded_content";
import School_Admin_Library from "../Library/School_Admin_Library/School_Admin_Library";
import Slibrary from "../Library/Student_Library/Slibrary";
import Slibrary_Topicwise from "../Library/Student_Library/Slibrary_Topicwise";
import ContentPlayer from "../Library/ContentPlayer/ContentPlayer";
import School_Admin_Library_Lib from "../Library/School_Admin_Library/School_Admin_Library_Lib";
import ShowSubjects from "../Show/ShowSubjects";
import AddChapter from "../Library/Teacher_Library/NewContent/AddChapter";
import AddTopic from "../Library/Teacher_Library/NewContent/AddTopic";
import AddSubTopic from "../Library/Teacher_Library/NewContent/AddSubTopic";
import School_Admin_Library_Content_Comp from "../Library/School_Admin_Library/School_Admin_Library_Content_Comp";
import School_Admin_Library_Student_Wise_Progress from "../Library/School_Admin_Library/School_Admin_Library_Student_Wise_Progress";
import Create_Inhouse_Class from "../Library/InHouse_Library/Create_Inhouse_class";
import Create_Inhouse_Subject from "../Library/InHouse_Library/Create_Inhouse_Subject";
import Add_Inhouse_Chapter from "../Library/InHouse_Library/Add_Inhouse_Chapter";
import Add_Inhouse_Topic from "../Library/InHouse_Library/Add_Inhouse_Topic";
import Add_Inhouse_Subtopic from "../Library/InHouse_Library/Add_Inhouse_Subtopic";
import Inhouse_Library from "../Library/InHouse_Library/InHouse_Library_Lib/Inhouse_Library";
import Inhouse_Library_Lib from "../Library/InHouse_Library/InHouse_Library_Lib/Inhouse_Library_Lib";
import Course from "../Library/Course/modify_course";
import AddYieCourse from "../Library/ContentMangement/AddYieCourse";
import AddYieChapter from "../Library/ContentMangement/AddYieChapter";
import AddYieContent from "../Library/ContentMangement/AddYieContent";
import LMS_Tracker from "../Library/Tracker/LMS_Tracker";
import SchoolLevelLMS from "../Library/Tracker/SchoolLevelLMS";
import ClassLevelLMS from "../Library/Tracker/ClassLevelLMS";
// Imports for Attendance 
import Teacher_Attendance from "../Attendance/Teacher_Attendance/Teacher_Attendance";
import Prev_Attendance from "../Attendance/Teacher_Attendance/Prev_Attendance/Prev_Attendance";
import Admin_Attendance from "../Attendance/Admin_Attendance/Admin_Attendance";
import Ad_Teacher_Attendance from "../Attendance/Admin_Attendance/Admin_Teacher_Attendance/Ad_Teacher_Attendance";
import Admin_Teacher_Prev_Attendance from "../Attendance/Admin_Attendance/Admin_Teacher_Prev_Attendance/Admin_Teacher_Prev_Attendance";
import Student_Attendance from "../Attendance/Student_Attendance/Student_Attendance";
import Ad_student_attendance from "../Attendance/Student_Attendance/Ad-Student_attendance";
import MarkAttendance from "../Attendance/MarkAttendance";
import ShowSchoolAttendance from "../Attendance/ShowSchoolAttendance";
import ShowYourAttendance from "../Attendance/ShowYourAttendance";
import OverallSchoolAttendance from "../Attendance/OverallSchoolAttendance";
import OverallClassAttendance from "../Attendance/OverallClassAttendance";
import DistrictLevelAttendanceView from "../Attendance/DistrictLevelAttendanceView";
import SchoolLevelAttendanceView from "../Attendance/SchoolLevelAttendanceView";
import ClassLevelAttendanceView from "../Attendance/ClassLevelAttendanceView";
import UserLevelAttendanceView from "../Attendance/UserLevelAttendanceView";

//Imports for School Management
import AddSchool from "../School_Management/AddSchool";
import Show_Edit_Schools from "../School_Management/Show_Edit_Schools";
import EditSchool from "../School_Management/EditSchool";
import EditSchoolUser from "../School_Management/EditSchoolUser";
import EditSchoolInfo from "../School_Management/EditSchoolInfo";
import EditSchoolVariables from "../School_Management/EditSchoolVariables";

// Import for User Management
import ViewProfile from "../UserManagement/ViewProfile";
import AddUser from "../UserManagement/AddUser";
import Dashboard from "../Dashboard/Dashboard";
import ManageUsers from "../UserManagement/ManageUsers";
import MarkRFID from "../UserManagement/MarkRFID";
import GetUsers from "../UserManagement/GetUsers";
import GenerateIDCard from "../UserManagement/GenerateIDCard";

// Import for Life Cycle Management
import EditVariables from "../LifeCycle_Management/District/EditVariables";
import ShowVariables from "../LifeCycle_Management/District/ShowVariables";
import ShowVariablesClass from "../LifeCycle_Management/District/ShowVariables/class";
import ShowVariablesSection from "../LifeCycle_Management/District/ShowVariables/section";
import ShowVariablesSubjects from "../LifeCycle_Management/District/ShowVariables/subjects";
import ShowVariablesSession from "../LifeCycle_Management/District/ShowVariables/session";

// Imports for Adding Users and creating classes
import AddCentral from "../Add/AddCentral";
import AddDistrict from "../Add/AddDistrict";
import AddSchoolAdmin from "../Add/AddSchoolAdmin";
import AddTeacher from "../Add/AddTeacher";
import AddStudent from "../Add/AddStudent";
import AddParent from "../Add/AddParent";
import CreateClass from "../Add/CreateClass";

// Imports for Showing Users and Classes
import ShowCentral from "../Show/ShowCentrals";
import ShowDistrict from "../Show/ShowDistricts";
import ShowSchoolAdmin from "../Show/ShowSchoolAdmins";
import ShowTeacher from "../Show/ShowTeachers";
import ShowStudent from "../Show/ShowStudents";
import ShowParent from "../Show/ShowParents";
import ShowClasses from "../Show/ShowClasses";

//Import for Edit Users
import EditTeachers from "../Edit/EditTeachers";
import EditParents from "../Edit/EditParents";
import EditStudents from "../Edit/EditStudents";
import ShowEditUser from "../Users/ShowEditUser";

// Imports for Login
import Login from "../Login/Login";

// Imports for Chat section
import ChatTeacher from "../Chat/ChatTeacher";
import ChatStudent from "../Chat/ChatStudent";
import ChatAdmin from "../Chat/ChatAdmin";

//Imports for fee collection
import Adminfee from "../FeeCollection/Admin_Fee_Collection/AdminfeeCollection";
import Addetails from "../FeeCollection/Admin_Fee_Collection/Add-details/Add_details";
import Complete from "../FeeCollection/Admin_Fee_Collection/Complete_details/Complete_details";
import Razor from "../FeeCollection/Admin_Fee_Collection/Complete_details/Razor-pay/Razor";
import Studentfee from "../FeeCollection/Student_Fee_Collection/Student_Fee_Collection";

// Imports for Documents
// Admin
import AdminDocuments from "../Documents/Admin_Documents/Admin_Documents";
import AdminAcademicDocument from "../Documents/Admin_Documents/AdminAcademicDocument";
import AdminSubmissionDocument from "../Documents/Admin_Documents/AdminSubmissionDocument";
import AdminAddDocument from "../Documents/Admin_Documents/AdminAddDocument";
// Teacher
import TeacherDocuments from "../Documents/Teacher_Documents/Teacher_Documents";
import TeacherAcademicDocument from "../Documents/Teacher_Documents/TeacherAcademicDocument";
import TeacherSubmissionDocument from "../Documents/Teacher_Documents/TeacherSubmissionDocument";
import TeacherAddDocument from "../Documents/Teacher_Documents/TeacherAddDocument";
// Student
import StudentDocuments from "../Documents/Student_Documents/Student_Documents";
import StudentAcademicDocument from "../Documents/Student_Documents/StudentAcademicDocument";
import StudentSubmissionDocument from "../Documents/Student_Documents/StudentSubmissionDocument";
import StudentAddDocument from "../Documents/Student_Documents/StudentAddDocument";
import ShowAllDocuments from "../Documents/ShowDocument/ShowAllDocuments";

// Impetus Document
import AddDocument from "../Documents/Add Document/AddDocument";
import ShowSchoolDocument from "../Documents/ShowDocument/ShowSchoolDocument";
import ShowYourDocument from "../Documents/ShowDocument/ShowYourDocument";
import ShowUploadedDocument from "../Documents/ShowDocument/ShowUploadedDocument";
//import Document from "../Documents/Document";
// CREATE LIVE CLASS
// import CreateLiveClass from "../Add/CreateLiveClass";
// Imports for calendar
import UserCalendar from "../Calendar/UserCalendar";

// Imports for LMS
import CreateSubject from "../Add/CreateSubject";

// Other important imports
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import NotFound from "../NotFound/NotFound";
import Quiz from "../Quiz/Quiz";
import ProtectedRoutes from "../ProtectedRoutes/ProtectedRoutes";

import ForgetPassword from "../Login/ForgetPassword";
// import Mcq from "../Assesment/AssessmentCreator/Assessment/Mcq";
import Library from "../Assesment/Library";
import VerifyOtp from "../Login/VerifyOtp";
import AddNotice from "../Notices/Add Notices/AddNotice";

// Lifecycle Imports
import LifeCycleLogs from "../LifeCycle_Management/District/LifeCycleLogs";
import CreateSubjectTest from "../Library/test/CreateSubject";
import VideoPlayer from "../Library/ContentPlayer/VideoPlayer";
import PdfViewer from "../Library/ContentPlayer/PdfViewer";

import CreateAssesment from "../Assesment/AssessmentCreator/CreateAssesment";
import AssessmentEvaluator from "../Assesment/AssessmentEvaluator/AssessmentEvaluator";
import AssessmentTracker from "../Assesment/AssessmentTracker/AssessmentTracker";
import ViewAssessment from "../Assesment/AssessmentTracker/ViewAssessment";
import AssessmentLibrary from "../Assesment/AssessmentLibrary/AssessmentLibrary";

// Reports and Analytics
import AttendanceReport from "../ReportsAndAnalytics/AttendanceReport";
import LearningManagementReport from "../ReportsAndAnalytics/LearningManagementReport";
import AssessmentReport from "../ReportsAndAnalytics/AssessmentReport";
import EditAssessment from "../Assesment/AssessmentTracker/EditAssessment";
import QuestionsUI from "../Assesment/AssessmentTracker/StudentAssessmentTracker/QuestionsUI";
import SubmitQuestions from "../Assesment/AssessmentTracker/SubmitQuestions";
import UserLevelLMS from "../Library/Tracker/UserLevelLMS";

function Body() {
  const [loggedInT, setLoggedInT] = useState(true);
  const [
    { user, userDetails, initialState, loggedIn, class_teacher_class_details },
  ] = useDataLayerValue();
  const [forWhom, setForWhom] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [routePermissionInfo, setRoutePermissionInfo] = useState({
    CRUD: {
      centralCRUD: false,
      districtCRUD: false,
      schoolAdminCRUD: false,
      teacherCRUD: false,
      studentCRUD: false,
      parentCRUD: false,
      classCRUD: false,
    },
    OTHERS: {
      dashboard: undefined,
      attendance: undefined,
      library: undefined,
      assessment: undefined,
      liveClasses: undefined,
      forum: undefined,
      calendar: undefined,
      reports: undefined,
      fee: undefined,
      documents: undefined,
      notices: undefined,
      quiz: undefined,
    },
  });

  // Selecting dashboard based on usertype and setting route permissions

  useEffect(() => {
    setRoutePermissionInfoFunc();
  }, [userDetails, class_teacher_class_details]);

  const setRoutePermissionInfoFunc = () => {
    switch (userDetails?.userType || initialState?.userDetails.userType) {
      case "TEACHER":
        setForWhom("Teacher");
        setRoutePermissionInfo((prevState) => ({
          ...prevState,
          OTHERS: {
            ...prevState.OTHERS,
            dashboard: <Teacher_Dashboard />,
            attendance: class_teacher_class_details ? (
              <Teacher_Attendance />
            ) : (
              <NotFound />
            ),
            reports: <Treports />,
            library: <Tlibrary />,
            assessment: undefined,
            liveClasses: <LiveClasses_Teacher />,
            documents: undefined,
            notices: <Notices_Teacher />,
            quiz: <Quiz />,
            createClass: <CreateClass />,
            chat: <ChatTeacher />,
            documents: <TeacherDocuments />,
            calendar: <UserCalendar />,
          },
        }));
        break;
      case "STUDENT":
        setForWhom("Student");
        setRoutePermissionInfo((prevState) => ({
          ...prevState,
          OTHERS: {
            ...prevState.OTHERS,
            dashboard: <Student_Dashboard />,
            attendance: <Student_Attendance />,
            reports: <Student_Reports />,
            library: <Slibrary />,
            assessment: undefined,
            liveClasses: <LiveClasses_Student />,
            documents: <StudentDocuments />,
            notices: <NoticesStudent />,
            quiz: <Quiz />,
            chat: <ChatStudent />,
            fee: <Studentfee />,
            calendar: <UserCalendar />,
          },
        }));
        break;
      case "SCHOOL_ADMIN":
        setForWhom("School Admin");
        setRoutePermissionInfo((prevState) => ({
          ...prevState,
          CRUD: {
            ...prevState.CRUD,
            teacherCRUD: true,
            studentCRUD: true,
            parentCRUD: true,
            classCRUD: true,
            schoolAdminCRUD: true,
          },
          OTHERS: {
            ...prevState.OTHERS,
            dashboard: <Admin_Dashboard />,
            attendance: <Admin_Attendance />,
            reports: <Areports />,
            library: <School_Admin_Library />,
            assessment: undefined,
            liveClasses: <LiveClasses_Admin />,
            documents: <AdminDocuments />,
            notices: <Notices_Admin />,
            quiz: <Quiz />,
            createClass: <CreateClass />,
            showClasses: <ShowClasses />,
            chat: <ChatAdmin />,
            fee: <Adminfee />,
            calendar: <UserCalendar />,
          },
        }));
        break;
      case "CENTRAL":
        setForWhom("Central");
        setRoutePermissionInfo((prevState) => ({
          ...prevState,
          CRUD: {
            ...prevState.CRUD,
            centralCRUD: true,
            districtCRUD: true,
            schoolAdminCRUD: true,
            teacherCRUD: true,
            studentCRUD: true,
            parentCRUD: true,
            classCRUD: true,
          },
          OTHERS: {
            createClass: <Create_Inhouse_Class />,
            library: <Inhouse_Library />,
            calendar: <UserCalendar />,
          },
        }));
        break;
      case "DISTRICT":
        setForWhom("District");
        setRoutePermissionInfo((prevState) => ({
          ...prevState,
          CRUD: {
            ...prevState.CRUD,
            schoolAdminCRUD: true,
            teacherCRUD: true,
            studentCRUD: true,
            parentCRUD: true,
          },
          OTHERS: {
            createClass: <Create_Inhouse_Class />,
            library: <Inhouse_Library />,
            calendar: <UserCalendar />,
          },
        }));
        break;
      case "SUPER_USER":
        setForWhom("Super User");
        setRoutePermissionInfo((prevState) => ({
          ...prevState,
          CRUD: {
            ...prevState.CRUD,
            centralCRUD: true,
            districtCRUD: true,
            schoolAdminCRUD: true,
            teacherCRUD: true,
            studentCRUD: true,
            parentCRUD: true,
          },
        }));
        break;

      default:
        setForWhom("");
        break;
    }
  };

  useEffect(() => {
    // if (location.pathname === "/login") {
    //   if (location?.state?.from) {
    //     navigate(location?.state?.from.pathname);
    //   } else {
    //     navigate("/");
    //   }
    // }
    setLoggedInT(loggedIn);
  }, [loggedIn]);

  return (
    <div
      className="Body"
      style={{
        height: !loggedIn ? "100vh" : "95vh",
      }}
    >
      {/* <p className="module-for">{forWhom} Dashboard</p> */}
      <div className="body-content">
        <Routes>
          {/* Public Routes */}
          {/* Login Route */}

          {/* Private Routes */}
          {loggedInT ? (
            <Route>
              {/* Route for Dashboard */}
              <Route exact path="/" element={<Home />} />
              <Route exact path="/user-profile" element={<User_profile />} />
              <Route path="/user-profile/Edit" element={<User_Edit />} />

              {/* Routes for chat */}
              <Route path="chat" element={<Chat />} />
              <Route path="addGroup" element={<AddGroup />} />

              {/* Routes for attendance */}

              <Route
                exact
                path="/attendance"
                element={routePermissionInfo?.OTHERS.attendance}
              />

              <Route
                exact
                path="/attendance/prev-attendance"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <Prev_Attendance />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                exact
                path="/attendance/update-attendance"
                element={
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <Ad_Teacher_Attendance />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                exact
                path="/attendance/student_attendance"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ? (
                    <Ad_student_attendance />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                exact
                path="/attendance"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <Admin_Teacher_Prev_Attendance />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/attendance/teacher_attendance"
                element={<Ad_Teacher_Attendance />}
              />
              <Route
                exact
                path="/attendance/showAdminAttendance"
                element={
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ? (
                    <Admin_Attendance />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                exact
                path="/notices"
                element={routePermissionInfo?.OTHERS.notices}
              />
              <Route
                path="add-notice"
                element={
                  // userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ||
                  //   userDetails?.userType === "STUDENT" ? (
                  <AddNotice />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />
              <Route
                path="view-notices"
                element={
                  // userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ||
                  //   userDetails?.userType === "STUDENT" ? (
                  <ShowYourNotices />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />

              <Route
                path="view-uploaded-notices"
                element={
                  // userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ||
                  //   userDetails?.userType === "STUDENT" ? (
                  <ShowUploadedNotices />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />
              <Route
                path="view-school-notices"
                element={
                  // userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ||
                  //   userDetails?.userType === "STUDENT" ? (
                  <ShowSchoolNotices />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />
              <Route
                path="view-attendance"
                element={
                  // userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ||
                  //   userDetails?.userType === "STUDENT" ? (
                  <ShowYourAttendance />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />
              <Route
                path="view-school-attendance"
                element={
                  // userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ||
                  //   userDetails?.userType === "STUDENT" ? (
                  <ShowSchoolAttendance />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />
              <Route
                path="overall-school-attendance"
                element={
                  // userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ||
                  //   userDetails?.userType === "STUDENT" ? (
                  <OverallSchoolAttendance />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />
              <Route
                path="overall-class-attendance"
                element={
                  // userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ||
                  //   userDetails?.userType === "STUDENT" ? (
                  <OverallClassAttendance />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />

              {/* Route for live classes */}
              <Route
                path="/create-live-classes"
                element={<CreateLiveClass />}
              />
              <Route path="/view-live-classes" element={<ViewLiveClasses />} />

              {/* Routes for fee collection */}
              <Route
                path="/fee-collection"
                element={routePermissionInfo?.OTHERS.fee}
              />
              <Route path="/fee-collection/Details" element={<Addetails />} />
              <Route
                path="/fee-collection/Complete-Details"
                element={<Complete />}
              />
              <Route
                path="/fee-collection/Complete-Details/Pay"
                element={<Razor />}
              />

              {/* Route for Documents */}
              <Route
                path="/documents"
                element={routePermissionInfo.OTHERS.documents}
              >
                {/* <Route
                index
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <AdminAcademicDocument />
                  ) : userDetails?.userType === "TEACHER" ? (
                    <TeacherAcademicDocument />
                  ) : userDetails?.userType === "STUDENT" ? (
                    <StudentAcademicDocument />
                  ) : (
                    <NotFound />
                  )
                }
              /> */}
                {/*               
              <Route
                path="AcademicDocument"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <AdminAcademicDocument />
                  ) : userDetails?.userType === "TEACHER" ? (
                    <TeacherAcademicDocument />
                  ) : userDetails?.userType === "STUDENT" ? (
                    <StudentAcademicDocument />
                  ) : (
                    <NotFound />
                  )
                }
              /> */}
                {/* <Route
                path="SubmissionDocument"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <AdminSubmissionDocument />
                  ) : userDetails?.userType === "TEACHER" ? (
                    <TeacherSubmissionDocument />
                  ) : userDetails?.userType === "STUDENT" ? (
                    <StudentSubmissionDocument />
                  ) : (
                    <NotFound />
                  )
                }
              /> */}
                {/* <Route
                path="AddDocument"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <AdminAddDocument />
                  ) : userDetails?.userType === "TEACHER" ? (
                    <TeacherAddDocument />
                  ) : userDetails?.userType === "STUDENT" ? (
                    <StudentAddDocument />
                  ) : (
                    <NotFound />
                  )
                }
              /> */}
                <Route
                  path="show-your-attendance"
                  element={
                    // userDetails?.userType === "SCHOOL_ADMIN" ||
                    //   userDetails?.userType === "TEACHER" ||
                    //   userDetails?.userType === "STUDENT" ? (
                    <ShowYourAttendance />
                    // ) : (
                    //   <NotFound />
                    // )
                  }
                />
                <Route
                  path="add-document"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ||
                    userDetails?.userType === "TEACHER" ||
                    userDetails?.userType === "STUDENT" ? (
                      <AddDocument />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="show-school-documents"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ||
                    userDetails?.userType === "TEACHER" ||
                    userDetails?.userType === "STUDENT" ? (
                      <ShowSchoolDocument />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="show-your-document"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ||
                    userDetails?.userType === "TEACHER" ||
                    userDetails?.userType === "STUDENT" ? (
                      <ShowYourDocument />
                    ) : (
                      <NotFound />
                    )
                  }
                />
              </Route>

              {/* { Routes for calendar} */}
              <Route
                exact
                path="/calendar"
                element={routePermissionInfo?.OTHERS.calendar}
              />

              {/* Routes for Reports */}

              <Route
                exact
                path="/reports"
                element={routePermissionInfo?.OTHERS.reports}
              />
              <Route path="/reports/subject" element={<ReportsInner />} />

              <Route path="reports/my-report" element={<Myreport />} />
              <Route path="reports/Evalution" element={<Evalution />} />
              <Route
                path="reports/Evalution/Go-Evalution"
                element={<Gevalution />}
              />
              <Route
                path="reports/Evalution/Go-Evalution/Assessment-dash"
                element={<ADash />}
              />
              <Route
                path="reports/Evalution/Go-Evalution/Student"
                element={<Student />}
              />
              <Route
                path="reports/Evalution/Go-Evalution/Over-All"
                element={<Over />}
              />

              <Route
                path="/reports/my-report"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <SEvolution />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/reports/Teacher-Evalution"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <TEvolution />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/reports/Class-Evalution"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <CEvollution />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/reports/Evalution/Go-Admin-Evalution"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <GAEvolution />
                  ) : (
                    <NotFound />
                  )
                }
              >
                <Route
                  path="Admin-Assessment-dash"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ? (
                      <Admin_Assess />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="Admin-Over-All"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ? (
                      <Admin_overAll />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="Admin-Student"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ? (
                      <AStudent />
                    ) : (
                      <NotFound />
                    )
                  }
                />
              </Route>

              {/* Route for notices */}

              <Route
                exact
                path="/notices"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <Notices_Teacher />
                  ) : userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <Notices_Admin />
                  ) : (
                    <NoticesStudent />
                  )
                }
              />
              <Route
                path="/notices/create-notice"
                element={
                  userDetails?.userType === "TEACHER" &&
                  class_teacher_class_details !== undefined ? (
                    <Notices_Teacher_Create />
                  ) : userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <Notices_Admin_Create />
                  ) : (
                    <NotFound />
                  )
                }
              />

              {/* Routes for Libraries */}

              <Route path="/library" element={<CourseLibrary />} />
              <Route path="/lms-tracker" element={<LMS_Tracker/>} />
              <Route
                path="view-school-lms-progress/:id"
                element={<SchoolLevelLMS/>}
              />
              <Route
                path="view-class-lms-progress/:schoolId/:classId"
                element={<ClassLevelLMS/>}
              />
              <Route
                path="view-user-lms-progress/:id"
                element={<UserLevelLMS/>}
              />
              <Route
                path="/course/create-yie-course"
                element={<AddYieCourse />}
              />
              <Route
                path="/course/create-yie-chapter"
                element={<AddYieChapter />}
              />
              <Route
                path="/course/create-yie-content"
                element={<AddYieContent />}
              />

              <Route
                path="/library"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <Ucontent />
                  ) : userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <NotFound />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/library/Uploaded-content"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <Ucontent />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/library/add-content/add-chapter"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <AddChapter />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Add_Inhouse_Chapter />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/add-content/add-topic"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <AddTopic />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Add_Inhouse_Topic />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/add-content/add-sub-topic"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <AddSubTopic />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Add_Inhouse_Subtopic />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/library/topicwise/:classroom_id/:subject_id"
                element={
                  userDetails?.userType === "STUDENT" ? (
                    <Slibrary_Topicwise />
                  ) : userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <NotFound />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/:classroomId/:subjectId"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <School_Admin_Library_Lib />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Inhouse_Library_Lib />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/progress/:classroomId/:subjectId"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <School_Admin_Library_Content_Comp />
                  ) : userDetails?.userType === "STUDENT" ? (
                    <NotFound />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/progress/student-wise/:classroomId/:subjectId/:chapterId"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <School_Admin_Library_Student_Wise_Progress />
                  ) : userDetails?.userType === "STUDENT" ? (
                    <NotFound />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/library/player/:classroomId/:subjectId/:chapterId"
                element={<ContentPlayer />}
              />
              <Route
                path="course-player/:courseId"
                element={<ContentPlayer />}
              />
              <Route path="video-player" element={<VideoPlayer />} />
              <Route path="pdf-viewer" element={<PdfViewer />} />
              {/* Quiz */}
              <Route
                path="/create-quiz"
                element={
                  userDetails?.userType !== "STUDENT" ? <Quiz /> : <NotFound />
                }
              />

              {/* LMS */}

              <Route
                path="/create-subject"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <CreateSubject />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Create_Inhouse_Subject />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/show-subjects"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <ShowSubjects />
                  ) : (
                    <NotFound />
                  )
                }
              />

              {/* Add user components */}
              <Route
                path="/add-central"
                element={
                  routePermissionInfo.CRUD.centralCRUD ? (
                    <AddCentral />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/add-district"
                element={
                  routePermissionInfo.CRUD.districtCRUD ? (
                    <AddDistrict />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/add-school-admin"
                element={
                  routePermissionInfo.CRUD.schoolAdminCRUD ? (
                    <AddSchoolAdmin />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/add-teacher"
                element={
                  routePermissionInfo.CRUD.teacherCRUD ? (
                    <AddTeacher />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/add-student"
                element={
                  routePermissionInfo.CRUD.studentCRUD ? (
                    <AddStudent />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/add-parent"
                element={
                  routePermissionInfo.CRUD.parentCRUD ? (
                    <AddParent />
                  ) : (
                    <NotFound />
                  )
                }
              />

              {/* Create Class Route */}

              <Route
                path="create-class"
                element={
                  routePermissionInfo.CRUD.classCRUD ? (
                    routePermissionInfo.OTHERS.createClass
                  ) : (
                    <NotFound />
                  )
                }
              />

              {/* <Route
                path="create-live-class"
                <Route
                  path="show-your-attendance"
                  element={
                    // userDetails?.userType === "SCHOOL_ADMIN" ||
                    //   userDetails?.userType === "TEACHER" ||
                    //   userDetails?.userType === "STUDENT" ? (
                    <ShowYourAttendance />
                    // ) : (
                    //   <NotFound />
                    // )
                  }
                />
                <Route
                  path="add-document"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ||
                      userDetails?.userType === "TEACHER" ||
                      userDetails?.userType === "STUDENT" ? (
                      <AddDocument />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="show-school-documents"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ||
                      userDetails?.userType === "TEACHER" ||
                      userDetails?.userType === "STUDENT" ? (
                      <ShowSchoolDocument />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="show-your-document"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ||
                      userDetails?.userType === "TEACHER" ||
                      userDetails?.userType === "STUDENT" ? (
                      <ShowYourDocument />
                    ) : (
                      <NotFound />
                    )
                  }
                />
              </Route>

              {/* { Routes for calendar} */}
              <Route
                exact
                path="/calendar"
                element={routePermissionInfo?.OTHERS.calendar}
              />

              {/* Routes for Reports */}

              <Route
                exact
                path="/reports"
                element={routePermissionInfo?.OTHERS.reports}
              />
              <Route path="/reports/subject" element={<ReportsInner />} />

              <Route path="reports/my-report" element={<Myreport />} />
              <Route path="reports/Evalution" element={<Evalution />} />
              <Route
                path="reports/Evalution/Go-Evalution"
                element={<Gevalution />}
              />
              <Route
                path="reports/Evalution/Go-Evalution/Assessment-dash"
                element={<ADash />}
              />
              <Route
                path="reports/Evalution/Go-Evalution/Student"
                element={<Student />}
              />
              <Route
                path="reports/Evalution/Go-Evalution/Over-All"
                element={<Over />}
              />

              <Route
                path="/reports/my-report"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <SEvolution />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/reports/Teacher-Evalution"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <TEvolution />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/reports/Class-Evalution"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <CEvollution />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/reports/Evalution/Go-Admin-Evalution"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <GAEvolution />
                  ) : (
                    <NotFound />
                  )
                }
              >
                <Route
                  path="Admin-Assessment-dash"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ? (
                      <Admin_Assess />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="Admin-Over-All"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ? (
                      <Admin_overAll />
                    ) : (
                      <NotFound />
                    )
                  }
                />
                <Route
                  path="Admin-Student"
                  element={
                    userDetails?.userType === "SCHOOL_ADMIN" ? (
                      <AStudent />
                    ) : (
                      <NotFound />
                    )
                  }
                />
              </Route>

              {/* Route for notices */}

              <Route
                exact
                path="/notices"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <Notices_Teacher />
                  ) : userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <Notices_Admin />
                  ) : (
                    <NoticesStudent />
                  )
                }
              />
              <Route
                path="/notices/create-notice"
                element={
                  userDetails?.userType === "TEACHER" &&
                  class_teacher_class_details !== undefined ? (
                    <Notices_Teacher_Create />
                  ) : userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <Notices_Admin_Create />
                  ) : (
                    <NotFound />
                  )
                }
              />

              {/* Routes for Libraries */}

              <Route
                path="/library"
                element={routePermissionInfo?.OTHERS.library}
              />

              <Route
                path="/library"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <Ucontent />
                  ) : userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <NotFound />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/library/Uploaded-content"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <Ucontent />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/library/add-content/add-chapter"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <AddChapter />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Add_Inhouse_Chapter />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/add-content/add-topic"
                element={
                  // userDetails?.userType === "TEACHER" ? (
                  <AddTopic />
                  // ) : userDetails?.userType === "CENTRAL" ? (
                  //   <Add_Inhouse_Topic />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />
              <Route
                path="/create-subject-test"
                element={
                  // userDetails?.userType === "TEACHER" ? (
                  <CreateSubjectTest />
                  // ) : userDetails?.userType === "CENTRAL" ? (
                  //   <Add_Inhouse_Topic />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />

              <Route
                path="overall-class-attendance"
                element={
                  // userDetails?.userType === "TEACHER" ? (
                  <OverallClassAttendance />
                  // ) : userDetails?.userType === "CENTRAL" ? (
                  //   <Add_Inhouse_Topic />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />

              <Route
                path="course"
                element={
                  // userDetails?.userType === "TEACHER" ? (
                  <Course />
                  // ) : userDetails?.userType === "CENTRAL" ? (
                  //   <Add_Inhouse_Topic />
                  // ) : (
                  //   <NotFound />
                  // )
                }
              />

              <Route
                path="/library/add-content/add-sub-topic"
                element={
                  userDetails?.userType === "TEACHER" ? (
                    <AddSubTopic />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Add_Inhouse_Subtopic />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/library/topicwise/:classroom_id/:subject_id"
                element={
                  userDetails?.userType === "STUDENT" ? (
                    <Slibrary_Topicwise />
                  ) : userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <NotFound />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/:classroomId/:subjectId"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <School_Admin_Library_Lib />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Inhouse_Library_Lib />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/progress/:classroomId/:subjectId"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <School_Admin_Library_Content_Comp />
                  ) : userDetails?.userType === "STUDENT" ? (
                    <NotFound />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/library/progress/student-wise/:classroomId/:subjectId/:chapterId"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <School_Admin_Library_Student_Wise_Progress />
                  ) : userDetails?.userType === "STUDENT" ? (
                    <NotFound />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/library/player/:classroomId/:subjectId/:chapterId"
                element={<ContentPlayer />}
              />

              {/* Quiz */}
              <Route
                path="/create-quiz"
                element={
                  userDetails?.userType !== "STUDENT" ? <Quiz /> : <NotFound />
                }
              />

              {/* LMS */}

              <Route
                path="/create-subject"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <CreateSubject />
                  ) : userDetails?.userType === "CENTRAL" ? (
                    <Create_Inhouse_Subject />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/show-subjects"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <ShowSubjects />
                  ) : (
                    <NotFound />
                  )
                }
              />

              {/* Add user components */}
              <Route
                path="/add-central"
                element={
                  routePermissionInfo.CRUD.centralCRUD ? (
                    <AddCentral />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/add-district"
                element={
                  routePermissionInfo.CRUD.districtCRUD ? (
                    <AddDistrict />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/add-school-admin"
                element={
                  routePermissionInfo.CRUD.schoolAdminCRUD ? (
                    <AddSchoolAdmin />
                  ) : (
                    <NotFound />
                  )
                }
              />
              {/* <Route
                path="/add-teacher"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                    userDetails?.userType === "TEACHER" ? (
                    <CreateLiveClass />
                  ) : (
                    <NotFound />
                  )
                }
              />  */}
              {/* School Management */}
              <Route
                path="/add-school"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <AddSchool />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/edit-school"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <EditSchool />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/edit-school/:schoolId"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <EditSchool />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/edit-school-info/:schoolId"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <EditSchoolInfo />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/edit-school-variables/:schoolId"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <EditSchoolVariables />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route path="/user/:userId" element={<ViewProfile />} />
              <Route path="/add-user" element={<AddUser />} />
              <Route
                path="/edit-school-user/:schoolId"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <EditSchoolUser />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route path="/edit-school-user" element={<EditSchoolUser />} />
              <Route
                path="/show-edit-school"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <Show_Edit_Schools />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-variables"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <ShowVariables />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-variables/class"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <ShowVariablesClass />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-variables/section"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <ShowVariablesSection />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-variables/subjects"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <ShowVariablesSubjects />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-variables/session"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <ShowVariablesSession />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/edit-variables"
                element={
                  userDetails?.userType === "CENTRAL" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <EditVariables />
                  ) : (
                    <NotFound />
                  )
                }
              />
              {/* <Route
              path="/dashboard"
              element={
					<Dashboard/>
				}
            /> */}
              {/* Show user components */}
              <Route
                path="/show-central"
                element={
                  routePermissionInfo.CRUD.centralCRUD ? (
                    <ShowCentral />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-district"
                element={
                  routePermissionInfo.CRUD.districtCRUD ? (
                    <ShowDistrict />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-school-admin"
                element={
                  routePermissionInfo.CRUD.schoolAdminCRUD ? (
                    <ShowSchoolAdmin />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-teacher"
                element={
                  routePermissionInfo.CRUD.teacherCRUD ? (
                    <ShowTeacher />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-student"
                element={
                  routePermissionInfo.CRUD.studentCRUD ? (
                    <ShowStudent />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-parent"
                element={
                  routePermissionInfo.CRUD.parentCRUD ? (
                    <ShowParent />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="/show-class"
                element={
                  routePermissionInfo.CRUD.classCRUD ? (
                    <ShowClasses />
                  ) : (
                    <NotFound />
                  )
                }
              />
              {/* Edit User Component */}
              <Route
                path="/edit-teacher"
                element={
                  routePermissionInfo.CRUD.teacherCRUD ? (
                    <EditTeachers />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/edit-parent"
                element={
                  routePermissionInfo.CRUD.parentCRUD ? (
                    <EditParents />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/edit-student"
                element={
                  routePermissionInfo.CRUD.studentCRUD ? (
                    <EditStudents />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="/show-edit-user"
                element={
                  routePermissionInfo.CRUD.studentCRUD ? (
                    <ShowEditUser />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="forgetPassword"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ? (
                    <EditStudents />
                  ) : (
                    <NotFound />
                  )
                }
              />

              {/* Assesment */}
              <Route
                path="assessment/auroscholar/"
                element={
                  userDetails?.userType === "STUDENT" ? (
                    <Auroscholar />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="assessment/create-assessment/"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <CreateAssesment />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="assessment/assessment-tracker"
                element={
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ||
                  userDetails?.userType === "STUDENT" ? (
                    <AssessmentTracker />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="assessment/assessment-tracker/:assessment_id"
                element={
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ? (
                    <ViewAssessment />
                  ) : (
                    <NotFound />
                  )
                }
              />
              

              <Route
                path="assessment/assessment-tracker/:assessment_id/edit"
                element={
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ? (
                    <EditAssessment />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="assessment/assessment-tracker/:assessment_id/submit"
                element={
                  //   userDetails?.userType === "DISTRICT" ||
                  //   userDetails?.userType === "SCHOOL_ADMIN" ||
                  //   userDetails?.userType === "TEACHER" ? (
                  <SubmitQuestions />
                  //   ) : (
                  //     <NotFound />
                  //   )
                }
              />

              <Route
                path="assessment/assessment-library"
                element={
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ? (
                    <AssessmentLibrary />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="assessment/assessment-library/:assessment_id"
                element={
                  userDetails?.userType === "DISTRICT" ||
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ? (
                    <ViewAssessment />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route
                path="assessment/assessment-evaluator"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ||
                  userDetails?.userType === "TEACHER" ||
                  userDetails?.userType === "DISTRICT" ? (
                    <AssessmentEvaluator />
                  ) : (
                    <NotFound />
                  )
                }
              />

              <Route path="*" element={<NotFound />} />

              <Route
                path="school-dashboard"
                element={
                  userDetails?.userType === "SCHOOL_ADMIN" ? (
                    <SchoolDashboard />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="school-dashboard/:id"
                element={
                  userDetails?.userType === "DISTRICT" ? (
                    <SchoolIdDashboard />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route
                path="district-dashboard"
                element={
                  userDetails?.userType === "DISTRICT" ? (
                    <DistrictDashboard />
                  ) : (
                    <NotFound />
                  )
                }
              />
              <Route path="dashboard" element={<UserDashboard />}>
                <Route path=":id" element={<UserDashboard />} />
              </Route>

              {/* New Routes */}

              <Route path="mark-attendance" element={<MarkAttendance />} />
              <Route path="add-document" element={<AddDocument />} />
              <Route
                path="show-all-documents"
                element={<ShowSchoolDocument />}
              />
              <Route
                path="show-your-documents"
                element={<ShowYourDocument />}
              />
              <Route
                path="show-uploaded-documents"
                element={<ShowUploadedDocument />}
              />
              <Route path="manage-users" element={<ManageUsers />} />
              <Route path="mark-rfid" element={<MarkRFID />} />
              <Route path="edit-user/:id" element={<EditUser />} />
              <Route
                path="temp"
                element={
                  <GetUsers
                    districtId={userDetails?.districtId}
                    schoolId={"125c0847-9461-492a-b707-0cdf30421f2e"}
                  />
                }
              />
              <Route path="lifecycle-logs" element={<LifeCycleLogs />} />
              <Route
                path="view-all-attendance"
                element={<DistrictLevelAttendanceView />}
              />
              <Route
                path="view-all-school-attendance"
                element={<SchoolLevelAttendanceView />}
              />
              <Route
                path="view-all-school-attendance/:id"
                element={<SchoolLevelAttendanceView />}
              />
              <Route
                path="view-class-attendance/:id/:class_id"
                element={<ClassLevelAttendanceView />}
              />
              <Route
                path="view-class-attendance/:id"
                element={<ClassLevelAttendanceView />}
              />
              <Route
                path="view-user-attendance/"
                element={<UserLevelAttendanceView />}
              />
              <Route
                path="view-user-attendance/:id"
                element={<UserLevelAttendanceView />}
              />
              <Route path="generate-id-card" element={<GenerateIDCard />} />
              <Route path="attendance-report" element={<AttendanceReport />} />
              <Route
                path="learning-management-report"
                element={<LearningManagementReport />}
              />
              <Route path="assessment-report" element={<AssessmentReport />} />
              <Route path="show-documents" element={<ShowAllDocuments />} />
            </Route>
          ) : (
            <Route>
              {/* <Route 
								path="/"
								element={!loggedIn ? <Home /> : <NotFound />
								} /> */}
              <Route path="*" element={!loggedIn ? <Login /> : <NotFound />} />
              <Route
                path="/login"
                element={!loggedIn ? <Login /> : <NotFound />}
              />
              <Route
                path="login/forgetPassword"
                element={!loggedIn ? <ForgetPassword /> : <NotFound />}
              />
              <Route
                path="login/forgetPassword/verifyotp"
                element={!loggedIn ? <VerifyOtp /> : <NotFound />}
              />
            </Route>
          )}
        </Routes>
      </div>
    </div>
  );
}

export default Body;
