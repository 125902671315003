import { Box, Button, Grid } from '@mui/material'
import React from 'react'
import { Stack } from 'react-bootstrap'
import TurnedInIcon from '@mui/icons-material/TurnedIn';


const AssessmentCard = ({ratio,i,AssObj,SetDetails,fetchSubmissionList}) => {
  return (
   <Grid key={AssObj._id}  style={{height:"250px", borderRadius:"20px",minWidth:"260px"}} item xs={12} md={12} sm={12} lg={4}>
                        <Box className="w-100 h-100" style={{backgroundColor:"#FFFFFF",display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", borderRadius:"20px"}}>
                            <Stack direction="horizontal" style={{paddingLeft:"6%",paddingTop:"4%"}} className='justify-content-start'>
                                <TurnedInIcon/>                            </Stack>
                            <Box className="ps-3 pe-3 px-3 py-1 d-flex flex-column justify-content-around"   style={{backgroundColor:"#FFFFFF", width:"80%", height:"80%", borderRadius:"20px"}}>
                            <Stack direction="horizontal" className='justify-content-center'>
                                <Box sx={{fontSize:"1.3rem",fontWeight:"bold"}}>{AssObj.title[0].toUpperCase() + AssObj.title.slice(1)}</Box>
                            </Stack>
                            {/* <Stack direction="vertical" className='justify-content-around' >
                                <Box>{ratio[i]?.percentage===0||ratio[i]?.percentage===NaN||!ratio[i]?"No submission":ratio[i]?.percentage +"% submission Rate"} </Box>
                                <Box>{AssObj.late_submission===true?"No Time Limit":new Date(AssObj.ended_at).getTime()<Date.now()?"Active":"Time Expired"}</Box> 
                            </Stack> //if want may be */}
                            <Stack  direction="horizontal" className='justify-content-around align-items-center mt-3'>
                                <Button onClick={()=>SetDetails((pre)=>{
                                    let updated={...pre}
                                    updated.status=!pre.status
                                    if(updated.status){
                                        updated.data={
                                            ...AssObj,
                                            percentage:ratio[i]?.percentage,
                                        }
                                    }else{
                                        updated.data={}
                                    }
                                    return updated
                                })} 
                                // size="medium"
                                style={{backgroundColor:"#FFFFFF",borderRadius:"3px",padding:"3px",fontWeight:"bold" ,color:"#0d6efd",border:"1px solid #0d6efd"}}
                                // sx={{padding:"3px"}}
                                variant='contained'>Details</Button>
                                <Button
                                value={AssObj._id}
                                style={{backgroundColor:"#0d6efd",borderRadius:"3px",padding:"5px",color:"white"}}
                                // sx={{}}
                                data-title={AssObj.title}
                                onClick={(e)=>fetchSubmissionList(e)}
                                 variant='contained'>Evaluate</Button>
                            </Stack>
                            </Box>
                        </Box>
                       </Grid>

  )
}

export default AssessmentCard