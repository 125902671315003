import { React, useState, useEffect } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { BiUserCircle } from "react-icons/bi";
import { AiFillHome } from "react-icons/ai";
import Api from "../../Api/axios";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { useNavigate } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton } from "@material-ui/core";


function Header({ logout }) {
	const navigate = useNavigate();
	const [{ loggedIn, userDetails }, dispatch] = useDataLayerValue();
	const [userAvatar, setUserAvatar] = useState(userDetails?.avatar ? userDetails?.avatar : `${process.env.PUBLIC_URL}/Images/default_avatar.jpg`);
	const [notificationIcon, setNotificationIcon] = useState(`${process.env.PUBLIC_URL}/Images/notification-icon.png`);
	const [notifications, setNotifications] = useState([]);
	function getWindowSize() {
		const { innerWidth, innerHeight } = window;
		return { innerWidth, innerHeight };
	}
	const [windowSize, setWindowSize] = useState(getWindowSize());
	useEffect(() => {
		Api.get('/notification/getUserNotification?limit=5').then((res) => {
			console.log('yayyy');
			setNotifications(res.data);
		})
	}, [userDetails,])

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);
	if (loggedIn) {
		return (
			<div className="Header">
				<div className="navbar">
					<div className="navbar-left">
						<Link to="/" style={{
							textDecoration: "none",
							height: "52px",
							// width: "60px",
							color: "black",
							// align: "center",
							// justifyContent: "center",
						}}>
							<div className="logo">
								<img
									src="/Images/logo.jpg"
									height={"40px"}
									width={"40px"}
									style={{ borderRadius: "50%", marginRight: "10px" }}
									alt="logo"
								/>
								{(windowSize.innerWidth < 500) ?
									<marquee direction="left"
										style={{
											maxWidth: windowSize.innerWidth - 200
										}}
									>
										<h3 style={{
											margin: "0",
											fontWeight: "bold",
										}}>Youth India E-School</h3>
									</marquee> :
									<h3 style={{
										margin: "0",
										fontWeight: "bold",
									}}>Youth India E-School</h3>
								}
							</div>
							{/* <h3>Youth India E-School</h3> */}
						</Link>
					</div>
					{/* <div style={{
						display: "flex",
						alignItems: "left",
						justifyContent: "center",
					}} align="left">
						
					</div> */}
					<div className="navbar-right">
						{loggedIn && (
							<>
								{/* <Link to="/">
									<AiFillHome
										size={28}
										color={"black"}
										style={{ cursor: "pointer" }}
									/>
								</Link>
								<Link to="user-profile">
									<BiUserCircle
										size={28}
										color={"black"}
										style={{ cursor: "pointer" }}
									/>
								</Link>
								<button
									className="nav-login-btn"
									onClick={() => {
										logout();
									}}
								>
									Logout
								</button> */}
								<DropdownButton
									title={
										<IconButton>
											<NotificationsIcon />
										</IconButton>
									}
									variant="light"
									align="end"
									style={{
										justifyContent: "right",

									}}
									onClick={() => {
										Api.get('/notification/getUserNotification?limit=5').then((res) => {

											setNotifications(res.data);
										})
									}}
								>

									{

										// notifications.length > 0 ?
										notifications?.map((notification) => {
											let date = new Date(notification.createdAt);
											let neww = false
											let today = false
											let minutes
											// get time 5 minutes ago
											let time = new Date();
											time.setMinutes(time.getMinutes() - 5);
											// check if notification is less than 5 minutes old
											if (date > time) {
												console.log(date.toLocaleTimeString())
												neww = true
												minutes = Math.floor((date - time) / 60000);
												minutes = 5 - minutes
											}
											// check if date today
											if (date.getDate() === new Date().getDate()) {
												today = true
											}


											return (

												<Dropdown.Item style={{ color: "black" }} onClick={() => {
													if (notification.link) {
														navigate(notification.link)
													}
												}}>
													<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", color: "blueviolet", fontSize: "12px" }}>
														<div>
															{today ? 'TODAY' : date.toLocaleDateString()}
														</div>
														<div style={{ display: "flex", alignItems: "center" }}>
															{neww ? <div style={{ backgroundColor: "#B2F77D", borderRadius: "50%", width: "10px", height: "10px", marginRight: "5px" }}></div> : null}
															{neww ? `${minutes} minute ago` : date.toLocaleTimeString()}

														</div>
													</div>
													{notification.message}
													<Dropdown.Divider />
												</Dropdown.Item>
											)
										})

									}
									{/* center drop down item */}
									{
										notifications.length > 0 ?
											<Dropdown.Item style={{ display: "flex", justifyContent: "center", color: "blue" }} onClick={() => { navigate("/dashboard") }}>
												View All
											</Dropdown.Item>
											:
											<Dropdown.Item style={{ color: "blue" }}>
												No new notifications
											</Dropdown.Item>
									}


								</DropdownButton>

								<DropdownButton
									title={
										<img
											src={userAvatar}
											height={"40px"}
											width={"40px"}
											style={{ borderRadius: "50%" }}
											alt="logo"
										/>
									}
									variant="light"
									align="end"
									style={{
										justifyContent: "right"
									}}
								>
									<Dropdown.Item onClick={
										() => {
											navigate("/dashboard")
										}
									} >Dashboard</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item onClick={() => {
										logout();
									}} >Logout</Dropdown.Item>
								</DropdownButton>
							</>
						)}
					</div>
				</div>
			</div >
		);
	} else {
		return (
			<>
			</>
		)
	}
}

export default Header;
