import React, { useEffect, useState, useRef } from "react";
import Api from "../../Api/axios";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Error from "../ErrorSuccess/Error";
import { FaUserEdit } from "react-icons/fa";
import EditModal from "../EditModal/EditModal";
import { useReactToPrint } from "react-to-print";

const ShowParents = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [errMesssage, setErrMesssage] = useState();
  const [allUsersFiltered, setAllUsersFiltered] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [idToBeEdited, setIdToBeEdited] = useState();
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userContactNo, setUserContactNo] = useState();

  const componentRef = useRef();

  useEffect(() => {
    fetchParents();
  }, []);

  const fetchParents = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    if (
      userDetails?.userType === "CENTRAL" ||
      userDetails?.userType === "DISTRICT"
    ) {
      await Api.get("/user/all-parents")
        .then((res) => {
          setAllUsers(res.data);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((err) => {
          setErrMesssage(err.response.message);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    } else {
      await Api.get(`/user/by-school?id=${userDetails.schoolId}&type=PARENT`)
        .then((res) => {
          setAllUsers(res.data);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((err) => {
          setErrMesssage(err.response.message);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    }
  };

  // update Contact No from EditModal
  const changeValue = (e) => {
    const { id, value } = e.target;
    setUserContactNo({ [id]: value });
  };

  const openEditModal = (userId, userName, userEmail, userContactNo) => {
    console.log(allUsers);
    setEditModalOpen(true);
    setUserName(userName);
    setUserEmail(userEmail);
    setUserContactNo(userContactNo);
    setIdToBeEdited(userId);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const editConfirmed = () => {
    setEditModalOpen(false);
    console.log(idToBeEdited);
    console.log(userContactNo);
    editUser();
  };
  const editUser = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    //console.log(userContactNo);
    await Api.put(
      `user?id=${idToBeEdited}`,
      { contactNumber: `${userContactNo.userContactNo}` },
      true
    )
      .then(() => fetchParents())
      .catch((err) => console.log(err));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const changeFilterData = (e) => {
    const { id, value } = e.target;
    setFilterData({ ...filterData, [id]: value });
  };

  useEffect(() => {
    setFilteredUserDetails();
  }, [filterData, allUsers]);

  const setFilteredUserDetails = () => {
    const keys = Object.keys(filterData);
    let allEmpty = Object.values(filterData).every(
      (x) => x === null || x === "" || x === undefined
    );
    if (allEmpty || filterData === {}) {
      setAllUsersFiltered(allUsers);
    } else {
      let filteredUserDetailsTemp = allUsers.filter((attd) => {
        return keys.every((key) =>
          attd[key]
            ?.toLocaleLowerCase()
            .includes(filterData[key]?.toLocaleLowerCase())
        );
      });
      setAllUsersFiltered(filteredUserDetailsTemp);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="show-table-container">
      <div className="show-table-container-main">
        <button onClick={handlePrint}>Print User Details</button>
        <table className="table" ref={componentRef}>
          {" "}
          <thead>
            <tr>
              <th colSpan="5">Parents</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Name
                  <input
                    type="text"
                    id="name"
                    placeholder="eg: Ayan"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Email
                  <input
                    type="text"
                    id="email"
                    placeholder="eg: Ayan"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Teacher Id
                  <input
                    type="text"
                    id="_id"
                    placeholder="eg: id"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>

              <td>
                <div className="ad-st-attnd-table-filter">
                  Contact Number
                  <input
                    type="text"
                    id="contactNumber"
                    placeholder="eg: 91XXXXXX01"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>

              <td>Edit</td>
            </tr>
            {allUsersFiltered?.map((user) => (
              <tr>
                <td key={user}>{user?.name}</td>
                <td key={user}>{user?.email}</td>
                <td key={user}>{user?.username}</td>
                <td key={user}>{user?.contactNumber}</td>
                <td>
                  <FaUserEdit
                    color="Black"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      openEditModal(
                        user?._id,
                        user?.name,
                        user?.email,
                        user?.contactNumber
                      )
                    }
                  />
                </td>
              </tr>
            ))}
            {errMesssage && <Error message={errMesssage} />}
          </tbody>
          <EditModal
            changevalue={changeValue}
            open={editModalOpen}
            closeEditModal={closeEditModal}
            editConfirmed={editConfirmed}
            userId={idToBeEdited}
            userName={userName}
            userContactNo={userContactNo}
            userEmail={userEmail}
            message={
              "Do you really want to edit this user ?"
            }
          />
        </table>
      </div>
    </div>
  );
};

export default ShowParents;
