import { SearchOutlined } from '@material-ui/icons'
import { Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import TableRow from "./TableRow"
import { useNavigate } from "react-router-dom";

const AssessmentTable = ({datas,search,selectedAssid,filterAssessment,isSubmitted=null,BtnClick=null,showAll=null}) => {
  console.log("datas auto table",datas,"filterAssessment",filterAssessment,selectedAssid)
  const navigate = useNavigate();

  return (
    <Grid  container>
    <Grid item xs={12} md={12} sm={12} lg={12} className='mt-2'>
    <Grid container className='mt-2 w-100 ps-5 pe-5' spacing={4}>
        {filterAssessment==="All"&& filterAssessment!=="Urgency"&&
             <Table striped bordered hover responsive>
               <TableRow isSubmitted={isSubmitted} showAll={showAll} />
               <tbody>
                 {datas
                  .filter((x)=>{
                    return x.title.includes(search)
                  })
                   .map((data,i) => {
                     return (
                       <tr>
                         <td>
                         <p className="d-flex justify-content-center align-items-center">{i+1}</p> 
                          </td>
                         <td>
                         <p className="d-flex justify-content-center align-items-center">{data.title}</p> 
                          </td>
                         <td>
                         <p className="d-flex justify-content-center align-items-center">{data.total_questions}</p> 
                          </td>
                         <td>
                         <p className="d-flex justify-content-center align-items-center">{data.created_by_name}</p> 
                          </td>
                         <td>
                         <p className="d-flex justify-content-center align-items-center"> {`${new Date(data.createdAt).getDate()}/ ${new Date(data.createdAt).getMonth()+1}/${new Date(data.createdAt).getFullYear()}`}</p> 
                          </td>
                        {!(data.late_submission)?
                         <td>
                            <p className="d-flex justify-content-center align-items-center">{`${new Date(data.ended_at).getDate()}/ ${new Date(data.ended_at).getMonth()+1}/${new Date(data.ended_at).getFullYear()}`}</p> 
                          </td>
                         :
                         <td>
                         <p className="d-flex justify-content-center align-items-center">No Time Limit</p> 
                          </td>
                        }
                        {!showAll &&
                            <td >
                            <Button value={data._id} data-datas={JSON.stringify(datas)}
                            onClick={(e)=>{
                              BtnClick&&BtnClick(e)
                            }
                            }
                              sx={{backgroundColor:"blue", color:"#FFFFFF"}}> {isSubmitted?"View ":"Start "}</Button>
                          </td>
                        }
                       
                       </tr>
                     );
                   })}
               </tbody>
             </Table>

        //    datas?.filter((x)=>{
        //     if(x.title.includes(search)){
        //         return 1
        //     }else{
        //         return 0
        //     }
        //    })
        //    .map((AssObj,i)=><AssessmentDetailsCard/>
        //    )
        }
        {filterAssessment!=="All"&& filterAssessment==="Urgency"&&
              <Table striped bordered hover responsive>
              <TableRow isSubmitted={isSubmitted} showAll={showAll}/>
              <tbody>
                {datas
                    .sort((a,b)=>{ //descending
                      if(new Date(a.ended_at)>new Date(b.ended_at)){
                          return 1
                      }else{
                          return -1
                      }
                    })
                 .filter((x)=>{
                   return x.title.includes(search)
                 })
                  .map((data,i) => {
                    return (
                      <tr>
                        <td>
                        <p className="d-flex justify-content-center align-items-center">{i+1}</p> 
                         </td>
                        <td>
                        <p className="d-flex justify-content-center align-items-center">{data.title}</p> 
                         </td>
                        <td>
                        <p className="d-flex justify-content-center align-items-center">{data.total_questions}</p> 
                         </td>
                        <td>
                        <p className="d-flex justify-content-center align-items-center">{data.created_by_name}</p> 
                         </td>
                        <td>
                        <p className="d-flex justify-content-center align-items-center"> {`${new Date(data.createdAt).getDate()}/ ${new Date(data.createdAt).getMonth()+1}/${new Date(data.createdAt).getFullYear()}`}</p> 
                         </td>
                       {!(data.late_submission)?
                        <td>
                           <p className="d-flex justify-content-center align-items-center">{`${new Date(data.ended_at).getDate()}/ ${new Date(data.ended_at).getMonth()+1}/${new Date(data.ended_at).getFullYear()}`}</p> 
                         </td>
                        :
                        <td>
                        <p className="d-flex justify-content-center align-items-center">No Time Limit</p> 
                         </td>
                       }
                        {!showAll &&
                            <td >
                          <Button value={data._id} data-datas={JSON.stringify(datas)} 
                          onClick={(e)=>{BtnClick&&BtnClick(e)}}
                          // onClick={()=>navigate("assessment/assessment-tracker/:assessment_id/submit")}
                            sx={{backgroundColor:"blue", color:"#FFFFFF"}}> {isSubmitted?"View ":"Start "}</Button>
                          </td>
                        }
                      
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
        }

    </Grid>
    </Grid>
</Grid>


  )
}

export default AssessmentTable