export function AppendDataWithPostObjFormat(array,userschoolId){
const [email,name,districtId,contactNumber,
    userType,gender,displayUserType,Teacher_category,
    class_taught_type,Caste,disability_type,
    DOB,permanent_address,plus_two_stream,graduation_stream,postgraduation_stream,
    m_phil,Phd,Professional_qualification,first_appointment_date,
    admission_number,aadhar_number,father_name,mother_name,student_parents_address,
    name_of_habitation_or_locality,mother_tongue,Religion,
    social_category,is_student_bpl,student_opted,medium_of_instruction,
    password
]=array
var data = {
    email: email,
    name: name,
    password: password,
    contactNumber: contactNumber,
    schoolId: userschoolId,
    userType_specific_data: {
        districtId: districtId,
        userType: userType,
        gender: gender,
        displayUserType: displayUserType,
        Teacher_category: Teacher_category,
        class_taught_type: class_taught_type,
        Caste: Caste,
        disability_type: disability_type,
        DOB: DOB,
        permanent_address: permanent_address,
        plus_two_stream: plus_two_stream,
        graduation_stream: graduation_stream,
        postgraduation_stream: postgraduation_stream,
        Phd: Phd,
        m_phil: m_phil,
        Professional_qualification: Professional_qualification,
        first_appointment_date: first_appointment_date,
        admission_number: admission_number,
        aadhar_number: aadhar_number,
        father_name: father_name,
        mother_name: mother_name,
        student_parents_address: student_parents_address,
        name_of_habitation_or_locality: name_of_habitation_or_locality,
        mother_tongue: mother_tongue,
        Religion: Religion,
        social_category: social_category,
        is_student_bpl: is_student_bpl,
        student_opted: student_opted,
        medium_of_instruction: medium_of_instruction,
    }
}
return data
}

