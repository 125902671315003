import { React, useState, useEffect } from 'react';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Api from "../../Api/axios";
import { Button } from 'react-bootstrap';

const Auroscholar = () => {
    const [srcUrl , setSrcUrl] = useState();
    const getToken = () => {
        Api.get('/auroscholar').then((response) => {
            setSrcUrl(response.data[0].response.data.url);
        })
    }
    return (
        <>
        { srcUrl ?
            <iframe
                src={srcUrl}
                allow="camera *;microphone *" 
                width="100%" 
                height={(window.innerHeight - 61) + "px"}
            >
            </iframe>
            :
            <div align="center">
                <div className = "mt-4 col-11 col-lg-10" style={{backgroundColor:"#fff"}}>
                    <h1 className="pt-4">AuroScholar x Youth India E-School</h1>
                    <p className="ps-2 pe-2 pt-4">We have partnered with AuroScholar to provide scholarship to students studying with us. Click the below buttion to take the test.</p>
                    <Button className='m-4' onClick={getToken}>Enter Auroscholar</Button>
                </div>
            </div>
        }
        </>
    )
}

export default Auroscholar;