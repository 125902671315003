import React, { useState, useEffect } from 'react';
import { Box, option } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Api from "../../../Api/axios";
import { MenuItem } from "@mui/material";
import Table from 'react-bootstrap/Table';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import fs from 'fs'
// import * as fs from 'fs';
import FormData from 'form-data'
// var fs = require('fs');
// const fs = require('fs');
// const fs = require('fs-extra')

const AddYieContent = () => {
    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();
    const TYPES = ['VIDEO', 'PDF']
    const [course, setCourse] = useState("");
    const [chapterId, setChapterId] = useState("");
    const [courses, setCourses] = useState([]);
    const [chapters, setChapters] = useState([{ _id: uuidv4(), chapter_name: "", chapter_description: "" }]);
    const [chapter, setChapter] = useState({ chapter_name: "" });
    const [classNames, setClassNames] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [className, setClassName] = useState('ALL');
    const [type, setType] = useState('VIDEO');
    const [doc, setDoc] = useState('');
    const [docPath, setDocPath] = useState('');
    const [transcript, setTranscript] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let temp = [{ id: "ALL", name: "ALL" }]
        for (let i = 1; i <= 12; i++) {
            temp.push({ id: i, name: i })

        }
        setClassNames(temp)

        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    }, [])

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let temp = {}
        if (className !== "ALL") {
            temp = {
                class_name: className
            }
        }
        Api.post("/course/get-yie-courses-filter", temp).then((res) => {
            setCourses(res.data);
        }).catch((err) => {
            console.log(err);
        })
        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    }, [className])

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });

        Api.post("/course/get-chapters", { course_id: course }).then((res) => {
            setChapters(res.data);
            setChapter(res.data[0] ? res.data[0]._id : "")
        }).catch((err) => {
            console.log(err);
        })
        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    }, [course])
    const chunkSize = 1024 * 1024 * 10
    const uploadChunk = async (data, chunk, partNumber, { fileName, fileType }) => {
        const data_to_send = new FormData()
        data_to_send.append('file', chunk, { filename: fileName, contentType: fileType, knownLength: chunk.length })
        data_to_send.append('partNumber', partNumber)
        data_to_send.append('key', data.key)
        data_to_send.append('uploadId', data.uploadId)
        const res = await Api.post(`/course/upload-part`, data_to_send).then((res) => {
            res.data['PartNumber'] = partNumber
            console.log('DONE', res.data)
            return res.data
        })
        return res
    }
    const fileUpload = async (iamafiletoupload) => {
        const multipart_upload = await Api.post(`/course/create-multipart-upload`, {
            file_name: iamafiletoupload.name,
            course_id: course,
            file_mimetype: iamafiletoupload.type,
        })
        const uploadId = multipart_upload.data.UploadId
        const key = multipart_upload.data.Key
        let partNumber = 1;
        let chunks = [];
        for (let i = 0; i < iamafiletoupload.size; i += chunkSize) {
            chunks.push(uploadChunk({ key: key, uploadId: uploadId }, iamafiletoupload.slice(i, i + chunkSize), partNumber, { "fileName": iamafiletoupload.name, "fileType": iamafiletoupload.type }))
            if (chunks.length % 5 == 0) {
                await Promise.all(chunks)
            }
            console.log('CHUNKS', chunks)
            partNumber += 1
        }
        await Promise.all(chunks)
        let send_parts = []
        for await (const part of chunks) {
            send_parts.push(part)
            console.log('SEND PARTS', send_parts)
        }
        console.log('SEND PARTS', send_parts)

        const complete_multipart = await Api.post(`/course/complete-multipart-upload`, {
            key: key,
            uploadId: uploadId,
            parts: send_parts
        })
        return complete_multipart
    }
    const createYieContent = async () => {
        // if (subjectIdCourseName === "") {
        //     alert("Please select a course name");
        //     return;
        // }
        // if (description === "") {
        //     alert("Please enter description");
        //     return;
        // }
        // if (thumbnail === "") {
        //     alert("Please upload thumbnail");
        //     return;
        // }
        // if (thumbnail.size > 10485760) {
        //     alert("Thumbnail size should be less than 10MB");
        //     return;
        // }
        if (title === "") {
            alert("Please enter title");
            return;
        }
        if (doc === "") {
            alert("Please upload document");
            return;
        }
        if (chapter === "" || chapter === undefined) {
            console.log(chapter);
            alert("Please select a chapter");
            return;
        }

        dispatch({
            type: "SET_LOADING",
            loading: true,
        });

        let docUpload = await fileUpload(doc);
        let formData = new FormData();
        formData.append("chapter_id", chapter);
        formData.append("title", title);
        formData.append("content_type", type);
        formData.append("s3_key", docUpload?.data?.res?.Key);
        formData.append("Location", docUpload?.data?.res?.Location);
        if (transcript !== "") {
            let transcriptUpload = await fileUpload(transcript);
            formData.append("transcript[s3_key]", transcriptUpload?.data?.res?.Key);
            formData.append("transcript[Location]", transcriptUpload?.data?.res?.Location);
        }
        await Api.post("course/create-topic", formData).then((res) => {
            // setCreatedCourse(res.data);
            setPageNo(2);
        }).catch((err) => {
            setPageNo(-1)
        }).finally(() => {
            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        })
    }
    const doNothing = (event) => {
        return true;
    }

    return (
        <>

            <center>
                <h1 className="m-4">Upload YIE Content</h1>
                {pageNo == 1
                    ?

                    <div className="shadow-lg p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                        <Form.Group controlId="formFile" className="m-3">
                            <Stack type="vartical" gap={4}>
                                <InputGroup>
                                    <InputGroup.Text>Select Class</InputGroup.Text>
                                    <Form.Select aria-label="Select Class" value={className}
                                        onChange={(e) => setClassName(e.target.value)} >

                                        {classNames.map((cl, i) => (
                                            <option key={i} value={cl.id} style={{ width: "100%" }}>
                                                {cl.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>Select Course</InputGroup.Text>
                                    <Form.Select aria-label="Select Class" value={course.subject_name}
                                        onChange={(e) => setCourse(e.target.value)} >

                                        {courses.map((cl, i) => (
                                            <option key={i} value={cl._id} style={{ width: "100%" }}>
                                                {cl.subject_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>Select Chapter</InputGroup.Text>
                                    <Form.Select aria-label="Select Class" value={chapter.chapter_name}
                                        onChange={(e) => {
                                            console.log(chapter);
                                            setChapter(e.target.value)
                                        }} >

                                        {chapters.map((cl, i) => (
                                            <option key={i} value={cl._id} style={{ width: "100%" }}>
                                                {cl.chapter_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>Select Type</InputGroup.Text>
                                    <Form.Select aria-label="Select Class" value={type}
                                        onChange={(e) => setType(e.target.value)} >

                                        {TYPES.map((cl, i) => (
                                            <option key={i} value={cl} style={{ width: "100%" }}>
                                                {cl}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>
                                        Title
                                    </InputGroup.Text>
                                    <Form.Control
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    // placeholder="Enter Title"
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>Content</InputGroup.Text>
                                    <Form.Control
                                        type="file"
                                        // value={uploadFile}
                                        onChange={async (e) => setDoc(e.target.files[0])}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>Transcript</InputGroup.Text>
                                    <Form.Control
                                        type="file"
                                        // value={uploadFile}
                                        onChange={(e) => setTranscript(e.target.files[0])}
                                    />
                                </InputGroup>
                                <center style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outline-secondary" size="lg" onClick={createYieContent} style={{ minWidth: '150px' }} >
                                        Create YIE Content
                                    </Button>
                                </center>
                            </Stack>
                        </Form.Group>
                    </div>


                    : pageNo === -1 ?
                        <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                            <h3 className="m-4">Content Upload Failed!</h3>
                            <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                                <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => {
                                    setPageNo(1);
                                    // setClassName(0);
                                    // setCourse("");
                                    // setChapter("");
                                    setTitle("");
                                    setDoc("");
                                    setTranscript("");
                                }} >
                                    Add More
                                </Button>
                                <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
                                    Go to Dashboard
                                </Button>
                            </Stack>
                        </div>
                        :
                        <center>
                            <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                                <img src={`${process.env.PUBLIC_URL}/Images/sucess.png`} style={{ width: '100px' }} alt="" />
                                <h3 className="m-4">Content Uploaded Sucessfully!</h3>
                                <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                                    <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => {
                                        setPageNo(1);
                                        setClassName("");
                                        setCourse("");
                                        setChapter("");
                                        setTitle("");
                                        setDoc("");
                                        setTranscript("");
                                    }} >
                                        Add More
                                    </Button>
                                    <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
                                        Go to Dashboard
                                    </Button>
                                </Stack>
                            </div>


                        </center>

                }
            </center >
        </>
    )
};


export default AddYieContent;
