import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Api from "../../../Api/axios";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import "./admin_attendance.css";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Admin_Attendance() {
  const [attendanceType, setAttendanceType] = useState("Student");
  const [showTable, setShowTable] = useState(false);
  const [selectedSection, setSelectedSection] = useState();
  const [selectedClass, setSelectedClass] = useState();
  const [attendanceData, setAttendanceData] = useState([]);
  const [attendanceDataFiltered, setAttendanceDataFiltered] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [errMessage, setErrMessage] = useState();
  const [classListTemp, setClassListTemp] = useState({});
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [fetchAllStudents, setFetchAllStudents] = useState(false);
  const [startDate, setStartDate] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startDateFinal, setStartDateFinal] = useState();
  const [selectedDateFinal, setSelectedDateFinal] = useState();
  const [endDateFinal, setEndDateFinal] = useState();
  const [attdType, setAttdType] = useState();
  const [attdTypeFinal, setAttdTypeFinal] = useState();
  const [userType, setUserType] = useState();
  //const [allUserType, setAllUserType]=useState(false);

  const componentRef = useRef();

  useEffect(() => {
    getClasses();
  }, []);

  const toTimeStamp = (date) => {
    const d = Date.parse(date);
    return d;
  };

  const getClasses = async () => {
    let classTemp = {};
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.get("/live-class").then((res) => {
      res.data.forEach((class_) => {
        // console.log(class_);
        if (!(class_.class_name in classTemp)) {
          classTemp[class_.class_name] = {
            sections: [class_.class_section],
          };
        } else {
          let prevClassTemp = classTemp[class_.class_name].sections;
          prevClassTemp.push(class_.class_section);
          classTemp[class_.class_name].sections = prevClassTemp;
        }
      });
    });
    setClassListTemp(classTemp);
    const classesTemp = Object.keys(classTemp);
    setClasses(classesTemp);
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const selectClassFunc = (e) => {
    const { value } = e.target;
    setSelectedClass(value);
    if (value !== "All") {
      if (value === "") {
        setSections([]);
      } else {
        setSections(classListTemp[value].sections);
      }
    }
  };

  const getAttendanceList = async (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    if (selectedClass === "All") {
      setFetchAllStudents(true);
    } else {
      setFetchAllStudents(false);
    }
    if (attdType === "RANGE") {
      setStartDateFinal(startDate);
      setEndDateFinal(endDate);
      setAttdTypeFinal("RANGE");
    } else {
      setSelectedDateFinal(selectedDate);
      setAttdTypeFinal("DATE");
    }
    let studentsFetched = false;
    let allStudents = [];
    let allStudentsRecord = [];
    setShowTable(true);
    if (userType === "ALL") {
      await Api.get(`/user/by-school?id=${userDetails.schoolId}`)
        .then((res) => {
          allStudentsRecord = res.data;
          res.data.forEach((item) => {
            if (item.classes) {
              let classDetails =
                item.classes[item.classes.length - 1].split(" ");
              let className = classDetails[0];
              let classSection = classDetails[1];
              if (selectedClass === "All") {
                allStudents.push(item._id);
                studentsFetched = true;
              } else {
                if (
                  className === selectedClass &&
                  classSection === selectedSection
                ) {
                  // console.log(item);
                  allStudents.push(item._id);
                  studentsFetched = true;
                }
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
          studentsFetched = false;
        });
    } else {
      await Api.get(
        `/user/by-school?id=${userDetails.schoolId}&type=${userType}`
      )
        .then((res) => {
          allStudentsRecord = res.data;
          res.data.forEach((item) => {
            if (item.classes) {
              let classDetails =
                item.classes[item.classes.length - 1].split(" ");
              let className = classDetails[0];
              let classSection = classDetails[1];
              if (selectedClass === "All") {
                allStudents.push(item._id);
                studentsFetched = true;
              } else {
                if (
                  className === selectedClass &&
                  classSection === selectedSection
                ) {
                  // console.log(item);
                  allStudents.push(item._id);
                  studentsFetched = true;
                }
              }
            }
          });
        })
        .catch((err) => {
          console.log(err);
          studentsFetched = false;
        });
    }
    if (studentsFetched && attdType === "RANGE") {
      await Api.post("/attendance/student-report", {
        ids: allStudents,
        start_date: Date.parse(startDate),
        end_date: Date.parse(endDate.setDate(endDate.getDate() + 1)),
      })
        .then((res) => {
          let attendanceDataTemp = res.data.allstudents;
          // console.log(attendanceDataTemp);
          attendanceDataTemp.forEach((item) => {
            item.class_name = item.class_name_section.split(" ")[0];
            item.class_section = item.class_name_section.split(" ")[1];
          });
          setAttendanceData(attendanceDataTemp);
          setFilteredAttendanceDetails();
        })
        .catch((err) => {
          setErrMessage(err);
        });
    } else if (studentsFetched && attdType === "DATE") {
      let postBody;
      const d = new Date(selectedDate);
      const dateTosend = `${d.getDate()}/${
        d.getMonth() + 1
      }/${d.getFullYear()}`;

      if (selectedClass === "All") {
        postBody = {
          attendee_type: "STUDENT",
          date: dateTosend,
        };
      } else {
        postBody = {
          class_name: selectedClass,
          class_section: selectedSection,
          attendee_type: "STUDENT",
          date: dateTosend,
        };
      }
      await Api.post("/attendance/attendance-of-users", {
        attendance_data: postBody,
      })
        .then((res) => {
          let attendanceDataTemp;
          if (selectedClass === "All") {
            attendanceDataTemp = allStudentsRecord;
          } else {
            attendanceDataTemp = allStudentsRecord.filter(
              (st) =>
                st.classes !== null &&
                st.classes[st.classes.length - 1].split(" ")[0] ===
                  selectedClass &&
                st.classes[st.classes.length - 1].split(" ")[1] ===
                  selectedSection
            );
          }

          allStudentsRecord.forEach((st) => {
            st.attendance = "ABSENT";

            if (st.classes !== null) {
              st.class_name = st.classes[st.classes.length - 1].split(" ")[0];
              st.class_section =
                st.classes[st.classes.length - 1].split(" ")[1];
              st.class_name_section = `${
                st.classes[st.classes.length - 1].split(" ")[0]
              } ${st.classes[st.classes.length - 1].split(" ")[1]}`;
            }
          });
          res.data.attendance_list.forEach((class_data) => {
            let className = class_data.class_name;
            let classSection = class_data.class_section;

            let classStudents = allStudentsRecord.filter(
              (st) =>
                st.classes !== null &&
                st.classes[st.classes.length - 1].split(" ")[0] === className &&
                st.classes[st.classes.length - 1].split(" ")[1] === classSection
            );

            classStudents.forEach((st) => {
              if (class_data.attendees_present.includes(st._id)) {
                st.attendance = "PRESENT";
              } else {
                st.attendance = "ABSENT";
              }
              let targetStudent = attendanceDataTemp.filter(
                (st_temp) => st_temp._id === st._id
              )[0];
              targetStudent = st;
            });
          });
          setAttendanceData(attendanceDataTemp);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const changeTimeFormat = (date) => {
    const d = new Date(date);
    const returnDate = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
    return returnDate;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const changeFilterData = (e) => {
    const { id, value } = e.target;
    setFilterData({ ...filterData, [id]: value });
  };

  useEffect(() => {
    setFilteredAttendanceDetails();
  }, [filterData, attendanceData]);

  const setFilteredAttendanceDetails = () => {
    // console.log(attendanceData);
    const keys = Object.keys(filterData);
    let allEmpty = Object.values(filterData).every(
      (x) => x === null || x === "" || x === undefined
    );
    if (allEmpty || filterData === {}) {
      setAttendanceDataFiltered(attendanceData);
    } else {
      let tempAttendanceFilteredData = attendanceData.filter((attd) => {
        return keys.every((key) =>
          attd[key]
            ?.toLocaleLowerCase()
            .includes(filterData[key]?.toLocaleLowerCase())
        );
      });
      setAttendanceDataFiltered(tempAttendanceFilteredData);
    }
  };

  return (
    <div className="ad-attnd-container">
      <div className="ad-attnd-container-main">
        <div className="ad-attnd-top">
          {/* <button
            className={attendanceType === "Staff" && "ad-attnd-top-btn-active"}
            onClick={() => {
              setAttendanceType("Staff");
            }}
          >
            Staff
          </button> */}
          {/* <button
            className={
              attendanceType === "Student" && "ad-attnd-top-btn-active"
            }
            onClick={() => {
              setAttendanceType("Student");
            }}
          >
            Student
          </button> */}
        </div>

        {/* <h4>{attendanceType === "Staff" ? "Staff Attendance" : ""}</h4> */}

        {/* <div
          className={`ad-attnd-middle ${
            attendanceType === "Student" && "ad-attnd-middle-invis"
          }`}
        >
          <Link to="teacher_attendance">
            <button>Update Teachers Attendance</button>
          </Link>
          <Link to="teacher_attendance/prev-attnd">
            <button>View Previous Attendance</button>
          </Link>
        </div> */}

        <div
          className={`ad-attnd-bottom ${
            attendanceType !== "Student" && "ad-attnd-bottom-invis"
          }`}
        >
          <form
            className="ad-st-attnd-top"
            onSubmit={(e) => {
              getAttendanceList(e);
            }}
          >
            <label>User Type :</label>
            <select
              name="user-type"
              id="user-type"
              onChange={(e) => setUserType(e.target.value)}
              required
            >
              <option value="">Select User Type</option>
              <option value="ALL">ALL</option>
              <option value="STUDENT">STUDENT</option>
              <option value="TEACHER">TEACHER</option>
            </select>
            <label htmlFor="attd-type">Attendance Type :</label>
            <select
              name="attd-type"
              id="attd-type"
              onChange={(e) => setAttdType(e.target.value)}
              required
            >
              <option value="">Select type</option>
              <option value="DATE">Specific Date</option>
              <option value="RANGE">Date Range</option>
            </select>

            <label htmlFor="ad-st-attnd-class">Select Class : </label>
            <select
              name="ad-st-attnd-class"
              id="ad-st-attnd-class"
              onChange={(e) => {
                selectClassFunc(e);
              }}
              required
            >
              <option value="">Select Class</option>
              <option value="All">All</option>
              {classes.map((class_) => (
                <option value={class_}>{class_}</option>
              ))}
            </select>
            {selectedClass !== "All" && (
              <>
                <label htmlFor="ad-st-attnd-dept">Select Section : </label>

                <select
                  name="ad-attnd-teach-dept"
                  id="ad-attnd-teach-dept"
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}
                  required
                >
                  <option value="">Select Section</option>
                  {sections.map((section_) => (
                    <option value={section_}>{section_}</option>
                  ))}
                </select>
              </>
            )}
            {attdType === "RANGE" && (
              <>
                <div>
                  <DatePicker
                    placeholderText="Start Date (optional)"
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    minDate={new Date().setDate(new Date().getDate() - 10)}
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                  />
                </div>
                <div>
                  <DatePicker
                    placeholderText="End Date (optional)"
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={new Date().setDate(new Date().getDate() - 10)}
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                  />
                </div>
              </>
            )}
            {attdType === "DATE" && (
              <>
                <div>
                  <DatePicker
                    placeholderText="Select Date"
                    selected={selectedDate}
                    onChange={(date) => setSelectedDate(date)}
                    minDate={new Date().setDate(new Date().getDate() - 10)}
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown={true}
                    showYearDropdown
                    required
                  />
                </div>
              </>
            )}
            <button
              type="button"
              onClick={() => {
                setStartDate();
                setEndDate();
                setSelectedDate();
              }}
            >
              Clear Dates
            </button>
            <button type="submit">Get Attendance Record</button>
            {showTable && (
              <button type="submit" onClick={handlePrint}>
                Print Attendance Record
              </button>
            )}
          </form>
          {showTable ? (
            <div className="ad-st-attnd-bottom" ref={componentRef}>
              <p>
                Class - {selectedClass} | Section -{" "}
                {selectedClass === "All" ? "All" : selectedSection} |
                {attdTypeFinal === "RANGE"
                  ? ` Start Date : ${
                      startDateFinal ? changeTimeFormat(startDateFinal) : "*"
                    } | End date : ${
                      endDateFinal ? changeTimeFormat(endDateFinal) : "*"
                    } `
                  : ` Date : ${changeTimeFormat(selectedDateFinal)} `}
                | Attendance Report
              </p>
              <div className="ad-st-attnd-bottom-info">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <div className="ad-st-attnd-table-filter">
                          Student Name
                          <input
                            type="text"
                            id="name"
                            placeholder="eg: Ayan"
                            onChange={(e) => changeFilterData(e)}
                          />
                        </div>
                      </th>
                      <th>
                        <div className="ad-st-attnd-table-filter">
                          Conventional Id
                          <input
                            type="text"
                            id={`${
                              attdTypeFinal === "DATE"
                                ? "conventionalId"
                                : "conventional_id"
                            }`}
                            placeholder="eg: STUDENT-1653XXXXXX123"
                            onChange={(e) => changeFilterData(e)}
                          />
                        </div>
                      </th>
                      {fetchAllStudents && (
                        <th>
                          <div className="ad-st-attnd-table-filter">
                            Class
                            <input
                              type="text"
                              id="class_name"
                              placeholder="eg: 5"
                              onChange={(e) => changeFilterData(e)}
                            />
                          </div>
                        </th>
                      )}
                      {fetchAllStudents && (
                        <th>
                          <div className="ad-st-attnd-table-filter">
                            Section
                            <input
                              type="text"
                              id="class_section"
                              placeholder="eg: A"
                              onChange={(e) => changeFilterData(e)}
                            />
                          </div>
                        </th>
                      )}

                      {attdTypeFinal === "RANGE" && (
                        <>
                          <th>
                            <div className="ad-st-attnd-table-filter">
                              Number of Attended Days
                            </div>
                          </th>
                          <th>
                            <div className="ad-st-attnd-table-filter">
                              Total Working Days
                            </div>
                          </th>
                        </>
                      )}
                      {attdTypeFinal === "DATE" && (
                        <>
                          <th>
                            <div className="ad-st-attnd-table-filter">
                              Attendance
                              <input
                                type="text"
                                id="attendance"
                                placeholder="eg: A"
                                onChange={(e) => changeFilterData(e)}
                              />
                            </div>
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceDataFiltered?.map(
                      (e, i) =>
                        e.class_name_section !== undefined && (
                          <tr key={i}>
                            <td>{e?.name}</td>
                            <td>{e?.conventional_id || e?.conventionalId}</td>
                            {fetchAllStudents && (
                              <>
                                <td>{e?.class_name_section?.split(" ")[0]}</td>
                                <td>{e?.class_name_section?.split(" ")[1]}</td>
                              </>
                            )}

                            {attdTypeFinal === "RANGE" && (
                              <>
                                <td>{e?.attendance_count}</td>
                                <td>{e?.total_class_count}</td>
                              </>
                            )}
                            {attdTypeFinal === "DATE" && (
                              <>
                                <td>{e?.attendance}</td>
                              </>
                            )}
                          </tr>
                        )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      .
    </div>
  );
}

export default Admin_Attendance;
