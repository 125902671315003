export function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export function validationUserAssessment(answer){
let isError=false
answer.map((x)=>{
  if(x.quesType==="mcq"){
    if(x.userAnwer===""){
      console.log("mcq err")
      isError=true
    }

  }else if(x.quesType==="msq"){
    if(x.userAnwer.length===0){
      console.log("msq err")
      isError=true
    }

  }else if(x.quesType==="subjective"){
    if(x.userAnwer===""){
      isError=true
    }
  }
})
return isError
}

export function indexChanging(quesArr, ansArr){
  let updated=[]
  // console.log("quesArr ==>",quesArr,"ansArr ==>", ansArr)
  ansArr.map((x,i)=>{
    if(x.quesType==="mcq"){
      let index=0 //internal user restricted // need to be teted with index
      let len=quesArr[i].options.length
      for(let j=0;j<len;j++){
          if(quesArr[i].options[j].id===x.userAnwer){
              // console.log("index ==> ",index)
              updated.push({
                question_id:x.quesId,
                answer:index
              })
              break
          }else{
              // console.log("executing")
              index++
          }

        }
    }else if(x.quesType==="msq"){
                        let index=0
                        let userAnsObj={}
                        let userAnsArr=[]
                        x.userAnwer.map((id)=>{
                            userAnsObj[id]=id
                        })
                        quesArr[i].options.map((y)=>{
                            if(userAnsObj[y.id]){
                                userAnsArr.push(index)
                                index++
                            }else{
                                // console.log("executing msq")
                                index++
                            }
                        })
                        updated.push({
                          question_id:x.quesId,
                          answer:userAnsArr
                        })
  
    }else if(x.quesType==="subjective"){
                updated.push({
                  question_id:x.quesId,
                  answer:x.userAnwer
                })

    }
  })

  return updated

}