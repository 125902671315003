import React, { useState, useEffect } from "react";
import { Box, MenuItem, Multiselect, Grid } from "@mui/material";
import TextField from "@material-ui/core/TextField";
// import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddIcon from "@material-ui/icons/Add";
import Icon from "@material-ui/core/Icon";
import Quiz from "../../Quiz/Quiz";
// import FormControl from "@mui/material/FormControl";
// import "./Mcq.css";
import { withStyles } from "@material-ui/core/styles";
import { Container } from "@mui/material";

import { v4 as uuidv4 } from "uuid";
// import SelectField from '@material-ui/SelectField';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { makeStyles } from "@material-ui/core/styles";
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SettingsIcon from "@material-ui/icons/Settings";
// import { DateTimePicker } from '@material-ui/pickers'
// import * as React from 'react';
// import dayjs, { Dayjs } from 'dayjs';
// import TextField from '@mui/material/TextField';
// import DateTimePicker from 'react-datetime-picker'
import dayjs from "dayjs"; //{ Dayjs }
// import TextField from '@mui/material/TextField';

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import { Multiselect as MultiselectReactDropDown } from "multiselect-react-dropdown"; //dynamic
// import { Dropdown } from "react-bootstrap";
import Api from "../../../Api/axios";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

import Table from "react-bootstrap/Table";
import { borderBottom } from "@mui/system";

import {
  UserTypes,
  difficultyLevels,
  difficultyLevelsQues,
  chapters,
  topics,
  subTopics,
  AssigningShowByOrder,
  AssessmentShowByOrder,
  questionTypes,
  AddtionalQuesFormat,
} from "../Constants";
import { useParams } from "react-router";

const muiUseStyles = makeStyles((theme) => ({
  QuestionType: {
    width: "180px",
  },
}));

let updatedEndTime = dayjs().set("minute", dayjs().get("minute") + 2);

const EditAssessment = () => {
  const aId = useParams();
  console.log("sucess", aId.assessment_id);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [assessmentData, setAssessmentData] = useState([]);
  const [filterAssessment, setFilterAssessment] = useState([]);
  const [assesmentPage, setAssesmentPage] = useState("1");
  const [assesmentType, setAssesmentType] = useState("");
  const [assesmentName, setAssesmentName] = useState("");
  const [schoolList, setSchoolList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [userType, setuserType] = useState("");

  console.log("id", aId.assessment_id);

  useEffect(() => {
    Api.get("/assessment/created-assessment").then((res) => {
      let AList = [];
      let fetchedData = res.data;
      fetchedData.forEach((x) => {
        AList.push(x);
      });
      setAssessmentData(AList);
    });
  }, [userDetails]);

  useEffect(() => {
    const filter = assessmentData.filter((x) => x._id === aId.assessment_id);
    setFilterAssessment(filter.map((x) => x));
  }, [assessmentData]);

  // useEffect(() => {
  //   filterAssessment.forEach((x) => {
  //     setAssesmentName(x.title);
  //     setuserType(x.user_type);
  //   });
  // }, [filterAssessment]);

  const handleAssesmentNameChange = (event) => {
    setAssesmentName(event.target.value);
  };
  const [assesmentClass, setAssesmentClass] = useState("ALL"); //not render..coz of single select
  const handleAssesmentClassChange = (event) => {
    setAssesmentClass(event.target.value);
  };
  const [assesmentSubject, setAssesmentSubject] = useState("");
  const handleAssesmentSubjectChange = (event) => {
    setAssesmentSubject(event.target.value);
    setAssessmentOrderElements((pre) => {
      let updated = [...pre];
      updated[1].selected = true;
      return updated;
    });
  };

  const handleUserTypeChange = (event) => {
    setuserType(event.target.value);
    if (
      userDetails?.userType === "SCHOOL_ADMIN" ||
      userDetails?.userType === "TEACHER"
    ) {
      setOrderElements((pre) => {
        let updated = [...pre];
        updated[2].selected = true;
        return updated;
      });
    } else {
      setOrderElements((pre) => {
        // console.log("firstFalseobjIndex pre 1",pre)

        // let firstFalseobjIndex=pre.findIndex((e)=>e.selected===false)
        // console.log("firstFalseobjIndex 1",firstFalseobjIndex)
        // return pre
        // if(firstFalseobjIndex!==-1){
        let updated = [...pre];
        updated[1].selected = true;
        //  console.log("updatred =======>", updated)
        return updated;
        // }else{
        //     return pre
        // }
      });
    }
  };
  const [SelectSchool, setSelectSchool] = useState();
  const handleSelectSchoolChange = (event) => {
    setSelectSchool(event.target.value);
    setOrderElements((pre) => {
      let updated = [...pre];
      updated[2].selected = true;
      return updated;
    });
  };
  const [NoTimeLimit, setNoTimeLimit] = useState(false);
  const handleNoTimeLimitChange = () => {
    setNoTimeLimit((pre) => !pre);
  };
  const [assesmentChapter, setAssesmentChapter] = useState("");
  const handleAssesmentChapterChange = (event) => {
    setAssesmentChapter(event.target.value);
    setAssessmentOrderElements((pre) => {
      let updated = [...pre];
      updated[2].selected = true;
      return updated;
    });
  };
  const [assesmentTopic, setAssesmentTopic] = useState("");
  const handleAssesmentTopicChange = (event) => {
    setAssesmentTopic(event.target.value);

    setAssessmentOrderElements((pre) => {
      let updated = [...pre];
      updated[3].selected = true;
      return updated;
    });
  };
  const [assesmentSubTopic, setAssesmentSubTopic] = useState("");
  const handleAssesmentSubTopicChange = (event) => {
    setAssesmentSubTopic(event.target.value);
  };
  const handleNullAssesmentPageChange = (event) => {
    // if (validateAssesmentPage1()) {
    setAssesmentPage("5");
    setAssesmentType("NULL");
    // }
  };
  const handleRandomAssesmentPageChange = (event) => {
    // if (validateAssesmentPage1()) {
    setAssesmentPage("4");
    setAssesmentType("RANDOM");
    // }
  };
  const handleCustomAssesmentPageChange = (event) => {
    // if (validateAssesmentPage1()) {
    setAssesmentPage("4");
    setAssesmentType("CUSTOM");
    // }
  };
  useEffect(() => {
    if (userDetails?.userType === "DISTRICT") {
      Api.get("/school-entity/school").then((res) => {
        let SchoolList = [];
        let fetchedData = res.data;
        fetchedData.forEach((x) => {
          let obj = {};
          obj.value = x._id;
          obj.label = x.name;
          SchoolList.push(obj);
        });
        setSchoolList(() => SchoolList);
      });
    }
  }, []);

  const [assesmentStartTime, setAssesmentStartTime] = useState(dayjs());
  const handleAssesmentStartTimeChange = (event) => {
    setAssesmentStartTime(event);
  };
  const [assesmentEndTime, setAssesmentEndTime] = useState(updatedEndTime);
  // console.log("assesmentEndTime ",assesmentStartTime,"assesmentEndTime",assesmentEndTime)
  const handleAssesmentEndTimeChange = (event) => {
    setAssesmentEndTime(event);
  };
  const [randomFilter, setRandomFilter] = useState([
    { id: uuidv4(), difficultyLevel: "ALL", noOfQues: 1 },
  ]);
  const handleRandomFilterAdd = (event) => {
    setRandomFilter([
      ...randomFilter,
      { id: uuidv4(), difficultyLevel: "ALL", noOfQues: 1 },
    ]);
  };
  const handleRandomFilterRemove = (filterId, event) => {
    setRandomFilter(randomFilter.filter((filter) => filter.id !== filterId));
  };
  const handleRandomFilterDifficultyLevelChange = (filterId, event) => {
    setRandomFilter(
      randomFilter.map((filter) =>
        filter.id === filterId
          ? { ...filter, difficultyLevel: event.target.value }
          : filter
      )
    );
  };
  const handleRandomFilterNoOfQuesChange = (filterId, event) => {
    const value = event.target.value.replace(/\D/g, "");
    setRandomFilter(
      randomFilter.map((filter) =>
        filter.id === filterId ? { ...filter, noOfQues: value } : filter
      )
    );
  };
  const validateAssesmentPage1 = (event) => {
    if (userDetails?.userType === "SCHOOL_ADMIN") {
      if (filterUserClasses.length === 0) {
        alert("Class should be selected");
        return;
      }
    } else if (userDetails?.userType === "TEACHER") {
      if (filterUserClasses.length === 0) {
        alert("Class should be selected");
        return;
      }
    } else if (userDetails?.userType === "DISTRICT") {
      //from log in
      if (userType !== "SchoolAdmin") {
        if (filterUserClasses.length === 0) {
          alert("Class should be selected");
          return;
        }
        if (SelectSchool == "") {
          alert("School should be selected");
          return;
        }
      }
    }

    if (assesmentName === "") {
      alert("Assesment Name cannot be empty");
      return false;
    }
    if (!NoTimeLimit && assesmentStartTime >= assesmentEndTime) {
      alert("Start Time should be less than End Time");
      return false;
    }
    if (userType === "") {
      alert("userType should be selected");
      return;
    }
    // if(SelectSchool==""){
    //     alert("School should be selected");
    //     return
    // }
    if (assesmentChapter === "") {
      alert("assesmentChapter should be selected");
      return;
    }
    if (assesmentTopic === "") {
      alert("assesmentTopic should be selected");
      return;
    }
    if (assesmentSubTopic === "") {
      alert("assesmentSubTopic should be selected");
      return;
    }

    // if(userType!=="SchoolAdmin"){
    //     if(filterUserClasses.length===0){
    //         alert("Class should be selected");
    //         return
    //     }
    // }
    if (assesmentSubject === "") {
      alert("Assesment Subject should be selected");
      return;
    }

    setAssesmentPage("2");
    return true;
  };
  const validateAssesmentPage2Random = (event) => {
    if (randomFilter.length === 0) {
      alert("Atleast one filter should be added");
      return false;
    }
    if (randomFilter.some((filter) => filter.difficultyLevel === "")) {
      alert("Difficulty Level cannot be NULL");
      return false;
    }
    if (randomFilter.some((filter) => filter.noOfQues <= 0)) {
      alert("No. of Questions should be greater than 0");
      return false;
    }
    return true;
  };
  // const[files,setFiles]=useState("")

  const validateAssesmentPage4Custom = () => {
    let isError = false;
    inputQuesFields.forEach((obj) => {
      if (obj.questionType !== "DocumentUpload") {
        if (obj.description === "") {
          // alert("a")
          isError = true;
          return;
        }
      }
      if (obj.questionType === "MCQ" || obj.questionType === "MSQ") {
        if (obj.options.length < 2 || obj.correctAnswer === "") {
          console.log("opyioms length errr");
          isError = true;
          return;
        }
        obj.options.forEach((optionObj) => {
          if (optionObj.option === "") {
            console.log("opyioms empty errr");
            isError = true;
            return;
          }
        });
      }

      // if(obj.questionType==="DocumentUpload"){
      //     if(obj?.files===undefined || obj.files===""){
      //         alert("b")
      //         isError=true;
      //         return
      //     }
      //     // console.log("document",files)
      // }
      if (obj.difficulty === "" || obj.correctMarks === 0) {
        //common
        console.log("common opyioms empty errr");

        isError = true;
        return;
      }
      // alert("v")
    });
    return !isError;
  };

  function idGenerator() {
    return uuidv4();
  }
  const [Error, setError] = useState({
    isError: false,
    statement: "",
  });
  const [userEmptyCheckError, setUserEmptyCheckError] = useState({
    isError: false,
    statement: "",
  });
  const goToDashboard = (event) => {
    window.location.href = window.location.origin;
  };
  // const setAllVariablesToDefault = (event) => {
  //   filterAssessment.forEach((x) => {
  //     setAssesmentPage("1");
  //     setAssesmentType("");
  //     setAssesmentName(x.title);
  //     setuserType(x.user_type);
  //     setSelectSchool(x.school);
  //     // setAssesmentClass("ALL");
  //     setAssesmentSubject("ALL");
  //     setAssesmentChapter("ALL");
  //     setAssesmentTopic("ALL");
  //     setNoTimeLimit(false);
  //     setUserList([]);
  //     setFilterUserClasses([]);
  //   });
  //   // console.log(handeleCreateAssesment(event));
  //   setError({
  //     isError: false,
  //     statement: "",
  //   });
  //   setUserEmptyCheckError({
  //     isError: false,
  //     statement: "",
  //   });
  //   setAssesmentSubTopic("ALL");
  //   setAssesmentStartTime(dayjs());
  //   let updatedEndTime = dayjs().set("minute", dayjs().get("minute") + 2);
  //   setAssesmentEndTime(updatedEndTime);
  //   setRandomFilter([{ id: uuidv4(), difficultyLevel: "ALL", noOfQues: 1 }]);
  //   setQuesInputFields([
  //     {
  //       id: uuidv4(),
  //       description: "",
  //       questionType: "MCQ",
  //       difficulty: "",
  //       correctAnswer: "",
  //       correctMarks: 0,
  //       incorrectMarks: 0,
  //       options: [
  //         { id: uuidv4(), option: "", selected: false },
  //         { id: uuidv4(), option: "", selected: false },
  //       ],
  //     },
  //   ]);
  //   setError((pre) => {
  //     return {
  //       ...pre,
  //       isError: false,
  //       statement: "",
  //     };
  //   });
  // };
  // useEffect(())
  useEffect(() => {
    function scrollToTop() {
      // console.log("caling inside")
      // window.scrollTo(0,0)
      document
        .getElementById("custom-wrapper")
        .scrollIntoView({ behavior: "smooth" });
    }
    if (Error?.isError) {
      console.log("caling");
      scrollToTop();
    }
    // if(userEmptyCheckError?.isError){
    //     scrollToTop()
    // }
  }, [Error]);
  const generateAssesment = (event) => {
    if (assesmentType === "RANDOM") {
      if (validateAssesmentPage2Random()) {
        setAssesmentPage("3");
        //lets assume question is mixup with as easy and meduim
        /*
                let AssesmentQuestions=[]
                let easyCount=randomFilter[0].noOFQues//as of now
                let mediumCount=randomFilter[1].noOFQues
                fakedata.forEach((x)=>{ //fetching data using API
                   if(x=="easy" && easyCount!=0){
                        AssesmentQuestions.push(x)
                        easyCount--
                   }else if(x=="medium" && mediumCount!=0){
                        AssesmentQuestions.push(x)
                        mediumCount--
                   }
                })
                  */
        console.log("randomFilter", randomFilter);
      } else {
        return;
      }
    } else if (assesmentType === "CUSTOM") {
      if (validateAssesmentPage4Custom()) {
        dispatch({
          type: "SET_LOADING",
          loading: true,
        });
        // post api
        let newQuestionsArray = [];
        let inputQuestions = [...inputQuesFields];
        inputQuestions.forEach((x) => {
          let newObj;
          if (x.questionType === "MCQ") {
            newObj = {
              description: x.description,
              correct_marking: x.correctMarks,
              incorrect_marking: x.incorrectMarks,
              options: x.options,
              correct_answer: x.correctAnswer,
              type: "mcq",
              difficulty: x.difficulty,
            };
          } else if (x.questionType === "MSQ") {
            newObj = {
              description: x.description,
              correct_marking: x.correctMarks,
              incorrect_marking: x.incorrectMarks,
              options: x.options,
              correct_answer: x.correctAnswer,
              type: "msq",
              difficulty: x.difficulty,
            };
          } else if (x.questionType === "Subjective") {
            newObj = {
              description: x.description,
              correct_marking: x.correctMarks,
              incorrect_marking: x.incorrectMarks,
              type: "subjective",
              subjective_type: "any",
              word_limit: x.wordsLimit,
              difficulty: x.difficulty,
            };
            if (x.isAnswerNumeric) {
              newObj["subjective_type"] = "numeric";
            }
          }
          newQuestionsArray.push(newObj);
        });

        let classIds = [];
        filterUserClasses.forEach((x, i) => {
          // param[`classes[${i}]`]=x.classid
          classIds.push(x.classid);
        });
        let userIds = [];
        userList.forEach((x) => {
          if (x.checked) {
            userIds.push(x.userId);
          }
        });
        let selectedSchoolId = "";
        schoolList.forEach((x) => {
          if (x.value === SelectSchool) {
            // console.log("insdie inside selectedSchoolId 22==>",SelectSchool,x.label)
            selectedSchoolId = x.value;
          }
        });
        let bodyObj = {
          title: assesmentName, //name
          type: "quiz",
          class: classIds, //["37d65615-14f6-4b9d-82ed-7e39530c8964"]
          user_ids: userIds,
          ended_at: assesmentEndTime, //1671849962624
          late_submission: NoTimeLimit, //true
          evaluate_mcqs: true, //true
          user_type: userType, //STUDENT
          questions: newQuestionsArray, //above ref,
          school: selectedSchoolId,
        };
        console.log("classIds", classIds, "userIds", userIds);

        console.log("bodyObj======>", bodyObj);
        Api.post("/assessment/create-assessment", bodyObj)
          .then((res) => {
            console.log("bodyObj and res =====>", bodyObj, res);
            setAssesmentPage("5");
            setError((pre) => {
              return {
                ...pre,
                isError: false,
                statement: "",
              };
            });
          })
          .catch((err) => {
            console.log("err while sending", err);
          });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });

        console.log("before gtherd question", inputQuesFields); //need review
        // inputQuesFields.forEach((quesObj)=>{ //as of now not need
        //     if(quesObj.questionType==="DocumentUpload"){
        //         let formData=new FormData();
        //         console.log("empty form ", formData)
        //         // for(let key of Object.keys(quesObj.files)){
        //         //     console.log("quesObj.files[key]",quesObj.files[key])
        //         //     formData.append("docs",quesObj.files[key])
        //         // }
        //         // console.log("formdata",formData)
        //         // quesObj.files=formData
        //         // console.log("typeof",typeof quesObj.files);
        //         for(let i=0;i<quesObj.files.length;i++){
        //             // console.log("here",quesObj.files[i], i);
        //             formData.append("docs",quesObj.files[i])
        //         }
        //         quesObj.files=formData
        //         for(let key of formData.entries()){
        //             console.log("key==>",key,key[0],"form dta formData ==>",key[1]);
        //         }

        //     }
        // })
        /*
                Api.post("/url",ToBeSendData)
                
                */
        // console.log("after gtherd question",inputQuesFields)
        // console.log("testing ===>")

        // console.log("gtherd question",files)
        // inputQuesFields.forEach((quesObj)=>{
        //     if(quesObj.questionType==="DocumentUpload"){
        //         for(let key of quesObj.files.entries()){
        //             console.log("key==>",key,key[0],"form dta formData ==>",key[1]);
        //         }

        //     }
        // })
      } else {
        setError((pre) => {
          return {
            ...pre,
            isError: true,
            statement: "Fields can not be empty or options is not selected",
          };
        });
        return;
      }
    } else {
      return;
    }
  };
  const handeleCreateAssesment = (event) => {
    return {
      assesmentName: assesmentName,
      assesmentType: assesmentType,
      assesmentClass: assesmentClass,
      assesmentSubject: assesmentSubject,
      assesmentChapter: assesmentChapter,
      assesmentTopic: assesmentTopic,
      assesmentSubTopic: assesmentSubTopic,
      assesmentStartTime: assesmentStartTime,
      assesmentEndTime: assesmentEndTime,
      randomFilter: randomFilter,
      inputQuesFields: inputQuesFields,
    };
  };
  // const [classList, setClassList] = useState([]); //dynamic
  const [filterUserClasses, setFilterUserClasses] = useState([]);
  // const [filterUserSubjects, setFilterUserSubjects] = useState([]);

  const userTypes = UserTypes(userDetails);
  const [subjects, setsubjects] = useState([]);
  useEffect(() => {
    Api.get(`/preconfig-entites/subjects`).then((res) => {
      let fetchedSubjects = [...res.data];
      let updatedSub = [];
      fetchedSubjects.forEach((x) => {
        let obj = {};
        obj.label = x.name;
        obj.value = x._id;
        updatedSub.push(obj);
      });
      setsubjects(() => updatedSub);
    });
  }, []);

  // const[userAssigningList,setUserAssigningList]=useState([])
  const [userList, setUserList] = useState([]);
  // const[schoolId,setSchoolId]=useState()
  useEffect(() => {
    if (SelectSchool) {
      let selectedSchoolId = "";
      schoolList.forEach((x) => {
        if (x.value === SelectSchool) {
          // console.log("insdie inside selectedSchoolId 22==>",SelectSchool,x.label)
          selectedSchoolId = x.value;
        }
      });
      const user = {
        Teacher: "TEACHER",
        Student: "STUDENT",
        SchoolAdmin: "SCHOOL_ADMIN",
      };
      // console.log("userType userType=======>>>==>",userType,user[userType]);
      // console.log("selectedSchoolId",selectedSchoolId, SelectSchool)
      let fetchedUserData = [];

      if (userType !== "SchoolAdmin" && filterUserClasses?.length !== 0) {
        let param = { userType: user[userType], schoolId: selectedSchoolId };
        // forClasses.map((cl, i) => {
        //     param[`classes[${i}]`] = cl._id;
        // })
        // console.log("filter",filterUserClasses,"overall",classList)
        let ids = [];
        filterUserClasses.forEach((x, i) => {
          // param[`classes[${i}]`]=x.classid
          ids.push(x.classid);
        });
        param.classes = ids;

        //    let idsString=""
        //    ids.forEach((x,i)=>{
        //     if(i!==ids.length-1){
        //         idsString+=x+","
        //     }else{
        //         idsString+=x
        //     }
        //    })
        //    console.log("idsString==>",idsString, ids)
        Api.get(`/user/users-data`, { params: param })
          .then((res) => {
            // console.log("r==============>",res.data);
            // console.log("1 ==>with class use  required===>",res.data)
            fetchedUserData = [...res.data];
            let requiredUserDetails = [];
            fetchedUserData.forEach((x) => {
              let obj = {};
              obj.name = x.name;
              obj.userType = x.userType;
              obj.username = x.username;
              obj.checked = false;
              obj.userId = x._id;
              requiredUserDetails.push(obj);
            });
            //  requiredUserDetails.push({//dev testing
            //     name:"dev student",
            //     userType:"STUDENT",
            //     username:"dev username",
            //     userId:"2fdb0144-4d14-4737-9e2f-14833f55340d",
            //     checked:true
            //  })
            console.log(" with class use  required===>", requiredUserDetails);
            setUserList(() => requiredUserDetails);
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else if (userType === "SchoolAdmin") {
        Api.get(
          `/user/users-data/?schoolId=${selectedSchoolId}&userType=${user[userType]}`
        )
          .then((res) => {
            // console.log(" admin user  required===>",res.data)
            fetchedUserData = [...res.data];
            let requiredUserDetails = [];
            fetchedUserData.forEach((x) => {
              let obj = {};
              obj.name = x.name;
              obj.username = x.username;
              obj.checked = false;
              obj.userId = x._id;
              requiredUserDetails.push(obj);
            });
            console.log(" admin user required===>", requiredUserDetails);

            setUserList(() => requiredUserDetails);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
    // console.log("value SelectSchool ",SelectSchool)
  }, [SelectSchool, filterUserClasses, userType]);

  useEffect(() => {
    if (
      SelectSchool ||
      userDetails?.userType === "SCHOOL_ADMIN" ||
      userDetails?.userType === "TEACHER"
    ) {
      let selectedSchoolId = ""; //testb
      if (userDetails?.userType === "DISTRICT") {
        schoolList.forEach((x) => {
          if (x.value === SelectSchool) {
            selectedSchoolId = x.value;
          }
        });
      } else {
        selectedSchoolId = userDetails.schoolId;
      }
      console.log(selectedSchoolId);
      Api.get(`/school-entity/all-classes/?school_id=${selectedSchoolId}`)
        .then((res) => {
          let sectionWise = [...res.data];
          let classListFetched = [];
          sectionWise.forEach((x) => {
            let obj = {};
            obj.class_name_section = x.class_name_section;
            obj.class_name = x.class_name;
            obj.classid = x._id;
            classListFetched.push(obj);
          });
          setClassList(() => classListFetched); //bug  set filter class to empty when changing class
          setFilterUserClasses(() => []);
          // console.log(sectionWise,"<=======classListFetched=======>");
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [SelectSchool]);

  // const [subjectList, setSubjectList] = useState([]);

  // useEffect(() => {
  //    if (userDetails?.schoolId) {
  //    (async function getClassAndSubject(){
  //         await Api.get("school-entity/all-classes?school_id=" + userDetails?.schoolId).then((res) => {
  //         setClassList(res.data);
  //         setFilterUserClasses([])
  //     })
  //     await Api.get("preconfig-entites/subjects").then((res) => {
  //         res.data.sort((a, b) => a.name.localeCompare(b.name));
  //         setSubjectList(res.data);
  //     })
  //     })()

  //     } else {
  //         setClassList([]);
  //         setFilterUserClasses([])
  //     }
  // }, [userDetails]);
  // function handleChange(e){
  //     console.log("e2 ==>",e)
  // }

  const [assessmentType, setAssessmentType] = useState([questionTypes[2]]);

  const [inputQuesFields, setQuesInputFields] = useState([
    AddtionalQuesFormat(uuidv4(), uuidv4(), uuidv4(), assessmentType),
  ]);
  function validateUserSelection() {
    let isError = false;
    let notCheckedCount = 0;
    userList?.forEach((obj) => {
      if (!obj.checked) {
        notCheckedCount++;
      }
    });
    if (notCheckedCount === userList?.length) {
      isError = true;
    }
    if (isError) {
      setUserEmptyCheckError({
        isError: isError,
        statement: "minimum one wil be selected",
      });
    }

    if (!isError) {
      console.log("moving tpo 3");
      setAssesmentPage("3");
    }
  }
  function selectAssessmentType(e) {
    //no functionality
    console.log("selecrted", e.target.value);
    let selectedValue = e.target.value;
    // console.log("selecrted",e.target)
    let SelectedObjoptions = [...questionTypes].find(
      (obj) => obj.value === selectedValue
    );
    // console.log("ops",SelectedObjoptions)

    setAssessmentType([SelectedObjoptions]);
    if (selectedValue !== "MixedMcqSub") {
      setQuesInputFields(
        inputQuesFields.map((inputQuestion) => {
          return { ...inputQuestion, questionType: e.target.value };
        })
      );
    } else if (selectedValue === "MixedMcqSub") {
      setQuesInputFields(
        inputQuesFields?.map((inputQuestion) => {
          return { ...inputQuestion, questionType: "MCQ" };
        })
      );
    }
    // else if(selectedValue==="Subjective"){

    // }
    // console.log("seleted array",[SelectedObjoptions])
    // setQuesInputFields(inputQuesFields.map(inputQuestion =>{
    //     return{ questionType: e.target.value }
    // }))
    // setAssessmentType()
  }
  const [isNumeric, setNumeric] = useState(false);
  const [orderElements, setOrderElements] = useState(AssigningShowByOrder);
  const [AssessmentOrderElements, setAssessmentOrderElements] = useState(
    AssessmentShowByOrder
  );
  const muiClasses = muiUseStyles();

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
      className="mb-5"
    >
      {assesmentPage === "1" && (
        <>
          <h1 align="center" className="mt-4">
            Edit Assessment
          </h1>
          <center>
            <div
              className="ps-4 pe-4 pt-4 pb-2 col-10 col-lg-9"
              style={{ borderRadius: 10 }}
              align="center"
            >
              <h4
                style={{ display: "flex", justifyContent: "flex-start" }}
                className="ps-2 pe-2 mt-4 mb-3"
              >
                Assessment Name
              </h4>
              <TextField
                id="assesmentName"
                name="assesmentName"
                // label="Assesment Name"
                placeholder="Enter Assesment Name Here"
                // variant="filled"
                value={assesmentName}
                onChange={(e) => handleAssesmentNameChange(e)}
                style={{ width: "100%" }}
              />
            </div>
            <div
              className="ps-4 pe-4 pb-3 col-10 col-lg-9"
              style={{ borderRadius: 10 }}
              align="center"
            >
              <h4
                style={{ display: "flex", justifyContent: "flex-start" }}
                className="ps-2 pe-2 mt-4 mb-3"
              >
                Assign To
              </h4>
              <Grid container>
                <Grid item sm={12} md={12} lg={6} xs={12}>
                  {orderElements[0].selected && (
                    <TextField //1
                      id="userType"
                      name="userType"
                      select
                      sx={{ width: "100%" }}
                      // style={{backgroundColor:"yellow"}}
                      label="&nbsp;&nbsp;&nbsp;Select user type"
                      value={userType}
                      onChange={(e) => handleUserTypeChange(e)}
                      // variant="filled"
                    >
                      {userTypes.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{ width: "100%" }}
                        >
                          {option.value}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>

                {orderElements[1].selected &&
                  !(userDetails.userType === "SCHOOL_ADMIN") &&
                  !(userDetails.userType === "TEACHER") && (
                    <Grid item sm={12} lg={6} md={12} xs={12}>
                      <TextField //2
                        id="SelectSchool"
                        name="SelectSchool"
                        select
                        sx={{ width: "100%" }}
                        // placeholder="Select School"
                        label="&nbsp;&nbsp;&nbsp;Select School"
                        value={SelectSchool}
                        onChange={(e) => handleSelectSchoolChange(e)}
                        // variant="filled"
                      >
                        {schoolList.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            style={{ width: "100%" }}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                {/* </Grid> */}

                {/* {userType!=="SchoolAdmin"&&
                            <> */}

                {/* {(subjectList.length !== 0) &&
                                    <Dropdown >
                                    <Dropdown.Toggle>
                                            Select Subject
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu onSelect={(e)=>handleChange(e)}>
                                        {subjectList.map((obj)=>{
                                            return (
                                                <Dropdown.Item>
                                                {obj?.name}
                                            </Dropdown.Item>
                                            )
                                        })
                                        }
                                    </Dropdown.Menu>
                                    </Dropdown>
                     
                    } */}

                {userType !== "SchoolAdmin" && (
                  // <Grid container>
                  <Grid
                    className="mt-2"
                    item
                    sm={12}
                    md={12}
                    lg={6}
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <>
                      {/* <Container
                                                maxWidth="md"
                                                // sx={{maxWidth:"md"}}
                                                // id="assesmentClass"
                                                sx={{width:"50%"}}
                                                // name="assesmentClass"
                                                className="w-30 p-0 m-0"
                                                // select
                                                // label="Select Class"
                                                // // value={assesmentClass}
                                                // // onChange={e => handleAssesmentClassChange(e)}
                                                variant="filled"
                                                >  */}
                      {/* {classes.map((option) => (
                                                <MenuItem key={option.value} value={option.value} style={{ width: "100%" }}>
                                                {option.label}
                                                </MenuItem>
                                                ))} */}
                      {orderElements[2].selected && (
                        <MultiselectReactDropDown
                          //3
                          showArrow
                          name="multiSelectReact"
                          style={{
                            searchBox: {
                              border: "none",
                              maxWidth: "280px",
                              width: "250px",
                              borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
                              borderRadius: "0px",
                            },
                          }}
                          options={classList}
                          displayValue="class_name_section"
                          // className="mt-2"
                          placeholder="&nbsp;&nbsp;&nbsp;Select the Class"
                          groupBy="class_name"
                          showCheckbox={true}
                          selectedValues={filterUserClasses}
                          onSelect={(selectedList, selectedItem) => {
                            setFilterUserClasses(selectedList);
                          }}
                          onRemove={(selectedList, selectedItem) => {
                            setFilterUserClasses(selectedList);
                          }}
                        />
                      )}
                      {/* 
                                                {(classList.length !== 0) &&
                                                <MultiselectReactDropDown
                                                showArrow
                                                className="pt-2 pb-2 col-3"
                                                options={classList}
                                                displayValue="class_name_section"
                                                groupBy="class_name"
                                                showCheckbox={true}
                                                selectedValues={filterUserClasses}
                                                onSelect={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                                                onRemove={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                                                style={{
                                                marginTop: "0",
                                                }}
                                                />
                                                } */}
                      {/* </Container> */}
                    </>
                  </Grid>
                )}
              </Grid>

              <h4
                style={{ display: "flex", justifyContent: "flex-start" }}
                className="ps-2 pe-2 mt-4 mb-3"
              >
                Assessment Details
              </h4>

              <Grid container>
                <Grid item sm={12} lg={6} md={12} xs={12}>
                  {AssessmentOrderElements[0].selected && (
                    <TextField //4// in grid css to make down for multiselect===>className={"mt-2 d-flex flex-column justify-content-end align-items-center"}
                      id="assesmentSubject"
                      name="assesmentSubject"
                      select
                      label="&nbsp;&nbsp;&nbsp;Select Subject"
                      // placeholder="Select Subject"
                      value={assesmentSubject}
                      onChange={(e) => handleAssesmentSubjectChange(e)}
                      // variant="filled"
                    >
                      {subjects.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{ width: "100%" }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid item sm={12} md={12} lg={6} xs={12} gap={2}>
                  {AssessmentOrderElements[1].selected && (
                    <TextField //5
                      id="assesmentChapter"
                      name="assesmentChapter"
                      select
                      label="&nbsp;&nbsp;&nbsp;Select Chapter"
                      value={assesmentChapter}
                      onChange={(e) => handleAssesmentChapterChange(e)}
                      // variant="filled"
                    >
                      {chapters.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{ width: "100%" }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid item sm={12} lg={6} md={12} xs={12}>
                  {AssessmentOrderElements[2].selected && (
                    <TextField //6
                      id="assesmentTopic"
                      name="assesmentTopic"
                      select
                      label="&nbsp;&nbsp;&nbsp;Select Topic"
                      value={assesmentTopic}
                      className="mt-2"
                      onChange={(e) => handleAssesmentTopicChange(e)}
                      //variant="filled"
                    >
                      {topics.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{ width: "100%" }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
                <Grid item sm={12} lg={6} md={12} xs={12}>
                  {AssessmentOrderElements[3].selected && (
                    <TextField //7
                      id="assesmentSubTopic"
                      name="assesmentSubTopic"
                      select
                      className="mt-2"
                      label="&nbsp;&nbsp;&nbsp;Select Sub-Topic"
                      value={assesmentSubTopic}
                      onChange={(e) => handleAssesmentSubTopicChange(e)}
                      //variant="filled"
                    >
                      {subTopics.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{ width: "100%" }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>
              </Grid>
              <h4
                style={{ display: "flex", justifyContent: "flex-start" }}
                className="ps-2 pe-2 mt-4 mb-3"
              >
                Assessment Duration
              </h4>
              <Grid container>
                <Grid
                  item
                  sm={12}
                  md={4}
                  lg={6}
                  xs={12}
                  className={"ms-5 mb-3"}
                >
                  <Form.Check>
                    <Form.Check.Input
                      checked={NoTimeLimit}
                      onChange={() => handleNoTimeLimitChange()}
                    />
                    <Form.Check.Label className="float-start">
                      No Time Limit
                    </Form.Check.Label>
                  </Form.Check>
                </Grid>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container>
                  <Grid item sm={12} md={4} lg={6} xs={12}>
                    <DateTimePicker
                      label="Start Date & Time"
                      renderInput={(params) => <TextField {...params} />}
                      value={assesmentStartTime}
                      onChange={(e) => {
                        handleAssesmentStartTimeChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} lg={6} md={12} xs={12}>
                    {!NoTimeLimit && (
                      <DateTimePicker
                        label="End Date & Time"
                        renderInput={(params) => <TextField {...params} />}
                        value={assesmentEndTime}
                        onChange={(e) => {
                          handleAssesmentEndTimeChange(e);
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </div>
            <div
              className="ps-4 pe-4 pb-2 col-10 col-lg-9 d-flex flex-row-reverse me-5"
              style={{ borderRadius: 10 }}
            >
              <Button
                className="ms-1 me-1 mb-3"
                variant="secondary"
                size="lg"
                onClick={(e) => validateAssesmentPage1(e)}
              >
                NEXT
              </Button>
            </div>
          </center>
        </>
      )}
      {assesmentPage === "2" && (
        <>
          <center>
            <div
              className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mb-4"
              style={{ borderRadius: 10, backgroundColor: "#FFF" }}
              align="center"
            >
              <Stack className="row" direction="horizontal">
                <div className="col-12 col-sm-6" style={{ display: "flex" }}>
                  <Button
                    variant="light"
                    style={{ backgroundColor: "#FFF", color: "#6c757d" }}
                    // onhover={(e) => doNothing()}
                    onClick={(e) => {
                      setAssesmentPage("1");
                      // setSectionIds(new Set())
                    }}
                  >
                    ← Previous
                  </Button>
                </div>
                <div
                  className="col-12 col-sm-6"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="outline-secondary"
                    align="right"
                    style={{ minWidth: "150px" }}
                    onClick={() => validateUserSelection()}
                  >
                    Next
                  </Button>
                </div>
              </Stack>
              <h2 className="mt-4">List of Users</h2>
              <div>
                <Button
                  className="m-4"
                  variant="outline-secondary"
                  onClick={(e) =>
                    setUserList(
                      userList?.map((us) => ({ ...us, checked: false }))
                    )
                  }
                  style={{ minWidth: "150px" }}
                >
                  Un-Check All
                </Button>
                <Button
                  className="m-4"
                  variant="outline-secondary"
                  onClick={(e) =>
                    setUserList(
                      userList?.map((us) => ({ ...us, checked: true }))
                    )
                  }
                  style={{ minWidth: "150px" }}
                >
                  Check All
                </Button>
              </div>
              {userEmptyCheckError?.isError && (
                <Box
                  sx={{ color: "error.main" }}
                  noValidate
                  className="mb-1 mt-2 w-50"
                >
                  {userEmptyCheckError.statement}
                </Box>
              )}
              <Table striped bordered hover responsive className="mt-4 mb-4">
                <thead>
                  <tr>
                    <td>
                      <b>Sr. No.</b>
                    </td>
                    <td>
                      <b>Name</b>
                    </td>
                    <td>
                      <b>Username</b>
                    </td>
                    <td>
                      <b>Check/Uncheck</b>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {userList?.map((user, i) => {
                    // console.log(user);
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{user.name}</td>
                        <td>{user.username || user.userType}</td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            style={{ padding: 10 }}
                            checked={user.checked}
                            onChange={(e) => {
                              let temp = [...userList];
                              temp[i].checked = !temp[i].checked;
                              setUserList(temp);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </center>
        </>
      )}
      {assesmentPage === "3" && (
        <>
          <center className="mt-2 ps-4 pe-4 pb-2 col-12 col-lg-12">
            <div
              className="ps-4 pe-4 pb-2 mt-5 col-10 col-lg-9"
              style={{ borderRadius: 10 }}
              align="center"
            >
              <Stack className="row  mb-4 mt-4" direction="horizontal">
                <div
                  className="col-12 col-sm-6"
                  style={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Button
                    variant="light"
                    style={{ backgroundColor: "#FFF", color: "#6c757d" }}
                    // onhover={(e) => doNothing()}
                    onClick={(e) => {
                      setAssesmentPage("2");
                      // setSectionIds(new Set())
                    }}
                  >
                    ← Previous
                  </Button>
                </div>
                {/* <div className="col-12 col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }}
                                                 onClick={() =>validateUserSelection()}  >
													Next
												</Button>
											</div> */}
              </Stack>
              <Grid container>
                <Grid item sm={12} md={12} lg={6} xs={12}>
                  <Button
                    className="ms-1 me-1 mb-3"
                    variant="secondary"
                    size="lg"
                    onClick={(e) => handleNullAssesmentPageChange(e)}
                    // style={{width:"80%"}}
                  >
                    Create Null Assesment
                  </Button>
                </Grid>
                <Grid item sm={12} lg={6} md={12} xs={12}>
                  <Button
                    className="ms-1 me-1 mb-3"
                    variant="secondary"
                    size="lg"
                    onClick={(e) => handleRandomAssesmentPageChange(e)}
                    // style={{width:"80%"}}
                  >
                    Create Random Assesment
                  </Button>
                </Grid>

                <Grid item sm={12} lg={12} md={12} xs={12}>
                  <Button
                    className="ms-1 me-1 mb-3"
                    variant="secondary"
                    size="lg"
                    onClick={(e) => handleCustomAssesmentPageChange(e)}
                    // style={{width:"80%"}}
                  >
                    Create Custom Assesment
                  </Button>
                </Grid>
              </Grid>
              <div
                className="ps-4 pe-4 pb-2"
                style={{ borderRadius: 10 }}
                align="left"
              >
                <h4 style={{ color: "#6c757d" }}>Note - </h4>
                <ul style={{ color: "#6c757d" }}>
                  <li>
                    <h5>
                      Null Assesment - It will create a Null Assesment i.e., it
                      will not contain any question.{" "}
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Random Assesment - It will create a Assesment from the
                      Questions from the Question Bank.{" "}
                    </h5>
                  </li>
                  <li>
                    <h5>
                      Custom Assesment - It will create a Assesment in this you
                      have to enter questions manuallay and you will also be
                      having a option to import random questions from the
                      Question Bank.
                    </h5>
                  </li>
                </ul>
              </div>
            </div>
          </center>
        </>
      )}
      {assesmentPage === "4" && assesmentType === "RANDOM" && (
        <>
          <center>
            <div
              className="p-4 col-10 col-lg-9"
              style={{ borderRadius: 10 }}
              align="center"
            >
              <h2 className="mb-4">Choose Filter for Random Question</h2>
              <>
                {randomFilter.map((Filter) => (
                  <div>
                    <TextField
                      id="filterDifficultyLevel"
                      select
                      label="Select Difficulty Level"
                      value={Filter.difficultyLevel}
                      onChange={(e) =>
                        handleRandomFilterDifficultyLevelChange(Filter.id, e)
                      }
                      variant="filled"
                    >
                      {difficultyLevels.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={{ width: "100%" }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      id="filterNoOfQues"
                      label="No Of Questions"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                      value={Filter.noOfQues}
                      onChange={(e) =>
                        handleRandomFilterNoOfQuesChange(Filter.id, e)
                      }
                      variant="filled"
                    />
                    <IconButton
                      disabled={randomFilter.length === 1}
                      onClick={(e) => handleRandomFilterRemove(Filter.id, e)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </div>
                ))}
              </>
            </div>
            <Button
              variant="outline-secondary"
              onClick={(e) => handleRandomFilterAdd(e)}
            >
              <AddIcon />
              Add More Filter
            </Button>
            <div
              className="p-4 col-10 col-lg-9"
              style={{ borderRadius: 10 }}
              align="center"
            >
              <Button
                className="m-4"
                variant="secondary"
                size="lg"
                onClick={(e) => setAssesmentPage("1")}
              >
                Go to Previous Page
              </Button>
              <Button
                className="m-4"
                variant="secondary"
                size="lg"
                onClick={(e) => generateAssesment()}
              >
                Create Assesment
              </Button>
            </div>
          </center>
        </>
      )}
      {assesmentPage === "4" && assesmentType === "CUSTOM" && (
        <>
          <center id="custom-wrapper">
            <h2 className="m-4">Add Questions for Assesment</h2>
            {/* <Form.Select className="p-6 w-50" controlId="Assessment-type" as="select" onChange={(e)=>selectAssessmentType(e)} >
                            {
                            questionTypes?.map((obj)=> <option value={obj.value}>{obj.description}</option>)
                            }
                        </Form.Select> */}
            {Error?.isError && (
              <Box
                // component="form"
                sx={{ color: "error.main" }}
                noValidate
                className="mb-1 mt-2 w-50"
              >
                {Error.statement}
              </Box>
            )}
            {assessmentType?.length !== 0 &&
              inputQuesFields?.map((inputQuesField) => (
                <div key={inputQuesField.id} align="center">
                  <div
                    className="p-4 col-10 col-lg-9 mb-4 mt-4"
                    style={{ background: "#e6e6e6", borderRadius: 10 }}
                    align="left"
                  >
                    <Form.Group controlId="question-description">
                      <Stack direction="horizontal" gap={3}>
                        <>
                          {inputQuesField?.questionType ===
                            "DocumentUpload" && (
                            <div
                              className=""
                              align="left"
                              style={{ width: "65%", height: "50px" }}
                            >
                              {/* <Form.Group controlId="file-selection">                                                      //w-90
                                                                                //w-90
                                                        <div  className="mb-2" align="left" style={{ width: "60%" }}>
                                                        <Form.Label>Select files</Form.Label>
                                                        <Form.Control type="file" multiple onChange={(e)=>{
                                                            setQuesInputFields(inputQuesFields.map(inputQuestion =>{
                                                                return (inputQuestion.id === inputQuesField.id ? 
                                                                    { ...inputQuestion, files: e?.target?.files } : inputQuestion)}))
                                                            }}/>
                                                        </div>
                                                </Form.Group> */}

                              <Form.Control
                                inline
                                as="textarea"
                                rows={3}
                                placeholder="Enter Question"
                                // className=""
                                style={{ width: "100%", height: "50px" }}
                                name="description"
                                value={inputQuesField.description}
                                onChange={(e) =>
                                  setQuesInputFields(
                                    inputQuesFields.map((inputQuestion) =>
                                      inputQuestion.id === inputQuesField.id
                                        ? {
                                            ...inputQuestion,
                                            description: e.target.value,
                                          }
                                        : inputQuestion
                                    )
                                  )
                                }
                              />
                            </div>
                          )}
                        </>
                        {inputQuesField?.questionType !== "DocumentUpload" && (
                          <Form.Control
                            inline
                            as="textarea"
                            rows={3}
                            placeholder="Enter Question"
                            // className=""
                            style={{ width: "65%", height: "50px" }}
                            name="description"
                            value={inputQuesField.description}
                            onChange={(e) =>
                              setQuesInputFields(
                                inputQuesFields.map((inputQuestion) =>
                                  inputQuestion.id === inputQuesField.id
                                    ? {
                                        ...inputQuestion,
                                        description: e.target.value,
                                      }
                                    : inputQuestion
                                )
                              )
                            }
                          />
                        )}
                        {assessmentType[0].value == "MixedMcqSub" && (
                          // <Form.Control
                          //     inline
                          //     as="select"
                          //     style={{ width: '25%', height: "50px" }}
                          //     name="questionType"
                          //     // value={inputQuesField.questionType}
                          //     onChange={e =>{
                          //         console.log(e.target.value);
                          //           setQuesInputFields(inputQuesFields.map(inputQuestion =>{
                          //         // console.log("question type ==>", e.target.value)
                          //         return (inputQuestion.id === inputQuesField.id ?
                          //             { ...inputQuestion, questionType: e.target.value } : inputQuestion)}))
                          //     }

                          //         }
                          //     variant="filled"
                          // >
                          //     {
                          //         assessmentType?.map((obj)=>obj.options.map((optionObj)=>{
                          //             // console.log("optionOBJ",optionObj)
                          //         return <option  value={optionObj.value}>{optionObj.description}</option>}))
                          //     }
                          // </Form.Control>
                          <TextField
                            label="Question Type" //&nbsp;&nbsp;&nbsp;
                            variant="filled"
                            select
                            InputProps={{ className: muiClasses.QuestionType }}
                            name="questionType"
                            style={{
                              width: "25%",
                              height: "50px",
                              paddingRight: "10%",
                            }}
                            // sx={{width:"100%"}}
                            value={inputQuesField.questionType}
                            onChange={(e) => {
                              console.log(e.target.value);
                              setQuesInputFields(
                                inputQuesFields.map((inputQuestion) => {
                                  // console.log("question type ==>", e.target.value)
                                  return inputQuestion.id === inputQuesField.id
                                    ? {
                                        ...inputQuestion,
                                        questionType: e.target.value,
                                      }
                                    : inputQuestion;
                                })
                              );
                            }}
                          >
                            {assessmentType?.map((obj) =>
                              obj.options.map((optionObj) => {
                                console.log(
                                  "optionOBJ========>",
                                  assessmentType
                                );
                                return (
                                  <option
                                    className="my-3 mx-2"
                                    value={optionObj.value}
                                  >
                                    {optionObj.description}
                                  </option>
                                );
                              })
                            )}
                          </TextField>
                        )}
                      </Stack>

                      <>
                        {inputQuesField?.questionType === "MCQ" && (
                          <div className="mt-3 mb-2" align="left">
                            <Form.Group controlId="mcq-options">
                              {inputQuesField?.options.map((quesOption) => (
                                <div
                                  key={quesOption.id}
                                  className="mb-2"
                                  align="left"
                                  style={{ width: "60%" }}
                                >
                                  <InputGroup style={{ borderRadius: 10 }}>
                                    <InputGroup.Radio
                                      style={{ padding: 10 }}
                                      value={quesOption.selected}
                                      checked={
                                        inputQuesField.correctAnswer ===
                                        quesOption.id
                                      }
                                      onChange={(e) =>
                                        setQuesInputFields(
                                          inputQuesFields.map((inputQues) =>
                                            inputQues.id === inputQuesField.id
                                              ? {
                                                  ...inputQues,
                                                  correctAnswer: quesOption.id,
                                                }
                                              : inputQues
                                          )
                                        )
                                      }
                                    />
                                    <Form.Control
                                      placeholder="Enter Option"
                                      aria-label="Text input with radio button"
                                      // value={quesOption.description}
                                      style={{
                                        backgroundColor:
                                          inputQuesField.correctAnswer ===
                                          quesOption.id
                                            ? "#34d1a3"
                                            : "",
                                      }}
                                      // className="success"
                                      onChange={(e) => {
                                        setQuesInputFields(
                                          inputQuesFields.map((inputQues) =>
                                            inputQues.id === inputQuesField.id
                                              ? {
                                                  ...inputQues,
                                                  options:
                                                    inputQues.options.map(
                                                      (quesOptionVar) =>
                                                        quesOptionVar.id ===
                                                        quesOption.id
                                                          ? {
                                                              ...quesOptionVar,
                                                              option:
                                                                e.target.value,
                                                            }
                                                          : quesOptionVar
                                                    ),
                                                }
                                              : inputQues
                                          )
                                        );
                                        // console.log("tot options==>",inputQuesField?.options,"current ques==>",quesOption,"<=====e.target.value======>",e.target.value)
                                      }}
                                    />
                                    <IconButton
                                      disabled={
                                        inputQuesField.options.length === 1
                                      }
                                      onClick={(e) =>
                                        setQuesInputFields(
                                          inputQuesFields.map((inputQues) =>
                                            inputQues.id === inputQuesField.id
                                              ? {
                                                  ...inputQues,
                                                  options:
                                                    inputQues.options.filter(
                                                      (quesOptionVar) =>
                                                        quesOptionVar.id !==
                                                        quesOption.id
                                                    ),
                                                }
                                              : inputQues
                                          )
                                        )
                                      }
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </InputGroup>
                                </div>
                              ))}
                              <Button
                                variant="outline-secondary"
                                onClick={(e) =>
                                  setQuesInputFields(
                                    inputQuesFields.map((inputQuestion) =>
                                      inputQuestion.id === inputQuesField.id
                                        ? {
                                            ...inputQuestion,
                                            options: [
                                              ...inputQuestion.options,
                                              {
                                                id: uuidv4(),
                                                option: "",
                                                description: "",
                                                selected: false,
                                              },
                                            ],
                                          }
                                        : inputQuestion
                                    )
                                  )
                                }
                              >
                                <AddIcon />
                                Add Option
                              </Button>
                            </Form.Group>
                          </div>
                        )}
                      </>
                      <>
                        {inputQuesField?.questionType === "MSQ" && (
                          <div className="mt-3 mb-2" align="left">
                            <Form.Group controlId="mcq-options">
                              {inputQuesField?.options.map((quesOption) => (
                                <div
                                  key={quesOption.id}
                                  className="mb-2"
                                  align="left"
                                  style={{ width: "60%" }}
                                >
                                  <InputGroup style={{ borderRadius: 10 }}>
                                    <InputGroup.Radio
                                      style={{ padding: 10 }}
                                      // value={quesOption.selected}
                                      checked={inputQuesField.correctAnswer.includes(
                                        quesOption.id
                                      )}
                                      onClick={(e) => {
                                        let optionLength =
                                          inputQuesField?.options.length;
                                        let correctAnswerLength =
                                          inputQuesField.correctAnswer.length;
                                        console.log(
                                          "optionLength",
                                          optionLength
                                        );
                                        console.log(
                                          "correctAnswerLength",
                                          correctAnswerLength
                                        );
                                        if (
                                          optionLength - 1 ===
                                          correctAnswerLength
                                        ) {
                                          alert(
                                            "all options should not be selected"
                                          );
                                        } else {
                                          setQuesInputFields(
                                            inputQuesFields.map((inputQues) => {
                                              if (
                                                inputQues.id ===
                                                inputQuesField.id
                                              ) {
                                                let array;
                                                if (
                                                  Array.isArray(
                                                    inputQues?.correctAnswer
                                                  )
                                                ) {
                                                  array = [
                                                    ...inputQues.correctAnswer,
                                                  ];
                                                  if (
                                                    array.includes(
                                                      quesOption.id
                                                    )
                                                  ) {
                                                    // console.log("before",array)
                                                    // console.log("checked 2=ing test ==>")
                                                    // console.log(quesOption.id)
                                                    array = array.filter(
                                                      (id) =>
                                                        id !== quesOption.id
                                                    );
                                                    // console.log("after",array)
                                                  } else {
                                                    // console.log("checked 2=ing test pusing n ==>")
                                                    array.push(quesOption.id);
                                                  }
                                                } else {
                                                  array = [quesOption.id];
                                                }
                                                inputQues.correctAnswer = array;
                                                // console.log("updated ==>",inputQues)
                                                // console.log(inputQuesField.correctAnswer);
                                                // console.log(quesOption.id);
                                                // console.log(e.target.checked);
                                                // console.log(inputQuesField.correctAnswer.includes(quesOption.id));
                                                return inputQues;
                                              } else {
                                                return inputQues;
                                              }
                                            })
                                          );
                                        }
                                      }}
                                      // onClick={()=>console.log("clicked")}
                                    />
                                    <Form.Control
                                      placeholder="Enter Option"
                                      aria-label="Text input with radio button"
                                      // value={quesOption.description}
                                      style={{
                                        backgroundColor:
                                          inputQuesField.correctAnswer ===
                                          quesOption.id
                                            ? "#34d1a3"
                                            : "",
                                      }}
                                      // className="success"
                                      onChange={(e) => {
                                        setQuesInputFields(
                                          inputQuesFields.map((inputQues) =>
                                            inputQues.id === inputQuesField.id
                                              ? {
                                                  ...inputQues,
                                                  options:
                                                    inputQues.options.map(
                                                      (quesOptionVar) =>
                                                        quesOptionVar.id ===
                                                        quesOption.id
                                                          ? {
                                                              ...quesOptionVar,
                                                              option:
                                                                e.target.value,
                                                            }
                                                          : quesOptionVar
                                                    ),
                                                }
                                              : inputQues
                                          )
                                        );
                                        // console.log("tot options==>",inputQuesField?.options,"current ques==>",quesOption,"<=====e.target.value======>",e.target.value)
                                      }}
                                    />
                                    <IconButton
                                      disabled={
                                        inputQuesField.options.length === 1
                                      }
                                      onClick={(e) =>
                                        setQuesInputFields(
                                          inputQuesFields.map((inputQues) =>
                                            inputQues.id === inputQuesField.id
                                              ? {
                                                  ...inputQues,
                                                  options:
                                                    inputQues.options.filter(
                                                      (quesOptionVar) =>
                                                        quesOptionVar.id !==
                                                        quesOption.id
                                                    ),
                                                }
                                              : inputQues
                                          )
                                        )
                                      }
                                    >
                                      <DeleteForeverIcon />
                                    </IconButton>
                                  </InputGroup>
                                </div>
                              ))}
                              <Button
                                variant="outline-secondary"
                                onClick={(e) =>
                                  setQuesInputFields(
                                    inputQuesFields.map((inputQuestion) =>
                                      inputQuestion.id === inputQuesField.id
                                        ? {
                                            ...inputQuestion,
                                            options: [
                                              ...inputQuestion.options,
                                              {
                                                id: uuidv4(),
                                                option: "",
                                                description: "",
                                                selected: false,
                                              },
                                            ],
                                          }
                                        : inputQuestion
                                    )
                                  )
                                }
                              >
                                <AddIcon />
                                Add Option
                              </Button>
                            </Form.Group>
                          </div>
                        )}
                      </>
                      {inputQuesField?.questionType === "Subjective" && (
                        <h5
                          className="mt-3"
                          align="left"
                          muted
                          //    style={{backgroundColor:"red"}}
                        >
                          Answer Response Type
                        </h5>
                      )}

                      <>
                        {inputQuesField?.questionType === "Subjective" && (
                          <div className="ms-4" align="center">
                            <div className="mt-2 mb-2" align="left">
                              <Form.Group controlId="mcq-options">
                                {/* {inputQuesField?.options.map(quesOption => ( */}
                                <div
                                  className="mb-2"
                                  align="left"
                                  style={{ width: "100%" }}
                                >
                                  {/* <InputGroup  className="ms-3" style={{ borderRadius: 10 }}> */}
                                  {/* <Form.Check style={{ padding: 10 }} 
                                                                // value={quesOption.selected}
                                                                // className="ms-4"
                                                                type={"checkbox"}
                                                                label={"Numeric Answers"}
                                                                    checked={isNumeric}
                                                                    onChange={e =>setNumeric(e.target.checked)}
                                                                />
                                                              {!isNumeric&&
                                                               <TextField
                                                               label="Enter Answer Words Limit"
                                                               variant="filled"
                                                               type="number"
                                                               InputProps={{inputProps:{
                                                               min:10
                                                               }}}
                                                               // value={inputQuesField.correctMarks}
                                                               // onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, correctMarks: e.target.value } : inputQuestion)))}
                                                               // style={{ marginLeft: 10 }}
                                                           />
                                               } */}

                                  <Grid container>
                                    <Grid
                                      item
                                      sm={12}
                                      lg={12}
                                      md={12}
                                      xs={12}
                                      className="d-flex align-items-center"
                                      // style={{backgroundColor:"red"}}
                                    >
                                      <Form.Check
                                        style={{ padding: 10 }}
                                        //   value={inputQuesField.isAnswerNumeric}
                                        // className="ms-4"
                                        id={inputQuesField.id}
                                        type={"checkbox"}
                                        checked={
                                          inputQuesField?.isAnswerNumeric
                                        }
                                        onChange={(e) => {
                                          // if(e?.target?.checked){
                                          // console.log("ques filed",inputQuesField)
                                          setQuesInputFields((pre) => {
                                            let updated = [...pre];
                                            updated.map((obj) => {
                                              if (
                                                obj.id === inputQuesField.id
                                              ) {
                                                obj.isAnswerNumeric =
                                                  e?.target?.checked;
                                              }
                                              return obj;
                                            });
                                            // console.log("updated filed",updated)
                                            return updated;
                                          });
                                          // }
                                          // else{
                                          //     setQuesInputFields((pre)=>{
                                          //         let updated=[...pre]
                                          //         updated.map((obj)=>{
                                          //             if(obj.id===inputQuesField.id){
                                          //                 updated.isAnswerNumeric=e?.target?.checked
                                          //             }
                                          //         })
                                          //         return updated
                                          //     })
                                          // }
                                          // setNumeric(e.target.checked)
                                        }}
                                      />
                                      <label htmlFor={inputQuesField?.id}>
                                        Numeric Answers Only
                                      </label>
                                    </Grid>
                                    <Grid
                                      item
                                      sm={12}
                                      lg={12}
                                      md={12}
                                      xs={12}
                                      className="d-flex align-items-center"
                                    >
                                      <Form.Check
                                        style={{ padding: 10 }}
                                        //   value={!inputQuesField.isAnswerNumeric}
                                        // className="ms-4"
                                        id={inputQuesField.id + "words"}
                                        type={"checkbox"}
                                        checked={
                                          !inputQuesField?.isAnswerNumeric
                                        }
                                        onChange={(e) => {
                                          console.log(
                                            " checked ques filed",
                                            e?.target?.checked
                                          );
                                          // if(!e?.target?.checked){
                                          //     setQuesInputFields((pre)=>{
                                          //         let updated=[...pre]
                                          //         updated.map((obj)=>{
                                          //             if(obj.id===inputQuesField.id){
                                          //                 updated.isAnswerNumeric=(e?.target?.checked)
                                          //             }
                                          //         })
                                          //         return updated
                                          //     })
                                          // }else{

                                          setQuesInputFields((pre) => {
                                            let updated = [...pre];
                                            updated.map((obj) => {
                                              if (
                                                obj.id === inputQuesField.id
                                              ) {
                                                obj.isAnswerNumeric =
                                                  !e?.target?.checked;
                                              }
                                            });
                                            return updated;
                                          });

                                          // }

                                          // setNumeric(e.target.checked)
                                        }}
                                      />
                                      <label
                                        htmlFor={inputQuesField?.id + "words"}
                                      >
                                        Specify Words Count
                                      </label>
                                    </Grid>
                                    <Grid
                                      className="d-flex justify-content-center align-items-center"
                                      item
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      xs={12}
                                    >
                                      {!inputQuesField?.isAnswerNumeric && (
                                        <>
                                          {/* <label htmlFor={inputQuesField?.id}>Specify Words Limit</label>//testb2
    
    */}
                                          <TextField
                                            label="Enter Answer Words Limit"
                                            variant="filled"
                                            type="number"
                                            // style={{backgroundColor:"red"}}
                                            InputProps={{
                                              inputProps: {
                                                min: 10,
                                              },
                                            }}
                                            // value={inputQuesField.correctMarks}
                                            // onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, correctMarks: e.target.value } : inputQuestion)))}
                                            // style={{ marginLeft: 10 }}
                                          />
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>

                                  {/* </InputGroup> */}

                                  {
                                    !isNumeric && <></>

                                    // <>
                                    //    {/* <h5 className="mt-3" muted>
                                    //    Words Limit
                                    //  </h5> */}
                                    // <InputGroup style={{ borderRadius: 10 }}>

                                    //       {/* {!isNumeric&& */}
                                    //       <>

                                    //       {/* <Form.Control placeholder="Enter Answer Words Limit" aria-label="Text input with checkbox button"
                                    //         // disabled={isNumeric}
                                    //         type="number"
                                    //        min={10}
                                    //         // value={quesOption.description}
                                    //         // style={{backgroundColor:inputQuesField.correctAnswer === quesOption.id?"#34d1a3":""}}
                                    //         // className="success"
                                    //         // onChange={e => }
                                    //     /> */}
                                    //         <TextField
                                    //         label="Enter Answer Words Limit"
                                    //         variant="filled"
                                    //         type="number"
                                    //         InputProps={{inputProps:{
                                    //         min:10
                                    //         }}}
                                    //         // value={inputQuesField.correctMarks}
                                    //         // onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, correctMarks: e.target.value } : inputQuestion)))}
                                    //         // style={{ marginLeft: 10 }}
                                    //     />

                                    //     </>
                                    // </InputGroup>
                                    // </>
                                  }
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        )}
                      </>

                      <div className="m-4" align="center">
                        <TextField
                          select
                          label="Select Question Difficulty"
                          variant="filled"
                          value={inputQuesField.difficulty}
                          onChange={(e) =>
                            setQuesInputFields(
                              inputQuesFields.map((inputQuestion) =>
                                inputQuestion.id === inputQuesField.id
                                  ? {
                                      ...inputQuestion,
                                      difficulty: e.target.value,
                                    }
                                  : inputQuestion
                              )
                            )
                          }
                        >
                          {difficultyLevelsQues.map((difficultyLevel) => (
                            <MenuItem
                              key={difficultyLevel.value}
                              value={difficultyLevel.value}
                              style={{ width: "100%" }}
                            >
                              {difficultyLevel.label}
                            </MenuItem>
                          ))}
                        </TextField>
                        <TextField
                          label="Correct Marks"
                          variant="filled"
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                          value={inputQuesField.correctMarks}
                          onChange={(e) =>
                            setQuesInputFields(
                              inputQuesFields.map((inputQuestion) =>
                                inputQuestion.id === inputQuesField.id
                                  ? {
                                      ...inputQuestion,
                                      correctMarks: e.target.value,
                                    }
                                  : inputQuestion
                              )
                            )
                          }
                          style={{ marginLeft: 10 }}
                        />
                        <TextField
                          label="InCorrect Marks"
                          variant="filled"
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                          value={inputQuesField.incorrectMarks}
                          onChange={(e) =>
                            setQuesInputFields(
                              inputQuesFields.map((inputQuestion) =>
                                inputQuestion.id === inputQuesField.id
                                  ? {
                                      ...inputQuestion,
                                      incorrectMarks: e.target.value,
                                    }
                                  : inputQuestion
                              )
                            )
                          }
                          style={{ marginLeft: 10 }}
                        />
                      </div>
                      <div className="mt-4" align="right">
                        <IconButton
                          disabled={inputQuesFields.length === 1}
                          onClick={(e) =>
                            setQuesInputFields(
                              inputQuesFields.filter(
                                (inputQuestion) =>
                                  inputQuestion.id !== inputQuesField.id
                              )
                            )
                          }
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </div>
                    </Form.Group>
                  </div>
                </div>
              ))}
            <div className="mt-4" align="center">
              <Button
                variant="outline-secondary"
                onClick={(e) =>
                  setQuesInputFields([
                    ...inputQuesFields,
                    AddtionalQuesFormat(
                      uuidv4(),
                      uuidv4(),
                      uuidv4(),
                      assessmentType
                    ),
                  ])
                }
              >
                <AddIcon />
                Add Question
              </Button>
            </div>
            <div
              className="p-4 col-10 col-lg-9"
              style={{ borderRadius: 10 }}
              align="center"
            >
              <Button
                className="m-4"
                variant="secondary"
                size="lg"
                onClick={(e) => setAssesmentPage("3")}
              >
                Go to Previous Page
              </Button>
              <Button
                className="m-4"
                variant="secondary"
                size="lg"
                onClick={() => generateAssesment()}
              >
                Create Assesment
              </Button>
            </div>
          </center>
        </>
      )}
      {assesmentPage === "5" && (
        <>
          <h1 align="center" className="mt-4">
            Assesment Created Successfully
          </h1>
          <h5 className="mt-4 ms-4">
            <b>Assesment Details - </b>
          </h5>
          <p className="mt-2 ms-5">
            <b>Assesment ID:</b> {} <br></br>
            <b>Assesment Name:</b> {} <br></br>
            <b>No of Questions:</b> {} <br></br>
            <b>Start Time:</b> {} <br></br>
            <b>End Time:</b> {} <br></br>
            <b>Assesment Link:</b> {} <br></br>
          </p>
          <center>
            <div
              className="ps-4 pe-4 pb-3 col-10 col-lg-9"
              style={{ borderRadius: 10 }}
              align="center"
            >
              <Button
                className="m-4"
                variant="secondary"
                size="lg"
                // onClick={(e) => setAllVariablesToDefault()}
              >
                Create Another Assessment
              </Button>
              <Button
                className="m-4"
                variant="secondary"
                size="lg"
                onClick={(e) => goToDashboard()}
              >
                Go to Dashboard
              </Button>
            </div>
          </center>
        </>
      )}
    </Box>
  );
};

export default EditAssessment;
