import React, { useEffect, useState } from "react";
import "./login.css";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { Alert, Button, TextField } from "@mui/material";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import Api from "../../Api/axios";
import VerifyOtp from "./VerifyOtp";
import { Timer } from "@material-ui/icons";
import Countdown from "react-countdown";

const Completionist = () => <Alert severity="error">Oops Time Out</Alert>;
const renderer = ({ minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  }
};

const ForgetPassword = () => {
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [success, setSuccess] = useState(true);
  const [Email, setEmail] = useState();
  const [emaildata, setEmailData] = useState();
  const [emailerror, setEmailError] = useState(false);
  const [otp, setOtp] = useState();
  const [verify, setVerify] = useState(false);
  const [data, setData] = useState();
  const [token, setToken] = useState("");
  const [password, setPassword] = useState();
  const [verifyerror, setVerifyError] = useState(false);

  const navigate = useNavigate();

  const otpsent = async (e) => {
    e.preventDefault();
    // console.log(Email);
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.post("forgotPassword/generateOtp", {
      email: Email,
    })
      .then((res) => {
        setSuccess(false);
        setEmailData(res.data.message);
      })
      .catch((err) => {
        console.log(err);
        setEmailError(true);
      });
    console.log("emailData", emaildata);
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const verification = async (e) => {
    e.preventDefault();
    // console.log("Email from verification", Email);
    // console.log("Otp from verification", otp);
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.post("forgotPassword/verifyOtp", {
      email: Email,
      otp: otp,
    })
      .then((res) => {
        setToken(res.data.resetToken);
        setVerify(true);
      })
      .catch((err) => {
        console.log(err);
        setVerifyError(true);
      });
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
    console.log(token);
  };

  const confirmPassword = (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    Api.post("forgotPassword/resetPassword", {
      email: Email,
      password: password,
      resetToken: token,
    })
      .then((res) => {
        console.log(res.data);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  return (
    <>
      {success && (
        <div className="container-forget-password">
          <div className="forget-password">
            <div className="forgetDetails">
              <h1 className="h1">Forgot Your Password</h1>
              <h4 className="h">
                Please enter the email address associated with your account and
                We will email you a link to reset your password.
              </h4>
            </div>
            <form
              onSubmit={(e) => {
                otpsent(e);
              }}
              className="forget-form"
            >
              <TextField
                style={{ margin: "0.5rem" }}
                label="Email address"
                variant="outlined"
                type="mail"
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* <NavLink to="verifyotp">verifyotp</NavLink> */}

              {/* <NavLink to="verifyotp" style={{ textDecoration: "none" }}> */}
              {emailerror && (
                <Alert style={{ margin: "0.5rem" }} severity="error">
                  Oops Something wrong
                </Alert>
              )}
              <Button
                type="submit"
                style={{ margin: "0.5rem", backgroundColor: "#00AB55" }}
                variant="contained"
              >
                Send the request
              </Button>
              {/* </NavLink> */}
              {/* {success && (
                <Navigate to="verifyotp">
                  <VerifyOtp email={Email} />
                </Navigate>
              )} */}
              <NavLink to="login" style={{ textDecoration: "none" }}>
                <Button
                  style={{
                    margin: "0.5rem",
                    fontWeight: "bolder",
                    color: "#00AB55",
                  }}
                  color="success"
                >
                  Back
                </Button>
              </NavLink>
            </form>
          </div>
        </div>
      )}
      {!success && (
        <div className="verify-otp">
          <div className="verify-container">
            <div className="verify-text">
              <h1>Please Check your email!</h1>
              <p className="para">
                We have mailed a 6-digit confirmation code to abc@domain, please
                enter the code in below box to verify your Email
              </p>
              <div className="timer-countdown">
                <Timer />
                <Countdown
                  style={{ margin: "1rem" }}
                  date={Date.now() + 300000}
                  renderer={renderer}
                />
              </div>
            </div>
            <form
              onSubmit={(e) => {
                verification(e);
              }}
              className="verify-form"
            >
              <TextField
                style={{ margin: "1rem" }}
                type="text"
                label="Enter a code"
                variant="outlined"
                onChange={(e) => setOtp(e.target.value)}
              />
              <Button
                type="submit"
                style={{
                  backgroundColor: "#00AB55",
                  color: "white",
                  margin: "1rem",
                }}
                variant="contained"
                color="success"
              >
                verify
              </Button>
              {verifyerror && (
                <Alert severity="error">Oops Something Wrong</Alert>
              )}
            </form>

            {verify && (
              <form onSubmit={(e) => confirmPassword(e)} className="password">
                <TextField
                  type="password"
                  style={{ margin: "1rem" }}
                  fullWidth
                  variant="outlined"
                  label="Enter your new Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="success"
                  style={{
                    margin: "1rem",
                    backgroundColor: "#00AB55",
                    color: "white",
                  }}
                >
                  Confirm
                </Button>
              </form>
            )}
            <div className="resend-code">
              Don't receive a code ?
              <Button
                style={{ color: "#00AB55" }}
                type="submit"
                onSubmit={(e) => otpsent(e)}
              >
                Resend a code
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgetPassword;
