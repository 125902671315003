import React, { useState, useEffect } from "react";
import { Form, Button, Stack, Table } from "react-bootstrap";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";
import Pie from "../../ReportsAndAnalytics/Pie";
const DistrictLevelLMS = () => {
    const [dateRange, setDateRange] = useState([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
    const [dateRangeType, setDateRangeType] = useState("TODAY");
    const [dateRangeTypes, setDateRangeTypes] = useState([
        {
            value: "TODAY",
            text: "Today",
        },
        {
            value: "YESTERDAY",
            text: "Yesterday",
        },
        {
            value: "LAST_7_DAYS",
            text: "Last 7 Days",
        },
        {
            value: "LAST_30_DAYS",
            text: "Last 30 Days",
        },
        {
            value: "CUSTOM",
            text: "Custom",
        },
        {
            value: "ALL_TIME",
            text: "All Time",
        }
    ]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [schoolList, setSchoolList] = useState([]);
    const [userTypes, setUserTypes] = useState([
        {
            value: "STUDENT",
            text: "Student",
        },
        {
            value: "TEACHER",
            text: "Teacher",
        },
        {
            value: "SCHOOL_ADMIN",
            text: "School Admin",
        }
    ]);
    const [userType, setUserType] = useState("STUDENT");
    // const [userList, setUserList] = useState([]);
    const[coursesList,setCoursesList]=useState([])
    const[overAllProgress,setOverALLProgress]=useState({})
    useEffect(() => {
        Api.get("/school-entity/school").then((res) => {
            setSchoolList(res.data);
        });
    }, [userDetails]);


    useEffect(() => {
    const data = {};
    if (dateRangeType !== "ALL_TIME") {
        data.start_time = dateRange[0].getTime();
        data.end_time = dateRange[1].getTime();
    }
        Api.get("/course/all-progress", {
            params: data,
        }).then((res) => {
            let fetchedData=res.data
            console.log("fetchedData",fetchedData);
            setCoursesList(()=>fetchedData)
            let schoolWiseProgress={}
            fetchedData.map((course)=>{
                if(course.userType!=="DISTRICT"){
                    if(!schoolWiseProgress[course.userType]){
                        schoolWiseProgress[course.userType]={}
                    }
                    if(!schoolWiseProgress[course.userType][course.schoolId]){
                        schoolWiseProgress[course.userType][course.schoolId]={}
                    }
                    if(!schoolWiseProgress[course.userType][course.schoolId][course.general_id]){
                        schoolWiseProgress[course.userType][course.schoolId][course.general_id]={
                            progress:parseInt((course.progress)*100),
                            count:1
                        }
                        schoolWiseProgress[course.userType][course.schoolId][course.general_id].average=parseInt(schoolWiseProgress[course.userType][course.schoolId][course.general_id].progress/schoolWiseProgress[course.userType][course.schoolId][course.general_id].count)
                    }else{
                        schoolWiseProgress[course.userType][course.schoolId][course.general_id].progress+=parseInt((course.progress)*100)
                        schoolWiseProgress[course.userType][course.schoolId][course.general_id].count+=1
                        schoolWiseProgress[course.userType][course.schoolId][course.general_id].average=parseInt(schoolWiseProgress[course.userType][course.schoolId][course.general_id].progress/schoolWiseProgress[course.userType][course.schoolId][course.general_id].count)
                    }
    
                }
            })
            let overAllProgressUpdated={}
            Object.keys(schoolWiseProgress).map((user)=>{
                Object.keys(schoolWiseProgress[user]).map((schoolId)=>{
                    Object.keys( schoolWiseProgress[user][schoolId]).map((courseId)=>{
                        if(!overAllProgressUpdated[user]){
                            overAllProgressUpdated[user]={}
                        }
                        if(!overAllProgressUpdated[user][schoolId]){
                            overAllProgressUpdated[user][schoolId]={}
                            overAllProgressUpdated[user][schoolId].TotalCourses=1
                            overAllProgressUpdated[user][schoolId].Progress=schoolWiseProgress[user][schoolId][courseId].average
                            overAllProgressUpdated[user][schoolId].AverageProgress=parseInt(overAllProgressUpdated[user][schoolId].Progress/overAllProgressUpdated[user][schoolId].TotalCourses)
                        }else{
                            overAllProgressUpdated[user][schoolId].TotalCourses+=1
                            overAllProgressUpdated[user][schoolId].Progress+=schoolWiseProgress[user][schoolId][courseId].average
                            overAllProgressUpdated[user][schoolId].AverageProgress=parseInt(overAllProgressUpdated[user][schoolId].Progress/overAllProgressUpdated[user][schoolId].TotalCourses)
                        }
                    })
                    if(!overAllProgressUpdated[user]?.Total){
                        overAllProgressUpdated[user].Total= overAllProgressUpdated[user][schoolId].TotalCourses
                        overAllProgressUpdated[user].Progress= overAllProgressUpdated[user][schoolId].Progress
                        overAllProgressUpdated[user].AverageProgress=parseInt(overAllProgressUpdated[user].Progress/overAllProgressUpdated[user].Total)
                    }else{
                        overAllProgressUpdated[user].Total+= overAllProgressUpdated[user][schoolId].TotalCourses
                        overAllProgressUpdated[user].Progress+= overAllProgressUpdated[user][schoolId].Progress
                        overAllProgressUpdated[user].AverageProgress=parseInt(overAllProgressUpdated[user].Progress/overAllProgressUpdated[user].Total)
                    }
                })
            })
    
            // console.log("schoolWiseProgress",schoolWiseProgress);
            // console.log("overAllProgressUpdated",overAllProgressUpdated);
            setOverALLProgress(()=>overAllProgressUpdated)
        })
      
       

    }, [dateRange,userType,dateRangeType]);
    return (
        <div>
            <h1 className="pt-3" align="center" style={{ fontWeight: "bold" }}>Learning Management Report</h1>
            <Form className="row">
                <Form.Group className="col-12" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Date Range</Form.Label>
                    <Form.Control as="select" value={dateRangeType} onChange={(e) => {
                        if (e.target.value === "TODAY") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "YESTERDAY") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - 1)]);
                        } else if (e.target.value === "LAST_7_DAYS") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (6 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "LAST_30_DAYS") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) - (29 * 24 * 60 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        } else if (e.target.value === "CUSTOM") {
                            setDateRange([new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), new Date(new Date(new Date(new Date().getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]);
                        }
                        console.log("e.target.value",e.target.value)
                        setDateRangeType(e.target.value)
                    }}>
                        {dateRangeTypes.map((item) => {
                            return (
                                <option value={item.value}>{item.text}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group className="col-6" style={{
                    paddingLeft: "30px",
                    paddingRight: "10px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[0].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                        setDateRangeType("CUSTOM")
                        let temp_time_array = [new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]]
                        if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                            setDateRange([new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)), dateRange[1]])
                        } else {
                            alert("Start Date cannot be greater than End Date.")
                        }
                    }} />
                </Form.Group>
                <Form.Group className="col-6" style={{
                    paddingLeft: "10px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control disabled={dateRangeType !== "CUSTOM"} type="date" value={new Date(dateRange[1].getTime() + (5 * 60 * 60 * 1000 + 30 * 60 * 1000)).toISOString().split("T")[0]} onChange={(e) => {
                        setDateRangeType("CUSTOM")
                        let temp_time_array = [dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))]
                        if (temp_time_array[0].getTime() < temp_time_array[1].getTime()) {
                            setDateRange([dateRange[0], new Date(new Date(new Date(e.target.value).toISOString().split("T")[0]).getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000) + (24 * 60 * 60 * 1000 - 1))])
                        } else {
                            alert("End Date cannot be less than Start Date.")
                        }
                    }} />
                </Form.Group>
            </Form>
            <Stack className="row" gap={3} direction="horizontal" style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div style={{
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    width: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}>
                    <Pie percentage={(overAllProgress["STUDENT"] ? (overAllProgress["STUDENT"]["AverageProgress"] ? overAllProgress["STUDENT"]["AverageProgress"] : 0) : 0)  } colour="red" />
                    <p align="center" style={{
                        "fontWeight": "bold",
                        "fontSize": "x-large",
                    }}>
                        Student LMS Progress
                    </p>
                </div>
                <div style={{
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    width: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}>
                    <Pie percentage={(overAllProgress["TEACHER"] ? (overAllProgress["TEACHER"]["AverageProgress"] ? overAllProgress["TEACHER"]["AverageProgress"] : 0) : 0)  } colour="blue" />
                    <p align="center" style={{
                        "fontWeight": "bold",
                        "fontSize": "x-large",
                    }}>
                        Teacher LMS Progress
                    </p>
                </div>
                <div style={{
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    width: "350px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}>
                    <Pie percentage={(overAllProgress["SCHOOL_ADMIN"] ? (overAllProgress["SCHOOL_ADMIN"]["AverageProgress"] ? overAllProgress["SCHOOL_ADMIN"]["AverageProgress"] : 0) : 0)  } colour="yellow" />
                    <p align="center" style={{
                        "fontWeight": "bold",
                        "fontSize": "x-large",
                    }}>
                        School Admin LMS Progress
                    </p>
                </div>
            </Stack>
            <Form className="row">
                <Form.Group className="col-12" style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                }}>
                    <Form.Label>User Type</Form.Label>
                    <Form.Control as="select" value={userType} onChange={(e) => {
                        setUserType(e.target.value)
                    }}>
                        {userTypes.map((item) => {
                            return (
                                <option value={item.value}>{item.text}</option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
            <div style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
            }}>
                {
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Sr. No.</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>School Name</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Conventional ID</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Total Courses</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Average Progress</th>
                                <th className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Detail View</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schoolList.map((item, index) => {
                                return (
                                    <tr>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{item.name}</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{item.conventional_id}</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{overAllProgress[userType] ? (overAllProgress[userType][item._id] ? overAllProgress[userType][item._id]["TotalCourses"] : 0) : 0}</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{overAllProgress[userType] ? (overAllProgress[userType][item._id] ? (overAllProgress[userType][item._id]["AverageProgress"]).toFixed(2) : "0") : "0"} %</td>
                                        <td className="p-2" style={{ background: "white", borderTop: "none", borderLeft: "1px solid rgb(0 0 0 / 26%)", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}><a style={{
                                            textDecoration: "none",
                                        }} href={`/view-school-lms-progress/${item._id}`}>View</a></td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                }
            </div>
        </div>
    )
}

export default DistrictLevelLMS;