import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Multiselect } from "multiselect-react-dropdown";
import { Button, Stack, Table } from 'react-bootstrap';
import Api from '../../Api/axios';
import { TextField, MenuItem } from '@material-ui/core';
import Modal from 'react-bootstrap/Modal';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
// import HourglassTopIcon from '@material-ui/icons/HourglassTop';
// import NotStartedIcon from '@material-ui/icons/NotStarted';
// import FaHourglassStart from "react-icons/fa";
// import MdNotStarted from "react-icons/md";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
// import NotStartedOutlinedIcon from 'NotStartedOutlined';
import GetUsers from '../UserManagement/GetUsers';

const EditSchoolUser = () => {
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const urlParams = useParams();
    const [schoolId, setSchoolId] = useState(
        urlParams?.schoolId ? urlParams.schoolId : userDetails?.schoolId ? userDetails.schoolId : ""
    )
    console.log(schoolId)
    const [selectedSchool, setSelectedSchool] = useState({});
    const [schoolConventionalID, setSchoolConventionalID] = useState('');
    const [schoolAvailable, setSchoolAvailable] = useState(false);
    const [schoolName, setSchoolName] = useState('');
    const [schoolAddress, setSchoolAddress] = useState('');
    const [schoolLogo, setSchoolLogo] = useState('');
    const [schoolPhoneNo, setSchoolPhoneNo] = useState('');
    const [schoolEmail, setSchoolEmail] = useState('');
    const [schoolWebsite, setSchoolWebsite] = useState('');
    const [schoolSession, setSchoolSession] = useState('');
    const [schoolStartingClass, setSchoolStartingClass] = useState('');
    const [schoolEndingClass, setSchoolEndingClass] = useState('');
    const [schoolMaxSection, setSchoolMaxSection] = useState('');
    const [schoolMaxStudents, setSchoolMaxStudents] = useState('');
    const [schoolSubjects, setSchoolSubjects] = useState([]);
    useEffect(() => {
        Api.get('/school-entity/school/').then((response) => {
            try {
                response.data.map((school) => {
                    console.log(school);
                    if (school._id === schoolId) {
                        setSelectedSchool(school);
                        setSchoolName(school.name);
                        setSchoolAddress(school.address);
                        setSchoolLogo(school.logo);
                        setSchoolPhoneNo(school.Contact_number);
                        setSchoolEmail(school.email);
                        setSchoolWebsite(school.website);
                        setSchoolSession(school.session);
                        setSchoolStartingClass(school.class_range_start);
                        setSchoolEndingClass(school.class_range_end);
                        setSchoolMaxSection(school.max_number_of_sections);
                        setSchoolMaxStudents(school.students_per_section);
                        setSchoolSubjects(school.subjects);
                        setSchoolAvailable(true);
                    }
                });
            }
            catch (error) {
                if (response.data._id === schoolId) {
                    setSelectedSchool(response.data);
                    setSchoolName(response.data.name);
                    setSchoolAddress(response.data.address);
                    setSchoolLogo(response.data.logo);
                    setSchoolPhoneNo(response.data.Contact_number);
                    setSchoolEmail(response.data.email);
                    setSchoolWebsite(response.data.website);
                    setSchoolSession(response.data.session);
                    setSchoolStartingClass(response.data.class_range_start);
                    setSchoolEndingClass(response.data.class_range_end);
                    setSchoolMaxSection(response.data.max_number_of_sections);
                    setSchoolMaxStudents(response.data.students_per_section);
                    setSchoolSubjects(response.data.subjects);
                    setSchoolAvailable(true);
                }
            }
        });
    }, []);

    const [userDict, setUserDict] = useState([]);
    useEffect(() => {
        Api.get('/user/by-school?id=' + schoolId).then((response) => {
            setUserDict(response.data.map((user) => {
                return {
                    ...user,
                    isChecked: false
                }
            }));
        })
    }, []);
    const userTypes = [
        ['CENTRAL', 'Central'],
        ['DISTRICT', 'District'],
        ['SCHOOL_ADMIN', 'School Admin'],
        ['TEACHER', 'Teacher'],
        ['PARENT', 'Parent'],
        ['STUDENT', 'Student'],
    ]
    const [selectedUserType, setSelectedUserType] = useState('ALL');
    const [classes, setClasses] = useState([]);
    const [selectedClass, setSelectedClass] = useState('ALL');
    useEffect(() => {
        Api.get('/school-entity/all-classes?school_id=' + schoolId).then((response) => {
            setClasses(response.data);
        })
    }, []);
    const [searchString, setSearchString] = useState('');
    const [transferOpen, setTransferOpen] = useState(false);
    const [transferUsersDistrictsList, setTransferUsersDistrictsList] = useState([]);
    const [transferUsersSchoolsList, setTransferUsersSchoolsList] = useState([]);
    const [transferUsersClassesList, setTransferUsersClassesList] = useState([]);
    const [transferUsersSectionsList, setTransferUsersSectionsList] = useState([]);
    const [transferUsersDistrict, setTransferUsersDistrict] = useState('');
    const [transferUsersSchool, setTransferUsersSchool] = useState('');
    const [transferUsersClass, setTransferUsersClass] = useState('');
    const [transferUsersSection, setTransferUsersSection] = useState('');
    const [transferStudentDistrictsList, setTransferStudentDistrictsList] = useState([]);
    const [transferStudentSchoolsList, setTransferStudentSchoolsList] = useState([]);
    const [transferStudentClassesList, setTransferStudentClassesList] = useState([]);
    const [transferStudentDistrict, setTransferStudentDistrict] = useState('');
    const [transferStudentSchool, setTransferStudentSchool] = useState('');
    const [transferStudentClass, setTransferStudentClass] = useState('');
    const [promoteStudentClass, setPromoteStudentClass] = useState('');
    const [promoteStudentClassList, setPromoteStudentClassList] = useState([]);
    useEffect(() => {
        Api.get('/school-entity/all-classes?school_id=' + schoolId).then((response) => {
            setPromoteStudentClassList(response.data);
        })
    }, []);
    useEffect(() => {
        Api.get('/district-entity/all').then((response) => {
            setTransferUsersDistrictsList(response.data);
            setTransferStudentDistrictsList(response.data);
        })
    }, []);
    useEffect(() => {
        console.log(transferUsersDistrict, "okay");
        if (transferUsersDistrict !== '') {
            Api.get('/school-entity/school?district_id=' + transferUsersDistrict).then((response) => {
                setTransferUsersSchoolsList(response.data);
                if (response.data.length >= 1) {
                    setTransferUsersSchool(response.data[0]._id);
                }
            })
        } else {
            setTransferUsersSchoolsList([]);
        }
    }, [transferUsersDistrict]);
    useEffect(() => {
        if (transferUsersSchool !== '') {
            Api.get('/school-entity/all-classes?school_id=' + transferUsersSchool).then((response) => {
                setTransferUsersClassesList(response.data);
            })
        } else {
            setTransferUsersClassesList([]);
        }
    }, [transferUsersSchool]);
    const [errorText, setErrorText] = useState('');
    const transferUsers = () => {
        if (transferUsersDistrict === '' || transferUsersSchool === '') {
            setErrorText('Please select a district and a school');
        } else {
            setErrorText('')
            setUserDict(userDict.map((user) => ({ ...user, status: "running" })));

        }
    }
    const [promoteOpen, setPromoteOpen] = useState(false);
    const promoteUsers = () => {
        if (promoteStudentClass === '') {
            setErrorText('Please select a class');
        } else {
            setErrorText('')
            setUserDict(userDict.map((user) => ({ ...user, status: "running" })))
            const selectedUsers = userDict.filter((user) => user?.isChecked);
            selectedUsers.map((user, idx) => {
                let prerror = false;
                console.log(user._id);
                if (user.isChecked) {
                    if (user.userType === 'STUDENT' && user.classes.length > 0) {
                        Api.post('/user-lifecycle/promote/student', {
                            userId: user._id,
                            to_class_id: promoteStudentClass,
                            from_class_id: user.classes[0]
                        }).then((response) => {
                            setUserDict(userDict.map((u) => (u._id === user._id ? { ...u, status: "success" } : u)));
                        }).catch((error) => {
                            console.log(error);
                            prerror = true;
                        }).finally(() => {
                            setUserDict(userDict.map((u) => (u._id === user._id ? { ...u, status: "failed" } : u)));
                        })
                    } else {
                        setUserDict(userDict.map((u) => (u._id === user._id ? { ...u, status: "failed" } : u)));
                    }
                }
            })
        }
    }
    return (
        <>
            {
                schoolAvailable ?
                    <div className="p-4 col-12" style={{ backgroundColor: "#fff" }} align="center">
                        <h1 className='mb-4'> Edit School </h1>
                        <div className="row">
                            <div className="col-auto ms-4 me-2">
                                <img src={schoolLogo} alt="School Logo" width="130" height="130" />
                            </div>
                            <div className="col-auto ms-2" align="left">
                                <h3>{schoolName}</h3>
                                <p>Phone No: <a target="_blank" href={"tel:+91" + schoolPhoneNo} style={{ textDecoration: "none" }}>+91 {schoolPhoneNo}</a><br></br>E-Mail: <a target="_blank" href={"mailto:" + schoolEmail} style={{ textDecoration: "none" }}>{schoolEmail}</a> <br></br>Website: <a target="_blank" href={schoolWebsite ? schoolWebsite.indexOf("://") == -1 ? "https://" + schoolWebsite : schoolWebsite : ""} style={{ textDecoration: "none" }}>{schoolWebsite} </a> <br></br>Address: {schoolAddress}</p>
                            </div>
                        </div>
                        <div>
                            <GetUsers
                                districtId={selectedSchool.district_id}
                                schoolId={selectedSchool._id}
                                lifeCycleEdit={true}
                            />
                        </div>

                    </div> :
                    <div className="p-4 col-12" style={{ borderRadius: 10 }} align="center">
                        <img src={`${process.env.PUBLIC_URL}/Images/failure.png`} style={{ width: '100px' }} alt="" />
                        <h3 className="m-4">Sorry, we cannot find the requested school.</h3>
                        <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                            <Button variant="outline-secondary" size="lg" onClick={(e) => { window.location.href = window.location.origin; }} >
                                Go to Dashboard
                            </Button>
                        </Stack>
                    </div>
            }
        </>
    )
}

export default EditSchoolUser;