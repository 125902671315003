import React, { useState, useEffect, useReducer } from "react";
// import {ScrollView} from "react";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { Form, Stack, InputGroup } from 'react-bootstrap';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { NavLink } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import { IconContext } from "react-icons";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from "@material-ui/core/Divider";

function setActive(recurList, EditSearchList) {
    var len = recurList.length
    for (var i = len-2 ; i >= 1; i--) {
        // console.log(recurList,"okk" , recurList[i], "okk" , recurList[i][0],"okk",recurList[i+1][0],"okk",recurList[i+1][1])
        recurList[i][0]['options'][recurList[i+1][1]] = recurList[i+1][0]
    }
    recurList[0][0][recurList[1][1]] = recurList[1][0]
    EditSearchList(recurList[0][0])
    return ;
}


const MenuItemSidebarInner = ({ option, i, sidebarOptions, userDetails, sideBarOpen, sideBarTempOpen, setSideBarOpen, dispatch, EditSearchList, recurList , render, setRenderForce }) => {
    // console.log(EditSearchList)
    return (
        <Stack
            direction="horizontal"
            onClick={() => {
                option = {...option, active: !option.active}
                setActive(recurList.concat([[option,i]]), EditSearchList)
                // setSideBarOpen(sideBarOpen)
                setRenderForce(!render)
            }}
        >
            <Stack style={{ width: "100%" }} direction="horizontal" gap={1}>
                <IconButton
                    className="ms-0 me-0 mt-0 mb-0 ps-2 pe-0 pt-2 pb-2"
                    style={{ backgroundColor: "#FFF", color: "#000000" }}
                    onClick={() => setSideBarOpen(true)}
                >
                    {option?.icon}
                </IconButton>
                <p className="ms-0 me-0 mt-0 mb-0 ps-2 pe-0 pt-2 pb-2">{option?.name}</p>
            </Stack>
            <IconButton
                className="ms-0 me-0 mt-0 mb-0 ps-0 pe-2 pt-2 pb-2"
                style={{ backgroundColor: "#FFF", color: "#000000" }}
            >
                {option?.options?.length > 0 ?
                    option?.active ?
                        <ExpandLessIcon />
                        :
                        <ExpandMoreIcon />
                    :
                    <></>
                }
            </IconButton>
        </Stack>
    )
}
const MenuItemSidebar = ({ sidebarOptions, userDetails, sideBarOpen, sideBarTempOpen, setSideBarOpen, dispatch, active, padding, EditSearchList , recurList}) => {
    // console.log(sidebarOptions)
    const [render, setRenderForce] = useState(true)
    return (
        <>
            {active ? sidebarOptions?.map(
                (option, i) =>
                    (option?.opFor.includes(userDetails?.userType) || userDetails?.is_internal_super_user || (option?.opFor.includes("INTERNAL") && userDetails?.is_internal_user)) && (
                        sideBarOpen || sideBarTempOpen ?
                            <div>
                                {option?.options?.length > 0 ?
                                    <>
                                        <MenuItemSidebarInner option={option} i={i} sidebarOptions={sidebarOptions} userDetails={userDetails} sideBarOpen={sideBarOpen} sideBarTempOpen={sideBarTempOpen} setSideBarOpen={setSideBarOpen} dispatch={dispatch} EditSearchList={EditSearchList} recurList={recurList} render={render} setRenderForce={setRenderForce} />
                                        <div style={{ marginLeft: padding.toString() + "px", borderLeft: "1px solid rgba(0, 0, 0, 0.2)" }} >
                                            <MenuItemSidebar
                                                sidebarOptions={option?.options}
                                                userDetails={userDetails}
                                                sideBarOpen={sideBarOpen}
                                                sideBarTempOpen={sideBarTempOpen}
                                                setSideBarOpen={setSideBarOpen}
                                                dispatch={dispatch}
                                                active={option?.active}
                                                padding={padding}
                                                EditSearchList={EditSearchList}
                                                recurList = {recurList.concat([[option,i]])}
                                            />
                                        </div>
                                    </>
                                    :
                                    <NavLink to={"/" + option?.route} className="text-decoration-none" style={({ isActive }) => ({
                                        color: isActive ? "#1981ee" : "#000",
                                    }
                                    )}>
                                        <MenuItemSidebarInner option={option} i={i} sidebarOptions={sidebarOptions} userDetails={userDetails} sideBarOpen={sideBarOpen} sideBarTempOpen={sideBarTempOpen} setSideBarOpen={setSideBarOpen} dispatch={dispatch} EditSearchList={EditSearchList} recurList={recurList} render={render} setRenderForce={setRenderForce}/>
                                    </NavLink>
                                }
                            </div> :
                            <center>
                                <IconButton
                                    style={{ backgroundColor: "#FFF", color: "#000000" }}
                                    onClick={() => {
                                        setSideBarOpen(true)
                                        option = {...option, active: !option.active}
                                        setActive(recurList.concat([[option,i]]), EditSearchList)
                                        setRenderForce(!render)
                                        
                                    }}
                                >
                                    <div>{option?.icon}</div>
                                </IconButton>
                            </center>
                    )
            ) : null}
        </>
    )
}

export default MenuItemSidebar;