import React, { useState, useEffect, useRef } from "react";
import { Stack, Form, InputGroup, Button } from 'react-bootstrap';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@material-ui/icons/Search';
import "./chat.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Api from "../../Api/axios";
import { useDataLayerValue } from "../../DataLayer/DataLayer";

const Chat = () => {
    const [chatNameSearch, setChatNameSearch] = useState('');
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [chatList, setChatList] = useState([]);
    const [filteredChatList, setFilteredChatList] = useState([]);
    useEffect(() => {
        Api.get("discussion-forum/chatroom").then((res) => {
            setChatList(res.data);
            console.log(res.data);
        });
    }, [userDetails]);
    useEffect(() => {
        setFilteredChatList(
            chatList.filter((chat) => {
                if (chatNameSearch === '') {
                    return chat;
                }
                return chat.title.toLowerCase().includes(chatNameSearch.toLowerCase());
            })
        );
    }, [chatNameSearch, chatList]);
    const [selectedChat, setSelectedChat] = useState({});
    const [selectedThread, setSelectedThread] = useState({});
    const [chatMessages, setChatMessages] = useState([]);
    const [threadMessages, setThreadMessages] = useState([]);
    // setInterval(() => {
    //     if (selectedChat._id && threadOpen === false) {
    //         Api.get(`discussion-forum/questions/?chatroomId=${selectedChat._id}&page=0&offset=100`).then((res) => {
    //             setChatMessages(res.data);
    //             console.log(res.data);
    //         });
    //     }
    // }, 20000);
    // setInterval(() => {
    //     if (threadOpen && selectedThread._id) {
    //         Api.get(`discussion-forum/answers/?question_id=${selectedThread._id}&page=0&offset=100`).then((res) => {
    //             setThreadMessages(res.data);
    //             console.log(res.data);
    //         })
    //         console.log('thread open');
    //     }
    // }, 20000);
    useEffect(() => {
        if (selectedChat._id) {
            dispatch({
                type: "SET_LOADING",
                loading: true,
            });
            Api.get(`discussion-forum/questions/?chatroomId=${selectedChat._id}&page=0&offset=100`).then((res) => {
                setChatMessages(res.data);
                console.log(res.data);
            }).finally(() => {
                dispatch({
                    type: "SET_LOADING",
                    loading: false,
                });
            });
        }
    }, [selectedChat]);
    useEffect(() => {
        if (selectedThread._id) {
            dispatch({
                type: "SET_LOADING",
                loading: true,
            });
            Api.get(`discussion-forum/answers/?question_id=${selectedThread._id}&page=0&offset=100`).then((res) => {
                setThreadMessages(res.data);
                console.log(res.data);
            }).finally(() => {
                dispatch({
                    type: "SET_LOADING",
                    loading: false,
                });
            })
        }
    }, [selectedThread]);
    const [threadOpen, setThreadOpen] = useState(false);
    useEffect(() => {
        if (threadOpen === false) {
            const objDiv = document.getElementById("chat-messages");
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        }
    }, [selectedChat, chatMessages, threadOpen]);
    useEffect(() => {
        if (threadOpen === true) {
            const objDiv = document.getElementById("thread-messages");
            if (objDiv) {
                objDiv.scrollTop = objDiv.scrollHeight;
            }
        }
    }, [threadOpen, selectedThread, threadMessages]);
    const [chatSendMessage, setChatSendMessage] = useState('');
    const [threadSendMessage, setThreadSendMessage] = useState('');

    return (
        <div
            style={{
                height: '100%',
                backgroundColor: '#FFF',
            }}
        >
            <Stack
                direction="horizontal"
                gap={0}
                style={{
                    height: '100%',
                }}
            >
                <div
                    style={{
                        minWidth: '400px',
                        minHeight: '100%',
                        maxHeight: '100%',
                    }}
                >
                    <div
                        style={{
                            minHeight: '60px',
                            maxHeight: '60px',
                            backgroundColor: 'rgb(190 190 193)',
                            // border: '1px solid rgb(190 190 193)',
                        }}
                    >
                        <Stack direction="horizontal" gap={0}>
                            <InputGroup className="pt-2 pb-2 ps-2 pe-2">
                                <InputGroup.Text
                                    style={{ backgroundColor: "#FFF", color: "#000000", border: "none" }}
                                >
                                    <SearchIcon
                                        style={{ fontSize: "30px" }}
                                    />
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Search"
                                    value={chatNameSearch}
                                    onChange={(e) => setChatNameSearch(e.target.value)}
                                    style={{ backgroundColor: "#FFF", color: "#000000", border: "none" }}
                                />
                            </InputGroup>
                            {/* <Button
                                variant="secondary"
                                className="ms-1 me-2"
                                style={{
                                    whiteSpace: 'nowrap',
                                    height: '100%',
                                }}
                            >
                                New Chat
                            </Button> */}
                        </Stack>
                    </div>
                    <div
                        className="scrollable-div pt-1 pb-2"
                        style={{
                            maxHeight: 'calc(100vh - 170px)',
                            minHeight: 'calc(100vh - 170px)',
                            overflowY: "scroll",
                            overflowX: "hidden",
                            height: "100%",
                            scrollbarWidth: "1px"
                        }}
                    >
                        {
                            filteredChatList.map((chat) => {
                                return (
                                    <>
                                        <div
                                            className="p-2 item-hover"
                                            onClick={() => setSelectedChat(chat)}
                                        >
                                            <Stack direction="horizontal" gap={0}>
                                                <img
                                                    src={chat?.avatar ? chat.avatar : `${process.env.PUBLIC_URL}/Images/group-chat-default.jpg`}
                                                    alt="Avatar"
                                                    style={{
                                                        width: '100%',
                                                        maxWidth: '40px',
                                                        maxHeight: '40px',
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                                <p
                                                    className="ps-2"
                                                    style={{
                                                        fontSize: '1.2rem',
                                                        fontWeight: 'bold',
                                                        margin: '0',
                                                    }}
                                                >
                                                    {chat.title}
                                                </p>
                                            </Stack>
                                        </div>
                                        <hr className="m-1 p-0" style={{ backgroundColor: "#000" }} />
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                {!threadOpen ? selectedChat._id ?
                    <div
                        style={{
                            width: '100%',
                            minHeight: '100%',
                            maxHeight: '100%',
                            minWidth: '400px',
                            background: `linear-gradient(rgb(219 216 211 / 92%), rgb(219 216 211 / 92%)), url(${process.env.PUBLIC_URL}/Images/chat-background.jpg)`,
                        }}
                    >
                        <div
                            style={{
                                minHeight: '60px',
                                maxHeight: '60px',
                                backgroundColor: 'rgb(225 225 228)',
                            }}
                        >
                            <Stack direction="horizontal" gap={2}>
                                <img
                                    className="p-2"
                                    src={selectedChat?.avatar ? selectedChat.avatar : `${process.env.PUBLIC_URL}/Images/group-chat-default.jpg`}
                                    alt="Avatar"
                                    style={{
                                        width: '100%',
                                        maxWidth: '60px',
                                        maxHeight: '60px',
                                        borderRadius: '50%',
                                    }}
                                />
                                <p
                                    style={{
                                        fontSize: '1.2rem',
                                        fontWeight: 'bold',
                                        margin: '0',
                                    }}
                                >
                                    {selectedChat.title ? selectedChat.title : "Select a chat"}
                                </p>
                            </Stack>
                        </div>
                        <div
                            id="chat-messages"
                            className="scrollable-div"
                            style={{
                                maxHeight: 'calc(100vh - 170px)',
                                minHeight: 'calc(100vh - 170px)',
                                overflowY: "scroll",
                                overflowX: "hidden",
                                height: "100%",
                                scrollbarWidth: "1px",
                            }}
                        >
                            <div
                                style={{
                                    transform: "scaley(-1)",
                                    minHeight: 'calc(100vh - 170px)',
                                    padding: '10px',
                                }}
                            >
                                {console.log(chatMessages)}
                                {chatMessages?.map((message) => {
                                    return (
                                        <div
                                            style={{
                                                transform: "scaley(-1)",
                                                maxWidth: '80%',
                                                minWidth: '100px',
                                                width: 'fit-content',
                                                backgroundColor: '#FFF',
                                                borderRadius: '0px 10px 10px 10px',
                                                padding: '5px 10px 5px 10px',
                                                margin: '10px',
                                                wordBreak: 'break-all',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <a
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '12px',
                                                        color: '#ff0505',
                                                    }}
                                                >
                                                    {message.created_by_name}
                                                </a>
                                                <button
                                                    style={{
                                                        fontSize: '12px',
                                                        color: 'rgb(86 0 255)',
                                                        paddingLeft: '90px',
                                                    }}
                                                    onClick={() => {
                                                        setThreadOpen(true)
                                                        setSelectedThread(message)
                                                    }}
                                                >
                                                    Open Thread
                                                </button>
                                            </div>
                                            <p
                                                style={{
                                                    whiteSpace: "pre-wrap"
                                                }}
                                            >
                                                {message.question_description}
                                            </p>
                                            <div
                                                style={{
                                                    width: '100%'
                                                }}
                                                align="right"
                                            >
                                                <a
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '12px',
                                                        color: '#000',
                                                        minWidth: '100%',

                                                    }}
                                                >
                                                    {new Date(message.created_at).toLocaleDateString() + " " + new Date(message.created_at).toLocaleTimeString()}
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div
                            style={{
                                minHeight: '60px',
                                maxHeight: '60px',
                                backgroundColor: 'rgb(225 225 228)',
                            }}
                            className="p-2"
                        >
                            <Stack direction="horizontal" gap={2}>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    placeholder="Type a message"
                                    value={chatSendMessage}
                                    onChange={(e) => {
                                        setChatSendMessage(e.target.value)
                                        console.log(e.target.value)
                                    }}
                                    style={{
                                        backgroundColor: "#FFF",
                                        color: "#000000",
                                        border: "none",
                                        borderRadius: "20px",
                                        padding: "10px",
                                        height: "100%",
                                        maxHeight: "45px",
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        if (chatSendMessage !== "") {
                                            Api.post('discussion-forum/question', {
                                                chatroomId: selectedChat._id,
                                                question_description: chatSendMessage,
                                            }).then((res) => {
                                                console.log(res);
                                                Api.get(`discussion-forum/questions/?chatroomId=${selectedChat._id}&page=0&offset=100`).then((res) => {
                                                    setChatMessages(res.data);
                                                    console.log(res.data);
                                                }).finally(() => {
                                                    setChatSendMessage("");
                                                })
                                            })
                                        }
                                    }}
                                    style={{
                                        backgroundColor: "rgb(255 255 255 / 0%)",
                                        color: "#000000",
                                        border: "none",
                                        borderRadius: "20px",
                                        // padding: "10px",
                                        height: "100%",
                                    }}
                                >
                                    <SendIcon />
                                </Button>
                            </Stack>
                        </div>
                    </div>
                    :
                    <div
                        style={{
                            minHeight: '100%',
                            // backgroundColor: 'rgb(225 225 228)',
                            width: '100%',
                            minWidth: '400px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'center',
                        }}
                    >
                        <Stack
                            direction="vertical"
                            gap={2}
                            align="center"
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center',
                            }}
                        >
                            <img
                                src={`${process.env.PUBLIC_URL}/Images/chat-vector.jpg`}
                                alt="Avatar"
                                style={{
                                    width: '100%',
                                    maxWidth: '400px',
                                    maxHeight: '400px',
                                    // borderRadius: '50%',
                                }}
                            />
                        </Stack>
                    </div>
                    :
                    <div
                        style={{
                            width: '100%',
                            minHeight: '100%',
                            maxHeight: '100%',
                            minWidth: '400px',
                            background: `linear-gradient(rgb(219 216 211 / 92%), rgb(219 216 211 / 92%)), url(${process.env.PUBLIC_URL}/Images/chat-background.jpg)`,
                        }}
                    >
                        <div
                            style={{
                                minHeight: '60px',
                                maxHeight: '60px',
                                backgroundColor: 'rgb(225 225 228)',
                                display: 'flex',
                                alignmentBaseline: 'center',
                            }}
                        >
                            <Stack direction="horizontal" gap={2}>
                                <Button
                                    style={{
                                        backgroundColor: "rgb(255 255 255 / 0%)",
                                        color: "#000000",
                                        border: "none",
                                        borderRadius: "20px",
                                        // padding: "10px",
                                        height: "100%",
                                    }}
                                    onClick={() => {
                                        setThreadOpen(false)
                                        setThreadMessages([])
                                        setSelectedThread({})
                                    }}
                                >
                                    <ArrowBackIcon />
                                </Button>
                            </Stack>
                        </div>
                        <div
                            id="thereadMessage"
                            style={{
                                maxWidth: '80%',
                                minWidth: '100px',
                                width: 'fit-content',
                                backgroundColor: '#FFF',
                                borderRadius: '0px 10px 10px 10px',
                                padding: '5px 10px 5px 10px',
                                margin: '10px',
                                wordBreak: 'break-all',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <a
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        color: '#ff0505',
                                    }}
                                >
                                    {selectedThread.created_by_name}
                                </a>
                            </div>
                            <p
                                style={{
                                    whiteSpace: "pre-wrap"
                                }}
                            >
                                {selectedThread.question_description}
                            </p>
                            <div
                                style={{
                                    width: '100%'
                                }}
                                align="right"
                            >
                                <a
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '12px',
                                        color: '#000',
                                        minWidth: '100%',

                                    }}
                                >
                                    {new Date(selectedThread.created_at).toLocaleDateString() + " " + new Date(selectedThread.created_at).toLocaleTimeString()}
                                </a>
                            </div>
                        </div>

                        <div
                            id="thread-messages"
                            className="scrollable-div"
                            style={{
                                maxHeight: `calc(100vh - 170px - ${document.getElementById("thereadMessage")?.scrollHeight + 20}px)`,
                                minHeight: `calc(100vh - 170px - ${document.getElementById("thereadMessage")?.scrollHeight + 20}px)`,
                                overflowY: "scroll",
                                overflowX: "hidden",
                                height: "100%",
                                scrollbarWidth: "1px",
                            }}
                        >
                            <div
                                style={{
                                    transform: "scaley(-1)",
                                    minHeight: `calc(100vh - 170px - ${document.getElementById("thereadMessage")?.scrollHeight + 20}px)`,
                                    padding: '10px',
                                }}
                            >
                                {threadMessages?.map((message, index) => {
                                    return (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignContent: "center",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    transform: "scaley(-1)",
                                                    maxWidth: '80%',
                                                    minWidth: '100px',
                                                    width: 'fit-content',
                                                    backgroundColor: 'rgb(215 250 209)',
                                                    borderRadius: '10px 0px 10px 10px',
                                                    padding: '5px 10px 5px 10px',
                                                    margin: '10px',
                                                    wordBreak: 'break-all',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <a
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '12px',
                                                            color: '#ff0505',
                                                        }}
                                                    >
                                                        {message.created_by_name}
                                                    </a>
                                                </div>
                                                <p
                                                    style={{
                                                        whiteSpace: "pre-wrap"
                                                    }}
                                                >
                                                    {message.answer}
                                                </p>
                                                <div
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                    align="right"
                                                >
                                                    <a
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '12px',
                                                            color: '#000',
                                                            minWidth: '100%',

                                                        }}
                                                    >
                                                        {new Date(message.created_at).toLocaleDateString() + " " + new Date(message.created_at).toLocaleTimeString()}
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div
                            style={{
                                minHeight: '60px',
                                maxHeight: '60px',
                                backgroundColor: 'rgb(225 225 228)',
                            }}
                            className="p-2"
                        >
                            <Stack direction="horizontal" gap={2}>
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    placeholder="Type a message"
                                    value={threadSendMessage}
                                    onChange={(e) => setThreadSendMessage(e.target.value)}
                                    style={{
                                        backgroundColor: "#FFF",
                                        color: "#000000",
                                        border: "none",
                                        borderRadius: "20px",
                                        padding: "10px",
                                        height: "100%",
                                        maxHeight: "45px",
                                    }}
                                />
                                <Button
                                    onClick={() => {
                                        if (threadSendMessage !== "") {
                                            Api.post('discussion-forum/answer', {
                                                question_id: selectedThread._id,
                                                chatroomId: selectedThread.chatroomId,
                                                answer: threadSendMessage,
                                            }).then((res) => {
                                                console.log(res);
                                                Api.get(`discussion-forum/answers/?question_id=${selectedThread._id}&page=0&offset=100`).then((res) => {
                                                    setThreadMessages(res.data);
                                                    console.log(res.data);
                                                }).finally(() => {
                                                    setThreadSendMessage("");
                                                })
                                            })
                                        }
                                    }}
                                    style={{
                                        backgroundColor: "rgb(255 255 255 / 0%)",
                                        color: "#000000",
                                        border: "none",
                                        borderRadius: "20px",
                                        // padding: "10px",
                                        height: "100%",
                                    }}
                                >
                                    <SendIcon />
                                </Button>
                            </Stack>
                        </div>
                    </div>
                }
                {/* 
                <div
                    style={{
                        minWidth: '350px',
                        minHeight: '100%',
                        maxHeight: '100%',
                    }}
                >
                    <h1>Chat</h1>
                </div> 
                */}
            </Stack >
        </div >
    )
}

export default Chat;