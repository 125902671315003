// import React, { useEffect, useState, useRef } from "react";
// import Api from "../../Api/axios";
// import "./edit.css";
// import { useDataLayerValue } from "../../DataLayer/DataLayer";
// import Error from "../ErrorSuccess/Error";
// import { FaUserEdit } from "react-icons/fa";
// import EditModal from "../EditModal/EditModal";
// import { useReactToPrint } from "react-to-print";

// const EditTeacher = () => {
//   const [allUsers, setAllUsers] = useState([]);
//   //   const [singleUser, setSingleUser] = useState([]);
//   const [{ userDetails }, dispatch] = useDataLayerValue();
//   const [errMesssage, setErrMesssage] = useState();
//   const [editModalOpen, setEditModalOpen] = useState(false);

//   const [idToBeEditd, setIdToBeEditd] = useState();
//   const [userName, setUserName] = useState();
//   const [userEmail, setUserEmail] = useState();
//   const [userContactNo, setUserContactNo] = useState();

//   const [allUsersFiltered, setAllUsersFiltered] = useState([]);
//   const [filterData, setFilterData] = useState({});
//   const componentRef = useRef();

//   useEffect(() => {
//     fetchTeachers();
//   }, []);

//   const fetchTeachers = async () => {
//     dispatch({
//       type: "SET_LOADING",
//       loading: true,
//     });
//     if (
//       userDetails?.userType === "CENTRAL" ||
//       userDetails?.userType === "DISTRICT"
//     ) {
//       await Api.get("/user/all-teachers")
//         .then((res) => {
//           setAllUsers(res.data);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         })
//         .catch((err) => {
//           setErrMesssage(err.response.message);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         });
//     } else {
//       await Api.get(`/user/by-school?id=${userDetails.schoolId}&type=TEACHER`)
//       //await Api.get(`/user/by-school?id=${userDetails.schoolId}&type=TEACHER`)
//         .then((res) => {
//           setAllUsers(res.data);

//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         })
//         .catch((err) => {
//           setErrMesssage(err.response.message);
//           dispatch({
//             type: "SET_LOADING",
//             loading: false,
//           });
//         });
//     }
//   };

//   // update Contact No from EditModal
//   const changeValue = (e) => {
//     const { id, value } = e.target;
//     setUserContactNo({ [id]: value });
//   };

//   const openEditModal = (userId, userName, userEmail, userContactNo) => {
//     setEditModalOpen(true);
//     setUserName(userName);
//     setUserEmail(userEmail);
//     setUserContactNo(userContactNo);
//     setIdToBeEditd(userId);
//     //getEditUser();
//   };
//   const closeEditModal = () => {
//     setEditModalOpen(false);
//   };

//   const editConfirmed = () => {
//     setEditModalOpen(false);
//     console.log(idToBeEditd);
//     //updated userContactNO
//    // console.log(userContactNo);
//     editUser();
//   };

//   const editUser = async () => {
//     dispatch({
//       type: "SET_LOADING",
//       loading: true,
//     });
//     await Api.put(`user?id=${idToBeEditd}`, { contactNumber: `${userContactNo.userContactNo}`},true)
//       .then((res) => {
//         fetchTeachers();
//         console.log(res.data);
//       })
//       .catch((err) => console.log(err));
//     dispatch({
//       type: "SET_LOADING",
//       loading: false,
//     });

//     // await Api.edit("/user", { params: { id: idToBeEditd } })
//     //   .then((res) =>
//     //     fetchTeachers();
//     //     console.log(res);
//     //   })
//     //   .catch((err) => console.log(err));
//     // dispatch({
//     //   type: "SET_LOADING",
//     //   loading: false,
//     // });
//   };

//   const changeFilterData = (e) => {
//     const { id, value } = e.target;
//     setFilterData({ ...filterData, [id]: value });
//   };

//   useEffect(() => {
//     setFilteredUserDetails();
//   }, [filterData, allUsers]);

//   const setFilteredUserDetails = () => {
//     const keys = Object.keys(filterData);
//     let allEmpty = Object.values(filterData).every(
//       (x) => x === null || x === "" || x === undefined
//     );
//     if (allEmpty || filterData === {}) {
//       setAllUsersFiltered(allUsers);
//     } else {
//       let filteredUserDetailsTemp = allUsers.filter((attd) => {
//         return keys.every((key) =>
//           attd[key]
//             ?.toLocaleLowerCase()
//             .includes(filterData[key]?.toLocaleLowerCase())
//         );
//       });
//       setAllUsersFiltered(filteredUserDetailsTemp);
//     }
//   };

//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current,
//   });

//   return (
//     <div className="show-table-container">
//       <div className="show-table-container-main">
//         <button onClick={handlePrint}>Print User Details</button>
//         <table className="table" ref={componentRef}>
//           <thead>
//             <tr>
//               <th colSpan="5">Teachers</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Name
//                   <input
//                     type="text"
//                     id="name"
//                     placeholder="eg: Ayan"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Email
//                   <input
//                     type="text"
//                     id="email"
//                     placeholder="eg: Ayan"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>
//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Teacher Id
//                   <input
//                     type="text"
//                     id="_id"
//                     placeholder="eg: id"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>

//               <td>
//                 <div className="ad-st-attnd-table-filter">
//                   Contact Number
//                   <input
//                     type="text"
//                     id="contactNumber"
//                     placeholder="eg: 91XXXXXX01"
//                     onChange={(e) => changeFilterData(e)}
//                   />
//                 </div>
//               </td>

//               <td>Edit</td>
//             </tr>
//             {allUsersFiltered?.map((user) => {
//               <tr key={user._id}>
//                 <td>{user?.name}</td>
//                 <td>{user?.email}</td>
//                 <td>{user?._id}</td>
//                 <td>{user?.contactNumber}</td>
//                 <td>
//                   <FaUserEdit
//                     color="Black"
//                     style={{ cursor: "pointer" }}
//                     onClick={() =>
//                       openEditModal(
//                         user?._id,
//                         user?.name,
//                         user?.email,
//                         user?.contactNumber
//                       )
//                     }
//                   />
//                 </td>
//               </tr>
//             })}
//             {errMesssage && <Error message={errMesssage} />}
//           </tbody>
//         </table>
//       </div>

//       <EditModal
//         changevalue={changeValue}
//         open={editModalOpen}
//         closeEditModal={closeEditModal}
//         editConfirmed={editConfirmed}
//         userId={idToBeEditd}
//         userName={userName}
//         userContactNo={userContactNo}
//         userEmail={userEmail}
//         message={"Do you really want to edit this user ?"}
//       />
//     </div>
//   );
// };

// export default EditTeacher;

import React, { useEffect, useState, useRef } from "react";
import Api from "../../Api/axios";
//import "./show.css";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Error from "../ErrorSuccess/Error";
import { FaUserEdit } from "react-icons/fa";
import EditModal from "../EditModal/EditModal";
import { useReactToPrint } from "react-to-print";

const ShowTeachers = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [errMesssage, setErrMesssage] = useState();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [idToBeEditd, setIdToBeEditd] = useState();
  const [allUsersFiltered, setAllUsersFiltered] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userContactNo, setUserContactNo] = useState("");
  const componentRef = useRef();

  useEffect(() => {
    fetchTeachers();
  }, []);

  //fetching teacher data on the basis of user type
  const fetchTeachers = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    if (
      userDetails?.userType === "CENTRAL" ||
      userDetails?.userType === "DISTRICT"
    ) {
      await Api.get("/user/all-teachers")
        .then((res) => {
          setAllUsers(res.data);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((err) => {
          setErrMesssage(err.response.message);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    } else {
      await Api.get(`/user/by-school?id=${userDetails.schoolId}&type=TEACHER`)
        .then((res) => {
          setAllUsers(res.data);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        })
        .catch((err) => {
          setErrMesssage(err.response?.message);
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
        });
    }
  };

  // update Contact No from EditModal
  const changeValue = (e) => {
    const { id, value } = e.target;
    setUserContactNo({ [id]: value });
  };

  const openEditModal = (userId, userName, userEmail, userContactNo) => {
    console.log(allUsers);
    setEditModalOpen(true);
    setUserName(userName);
    setUserEmail(userEmail);
    setUserContactNo(userContactNo);
    setIdToBeEditd(userId);
  };
  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  const editConfirmed = () => {
    setEditModalOpen(false);
    console.log(idToBeEditd);
    console.log(userContactNo);
    editUser();
  };

  const editUser = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.put(
      `user?id=${idToBeEditd}`,
      { contactNumber: `${userContactNo.userContactNo}` },
      true
    )
      .then((res) => fetchTeachers())
      .catch((err) => console.log(err));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const changeFilterData = (e) => {
    const { id, value } = e.target;
    setFilterData({ ...filterData, [id]: value });
  };

  useEffect(() => {
    setFilteredUserDetails();
  }, [filterData, allUsers]);

  const setFilteredUserDetails = () => {
    const keys = Object.keys(filterData);
    let allEmpty = Object.values(filterData).every(
      (x) => x === null || x === "" || x === undefined
    );
    if (allEmpty || filterData === {}) {
      setAllUsersFiltered(allUsers);
    } else {
      let filteredUserDetailsTemp = allUsers.filter((attd) => {
        return keys.every((key) =>
          attd[key]
            ?.toLocaleLowerCase()
            .includes(filterData[key]?.toLocaleLowerCase())
        );
      });
      setAllUsersFiltered(filteredUserDetailsTemp);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div className="show-table-container">
      <div className="show-table-container-main">
        <button onClick={handlePrint}>Print User Details</button>
        <table className="table" ref={componentRef}>
          <thead>
            <tr>
              <th colSpan="5">Teachers</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Name
                  <input
                    type="text"
                    id="name"
                    placeholder="eg: Ayan"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Email
                  <input
                    type="text"
                    id="email"
                    placeholder="eg: Ayan"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>
              <td>
                <div className="ad-st-attnd-table-filter">
                  Teacher Id
                  <input
                    type="text"
                    id="_id"
                    placeholder="eg: id"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>

              <td>
                <div className="ad-st-attnd-table-filter">
                  Contact Number
                  <input
                    type="text"
                    id="contactNumber"
                    placeholder="eg: 91XXXXXX01"
                    onChange={(e) => changeFilterData(e)}
                  />
                </div>
              </td>

              <td>Edit</td>
            </tr>
            {allUsersFiltered?.map((user) => (
              <tr>
                <td>{user?.name}</td>
                <td>{user?.email}</td>
                <td>{user?._id}</td>
                <td>{user?.contactNumber}</td>
                <td>
                  <FaUserEdit
                    color="black"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      openEditModal(
                        user?._id,
                        user?.name,
                        user?.email,
                        user?.contactNumber
                      )
                    }
                  />
                </td>
              </tr>
            ))}
            {errMesssage && <Error message={errMesssage} />}
          </tbody>
        </table>
      </div>

      <EditModal
        changevalue={changeValue}
        open={editModalOpen}
        closeEditModal={closeEditModal}
        editConfirmed={editConfirmed}
        userId={idToBeEditd}
        userName={userName}
        userContactNo={userContactNo}
        userEmail={userEmail}
        message={"Do you really want to edit this user ? "}
      />
    </div>
  );
};

export default ShowTeachers;
