import { useNavigate, useParams } from "react-router";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import Api from "../../../Api/axios";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";

const ViewAssessment = () => {
  const id = useParams();
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [asDetails, setAsDetails] = useState({});
  const [submittedUsers, setSubmittedUsers] = useState([]);
  const [notSubmittedUsers, setNotSubmittedUsers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [options, setOptions] = useState();
  const [mapQuestions, setMapQuestions] = useState({});
  const navigate = useNavigate();

  //   useEffect(() => {
  //     Api.get(
  //       `/assessment/submission/users-submitted?assessment_id=a96ccad3-9d43-4df5-9a83-073c59ce6133`
  //     ).then((res) => {
  //       console.log("Response", res.data);
  //     });
  //   }, []);

  useEffect(() => {
    Api.get(`/assessment/created-assessment`).then((res) => {
      console.log("assessment created", res.data);
      let fetchedAssessment = res.data;
      let aD;
      fetchedAssessment.forEach((x) => {
        if (x._id === id.assessment_id) {
          console.log("Ths is X", x);
          setQuestions(x.questions);
          setAsDetails(x);
          return;
        }
      });
    });
  }, [userDetails, id.assessment_id]);

  console.log("Assessment List", asDetails);

  useEffect(() => {
    Api.get(
      "assessment/submission/users-not-submitted?assessment_id=" +
        id.assessment_id
    ).then((res) => {
      let notSubmittedList = [];
      let fetchedData = res.data;
      fetchedData.forEach((x) => {
        let obj = {};
        obj.name = x.name;
        obj.email = x.email;
        notSubmittedList.push(obj);
      });
      console.log("notSubmittedList ------> ",notSubmittedList)
      setNotSubmittedUsers(notSubmittedList);
    });
  }, [userDetails, id.assessment_id]);

  useEffect(() => {
    Api.get(
      "/assessment/submission/users-submitted?assessment_id=" + id.assessment_id
    ).then((res) => {
      let submittedList = [];
      let fetchedData = res.data;
      console.log("getcehde data",fetchedData)
      fetchedData.forEach((x) => {
        let obj = {};
        obj.name = x.name;
        obj.email = x.email;
        submittedList.push(obj);
      });
      setSubmittedUsers(submittedList);
    });
  }, [userDetails, id.assessment_id]);

  console.log("submitedUSer", submittedUsers);
  console.log("qestions", questions);
useEffect(()=>{
  console.log("submittedUsers    -----> ",submittedUsers);
},[submittedUsers])
  return (
    <div
      className="ps-2 pe-2"
      style={{
        minHeight: "94vh",
        backgroundColor: "#FFF",
        paddingBottom: "40px",
      }}
      align="center"
    >
      <div align="left">
        <Button
          className="m-4"
          variant="secondary"
          size="sm"
          onClick={() => navigate("/assessment/assessment-tracker")}
        >
          Go Back
        </Button>
      </div>
      {asDetails !== [] && (
        <h1 className="p-4" style={{ fontWeight: "bold" }}>
          {asDetails.title}
        </h1>
      )}

      <div align="Right">
        <Button
          className="m-4"
          variant="secondary"
          size="sm"
          onClick={() =>
            navigate(`/assessment/assessment-tracker/${id.assessment_id}/edit`)
          }
        >
          Edit Assessment
        </Button>
      </div>
      <Tabs
        defaultActiveKey="details"
        className="mb-3"
        style={{
          width: "100%",
          whiteSpace: "nowrap",
        }}
        // onSelect={(k) => {
        //     // this.setState({ key: k });
        //     setKey(k);
        // }}
      >
        <Tab
          className="p-4"
          tabClassName="ms-2 me-2"
          eventKey="details"
          title="Details"
          align="left"
        >
          <Table responsive hover>
            <tbody>
              <tr>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "1px solid black",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                  }}
                >
                  <b>Created By</b>
                </td>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "1px solid rgb(0 0 0 / 26%)",
                    textAlign: "left",
                    width: "100%",
                  }}
                >
                  : {asDetails.created_by_name}
                </td>
              </tr>
              <tr>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "1px solid rgb(0 0 0 / 26%)",
                    textAlign: "left",
                  }}
                >
                  <b>Created Date and time</b>
                </td>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "1px solid rgb(0 0 0 / 26%)",
                    textAlign: "left",
                  }}
                >
                  : {new Date(asDetails.createdAt).toLocaleString()}
                </td>
              </tr>
              <tr>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "1px solid rgb(0 0 0 / 26%)",
                    textAlign: "left",
                  }}
                >
                  <b>Exipired Date</b>
                </td>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "1px solid rgb(0 0 0 / 26%)",
                    textAlign: "left",
                  }}
                >
                  : {asDetails.ended_at}
                </td>
              </tr>
              <tr>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  <b>User Type</b>
                </td>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  : {asDetails.user_type}
                </td>
              </tr>
              <tr>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "1px solid rgb(0 0 0 / 26%)",
                    textAlign: "left",
                  }}
                >
                  <b>Total no. questions</b>
                </td>
                <td
                  className="p-2"
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    textAlign: "left",
                  }}
                >
                  : {asDetails.total_questions}
                </td>
              </tr>
            </tbody>
          </Table>
        </Tab>
        <Tab
          eventKey="submitted"
          tabClassName="ms-2 me-2"
          title="Submitted List"
          className="ps-4 pe-4 pt-4 pb-2"
          align="center"
        >
          <div className="m-4">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th scope="col" style={{ minWidth: "80px" }}>
                    Sl.no
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Student Name
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Email
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Marks Assigned
                  </th>
                </tr>
              </thead>
              <tbody>
                {submittedUsers !== [] &&
                  submittedUsers.map((data, index) => {
                    return (
                      <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.name}</td>
                      <td>{data.email}</td>
                      <td>null</td>
                    </tr>
                    )
                  })}
              </tbody>
            </Table>
          </div>
        </Tab>
        <Tab
          eventKey="not-submitted"
          tabClassName="ms-2 me-2"
          title="Not Submitted List"
          className="ps-4 pe-4 pt-4 pb-2"
          align="center"
        >
          <div className="m-4">
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th scope="col" style={{ minWidth: "80px" }}>
                    Sl.no
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Student Name
                  </th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Email
                  </th>
                </tr>
              </thead>
              <tbody>
                {notSubmittedUsers !== [] &&
                  notSubmittedUsers.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.name}</td>
                        <td>{data.email}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Tab>
        <Tab
          eventKey="questions"
          tabClassName="ms-2 me-2"
          title="Questions"
          className="ps-4 pe-4 pt-4 pb-2"
          align="center"
        >
          <div className="m-4">
            <h2>Assessment Questions</h2>
            {questions !== [] &&
              questions.map((data, index) => {
                return (
                  <Card key={index}>
                    <Card.Title>
                      {index + 1}: {data.description} ?
                    </Card.Title>
                  </Card>
                );
              })}
            {/* {data.options.map((op, index) => {
                  <Card.Subtitle key={index} className="mb-2 text-muted">
                    {op.option}
                  </Card.Subtitle>;
                })} */}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ViewAssessment;
