import React, { useState, useEffect } from 'react';
import Api from '../../Api/axios';
import { Stack } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table, Button, Form, Col, Row } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import Modal from 'react-bootstrap/Modal';
import { useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const UserDashboard = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    console.log(params);
    const [user, setUser] = useState([]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [refresh, setRefresh] = useState(false);
    const [userAvailable, setUserAvailable] = useState(true);
    const [sessionList, setSessionList] = useState([]);
    const [userLifeCycle, setUserLifeCycle] = useState([]);
    const [userClasses, setUserClasses] = useState([]);
    const [wards, setwards] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const urlParams = useParams();
    const navigate = useNavigate();
    // console.log(urlParams.id ? urlParams.id : userDetails._id);
    useEffect(() => {
        Api.get('/user/get-user-by-id/?id=' + (urlParams.id ? urlParams.id : userDetails?._id)).then((res) => {
            console.log("teacher data ==>", res.data)
            setUser({ ...res.data, DOB: res.data.DOB ? new Date(res.data.DOB) : res.data.DOB, first_appointment_date: res.data.first_appointment_date ? new Date(res.data.first_appointment_date) : res.data.first_appointment_date });
            setUserAvailable(true);
            Api.get('/session-entity?district_id=' + res.data.district_id).then((resp) => {
                setSessionList(resp.data);
            })
            Api.get('/user-lifecycle/by_user_id/?user_id=' + res.data._id).then((response) => {
                setUserLifeCycle(response.data);
            })
            Api.post('/live-class/get-all-classes', { "data": [...res.data.classes] }).then((response) => {
                response.data.sort((a, b) => parseInt(a.class_name) > parseInt(b.class_name) ? 1 : parseInt(a.class_name) < parseInt(b.class_name) ? -1 : a.class_section > b.class_section ? 1 : -1)
                setUserClasses(response.data);
            })
            var w = [];
            res.data.wards.map((ward) => {
                Api.get('/user/get-user-by-id/?id=' + ward).then((r) => {
                    w.push(r.data);
                })
            })
            setwards(w);
        }).catch((err) => {
            setUserAvailable(false);
            if (!(urlParams.id ? urlParams.id : userDetails?._id)) {
                setRefresh(!refresh);
            }
        })
        Api.get('/notification/getUserNotification').then((res) => {
            setNotifications(res.data);
        })

    }, [refresh]);

    return (
        <>
            <div style={{ minHeight: "100%", width: "auto", minWidth: "800px", background: "#FFF" }}>
                <div style={{ height: "150px", width: "auto", color: "#fff", background: `url(${process.env.PUBLIC_URL}/Images/banner.jpg)`, backgroundSize: "cover" }} align="left">

                </div>
                <div align="center">
                    <Stack direction='vertical' gap={3}
                        className="pb-4"
                        style={{ alignItems: "center", width: "100%" }}
                    >
                        <img
                            src={user?.avatar ? user.avatar : `${process.env.PUBLIC_URL}/Images/default_avatar.jpg`}
                            alt="profile pic"
                            className='ms-2'
                            style={{ marginTop: "-90px", width: "200px", height: "200px", border: "4px solid white", borderRadius: "50%" }}
                        />
                        <h1 style={{ marginBottom: "0", paddingBottom: "0", fontWeight: "bold" }}>  {user?.name}</h1>
                        <h4 className='text-muted' style={{ marginTop: "0", paddingTop: "0", fontWeight: "light" }}>{user?.displayUserType}</h4>
                    </Stack>
                    <Tabs
                        defaultActiveKey="basicInfo"
                        className="mb-3"
                        style={{
                            width: "100%",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <Tab className='p-4' tabClassName='ms-2 me-2' eventKey="basicInfo" title="Basic Info" align="left">
                            <Table responsive hover>
                                <tbody>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap", minWidth: "200px" }}><b>Conventioanl ID</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", width: "100%" }}>: {user.conventionalId}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>District</b></td>
                                        {/*  remove this hardcode  */}
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: Sundergarh</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Date of Birth</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {
                                            user.DOB ? String(user.DOB.getDate()).padStart(2, '0') + '-' + String(user.DOB.getMonth() + 1).padStart(2, '0') + '-' + user.DOB.getFullYear() : ""
                                        }</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Gender</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.gender}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Mother Tongue</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.mother_tongue}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Email</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.email}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Phone No.</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: +91 {user.contactNumber}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Address</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.permanent_address}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Religion</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Religion}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Caste</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Caste ? user.Caste : user.social_category}</td>
                                    </tr>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Disability</b></td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.disability_type}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Tab>
                        {
                            user.userType === "STUDENT" ?
                                <Tab eventKey="studentInfo" tabClassName='ms-2 me-2' title="Student Info" className='p-4' align="left">
                                    <Table responsive hover>
                                        <tbody>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>School Name</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.schoolName}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>School Board</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.schoolBoard}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Block Name</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Block_name}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Class</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {
                                                    userClasses.map((c, index) => {
                                                        return (
                                                            <span key={index}>{c.class_name_section}{((index + 1) !== userClasses.length) ? ", " : ""}</span>
                                                        )
                                                    })
                                                }</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Admission Number</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.admission_number}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Unique Student Id</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.unique_student_id}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Father Name</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.father_name}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Mother Name</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.mother_name}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Parents Address</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.student_parents_address}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Locality</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.name_of_habitation_or_locality}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Is Student BPL</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.is_student_bpl}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Student Type</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.student_opted}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Medium of Instruction</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.medium_of_instruction}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Eye Screening</b></td>
                                                <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.eye_screening}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Tab>
                                : user.userType === "PARENT" ?
                                    <Tab eventKey="parentInfo" tabClassName='ms-2 me-2' title="Parent Info" className='p-4' align="left">
                                        <Table responsive hover>
                                            <tbody>
                                                <tr>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Wards</b></td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {
                                                        wards.map((ward, index) => {
                                                            return (
                                                                <span key={index} onClick={navigate(ward._id)}>{ward.name}{((index + 1) !== userClasses.length) ? ", " : ""}</span>
                                                            )
                                                        })
                                                    }</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Tab>
                                    : user.userType === "TEACHER" ?
                                        <Tab eventKey="teacherInfo" tabClassName='ms-2 me-2' title="Teacher Info" className='p-4' align="left">
                                            <Table responsive hover>
                                                <tbody>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>School Name</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.schoolName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>School Board</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.schoolBoard}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Block Name</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Block_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Classes</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {
                                                            userClasses.map((c, index) => {
                                                                return (
                                                                    <span key={index}>{c.class_name_section}{((index + 1) !== userClasses.length) ? ", " : ""}</span>
                                                                )
                                                            })
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Teacher School Catagory</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.teacher_schcat_desc}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Present School Code</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Present_school_code}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Teacher Code</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Teacher_code}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Teacher Category</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Teacher_category}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Subjects Taught</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {
                                                            user.subject_taught.map((subject, index) => {
                                                                return (
                                                                    <span key={index}>{subject.name}{((index + 1) !== user.subject_taught.length) ? ", " : ""}</span>
                                                                )
                                                            })
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Class Taught Type</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.class_taught_type}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Plus Two Stream</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.plus_two_stream}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Graduation Stream</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.graduation_stream}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Postgraduation Stream</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.postgraduation_stream}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>M Phil</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.m_phil}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Phd Subject</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Phd}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Professional Qualification</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Professional_qualification}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Appointment Date</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {
                                                            user.first_appointment_date ? String(user.first_appointment_date.getDate()).padStart(2, '0') + '-' + String(user.first_appointment_date.getMonth() + 1).padStart(2, '0') + '-' + user.first_appointment_date.getFullYear() : ""
                                                        }</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Medium of Instruction</b></td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.medium_of_instruction}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Tab>

                                        : user.userType === "SCHOOL_ADMIN" ?
                                            <Tab eventKey="adminInfo" tabClassName='ms-2 me-2' title="Admin Info" className='p-4' align="left">
                                                <Table responsive hover>
                                                    <tbody>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>School Name</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.schoolName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>School Board</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.schoolBoard}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Block Name</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Block_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Plus Two Stream</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.plus_two_stream}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Graduation Stream</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.graduation_stream}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Postgraduation Stream</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.postgraduation_stream}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>M Phil</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.m_phil}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Phd Subject</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Phd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Professional Qualification</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.Professional_qualification}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Appointment Date</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {
                                                                user.first_appointment_date ? String(user.first_appointment_date.getDate()).padStart(2, '0') + '-' + String(user.first_appointment_date.getMonth() + 1).padStart(2, '0') + '-' + user.first_appointment_date.getFullYear() : ""
                                                            }</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left", whiteSpace: "nowrap" }}><b>Medium of Instruction</b></td>
                                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "left" }}>: {user.medium_of_instruction}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Tab>
                                            :
                                            <>
                                            </>
                        }
                        <Tab eventKey="variables" tabClassName='ms-2 me-2' title="LifeCycle" className='p-4' align="left">
                            <Table responsive hover stripped>
                                <thead>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Sr. No.
                                        </td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Lifecycle Type
                                        </td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Date
                                        </td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Time (24 Hrs Format)
                                        </td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            View Details
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        userLifeCycle.map((item, index) => {
                                            var date_time = new Date(item.updatedAt);
                                            var dd = String(date_time.getDate()).padStart(2, '0');
                                            var mm = String(date_time.getMonth() + 1).padStart(2, '0');
                                            var yyyy = date_time.getFullYear();
                                            var time = date_time.getHours() + ":" + date_time.getMinutes();
                                            var date = dd + '/' + mm + '/' + yyyy;
                                            return (
                                                <>
                                                    <Modal show={item.isOpened} onHide={(e) => { setUserLifeCycle(userLifeCycle.map(sc => (item._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>View</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <div className='p-2'>
                                                                {
                                                                    item.cycleType === "ONBOARD" ?
                                                                        <>
                                                                            <h3 align="center">ONBOARD</h3>
                                                                            <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                            <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                            <span style={{ fontWeight: "bold" }}>District: </span>{item.to_district_name} <br />
                                                                            <span style={{ fontWeight: "bold" }}>School: </span>{item.to_school_name} <br />
                                                                            <span style={{ fontWeight: "bold" }}>Class: </span>{item.to_class_name} <br />
                                                                        </>
                                                                        : item.cycleType === "ASSIGN" ?
                                                                            <>
                                                                                <h3 align="center">ASSIGN</h3>
                                                                                <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                                <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                                <span style={{ fontWeight: "bold" }}>District: </span>{item.to_district_name} <br />
                                                                                <span style={{ fontWeight: "bold" }}>School: </span>{item.to_school_name} <br />
                                                                                <span style={{ fontWeight: "bold" }}>Class: </span>{item.to_class_name} <br />
                                                                            </>
                                                                            : item.cycleType === "PROMOTE" ?
                                                                                <>
                                                                                    <h3 align="center">PROMOTE</h3>
                                                                                    <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                                    <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                                    <span style={{ fontWeight: "bold" }}>From Class: </span>{item.from_class_name} <br />
                                                                                    <span style={{ fontWeight: "bold" }}>To Class: </span>{item.to_class_name} <br />
                                                                                </>

                                                                                : item.cycleType === "TRANSFER" ?
                                                                                    <>
                                                                                        <h3 align="center">TRANSFER</h3>
                                                                                        <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                                        <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                                        <span style={{ fontWeight: "bold" }}>From Class: </span>{item.from_class_name} <br />
                                                                                        <span style={{ fontWeight: "bold" }}>To Class: </span>{item.to_class_name} <br />
                                                                                        <span style={{ fontWeight: "bold" }}>From School: </span>{item.from_school_name} <br />
                                                                                        <span style={{ fontWeight: "bold" }}>To School: </span>{item.to_school_name} <br />
                                                                                        <span style={{ fontWeight: "bold" }}>From District: </span>{item.from_district_name} <br />
                                                                                        <span style={{ fontWeight: "bold" }}>To District: </span>{item.to_district_name} <br />
                                                                                    </>
                                                                                    : item.cycleType === "DEMOTE" ?
                                                                                        <>
                                                                                            <h3 align="center">DEMOTE</h3>
                                                                                            <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                                            <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                                            <span style={{ fontWeight: "bold" }}>From Class: </span>{item.from_class_name} <br />
                                                                                            <span style={{ fontWeight: "bold" }}>To Class: </span>{item.to_class_name} <br />
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <h3 align="center">SUSPEND</h3>
                                                                                            <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                                            <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                                        </>
                                                                }
                                                            </div>
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="secondary" onClick={(e) => { setUserLifeCycle(userLifeCycle.map(sc => (item._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>
                                                                Close
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                    <tr>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                            {index + 1}
                                                        </td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                            {item.cycleType}
                                                        </td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                            {date}
                                                        </td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                            {time}
                                                        </td>
                                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }} onClick={(e) => { setUserLifeCycle(userLifeCycle.map(sc => (item._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>
                                                            View
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>

                        </Tab>
                        <Tab eventKey="notifications" tabClassName='ms-2 me-2' title="Notifications" className='p-4' align="left">
                            <Table responsive hover stripped>
                                <thead>
                                    <tr>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Sr. No.
                                        </td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Date
                                        </td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Time
                                        </td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Message
                                        </td>
                                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                            Notification Type
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        notifications.map((item, index) => {
                                            let date = new Date(item.createdAt)
                                            // date to dd-mm-yyyy format
                                            date = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
                                            let time = new Date(item.createdAt).toLocaleTimeString()

                                            return (
                                                <tr onClick={() => {
                                                    if (item.link) {
                                                        navigate(item.link)
                                                    }
                                                }}>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                        {index + 1}
                                                    </td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                        {date}
                                                    </td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                        {time}
                                                    </td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                        {item.message}
                                                    </td>
                                                    <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                        {item.notificationType}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}

export default UserDashboard;