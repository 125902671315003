import React, { useEffect, useState } from "react";
import Api, { setToken } from "../../Api/axios";
import "./add.css";
import axios from "axios";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Success from "../ErrorSuccess/Success";
import Error from "../ErrorSuccess/Error";
import { Button } from "@mui/material";

const AddCentral = () => {
  const [newUserData, setNewUserData] = useState({});
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [succMessage, setSuccMessage] = useState();
  const [errMessage, setErrMessage] = useState();

  const inputFields = [
    {
      id: "name",
      name: "name",
      placeholder: "Central Name",
      type: "text",
    },
    {
      id: "email",
      name: "email",
      placeholder: "Central Email",
      type: "email",
    },
    {
      id: "password",
      name: "password",
      placeholder: "Student Password",
      type: "password",
      required: true,
      minLength: 8,
    },
    {
      id: "username",
      name: "userName",
      placeholder: "Central User Name",
      type: "text",
    },

    {
      id: "contactNumber",
      name: "contactNumber",
      placeholder: "Student Contact Number",
      type: "text",
      required: true,
      minLength: 10,
      maxLength: 10,
    },
  ];

  const inputData = (e) => {
    setNewUserData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const addNewUser = async (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    if (isNaN(newUserData.contactNumber)) {
      setSuccMessage();
      setErrMessage("Contact number must be numeric");
    } else {
      await Api.post("/user/central", newUserData)
        .then((res) => {
          setSuccMessage("Central added successfully");
        })
        .catch((err) => {
          setErrMessage(err.response.data.message);
          setSuccMessage();
        });
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  return (
    <div className="container">
      <h2>Add Central</h2>
      <Button variant="contained" component="label">
        Upload File
        <input hidden accept={".csv"} multiple type="file" />
      </Button>
      <form onSubmit={(e) => addNewUser(e)}>
        {inputFields.map((field) => (
          <input
            key={field.id}
            id={field.id}
            type={field.type}
            name={field.name}
            placeholder={field.placeholder}
            className="input"
            minLength={field?.minLength && field?.minLength}
            maxLength={field?.maxLength && field?.maxLength}
            onChange={(e) => {
              inputData(e);
            }}
            required={field?.required ? true : false}
          />
        ))}
        {succMessage ? (
          <Success message={succMessage} />
        ) : errMessage ? (
          <Error message={errMessage} />
        ) : (
          ""
        )}
        <button type="submit" className="btn btn-add">
          Add Central
        </button>
      </form>
    </div>
  );
};

export default AddCentral;
