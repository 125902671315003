import React from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Form from 'react-bootstrap/Form';

const MultiSelectComp = ({label,style,classes,options,groupBy,selectedVal,setSelected}) => {
  return (
    <Form.Group
    className={classes}
    controlId="class"
  >
    {label&&<Form.Label>{label}</Form.Label>}
    <Multiselect
      showArrow
      options={options}
      placeholder="Select Class"
      displayValue="class_name_section"
      groupBy={groupBy}
      showCheckbox={true}
      selectedValues={selectedVal}
      onSelect={(selectedList, selectedItem) => {
        setSelected(selectedList);
      }}
      onRemove={(selectedList, selectedItem) => {
        setSelected(selectedList);
      }}
      style={style}
    />
  </Form.Group>



  )
}

export default MultiSelectComp