import React, { useState } from "react";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import StudentAssessmentTracker from "./StudentAssessmentTracker/StudentAssessmentTracker";
import SchoolAdminAssessmentTracker from "./SchoolAdminAssessmentTracker";
import TeacherAssessmentTracker from "./TeacherAssessmentTracker";
import DistrictAssessmentTracker from "./DistrictAssessmentTracker";
import QuestionsUI from "./StudentAssessmentTracker/QuestionsUI";
import { useNavigate } from "react-router-dom";

const AssessmentTracker = () => {
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const navigate = useNavigate();
  const [selectedAssid, setSelectedAssid] = useState({
    status: false,
    id: "",
    OverAllDatas: [],
  });
  function updateAssId(e) {
    let overAllDatas = JSON.parse(e.target.dataset.datas);
    let selectedID = e.target.value;
    console.log("overAllDatas",overAllDatas,"selectedID",selectedID)
    setSelectedAssid(() => {
      return {
        status: true,
        id: selectedID,
        OverAllDatas: overAllDatas,
      };
    }, navigate(`/assessment/assessment-tracker/${selectedID}/submit`)    );
  }

  function backToTracker() {
    setSelectedAssid(() => {
      return {
        status: false,
        id: "",
        OverAllDatas: [],
      };
    });
  }

  return (
    <div
      className="ps-2 pe-2"
      style={{
        minHeight: "94vh",
        backgroundColor: "#FFF",
        paddingBottom: "40px",
      }}
      align="center"
    >
      <h1 className="p-4" style={{ fontWeight: "bold" }}>
        Assessment Tracker
      </h1>
      {userDetails.userType == "DISTRICT" && !selectedAssid.status && (
        <DistrictAssessmentTracker updateAssId={updateAssId} />
      )}
      {userDetails.userType == "SCHOOL_ADMIN" && !selectedAssid.status && (
        <SchoolAdminAssessmentTracker updateAssId={updateAssId} />
      )}
      {userDetails.userType == "TEACHER" && !selectedAssid.status && (
        <TeacherAssessmentTracker updateAssId={updateAssId} />
      )}
      {userDetails.userType == "STUDENT" && !selectedAssid.status && (
        <StudentAssessmentTracker backToTracker={backToTracker} selectedAssid={selectedAssid} updateAssId={updateAssId} />
      )}
      {/* {userDetails.userType == "STUDENT" &&selectedAssid.status&&
      <QuestionsUI backToTracker={backToTracker} selectedAssid={selectedAssid}/>
      } */}
    </div>
  );
};

export default AssessmentTracker;
