import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import ReactPlayer from "react-player";
import Api from "../../../Api/axios";

const VideoPlayer = ({url, subtitle, item, itemWiseProgress,chapterId}) => {
	const [percent, setPercent] = useState(item?.percentage);
	useEffect(() => {
		setPercent(item?.percentage);
	}, [item]);
	return (
		<div>
			<ReactPlayer
				url={url}
				className="react-player"
				controls
				width="100%"
				height="100%"
				config={{
					file: {
						attributes: {
							controlsList: 'nodownload',
							crossOrigin: 'anonymous',
						},
						tracks: [
							{ kind: 'subtitles', src: subtitle, srcLang: 'en', default: true, mode: "showing" },
						]
					}
				}}
				onContextMenu={e => e.preventDefault()}
				onProgress={(e) => {
					console.log(percent);
					if (((Math.round(e.played * 100) - percent) >= 10) || (e.played === 1)) {
						setPercent(Math.round(e.played * 100));
						itemWiseProgress(item, chapterId, Math.round(e.played * 100));
					}
				}}
				onSeek={(e) => {
					console.log(e);
				}}
			/>
		</div>
	);
};

export default VideoPlayer;