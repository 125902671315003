import React, { useState, useEffect } from 'react';
import { Box, option } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Api from "../../../Api/axios";
import { MenuItem } from "@mui/material";
import Table from 'react-bootstrap/Table';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';
import { Multiselect } from "multiselect-react-dropdown";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useNavigate } from "react-router-dom";



const AddYieCourse = () => {



    const navigate = useNavigate();
    const [
        { user, userDetails, initialState, loggedIn, class_teacher_class_details }, dispatch
    ] = useDataLayerValue();

    const [classMap, setClassMap] = useState([]);
    // initilaize classMap 1  to 12 array
    const [subjectIdCourseName, setSubjectIdCourseName] = useState('');
    const [className, setClassName] = useState('1');
    const [description, setDescription] = useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [createdCourse, setCreatedCourse] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [subjects, setSubjects] = useState([]);

    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });

        let temp = []
        for (let i = 1; i <= 12; i++) {
            temp.push({ id: i, name: i })

        }
        setClassMap(temp)
        Api.get("preconfig-entites/subjects").then((res) => {
            setSubjects(res.data);
        }).catch((err) => {
            console.log(err);
        })

        dispatch({
            type: "SET_LOADING",
            loading: false,
        });


    }, []);


    const createYieCourse = async () => {
        if (subjectIdCourseName === "") {
            alert("Please select a course name");
            return;
        }
        if (description === "") {
            alert("Please enter description");
            return;
        }
        if (thumbnail === "") {
            alert("Please upload thumbnail");
            return;
        }
        if (thumbnail.size > 10485760) {
            alert("Thumbnail size should be less than 10MB");
            return;
        }

        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let formData = new FormData();
        formData.append("file", thumbnail);
        formData.append("subject_id", subjectIdCourseName);
        formData.append("description", description);
        formData.append("class_name", className);
        await Api.post("course/create-course", formData).then((res) => {
            setCreatedCourse(res.data);
            setPageNo(2);
        }).catch((err) => {
            setPageNo(-1)
        }).finally(() => {
            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        })
    }
    const doNothing = (event) => {
        return true;
    }
    return (
        <>
            <center>
                <h1 className="m-4">Create YIE Course</h1>
                {pageNo == 1
                    ?

                    <div className="shadow-lg p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                        <Form.Group controlId="formFile" className="m-3">
                            <Stack type="vartical" gap={4}>
                                <InputGroup>
                                    <InputGroup.Text>Select Class</InputGroup.Text>
                                    <Form.Select aria-label="Select Class" value={className}
                                        onChange={(e) => setClassName(e.target.value)} >

                                        {classMap.map((cl, i) => (
                                            <option key={i} value={cl.id} style={{ width: "100%" }}>
                                                {cl.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>
                                        Course Name
                                    </InputGroup.Text>
                                    <Form.Control
                                        as="select"
                                        aria-label="Default"
                                        aria-describedby="inputGroup-sizing-default"
                                        value={subjectIdCourseName}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            console.log(subjects)
                                            setSubjectIdCourseName(e.target.value)
                                        }
                                        }
                                    >
                                        <option value="" disabled>Select Subject</option>
                                        {subjects?.map((subject, i) => (
                                            <option key={i} value={subject._id} style={{ width: "100%" }}>
                                                {subject.name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>Description</InputGroup.Text>
                                    <Form.Control as="textarea" aria-label="With textarea" aria-describedby="inputGroup-sizing-default"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Text>Thumbnail</InputGroup.Text>
                                    <Form.Control
                                        type="file"
                                        // value={uploadFile}
                                        onChange={(e) => setThumbnail(e.target.files[0])}
                                    />
                                </InputGroup>

                                {
                                    console.log(thumbnail, 'thumbnail')
                                }

                                <center style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="outline-secondary" size="lg" onClick={createYieCourse} style={{ minWidth: '150px' }} >
                                        Create YIE Course
                                    </Button>
                                </center>
                            </Stack>
                        </Form.Group>
                    </div>
                    : pageNo === -1 ?
                        <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">

                        </div>
                        :
                        <center>
                            <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mt-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
                                <img src={`${process.env.PUBLIC_URL}/Images/sucess.png`} style={{ width: '100px' }} alt="" />
                                <h3 className="m-4">Course Successfully </h3>
                                <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                                    <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => {
                                        navigate(`/course/create-yie-chapter`)
                                    }}>
                                        Add Chapters To This Course
                                    </Button>
                                    <Button variant="outline-secondary" size="lg" onhover={(e) => doNothing()} onClick={(e) => { window.location.href = window.location.origin; }} >
                                        Go to Dashboard
                                    </Button>
                                </Stack>
                            </div>


                        </center>

                }
            </center >
        </>
    );
};


export default AddYieCourse;