import React, { useEffect } from 'react'
import { useState } from 'react'
import SingleSelect from './component/SingleSelect'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import Api from '../../Api/axios';
import { Multiselect as MultiselectReactDropDown } from 'multiselect-react-dropdown'; //dynamic
import { v4 as uuidv4 } from 'uuid';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const CreateLiveClassDetails = ({userDetails,classId,
    setTime,setEveryday,everyday,setDate,createLiveClass,
    liveClassName,setLiveClassName,classMap,setClassId,
    setClassMap,userTypeList,currentUserTypeIndex,DefaultEmptyEventList,
    eventList,setEventList,AssignUsers
}) => {
 
    const [userCreateType, setUserCreateType] = useState(userTypeList[currentUserTypeIndex + 1][0]);
    const[schoolList,setSchoolList]=useState([])
    // const[classList,setClassList]=useState([])
    useEffect(()=>{
        // if(userDetails?.userType==="DISTRICT"){
            console.log("useEftter ")
           Api.get("/school-entity/school").then((res)=>{
               let SchoolList = [];
               let fetchedData=res.data
               if(userDetails?.userType==="DISTRICT"){
                fetchedData.forEach((x)=>{
                    let obj={}
                    obj.value=x._id
                    obj.label=x.name
                    SchoolList.push(obj)
                })
                setSchoolList(()=>SchoolList)       
               }
               if(userDetails.userType!=="DISTRICT"){
                console.log("fetchedData",fetchedData);
                let obj={}
                obj.value=fetchedData._id
                obj.label=fetchedData.name
                setSchoolList(()=>[obj])       
            }
           })
        // }
   },[])


   const [DefaultclassListForAdminTeacher,setDefaultclassListForAdminTeacher]=useState([])
   useEffect(()=>{
    if(userDetails.userType!=="DISTRICT"){
        console.log("again forking ==> ")
        Api.get(`/school-entity/all-classes/?school_id=${userDetails.schoolId}`).then((res)=>{
            let sectionWise=[...res.data]
            let classListFetched=[]
            sectionWise.forEach((x)=>{
                let obj={}
                obj.class_name_section=x.class_name_section
                obj.class_name=x.class_name
                obj.classid=x._id
                classListFetched.push(obj)
            }
            )
            setDefaultclassListForAdminTeacher(()=>classListFetched)
            let updated=[...eventList]
            updated=updated.map((x)=>{
                    let obj={...x}
                    if(obj?.classId.length===0){
                        obj["SelectSchool"]=userDetails.schoolId
                        obj["classOptions"]=classListFetched
                        obj["classId"]=[]
                    }
                    return obj
            })
            setEventList(()=>updated)
        })
    
    }

   },[userDetails._id])
//    const [SelectSchool, setSelectSchool] = useState("");

//    useEffect(()=>{

//     // if(SelectSchool){
//     //     console.log("SelectSchool",SelectSchool)
//     //     Api.get(`/school-entity/all-classes/?school_id=${SelectSchool}`).then((res)=>{
//     //         let sectionWise=[...res.data]
//     //         let classListFetched=[]
//     //         sectionWise.forEach((x)=>{
//     //             let obj={}
//     //             obj.class_name_section=x.class_name_section
//     //             obj.class_name=x.class_name
//     //             obj.classid=x._id
//     //             classListFetched.push(obj)
//     //         })
//     //         setClassMap(()=>classListFetched)//bug  set filter class to empty when changing school and page wise afetr completeing
//     //         setClassId(()=>[])
//     //         // setFilterUserClasses(()=>[])
//     //         // console.log(sectionWise,"<=======classListFetched=======>");
//     //     }).catch((err)=>{
//     //         console.log("err",err)
//     //     })

//     // }
  
// },[SelectSchool])


// const [filterUserClasses, setFilterUserClasses] = useState([]);
useEffect(()=>{
    console.log("eventList",eventList);
},[eventList])
   const handleSelectSchoolChange = (event,Id) => {
    console.log("handleSelectSchoolChange",Id)
    let updated=[...eventList]
    // updated=updated.map((x)=>{
    //     if(x.id===Id){
    //         let obj={...x}
    //         obj["SelectSchool"]=event.target.value
    //         console.log("obj for school",obj)
    //         return obj
    //     }else{
    //         return x
    //     }
    // })
    let selectedSchlID
    if(userDetails?.userType==="DISTRICT"){
         selectedSchlID=event.target.value
    }else{
        selectedSchlID=userDetails.schoolId
    }
    // console.log("schoolList",event.target.value);
    Api.get(`/school-entity/all-classes/?school_id=${selectedSchlID}`).then((res)=>{
        let sectionWise=[...res.data]
        let classListFetched=[]
        sectionWise.forEach((x)=>{
            let obj={}
            obj.class_name_section=x.class_name_section
            obj.class_name=x.class_name
            obj.classid=x._id
            classListFetched.push(obj)
        })
        updated=updated.map((x)=>{
            if(x.id===Id){
                let obj={...x}
                obj["SelectSchool"]=selectedSchlID
                obj["classOptions"]=classListFetched
                obj["classId"]=[]
                // console.log("obj for school",obj) 
                // console.log("schoolList",schoolList);
                return obj
            }else{
                return x
            }
        })
        setEventList(()=>updated)

    })

    // console.log("school ==> ",event.target.value)
    // setSelectSchool(event.target.value);//justy for one this.. not required
}
function AddAnotherEvent(){
    let updatedAddedEvent=[...eventList]
    if(userDetails?.userType==="DISTRICT"){
        updatedAddedEvent.push({...DefaultEmptyEventList,id:uuidv4()})
    }else{
        updatedAddedEvent.push({...DefaultEmptyEventList,id:uuidv4(),classOptions:DefaultclassListForAdminTeacher})
    }
    setEventList(()=>updatedAddedEvent)
    // console.log("adding ")
}

function addSelected(id,selectedItem){
    let updated=[...eventList]
    updated=updated.map((x)=>{
        if(x.id===id){
            // console.log("working",x,id)
            let obj={...x}
            let updatedArray=[...obj.classId]
            // updatedArray=updatedArray.filter((classObj)=>{ //not filterig for id spell mis
            //   return classObj.classid!==selectedItem.classid
            // })
            updatedArray.push(selectedItem)
            obj.classId=updatedArray
            return obj
        }else{
            return x
        }
    })
    console.log("add ed updated ==> ",updated)
    setEventList(()=>updated)

}


function removeSelected(id,selectedItem){
    let updated=[...eventList]
    updated=updated.map((x)=>{
        if(x.id===id){
            let obj={...x}
            let updatedArray=[...obj.classId]
            // console.log("del",updatedArray,"id",id,"selectedItem",selectedItem)
            updatedArray=updatedArray.filter((classObj)=>{
                return classObj.classid!==selectedItem.classid
            })
            obj.classId=updatedArray
            return obj
        }else{
            return x
        }
    })
    console.log("removed updated ==> ",updated)
    setEventList(()=>updated)

}

function removeEvent(selectedId){
    let updated=[...eventList]
    updated=updated.filter((x)=>{
        return x.id!==selectedId
        })
        setEventList(()=>updated) 
    // console.log("setEventList=null",updated); 
        // AssignUsers(updated)
}

    return (
    <>
    <div className="p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
{/* <div > */}
{eventList.map((option)=>{
    let {id,userCreateType,userTypeList,currentUserTypeIndex,
        SelectSchool,classOptions,classId,
        // schoolList
    }=option
        // console.log("option.userCreateType",option.userCreateType)
return(
    <Form.Group key={id} style={{ borderRadius: 10}} controlId="formFile" className="m-3 p-4 shadow-lg mb-4">
    <Stack type="vartical" gap={4}>
    <SingleSelect  
            setEventList={setEventList}
            className={"w-100"}
            label="Select User Type"
            id={id}
            eventList={eventList}
            userCreateType={userCreateType}
            userTypeList={userTypeList}
            currentUserTypeIndex={currentUserTypeIndex}
            labelClassName='d-flex justify-content-start'
            setUserCreateType={setUserCreateType}//as now no use
            />
        {userDetails.userType==="DISTRICT"&&
        <InputGroup>
                <InputGroup.Text>Select School List</InputGroup.Text>
                <Form.Select aria-label="Select School List" 
                value={SelectSchool}
                 onChange={e => handleSelectSchoolChange(e,id)}
                     >
                    <option key="ALL" value="" style={{ width: "100%" }}>
                        Choose here 
                    </option>
                    {schoolList.map((option, i) => (
                        <option key={option.value} value={option.value} style={{ width: "100%" }}>
                            {option.label}
                        </option>
                    ))}
                </Form.Select>
                </InputGroup>

        }
           {userDetails.userType!=="DISTRICT"&&  //mea=ns studemnt and taecher
        <InputGroup>
                <InputGroup.Text>Select School List</InputGroup.Text>
                <Form.Select aria-label="Select School List" 
                value={SelectSchool}
                 onChange={e => handleSelectSchoolChange(e,id)}
                     >
                    {/* <option key="ALL" value="" style={{ width: "100%" }}>
                        Choose here 
                    </option> */}
                    {/* {console.log("schoolList",schoolList)} */}
                    {/* {schoolList.map((option, i) => ( */}
                        <option  value={schoolList[0]?.value} style={{ width: "100%" }}>
                            {schoolList[0]?.label}
                        </option>
                    {/* ))} */}
                </Form.Select>
                </InputGroup>

        }
        {(option.userCreateType!=="SCHOOL_ADMIN"||userDetails.userType!=="DISTRICT")&&
                <InputGroup>
                <InputGroup.Text>Select Class</InputGroup.Text>
                <MultiselectReactDropDown
                showArrow
                name="multiSelectReact"
                style={{searchBox:
                    {maxWidth:"280px",width:"250px",//border:"none",borderBottom:"1px solid rgba(0, 0, 0, 0.5)"
                    borderRadius:"0px"}}}
                options={classOptions}
                displayValue="class_name_section"
                // className="mt-2"
                placeholder="&nbsp;&nbsp;&nbsp;Select the Class"
                groupBy="class_name"
                showCheckbox={true}
                selectedValues={option.classId}//..........
                onSelect={(selectedList, selectedItem) => { 
                    addSelected(id,selectedItem)
                    // console.log("selectedLis",selectedList,"selectedItem",selectedItem)
                    // setClassId(selectedList)
                 }}
                onRemove={(selectedList, selectedItem) => { 
                    // console.log("removed",selectedList,"selectedItem",selectedItem)
                    removeSelected(id,selectedItem)
                }}
                />
                </InputGroup>
        }

            <center style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <IconButton
                        disabled={eventList.length===1}
                        onClick={()=>removeEvent(id)}
                        // onClick={e => setQuesInputFields(inputQuesFields.map(inputQues => (inputQues.id === inputQuesField.id ? { ...inputQues, options: inputQues.options.filter(quesOptionVar => quesOptionVar.id !== quesOption.id) } : inputQues)))}
                        >
                        <DeleteForeverIcon />
                        </IconButton>
            </center>
                    
    </Stack>
</Form.Group>
)
})
}   
{/* </div>   */}
        <center className='mb-5' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button  className='ms-3' variant="outline-secondary" size="lg" onClick={()=>AddAnotherEvent()} style={{ minWidth: '150px' }} >
                    Add Another Event
                </Button>
                <Button className='ms-3' variant="outline-secondary" size="lg" onClick={()=>AssignUsers()} style={{ minWidth: '150px' }} >
                    Assign users
                </Button>
            </center>      
</div>



</>
  )
}

export default CreateLiveClassDetails