import React from 'react'

const TableRowColumn = ({isSubmitted,showAll}) => {
  return (
    <thead>
    <tr>
     <th  scope="col" style={{ minWidth: "10px" }}>
       <p className="d-flex justify-content-center align-items-center">S.no</p> 
      </th>
      <th scope="col" style={{ minWidth: "80px" }}>
      <p className="d-flex justify-content-center align-items-center">Title</p> 
      </th>
      <th scope="col" style={{ minWidth: "50px" }}>
      <p className="d-flex justify-content-center align-items-center"> No. of Questions</p> 
      </th>
      <th  scope="col" style={{ minWidth: "180px" }}>
      <p className="d-flex justify-content-center align-items-center">Created by</p> 
        
      </th>
      <th scope="col" style={{ minWidth: "180px" }}>
      <p className="d-flex justify-content-center align-items-center">Created At</p> 
      </th>
      <th scope="col" style={{ minWidth: "180px" }}>
      <p className="d-flex justify-content-center align-items-center">Ended At</p> 
        
      </th>
      {!showAll &&
        <th scope="col" style={{ minWidth: "180px" }}>
        <p className="d-flex justify-content-center align-items-center">{isSubmitted?"View ":"Start "} Assessment</p> 
        </th>
      }
    
    </tr>
  </thead>

  )
}

export default TableRowColumn