import React, { useState, useEffect } from "react";
import "./notices_student.css";
import Notice_Board from "../Notice_Board";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";

function Notices_Student() {
  const [notices, setNotices] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const fetchNotices = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    await Api.get("/notices")
      .then((res) => {
        let tempNotices = [];
        res.data.forEach((notice) => {
          if (notice.notice_for === userDetails.userType) {
            tempNotices.push(notice);
          }
        });
        setNotices(tempNotices);
      })
      .catch((err) => console.log(err.response.data.message));
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  useEffect(() => {
    fetchNotices();
  }, []);

  return (
    <div className="Notices_Student">
      <div className="notices-student-container">
        <Notice_Board notices={notices} />
        <button className="notices-student-btn" onClick={() => fetchNotices()}>
          Update Notices
        </button>
      </div>
    </div>
  );
}

export default Notices_Student;
