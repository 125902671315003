import { React, useState, useEffect } from 'react';
import { Form, Button, Table, Stack, Modal } from 'react-bootstrap';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import DatePicker from "react-datepicker";
import Api from '../../Api/axios';
import "../Attendance/Attendance.css";
import Html5QrcodePlugin from '../Attendance/Html5QrcodePlugin.jsx';
const MultiUserWatching = ({ submitValueChange }) => {
    const userTypes = [
        ['DISTRICT', 'District'],
        ['SCHOOL_ADMIN', 'School Admin'],
        ['TEACHER', 'Teacher'],
        ['STUDENT', 'Student'],
    ]
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const currentUserTypeIndex = userTypes.findIndex((userType) => userType[0] === userDetails?.userType);
    const [selectedUserType, setSelectedUserType] = useState(userTypes[currentUserTypeIndex + 1][0]);
    const [userList, setUserList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [selectedClass, setSelectedClass] = useState(['ALL']);
    const [searchString, setSearchString] = useState('');
    const [scanText, setScanText] = useState('');
    const [forceRefresh, setForceRefresh] = useState(false);
    const [scanTextFinal, setScanTextFinal] = useState('');
    const [qrScanOpen, setQrScanOpen] = useState(false);
    function onScanSuccess(decodedText, decodedResult) {
        userList.map(async (user) => {
            if (user.conventionalId === decodedText || user._id === decodedText || user.rfid === decodedText) {
                userList[user["index"]].isChecked = true
                setForceRefresh(!forceRefresh);
                setScanTextFinal("Last Marked: " + user.conventionalId);
            }
        })
    }
    const [scanOpen, setScanOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [initialUserList, setInitialUserList] = useState(
        JSON.parse(localStorage.getItem("usersLoggedIn")).map((user, index) => {
            return user?._id
        })
    );
    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        Api.get(`/user/users-data?userType=${(selectedUserType === userDetails?.userType) ? userTypes[userTypes.findIndex((userType) => userType[0] === userDetails?.userType) + 1][0] : selectedUserType}` + (selectedClass ?
            selectedClass.map((classes, index) => {
                if (classes !== "ALL") {
                    return `&classes[${index}]=${classes}`
                }
            }).join('')
            : "")).then((res) => {
                setUserList(res.data.map((user, index) => {
                    user["index"] = index;
                    user["isChecked"] = initialUserList?.includes(user._id);
                    return user;
                }));
            }).finally(() => {
                dispatch({
                    type: "SET_LOADING",
                    loading: false,
                });
            })
    }, [selectedUserType, selectedClass, currentUserTypeIndex, userDetails]);
    useEffect(() => {
        if (userDetails?.userType !== 'DISTRICT') {
            dispatch({
                type: "SET_LOADING",
                loading: true,
            });
            Api.get("live-class").then((res) => {
                res.data.push({ _id: "ALL", class_name_section: "ALL" })
                setClassList(res.data);
            }).finally(() => {
                dispatch({
                    type: "SET_LOADING",
                    loading: false,
                });
            });
        }
    }, [selectedUserType]);
    const [submitTrue, setSubmitTrue] = useState(false);
    const [submitData, setSubmitData] = useState({});
    const handleSubmit = (e) => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        var usersLoggedIn = userList.filter((user) => user.isChecked)
        console.log(usersLoggedIn)
        localStorage.setItem("usersLoggedIn", JSON.stringify(usersLoggedIn));
        submitValueChange(false)
        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    }
    return (
        <div className='ps-2 pe-2' style={{
            // width: '100%',
            minHeight: '94vh',
            backgroundColor: '#FFF',
            paddingBottom: '40px'
        }}>
            <Modal show={submitTrue} onHide={() => setSubmitTrue(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Users Added</Modal.Title>
                </Modal.Header>
                <Modal.Body align="center">
                    <h1>Users Added</h1>
                    {/* <p>Attendance for {submitData?.attendee_type} has been marked with {submitData?.absent_count} useres marked as Absent & {submitData?.present_count} users marked as Present.</p> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSubmitTrue(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Form>
                <div className="row">
                    <Form.Group controlId="UserType" className={"pt-2 pb-2 " + ((userDetails?.userType !== 'DISTRICT' && (selectedUserType === 'STUDENT' || selectedUserType === 'TEACHER')) ? "col-6 col-md-4" : "col-6")}>
                        <Form.Label>User Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedUserType}
                            onChange={(e) => setSelectedUserType(e.target.value)}
                            style={{
                                minHeight: "55px"
                            }}
                            required
                        >
                            {userTypes.map((userType, index) => {
                                if (index > currentUserTypeIndex) {
                                    return <option key={userType[0]} value={userType[0]}>{userType[1]}</option>
                                }
                            })}
                        </Form.Control>
                    </Form.Group>
                    {
                        (userDetails?.userType !== 'DISTRICT' && (selectedUserType === 'STUDENT' || selectedUserType === 'TEACHER')) ?
                            <Form.Group controlId="ClassSection" className="pt-2 pb-2 col-12 col-md-4">
                                <Form.Label>Class</Form.Label>
                                {/* <Multiselect
                                    showArrow
                                    options={classList}
                                    displayValue="class_name_section"
                                    groupBy="class_name"
                                    showCheckbox={true}
                                    selectedValues={selectedClass}
                                    onSelect={(selectedList, selectedItem) => { setSelectedClass(selectedList) }}
                                    onRemove={(selectedList, selectedItem) => { setSelectedClass(selectedList) }}
                                /> */}
                                <Form.Control
                                    as="select"
                                    value={selectedClass ? selectedClass[0] : "ALL"}
                                    onChange={(e) => setSelectedClass([e.target.value])}
                                    style={{
                                        minHeight: "55px"
                                    }}
                                    required
                                >
                                    {
                                        classList.map((classes) => {
                                            return <option key={classes._id} value={classes._id}>{classes.class_name_section}</option>
                                        })
                                    }
                                </Form.Control>
                            </Form.Group>
                            : null
                    }
                </div>
                <center>
                    {/* <Button
                        variant="primary"
                        className="mt-2 mb-4"
                        onClick={handleGetUsers}
                        align="center"
                    >
                        Show Users
                    </Button> */}
                </center>
            </Form>
            <Modal show={scanOpen} onHide={() => { setScanOpen(false) }} size="lg"
                onShow={() => {
                    var input = document.getElementById('myIdInput');
                    console.log(input, "okk");
                    input.focus();
                    input.select();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Scan ID Card</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    maxHeight: "calc(100vh - 110px)",
                    overflow: "auto"
                }}>
                    <div className="p-2" align="center">
                        {scanTextFinal}
                    </div>
                    {/* 
                    <Html5QrcodePlugin
                        fps={1}
                        qrbox={250}
                        disableFlip={false}
                        qrCodeSuccessCallback={onScanSuccess} /> */}
                    <Form>
                        <Form.Group>
                            <Form.Label>Enter ID</Form.Label>
                            <Form.Control
                                id="myIdInput"
                                type="text"
                                placeholder="Enter ID"
                                value={scanText}
                                onChange={(e) => setScanText(e.target.value)}
                            />
                        </Form.Group>
                        <Button
                            className="mt-4 mb-4"
                            variant="primary"
                            onClick={(e) => {
                                var text = scanText;
                                // setScanTextFinal(text)
                                setScanText("")
                                e.preventDefault()
                                onScanSuccess(text, {})
                                return false
                            }}
                            type="submit"
                            style={{
                                width: "100%"
                            }}
                        >
                            Mark ID
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={qrScanOpen} onHide={() => { setQrScanOpen(false) }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Scan QR Code</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    maximumHeight: "calc(100vh - 210px)",
                    overflowY: "auto"
                }}>
                    <div className="p-2" align="center">
                        {scanTextFinal}
                    </div>
                    <Html5QrcodePlugin
                        fps={1}
                        qrbox={250}
                        disableFlip={false}
                        qrCodeSuccessCallback={onScanSuccess} />
                </Modal.Body>
            </Modal>
            <div className='mt-2'>
                {/* <h3 align="center" style={{fontWeight:"bold"}}>List of Users</h3> */}
                <div
                    style={{
                        marginBottom: '20px',
                    }}
                >
                    <Stack gap={2} direction="horizontal">
                        <Form.Control
                            placeholder="Search"
                            value={searchString}
                            onChange={(e) => setSearchString(e.target.value)}
                            style={{
                                minHeight: "55px"
                            }}
                        />
                        <Button
                            variant="secondary"
                            style={{
                                whiteSpace: "nowrap",
                                minHeight: "55px"
                            }}
                            onClick={() => {
                                userList.map((user) => {
                                    userList[user["index"]].isChecked = true
                                })
                                setForceRefresh(!forceRefresh)
                            }
                            }
                        >
                            Select All
                        </Button>
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                        <Button
                            variant="secondary"
                            style={{
                                whiteSpace: "nowrap",
                                width: "50%",
                                marginTop: "10px",
                                minHeight: "55px"
                            }}
                            onClick={
                                () => {
                                    setScanOpen(true);
                                }
                            }
                        >
                            Scan ID Card
                        </Button>
                        <Button
                            variant="secondary"
                            style={{
                                whiteSpace: "nowrap",
                                width: "50%",
                                marginTop: "10px",
                                minHeight: "55px"
                            }}
                            onClick={
                                () => {
                                    setQrScanOpen(true);
                                }
                            }
                        >
                            Scan QR Code
                        </Button>
                    </Stack>
                    <Button
                        variant="secondary"
                        style={{
                            whiteSpace: "nowrap",
                            width: "100%",
                            marginTop: "10px",
                            minHeight: "55px"
                        }}
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Sr. No.</th>
                            <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Name</th>
                            <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Conventional Id</th>
                            <th className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>Attendance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userList.map((user, index) => {
                                if (searchString === "" || user.name.toLowerCase().includes(searchString.toLowerCase()) || user.conventionalId.toLowerCase().includes(searchString.toLowerCase())) {
                                    return (
                                        <tr key={user._id}>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{index + 1}</td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{user.name}</td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>{user.conventionalId}</td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={user.isChecked}
                                                    id={user._id}
                                                    onChange={(e) => {
                                                        userList[user["index"]].isChecked = e.target.checked;
                                                        setForceRefresh(!forceRefresh);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default MultiUserWatching;