import React, { useState } from "react";
import { Box, MenuItem, Multiselect } from "@mui/material";
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
// import Quiz from "../../../Quiz/Quiz";
// import FormControl from "@mui/material/FormControl";
// import "./Library.css";
import { withStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
// import SelectField from '@material-ui/SelectField';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { makeStyles } from "@material-ui/core/styles";
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SettingsIcon from '@material-ui/icons/Settings';
// import { DateTimePicker } from '@material-ui/pickers'
// import * as React from 'react';
// import dayjs, { Dayjs } from 'dayjs';
// import TextField from '@mui/material/TextField';
// import DateTimePicker from 'react-datetime-picker'
import dayjs, { Dayjs } from 'dayjs';
// import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
let classes = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];
let subjects = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];
let chapters = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];
let topics = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];
let subTopics = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];
const difficultyLevels = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];
function Library() {
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            className="mb-5"
        >
            df
        </Box>
    );
}

export default Library;