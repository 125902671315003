import React, { useState, useEffect } from 'react';
import Api from '../../Api/axios';
import { Stack } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table, Button, Form, Col, Row } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import GetUsers from './GetUsers';

const MarkRFID = (data) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [districtId, setDistrictId] = useState(data.districtId ? data.districtId : (params.districtId ? params.districtId : (userDetails ? userDetails?.districtId : null)));
    const [schoolId, setSchoolId] = useState(data.schoolId ? data.schoolId : (params.schoolId ? params.schoolId : (userDetails ? userDetails?.schoolId : null)));
    useEffect(() => {
        console.log("params", params);
        console.log("data", data);
        console.log("userDetails", userDetails);
        setDistrictId(data.districtId ? data.districtId : (params.districtId ? params.districtId : (userDetails ? userDetails?.districtId : null)));
        setSchoolId(data.schoolId ? data.schoolId : (params.schoolId ? params.schoolId : (userDetails ? userDetails?.schoolId : null)));
    }, [data, params, userDetails]);
    return (
        <div style={{ backgroundColor: 'white', padding: '10px' }}>
            <h1 className='p-4' style={{ textAlign: 'center', fontWeight: 'bold' }}>Mark RFID</h1>
            <GetUsers
                districtId={districtId}
                schoolId={schoolId}
                markRFID={true}
            />
            { console.log(districtId, schoolId) }
        </div>
    )
}

export default MarkRFID;