import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Box, MenuItem } from "@mui/material";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from "../../../Api/axios";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Multiselect } from "multiselect-react-dropdown";

const ShowUploadedNotices = () => {
  const documentTypes = [
    ["Academic", "Academic"],
    ["Personal", "Personal"],
    ["Administrative", "Administrative"],
    ["Others", "Others"],
  ];
  const [userTypes, setUserTypes] = useState([
    {
      id: "DISTRICT",
      value: "District",
    },
    {
      id: "SCHOOL_ADMIN",
      value: "School Admin",
    },
    {
      id: "TEACHER",
      value: "Teacher",
    },
    {
      id: "STUDENT",
      value: "Student",
    },
  ]);
  const [classDict, setClassDict] = useState([]);
  const [sectionDict, setSectionDict] = useState({ ALL: [] });
  const [subjectDict, setSubjectDict] = useState([]);
  const [chapterDict, setChapterDict] = useState([]);
  const [topicDict, setTopicDict] = useState([]);
  const [subTopicDict, setSubTopicDict] = useState([]);
  const [userDict, setUserDict] = useState([]);

  const [documentType, setDocumentType] = useState("ALL");
  const [ownerUserType, setOwnerUserType] = useState("ALL");
  const [ownerUser, setOwnerUser] = useState("ALL");
  const [recipientUser, setRecipientUser] = useState("ALL");
  const [className, setClassName] = useState("ALL");
  const [subjectName, setSubjectName] = useState("ALL");
  const [chapterName, setChapterName] = useState("ALL");
  const [topicName, setTopicName] = useState("ALL");
  const [subTopicName, setSubTopicName] = useState("ALL");
  const [
    { user, userDetails, initialState, loggedIn, class_teacher_class_details },
  ] = useDataLayerValue();
  const currentUserTypeIndex = userTypes.findIndex(
    (userType) => userType[0] === userDetails?.userType
  );
  const [change, setChange] = useState(true);
  const [files, setFiles] = useState([]);
  const [{ type }, dispatch] = useDataLayerValue();
  const [fileC, setFileC] = useState([]);
  const [sectionName, setSectionName] = useState("ALL");
  React.useEffect(async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    setFiles([]);
    await Api.get("notices", { params: { created_by: userDetails._id } }).then(
      (res) => {
        setFiles(res.data);
        setFileC(res.data);
      }
    );
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  }, []);
  // React.useEffect(async () => {
  // 	dispatch({
  // 		type: "SET_LOADING",
  // 		loading: true,
  // 	});
  // 	setFiles([]);
  // 	await Api.get("notices", { params: { notice_for_user: [userDetails?._id], } }).then((res) => {
  // 		setFiles(res.data);
  // 	})
  // 	dispatch({
  // 		type: "SET_LOADING",
  // 		loading: false,
  // 	});
  // }, [change]);
  React.useEffect(async () => {
    let temp_dict = { ALL: { ALL: [] } };
    await Api.get("live-class").then((res) => {
      setClassDict(res.data);
    });
    // console.log(temp_dict);
  }, []);
  // React.useEffect(() => {
  // React.useEffect(() => {
  // 	Api.get("live-class/subject").then((res) => {
  // 		setSubjectDict(res.data);
  // 	})
  // }, []);
  useEffect(() => {
    console.log("change");
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    const data = {
      created_by: userDetails?._id,
      // userType: ownerUserType === "ALL" ? "" : ownerUserType,
      class_data_names: className === "ALL" ? "" : [className],
    };
    Api.get("notices", { params: data })
      .then((res) => {
        setFiles(res.data);
        // console.log(res.data);
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      });
  }, [className]);
  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
    if (event.target.value === "ALL") {
      files = fileC;
    } else {
      // filter files by usertype = ownertype
      files = files.filter((file) => file.userType === event.target.value);
    }
  };
  const handleOwnerUserTypeChange = (event) => {
    setOwnerUserType(event.target.value);
  };
  const handleClassChange = async (e) => {
    setClassName(e.target.value);
    // filter files by class_name_data array including class_name
    // files.filter((file) => file.class_name_data.includes(e.target.value));
  };
  const handleSubjectChange = async (e) => {
    setSubjectName(e.target.value);
  };

  const handleDeleteUploadedNotices = (id) => {
    console.log(id)
    // Api.delete("/" + user + id).then((res) => {
    //   alert("sucessfully deleted")
    // })
  };

  const [schoolList, setSchoolList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [filterDocumentType, setFilterDocumentType] = useState("");
  const [filterRecipientUserType, setFilterRecipientUserType] = useState("");
  const [filterOwnerUserType, setFilterOwnerUserType] = useState("");
  const [filterSchool, setFilterSchool] = useState("");
  const [filterClass, setFilterClass] = useState([]);
  const [filterSubject, setFilterSubject] = useState("");
  const [documentsList, setDocumentsList] = useState([]);
  useEffect(() => {
    Api.get("/school-entity/school").then((response) => {
      if (userDetails?.userType == "DISTRICT") {
        response.data.sort((a, b) => a.name.localeCompare(b.name));
        setSchoolList(response.data);
      } else {
        setSchoolList([response.data]);
        setFilterSchool(response.data._id);
      }
    });
  }, [userDetails]);
  useEffect(() => {
    if (filterSchool !== "") {
      Api.get("school-entity/all-classes?school_id=" + filterSchool).then(
        (response) => {
          setClassList(response.data);
        }
      );
    } else {
      setClassList([]);
    }
  }, [filterSchool]);
  useEffect(() => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    Api.get("notices", {
      params: {
        school_ids: filterSchool ? [filterSchool] : null,
        class_data_names:
          filterClass.length > 0
            ? filterClass.map((classData) => classData.class_name_section)
            : null,
        subject_id: filterSubject ? filterSubject : null,
        ownerType: filterOwnerUserType ? filterOwnerUserType : null,
        type: filterDocumentType ? filterDocumentType : null,
        userType: filterRecipientUserType ? filterRecipientUserType : null,
        created_by: userDetails._id,
      },
    })
      .then((response) => {
        setDocumentsList(response.data);
        console.log(response.data);
      })
      .finally(() => {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      });
  }, [
    filterDocumentType,
    filterRecipientUserType,
    filterOwnerUserType,
    filterSchool,
    filterClass,
    filterSubject,
  ]);

  return (
    <div
      className="ps-2 pe-2"
      style={{
        // width: '100%',
        minHeight: "94vh",
        backgroundColor: "#FFF",
        paddingBottom: "40px",
      }}
      align="center"
    >
      <h1 className="p-4" style={{ fontWeight: "bold" }}>
        {" "}
        List of Your Uploaded Notices{" "}
      </h1>
      <div className="m-4 row" style={{ justifyContent: "center" }}>
        <Form
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Form.Group
            controlId="ownerUserType"
            style={{
              padding: "10px",
            }}
          >
            <Form.Label>Notice by</Form.Label>
            <Form.Control
              as="select"
              value={filterOwnerUserType}
              onChange={(e) => setFilterOwnerUserType(e.target.value)}
              style={{
                minWidth: "250px",
                maxWidth: "300px",
                minHeight: "55px",
              }}
            >
              <option value="">ALL</option>
              {userTypes.map((userType) => {
                return <option value={userType.id}> {userType.value} </option>;
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group
            controlId="recipientUserType"
            style={{
              padding: "10px",
            }}
          >
            <Form.Label>Notice for</Form.Label>
            {/* <Multiselect
                        showArrow
                        labelledBy={"Select User Types"}
                        options={userTypes}
                        displayValue="value"
                        showCheckbox={true}
                        selectedValues={filterRecipientUserType}
                        onSelect={(selectedList, selectedItem) => { setFilterRecipientUserType(selectedList) }}
                        onRemove={(selectedList, selectedItem) => { setFilterRecipientUserType(selectedList) }}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                        }}
                    /> */}
            <Form.Control
              as="select"
              value={filterRecipientUserType}
              onChange={(e) => setFilterRecipientUserType(e.target.value)}
              style={{
                minWidth: "250px",
                maxWidth: "300px",
                minHeight: "55px",
              }}
            >
              <option value="">ALL</option>
              {userTypes.map((userType) => {
                return <option value={userType.id}> {userType.value} </option>;
              })}
            </Form.Control>
          </Form.Group>
          <Form.Group
            controlId="school"
            style={{
              padding: "10px",
            }}
          >
            <Form.Label>School</Form.Label>
            <Form.Control
              as="select"
              value={filterSchool}
              onChange={(e) => setFilterSchool(e.target.value)}
              style={{
                minWidth: "250px",
                maxWidth: "300px",
                minHeight: "55px",
              }}
              disabled={userDetails?.userType === "DISTRICT" ? false : true}
            >
              <option value="">ALL</option>
              {schoolList.map((school) => {
                return <option value={school._id}> {school.name} </option>;
              })}
            </Form.Control>
          </Form.Group>
          {classList.length > 0 && (
            <Form.Group
              controlId="class"
              style={{
                padding: "10px",
              }}
            >
              <Form.Label>Class</Form.Label>
              <Multiselect
                labelledBy={"Select"}
                showArrow
                options={classList}
                displayValue="class_name_section"
                groupBy="class_name"
                showCheckbox={true}
                selectedValues={filterClass}
                onSelect={(selectedList, selectedItem) => {
                  setFilterClass(selectedList);
                }}
                onRemove={(selectedList, selectedItem) => {
                  setFilterClass(selectedList);
                }}
                style={{
                  minWidth: "250px",
                  maxWidth: "300px",
                }}
              />
            </Form.Group>
          )}
        </Form>
        {/* <Form.Group className="p-2 col-12 col-sm-6 col-lg-3" align="left">
          <Form.Label align="left">Notice By</Form.Label>
          <Form.Control
            as="select"
            label="Document Type"
            // variant="filled"
            value={className}
            onChange={handleClassChange}
          >
            <option key="ALL" value="ALL" style={{ width: "100%" }}>
              ALL
            </option>
            {classDict.map((cl, i) => (
              <option
                key={cl._id}
                value={cl.class_name_section}
                style={{ width: "100%" }}
              >
                {cl.class_name_section}
              </option>
            ))}
          </Form.Control>
        </Form.Group> */}
        {/* <div>
                <TextField
                    select
                    label="Class"
                    variant="filled"
                    style={{ minWidth: 230 }}
                    className="m-2"
                    value={className}
                    onChange={handleClassChange}
                >
                    <MenuItem key="ALL" value="ALL" style={{ width: "10%" }}>
                        ALL
                    </MenuItem>
                    {
                        classDict.map((cl, i) => {
                            return (
                                <MenuItem key={cl._id} value={cl.class_name_section} style={{ width: "10%" }}>
                                    {cl.class_name_section}
                                </MenuItem>
                            )
                        })
                    }
                </TextField> */}
      </div>
      {/* <TextField
					select
					label="Document Type"
					variant="filled"
					style={{ minWidth: 230 }}
					value={documentType}
					onChange={handleDocumentTypeChange}
					className="m-2"
				>
				<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
						ALL
					</MenuItem>
					{documentTypes.map((documentType) => (
						<MenuItem key={documentType[0]} value={documentType[0]} style={{ width: "100%" }}>
						{documentType[1]}
						</MenuItem>
						))}
					</TextField> */}
      {/* <div> */}
      {/* <TextField
					select
					label="Notice By"
					variant="filled"
					style={{ minWidth: 230 }}
					className="m-2"
					value={ownerUserType}
					onChange={handleOwnerUserTypeChange}
				>
					<MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
						ALL
					</MenuItem>
					{
						userTypes.map((userType, i) => {
							return (
								<MenuItem key={userType[0]} value={userType[0]} style={{ width: "100%" }}>
									{userType[1]}
								</MenuItem>
							)
						})
					}
				</TextField>*/}

      {/* <> */}
      {/* <TextField
                    select
                    label="For Class"
                    variant="filled"
                    style={{ minWidth: 230 }}
                    className="m-2"
                    value={className}
                    onChange={handleClassChange}
                >
                    <MenuItem key="ALL" value="ALL" style={{ width: "100%" }}>
                        ALL
                    </MenuItem>
                    {
                        classDict.map((cl, i) => {
                            return (
                                <MenuItem key={cl._id} value={cl._class_name_section} style={{ width: "100%" }}>
                                    {cl.class_name_section}
                                </MenuItem>
                            )
                        })
                    }
                </TextField> */}
      {/* </> */}

      {/* </>
				}*/}
      {/* </div> */}
      {/* <Button className="m-3" variant="secondary" onClick={filterChange} style={{ minWidth: "100px" }}>
				Filter
			</Button> */}
      <div className="m-4">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col" style={{ minWidth: "80px" }}>
                Sr. No.
              </th>
              <th scope="col" style={{ minWidth: "200px" }}>
                Notice Name
              </th>
              <th scope="col" style={{ minWidth: "280px" }}>
                Notice Description
              </th>
              <th scope="col" style={{ minWidth: "200px" }}>
                Notice Date
              </th>
              {/* {console.log(userDetails?.userType , 	(userDetails?.userType === "TEACHER" || userDetails?.userType === "STUDENT"))} */}
              {(userDetails?.userType === "TEACHER" ||
                userDetails?.userType === "STUDENT") && (
                <th scope="col" style={{ minWidth: "200px" }}>
                  Class
                </th>
              )}
              <th scope="col" style={{ minWidth: "140px" }}>
                View
              </th>
              <th scope="col" style={{ minWidth: "140px" }}>
                delete
              </th>
            </tr>
          </thead>
          <tbody>
            {console.log(files, "files")}
            {/* {setFileC(0)} */}
            {files.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.title}</td>
                  <td>{item.description}</td>
                  <td>{item.date}</td>
                  {(userDetails?.userType === "TEACHER" ||
                    userDetails?.userType === "STUDENT") && (
                    <td>
                      {item.class_data_names
                        ? item.class_data_names
                            .map((name) => {
                              return name;
                            })
                            .join(" ,")
                        : "ALL"}
                    </td>
                  )}
                  <td>
                    <Button
                      variant="light"
                      onClick={() => window.open(item.link, "_blank")}
                    >
                      View
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="light"
                      onClick={() => handleDeleteUploadedNotices(item._id)}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ShowUploadedNotices;
