export const UserTypes = (userDetails) => {
let userTypes 
if(userDetails.userType==="DISTRICT"){
     userTypes = [
        {
            value: 'Teacher',
            label: 'Teacher'
        },
        {
            value: 'SchoolAdmin',
            label: 'School Admin'
        }, 
          {
            value: 'Student',
            label: 'Student'
        }
    ];
}else if(userDetails.userType==="SCHOOL_ADMIN"){
    userTypes = [
        {
            value: 'Teacher',
            label: 'Teacher'
        },
          {
            value: 'Student',
            label: 'Student'
        }
    ];

}else if(userDetails.userType==="TEACHER"){
    userTypes = [
          {
            value: 'Student',
            label: 'Student'
        }
    ];

}
return userTypes
}
export let difficultyLevels = [
    {
        value: 'ALL',
        label: 'ALL'
    },
    {
        value: 'EASY',
        label: 'EASY'
    },
    {
        value: 'MEDIUM',
        label: 'MEDIUM'
    }
  
];

export function ChangeStandardWords(val){
if(val==="Teacher"){
return "TEACHER"
}else if(val==="Student"){
    return "STUDENT"
}else if(val==="SchoolAdmin"){
    return "SCHOOL_ADMIN"
}


}
export let difficultyLevelsQues = [
    {
        value: 'ALL',
        label: 'ALL'
    },
    {
        value: 'EASY',
        label: 'EASY'
    },
    {
        value: 'MEDIUM',
        label: 'MEDIUM'
    }
];
export let chapters = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];
export let topics = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];
export let subTopics = [
    {
        value: 'ALL',
        label: 'ALL'
    }
];

export const AssigningShowByOrder=[{
    item:"userType",
    value:"1",
    selected:true
},
{
    item:"SelectSchool",
    value:"2",
    selected:false
},
{
    item:"multiSelectReact",
    value:"3",
    selected:false
}
]

export const AssessmentShowByOrder=[{
    item:"assesmentSubject",
    value:"1",
    selected:true
},
{
    item:"assesmentChapter",
    value:"2",
    selected:false
},
{
    item:"assesmentTopic",
    value:"3",
    selected:false
}
]
//{ //as of now no subtopic 
//     item:"assesmentSubTopic",
//     value:"4",
//     selected:false
// }

export const questionTypes=[
    {
        value:"MCQ",
        description:"Multiple Choice Question",
        options:[{value:"MCQ",
        description:"Multiple Choice Question"
    }]
    }
,{
    value:"Subjective",
    description:"Subjective Question",
    options:[{value:"Subjective",
    description:"Subjective Question"
}]
},{
    value:"MixedMcqSub",
    description:"MCQ and Subjective",
    options:[{value:"MCQ",
    description:"Multiple Choice Question",
},
{value:"MSQ",
description:"Multiple answer Question"},
{value:"Subjective",
description:"Subjective Question"},
{value:"DocumentUpload",
description:"Document Upload",
}]
},{
    value:"DocumentUpload",
    description:"Document Upload",
    options:[{value:"DocumentUpload",
    description:"Document Upload",
}]
}
]

export const AddtionalQuesFormat=(quesId,optID1,
    optID2,assessmentType)=>{
    let AddtionalQuesDefault={
        id:quesId,
        description: "",
        questionType:assessmentType[0]?.value=="MixedMcqSub"?"MCQ":assessmentType[0]?.value,
        difficulty: "",
        correctAnswer: "",
        correctMarks: 0,
        incorrectMarks: 0,
        wordsLimit:0,
        isSpecifyWordsCount:false,
        isAnswerNumeric:false,
        options: [{ id: optID1, option: "", selected: false },{ id:optID2, option: "", selected: false }]
    }
return AddtionalQuesDefault

}







   /*
    let subjects = [
    {
        value: 'ALL',
        label: 'ALL'
    },
    {
        value: 'BOTANY',
        label: 'BOTANY'
    }, 
      {
        value: 'ZOOLOGY',
        label: 'ZOOLOGY'
    }
];


 {
            id: uuidv4(),
            description: "",
            questionType: "MCQ",
            difficulty: "",
            correctAnswer: "",
            correctMarks: 0,
            incorrectMarks: 0,
            options: [{ id: uuidv4(), option: "", selected: false },{ id: uuidv4(), option: "", selected: false }],
            isAnswerNumeric:false,
            wordsLimit:10,
        }

    */


//     let classList = [//for reatc multi select
//     {
//         class_name_section: '6A',
//         class_name: '6'
//     },
//     {
//         class_name_section: '6B',
//         class_name: '6'
//     },
//     {
//         class_name_section: '6C',
//         class_name: '6'
//     },
//     {
//         class_name_section: '7A',
//         class_name: '7'
//     },
//     {
//         class_name_section: '7B',
//         class_name: '7'
//     },
//     {
//         class_name_section: '7C',
//         class_name: '7'
//     }    
// ];