// export  const AssessmentData=[//fake data
//     {
//         id:"1",
//         Ass_name:"Ass 1",
//         submitted:[{
//             studentName:"ram",
//             studentId:"2",
//             submissionDate:"16-12-2022",
//             section:"6A",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"Som",
//             studentId:"2",
//             submissionDate:"16-12-2022",
//             section:"6B",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"mani",
//             studentId:"2",
//             submissionDate:"16-12-2022",
//             section:"6C",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//     ],
//         Notsubmitted:[],
//         startdate:"12-12-2022",
//         Enddate:"14-12-2022",
//         subTopic:"area",
//         topic:"maths",
//         chapter:"all"
//     },
//     {
//         id:"2",
//         Ass_name:"Ass 2",
//         submitted:[{
//             studentName:"ram",
//             studentId:"2",
//             section:"6A",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"Som",
//             studentId:"2",
//             section:"6A",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"mani",
//             studentId:"2",
//             section:"6A",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//     ],
//         Notsubmitted:[],
//         startdate:"12-12-2022",
//         Enddate:"14-12-2022",
//         subTopic:"area",
//         topic:"maths",
//         chapter:"all"
//     },
//     {
//         id:"5",
//         Ass_name:"Ass 5",
//         submitted:[{
//             studentName:"ram",
//             section:"6C",
//             studentId:"2",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"Som",
//             studentId:"2",
//             section:"6C",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"mani",
//             section:"6C",
//             studentId:"2",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//     ],
//         Notsubmitted:[],
//         startdate:"12-12-2022",
//         Enddate:"14-12-2022",
//         subTopic:"area",
//         topic:"maths",
//         chapter:"all"
//     },
//     {
//         id:"3",
//         Ass_name:"Ass 3",
//         submitted:[{
//             studentName:"ram",
//             studentId:"2",
//             submissionDate:"16-12-2022",
//             section:"6B",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"Som",
//             studentId:"2",
//             section:"6B",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"mani",
//             studentId:"2",
//             section:"6B",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//     ],
//         Notsubmitted:[],
//         startdate:"12-12-2022",
//         Enddate:"14-12-2022",
//         subTopic:"area",
//         topic:"maths",
//         chapter:"all"
//     },
//     {
//         id:"4",
//         Ass_name:"Ass 4",
//         submitted:[{
//             studentName:"ram",
//             studentId:"2",
//             section:"6B",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"Som",
//             studentId:"2",
//             section:"6B",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//         {
//             studentName:"mani",
//             studentId:"2",
//             section:"6C",
//             submissionDate:"16-12-2022",
//             questions:[
//                 {
//                     desc:"what is area of sqauare ?",
//                     type:"mcq",
//                     correctAnswer:"5155848",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"1"
//                 },{
//                     desc:"select the districts of TN below ?",
//                     type:"msq",
//                     correctAnswer:["5155846","5155849","5155850"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"2"
//                 },
//                 {
//                     desc:"explain Pattern of Structural?",
//                     type:"subjective",
//                     answer:"Structural Pattern: These are patterns that help simplify the design by identifying a way to create relationships among entities such as objects and classes. They are concerned with how classes and objects can be assembled into larger structures. Some of the design patterns that fall into this category are: Adapter, Decorator, and Proxy.",
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"

//                 },
//                 {
//                     desc:"explain Document of Structural?",
//                     type:"Document",
//                     documents:["answer1.pdf","answer2.pdf"],
//                     correctMark:"12",
//                     negativeMark:"2",
//                     difficulty:"3"
//                 }

//             ]
//         },
//     ],
//         Notsubmitted:[],
//         startdate:"12-12-2022",
//         Enddate:"14-12-2022",
//         subTopic:"area",
//         topic:"maths",
//         chapter:"all"
//     }
    
// ]
export const filtersBy=[
    "All","Urgency"
]
export const questionTypeFilter=[
    "ALL","Mcq","MSQ","Subjective"
]