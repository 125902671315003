import { React, useState, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from 'react-bootstrap';
import Api from '../../Api/axios';
import { Stack } from '@mui/material';
import { Modal } from 'react-bootstrap';

const EditSchoolInfo = () => {
    const urlParams = useParams();
    const [selectedSchool, setSelectedSchool] = useState({});
    const [schoolAvailable, setSchoolAvailable] = useState(false);
    const [schoolName, setSchoolName] = useState('');
    const [schoolConventionalID, setSchoolConventionalID] = useState('');
    const [schoolAddress, setSchoolAddress] = useState('');
    const [schoolLogo, setSchoolLogo] = useState([]);
    const [schoolLogoDisp, setSchoolLogoDisp] = useState([]);
    const [schoolPhoneNo, setSchoolPhoneNo] = useState('');
    const [schoolEmail, setSchoolEmail] = useState('');
    const [schoolWebsite, setSchoolWebsite] = useState('');
    const [schoolSession, setSchoolSession] = useState('');
    const [schoolStartingClass, setSchoolStartingClass] = useState('');
    const [schoolEndingClass, setSchoolEndingClass] = useState('');
    const [schoolMaxSection, setSchoolMaxSection] = useState('');
    const [schoolMaxStudents, setSchoolMaxStudents] = useState('');
    const [schoolSubjects, setSchoolSubjects] = useState([]);
    const [sessionList, setSessionList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    useEffect(() => {
        Api.get('/school-entity/school/').then((response) => {
            response.data.map((school) => {
                console.log(school);
                if (school._id === urlParams.schoolId) {
                    setSelectedSchool(school);
                    setSchoolName(school.name);
                    setSchoolAddress(school.address);
                    setSchoolLogo(school.logo);
                    setSchoolLogoDisp(school.logo);
                    setSchoolPhoneNo(school.Contact_number);
                    setSchoolEmail(school.email);
                    setSchoolWebsite(school.website);
                    setSchoolSession(school.current_session);
                    setSchoolStartingClass(school.class_range_start);
                    setSchoolEndingClass(school.class_range_end);
                    setSchoolMaxSection(school.max_number_of_sections);
                    setSchoolMaxStudents(school.students_per_section);
                    setSchoolSubjects(school.subjects);
                    setSchoolAvailable(true);
                    Api.get('/session-entity?district_id=' + school.district_id).then((response) => {
                        setSessionList(response.data);
                    });
                    Api.get('/preconfig-entites/subjects').then((response) => {
                        console.log(response.data.filter((subject) => subject.name != null));
                        setSubjectList(response.data.filter((subject) => subject.name != null));
                    });
                }
            });
        });
    }, []);
    const [nameError, setNameError] = useState(false);
    const [conventionalIDError, setConventionalIDError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [logoError, setLogoError] = useState(false);
    const [phoneNoError, setPhoneNoError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [sessionError, setSessionError] = useState(false);
    const [classRangeError, setClassRangeError] = useState(false);
    const [sectionRangeError, setSectionRangeError] = useState(false);
    const [maxStudentsError, setMaxStudentsError] = useState(false);
    const [subjectsError, setSubjectsError] = useState(false);
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const validateSchoolName = (e) => {
        if (e.target.value.length < 1) {
            setNameError(true);
        } else {
            setNameError(false);
        }
        setSchoolName(e.target.value);
    };
    const validateSchoolConventionalID = (e) => {
        if (e.target.value.length < 1) {
            setConventionalIDError(true);
        } else {
            setConventionalIDError(false);
        }
        setSchoolConventionalID(e.target.value);
    };
    const validateSchoolAddress = (e) => {
        if (e.target.value.length < 1) {
            setAddressError(true);
        } else {
            setAddressError(false);
        }
        setSchoolAddress(e.target.value);
    };
    const validateSchoolLogo = (e) => {
        if (e.target.files.length < 1) {
            setLogoError(true);
        } else {
            if (e.target.files[0].size > 1048576) {
                setLogoError(true);
            } else if (e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png') {
                setLogoError(true);
            } else {
                setLogoError(false);
                setSchoolLogo(e.target.files[0]);
            }
        }
    };
    const validateSchoolPhoneNo = (e) => {
        if (e.target.value.length < 1) {
            setPhoneNoError(true);
        } else {
            if (e.target.value.match(/^[0-9]{10}$/)) {
                setPhoneNoError(false);
            } else {
                setPhoneNoError(true);
            }
        }
        setSchoolPhoneNo(e.target.value);
    };
    const validateSchoolEmail = (e) => {
        if (e.target.value.length < 1) {
            setEmailError(false);
        } else {
            if (e.target.value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setEmailError(false);
            } else {
                setEmailError(true);
            }
        }
        setSchoolEmail(e.target.value);
    };
    const validateSchoolWebsite = (e) => {
        if (e.target.value.length < 1) {
            setWebsiteError(true);
        } else {
            if (e.target.value.match(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm)) {
                setWebsiteError(false);
            } else {
                setWebsiteError(true);
            }
        }
        setSchoolWebsite(e.target.value);
    };

    const validateStartingClass = (e) => {
        setSchoolStartingClass(e.target.value);
        if (e.target.value < 1 || e.target.value > 12 || isNaN(e.target.value) || !schoolEndingClass || e.target.value > schoolEndingClass) {
            setClassRangeError(true);
        } else {
            setClassRangeError(false);
        }
    }
    const validateEndingClass = (e) => {
        setSchoolEndingClass(e.target.value);
        console.log(schoolStartingClass, e.target.value, schoolStartingClass > e.target.value);
        if (e.target.value < 1 || e.target.value > 12 || isNaN(e.target.value) || !schoolStartingClass || schoolStartingClass > e.target.value) {
            setClassRangeError(true);
        } else {
            setClassRangeError(false);
        }
    }
    const validateSection = (e) => {
        if (e.target.value < 1) {
            setSectionRangeError(true);
        } else {
            setSectionRangeError(false);
        }
        setSchoolMaxSection(e.target.value);
    }
    const validateMaxStudents = (e) => {
        if (e.target.value < 1) {
            setMaxStudentsError(true);
        } else {
            setMaxStudentsError(false);
        }
        setSchoolMaxStudents(e.target.value);
    }
    const validate = () => {
        let valid = true;
        if (!schoolName) {
            setNameError(true);
            valid = false;
        }
        // if (schoolConventionalID === '') {
        //     setConventionalIDError(true);
        //     valid = false;
        // }
        if (!schoolAddress) {
            setAddressError(true);
            valid = false;
        }
        // if (schoolLogo === '') {
        //     setLogoError(true);
        //     valid = false;
        // }
        if (!schoolPhoneNo) {
            setPhoneNoError(true);
            valid = false;
        }
        if (!schoolEmail) {
            setEmailError(true);
            valid = false;
        }
        // if (!schoolWebsite) {
        //     setWebsiteError(true);
        //     valid = false;
        // }
        console.log(schoolPhoneNo)
        if (!valid) {
            console.log('error', valid, nameError, conventionalIDError, addressError, logoError, phoneNoError, emailError, websiteError);
            return false;
        } else {
            const data = {
                'school_id': urlParams.schoolId,
                'name': schoolName,
                'address': schoolAddress,
                'logo': schoolLogo,
                'Contact_number': schoolPhoneNo,
                'email': schoolEmail,
                'website': schoolWebsite,
            }
            Api.put("/school-entity/update", data).then((response) => {
                setUpdateSuccess(true);
            });
        }
    }

    return (
        <div className='mb-4' align="center" style={{ overflowY: "scroll" }}>
            {schoolAvailable ? <div className="shadow-lg p-4 col-11 col-lg-10 mt-4 mb-4" style={{ borderRadius: 10, backgroundColor: "#fff" }}>
                <h1 className='mb-4'> Edit School </h1>
                {
                    updateSuccess ?
                        <Modal show={updateSuccess} onHide={() => setUpdateSuccess(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Success</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Successfully updated school details</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setUpdateSuccess(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        : null
                }
                <div className="row">
                    <div className="col-md-auto col-11 ms-4 me-2 mt-2 mb-2">
                        <img src={schoolLogoDisp} alt="School Logo" width="130" height="130" />
                    </div>
                    <div className="col-auto ms-2 mt-2 mb-2" align="left">
                        <h3>{schoolName}</h3>
                        <p>Phone No: <a target="_blank" href={"tel:+91" + schoolPhoneNo} style={{ textDecoration: "none" }}>+91 {schoolPhoneNo}</a><br></br>E-Mail: <a target="_blank" href={"mailto:" + schoolEmail} style={{ textDecoration: "none" }}>{schoolEmail}</a> <br></br>Website: <a target="_blank" href={schoolWebsite ? schoolWebsite.indexOf("://") == -1 ? "https://" + schoolWebsite : schoolWebsite : ""} style={{ textDecoration: "none" }}>{schoolWebsite} </a> <br></br>Address: {schoolAddress}</p>
                    </div>
                </div>
                <Form.Group controlId="schoolName" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Name</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            placeholder="Enter School Name"
                            isInvalid={nameError}
                            value={schoolName}
                            onChange={(e) => validateSchoolName(e)}

                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a school name.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="schoolAddress" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Address</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            as="textarea"
                            placeholder="Enter School Address"
                            isInvalid={addressError}
                            value={schoolAddress}
                            onChange={(e) => validateSchoolAddress(e)}

                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter Address.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="schoolLogo" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label align="left">Logo</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="file"
                            placeholder="Upload Logo"
                            isInvalid={logoError}
                            onChange={(e) => validateSchoolLogo(e)}

                        />
                        <Form.Control.Feedback type="invalid">
                            Please select a valid Logo File (PNG/JPG , Size less than 1MB).
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="schoolPhoneNo" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            placeholder="Enter Phone Number"
                            isInvalid={phoneNoError}
                            value={schoolPhoneNo}
                            onChange={(e) => validateSchoolPhoneNo(e)}

                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid Phone Number.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="schoolEmail" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Email</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            isInvalid={emailError}
                            value={schoolEmail}
                            onChange={(e) => validateSchoolEmail(e)}

                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid Email.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="schoolWebsite" className="m-3" style={{ textAlign: "left" }}>
                    <Form.Label>Website</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            type="text"
                            placeholder="Enter Website"
                            isInvalid={websiteError}
                            value={schoolWebsite}
                            onChange={(e) => validateSchoolWebsite(e)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid Website.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
                <Button variant="secondary" className='mb-4' onClick={validate}>
                    Save Changes
                </Button>
            </div> :
                <div className="shadow-lg p-4 col-11 col-lg-10 mt-4" style={{ borderRadius: 10 }} align="center">
                    <img src={`${process.env.PUBLIC_URL}/Images/failure.png`} style={{ width: '100px' }} alt="" />
                    <h3 className="m-4">Sorry, we cannot find the requested school.</h3>
                    <Stack className="m-4" direction="horizontal" gap={3} style={{ justifyContent: 'center' }}>
                        <Button variant="outline-secondary" size="lg" onClick={(e) => { window.location.href = window.location.origin; }} >
                            Go to Dashboard
                        </Button>
                    </Stack>
                </div>}
        </div>


    );
}

export default EditSchoolInfo;