import React, { useState, useEffect } from 'react';
import Api from '../../../Api/axios';
import { Stack } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Table, Button, Form, Col, Row } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import Modal from 'react-bootstrap/Modal';
import { useParams, useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const LifeCycleLogs = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const params = Object.fromEntries([...searchParams]);
    const urlParams = useParams();
    const navigate = useNavigate();
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [refresh, setRefresh] = useState(false);
    const [allLifeCycle, setAllLifeCycle] = useState([]);
    const [filterSchool, setFilterSchool] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState('');
    const [filterClass, setFilterUserClass] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [schoolList, setSchoolList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [page, setPage] = useState(1);
    const [selectedType, setSelectedType] = useState('');
    const [selectedUserType, setSelectedUserType] = useState('');
    useEffect(() => {
        dispatch({
            type: 'SET_LOADING',
            loading: true,
        });
        var data = {
            "page": page,
            "offset": 50
        }
        if (userDetails?.districtId) {
            data.districtIds = [userDetails?.districtId];
        }
        if (userDetails?.schoolId) {
            data.schoolIds = [userDetails?.schoolId];
        } else if (selectedSchool) {
            data.schoolIds = [selectedSchool];
        }
        if (selectedClass) {
            data.class_ids = [selectedClass];
        }
        if (selectedType) {
            data.cycleType = selectedType;
        }
        if (selectedUserType) {
            data.userType = selectedUserType;
        }
        Api.get("user-lifecycle/user-lifecycle-data", { params: data }).then((res) => {
            console.log(res.data);
            setAllLifeCycle([...allLifeCycle, ...res.data.userLifecycle]);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            dispatch({
                type: 'SET_LOADING',
                loading: false,
            });
        });
        if (!userDetails?.schoolId) {
            Api.get("school-entity/school").then((res) => {
                res.data.sort((a, b) => a.name.localeCompare(b.name));
                setSchoolList(res.data);
                console.log(res.data);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [refresh, userDetails, selectedSchool, selectedClass, page, selectedType, selectedUserType]);
    useEffect(() => {
        if (selectedSchool || userDetails?.schoolId) {
            Api.get("school-entity/all-classes", { params: { school_id: selectedSchool || userDetails?.schoolId } }).then((res) => {
                // res.data.sort((a, b) => a.class_name_section.localeCompare(b.class_name_section));
                setClassList(res.data);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [selectedSchool, userDetails]);
    return (
        <div
            className="p-4"
            style={{
                backgroundColor: '#FFF',
                minHeight: '94vh'
            }}
        >
            <h1 className='pb-5' align="center">Lifecycle Logs</h1>
            <div className='row' style={{justifyContent: "center"}}>
                {!userDetails?.schoolId &&
                    <Form.Group className='col-12 col-md-6 col-lg-4 p-2'>
                        <Form.Label>Select School</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedSchool}
                            onChange={(e) => {
                                setPage(1);
                                setAllLifeCycle([], setSelectedSchool(e.target.value));
                            }}
                        >
                            <option value="">All</option>
                            {schoolList.map((school) => (
                                <option value={school._id}>{school.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                }
                {(userDetails?.schoolId || selectedSchool) &&
                    <Form.Group className='col-12 col-md-6 col-lg-4 p-2'>
                        <Form.Label>Select Class</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedClass}
                            onChange={(e) => {
                                setPage(1);
                                setAllLifeCycle([], setSelectedClass(e.target.value));
                            }}
                        >
                            <option value="">All</option>
                            {classList.map((userClass) => (
                                <option value={userClass._id}>{userClass.class_name_section}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                }
                <Form.Group className='col-12 col-md-6 col-lg-4 p-2'>
                    <Form.Label>Lifecycle Type</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedType}
                        onChange={(e) => {
                            setPage(1);
                            setAllLifeCycle([], setSelectedType(e.target.value));
                        }}
                    >
                        <option value="">All</option>
                        <option value="ONBOARD">Onboard</option>
                        <option value="ASSIGN">Assign</option>
                        <option value="PROMOTE">Promote</option>
                        <option value="TRANSFER">Transfer</option>
                        <option value="DEMOTE">Demote</option>
                        <option value="SUSPEND">Suspend</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group className='col-12 col-md-6 col-lg-4 p-2'>
                    <Form.Label>User Type</Form.Label>
                    <Form.Control
                        as="select"
                        value={selectedUserType}
                        onChange={(e) => {
                            setPage(1);
                            setAllLifeCycle([], setSelectedUserType(e.target.value));
                        }}
                    >
                        <option value="">All</option>
                        {userDetails?.userType === "DISTRICT" && <option value="SCHOOL_ADMIN">School Admin</option>}
                        <option value="TEACHER">Teacher</option>
                        <option value="PARENT">Parent</option>
                        <option value="STUDENT">Student</option>
                    </Form.Control>
                </Form.Group>
            </div>
            <div className="pt-4 pb-4">
            <Table responsive hover stripped>
                <thead>
                    <tr>
                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                            Sr. No.
                        </td>
                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                            Lifecycle Type
                        </td>
                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                            User Name
                        </td>
                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                            Conventioanl ID
                        </td>
                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                            User Type
                        </td>
                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                            Date
                        </td>
                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                            Time (24 Hrs Format)
                        </td>
                        <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center", whiteSpace: "nowrap" }}>
                            View Details
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        allLifeCycle?.map((item, index) => {
                            var date_time = new Date(item.updatedAt);
                            var dd = String(date_time.getDate()).padStart(2, '0');
                            var mm = String(date_time.getMonth() + 1).padStart(2, '0');
                            var yyyy = date_time.getFullYear();
                            var time = String(date_time.getHours()).padStart(2, '0') + ":" + String(date_time.getMinutes()).padStart(2, '0');
                            var date = dd + '/' + mm + '/' + yyyy;
                            var user = {};
                            return (
                                    <>
                                        <Modal show={item.isOpened} onHide={(e) => { setAllLifeCycle(allLifeCycle.map(sc => (item._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>View</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className='p-2'>
                                                    {
                                                        item.cycleType === "ONBOARD" ?
                                                            <>
                                                                <h3 align="center">ONBOARD</h3>
                                                                <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                <span style={{ fontWeight: "bold" }}>District: </span>{item.to_district_name} <br />
                                                                <span style={{ fontWeight: "bold" }}>School: </span>{item.to_school_name} <br />
                                                                <span style={{ fontWeight: "bold" }}>Class: </span>{item.to_class_name} <br />
                                                            </>
                                                            : item.cycleType === "ASSIGN" ?
                                                                <>
                                                                    <h3 align="center">ASSIGN</h3>
                                                                    <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                    <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                    <span style={{ fontWeight: "bold" }}>District: </span>{item.to_district_name} <br />
                                                                    <span style={{ fontWeight: "bold" }}>School: </span>{item.to_school_name} <br />
                                                                    <span style={{ fontWeight: "bold" }}>Class: </span>{item.to_class_name} <br />
                                                                </>
                                                                : item.cycleType === "PROMOTE" ?
                                                                    <>
                                                                        <h3 align="center">PROMOTE</h3>
                                                                        <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                        <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                        <span style={{ fontWeight: "bold" }}>From Class: </span>{item.from_class_name} <br />
                                                                        <span style={{ fontWeight: "bold" }}>To Class: </span>{item.to_class_name} <br />
                                                                    </>
                                                                    : item.cycleType === "TRANSFER" ?
                                                                        <>
                                                                            <h3 align="center">TRANSFER</h3>
                                                                            <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                            <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                            <span style={{ fontWeight: "bold" }}>From Class: </span>{item.from_class_name} <br />
                                                                            <span style={{ fontWeight: "bold" }}>To Class: </span>{item.to_class_name} <br />
                                                                            <span style={{ fontWeight: "bold" }}>From School: </span>{item.from_school_name} <br />
                                                                            <span style={{ fontWeight: "bold" }}>To School: </span>{item.to_school_name} <br />
                                                                            <span style={{ fontWeight: "bold" }}>From District: </span>{item.from_district_name} <br />
                                                                            <span style={{ fontWeight: "bold" }}>To District: </span>{item.to_district_name} <br />
                                                                        </>
                                                                        : item.cycleType === "DEMOTE" ?
                                                                            <>
                                                                                <h3 align="center">DEMOTE</h3>
                                                                                <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                                <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                                <span style={{ fontWeight: "bold" }}>From Class: </span>{item.from_class_name} <br />
                                                                                <span style={{ fontWeight: "bold" }}>To Class: </span>{item.to_class_name} <br />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <h3 align="center">SUSPEND</h3>
                                                                                <span style={{ fontWeight: "bold" }}>Date : </span>{date} <br />
                                                                                <span style={{ fontWeight: "bold" }}>Time : </span>{time} <br />
                                                                            </>
                                                    }
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={(e) => { setAllLifeCycle(allLifeCycle.map(sc => (item._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        <tr>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                {index + 1}
                                            </td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                {item.cycleType}
                                            </td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                {item.user_obj?.name}
                                            </td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                {item.user_obj?.conventionalId}
                                            </td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                {item.user_obj?.userType}
                                            </td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                {date}
                                            </td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "1px solid rgb(0 0 0 / 26%)", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }}>
                                                {time}
                                            </td>
                                            <td className="p-2" style={{ borderTop: "none", borderLeft: "none", borderRight: "none", borderBottom: "1px solid rgb(0 0 0 / 26%)", textAlign: "center" }} onClick={(e) => { setAllLifeCycle(allLifeCycle.map(sc => (item._id == sc._id ? { ...sc, isOpened: !sc.isOpened } : sc))) }}>
                                                <a style={{ textDecoration: "none" }} href="#">View</a>
                                            </td>
                                        </tr>
                                    </>
                                )
                        })
                    }
                </tbody>
            </Table>
            </div>
            <div className='pt-2 pb-4' align="center">
                { allLifeCycle.length > 0 && allLifeCycle%50 == 0 &&
                <Button variant="secendory" onClick={
                    (e) => {
                        setPage(page + 1)
                    }
                }>Load More</Button> }
            </div>
        </div>
    )
}

export default LifeCycleLogs;