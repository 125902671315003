import React, { useState, useEffect } from 'react';
import { Table, Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Api from '../../../Api/axios';
import { Multiselect } from "multiselect-react-dropdown";

const ShowAllDocuments = () => {
    const [{ userDetails }, dispatch] = useDataLayerValue();
    const [documentTypes, setDocumentTypes] = useState([
        {
            id: "Academic",
            value: "Academic"
        },
        {
            id: "Personal",
            value: "Personal"
        },
        {
            id: "Administrative",
            value: "Administrative"
        },
        {
            id: "Others",
            value: "Others"
        },
    ]);
    const [userTypes, setUserTypes] = useState([
        {
            id: "DISTRICT",
            value: "District"
        },
        {
            id: "SCHOOL_ADMIN",
            value: "School Admin"
        },
        {
            id: "TEACHER",
            value: "Teacher"
        },
        {
            id: "STUDENT",
            value: "Student"
        },
    ]);
    const [schoolList, setSchoolList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [filterDocumentType, setFilterDocumentType] = useState("");
    const [filterRecipientUserType, setFilterRecipientUserType] = useState("");
    const [filterOwnerUserType, setFilterOwnerUserType] = useState("");
    const [filterSchool, setFilterSchool] = useState("");
    const [filterClass, setFilterClass] = useState([]);
    const [filterSubject, setFilterSubject] = useState("");
    const [documentsList, setDocumentsList] = useState([]);
    useEffect(() => {
        Api.get('/school-entity/school').then((response) => {
            if (userDetails?.userType == "DISTRICT") {
                response.data.sort((a, b) => a.name.localeCompare(b.name));
                setSchoolList(response.data);
            } else {
                setSchoolList([response.data]);
                setFilterSchool(response.data._id);
            }
        });
    }, [userDetails]);
    useEffect(() => {
        if (filterSchool !== "") {
            Api.get('school-entity/all-classes?school_id=' + filterSchool).then((response) => {
                setClassList(response.data);
            });
        } else {
            setClassList([]);
        }
    }, [filterSchool]);
    useEffect(() => {
        Api.get('preconfig-entites/subjects').then((response) => {
            response.data.sort((a, b) => a.name.localeCompare(b.name));
            setSubjectList(response.data);
        });
    }, []);
    useEffect(() => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        Api.get("documents", {
            params: {
                school_ids: filterSchool ? [filterSchool] : null,
                class_data_names: filterClass.length > 0 ? filterClass.map((classData) => classData.class_name_section) : null,
                subject_id: filterSubject ? filterSubject : null,
                ownerType: filterOwnerUserType ? filterOwnerUserType : null,
                type: filterDocumentType ? filterDocumentType : null,
                userType: filterRecipientUserType ? filterRecipientUserType : null,
            }
        }).then((response) => {
            setDocumentsList(response.data);
            console.log(response.data);
        }).finally(() => {
            dispatch({
                type: "SET_LOADING",
                loading: false,
            });
        });
    }, [filterDocumentType, filterRecipientUserType, filterOwnerUserType, filterSchool, filterClass, filterSubject]);
    return (
        <div
            style={{
                backgroundColor: "white",
                width: "100%",
                height: "100%",
                padding: "10px",
            }}
        >
            <h1
                style={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "20px",
                    fontWeight: "bold",
                }}
            > Show All Documents </h1>
            <Form
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "center",
                }}
            >
                <Form.Group
                    controlId="documentType"
                    style={{
                        padding: "10px",
                    }}
                >
                    <Form.Label>Document Type</Form.Label>
                    <Form.Control
                        as="select"
                        value={filterDocumentType}
                        onChange={(e) => setFilterDocumentType(e.target.value)}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                            minHeight: "55px",
                        }}
                    >
                        <option value="">ALL</option>
                        {documentTypes.map((documentType) => {
                            return (
                                <option value={documentType.id}> {documentType.value} </option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group
                    controlId="ownerUserType"
                    style={{
                        padding: "10px",
                    }}
                >
                    <Form.Label>Owner User Type</Form.Label>
                    <Form.Control
                        as="select"
                        value={filterOwnerUserType}
                        onChange={(e) => setFilterOwnerUserType(e.target.value)}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                            minHeight: "55px",
                        }}
                    >
                        <option value="">ALL</option>
                        {userTypes.map((userType) => {
                            return (
                                <option value={userType.id}> {userType.value} </option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group
                    controlId="recipientUserType"
                    style={{
                        padding: "10px",
                    }}
                >
                    <Form.Label>Recipient User Type</Form.Label>
                    {/* <Multiselect
                        showArrow
                        labelledBy={"Select User Types"}
                        options={userTypes}
                        displayValue="value"
                        showCheckbox={true}
                        selectedValues={filterRecipientUserType}
                        onSelect={(selectedList, selectedItem) => { setFilterRecipientUserType(selectedList) }}
                        onRemove={(selectedList, selectedItem) => { setFilterRecipientUserType(selectedList) }}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                        }}
                    /> */}
                    <Form.Control
                        as="select"
                        value={filterRecipientUserType}
                        onChange={(e) => setFilterRecipientUserType(e.target.value)}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                            minHeight: "55px",
                        }}
                    >
                        <option value="">ALL</option>
                        {userTypes.map((userType) => {
                            return (
                                <option value={userType.id}> {userType.value} </option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
                <Form.Group
                    controlId="school"
                    style={{
                        padding: "10px",
                    }}
                >
                    <Form.Label>School</Form.Label>
                    <Form.Control
                        as="select"
                        value={filterSchool}
                        onChange={(e) => setFilterSchool(e.target.value)}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                            minHeight: "55px",
                        }}
                        disabled={userDetails?.userType === "DISTRICT" ? false : true}
                    >
                        <option value="">ALL</option>
                        {schoolList.map((school) => {
                            return (
                                <option value={school._id}> {school.name} </option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
                {classList.length > 0 && <Form.Group
                    controlId="class"
                    style={{
                        padding: "10px",
                    }}
                >
                    <Form.Label>Class</Form.Label>
                    <Multiselect
                        labelledBy={"Select"}
                        showArrow
                        options={classList}
                        displayValue="class_name_section"
                        groupBy="class_name"
                        showCheckbox={true}
                        selectedValues={filterClass}
                        onSelect={(selectedList, selectedItem) => { setFilterClass(selectedList) }}
                        onRemove={(selectedList, selectedItem) => { setFilterClass(selectedList) }}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                        }}
                    />
                </Form.Group>
                }
                <Form.Group
                    controlId="subject"
                    style={{
                        padding: "10px",
                    }}
                >
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                        as="select"
                        value={filterSubject}
                        onChange={(e) => setFilterSubject(e.target.value)}
                        style={{
                            minWidth: "250px",
                            maxWidth: "300px",
                            minHeight: "55px",
                        }}
                    >
                        <option value="">ALL</option>
                        {subjectList.map((subject) => {
                            return (
                                <option value={subject._id}> {subject.name} </option>
                            )
                        })}
                    </Form.Control>
                </Form.Group>
            </Form>
            <div className="m-4">
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th scope="col"
                                style={{
                                    minWidth: "80px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Sr. No.</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Document Name</th>
                            <th scope="col"
                                style={{
                                    minWidth: "280px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Document Description</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Document Type</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Recipient User ID's</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Recipient User Type</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Owner Conventional Id</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Owner User Type</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >School's</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Class</th>
                            <th scope="col"
                                style={{
                                    minWidth: "200px",
                                    textAlign: "center",
                                    borderRight: "1px solid #dee2e6",
                                    borderLeft: "none",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >Subject</th>
                            <th scope="col"
                                style={{
                                    minWidth: "140px",
                                    textAlign: "center",
                                    borderRight: "none",
                                    borderLeft: "1px solid #dee2e6",
                                    borderTop: "1px solid #dee2e6",
                                    borderBottom: "1px solid #dee2e6",
                                }}
                            >View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {documentsList.map((item, index) => {
                            return (
                                <>
                                    <Modal
                                        show={item.userShowToggle}
                                        onHide={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Users List</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body
                                            style={{
                                                maxHeight: "calc(100vh - 210px)",
                                                overflowY: "scroll"
                                            }}
                                        >
                                            <Table striped bordered hover responsive className="mt-4 mb-4">
                                                <thead>
                                                    <tr>
                                                        <td><b>Sr. No.</b></td>
                                                        <td><b>Name</b></td>
                                                        <td><b>Conventional ID</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.document_for_user_data ? item.document_for_user_data.map((user, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>{user ? user[1] : user}</td>
                                                                <td>{user ? user[2] : user}</td>
                                                                {/* <td><Form.Check type="checkbox" style={{ padding: 10 }} checked = {user.checked} onChange={e => setUserList(userList.map(us => (us.username==user.username ? {...us, checked: !user.checked} : us)))} /></td> */}
                                                            </tr>
                                                        )
                                                    }) : <tr><td colSpan="4">No Users</td></tr>}
                                                </tbody>
                                            </Table>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <Modal
                                        show={item.schoolShowToggle}
                                        onHide={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, schoolShowToggle: !item.schoolShowToggle } : f))) }}
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Users List</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body
                                            style={{
                                                maxHeight: "calc(100vh - 210px)",
                                                overflowY: "scroll"
                                            }}
                                        >
                                            <Table striped bordered hover responsive className="mt-4 mb-4">
                                                <thead>
                                                    <tr>
                                                        <td><b>Sr. No.</b></td>
                                                        <td><b>Name</b></td>
                                                        <td><b>Conventional ID</b></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.school_id ? item.school_id.map((user, i) => {
                                                        console.log(user, schoolList.filter(school => school._id === user) )
                                                        return schoolList.filter(school => school._id === user).map((school) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td><b>{i + 1}</b></td>
                                                                    <td><b>{school.name}</b></td>
                                                                    <td><b>{school.conventional_id}</b></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }) : <tr><td colSpan="4">No Users</td></tr>}
                                                </tbody>
                                            </Table>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, schoolShowToggle: !item.schoolShowToggle } : f))) }}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <tr key={index}>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "none",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{index + 1}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{item.name}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{item.description}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{item.document_type}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}><a onClick={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, userShowToggle: !item.userShowToggle } : f))) }}>{item.document_for_user ? item.document_for_user.length + " Users" : "0 Users"}</a></td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{item.userType_to_show ? item.userType_to_show : ""}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{item.created_by ? item.created_by.conventionalId : ""}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{item.user_type_of_creator ? item.user_type_of_creator : ""}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}><a onClick={(e) => { setDocumentsList(documentsList.map(f => (f._id == item._id ? { ...f, schoolShowToggle: !item.schoolShowToggle } : f))) }}>{item.school_id ? item.school_id.length + " School's" : "0 School"}</a></td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{item.class_data_names ? item.class_data_names.map(name => { return name }).join(' ,') : "ALL/None"}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>{item.subject ? item.subject.name : "ALL/None"}</td>
                                        <td style={{
                                            textAlign: "center",
                                            borderRight: "none",
                                            borderLeft: "1px solid #dee2e6",
                                            borderTop: "1px solid #dee2e6",
                                            borderBottom: "1px solid #dee2e6",
                                        }}>
                                            <Button variant="secondary" onClick={() => window.open(item.link_arr[0], "_blank")}>
                                                View
                                            </Button>
                                        </td>
                                    </tr>
                                </>
                            )

                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default ShowAllDocuments;