import React, { useState,useEffect } from "react";
import { Box, MenuItem, Multiselect,Grid,MenuList  } from "@mui/material";
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from '@material-ui/icons/Add';
import Icon from '@material-ui/core/Icon';
import Quiz from "../../Quiz/Quiz";
// import FormControl from "@mui/material/FormControl";
// import "./Mcq.css";
import { withStyles } from "@material-ui/core/styles";
import { Container } from "@mui/material";

import { v4 as uuidv4 } from 'uuid';
// import SelectField from '@material-ui/SelectField';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { makeStyles } from "@material-ui/core/styles";
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import SettingsIcon from '@material-ui/icons/Settings';
// import { DateTimePicker } from '@material-ui/pickers'
// import * as React from 'react';
// import dayjs, { Dayjs } from 'dayjs';
// import TextField from '@mui/material/TextField';
// import DateTimePicker from 'react-datetime-picker'
import dayjs from 'dayjs';//{ Dayjs }
// import TextField from '@mui/material/TextField';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { Multiselect as MultiselectReactDropDown } from 'multiselect-react-dropdown'; //dynamic
// import { Dropdown } from "react-bootstrap";
import Api from "../../../Api/axios";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

import Table from 'react-bootstrap/Table';
import { borderBottom } from "@mui/system";
import { ThemeProvider, createMuiTheme  } from "@material-ui/core/styles";

import {UserTypes,difficultyLevels,difficultyLevelsQues,
    chapters,topics,subTopics,AssigningShowByOrder,
    AssessmentShowByOrder,questionTypes,
    AddtionalQuesFormat,
    ChangeStandardWords} from "../Constants"
    const styles = {
        ".MuiPaper-root": {
            height:"200px"
        }
      };
  

const muiUseStyles=makeStyles((theme)=>({
    QuestionType:{
        width:"180px"
    },
   
})

)
const popheightTheme = {
    "MuiPaper-root":{
        height:"200px"
    }
  }

    let updatedEndTime=dayjs().set('minute',dayjs().get('minute')+30)
    // updatedEndTime.$m+=1
        // console.log("updatedTime js ",updatedEndTime)
       
function CreateAssesment() {
    const [assesmentPage, setAssesmentPage] = useState("1");
    const [assesmentType, setAssesmentType] = useState("");
    const [assesmentName, setAssesmentName] = useState("");
    const handleAssesmentNameChange = (event) => {
        setAssesmentName(event.target.value);
    }
    // const [assesmentClass, setAssesmentClass] = useState("ALL");//not render..coz of single select
    const handleAssesmentClassChange = (event) => {
        // setAssesmentClass(event.target.value);
    }
    const [assesmentSubject, setAssesmentSubject] = useState("");
    const handleAssesmentSubjectChange = (event) => {
        setAssesmentSubject(event.target.value);
        console.log("setAssesmentSubject==>",event.target.value)
        console.log("event.target ",event.target.value)
        setAssessmentOrderElements((pre)=>{
            let updated=[...pre]  
           updated[1].selected=true
            return updated
       
      })
    }
    const theme = createMuiTheme({//testb
        "& .MuiPaper-root": {
            height:"200px"
        }
      });
    //   const classes = useStyles();

    const [schoolList,setSchoolList]=useState([])
    const[classList,setClassList]=useState([])
    const[overAllClassList,setOverAllClassList]=useState([])
    const [userType, setuserType] = useState("");
    const handleUserTypeChange = (event) => {
        setuserType(event.target.value);
        if(userDetails?.userType==="SCHOOL_ADMIN" || userDetails?.userType==="TEACHER"){
            setOrderElements((pre)=>{
                let updated=[...pre]  
               updated[2].selected=true
                return updated
           
          })
        }else{

            setOrderElements((pre)=>{
                // console.log("firstFalseobjIndex pre 1",pre)
            
                // let firstFalseobjIndex=pre.findIndex((e)=>e.selected===false)
                // console.log("firstFalseobjIndex 1",firstFalseobjIndex)
                // return pre
                // if(firstFalseobjIndex!==-1){
                  let updated=[...pre]  
                 updated[1].selected=true
                //  console.log("updatred =======>", updated)
                  return updated
                // }else{
                //     return pre
                // }
            })
        }
        

    }
    const [SelectSchool, setSelectSchool] = useState([]);
    const handleSelectSchoolChange = (event) => {
        setSelectSchool(event.target.dataset.valueId);
        console.log("e ==== >  checkingn ", event.target.dataset.valueId)
    // function checking(e){
    //     console.log("e ==== >  checkingn ", e.target)
    // console.log("e ==== >  checkingn ", e.target.value)
    // console.log("e ==== >  checkingn ", e.target.dataset)
    // // onChange={e => handleSelectSchoolChange(e)}
    // }
        // if(event.target.dataset.label==="ALL"){////new
        //     setOrderElements((pre)=>{
        //         let updated=[...pre]  
        //        updated[2].selected=false
        //         return updated
           
        //   })
        // }else{
        //     setOrderElements((pre)=>{
        //         let updated=[...pre]  
        //        updated[2].selected=true
        //         return updated
           
        //   })
        // }
   

    }
    const [NoTimeLimit, setNoTimeLimit] = useState(false);
    const handleNoTimeLimitChange = () => {
        setNoTimeLimit((pre)=>!pre);
        if(NoTimeLimit){
            setShowLateSubmission(()=>false)
        }
    }
    const [assesmentChapter, setAssesmentChapter] = useState("");
    const handleAssesmentChapterChange = (event) => {
        setAssesmentChapter(event.target.value);
        setAssessmentOrderElements((pre)=>{
            let updated=[...pre]  
           updated[2].selected=true
            return updated
       
      })
    }
    const [assesmentTopic, setAssesmentTopic] = useState("");
    const handleAssesmentTopicChange = (event) => {
        setAssesmentTopic(event.target.value);
    
    //     setAssessmentOrderElements((pre)=>{ as of now no subtopic
    //         let updated=[...pre]  
    //        updated[3].selected=true
    //         return updated
       
    //   })
    }
    const [assesmentSubTopic, setAssesmentSubTopic] = useState("");
    const handleAssesmentSubTopicChange = (event) => {
        setAssesmentSubTopic(event.target.value);
    }
    const handleNullAssesmentPageChange = (event) => {
        // if (validateAssesmentPage1()) {
            setAssesmentPage("5");
            setAssesmentType("NULL");
        // }
    }
    const handleRandomAssesmentPageChange = (event) => {
        // if (validateAssesmentPage1()) {
            setAssesmentPage("4");
            setAssesmentType("RANDOM");
        // }
    }
    const handleCustomAssesmentPageChange = (event) => {
        // if (validateAssesmentPage1()) {
            setAssesmentPage("4");
            setAssesmentType("CUSTOM");
        // }
    }

    // useEffect(()=>{
    //     console.log("userType ==> ",userType);
    //     console.log("ChangeStandardWords ==> ",ChangeStandardWords(userType));
    // },[userType])
    useEffect(()=>{
         if(userDetails?.userType==="DISTRICT"){
            Api.get("/school-entity/school").then((res)=>{
                let SchoolList = [];
                let fetchedData=res.data
                let obj={} ////new
                obj.value="All"
                obj.label="ALL"
                SchoolList.push(obj)
                fetchedData.forEach((x)=>{
                    let obj={}
                    obj.value=x._id
                    obj.label=x.name
                    SchoolList.push(obj)
                })
                setSchoolList(()=>SchoolList)       
                console.log("SchoolList==============>",res.data);
            })
         }
    },[])


    const [assesmentStartTime, setAssesmentStartTime] = useState(dayjs());
    const handleAssesmentStartTimeChange = (event) => {
        setAssesmentStartTime(event);
    }
    const [assesmentEndTime, setAssesmentEndTime] = useState(updatedEndTime);
    const[showLateSubmission,setShowLateSubmission]=useState(false)
    // console.log("assesmentEndTime ",assesmentStartTime,"assesmentEndTime",assesmentEndTime)
    const handleAssesmentEndTimeChange = (event) => {
        setAssesmentEndTime(event);
        setShowLateSubmission(()=>true)
    }
    const [randomFilter, setRandomFilter] = useState([{ id: uuidv4(), difficultyLevel: "ALL", noOfQues: 1 }]);
    const handleRandomFilterAdd = (event) => {
        setRandomFilter([...randomFilter, { id: uuidv4(), difficultyLevel: "ALL", noOfQues: 1 }]);
    }
    const handleRandomFilterRemove = (filterId, event) => {
        setRandomFilter(randomFilter.filter((filter) => filter.id !== filterId));
    }
    const handleRandomFilterDifficultyLevelChange = (filterId, event) => {
        setRandomFilter(randomFilter.map((filter) => filter.id === filterId ? { ...filter, difficultyLevel: event.target.value } : filter));
    }
    const handleRandomFilterNoOfQuesChange = (filterId, event) => {
        const value = event.target.value.replace(/\D/g, "");
        setRandomFilter(randomFilter.map((filter) => filter.id === filterId ? { ...filter, noOfQues: value } : filter));
    }
    const validateAssesmentPage1 = (event) => {
        if (assesmentName === "") {
            alert("Assesment Name cannot be empty");
            return false;
        }
        if(userType===""){
            alert("userType should be selected");
            return
        }
        if(userDetails?.userType==="SCHOOL_ADMIN"){
            if(filterUserClasses.length===0){
                alert("Class should be selected");
                return
            }
            
        }else if( userDetails?.userType==="TEACHER"){
            if(filterUserClasses.length===0){
                alert("Class should be selected");
                return
            }

        }else if(userDetails?.userType==="DISTRICT"){//from log in
            if(SelectSchool.length===0){
                alert("School should be selected");
                return
            }
        if(userType!=="SchoolAdmin"){
            // if(filterUserClasses.length===0){
            //     alert("Class should be selected");
            //     return
            // }
        }
    }
       
      
        // if(assesmentSubject===""){
        //     alert("Assesment Subject should be selected"); 
        //     return
        // }
        if (!NoTimeLimit&&assesmentStartTime >= assesmentEndTime) {
            alert("Start Time should be less than End Time");
            return false;
        }
        // if(SelectSchool==""){
        //     alert("School should be selected");
        //     return
        // }
        /*//as of now no validation for chap/topic selection
        // if(assesmentChapter===""){
        //     alert("assesmentChapter should be selected");
        //     return
        // }
        // if(assesmentTopic===""){
        //     alert("assesmentTopic should be selected");
        //     return
        // }
        //  if(assesmentSubTopic===""){
        //     alert("assesmentSubTopic should be selected");
        //     return
        // }*/

        // if(userType!=="SchoolAdmin"){
        //     if(filterUserClasses.length===0){
        //         alert("Class should be selected");
        //         return
        //     }
        // }
       console.log("it is calling or not")
        assignUserList()
        setAssesmentPage("2");
        setMovetoTop(true)  
        return true;
    }
    const validateAssesmentPage2Random = (event) => {
        if (randomFilter.length === 0) {
            alert("Atleast one filter should be added");
            return false;
        }
        if (randomFilter.some((filter) => filter.difficultyLevel === "")) {
            alert("Difficulty Level cannot be NULL");
            return false;
        }
        if (randomFilter.some((filter) => filter.noOfQues <= 0)) {
            alert("No. of Questions should be greater than 0");
            return false;
        }
        return true;
    }
    // const[files,setFiles]=useState("")

    const validateAssesmentPage4Custom = () => {
        let isError=false
        inputQuesFields.forEach((obj)=>{
            if(obj.questionType!=="DocumentUpload"){
                if(obj.description===""){
                    // alert("a")
                    isError=true
                    return
                }
            }
            if(obj.questionType==="MCQ" || obj.questionType==="MSQ" ){
                if(obj.options.length<2 || obj.correctAnswer===""){
                    console.log("opyioms length errr")
                    isError=true
                    return
                }
                obj.options.forEach((optionObj)=>{
                    if(optionObj.option===""){
                        console.log("opyioms empty errr")
                        isError=true
                        return
                    }
                })
            }
          
            // if(obj.questionType==="DocumentUpload"){
            //     if(obj?.files===undefined || obj.files===""){
            //         alert("b")
            //         isError=true;
            //         return
            //     }
            //     // console.log("document",files)
            // }
            if(obj.difficulty==="" || obj.correctMarks===0){ //common
                console.log("common opyioms empty errr")

                isError=true;
                return
            }
            // alert("v")

        })
        return !isError;

    }
    
    
    function idGenerator(){
        return uuidv4()
    }
const[Error,setError]=useState({
    isError:false,
    statement:""
})
const[userEmptyCheckError,setUserEmptyCheckError]=useState({
    isError:false,
    statement:""
})
    const goToDashboard = (event) => {
        window.location.href = window.location.origin;
    }
const [movetoTop,setMovetoTop]=useState(false)

    // useEffect(())
    useEffect(()=>{
        function scrollToTop(){
            // console.log("caling inside")
            // window.scrollTo(0,0)
            document.getElementById("custom-wrapper").scrollIntoView({behavior:"smooth"})
        }
        function scrollToTopPage2(){
            document.getElementById("custom-wrapper-page2").scrollIntoView({behavior:"smooth"})
            // .scrollIntoView({behavior:"smooth"})    
        }
        if(Error?.isError){
            console.log("caling")
            alert("Fields can not be empty or options must be selected")
            // scrollToTop()
        }
        if(movetoTop){
            // setTimeout(()=>{
                // scrollToTopPage2()
                // setMovetoTop(false)
            // },6000)
            
        }
        // if(userEmptyCheckError?.isError){
        //     scrollToTop()
        // }
    },[Error,movetoTop])
    const generateAssesment = (event) => {
        if (assesmentType === "RANDOM") {
            if (validateAssesmentPage2Random()) {
                setAssesmentPage("3");
                //lets assume question is mixup with as easy and meduim
                /*
                let AssesmentQuestions=[]
                let easyCount=randomFilter[0].noOFQues//as of now
                let mediumCount=randomFilter[1].noOFQues
                fakedata.forEach((x)=>{ //fetching data using API
                   if(x=="easy" && easyCount!=0){
                        AssesmentQuestions.push(x)
                        easyCount--
                   }else if(x=="medium" && mediumCount!=0){
                        AssesmentQuestions.push(x)
                        mediumCount--
                   }
                })

                  */ 
                console.log("randomFilter",randomFilter)

            } else {
                return;
            }
        } else if (assesmentType === "CUSTOM") {
            if (validateAssesmentPage4Custom()) {
        
                dispatch({
                    type: "SET_LOADING",
                    loading: true,
                });
                // post api
                let newQuestionsArray=[]
                let inputQuestions=[...inputQuesFields]
                console.log("inputQuestions",inputQuestions)
                inputQuestions.forEach((x)=>{
                    let newObj
                    if(x.questionType==="MCQ"){
                        let index=0 //internal user restricted // need to be teted with index
                        let len=x.options.length
                        for(let i=0;i<len;i++){
                            if(x.options[i].id===x.correctAnswer){
                                console.log("index ==> ",index)
                                break
                            }else{
                                console.log("executing")
                                index++
                            }
                        }
                        newObj= {
                            "description":x.description,
                            "correct_marking":x.correctMarks,
                            "incorrect_marking":x.incorrectMarks,
                            "options":x.options,
                            "correct_answer":index,
                            "type": "mcq",
                            "difficulty":x.difficulty
                        }
                    }else if(x.questionType==="MSQ"){
                        let index=0
                        let correctAnsObj={}
                        let correctAnsArr=[]
                        x.correctAnswer.map((id)=>{
                            correctAnsObj[id]=id
                        })
                        // console.log("correctAnsObj ==> ",correctAnsObj)
                        x.options.map((y)=>{
                            if(correctAnsObj[y.id]){
                                correctAnsArr.push(index)
                                index++
                            }else{
                                console.log("executing msq")
                                index++
                            }
                        })
                        newObj={
                            "description":x.description,
                            "correct_marking":x.correctMarks,
                            "incorrect_marking":x.incorrectMarks,
                            "options":x.options,
                            "correct_answer":correctAnsArr,
                            "type": "msq",
                            "difficulty":x.difficulty
                        }
                        
                    }else if(x.questionType==="Subjective"){
                           newObj=  {
                            "description":x.description,
                            "correct_marking":x.correctMarks,
                            "incorrect_marking":x.incorrectMarks,
                            "type": "subjective",
                            "subjective_type": "any",
                            "difficulty":x.difficulty
                        }
                         if(x.isAnswerNumeric){
                            newObj["subjective_type"]="numeric"
                        }
                        if(x.wordsLimit){
                            newObj["word_limit"]=Number(x.wordsLimit)
                        }   
                    }else if(x.questionType==="DocumentUpload"){
                        newObj=  {
                         "description":x.description,
                         "correct_marking":x.correctMarks,
                         "incorrect_marking":x.incorrectMarks,
                         "type": "doc",
                         "difficulty":x.difficulty
                     }   
                 }
                newQuestionsArray.push(newObj)
                })

                let classIds=[]
                filterUserClasses.forEach((x,i)=>{
                 // param[`classes[${i}]`]=x.classid
                 classIds.push(x.classid)
                })
                let userIds=[]
                userList.forEach((x)=>{
                    if(x.checked){
                        userIds.push(x.userId)
                    }
                })
                console.log("userList",userList,"userIds",userIds );
    // let selectedSchoolsId=""//for single select
    // schoolList.forEach((x)=>{//for single select 
    //     if(x.value===SelectSchool){
    //         // console.log("insdie inside selectedSchoolsId 22==>",SelectSchool,x.label)  
    //         selectedSchoolsId=x.value
    //     }
    // })
    let selectedSchoolsId=SelectSchool.map(x=>x.value)
/*
  {
                title,
                created_by_id: user._id,//
                created_by_name: user.is_internal_super_user ? 'YIE' : user.name,//
                class: data.class,
                subject_id: data.subject_id ? data.subject_id : null,
                chapter_id: data.chapter_id ? data.chapter_id : null,
                content_id: data.content_id ? data.content_id : null,
                type: data.type,
                school: user.schoolId ? user.schoolId : data.school_id,
                started_at: data.started_at ? data.started_at : null,
                ended_at: data.ended_at ? data.ended_at : null,
                user_ids: data.user_ids,
                user_type: data.user_type,
                late_submission: data.late_submission ? data.late_submission : false,
                evaluate_mcqs: data.evaluate_mcqs ? data.evaluate_mcqs : false,
            }

*/
let bodyObj={
    "title": assesmentName,//name
    "type":"quiz",
    "class": classIds,//["37d65615-14f6-4b9d-82ed-7e39530c8964"]
    "user_ids":userIds,//["37d65615-14f6-4b9d-82ed-7e39fdfdfdfdf0c8964"]
    "started_at": new Date(assesmentStartTime?.$d).getTime(),//1671849962624
    "late_submission": isLateSubmission,//true
    "evaluate_mcqs": isAutoEvaluate,//true
    "user_type":ChangeStandardWords(userType),//STUDENT//userType changes need to be cap
    "questions":newQuestionsArray,//above ref,
    "school":selectedSchoolsId, //"difhifhf845f41fdf1dfdff",
    "subject_id":assesmentSubject// need  to confirm  array of sting or string 
}
if(!NoTimeLimit){
    bodyObj["ended_at"]=new Date(assesmentEndTime?.$d).getTime()//1671849962624
}
// console.log("classIds",classIds,"userIds",userIds);

console.log("bodyObj======>",bodyObj,"userList",userList)
Api.post("/assessment/create-assessment", bodyObj).then((res) => {
    console.log("bodyObj and res =====>",bodyObj, res)
    setAssesmentPage("5");
    setError((pre)=>{
        return{
            ...pre,
            isError:false,
            statement:""
        }
    })
}).catch((err) => {
    console.log("err while sending",err);
})
dispatch({
    type: "SET_LOADING",
    loading: false,
});

            
                console.log("before gtherd question",inputQuesFields) //need review
                // inputQuesFields.forEach((quesObj)=>{ //as of now not need
                //     if(quesObj.questionType==="DocumentUpload"){
                //         let formData=new FormData();
                //         console.log("empty form ", formData)
                //         // for(let key of Object.keys(quesObj.files)){
                //         //     console.log("quesObj.files[key]",quesObj.files[key])
                //         //     formData.append("docs",quesObj.files[key])
                //         // }
                //         // console.log("formdata",formData)
                //         // quesObj.files=formData
                //         // console.log("typeof",typeof quesObj.files);
                //         for(let i=0;i<quesObj.files.length;i++){
                //             // console.log("here",quesObj.files[i], i);
                //             formData.append("docs",quesObj.files[i])
                //         }
                //         quesObj.files=formData
                //         for(let key of formData.entries()){
                //             console.log("key==>",key,key[0],"form dta formData ==>",key[1]);
                //         }

                //     }
                // })                
                /*
                Api.post("/url",ToBeSendData)
                
                */
                // console.log("after gtherd question",inputQuesFields)
                // console.log("testing ===>")

                // console.log("gtherd question",files)
                // inputQuesFields.forEach((quesObj)=>{
                //     if(quesObj.questionType==="DocumentUpload"){
                //         for(let key of quesObj.files.entries()){
                //             console.log("key==>",key,key[0],"form dta formData ==>",key[1]);
                //         }

                //     }
                // })  

            } else {
                setError((pre)=>{
                    return{
                        ...pre,
                        isError:true,
                        statement:"Fields can not be empty or options is not selected"
                    }
                }) 
                return;
            }
        } else {   
            return;
        }
    }
    // const handeleCreateAssesment = (event) => {
    //     return {
    //         assesmentName: assesmentName,
    //         assesmentType: assesmentType,
    //         assesmentClass: assesmentClass,
    //         assesmentSubject: assesmentSubject,
    //         assesmentChapter: assesmentChapter,
    //         assesmentTopic: assesmentTopic,
    //         assesmentSubTopic: assesmentSubTopic,
    //         assesmentStartTime: assesmentStartTime,
    //         assesmentEndTime: assesmentEndTime,
    //         randomFilter: randomFilter,
    //         inputQuesFields: inputQuesFields
    //     }
    // }
    // const [classList, setClassList] = useState([]); //dynamic
    const [filterUserClasses, setFilterUserClasses] = useState([]);
    // const [filterUserSubjects, setFilterUserSubjects] = useState([]);
    const [{userDetails }, dispatch] = useDataLayerValue();

    const userTypes=UserTypes(userDetails)
        console.log("userTypes userTypes  ==> ",userTypes)

    const [subjects,setsubjects]=useState([])
    useEffect(()=>{
        Api.get(`/preconfig-entites/subjects`).then((res)=>{
            console.log("res===>",res.data)
            let fetchedSubjects=[...res.data]
            let updatedSub=[]
            fetchedSubjects.forEach((x)=>{
                let obj={}
                obj.label=x.name
                obj.value=x._id
                updatedSub.push(obj)
            })
            setsubjects(()=>updatedSub)
        })
    },[])
 useEffect(()=>{
    console.log("assesmentEndTime",assesmentEndTime?.$d)
    // console.log("type assesmentEndTime",typeof(assesmentEndTime?.$d))
    // console.log("end ==> ",new Date(assesmentEndTime?.$d).getDate());
    // console.log(`${new Date(assesmentStartTime?.$d).getDate()}/${new Date(assesmentStartTime?.$d).getMonth()+1}/${new Date(assesmentStartTime?.$d).getFullYear()}`)
    // console.log(`${new Date(assesmentEndTime?.$d).getDate()}/${new Date(assesmentEndTime?.$d).getMonth()+1}/${new Date(assesmentEndTime?.$d).getFullYear()}`)

 },[assesmentEndTime,assesmentStartTime])
    // const[userAssigningList,setUserAssigningList]=useState([])
    const [userList, setUserList] = useState([]);

    useEffect(()=>{
        console.log("filterUserClasses ==> ",filterUserClasses)
    },[filterUserClasses])
    // const[schoolId,setSchoolId]=useState()
    // useEffect(()=>{
        // console.log("userType",userType)
        function assignUserList(){ //useEfect will not work coz of async fucntion updating classs in delay leads to all users fetching
            dispatch({
                type: "SET_LOADING",
                loading: true,
            });
            const user={
                "Teacher" :'TEACHER',
                "Student" : 'STUDENT',
                "SchoolAdmin" : 'SCHOOL_ADMIN'
            }
            console.log("it is coming ")
            if(SelectSchool&&userType!=="SchoolAdmin"&&userDetails.userType==="DISTRICT"){
                // let selectedSchoolsId=""
                // schoolList.forEach((x)=>{
                //     if(x.value===SelectSchool){
                //         selectedSchoolsId=x.value
                //         console.log("insdie inside selectedSchoolsId 22==> ",selectedSchoolsId)  
    
                //     }
                // })
                let isAllSelected=false
                let selectedSchoolsId=SelectSchool.map((x)=>{
                    if(x.value==="All"){
                        isAllSelected=true
                    }
                    return x.value
                })
                
                // console.log("userType userType=======>>>==>",userType,user[userType]);
                // console.log("selectedSchoolsId",selectedSchoolsId, SelectSchool)
                // let fetchedUserData=[]
                // if(userType!=="SchoolAdmin" && filterUserClasses?.length!==0){
                    // let param = { userType:user[userType],schoolId:selectedSchoolsId}bodyObj
                    let bodyObj = { userType:user[userType]}
                    //let bodyObj = { userType:user[userType]}
                    if(!isAllSelected){
                        bodyObj.school_ids =selectedSchoolsId
                    }
                    let classIds=[]
        //             console.log("classList",classList);
        // console.log("overAllClassList",overAllClassList);
                    if(filterUserClasses.length!==0&&!isAllSelected&&SelectSchool.length===1){
                        classIds=filterUserClasses.map((x)=>x.classid)
                        bodyObj.class_ids =classIds                        
                    }

                    if(filterUserClasses.length!==0&&!isAllSelected&&SelectSchool.length>1){
                        let selectedClass=new Set()
                        filterUserClasses.map((x)=>selectedClass.add(x.class_name_section))
                        console.log("---------->",selectedClass)
                        classIds=overAllClassList.filter((x)=>{
                            if(selectedClass.has(x.class_name_section)){
                                return true
                            }
                            return false
                        })
                        bodyObj.class_ids =classIds                      
                    }
                    console.log("body obj", bodyObj ,classIds)
                    // forClasses.map((cl, i) => { 
                    //     param[`classes[${i}]`] = cl._id;
                    // })
                    // console.log("filter",filterUserClasses,"overall",classList)
                //    let ids=[]//as of now api is not developed for classes , informed ghosh
                //     if(userType!=="SchoolAdmin" && filterUserClasses?.length!==0){
                //         filterUserClasses.forEach((x,i)=>{
                //             // param[`classes[${i}]`]=x.classid
                //             // let id=x.classid
                //             // let obj={}
                //             param[`classes[${i}]`]=x.classid
                //             // ids.push(obj)
                //         })
            // console.log(ids)
                //    param.classes=ids
    
                //    let idsString=""
                //    ids.forEach((x,i)=>{
                //     if(i!==ids.length-1){
                //         idsString+=x+","
                //     }else{
                //         idsString+=x
                //     }
                //    })
                //    console.log("idsString==>",idsString, ids)
                // console.log("param testing 1==> ",param);//test1
                    // Api.get(`/user/users-data`,{ params: param }).then((res)=>{
                    //     // console.log("r==============>",res.data);
                    //     // console.log("1 ==>with class use  required===>",res.data)
                    //      fetchedUserData=[...res.data]
                    //      let requiredUserDetails=[]
                    //      fetchedUserData.forEach((x)=>{
                    //          let obj={}
                    //          obj.name=x.name
                    //          obj.userType=x.userType
                    //          obj.username=x.username
                    //          obj.checked=false
                    //          obj.userId=x._id
                    //          requiredUserDetails.push(obj)
                    //      })
                    //     //  requiredUserDetails.push({//dev testing 
                    //     //     name:"dev student",
                    //     //     userType:"STUDENT",
                    //     //     username:"dev username",
                    //     //     userId:"2fdb0144-4d14-4737-9e2f-14833f55340d",
                    //     //     checked:true
                    //     //  })
                    //      console.log(" with class use  required===>", requiredUserDetails)
                    //      setUserList(()=>requiredUserDetails)     
                    // })
                    Api.post("/user/get_school_wise_user", bodyObj).then((res) => {
                        let fetchedUserData=[...res.data]
                        let requiredUserDetails=[]
                        fetchedUserData.forEach((x)=>{
                            let obj={}
                            obj.name=x.name
                            obj.username=x.username
                            obj.userType=x.userType
                            obj.checked=false
                            obj.userId=x._id
                            requiredUserDetails.push(obj)
                        })
                        setUserList(()=>requiredUserDetails)
                        dispatch({
                            type: "SET_LOADING",
                            loading: false,
                        })
                    })
                    .catch((err)=>{
                        console.log("err",err)
                    })
                    }else if(userType==="SchoolAdmin"&&userDetails.userType==="DISTRICT"){
                        // Api.get(`/user/users-data/?schoolId=${selectedSchoolsId}&userType=${user[userType]}`).then((res)=>{
                        //     // console.log(" admin user  required===>",res.data) SCHOOL_ADMIN
                        //     fetchedUserData=[...res.data]
                        //     let requiredUserDetails=[]
                        //     fetchedUserData.forEach((x)=>{
                        //         let obj={}
                        //         obj.name=x.name
                        //         obj.username=x.username
                        //         obj.userType=x.userType
                        //         obj.checked=false
                        //         obj.userId=x._id
                        //         requiredUserDetails.push(obj)
                        //     })
                        //     console.log(" admin user required===> 2", requiredUserDetails)
        
                        //     setUserList(()=>requiredUserDetails)
                        // })
                        let isAllSelected=false
                        let selectedSchoolsId=SelectSchool.map((x)=>{
                            if(x.value==="All"){
                                isAllSelected=true
                            }
                           return x.value
                        })
                    let bodyObj = { userType:user[userType]}
                    if(!isAllSelected){
                            bodyObj.school_ids =selectedSchoolsId
                        }
                        Api.post("/user/get_school_wise_user", bodyObj).then((res) => {
                            let fetchedUserData=[...res.data]
                            let requiredUserDetails=[]
                            fetchedUserData.forEach((x)=>{
                                let obj={}
                                obj.name=x.name
                                obj.username=x.username
                                obj.userType=x.userType
                                obj.checked=false
                                obj.userId=x._id
                                requiredUserDetails.push(obj)
                            })
                            setUserList(()=>requiredUserDetails)
                            dispatch({
                                type: "SET_LOADING",
                                loading: false,
                            })
                        })
                        .catch((err)=>{
                            console.log("err",err)
                        })
                    }
                  
                else if(SelectSchool?.length===0&&userDetails.userType!=="DISTRICT") {
                    let ids=[]
                    console.log("filterUserClasses",filterUserClasses)
                        // let param= {
                        //             "schoolId" : userDetails?.schoolId,
                        //             "userType" : user[userType],
                        //         }
                        let bodyObj= {
                            "school_ids" :[userDetails?.schoolId],
                            "userType" : user[userType],
                        }
                    //    filterUserClasses.forEach((x,i)=>{
                    //     // param[`classes[${i}]`]=x.classid
                    //     let id=x.classid
                    //     // let obj={}
                    //     param[`classes[${i}]`]=x.classid
                    //     // ids.push(obj)
                    //    })
                    //    console.log("ids",param,filterUserClasses)
                        // Api.get(`/user/users-data/`, {
                        //     params:param
                        // })
                    Api.post("/user/get_school_wise_user", bodyObj)
                        .then((res)=>{
                            // console.log(" admin user  required===>",res.data)
                            let fetchedUserData=[...res.data]
                            let requiredUserDetails=[]
                            fetchedUserData.forEach((x)=>{
                                let obj={}
                                obj.name=x.name
                                obj.username=x.username
                                obj.userType=x.userType
                                obj.checked=false
                                obj.userId=x._id
                                requiredUserDetails.push(obj)
                            })
                            console.log(" admin user required===> 2", requiredUserDetails)

                            setUserList(()=>requiredUserDetails)
                            dispatch({
                                type: "SET_LOADING",
                                loading: false,
                            })
                        }).catch((err)=>{
                            console.log("err",err)
                        })
                    }
        }
     


        // console.log("value SelectSchool ",SelectSchool)
 
    // },[SelectSchool,filterUserClasses,userType])

useEffect(()=>{
console.log("select school",SelectSchool );
let isAllSelected=false
SelectSchool?.map((x)=>{
    if(x.label==="ALL"){
        isAllSelected=true
    }
})
    if(!isAllSelected || userDetails?.userType==="SCHOOL_ADMIN"|| userDetails?.userType==="TEACHER" ){
        let selectedSchoolsId=[]
        if(userDetails?.userType==="DISTRICT"){
            selectedSchoolsId=SelectSchool.map((x)=>x.value)//as of mow
            // schoolList.forEach((x)=>{
            //     if(x.value===SelectSchool){
            //         selectedSchoolsId=x.value
            //     }
            // })
        }else{
            selectedSchoolsId=[userDetails.schoolId]
        }
        console.log("selectedSchoolsId <--",selectedSchoolsId)
        if(selectedSchoolsId.length===1){

        Api.get(`/school-entity/all-classes/?school_id=${selectedSchoolsId[0]}`).then((res)=>{
            let sectionWise=[...res.data]
            let classListFetched=[]
            sectionWise.forEach((x)=>{
                let obj={}
                obj.class_name_section=x.class_name_section
                obj.class_name=x.class_name
                obj.classid=x._id
                classListFetched.push(obj)
            })
            setClassList(()=>classListFetched)
            setOverAllClassList(()=>classListFetched)
            // setFilterUserClasses(()=>[])
            // console.log(sectionWise,"<=======classListFetched=======>");
        }).catch((err)=>{
            console.log("err",err)
        })
    }else{
        let overallClass=[]
        let count=0
        let commonClass=new Set()
        let classListFetched=[]
        if(selectedSchoolsId.length>1){

        (async function() {
            for await (const schId of selectedSchoolsId){
                Api.get(`/school-entity/all-classes/?school_id=${schId}`).then((res)=>{
                    let sectionWise=[...res.data]
                    sectionWise.forEach((x)=>{
                        let obj={}
                        obj.class_name_section=x.class_name_section
                        obj.class_name=x.class_name
                        obj.classid=x._id
                        overallClass.push(obj)
                        if(!commonClass.has(x.class_name_section)){
                            classListFetched.push(obj)
                        }
                        commonClass.add(x.class_name_section)
                    })
                    if(count===selectedSchoolsId.length-1){
                        setClassList(()=>classListFetched)
                        setOverAllClassList(()=>overallClass)
                    }
                    count++
                    // setFilterUserClasses(()=>[])
                    // console.log(sectionWise,"<=======classListFetched=======>");
                }).catch((err)=>{
                    console.log("err",err)
                })
            }
          })();
    }
    
}



    }

},[SelectSchool])


  
    // const [subjectList, setSubjectList] = useState([]);

    // useEffect(() => {
    //    if (userDetails?.schoolId) {
    //    (async function getClassAndSubject(){
    //         await Api.get("school-entity/all-classes?school_id=" + userDetails?.schoolId).then((res) => {
    //         setClassList(res.data);
    //         setFilterUserClasses([])
    //     })
    //     await Api.get("preconfig-entites/subjects").then((res) => {
    //         res.data.sort((a, b) => a.name.localeCompare(b.name));
    //         setSubjectList(res.data);
    //     })
    //     })()

           
    //     } else {
    //         setClassList([]);
    //         setFilterUserClasses([])
    //     }
    // }, [userDetails]);
    // function handleChange(e){
    //     console.log("e2 ==>",e)
    // }
 
const [assessmentType,setAssessmentType]=useState([
    questionTypes[2]  
])


const [inputQuesFields, setQuesInputFields] = useState(
    [AddtionalQuesFormat(uuidv4(),uuidv4(),uuidv4(),assessmentType)])
    useEffect(()=>{
        console.log("inputQuesFields",inputQuesFields);
    },[inputQuesFields])
function validateUserSelection(){
    let isError=false
    let notCheckedCount=0
    userList?.forEach((obj)=>{
        if(!obj.checked){
            notCheckedCount++
        }
    })
if(notCheckedCount===userList?.length){
    isError=true
}
    if(isError){
        setUserEmptyCheckError({
            isError:isError,
            statement:"minimum one wil be selected"
        })
    }

    if(!isError){
        console.log("moving tpo 3")
        setAssesmentPage("3")
    }

}
function selectAssessmentType(e){//no functionality  
console.log("selecrted",e.target.value)
let selectedValue=e.target.value
// console.log("selecrted",e.target)
let SelectedObjoptions=[...questionTypes].find((obj)=>obj.value===selectedValue)
// console.log("ops",SelectedObjoptions)

setAssessmentType([SelectedObjoptions])
if(selectedValue!=="MixedMcqSub"){
    setQuesInputFields(inputQuesFields.map(inputQuestion =>{
        return{...inputQuestion,questionType: e.target.value }
    }))
}else if(selectedValue==="MixedMcqSub"){
    setQuesInputFields(inputQuesFields?.map(inputQuestion =>{
        return{...inputQuestion,questionType:"MCQ" }
    }))
}
// else if(selectedValue==="Subjective"){
  
// }
// console.log("seleted array",[SelectedObjoptions])
// setQuesInputFields(inputQuesFields.map(inputQuestion =>{
//     return{ questionType: e.target.value }
// }))
// setAssessmentType()
}
const[isNumeric,setNumeric]=useState(false)
const[orderElements,setOrderElements]=useState(AssigningShowByOrder)
const[AssessmentOrderElements,setAssessmentOrderElements]=useState(AssessmentShowByOrder)
const muiClasses=muiUseStyles()


 const setAllVariablesToDefault = (event) => {
    // console.log(handeleCreateAssesment(event));
    setAssesmentPage("1");
    setAssesmentType("");
    setAssesmentName("");
    setuserType("");
    setSelectSchool([]);
    // setAssesmentClass("ALL");
    setAssesmentSubject("");
    // setSchoolList([])//it emty whoel 
    if(userDetails.userType==="DISTRICT"){
        setClassList([])
    }
    setAssesmentChapter("");
    setAssesmentTopic("");
    setNoTimeLimit(false)
    setUserList([])
    setFilterUserClasses([])
    setError({
        isError:false,
        statement:""
    })
    // setsubjects([])
    setUserEmptyCheckError({
        isError:false,
        statement:""
    })
    setAssesmentSubTopic("");
    setAssessmentType([
        questionTypes[2]  
    ])
    setAssesmentStartTime(dayjs());
    let updatedEndTime=dayjs().set('minute',dayjs().get('minute')+2)
    setAssesmentEndTime(updatedEndTime);
    setRandomFilter([{ id: uuidv4(), difficultyLevel: "ALL", noOfQues: 1 }]);
    setQuesInputFields(
        [AddtionalQuesFormat(uuidv4(),uuidv4(),uuidv4(),assessmentType)]
    )
    setNumeric(false)
    setOrderElements(AssigningShowByOrder)
    setAssessmentOrderElements(AssessmentShowByOrder)
    setMovetoTop(false)
    // setError((pre)=>{
    //     return{
    //         ...pre,
    //         isError:false,
    //         statement:""
    //     }
    // })
 }
const[isAutoEvaluate,setIsAutoEvaluate]=useState(true)
const[isLateSubmission,setIsLateSubmission]=useState(false)
useEffect(()=>{
    console.log("userList",userList);
},[userList])
function showClassList(list){
    let isAllSelected=false
    list.map((x)=>{
        if(x.label==="ALL"){
            isAllSelected=true
        }
    })
    if(isAllSelected){
        setOrderElements((pre)=>{
            let updated=[...pre]  
           updated[2].selected=false
            return updated
       
      })
    }else{
        setOrderElements((pre)=>{
            let updated=[...pre]  
           updated[2].selected=true
            return updated
       
      })
    }
}
return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            className="mb-5"
        >
            {assesmentPage==="1" &&
                <>

                     <div  className="mt-3 ms-auto me-auto col-10 col-lg-9 d-flex justify-content-end flex-row align-items-center"  style={{ borderRadius: 10}}>
                            <Button  className=" ms-1 me-1 mb-3" variant="secondary" size="lg" onClick={(e) => validateAssesmentPage1(e)}>
                               NEXT
                            </Button>
                        </div>
                    <h1 align="center">Create Assesment</h1>
                    <center >
                        <div  className="ps-4 pe-4 pt-4 pb-2 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                        <h4 style={{display:"flex",justifyContent:"flex-start"}} className="ps-2 pe-2 mt-4 mb-3">Assessment Name</h4>
                            <TextField
                                id="assesmentName"
                                name="assesmentName"
                                // label="Assesment Name"
                                placeholder="Enter Assesment Name Here"
                                // variant="filled"
                                value={assesmentName}
                                onChange={e => handleAssesmentNameChange(e)}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div className="ps-4 pe-4 pb-3 col-10 col-lg-9" style={{ borderRadius: 10}} align="center">
                        <h4 style={{display:"flex",justifyContent:"flex-start"}} className="ps-2 pe-2 mt-4 mb-3">Assign To</h4>
                        <Grid container>
                            <Grid item sm={12} md={12} lg={6} xs={12}  >
                            {orderElements[0].selected&&
                                <TextField //1
                                id="userType"
                                name="userType"
                                select
                                sx={{width:"100%"}}
                                // style={{backgroundColor:"yellow"}}
                                label="&nbsp;&nbsp;&nbsp;Select user type"
                                value={userType}
                                onChange={e => handleUserTypeChange(e)}
                                // variant="filled"
                            >
                                {userTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value} style={{ width: "100%" }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
}
                            </Grid>
                           
                            {orderElements[1].selected&&!(userDetails.userType==="SCHOOL_ADMIN") &&!(userDetails.userType==="TEACHER")&&
                            <Grid item >
                                 <MultiselectReactDropDown
                                                showArrow
                                                name="multiSelectReact"
                                                style={{searchBox:{border:"none",maxWidth:"280px",width:"250px",borderBottom:"1px solid rgba(0, 0, 0, 0.5)",borderRadius:"0px"}}}
                                                options={schoolList}
                                                displayValue="label"
                                                // className="mt-2"
                                                placeholder="&nbsp;&nbsp;&nbsp;Select the School"
                                                // groupBy="class_name"
                                                showCheckbox={true}
                                                selectedValues={SelectSchool}
                                                onSelect={(selectedList,item) => { 
                                                    // console.log("selectedList",item)
                                                    // console.log("SelectSchool",SelectSchool)
                                                    let isAllSelected=false
                                                    SelectSchool.forEach((x)=>{
                                                        if(x.label==="ALL"){
                                                            isAllSelected=true
                                                        }
                                                    })
                                                    showClassList(selectedList)
                                                    if(!isAllSelected){
                                                        console.log("its is working ",selectedList)
                                                        setSelectSchool(selectedList)
                                                    }else{
                                                        // console.log("SelectSchool pre", SelectSchool)
                                                        // setSelectSchool((pre)=>[...SelectSchool])
                                                        setSelectSchool(selectedList)
                                                        alert("You already selected ALL option")
                                                    }
                                                     }}
                                                onRemove={(selectedList, selectedItem) => { setSelectSchool(selectedList) }}
                                                />
                            {/* <TextField //2
                                id="SelectSchool"
                                name="SelectSchool"
                                select
                                sx={{}}//width:"100%", height:"160px"
                                // style={{backgroundColor:"blue"}}//height:"160px"
                                // placeholder="Select School"
                                label="&nbsp;&nbsp;&nbsp;Select School"
                                // value="ughubhujhj"
                                value="Hello World"
                                // variant="filled"
                            >
                            <div variant="menu" style={{overflow:"scroll", height:"160px"}}>
                                {schoolList.map((option) => (
                                    <MenuItem  key={option.value} data-label={option.label} data-valueId={option.valueId} value={option.value} onClick={e => handleSelectSchoolChange(e)} >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </div>
                            </TextField> */}

                            </Grid>
                        }
                        {/* </Grid> */}
                       
                           
                            {/* {userType!=="SchoolAdmin"&&
                            <> */}
             
        
                        {/* {(subjectList.length !== 0) &&
                                    <Dropdown >
                                    <Dropdown.Toggle>
                                            Select Subject
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu onSelect={(e)=>handleChange(e)}>
                                        {subjectList.map((obj)=>{
                                            return (
                                                <Dropdown.Item>
                                                {obj?.name}
                                            </Dropdown.Item>
                                            )
                                        })
                                        }
                                    </Dropdown.Menu>
                                    </Dropdown>
                     
                    } */}




                                                {userType!=="SchoolAdmin"&&

                                    // <Grid container>
                                        <Grid className="mt-2" item sm={12} md={12} lg={6} xs={12} style={{display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}  >
                                                <>
                                                {/* <Container
                                                maxWidth="md"
                                                // sx={{maxWidth:"md"}}
                                                // id="assesmentClass"
                                                sx={{width:"50%"}}

                                                // name="assesmentClass"
                                                className="w-30 p-0 m-0"
                                                // select
                                                // label="Select Class"
                                                // // value={assesmentClass}
                                                // // onChange={e => handleAssesmentClassChange(e)}
                                                variant="filled"
                                                >  */}
                                                {/* {classes.map((option) => (
                                                <MenuItem key={option.value} value={option.value} style={{ width: "100%" }}>
                                                {option.label}
                                                </MenuItem>
                                                ))} */}
                                                {orderElements[2].selected&&(SelectSchool!=="ALL")&&
                                                <MultiselectReactDropDown
                                                //3 
                                                showArrow
                                                name="multiSelectReact"
                                                style={{searchBox:{border:"none",maxWidth:"280px",width:"250px",borderBottom:"1px solid rgba(0, 0, 0, 0.5)",borderRadius:"0px"}}}
                                                options={classList}
                                                displayValue="class_name_section"
                                                // className="mt-2"
                                                placeholder="&nbsp;&nbsp;&nbsp;Select the Class"
                                                groupBy="class_name"
                                                showCheckbox={true}
                                                selectedValues={filterUserClasses}
                                                onSelect={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                                                onRemove={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                                                />
}
                                                {/* 
                                                {(classList.length !== 0) &&
                                                <MultiselectReactDropDown
                                                showArrow
                                                className="pt-2 pb-2 col-3"
                                                options={classList}
                                                displayValue="class_name_section"
                                                groupBy="class_name"
                                                showCheckbox={true}
                                                selectedValues={filterUserClasses}
                                                onSelect={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                                                onRemove={(selectedList, selectedItem) => { setFilterUserClasses(selectedList) }}
                                                style={{
                                                marginTop: "0",
                                                }}
                                                />
                                                } */}
                                                {/* </Container> */}
                                                </>
                                    </Grid>

                                  




}
</Grid>


        <h4 style={{display:"flex",justifyContent:"flex-start"}} className="ps-2 pe-2 mt-4 mb-3" >Assessment Details</h4>

                    <Grid container>
                    <Grid item sm={12} lg={6} md={12} xs={12}>
                    {AssessmentOrderElements[0].selected&&
                                        <TextField //4// in grid css to make down for multiselect===>className={"mt-2 d-flex flex-column justify-content-end align-items-center"}
                                        id="assesmentSubject"
                                        name="assesmentSubject"
                                        select
                                        label="&nbsp;&nbsp;&nbsp;Select Subject"
                                        // style={{maxHeight:"250px"}}
                                        // marginThreshold={0}
                                        placeholder="Select Subject"
                                        onChange={e => handleAssesmentSubjectChange(e)}
                                        // value={
                                        //     subjects?.filter((x)=>{
                                        //         if(x.value===assesmentSubject){
                                        //             return true
                                        //         }
                                        //         return false
                                        //     })[0]?.label
                                        // }
                                        // variant="filled" //testb
                                        // className={classes.root}       
                                        // InputProps={{className:muiClasses.HeightPop}}
                                    >
                                        {/* <div style={{height:"250px"}}> */}
                                        {subjects.map((option) => (
                                            <MenuItem key={option.value} 
                                            data-subids={option.value}
                                            value={option.value} style={{ width: "100%" }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        {/* </div> */}
                                    </TextField>
                                        /* <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // value={age}
                                            label="Age"
                                            // onChange={handleChange}
                                        >
                                              {subjects.map((option) => (
                                            <MenuItem key={option.value} value={option.value} style={{ width: "100%" }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                        </Select> */
            }
                                    </Grid>
                            <Grid item sm={12} md={12} lg={6} xs={12} gap={2} >
                            {/* {AssessmentOrderElements[1].selected&&
                                <TextField //5
                                id="assesmentChapter"
                                name="assesmentChapter"
                                select
                                label="&nbsp;&nbsp;&nbsp;Select Chapter"
                                value={assesmentChapter}
                                onChange={e => handleAssesmentChapterChange(e)}
                                // variant="filled"
                            >
                                {chapters.map((option) => (
                                    <MenuItem key={option.value} value={option.value} style={{ width: "100%" }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
            } */}
                            </Grid>
                            <Grid item sm={12} lg={6} md={12} xs={12}>
                                {/* {AssessmentOrderElements[2].selected&&

                            <TextField //6
                                id="assesmentTopic"
                                name="assesmentTopic"
                                select
                                label="&nbsp;&nbsp;&nbsp;Select Topic"
                                value={assesmentTopic}
                                className="mt-2"
                                onChange={e => handleAssesmentTopicChange(e)}
                                //variant="filled"
                            >
                                {topics.map((option) => (
                                    <MenuItem key={option.value} value={option.value} style={{ width: "100%" }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            
                        } */}
                           
                            </Grid>
                            <Grid item sm={12} lg={6} md={12} xs={12}>
                            {/* {AssessmentOrderElements[3].selected&&///as of now no subtopic

                            <TextField //7
                                id="assesmentSubTopic"
                                name="assesmentSubTopic"
                                select
                                className="mt-2"
                                label="&nbsp;&nbsp;&nbsp;Select Sub-Topic"
                                value={assesmentSubTopic}
                                onChange={e => handleAssesmentSubTopicChange(e)}
                                //variant="filled"
                            >
                                {subTopics.map((option) => (
                                    <MenuItem key={option.value} value={option.value} style={{ width: "100%" }}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
} */}
                                  
                            </Grid>
                        </Grid>
                        <h4 style={{display:"flex",justifyContent:"flex-start"}} className="ps-2 pe-2 mt-4 mb-3">Evaluation</h4>
                        <Grid container >
                            <Grid item sm={12} md={4} lg={6} xs={12} className={"ms-5 mb-3"} >
                            <Form.Check>
                                <Form.Check.Input checked={isAutoEvaluate}
                                onChange={()=>setIsAutoEvaluate((pre)=>!pre)}
                                /> 
                                <Form.Check.Label className="float-start" >Auto Evaluate mcq, msq</Form.Check.Label>
                            </Form.Check>
                            </Grid>
                        </Grid>
                        <h4 style={{display:"flex",justifyContent:"flex-start"}} className="ps-2 pe-2 mt-4 mb-3">Assessment Duration</h4>

                    <LocalizationProvider  dateAdapter={AdapterDayjs}>
                        <Grid container>
                            <Grid item sm={12} md={4} lg={6} xs={12}  >
                                    <DateTimePicker
                                            label="Start Date & Time"
                                            renderInput={(params) => <TextField {...params} />}
                                            value={assesmentStartTime}
                                            onChange={(e) => {
                                                handleAssesmentStartTimeChange(e);
                                            }}
                                        />
                                </Grid>
                                    <Grid  className="mb-4" item sm={12} lg={6} md={12} xs={12}>
                                    { !NoTimeLimit&&
                                            <DateTimePicker
                                            label="End Date & Time"
                                            renderInput={(params) => <TextField {...params} />}
                                            value={assesmentEndTime}
                                            onChange={(e) => {
                                                handleAssesmentEndTimeChange(e);
                                            }}
                                        />
                                        }
                                    </Grid>
                                    <Grid container >
                            <Grid item sm={12} md={4} lg={6} xs={12} className={"ms-5 mb-3"} >
                            <Form.Check>
                                <Form.Check.Input checked={NoTimeLimit}
                                onChange={()=>handleNoTimeLimitChange()}
                                /> 
                                <Form.Check.Label className="float-start" >No Time Limit</Form.Check.Label>
                            </Form.Check>
                            </Grid>
                        </Grid>
                                    <Grid container >
                            <Grid item sm={12} md={4} lg={6} xs={12} className={"ms-5 mb-3"} >
                            {!NoTimeLimit&&
                            <Form.Check>
                            <Form.Check.Input checked={isLateSubmission}
                            onChange={()=>setIsLateSubmission((pre)=>!pre)}
                            /> 
                            <Form.Check.Label className="float-start" >Late Submission</Form.Check.Label>
                            </Form.Check>
                            }

                            </Grid>
                        </Grid>
                        </Grid> 
                    </LocalizationProvider>
                           
                    </div>
                   
                    </center>
                </>
            }
              {assesmentPage === "2" &&
                <>
                <center  id="custom-wrapper-page2" 
                     > 
                    <div className="shadow-lg p-4 col-12 col-sm-10 col-lg-9 mb-4" style={{ borderRadius: 10, backgroundColor: "#FFF" }} align="center">
										<Stack className="row" direction="horizontal">
											<div className="col-12 col-sm-6" style={{ display: 'flex'}}>
												<Button variant="light" style={{ backgroundColor: "#FFF", color: "#6c757d" }} 
                                                // onhover={(e) => doNothing()} 
                                                onClick={(e) => {
													setAssesmentPage("1")
													// setSectionIds(new Set())
												}} >
													← Previous
												</Button>
											</div>
											<div className="col-12 col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }}
                                                 onClick={() =>validateUserSelection()}  >
													Next
												</Button>
											</div>
										</Stack>
										<h2 className="mt-4">List of Users</h2>
										<div>
											<Button className="m-4" variant="outline-secondary" onClick={e => setUserList(()=>userList?.map(us => ({ ...us, checked: false })))} style={{ minWidth: '150px' }} >
												Un-Check All
											</Button>
											<Button className="m-4" variant="outline-secondary" onClick={e => setUserList(()=>userList?.map(us => ({ ...us, checked: true })))} style={{ minWidth: '150px' }} >
												Check All
											</Button>
										</div>
                                        {userEmptyCheckError?.isError &&
                                            <Box
                                            sx={{color:"error.main"}}
                                            noValidate
                                            className="mb-1 mt-2 w-50"
                                        >
                                            {userEmptyCheckError.statement}
                                        </Box>
                                            }
										<Table striped bordered hover responsive className="mt-4 mb-4">
											<thead>
												<tr>
													<td><b>Sr. No.</b></td>
													<td><b>Name</b></td>
													<td><b>Username</b></td>
													<td><b>Check/Uncheck</b></td>
												</tr>
											</thead>
											<tbody>
												{userList?.map((user, i) => {
													// console.log(user);
													return (
														<tr key={i}>
															<td>{i + 1}</td>
															<td>{user.name}</td>
															<td>{user.username||user.userType}</td>
															<td><Form.Check
																type="checkbox"
																style={{ padding: 10 }}
																checked={user.checked}
																onChange={(e) => {
																	let temp = [...userList];
																	temp[i].checked = !temp[i].checked;
																	setUserList(temp);
																}}
															/></td>
														</tr>
													)
												})}
											</tbody>
										</Table>

					
                    
                    </div>



                    </center>
                </>
            }
              {assesmentPage === "3" &&
                <>
            
                    <center  className="mt-2 ps-4 pe-4 pb-2 col-12 col-lg-12">
                  
                    <div className="ps-4 pe-4 pb-2 mt-5 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                     <Stack className="row  mb-4 mt-4" direction="horizontal">
											<div className="col-12 col-sm-6" style={{ display: 'flex',justifyContent:"flex-start"}}>
												<Button variant="light" style={{ backgroundColor: "#FFF", color: "#6c757d" }} 
                                                // onhover={(e) => doNothing()} 
                                                onClick={(e) => {
													setAssesmentPage("2")
													// setSectionIds(new Set())
												}} >
													← Previous
												</Button>
											</div>
											{/* <div className="col-12 col-sm-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
												<Button variant="outline-secondary" align="right" style={{ minWidth: '150px' }}
                                                 onClick={() =>validateUserSelection()}  >
													Next
												</Button>
											</div> */}
							</Stack>
                            <Grid container>
                            <Grid item sm={12} md={12} lg={6} xs={12}  >
                            <Button className="ms-1 me-1 mb-3" variant="secondary" size="lg" onClick={(e) => handleNullAssesmentPageChange(e)}
                            // style={{width:"80%"}}
                            >
                                 Null Assesment
                            </Button>
                            </Grid>
                            <Grid item sm={12} lg={6} md={12} xs={12}>
                            <Button className="ms-1 me-1 mb-3" variant="secondary" size="lg" onClick={(e) => handleRandomAssesmentPageChange(e)}
                            // style={{width:"80%"}}

                            >
                                 Random Assesment
                            </Button>
                            </Grid>

                            <Grid item sm={12} lg={12} md={12} xs={12}>
                            <Button className="ms-1 me-1 mb-3" variant="secondary" size="lg" onClick={(e) => handleCustomAssesmentPageChange(e)}
                        // style={{width:"80%"}}

                            >
                                 Custom Assesment
                            </Button>
                                  
                            </Grid>
                        </Grid>     
                        <div className="ps-4 pe-4 pb-2" style={{ borderRadius: 10 }} align="left">
                        <h4 style={{ color: '#6c757d' }}>Note - </h4>
                        <ul style={{ color: '#6c757d' }}>
                            <li>
                                <h5>Null Assesment - It will create a Null Assesment i.e., it will not contain any question. </h5>
                            </li>
                            <li>
                                <h5>Random Assesment - It will create a Assesment from the Questions from the Question Bank. </h5>
                            </li>
                            <li>
                                <h5>Custom Assesment - It will create a Assesment in this you have to enter questions manuallay and you will also be having a option to import random questions from the Question Bank.</h5>
                            </li>
                        </ul>
                    </div>
                      
                </div>
                    </center>
                </>
            }
            {assesmentPage === "4" && assesmentType === "RANDOM" &&
                <>
                    <center>

                        <div className="p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                            <h2 className="mb-4">Choose Filter for Random Question</h2>
                            <>
                                {randomFilter.map((Filter) => (
                                    <div>
                                        <TextField
                                        id="filterDifficultyLevel"
                                        select
                                        label="Select Difficulty Level"
                                        value={Filter.difficultyLevel}
                                        onChange={(e) => handleRandomFilterDifficultyLevelChange(Filter.id, e)}
                                        variant="filled"
                                    >
                                        {difficultyLevels.map((option) => (
                                            <MenuItem key={option.value} value={option.value} style={{ width: "100%" }}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                        <TextField
                                            id="filterNoOfQues"
                                            label="No Of Questions"
                                            type="number"
                                            InputProps={{inputProps:{
                                                min:1
                                              }}}
                                            value={Filter.noOfQues}
                                            onChange={(e) => handleRandomFilterNoOfQuesChange(Filter.id, e)}
                                            variant="filled"
                                        />
                                        <IconButton disabled={randomFilter.length === 1} onClick={(e) => handleRandomFilterRemove(Filter.id, e)}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </div>
                                ))}
                            </>
                        </div>
                        <Button variant="outline-secondary" onClick={(e) => handleRandomFilterAdd(e)} >
                            <AddIcon />
                            Add More Filter
                        </Button>
                        <div className="p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                            <Button className="m-4" variant="secondary" size="lg" onClick={(e) => setAssesmentPage("1")}>
                                Go to Previous Page
                            </Button>
                            <Button className="m-4" variant="secondary" size="lg" onClick={(e) => generateAssesment()}>
                                Create Assesment
                            </Button>
                        </div>
                    </center>
                </>
            }
            {assesmentPage === "4" && assesmentType === "CUSTOM" &&
                <>
                    <center id="custom-wrapper">
                                          
                        <h2 className="m-4">Add Questions for Assesment</h2>
                        {/* <Form.Select className="p-6 w-50" controlId="Assessment-type" as="select" onChange={(e)=>selectAssessmentType(e)} >
                            {
                            questionTypes?.map((obj)=> <option value={obj.value}>{obj.description}</option>)
                            }
                        </Form.Select> */}
                        {Error?.isError &&
                                            <Box
                                            // component="form"
                                            sx={{color:"error.main"}}
                                            noValidate
                                            className="mb-1 mt-2 w-50"
                                        >
                                            {Error.statement}
                                        </Box>
                                            }
                      {assessmentType?.length!==0 &&inputQuesFields?.map(inputQuesField => ( 
                            <div key={inputQuesField.id} align="center">
                                <div className="p-4 col-10 col-lg-9 mb-4 mt-4" style={{ background: "#e6e6e6", borderRadius: 10 }} align="left">
                                    <Form.Group controlId="question-description">
                                        <Stack direction="horizontal"  gap={3}>
                                        <>{inputQuesField?.questionType === "DocumentUpload" &&
                                
                                            <div className="" align="left" style={{width:"65%", height: "50px"}}>
                                                
                                                {/* <Form.Group controlId="file-selection">                                                      //w-90
                                                                                //w-90
                                                        <div  className="mb-2" align="left" style={{ width: "60%" }}>
                                                        <Form.Label>Select files</Form.Label>
                                                        <Form.Control type="file" multiple onChange={(e)=>{
                                                            setQuesInputFields(inputQuesFields.map(inputQuestion =>{
                                                                return (inputQuestion.id === inputQuesField.id ? 
                                                                    { ...inputQuestion, files: e?.target?.files } : inputQuestion)}))
                                                            }}/>
                                                        </div>
                                                </Form.Group> */}
                                          
                                          <Form.Control
                                                inline
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter Question"
                                                // className=""
                                                style={{ width: '100%', height: "50px"}}
                                                name="description"
                                                value={inputQuesField.description}
                                                onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion =>
                                                     (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, description: e.target.value } : inputQuestion)))}
                                            />

                                             </div> 
                                        }
                                        </>
                                            {inputQuesField?.questionType !== "DocumentUpload" &&
                                             <Form.Control
                                                inline
                                                as="textarea"
                                                rows={3}
                                                placeholder="Enter Question"
                                                // className=""
                                                style={{ width: '65%', height: "50px"}}
                                                name="description"
                                                value={inputQuesField.description}
                                                onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion =>
                                                     (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, description: e.target.value } : inputQuestion)))}
                                            />}
                                            { assessmentType[0].value=="MixedMcqSub"&&
                                            // <Form.Control
                                            //     inline
                                            //     as="select"
                                            //     style={{ width: '25%', height: "50px" }}
                                            //     name="questionType"
                                            //     // value={inputQuesField.questionType}
                                            //     onChange={e =>{
                                            //         console.log(e.target.value);
                                            //           setQuesInputFields(inputQuesFields.map(inputQuestion =>{
                                            //         // console.log("question type ==>", e.target.value)
                                            //         return (inputQuestion.id === inputQuesField.id ? 
                                            //             { ...inputQuestion, questionType: e.target.value } : inputQuestion)}))
                                            //     }
                                                  
                                            //         }
                                            //     variant="filled"
                                            // >
                                            //     {
                                            //         assessmentType?.map((obj)=>obj.options.map((optionObj)=>{
                                            //             // console.log("optionOBJ",optionObj)
                                            //         return <option  value={optionObj.value}>{optionObj.description}</option>}))
                                            //     }
                                            // </Form.Control>
                                            <TextField
                                            label="Question Type"//&nbsp;&nbsp;&nbsp;
                                            variant="filled"
                                            select
                                            InputProps={{className:muiClasses.QuestionType}}
                                            name="questionType"
                                            style={{ width: '25%', height: "50px", paddingRight:"10%" }}
                                            // sx={{width:"100%"}}
                                            value={inputQuesField.questionType}
                                            onChange={e =>{
                                                console.log(e.target.value);
                                                  setQuesInputFields(inputQuesFields.map(inputQuestion =>{
                                                // console.log("question type ==>", e.target.value)
                                                return (inputQuestion.id === inputQuesField.id ? 
                                                    { ...inputQuestion, questionType: e.target.value } : inputQuestion)}))
                                            }
                                        }
                                          >
                                              {
                                            assessmentType?.map((obj)=>obj.options.map((optionObj)=>{
                                                // console.log("optionOBJ========>",assessmentType)
                                            return <option className="my-3 mx-2"  value={optionObj.value}>{optionObj.description}</option>}))
                                                     }
                                    
                                          </TextField>
                                            }
                                        </Stack>
                                
                                        <>{inputQuesField?.questionType === "MCQ" &&
                                            <div className="mt-3 mb-2" align="left">
                                                <Form.Group controlId="mcq-options">
                                                    {inputQuesField?.options.map(quesOption => (
                                                        <div key={quesOption.id} className="mb-2" align="left" style={{ width: "60%" }}>
                                                            <InputGroup style={{ borderRadius: 10 }}>
                                                        
                                                                <InputGroup.Radio style={{ padding: 10 }} value={quesOption.selected}
                                                                    checked={inputQuesField.correctAnswer === quesOption.id}
                                                                    onChange={e => setQuesInputFields(inputQuesFields.map(inputQues =>(inputQues.id === inputQuesField.id ? { ...inputQues, correctAnswer: quesOption.id } : inputQues)
                                                                            ))}
                                                                />
                                                                <Form.Control placeholder="Enter Option" aria-label="Text input with radio button"
                                                                    // value={quesOption.description}
                                                                    style={{backgroundColor:inputQuesField.correctAnswer === quesOption.id?"#34d1a3":""}}

                                                                    // className="success"
                                                                    onChange={e => {
                                                        setQuesInputFields(inputQuesFields.map(inputQues => (inputQues.id === inputQuesField.id ? { ...inputQues, options: inputQues.options.map(quesOptionVar =>(quesOptionVar.id === quesOption.id ? { ...quesOptionVar, option: e.target.value } : quesOptionVar)) } : inputQues)))
                                                                // console.log("tot options==>",inputQuesField?.options,"current ques==>",quesOption,"<=====e.target.value======>",e.target.value)
                                                                    }}
                                                                />
                                                                <IconButton
                                                                    disabled={inputQuesField.options.length === 1}
                                                                    onClick={e => setQuesInputFields(inputQuesFields.map(inputQues => (inputQues.id === inputQuesField.id ? { ...inputQues, options: inputQues.options.filter(quesOptionVar => quesOptionVar.id !== quesOption.id) } : inputQues)))}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </InputGroup>
                                                        </div>

                                                    ))}
                                                    <Button variant="outline-secondary"                                          
                                                         onClick={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, options: [...inputQuestion.options, { id: uuidv4(),option:"", description: "", selected: false }] } : inputQuestion)))}>
                                                        <AddIcon />
                                                        Add Option
                                                    </Button>
                                                </Form.Group>
                                            </div>
                                        }
                                        </>
                                        <>{inputQuesField?.questionType === "MSQ" &&
                                            <div className="mt-3 mb-2" align="left">
                                                <Form.Group controlId="mcq-options">
                                                    {inputQuesField?.options.map(quesOption => (
                                                        <div key={quesOption.id} className="mb-2" align="left" style={{ width: "60%" }}>
                                                            <InputGroup style={{ borderRadius: 10 }}>
                                                        
                                                                <InputGroup.Radio style={{ padding: 10 }} 
                                                                // value={quesOption.selected}
                                                                    checked={inputQuesField.correctAnswer.includes(quesOption.id)}
                                                                    onClick={e =>{
                                                                        let optionLength=inputQuesField?.options.length
                                                                        let correctAnswerLength=inputQuesField.correctAnswer.length
                                                                        console.log("optionLength",optionLength)
                                                                        console.log("correctAnswerLength",correctAnswerLength)
                                                                        // else{
                                                                            setQuesInputFields(inputQuesFields.map(inputQues =>{
                                                                                if(inputQues.id === inputQuesField.id){
                                                                                    let array
                                                                                    if(Array.isArray(inputQues?.correctAnswer)){
                                                                                         array=[...inputQues.correctAnswer]
                                                                                         if(array.includes(quesOption.id)){
                                                                                            console.log("before",array)
                                                                                            // console.log("checked 2=ing test ==>")
                                                                                            // console.log(quesOption.id)
                                                                                            array=array.filter((id)=>id!==quesOption.id)
                                                                                            console.log("after",array)
    
                                                                                         }else{
                                                                                            if(optionLength-1===correctAnswerLength){
                                                                                                // alert("all options should not be selected")
                                                                                            }else{
                                                                                                // array.push(quesOption.id)

                                                                                            }
                                                                                            array.push(quesOption.id)

                                                                                            // console.log("checked 2=ing test pusing n ==>")
                                                                                         }
                                                                                    }else{
                                                                                         array=[quesOption.id]
                                        
                                                                                    }
                                                                                    inputQues.correctAnswer=array
                                                                                    // console.log("updated ==>",inputQues)
                                                                                    // console.log(inputQuesField.correctAnswer);
                                                                                    // console.log(quesOption.id);
                                                                                    // console.log(e.target.checked);
                                                                                    // console.log(inputQuesField.correctAnswer.includes(quesOption.id));
                                                                                    return inputQues
    
                                                                                }else{
                                                                                        return inputQues
                                                                                }
                                                                             }
    
                                                        
                                                                                ))
                                                                        // }
                                                                         

                                                                        }
                                                                        }
                                                                        // onClick={()=>console.log("clicked")}
                                                                />
                                                                <Form.Control placeholder="Enter Option" aria-label="Text input with radio button"
                                                                    // value={quesOption.description}
                                                                    style={{backgroundColor:inputQuesField.correctAnswer === quesOption.id?"#34d1a3":""}}

                                                                    // className="success"
                                                                    onChange={e => {
                                                        setQuesInputFields(inputQuesFields.map(inputQues => (inputQues.id === inputQuesField.id ? { ...inputQues, options: inputQues.options.map(quesOptionVar =>(quesOptionVar.id === quesOption.id ? { ...quesOptionVar, option: e.target.value } : quesOptionVar)) } : inputQues)))
                                                                // console.log("tot options==>",inputQuesField?.options,"current ques==>",quesOption,"<=====e.target.value======>",e.target.value)
                                                                    }}
                                                                />
                                                                <IconButton
                                                                    disabled={inputQuesField.options.length === 1}
                                                                    onClick={e => setQuesInputFields(inputQuesFields.map(inputQues => (inputQues.id === inputQuesField.id ? { ...inputQues, options: inputQues.options.filter(quesOptionVar => quesOptionVar.id !== quesOption.id) } : inputQues)))}
                                                                >
                                                                    <DeleteForeverIcon />
                                                                </IconButton>
                                                            </InputGroup>
                                                        </div>

                                                    ))}
                                                    <Button variant="outline-secondary"                                          
                                                         onClick={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, options: [...inputQuestion.options, { id: uuidv4(),option:"", description: "", selected: false }] } : inputQuestion)))}>
                                                        <AddIcon />
                                                        Add Option
                                                    </Button>
                                                </Form.Group>
                                            </div>
                                        }
                                        </>
                                        {inputQuesField?.questionType === "Subjective" &&
                                           <h5 className="mt-3" align="left" muted
                                        //    style={{backgroundColor:"red"}}
                                           >
                                                 Answer Response Type
                                             </h5>
        
                                        }
                                     
                                        <>{inputQuesField?.questionType === "Subjective" &&
                                             <div className="ms-4" align="center">
                                                 
                                                <div className="mt-2 mb-2" align="left">
                                              
                                                <Form.Group controlId="mcq-options">
                                               
                                                    {/* {inputQuesField?.options.map(quesOption => ( */}
                                                        <div  className="mb-2" align="left" style={{ width: "100%" }}>
                                                            {/* <InputGroup  className="ms-3" style={{ borderRadius: 10 }}> */}
                                                            {/* <Form.Check style={{ padding: 10 }} 
                                                                // value={quesOption.selected}
                                                                // className="ms-4"
                                                                type={"checkbox"}
                                                                label={"Numeric Answers"}
                                                                    checked={isNumeric}
                                                                    onChange={e =>setNumeric(e.target.checked)}
                                                                />
                                                              {!isNumeric&&
                                                               <TextField
                                                               label="Enter Answer Words Limit"
                                                               variant="filled"
                                                               type="number"
                                                               InputProps={{inputProps:{
                                                               min:10
                                                               }}}
                                                               // value={inputQuesField.correctMarks}
                                                               // onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, correctMarks: e.target.value } : inputQuestion)))}
                                                               // style={{ marginLeft: 10 }}
                                                           />
                                               } */}
                                             

                                                              <Grid container >
  <Grid item sm={12} lg={12} md={12} xs={12}    className="d-flex align-items-center"
// style={{backgroundColor:"red"}}
>
    <Form.Check
      style={{ padding: 10 }}
    //   value={inputQuesField.isAnswerNumeric}
      // className="ms-4"
      id={inputQuesField.id}
      type={"checkbox"}
      checked={inputQuesField?.isAnswerNumeric}
      onChange={(e) =>{ 
        // if(e?.target?.checked){
            // console.log("ques filed",inputQuesField)
            setQuesInputFields((pre)=>{
                let updated=[...pre]
                updated.map((obj)=>{
                    if(obj.id===inputQuesField.id){
                        obj.isAnswerNumeric=e?.target?.checked
                    }
                    return obj
                })
        // console.log("updated filed",updated)
                return updated
            }) 
        // }
        // else{
        //     setQuesInputFields((pre)=>{
        //         let updated=[...pre]
        //         updated.map((obj)=>{
        //             if(obj.id===inputQuesField.id){
        //                 updated.isAnswerNumeric=e?.target?.checked
        //             }
        //         })
        //         return updated
        //     }) 
        // }
        // setNumeric(e.target.checked)
    }
    }
    /><label htmlFor={inputQuesField?.id}>Numeric Answers Only</label>
    
  </Grid>
  <Grid item sm={12} lg={12} md={12} xs={12}  className="d-flex align-items-center" >
  
  <Form.Check
      style={{ padding: 10 }}
    //   value={!inputQuesField.isAnswerNumeric}
      // className="ms-4"
      id={inputQuesField.id+"words"}
      type={"checkbox"}
      checked={(inputQuesField?.isSpecifyWordsCount)}
      onChange={(e) =>{ 
 console.log(" checked ques filed",e?.target?.checked)
        // if(!e?.target?.checked){
        //     setQuesInputFields((pre)=>{
        //         let updated=[...pre]
        //         updated.map((obj)=>{
        //             if(obj.id===inputQuesField.id){
        //                 updated.isAnswerNumeric=(e?.target?.checked)
        //             }
        //         })
        //         return updated
        //     }) 
        // }else{

            setQuesInputFields((pre)=>{
                let updated=[...pre]
                updated.map((obj)=>{
                    if(obj.id===inputQuesField.id){
                        obj.isSpecifyWordsCount=(e?.target?.checked)
                    }
                })
                return updated
            }) 

        // }

        // setNumeric(e.target.checked)
    }
    }
    /><label htmlFor={inputQuesField?.id+"words"}>Specify Words Count</label>

  </Grid>
  <Grid className="d-flex justify-content-center align-items-center"   item sm={12} md={12} lg={12} xs={12}> 
    
  </Grid>
</Grid>
                                                             
                            
                                                            {/* </InputGroup> */}
                                                              
                                                            {!isNumeric&&<></>
                                                            
                                                            // <>
                                                            //    {/* <h5 className="mt-3" muted>
                                                            //    Words Limit
                                                            //  </h5> */}
                                                            // <InputGroup style={{ borderRadius: 10 }}>
                                                                  
                                                            //       {/* {!isNumeric&& */}
                                                            //       <>
                                                               
                                                            //       {/* <Form.Control placeholder="Enter Answer Words Limit" aria-label="Text input with checkbox button"
                                                            //         // disabled={isNumeric}
                                                            //         type="number"
                                                            //        min={10}
                                                            //         // value={quesOption.description}
                                                            //         // style={{backgroundColor:inputQuesField.correctAnswer === quesOption.id?"#34d1a3":""}}
                                                            //         // className="success"
                                                            //         // onChange={e => }
                                                            //     /> */}
                                                            //         <TextField
                                                            //         label="Enter Answer Words Limit"
                                                            //         variant="filled"
                                                            //         type="number"
                                                            //         InputProps={{inputProps:{
                                                            //         min:10
                                                            //         }}}
                                                            //         // value={inputQuesField.correctMarks}
                                                            //         // onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, correctMarks: e.target.value } : inputQuestion)))}
                                                            //         // style={{ marginLeft: 10 }}
                                                            //     />


                                                            //     </>    
                                                            // </InputGroup>
                                                            // </>
                                                              }

                                                              
                                                              
                                                        </div>
                                                </Form.Group>
                                                </div>
                                            </div>
                                        }
                                        </>
                                      

                                        <div className="m-4" >
                                            <TextField
                                                select
                                                label="Select Question Difficulty"
                                                variant="filled"
                                                value={inputQuesField.difficulty}
                                                onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, difficulty: e.target.value } : inputQuestion)))}
                                            >
                                                {difficultyLevelsQues.map((difficultyLevel) => (
                                                    <MenuItem key={difficultyLevel.value} value={difficultyLevel.value} style={{ width: '100%' }}>
                                                        {difficultyLevel.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                label="Correct Marks"
                                                variant="filled"
                                                type="number"
                                                InputProps={{inputProps:{
                                                  min:0
                                                }}}
                                                value={inputQuesField.correctMarks}
                                                onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, correctMarks: e.target.value } : inputQuestion)))}
                                                style={{ marginLeft: 10 }}
                                            />
                                            <TextField
                                                label="InCorrect Marks"
                                                variant="filled"
                                                type="number"
                                                InputProps={{inputProps:{
                                                  min:0
                                                }}}
                                                value={inputQuesField.incorrectMarks}
                                                onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, incorrectMarks: e.target.value } : inputQuestion)))}
                                                style={{ marginLeft: 10 }}
                                            />
                                             {inputQuesField?.isSpecifyWordsCount && (<>
    {/* <label htmlFor={inputQuesField?.id}>Specify Words Limit</label>//testb2
    
    */}
      <TextField
        label="Enter Answer Words Limit"
        variant="filled"
        type="number"
        // style={{backgroundColor:"red"}}
        InputProps={{
          inputProps: {
            min: 10,
          },
        }}
        value={inputQuesField.wordsLimit}
        onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, wordsLimit: e.target.value } : inputQuestion)))}
        // onChange={e => setQuesInputFields(inputQuesFields.map(inputQuestion => (inputQuestion.id === inputQuesField.id ? { ...inputQuestion, correctMarks: e.target.value } : inputQuestion)))}
        // style={{ marginLeft: 10 }}
      />
      </>
    )}
                                        </div>
                                        <div className="mt-4" align="right">
                                            <IconButton disabled={inputQuesFields.length === 1} onClick={e => setQuesInputFields(inputQuesFields.filter(inputQuestion => inputQuestion.id !== inputQuesField.id))}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        ))}
                        <div className="mt-4" align="center">
                            <Button variant="outline-secondary" onClick={e => setQuesInputFields([...inputQuesFields,
                            AddtionalQuesFormat(uuidv4(),uuidv4(),uuidv4(),assessmentType)
                            ])}>
                                <AddIcon />
                                Add Question
                            </Button>
                        </div>
                        <div className="p-4 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                            <Button className="m-4" variant="secondary" size="lg" onClick={(e) => setAssesmentPage("3")}>
                                Go to Previous Page
                            </Button>
                            <Button className="m-4" variant="secondary" size="lg" onClick={() => generateAssesment()}>
                                Create Assesment
                            </Button>
                        </div>
                    </center>
                </>
            }
            {assesmentPage === "5" && 
                <>
                    <h1 align="center" className="mt-4">Assesment Created Successfully</h1>
                    <h5 className="mt-4 ms-4"><b>Assesment Details - </b></h5>
                    <p className="mt-2 ms-5">
                        <b>Assesment Name:</b> {assesmentName} <br></br>
                        <b>No of Questions:</b> {inputQuesFields.length}<br></br>
                        <b>Start Date:</b>{`${new Date(assesmentStartTime?.$d).getDate()}/${new Date(assesmentStartTime?.$d).getMonth()+1}/${new Date(assesmentStartTime?.$d).getFullYear()}`}<br></br>
                        <b>End Date:</b> {NoTimeLimit?"No TimeLimit":`${new Date(assesmentEndTime?.$d).getDate()}/${new Date(assesmentEndTime?.$d).getMonth()+1}/${new Date(assesmentEndTime?.$d).getFullYear()}`} <br></br>
                    </p>
                    <center>
                        <div className="ps-4 pe-4 pb-3 col-10 col-lg-9" style={{ borderRadius: 10 }} align="center">
                            <Button className="m-4" variant="secondary" size="lg" onClick={(e) => setAllVariablesToDefault()}>
                                Create Another Assessment
                            </Button>
                            <Button className="m-4" variant="secondary" size="lg" onClick={(e) => goToDashboard()}>
                                Go to Dashboard
                            </Button>
                        </div>
                    </center>
                </>
            }
        </Box >
    );
}

export default CreateAssesment;