import React, { useState, useEffect, useRef } from "react";
import "./chat.css";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import io from "socket.io-client";

function ChatTeacher() {
  const [{ userDetails }] = useDataLayerValue();
  const [loggedIn, setLoggedIn] = useState(false);
  const [room, setRoom] = useState("");
  const [roomToShow, setRoomToShow] = useState("");
  const [roomOps, setRoomOps] = useState([]);
  const [userName, setUserName] = useState(userDetails?.name);
  const [connected, setConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("second");
  const socketRef = useRef();

  useEffect(() => {
    setMessage("");
  }, [messages]);
  useEffect(() => {
    if (userDetails.classes) {
      const classes = userDetails.classes;
      setRoomOps(classes);
    }
  }, [userDetails]);

  const roomSelectFunc = (val) => {
    const className = val.split(" ")[0];
    const classSec = val.split(" ")[1];
    setRoom(`${userDetails.schoolId}_${className}_${classSec}`);
    setRoomToShow(`${userDetails.schoolName} (${className}${classSec})`);
  };

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on("chat", (data) => {
        setMessages(data);
      });
    }
    if (socketRef.current) {
      socketRef.current.on("initMessages", (data) => {
        console.log(data);
        initMessage(data);
      });
    }
  });

  const initMessage = (incomingMessages) => {
    // console.log(incomingMessages);
    setMessages(incomingMessages);
  };

  const establishConnection = (e) => {
    e.preventDefault();
    socketRef.current = io.connect("http://ec2.youthindiaeschool.com");
    // console.log(socketRef.current);
    if (socketRef.current && room) {
      socketRef.current.emit("join", room, (incomingMessages) =>
        initMessage(incomingMessages)
      );
      setConnected(true);
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    let cd = new Date();

    let currDate = `${cd.getDate()}/${cd.getMonth() + 1}/${cd.getFullYear()}`;
    let currTime = `${
      cd.getHours() < 10 ? `0${cd.getHours()}` : cd.getHours()
    }:${cd.getMinutes() < 10 ? `0${cd.getMinutes()}` : cd.getMinutes()} `;
    if (socketRef.current && message !== "" && room) {
      const messageData = {
        sender: userName,
        message: message,
        room: room,
        date: currDate,
        time: currTime,
      };
      socketRef.current.emit("chat", messageData);
      setMessages([...messages, messageData]);
    }
  };

  const goBackToChatHome = () => {
    setConnected(false);
    setMessages([]);
    setRoom("");
  };

  const returnDate = (d) => {
    const dateTemp = d.split("/");
    const date = dateTemp[0];
    const month = dateTemp[1];
    const year = dateTemp[2];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${date} ${months[month - 1]}`;
  };

  return (
    <div className="ChatMain">
      {!connected ? (
        <form
          className="logIn"
          onSubmit={(e) => {
            establishConnection(e);
          }}
        >
          <div className="inputs">
            <input
              type="text"
              //   placeholder="Name..."
              value={userName}
              disabled
            />

            <select
              name=""
              id=""
              onChange={(e) => {
                roomSelectFunc(e.target.value);
              }}
            >
              <option value="">Select Room</option>
              {roomOps.map((roomOp) => (
                <option value={roomOp}>{roomOp}</option>
              ))}
            </select>
          </div>
          <button type="submit">Enter Chat</button>
        </form>
      ) : (
        <div className="chatContainer">
          <div className="chat-room-info">
            <p>Room : {roomToShow}</p>
            <button onClick={() => goBackToChatHome()}>Back</button>
          </div>

          <div className="messages">
            {messages?.map((val, key) => {
              return (
                <div
                  className="messageContainer"
                  id={val.sender == userName ? "You" : "Other"}
                >
                  <div className="messageIndividual">
                    {val.sender === userName ? "You" : val.sender}:{" "}
                    {val.message}
                    <div className="messageTime">
                      {returnDate(val?.date)} {val?.time}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <form
            className="messageInputs"
            onSubmit={(e) => {
              sendMessage(e);
            }}
          >
            <input
              type="text"
              placeholder="Message..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </div>
  );
}

export default ChatTeacher;
