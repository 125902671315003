import { React, useState, useEffect } from 'react';
import Api from '../../Api/axios';
import { useParams, useNavigate } from "react-router-dom";
import { Stack } from 'react-bootstrap';
import IconButton from '@material-ui/core/IconButton';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import LocalPhoneOutlinedIcon from '@material-ui/icons/LocalPhoneOutlined';

const ViewProfile = () => {
    const urlParams = useParams();
    const [user, setUser] = useState(null);
    useEffect(() => {
        Api.get('/user/get-user-by-id?id=' + urlParams.userId).then((response) => {
            setUser(response.data);
        });
    }, []);
    return (
        <>
            {user ? <>

                <div style={{ minHeight: "100%", width: "auto", minWidth: "800px", background: "#FFF" }}>
                    <div style={{ height: "200px", width: "auto", color: "#fff", background: `url(${process.env.PUBLIC_URL}/Images/banner.jpg)`, backgroundSize: "cover" }} align="left">
                        <Stack direction='horizontal' gap={3}>
                            <img
                                src={user?.avatar ? user.avatar : `${process.env.PUBLIC_URL}/Images/default_avatar.jpg`}
                                alt="profile pic"
                                className='ms-2'
                                style={{ marginTop: "70px", width: "200px", height: "200px", border: "4px solid white", borderRadius: "50%" }}
                            />
                            <div style={{ marginTop: "100px" }}>
                                <h2 className="pt-2 pb-0 mb-0" style={{ fontSize: '36px', fontWeight: 'bold' }}>{user.name ? user.name : "User"}</h2>
                                <p className="m-0 ps-1" style={{ color: "white" }}>{user.userType}</p>
                                <Stack direction="horizontal" gap={2}>
                                    <IconButton
                                        className="pt-3 pb-3 ps-0 pe-0"
                                        style={{ color: "#4285F4" }}
                                    >
                                        <EmailOutlinedIcon />
                                    </IconButton>
                                    <p className="m-0 pt-3 pb-3 ps-0 pe-0" style={{ color: "#4285F4" }}>{user.email}</p>
                                    <p className="m-0 pt-3 pb-3 ps-0 pe-0" style={{ color: "#4285F4" }}> | </p>
                                    <IconButton
                                        className="pt-3 pb-3 ps-0 pe-0"
                                        style={{ color: "#4285F4" }}
                                    >
                                        <LocalPhoneOutlinedIcon />
                                    </IconButton>
                                    <p className="m-0 pt-3 pb-3 ps-0 pe-0" style={{ color: "#4285F4" }}>{user?.contactNumber?.userContactNo}</p>
                                </Stack>
                            </div>

                        </Stack>

                    </div>
                </div>
            </> : null}
        </>
    )
}

export default ViewProfile;