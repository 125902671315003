import React, { useEffect, useState } from "react";
import "./ad_teacher_attendance.css";
import { Link } from "react-router-dom";
import { useDataLayerValue } from "../../../../DataLayer/DataLayer";
import Api from "../../../../Api/axios";
import Error from "../../../ErrorSuccess/Error";
import Success from "../../../ErrorSuccess/Success";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Ad_Teacher_Attendance() {
  const [attendanceData, setAttendanceData] = useState([]);
  const [showAttendance, setShowAttendance] = useState(false);
  const [markAllClick, setMarkAllClick] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [errMessage, setErrMessage] = useState();
  const [succMessage, setSuccMessage] = useState();
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [selectedDate, setSelectedDate] = useState("");
  const [usertype, setUserType] = useState("ALL");

  useEffect(() => {
    getAttendanceList();
  }, []);

  const getAttendanceList = () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    // if (userDetails.userType === "DISTRICT") {
    //    console.log("hi i m District");
    //    console.log(userDetails?.schoolId);
    //   Api.get(`/user/by-school?id=${userDetails?.schoolId}`)
    //     .then((res) => {
    //       //console.log(res.data);
    //       setAttendanceData(res.data);
    //       console.log(res.data);
    //       dispatch({
    //         type: "SET_LOADING",
    //         loading: false,
    //       });
    //     })
    //     .catch((err) => {
    //       setErrMessage(err.response);
    //       dispatch({
    //         type: "SET_LOADING",
    //         loading: false,
    //       });
    //     });
    // }

    if (
      userDetails.userType === "SCHOOL_ADMIN" ||
      userDetails.userType === "DISTRICT"
    ) {
      if (usertype === "ALL") {
        Api.get(`/user/by-school?id=${userDetails?.schoolId}`)
          .then((res) => {
            //console.log(res.data);
            setAttendanceData(res.data);
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
          })
          .catch((err) => {
            setErrMessage(err.response);
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
          });
      } else {
        Api.get(`/user/by-school?id=${userDetails?.schoolId}&type=${usertype}`)
          .then((res) => {
            //console.log(res.data);
            setAttendanceData(res.data);
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
          })
          .catch((err) => {
            setErrMessage(err.response);
            dispatch({
              type: "SET_LOADING",
              loading: false,
            });
          });
      }
    }
  };

  const markPresent = (e) => {
    const { id, checked } = e.target;
    if (!checked) {
      setAttendees(attendees.filter((item) => item !== id));
    } else {
      setAttendees([...attendees, id]);
    }
  };

  const handleMarkAll = (e) => {
    setMarkAllClick(e.target.checked);
    attendanceData.forEach((item) => {
      setAttendees((prevState) => [...prevState, item._id]);
    });
    if (!e.target.checked) {
      setAttendees([]);
    }
  };

  const updateAttendanceSheet = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let attendeesPresent = {};
    attendees.forEach((item) => {
      Object.assign(attendeesPresent, { [item]: true });
    });

    attendanceData.forEach((item) => {
      if (!(item._id in attendeesPresent)) {
        Object.assign(attendeesPresent, { [item._id]: false });
      }
    });

    const dateOfAttendance = `${selectedDate.getDate()}/${
      selectedDate.getMonth() + 1
    }/${selectedDate.getFullYear()}`;
    const dataToPush = {
      attendance: attendeesPresent,
      school_id: userDetails.schoolId,
      attendee_type: "TEACHER",
      date: dateOfAttendance,
    };
    await Api.post("/attendance", dataToPush)
      .then((res) => {
        console.log(res);
        setSuccMessage("Attendance recorded successfully");
        setErrMessage();
      })
      .catch((err) => {
        setErrMessage(err.response);
        setSuccMessage();
      });
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  return (
    <div className="ad-attnd-teacher-container">
      <div className="ad-attnd-teacher-container-main">
        <form
          className="top"
          onSubmit={(e) => {
            e.preventDefault();
            getAttendanceList();
            setShowAttendance(true);
          }}
        >
          {userDetails.userType === "DISTRICT" ? (
            <select
              name="user-type"
              id="user-type"
              onChange={(e) => setUserType(e.target.value)}
              required
            >
              <option value="">Select User Type</option>
              <option value="ALL">All</option>
              <option value="SCHOOL_ADMIN">SCHOOL ADMIN</option>
              <option value="TEACHER">Teacher</option>
              <option value="STUDENT">Student</option>
            </select>
          ) : (
            <select
              name="user-type"
              id="user-type"
              onChange={(e) => setUserType(e.target.value)}
              required
            >
              <option value="">Select User Type</option>
              <option value="ALL">All</option>
              <option value="TEACHER">Teacher</option>
              <option value="STUDENT">Student</option>
            </select>
          )}

          <select>
            <option>Select Class</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
          </select>

          <select>
            <option>Select Section</option>
            <option>A</option>
            <option>B</option>
          </select>
          <DatePicker
            placeholderText="Select Date"
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            minDate={new Date().setDate(new Date().getDate() - 10)}
            maxDate={new Date()}
            dateFormat="dd-MM-yyyy"
            wrapperClassName="ad-attnd-teacher-container-main-datepicker"
            required
          />

          <div>
            <button type="submit">Mark Attendance</button>
            <Link to="/attendance">
              <button type="button">Back to Home Page</button>
            </Link>
          </div>
        </form>
        <div className="middle"></div>

        <div className="bottom">
          {showAttendance && (
            <div className="ad-attnd-teacher-attnd-sheet-container">
              <div className="mark-all-container">
                <div className="att-response-message">
                  {succMessage ? (
                    <Success message={succMessage} />
                  ) : errMessage ? (
                    <Error message={errMessage} />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <label htmlFor="ad-teacher-all">Mark all present</label>
                  <input
                    type="checkbox"
                    name="ad-teacher-all"
                    id="ad-teacher-all"
                    onChange={(e) => {
                      handleMarkAll(e);
                    }}
                  />
                </div>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Teacher Name</th>
                    <th>Teacher Id</th>
                    <th>Attendance</th>
                  </tr>
                </thead>
                <tbody>
                  {attendanceData.map((e, i) => (
                    <tr key={i}>
                      <td>{e.name}</td>
                      <td>{e.conventionalId}</td>
                      <td>
                        <input
                          type="checkbox"
                          name="attend-present"
                          id={e._id}
                          onChange={(element) => {
                            markPresent(element);
                          }}
                          checked={
                            (markAllClick && attendees.includes(e._id)) ||
                            attendees.includes(e._id)
                          }
                        />
                        Present
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="ad-teacher-update-btn">
                <button onClick={() => updateAttendanceSheet()}>Submit</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Ad_Teacher_Attendance;
