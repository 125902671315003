import { TextField, Button } from "@material-ui/core";
import React from "react";
import { Timer } from "@material-ui/icons";
import Countdown from "react-countdown";
import { Alert } from "@mui/material";
import { NavLink } from "react-router-dom";

const VerifyOtp = ({ email }) => {
  // const [verifyotp,setVerifyOtp]=useState(false);
  console.log(email);

  const Completionist = () => <Alert severity="error">Oops Time Out</Alert>;
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />;
    } else {
      return (
        <span>
          {minutes}:{seconds}
        </span>
      );
    }
  };

  return (
    <div className="verify-otp">
      <div className="verify-container">
        <div className="verify-text">
          <h1>Please Check your email!</h1>
          <p>
            We have mailed a 6-digit confirmation code to abc@domain, please
            enter the code in below box to verify your Email
          </p>
          <div className="timer-countdown">
            <Timer />
            <Countdown
              style={{ margin: "1rem" }}
              date={Date.now() + 300000}
              renderer={renderer}
            />
          </div>
        </div>
        <form className="verify-form">
          <TextField
            style={{ margin: "1rem" }}
            type="number"
            label="Enter a code"
            variant="outlined"
          />
          <Button
            style={{
              backgroundColor: "#00AB55",
              color: "white",
              margin: "1rem",
            }}
            variant="contained"
            color="success"
          >
            verify
          </Button>
        </form>
        <div className="password">
          <TextField
            style={{ margin: "1rem" }}
            fullWidth
            variant="outlined"
            label="Enter your new Password"
          />
          <Button
            variant="contained"
            color="success"
            style={{
              margin: "1rem",
              backgroundColor: "#00AB55",
              color: "white",
            }}
          >
            Confirm
          </Button>
        </div>
        <div className="resend-code">
          Don't receive a code ?
          <Button style={{ color: "#00AB55" }}> Resend a code</Button>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
